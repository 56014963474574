import React, { useEffect, useRef, useState } from "react";
import Accordion from "../../../components/Accordion";
import LeaveRequest from "./LeaveRequest";
import { allLeaves, userLeave } from "../../../apis/leaves";
import WithLoader from "../../../hoc/WithLoader";
import { useStyles } from "./styles";
import { io } from "socket.io-client";
import { WEB_SOCKET_URL } from "../../../config";
import CancelRequest from "./CancelRequest";
import { RequestedLeave } from "../types";
import { usePermissionObjectFor } from "../../../hooks/permission/usePermissionObjectFor";

interface IProps {
  showToast: any;
  leaveData: RequestedLeave[];
  refreshData?: Boolean;
  pageNo: number;
}

const SideBar: React.FC<IProps> = ({
  showToast,
  leaveData,
  refreshData,
  pageNo,
}) => {
  const classes = useStyles();
  const sidebarRoot = useRef<any>(null);
  const [rootHeight, setRootHeight] = useState<number>(400);
  const [loadRequests, setLoadRequests] = useState<boolean>(false);
  const [toggleRefresh, setToggleRefresh] = useState(refreshData);
  const [socketStatus, setSocketStatus] = useState(false);

  const permission = usePermissionObjectFor("leave");
  const hasCreateAccess = permission.includes("create");
  const hasGrantAccess = permission.includes("grant");

  const LeaveRequestWithLoader = WithLoader(LeaveRequest);
  const CancelRequestWithLoader = WithLoader(CancelRequest);

  useEffect(() => {
    let socket = io(`${WEB_SOCKET_URL}`, { transports: ["websocket"] });
    socket.on("message", (message: string) => {
      setSocketStatus(!socketStatus);
    });

    return () => {
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketStatus]);

  // useEffect(() => {
  //   if (!hasGrantAccess) {
  //     setRootHeight(600);
  //   }
  //   setRootHeight(sidebarRoot?.current?.clientHeight);
  //   getUserLeave();
  // }, []);

  /**
   * @description Get all leaves for a particular user
   * @method getUserLeave
   * @returns nothing
   */
  const getUserLeave = async () => {
    setLoadRequests(true);
    try {
      if (hasCreateAccess === false) {
        await allLeaves(pageNo);
        setLoadRequests(false);
      } else {
        await userLeave();
        setLoadRequests(false);
      }
    } catch (err: any) {
      showToast.error(err.response.data.message);
      setLoadRequests(false);
    }
  };

  const accordionData = [
    // {
    //   title: isSuperAdmin ? "Leave Details" : "Leave Applied",
    //   component: (
    //     <LeaveApplied
    //       data={userLeaveData}
    //       parentHeight={rootHeight}
    //       showToast={showToast}
    //     />
    //   ),
    // },
    {
      title: "Leave Requests",
      component: (
        <LeaveRequestWithLoader
          showToast={showToast}
          isLoading={loadRequests}
          parentHeight={rootHeight}
        />
      ),
      shouldDisplay: hasGrantAccess,
    },
    {
      title: "Cancel Requests",
      component: (
        <CancelRequestWithLoader
          showToast={showToast}
          parentHeight={rootHeight}
        />
      ),
      shouldDisplay: hasGrantAccess,
    },
  ];

  return (
    <div className={classes.container} ref={sidebarRoot}>
      <Accordion data={accordionData} />
    </div>
  );
};

export default SideBar;
