import {
    Paper,
    TableRow,
    TableHead,
    TableContainer,
    TableCell,
    TableBody,
    Table,
  } from "@material-ui/core";
  import { useStyles } from "../styles";
  import CircularProgress from "@material-ui/core/CircularProgress";
  import moment from "moment";
  import {user} from "../../types";
  
  interface Iprops {
    items:user[];
    headCells: {
      key: string;
      label: string;
    }[];
    isLoading: Boolean;
  }
  
  const EmployeeLeaveTable: React.FC<Iprops> = ({
    items = [],
    headCells,
    isLoading,
  }) => {
    const classes = useStyles();
    return (
      <TableContainer component={Paper} className={classes.table}>
        <Table>
          <TableHead className={classes.tableHeaderRoot}>
            <TableRow>
              <TableCell className={classes.tableHeader} width="2%"></TableCell>
              {headCells.map((headCells) => (
                <TableCell
                  height="40"
                  key={headCells.key}
                  align="left"
                  className={classes.tableHeader}
                >
                  <div
                  className={
                    headCells.key === "leavesTaken"
                      ? classes.tableHeaderNoBorder
                      : classes.tableHeaderInside
                  }
                >
                  <span>{headCells.label}</span>
                </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading ? (
            <div className={classes.loading}>
              <CircularProgress color="primary" />
            </div>
          ) : (
            <TableBody>
              {items.map((item: user, i: number) => (
                <TableRow
                  className={classes.tableRow}
                  key={item.id}
                >
                  <TableCell></TableCell>
                  <TableCell component="th" scope="row" align="left">
                    {item.name}
                  </TableCell>
                  <TableCell component="th" scope="row" align="left">
                  {item.privilegeLeave}
                  </TableCell>
                  <TableCell component="th" scope="row" align="left">
                  {item.casualLeave}
                  </TableCell>
                  <TableCell component="th" scope="row" align="left">
                  {item.sickLeave}
                  </TableCell>
                  <TableCell component="th" scope="row" align="left">
                  {item.leavesTaken}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    );
  };
  
  export default EmployeeLeaveTable;
  