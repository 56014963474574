import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import BasicLayout from "../../components/BasicLayout";
import Drawer from "@material-ui/core/Drawer";
import Button from "../../components/CustomButton";
import { useBasicNav } from "../../utils/useBasicNav";
import backIcon from "../../assets/icons/back.svg";
import Table from "../../components/Table";
import roleSettingsIcon from "../../assets/icons/roleSettings.svg";
import classNames from "classnames";
import addIcon from "../../assets/icons/addIcon.svg";
import DesignationForm from "../../components/Forms/DesignationForm";
import designationIcon from "../../assets/icons/designation.svg";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { getPersistentAuthentication } from "../../utils/functions";
import downArrowIcon from "../../assets/icons/downArrow.svg";
import { getRoles } from "../../apis/roles";
import { getUsers, inviteUser } from "../../apis/users";
import {
  getDepartment,
  getDesignation,
} from "../../apis/designations_departments";
import { useStyles } from "./styles";
import { Route, useHistory, useRouteMatch } from "react-router-dom";
import _ from "lodash";
import { permissions } from "../../recoil/atoms";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { getRoleData } from "../../apis/login";
import SimpleTableDis from "./employeeTable";
import SearchBox from "./searchEmployess";
import { employeePageTableAtoms } from "../../recoil/atoms";
import { filterEmployeePageTableDataValue } from "../../utils/constants";
import { useFetchTableData } from "./useFetchTableData";
import { CheckAllAdmins } from "../../utils/CheckPermissions";
import { Switch } from "react-router-dom";
import EmployeeDetails from "./EmployeeDetails";
import { getAllEmployees } from "../../apis/employees";
import SnackbarComponent from "../../components/SnackbarComponent";
import {
  employeeSensitiveDataEditPermission,
  employeeDataReadPermission,
} from "../../utils/functions";
import { usePermissionObjectFor } from "../../hooks/permission/usePermissionObjectFor";

const SORT_LABELS = ["Name", "Active", "Non Active", "All employees"];

interface Iprops {
  loadPrimary: (event: boolean, basic?: boolean) => void;
  showToast: any;
}
interface IDrawerObj {
  [key: string]: any;
}

interface designationObject {
  _id: string;
  designationName: string;
}
interface departmentObject {
  _id: string;
  departmentName: string;
}

const HEAD_CELLS = [
  { key: "fullname", label: "Name" },
  { key: "officialEmailId", label: "Email address" },
  { key: "contactNo", label: "Phone number" },
  { key: "designation", label: "Designation" },
  { key: "assignedTo", label: "Assigned to" },
  { key: "action", label: "" },
];
export const Toast = React.createContext<any>({});
/**
 * @description Function to display all the employee list in a tabular format
 * @method EmployeesPage
 * @param {loadPrimary} loadPrimary
 * @param {showToast} showToast
 * @returns a node
 */
const EmployeesPage: React.FC<Iprops> = ({ loadPrimary, showToast }) => {
  useBasicNav("employees");

  const classes = useStyles();
  const history = useHistory();
  // const [permission, setPermission] = useRecoilState<any>(permissions);
  const [sortActive, setSortActive] = useState<boolean>(false);
  const [refresh, setRefresh] = useState(false);
  const [roles, setRoles] = useState([]);
  const [loadRoles, setLoadRoles] = useState(false);
  const [departments, setDepartments] = useState<departmentObject[]>([]);
  const [loadDesignation, setLoadDesignation] = useState(false);
  const [designations, setDesignations] = useState<designationObject[]>([]);
  const [drawerType, setDrawerType] = useState("");
  const [drawerState, setDrawerState] = useState(false);
  const [tableItems, setTableItems] = useState([]);
  const [ownershipIdList, setOwnershipIdList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [clickedTableRow, setClickedTableRow] = useState(0);
  const [sortTag, setSortTag] = useState(
    filterEmployeePageTableDataValue.active
  );
  // const employeePermission = permission?.user;
  // const rolePermission = permission?.role;
  // const departmentPermission = permission?.department?.read;
  // const designationPermission = permission?.designation?.read;
  const [totalNumberOfPages, setTotalNumberOfPages] = useState(1);

  //recoil code
  const setEmployeePageTableState = useSetRecoilState(employeePageTableAtoms); //to update recoil state
  const employeePageTableState = useRecoilValue(employeePageTableAtoms); // to read recoil state

  const { path } = useRouteMatch();

  /**
   * @description Function for storing ownership id's
   * @method ownershipIds
   * @param {any} users
   * @returns nothing
   */
  const ownershipIds = (users: any) => {
    let tempObj: any = [];
    users.map((item: any) => {
      tempObj.push({
        fullname: item.fullname,
        _id: item._id,
      });
    });
    setOwnershipIdList([...tempObj]);
  };

  /**
   * @method getAllUsers
   * @description api call to get all users
   */

  //state for snackbar
  const [snackbarState, setSnackbarState] = useState({
    openBooleanProp: false,
    messageProp: "dummy message state",
    statusProp: "info",
  });

  const permission = usePermissionObjectFor("employees");
  const hasReadAccess = permission.includes("read");
  const employeeSensitiveDataEditPermission = permission.required([
    "read",
    "create",
    "upDate",
  ]);
  const hasCreateAccess = permission.includes("create");

  const setPaginationTableData = async () => {
    //set url
    const filterBy = employeePageTableState?.filterBy;
    const search = employeePageTableState?.search;
    const currentPageNumber = employeePageTableState?.currentPage;

    let fullUrl =
      "/employees?include=basicDetails.fullName,basicDetails.email,basicDetails.phoneNumber,employeeInformation.designation,employeeInformation.isActive,employeeInformation.assignedTo&";
    const searchUrlEndPoint = `search=${search}`;
    const pageUrlEndPoint = `page=${currentPageNumber}`;

    fullUrl = fullUrl + pageUrlEndPoint + "&" + searchUrlEndPoint;

    if (filterBy === filterEmployeePageTableDataValue.name) {
      fullUrl = fullUrl + "&" + `sortBy=basicDetails.fullName`;
    } else if (filterBy === filterEmployeePageTableDataValue.active) {
      fullUrl = fullUrl + "&" + `isActive=true`;
    } else if (filterBy === filterEmployeePageTableDataValue.inActive) {
      fullUrl = fullUrl + "&" + `isActive=false`;
    } else if (filterBy === filterEmployeePageTableDataValue.allEmployees) {
      fullUrl = "";
      fullUrl =
        "/employees?include=basicDetails.fullName,basicDetails.email,basicDetails.phoneNumber,employeeInformation.designation,employeeInformation.isActive,assignedTo&" +
        pageUrlEndPoint +
        "&" +
        `search=${search}`;
    }

    //fetch data
    let neWData: any = [];
    try {
      let res = await getAllEmployees(fullUrl);
      //
      const response = res?.data;
      neWData = res?.data?.docs;
      setTableItems(neWData);
      setTotalNumberOfPages(response?.pages);
      //reset the url params
      fullUrl = "";
    } catch (err: any) {
      //show toast
      setSnackbarState({
        openBooleanProp: true,
        messageProp: "Error : " + err,
        statusProp: "error",
      });
    }
  };

  useEffect(() => {
    // fetch data from here
    setPaginationTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    employeePageTableState.currentPage,
    employeePageTableState.search,
    employeePageTableState.filterBy,
  ]);

  // /**
  //  * @method getAllDepartments
  //  * @description api call to get all departments
  //  */

  const getAllDepartments = () => {
    loadPrimary(true, true);
    getDepartment()
      .then((response) => {
        if (response?.status == 200) {
          setDepartments(response?.data);
        }
        loadPrimary(false);
      })
      .catch((err) => {
        loadPrimary(false);
      });
  };

  // /**
  //  * @description Function which sorts the array on the basis of selected sort tag
  //  * @method sortEmployees
  //  * @param {string} sortTag
  //  * @returns a sorted array list
  //  */
  const sortEmployees = (sortTag: string) => {
    switch (sortTag) {
      case "Name":
        setSortTag("Name");
        //set recoil state for filterBy
        setEmployeePageTableState({
          tableData: employeePageTableState?.tableData,
          search: employeePageTableState?.search,
          filterBy: filterEmployeePageTableDataValue?.name,
          currentPage: 1,
          pages: employeePageTableState?.pages,
        });
        handleSortDropdown();
        break;

      case "Active":
        setSortTag("Active");
        //set recoil state for filterBy
        setEmployeePageTableState({
          tableData: employeePageTableState?.tableData,
          search: employeePageTableState?.search,
          filterBy: filterEmployeePageTableDataValue?.active,
          currentPage: 1,
          pages: employeePageTableState?.pages,
        });
        handleSortDropdown();
        break;

      case "Non Active":
        setSortTag("Non Active");
        //set recoil state for filterBy
        setEmployeePageTableState({
          tableData: employeePageTableState?.tableData,
          search: employeePageTableState?.search,
          filterBy: filterEmployeePageTableDataValue?.inActive,
          currentPage: 1,
          pages: employeePageTableState?.pages,
        });
        handleSortDropdown();
        break;

      case "All employees":
        setSortTag("All Employees");
        //set recoil state for filterBy
        setEmployeePageTableState({
          tableData: employeePageTableState?.tableData,
          search: employeePageTableState?.search,
          filterBy: filterEmployeePageTableDataValue?.allEmployees,
          currentPage: 1,
          pages: employeePageTableState?.pages,
        });
        handleSortDropdown();
        break;

      default:
      //
    }
  };

  // /**
  //  * @method getAllDesignation
  //  * @description api call to get all Designation
  //  */

  const getAllDesignation = () => {
    setLoadDesignation(true);
    loadPrimary(true, true);
    getDesignation()
      .then((response) => {
        if (response?.status == 200) {
          setDesignations(response?.data);
        }
        setLoadDesignation(false);
        loadPrimary(false);
      })
      .catch((err) => {
        //
        setLoadDesignation(false);
        loadPrimary(false);
      });
  };

  // /**
  //  * @description Function for redirecting to "Add User" page
  //  * @method handleAddUser
  //  * @returns nothing
  //  */

  // /**
  //  * @method toggleRefresh
  //  * @description refreshes/ re-renders the page
  //  */

  const toggleRefresh = (type: any) => {
    if (type == "designation") {
      getAllDesignation();
    } else if (type == "department") {
      getAllDepartments();
    }
  };

  // /**
  //  * @method toggleDrawerDesignation
  //  * @description open right drawer for roles form
  //  */

  const toggleDrawerDesignation = () => {
    setDrawerType("designation");
    setDrawerState(true);
  };

  // /**
  //  * @method toggleDrawerClose
  //  * @description close right drawer
  //  */

  const toggleDrawerClose = () => {
    setDrawerState(false);
  };

  // /**
  //  * @method handleSortDropdown
  //  * @description Function to open/close sort pop over menu
  //  * @returns nothing
  //  */

  const handleSortDropdown = () => {
    setSortActive(!sortActive);
  };

  // /**
  //  * @method handleClickAway
  //  * @description handles closing of dropdown when clicked outside
  //  */

  const handleClickAway = () => {
    setSortActive(false);
  };

  // /**
  //  * @description Function which refreshes the employee list after employee status has changed
  //  * @method changeEmployeeStatus
  //  * @returns nothing
  //  */

  const DRAWER_OBJECT: IDrawerObj = {
    designation: {
      element: (
        <DesignationForm
          toggleRefresh={toggleRefresh}
          departments={departments}
          loadDesignation={loadDesignation}
          designations={designations}
          showToast={showToast}
        />
      ),
      heading: "Designation",
    },
  };

  useEffect(() => {
    loadPrimary(false, false);
    getAllDesignation();
    getAllDepartments();
    //reset search and filter to default on first load of this page
    setEmployeePageTableState({
      tableData: [],
      search: "",
      filterBy: filterEmployeePageTableDataValue.active,
      currentPage: 1,
      pages: 1,
    });
  }, [window.location.href]);

  return (
    <Toast.Provider value={showToast}>
      <BasicLayout>
        <SnackbarComponent snackbarStateProp={snackbarState} />
        <Switch>
          <Route path={`${path}`} exact>
            {hasReadAccess && (
              <Grid
                direction="column"
                container
                className={classes.mainContainer}
              >
                <Grid
                  justify="space-between"
                  alignItems="center"
                  className={classes.buttonContainer}
                  container
                >
                  <Grid xs={8} container>
                    <Grid>
                      <SearchBox setdataProps={setTableItems} />
                    </Grid>
                  </Grid>
                  {employeeSensitiveDataEditPermission && (
                    <Grid className={classes.button}>
                      <Button
                        label="Designation"
                        isDisabled={false}
                        isBordered={false}
                        startIcon={designationIcon}
                        endIcon=""
                        isOutlined={true}
                        onButtonClick={toggleDrawerDesignation}
                        color=""
                      />
                    </Grid>
                  )}
                  {employeeSensitiveDataEditPermission && (
                    <ClickAwayListener onClickAway={handleClickAway}>
                      <Grid className={classes.button}>
                        <Button
                          label={`Sort by: ${sortTag}`}
                          isDisabled={false}
                          isBordered={false}
                          startIcon=""
                          endIcon={downArrowIcon}
                          isOutlined={false}
                          onButtonClick={handleSortDropdown}
                          color="whiteNoHover"
                        />

                        <Grid
                          container
                          className={classNames(classes.sortCategory, {
                            [classes.sortCategoryHide]: !sortActive,
                          })}
                        >
                          {SORT_LABELS.map((item, index) => {
                            return (
                              <Grid
                                key={item}
                                className={classes.categoryItems}
                                container
                                onClick={() => sortEmployees(item)}
                              >
                                <p className={classes.sortLabels}>{item}</p>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Grid>
                    </ClickAwayListener>
                  )}
                </Grid>
                <Grid
                  container
                  className={classNames(
                    classes.tableContainer
                    //   , {
                    //   [classes.tableContainerHide]: employeePermission?.read == false,
                    // }
                  )}
                >
                  {/* <Table
            inviteUserHandler={inviteUserHandler}
            headCells={HEAD_CELLS}
            loading={loading}
            items={tableItems}
            tableClickHandler={tableClickHandler}
            clickedTableIndex={clickedTableRow}
            changeEmpStatus={changeEmployeeStatus}
            showToast={showToast}
          /> */}
                  <SimpleTableDis
                    items={tableItems}
                    totalNumberOfPagesProps={totalNumberOfPages}
                    permission={hasCreateAccess}
                  />
                </Grid>
              </Grid>
            )}
            <Drawer
              anchor="right"
              open={drawerState}
              onClose={toggleDrawerClose}
            >
              <Grid
                className={classes.drawerContainer}
                justify="space-between"
                alignItems="center"
                container
              >
                <Grid
                  className={classes.backContainer}
                  onClick={toggleDrawerClose}
                  alignItems="center"
                  xs={3}
                  container
                >
                  <img src={backIcon} alt="back-icon" />
                  <p className={classes.back}>Back</p>
                </Grid>
                <Grid xs={4} container>
                  <h3 className={classes.title}>
                    {DRAWER_OBJECT[drawerType]?.heading}
                  </h3>
                </Grid>

                <Grid
                  justify="center"
                  alignItems="center"
                  container
                  className={
                    DRAWER_OBJECT[drawerType]?.heading !== "Designation"
                      ? classes.settings
                      : classes.settingsHidden
                  }
                >
                  <img src={roleSettingsIcon} alt="edit-icon" />
                </Grid>
                {DRAWER_OBJECT[drawerType]?.heading === "Designation" && (
                  <Grid xs={2}></Grid>
                )}
              </Grid>
              {DRAWER_OBJECT[drawerType]?.element}
            </Drawer>
          </Route>
          <Route path={`${path}/details/:id`} exact>
            <EmployeeDetails />
          </Route>
        </Switch>
      </BasicLayout>
    </Toast.Provider>
  );
};

export default EmployeesPage;
