import { ChangeEvent, useState } from "react";
import { getTeamMembersAPI } from "../../../apis/kra";
import { QUARTER_NAMES } from "../../../utils/constants";
import KRALayout from "../components/KRALayout";
import KRATable from "../components/KRATable";
import KRAHeader from "../KRAHeader";

const month = new Date().getMonth() + 1; //Jan is 1, Feb is 2
let quarter = Math.ceil(month / 3);

const currentQuarter = QUARTER_NAMES[quarter - 1];
const currentYear = new Date().getFullYear();

export interface IFilterData {
  year: number;
  quarter: string;
}

const AdminView = () => {
  const [searchText, setSearchText] = useState("");
  const [filterData, setFilterData] = useState<IFilterData>({
    year: currentYear,
    quarter: currentQuarter,
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleFilterData = (data: IFilterData) => {
    setFilterData(data);
  };

  return (
    <KRALayout>
      <KRAHeader
        searchText={searchText}
        handleChange={handleChange}
        filterData={filterData}
        handleFilterData={handleFilterData}
      />
      <KRATable searchText={searchText} filterData={filterData} />
    </KRALayout>
  );
};

export default AdminView;
