import React from "react";
import { MenuItem, IconButton, Menu } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {contentData} from "../../types";

const ITEM_HEIGHT = 48;

interface IProps {
  updateHandler: (itemValue: contentData) => void;
  deleteHandler: (itemValue: contentData)=> void;
  value:contentData;
}

const StatusMenu: React.FC<IProps> = ({ updateHandler,deleteHandler,value }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={(e)=>handleClick(e)}
        style={{ padding: 0 }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "10ch",
            backgroundColor: "#F6F9FF",
            boxShadow:
              "0px 12px 7px rgba(66, 66, 66, 0.03), 0px 5px 5px rgba(66, 66, 66, 0.04), 0px 1px 3px rgba(66, 66, 66, 0.05)",
          },
        }}
      >
        <MenuItem onClick={()=>updateHandler(value)}>Update</MenuItem>
        <MenuItem onClick={()=>deleteHandler(value)}>Delete</MenuItem>
      </Menu>
    </div>
  );
};
export default StatusMenu;
