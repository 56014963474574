import React, { useEffect, useState } from "react";
import { Grid, Paper, Box, Typography, Button } from "@material-ui/core";
import { useStyles } from "./styles";
import { Formik, FormikHelpers, FormikProps, Form, Field } from "formik";
import * as yup from "yup";
import { FormTextField } from "../FormtextField";
import FormValues from "../../models/bankDetailsType";
import { useLocation } from "react-router-dom";
import KeyValueWrapper from "../KeyValueWrapper";
import ValidationSchema from "./ValidationSchema";
import SnackbarComponent from "../SnackbarComponent";
import CircularProgress from "@material-ui/core/CircularProgress";
import { updateBankDetails } from "../../apis/employees";
import { employeeNormalDataUpdatePermission } from "../../utils/functions";
import { usePermissionObjectFor } from "../../hooks/permission/usePermissionObjectFor";

const BankDetailsValidationSchema = yup
  .object()
  .shape(ValidationSchema.BankDetailsValidationSchema);

interface Props {
  employeeId: string;
  bankDetailsInitialData: any;
  loaderStateProps?: boolean;
}

const BankDetailsEdit: React.FC<Props> = ({
  employeeId,
  bankDetailsInitialData,
  loaderStateProps = true,
}) => {
  const classes = useStyles();
  const [isEditableBasicDetails, setIsEditableBasicDetailsEdit] =
    useState(false);

  //eductaional dummy data
  const userDataProps = {
    bankName: "",
    accountNo: "",
    ifscCode: "",
  };

  const [bankDetailsData, setBankDetailsData] = useState(userDataProps);

  //state for snackbar
  const [snackbarState, setSnackbarState] = useState({
    openBooleanProp: false,
    messageProp: "dummy message state",
    statusProp: "info",
  });

 
  const permission = usePermissionObjectFor("employees");
  const employeeNormalDataUpdatePermission =permission.required([ "upDate","read"]);

  const setInitialData = () => {
    const initData = {
      bankName: bankDetailsInitialData?.bankName
        ? bankDetailsInitialData?.bankName
        : "",
      accountNo: bankDetailsInitialData?.accountNumber?.length
        ? bankDetailsInitialData?.accountNumber
        : "",
      ifscCode: bankDetailsInitialData?.ifsc
        ? bankDetailsInitialData?.ifsc
        : "",
    };

    // 
    setBankDetailsData(initData);
  };

  useEffect(() => {
    setInitialData();
  }, [bankDetailsInitialData]);

  const updateFormdata = async (data: FormValues) => {
    let dataToUpdate = {
      bankName: data.bankName,
      accountNumber: data.accountNo.toString(),
      ifsc: data.ifscCode,
      branchName: "NA",
    };

    try {
      await updateBankDetails(employeeId, dataToUpdate);
      setBankDetailsData(data);
      setIsEditableBasicDetailsEdit((previous) => !previous);

      //show toast
      setSnackbarState({
        openBooleanProp: true,
        messageProp: "successfully updated",
        statusProp: "success",
      });
    } catch (err: any) {
      //show toast
      setSnackbarState({
        openBooleanProp: true,
        messageProp: "Error : " + err,
        statusProp: "error",
      });
    }
  };

  const handleSubmit = (val: any) => {
    if (isEditableBasicDetails) {
      // 
      updateFormdata(val);
    }
  };

  return (
    <Box className={classes.basicDetailsViewMainContainer}>
      <SnackbarComponent snackbarStateProp={snackbarState} />
      {!loaderStateProps ? (
        <Box
          sx={{
            width: "100%",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Formik
          initialValues={bankDetailsData}
          enableReinitialize
          validationSchema={BankDetailsValidationSchema}
          onSubmit={(values, formikHelpers) => {
            // 
            formikHelpers.setSubmitting(false);
            handleSubmit(values);
          }}
        >
          {(formikProps: FormikProps<FormValues>) => (
            <Form noValidate autoComplete="off">
              <Grid container>
                <Box sx={{ width: "100%" }}>
                  <Grid container xs={12} className={classes.widthHundred}>
                    <Grid
                      container
                      item
                      xs={12}
                      className={classes.horizontalBoxContainerStyle}
                      style={{ paddingBlock: 16 }}
                    >
                      <KeyValueWrapper
                        label={"Bank Name"}
                        valueParam={formikProps.values.bankName}
                        name={"bankName"}
                        inputType={"textField"}
                        isEditable={isEditableBasicDetails}
                        xsProps={3}
                      />

                      <KeyValueWrapper
                        label={"Account No."}
                        valueParam={formikProps.values.accountNo}
                        name={"accountNo"}
                        inputType={"textField"}
                        valueTypeProps={"number"}
                        isEditable={isEditableBasicDetails}
                        xsProps={3}
                      />

                      <KeyValueWrapper
                        label={"IFSC Code"}
                        valueParam={formikProps.values.ifscCode}
                        name={"ifscCode"}
                        inputType={"textField"}
                        isEditable={isEditableBasicDetails}
                        xsProps={3}
                      />
                    </Grid>
                  </Grid>
                </Box>
                {employeeNormalDataUpdatePermission && (
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    sx={{ width: "100%" }}
                  >
                    {isEditableBasicDetails ? (
                      <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        // onClick={() => setIsEditableBasicDetailsEdit(false)}
                        disabled={formikProps.isSubmitting}
                      >
                        Save
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          // 
                          setIsEditableBasicDetailsEdit(true);
                        }}
                      >
                        Edit
                      </Button>
                    )}
                  </Box>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};

export default BankDetailsEdit;
