import { Leave } from "../types/leave";
const BASE_PATH = "/app";

export const dataLeaves: Leave[] = [
  {
    title: "PRIVILEDGE LEAVE",
    date: "22/05/2021 - 23/05/2021",
    status: "APPROVED",
  },
  {
    title: "SICK LEAVE",
    date: "22/05/2021 - 23/05/2021",
    status: "PENDING",
  },
  {
    title: "CASUAL LEAVE",
    date: "22/05/2021 - 23/05/2021",
    status: "REJECTED",
  },
  {
    title: "CASUAL LEAVE",
    date: "22/05/2021 - 23/05/2021",
    status: "CANCELLED",
  },
];

interface INavProperties {
  index: number;
  path: string;
}

interface INav {
  [key: string]: INavProperties;
}

export const Basic_Navs: INav = {
  dashboard: { index: 0, path: `${BASE_PATH}/dashboard` },
  // attendance: { index: 1, path: `${BASE_PATH}/attendance` },
  attendance: { index: 1, path: `${BASE_PATH}/attendance` },
  employees: { index: 2, path: `${BASE_PATH}/employees` },
  leaves: { index: 3, path: `${BASE_PATH}/leaves` },

  onboarding: { index: 4, path: `${BASE_PATH}/onboarding` },
  contentBasket: { index: 5, path: `${BASE_PATH}/contentBasket` },
  projects: { index: 6, path: `${BASE_PATH}/projects` },
  events: { index: 7, path: `${BASE_PATH}/events` },
  profile: { index: 8, path: `${BASE_PATH}/profile` },
  policies: { index: 9, path: `${BASE_PATH}/policies` },
  knowledgeHub: { index: 10, path: `${BASE_PATH}/knowledgeHub` },

  kra: { index: 11, path: `${BASE_PATH}/kra` },
  roles: { index: 12, path: `${BASE_PATH}/roles` },
  // performance: { index: 10, path: `${BASE_PATH}/performance` },
  logout: { index: 13, path: `${BASE_PATH}/signin` },
};

export const QUARTER_NAMES = ["Q1", "Q2", "Q3", "Q4"];

export const PERSISTENT_AUTHENTICATION = "tvcpsess";
export const PERSISTENT_PERMISSIONS = "tvpermissions";
export const PERSISTENTNEW_PERMISSIONS = "newCombinePermission";

export const calendarLegends = [
  {
    name: "Casual Leave",
    color: "#F3D90C",
  },
  {
    name: "Priviledge Leave",
    color: "#56F30C",
  },
  {
    name: "Sick Leave",
    color: "#F3890C",
  },
  {
    name: "Half day Leave",
    color: "#0CF3F3",
  },
  {
    name: "Holiday",
    color: "#D30CF3",
  },
];

export const UPLOAD_DOCUMENT_SIZE = 1048576;

export const phoneRegex = /^[6-9]\d{9}$/;
export const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/gm;
export const PINregex = /^(\d{6})$/;
export const voterIDregex = /^([a-zA-Z]){3}([0-9]){7}?$/;
export const adharRegex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
export const panRegex = /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/;
// export const passportRegex = /^[A-Z][0-9]{8}$/;
export const passportRegex = /^[A-Za-z][0-9]{7,8}$/;
export const bankAccountNoRegex = /^\s*-?[0-9]{9,18}\s*$/;
export const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
export const lettersRegex = /^[a-zA-Z ]*$/;
export const dobFormatRegex =
  /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/;
export const birthYearRegex = /^(?=\d{4}$)(17|18|19|20)\d+/;

export const convertBase64 = (file: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const filterEmployeePageTableDataValue = {
  name: "name",
  active: "active",
  inActive: "inActive",
  allEmployees: "allEmployees",
};

/**
 * TODO: CHANGE THESES VALUES ACCORDING TO THE NEW DOCUMENT IDS, IF THE BACKEND COLLECTION EVER GETS DROPPED
 */
export enum LEAVE_TYPES_VALUES {
  sickLeave = "61c45721a1419efe2fe52e3b",
  casualLeave = "61c45719a1419efe2fe52e39",
  privilegeLeave = "61c456dea1419efe2fe52e37",
}

export enum MODULES {
  ONBOARDING = "on-boarding",
  EMPLOYEES = "employees",
}
