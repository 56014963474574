import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import useDebounce from "../../../../../hooks/useDebounce";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Typography } from "@material-ui/core";
import {
  developerDetails,
  projectDetails,
  updateResources,
} from "../../../../../apis/projects";
import Button from "@material-ui/core/Button";
import { useLocation } from "react-router-dom";
import { Toast } from "../../../ProjectsPage";
import { useStyles } from "../style";
import {developers, Resource, selectedDevelopers} from "../../../Page/ProjectDetailPage/types";
import { usePermissionObjectFor } from "../../../../../hooks/permission/usePermissionObjectFor";


const checkedIcon = <CheckBoxIcon fontSize="small" />;
interface Iprops { 
}


const ResourceTabs: React.FC<Iprops> = ({

}) => {
  const classes = useStyles();
  const [value, setValue] = useState<string>("");
  const location = useLocation() as any;
  const _id = location.state._id as string;
  const [devResources, setDevResources] = useState<Resource[]>([]);
  const showToast = useContext(Toast);
  const [updated, setUpdated] = useState<boolean>(true);
  const [selectedResources, setSelectedResources] = useState<selectedDevelopers>({
    developers:[]
  });
  const [resources, setResources] = useState<Resource[]>([]);
 const permissions = usePermissionObjectFor("projects");
 const hasUpdateAccess = permissions.required("upDate");

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const debouncedValue = useDebounce<string>(value, 500);

  useEffect(() => {
    fetchDeveloper();
    fetchResources();
  }, [debouncedValue]);

  async function fetchDeveloper() {
    if (debouncedValue) {
      try {
        let { data, error } = await developerDetails(debouncedValue);
        if (error) {
          showToast.error(error.message);
        }
        if (data) {
          let tempItem: { email: string; id: string; name: string }[] = [];
          data.data.forEach(({ email, id, name }: Resource) => {
            let tempObjUser = {
              email: email,
              id: id,
              name: name,
            };
            tempItem.push(tempObjUser);
          });
          setDevResources(tempItem);
        }
      } catch (error: any) {
        showToast.error(error.response.data.message);
      }
    }
  }

  async function fetchResources(){
    try{
      let res = await projectDetails(_id);
      let tempItem: { email: string; id: string; name: string }[] = [];
      res?.data.developers.forEach(
        ({ _id, basicDetails }: developers) => {
          let tempObjUser = {
            id: _id ?? "N/A",
            email: basicDetails.email ?? "N/A",
            name: basicDetails.fullName ?? "N/A",
          };
          tempItem.push(tempObjUser);
        }
      );
      setResources(tempItem);
    }
    catch(err){}
  }

  const resourceHandler = async () => {
    setUpdated(true);
    try {
      await updateResources(_id, selectedResources);
      showToast.success("Updated Successfully");
      fetchResources();
      ;
    } catch (error: any) {
      showToast.error(error.response.data.message);
    }
  };

  return (
    <div className={classes.resourceTabs}>
      <Typography className={classes.title}>Email ID</Typography>
      <Autocomplete
        key={resources.length}
        multiple
        disabled={!hasUpdateAccess}
        autoHighlight={false}
        loading={value === ""? false: true}
        id="resources"
        fullWidth
        defaultValue={resources}
        options={devResources}
        onInputChange={(e, newInputValue) => setValue(newInputValue)}
        disableCloseOnSelect
        getOptionLabel={(option: any) => option.email}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={(e, value) => {
          setSelectedResources({
            developers: value,
          });
          setUpdated(false);
        }}
        renderOption={(option, { selected }) => (
          <>
            <Checkbox
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
              onChange={handleChange}
              color="primary"
            />
            {option.email}
          </>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            variant="outlined"
            label="Email ID"
            placeholder="email Id"
          />
        )}
      />
      <Button
        variant="contained"
        color="default"
        className={classes.donebutton}
        onClick={resourceHandler}
        disabled={
          !hasUpdateAccess ||
          selectedResources.developers === undefined ||
          updated
        }
      >
        Done
      </Button>
    </div>
  );
};
export default ResourceTabs;
