import { SvgIcon } from "@material-ui/core";
import React from "react";

const SearchIcon: React.FC = () => {
    return (
        <SvgIcon viewBox="-5 -5 24 24">
            <path d="M11.0207 10.0767L13.876 12.9314L12.9327 13.8747L10.078 11.0194C9.01587 11.8708 7.69471 12.334 6.33337 12.332C3.02137 12.332 0.333374 9.64403 0.333374 6.33203C0.333374 3.02003 3.02137 0.332031 6.33337 0.332031C9.64537 0.332031 12.3334 3.02003 12.3334 6.33203C12.3353 7.69337 11.8722 9.01452 11.0207 10.0767ZM9.68337 9.58203C10.5294 8.71196 11.002 7.54565 11 6.33203C11 3.75336 8.91137 1.66536 6.33337 1.66536C3.75471 1.66536 1.66671 3.75336 1.66671 6.33203C1.66671 8.91003 3.75471 10.9987 6.33337 10.9987C7.54699 11.0006 8.7133 10.5281 9.58337 9.68203L9.68337 9.58203Z" />
        </SvgIcon>
    );
};

export default SearchIcon;
