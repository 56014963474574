import axios from "./axios.global";

export const uploadPolicies = async (payload: object) => {
  try {
    let response = await axios.post("/policies", payload);
    return response;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getPolicies = async () => {
  try {
    let response = await axios.get("/policies");
    return response;
  } catch (err: any) {
    return err.response.data;
  }
};

export const deletePolicy = async (id: string) => {
  try {
    return await axios.delete(`policies/del/${id}`);
  } catch (err: any) {
    return err;
  }
};

export const downloadPolicy = async (name: string) => {
  try {
    return await axios.get(`/${name}`);
  } catch (err: any) {
    return err;
  }
};

export const acceptPolicies = async (id: string) => {
  try {
    return await axios.put(`acceptpolicy/${id}`);
  } catch (error: any) {
    return error;
  }
};
