import { SetterOrUpdater } from "recoil";

export const setDetailsData = (
  setBasicDetails: any,
  getBasicDetails: any,
  setAvatarImage: SetterOrUpdater<{
    profileImage: string;
  }>,
  basicDetails: any,
  setOnboardingIds: SetterOrUpdater<{
    eduId: string;
    workExp: string;
    FamLang: string;
    BankDetails: string;
    EmployeeInfo: string;
  }>
) => {
  setOnboardingIds((prev) => ({
    ...prev,
    eduId: getBasicDetails.id,
    FamLang: getBasicDetails.id,
    workExp: getBasicDetails.id,
  }));

  window.sessionStorage.setItem("onbId", getBasicDetails.id);
  if (getBasicDetails?.bloodGroup) {
    window.localStorage.setItem("bloodGrp", getBasicDetails?.bloodGroup);
  } else {
    window.localStorage.setItem("bloodGrp", "");
  }

  setBasicDetails({
    id: getBasicDetails.id,
    name: getBasicDetails.name,
    age: getBasicDetails?.age ? getBasicDetails?.age : "",
    gender: getBasicDetails?.gender ? getBasicDetails?.gender : "",
    married: getBasicDetails?.married ? getBasicDetails?.married : "",
    bloodGroup: getBasicDetails?.bloodGroup ? getBasicDetails?.bloodGroup : "",
    birthDate: getBasicDetails?.birthDate ? getBasicDetails?.birthDate : "",
    emailID: getBasicDetails.emailID,
    idProofs: {
      aadharNo: getBasicDetails?.idProofs?.aadharNo
        ? getBasicDetails?.idProofs?.aadharNo
        : "",
      panNo: getBasicDetails?.idProofs?.panNo
        ? getBasicDetails?.idProofs?.panNo
        : "",
      voterIdNo: getBasicDetails?.idProofs?.voterIdNo
        ? getBasicDetails?.idProofs?.voterIdNo
        : "",
      passportNo: getBasicDetails?.idProofs?.passportNo
        ? getBasicDetails?.idProofs?.passportNo
        : "",
    },
    mobileNo: getBasicDetails.mobileNo
      ? getBasicDetails.mobileNo.toString()
      : "",
    address: {
      present: {
        addressField: getBasicDetails?.address?.present?.addressField
          ? getBasicDetails?.address?.present?.addressField
          : "",
        city: getBasicDetails?.address?.present?.city
          ? getBasicDetails?.address?.present?.city
          : "",
        state: getBasicDetails?.address?.present?.state
          ? getBasicDetails?.address?.present?.state
          : "",
        pin: getBasicDetails?.address?.present?.pin
          ? getBasicDetails?.address?.present?.pin
          : "",
      },
      permanent: {
        addressField: getBasicDetails?.address?.permanent?.addressField
          ? getBasicDetails?.address?.permanent?.addressField
          : "",
        city: getBasicDetails?.address?.permanent?.city
          ? getBasicDetails?.address?.permanent?.city
          : "",
        state: getBasicDetails?.address?.permanent?.state
          ? getBasicDetails?.address?.permanent?.state
          : "",
        pin: getBasicDetails?.address?.permanent?.pin
          ? getBasicDetails?.address?.permanent?.pin
          : "",
      },
    },
    isPermanentAddressDifferent: getBasicDetails.isPermanentAddressDifferent
      ? getBasicDetails.isPermanentAddressDifferent
      : basicDetails.isPermanentAddressDifferent,
    emergencyContact: {
      name: getBasicDetails?.emergencyContact?.name
        ? getBasicDetails?.emergencyContact?.name
        : "",
      relationship: getBasicDetails?.emergencyContact?.relationship
        ? getBasicDetails?.emergencyContact?.relationship
        : "",
      contactNo: getBasicDetails?.emergencyContact?.contactNo
        ? getBasicDetails?.emergencyContact?.contactNo
        : "",
    },
  });

  // setAvatarImage({
  //   profileImage: getBasicDetails?.profileImage?.url
  //     ? getBasicDetails?.profileImage?.url
  //     : "",
  // });
};
