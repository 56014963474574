import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useFormik } from "formik";
import BasicLayout from "../../components/BasicLayout";
import * as Yup from "yup";
import { useBasicNav } from "../../utils/useBasicNav";
// import { getUsers } from "../../apis/users";
import { API_URL } from "../../config";
import Button from "../../components/CustomButton";
// import { getRoles } from "../../apis/roles";
// import {
//   getDesignation,
//   getDepartment,
// } from "../../apis/designations_departments";
import { createUser, editUser } from "../../apis/users";
import backIcon from "../../assets/icons/back.svg";
import { useHistory, useLocation } from "react-router";
import EmployeesForm from "../../components/Forms/EmployeesAddForm";
import { useStyles } from "./styles";

interface IProps {
  loadPrimary: (event: any) => void;
  showToast?: any;
}

interface IAssetList {
  assetName?: string;
  assetId?: string;
  history?: object;
}

/**
 * @description Functional component which renders the "Employee Add" form
 * @param {any} loadPrimary
 * @param {any} showToast
 * @returns a node
 */
const EmployeesAddPage: React.FC<IProps> = ({ loadPrimary, showToast }) => {
  const history = useHistory();
  const location: any = useLocation();
  const [submitting, setSubmitting] = useState(false);
  loadPrimary(false);
  useBasicNav("employees");
  const formValues = location?.state?.formValues;
  const designations = location?.state?.designations;
  const assignedUsers = location?.state?.users;
  const ownershipIdList = location?.state?.ownership;
  const currentUserId = location?.state?.currentUserId;
  const departments = location?.state?.departments;
  const roles = location?.state?.roles;
  

  const classes = useStyles();
  const [initialValues, setInitialValues] = useState<any>({
    fullname: "",
    empNo: "",
    gender: "",
    contactNo: "",
    officialEmailId: "",
    presentAddress: "",
    fatherName: "",
    workExperience: "",
    assignedTo: [],
    role: "",
    emergencyContactName: "",
    emergencyContactNo: "",
    emergencyContactRelation: "",
    adharCardNo: "",
    bankName: "",
    ifscCode: "",
    dateOfBirth: "",
    dateOfJoining: "",
    designation: "",
    qualification: "",
    personalEmailId: "",
    permanentAddress: "",
    motherName: "",
    bloodGroup: "",
    maritalStatus: "",
    spouseName: "",
    marriageDate: "",
    panCardNo: "",
    passportNo: "",
    accountNo: "",
    empStatus: "",
    department: "",
  });

  const UserSchema = Yup.object().shape({
    fullname: Yup.string().required("required"),
    empNo: Yup.string().required("required"),
    gender: Yup.string().required("required"),
    contactNo: Yup.string()
      .required("required")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      )
      .min(10, "to short")
      .max(10, "to long"),
    officialEmailId: Yup.string()
      .required("required")
      .matches(
        /\b(@techvariable.com)\b/,
        "only techvariable.com extension is allowed"
      ),
    presentAddress: Yup.string().required("required"),
    fatherName: Yup.string().required("required"),
    workExperience: Yup.string().required("required"),
    role: Yup.string().required("required"),
    emergencyContactName: Yup.string().required("required"),
    emergencyContactNo: Yup.string().required("required"),
    emergencyContactRelation: Yup.string().required("required"),
    adharCardNo: Yup.string().required("required"),
    bankName: Yup.string().required("required"),
    ifscCode: Yup.string().required("required"),
    dateOfBirth: Yup.string().required("required"),
    dateOfJoining: Yup.string().required("required"),
    designation: Yup.string().required("required"),
    qualification: Yup.string().required("required"),
    personalEmailId: Yup.string().required("required"),
    permanentAddress: Yup.string().required("required"),
    motherName: Yup.string().required("required"),
    bloodGroup: Yup.string().required("required"),
    maritalStatus: Yup.string().required("required"),
    panCardNo: Yup.string().required("required"),
    passportNo: Yup.string().required("required"),
    accountNo: Yup.string().required("required"),
    empStatus: Yup.string().required("required"),
    department: Yup.string().required("required"),
    assignedTo: Yup.array().min(1, "Assingees are required"),
  });

  const [assetList, setAssetList] = useState<[IAssetList]>([
    { assetName: "", assetId: "" },
  ]);

  const [profileImage, setProfileImage] = useState<any>("");
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const [assignedToArr, setAssignedToArr] = useState<any>([]);

  useEffect(() => {
    if (formValues) {
      setInitialValues(formValues);
      setAssetList(formValues.assets);
      //setUploadedFiles(formValues.attachment)
      let tempAttachment: object[] = [];
      formValues.attachment.forEach((item: any) => {
        let fileName = item?.path?.split("-")[item.path.length - 1];
        
        tempAttachment.push({
          name: item.path,
          path: item.path,
          isNotFile: true,
        });
      });
      (async () => {
        // let response = await fetch(`${API_URL}${formValues?.profilePic}`);
        // let data = await response.blob();
        // let metadata = {
        //   type: "image/jpeg",
        // };
        // let file = new File([data], "test.jpg", metadata);
        setProfileImage(
          `${API_URL}${formValues?.profilePic?.split(" ").join("%20")}`
        );
      })();

      setUploadedFiles([...tempAttachment]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    // setFieldValue,
    touched,
    handleBlur,
    resetForm,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: UserSchema,

    onSubmit: ({ ...values }) => {
      
      delete values.assets;
      delete values.attachment;
      delete values.profilePic;
      let formData: any = new FormData();

      Object.keys(values).map((key) => formData.append(key, values[key]));
      formData.append("assets", JSON.stringify(assetList));
      if (profileImage.path) {
        formData.append(`profilePic`, profileImage);
      }

      for (let i = 0; i < uploadedFiles.length; i++) {
        
        if (!uploadedFiles[i].isNotFile) {
          formData.append(`attachment`, uploadedFiles[i]);
        }
      }

      for (var pair of formData.entries()) {
        
      }
      setSubmitting(true);
      if (formValues) {
        editUser(formData, formValues._id)
          .then((response) => {
            
            if (response?.status === 200) {
              showToast.success("User edited");
            } else {
              showToast.error("Something went wrong");
            }

            setSubmitting(false);
          })
          .catch((err) => {
            
            setSubmitting(false);
          });
      } else {
        createUser(formData)
          .then((response) => {
            
            if (response?.status === 201) {
              showToast.success("User created");
            } else {
              showToast.error("Something went wrong");
            }
            setSubmitting(false);
          })
          .catch((err) => {
            
            setSubmitting(false);
          });
      }
    },

    validate: (values) => {
      let errors = {};
      // if (!values.original_file && !isDatabase) {
      //   errors.original_file = 'Required';
      // }
      // if (!values.title) {
      //   errors.title = 'Required';
      // }
      // if (!values.database_url && isDatabase) {
      //   errors.database_url = 'Required';
      // }

      return errors;
    },
  });

  const handleInputAssetChange = (e: any, index: number) => {
    const { name, value } = e.target;
    const list: [IAssetList] = [...assetList];
    list.splice(index, 1, { ...list[index], [name]: value });
    // list[index][name] = value;
    setAssetList(list);
  };

  const handleAddField = () => {
    assetList.push({ assetName: "", assetId: "" });
    
    setAssetList([...assetList]);
  };

  const handleDiscard = () => {
    resetForm({ values: "" });
    setProfileImage("");
    setUploadedFiles([]);
    setAssetList([{ assetName: "", assetId: "" }]);
    setInitialValues({
      fullname: "",
      empNo: "",
      gender: "",
      contactNo: "",
      officialEmailId: "",
      presentAddress: "",
      fatherName: "",
      workExperience: "",
      assignedTo: [],
      role: "",
      emergencyContactName: "",
      emergencyContactNo: "",
      emergencyContactRelation: "",
      adharCardNo: "",
      bankName: "",
      ifscCode: "",
      dateOfBirth: "",
      dateOfJoining: "",
      designation: "",
      qualification: "",
      personalEmailId: "",
      permanentAddress: "",
      motherName: "",
      bloodGroup: "",
      maritalStatus: "",
      spouseName: "",
      marriageDate: "",
      panCardNo: "",
      passportNo: "",
      accountNo: "",
      empStatus: "",
      department: "",
    });
  };
  

  return (
    <BasicLayout>
      <Grid direction="column" container className={classes.mainContainer}>
        <Grid
          alignItems="center"
          justify="space-between"
          container
          className={classes.top}
        >
          <Grid
            onClick={() => history.goBack()}
            className={classes.backContainer}
            alignItems="center"
            xs={2}
            container
          >
            <img src={backIcon} alt="back-icon" />
            <p className={classes.back}>Back</p>
          </Grid>
          <Grid xs={5} justify="flex-end" container>
            <Grid>
              <Button
                label="Save"
                isDisabled={submitting}
                isBordered={false}
                startIcon=""
                endIcon=""
                isOutlined={true}
                onButtonClick={handleSubmit}
                color="primary"
              />
            </Grid>

            <Grid className={classes.buttonSave}>
              <Button
                label="Discard"
                isDisabled={false}
                isBordered={false}
                startIcon=""
                endIcon=""
                isOutlined={false}
                onButtonClick={handleDiscard}
                color="primary"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.bottom}>
          <EmployeesForm
            handleSubmit={handleSubmit}
            handleInputAssetChange={handleInputAssetChange}
            handleAddField={handleAddField}
            handleChange={handleChange}
            roles={roles}
            userId={formValues?._id}
            designations={designations}
            departments={departments}
            profileImage={profileImage}
            setUploadedFiles={setUploadedFiles}
            uploadedFiles={uploadedFiles}
            setProfileImage={setProfileImage}
            assetList={assetList}
            assignedToArr={assignedToArr}
            setAssignedToArr={setAssignedToArr}
            errors={errors}
            values={values}
            handleBlur={handleBlur}
            touched={touched}
            showToast={showToast}
            assignedToUsers={assignedUsers}
            ownershipList={ownershipIdList}
            currentUserId={currentUserId}
          />
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

export default EmployeesAddPage;
