// import { Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.textColor.white,
    padding: "15px 41px",
    borderRadius: "10px",
    height: "calc(100vh - 3rem)",
    marginLeft: "1rem",
    overflow: "scroll",
  },
  subtitle: {
    fontSize: "12px",
    color: "#686868",
    paddingBottom: "20px",
    fontWeight: 400,
  },
  imageGroup: {
    display: "inline-block",
    position: "relative",
    cursor: "pointer",
  },
  closeImage: {
    position: "absolute",
    top: 0,
    right: 0,
    // display: "none",
  },
  successButton: {
    borderColor: "green",
    color: "green",
    cursor: "default",
  },
  tableContainer: {
    paddingTop: theme.typography.pxToRem(20),
  },
  fileName: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "5rem",
  },
  approvedIcon: {
    color: "green",
  },
  rejectedIcon: {
    color: "red",
  },
  pendingIcon: {
    color: "#FF9F45",
  },
}));
