import { Grid, InputLabel, Paper, Typography } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  checkAcademicDocumentSelectionAtom,
  isUploadsViewEnabled,
  recruiteeBasicDetails
} from "../../../../../recoil/atoms";
import { UploadsContext } from "../../../../../store/uploadsStore";

import { useDropzone } from "react-dropzone";
import { getDocuments } from "../../../../../apis/onboarding";
import uploadDocument from "../../../../../assets/icons/uploadDocument.svg";
import { documentDownloadAtom } from "../../../../../recoil/downloadAtoms";
import { HandleDroppedAcademics } from "../HandleDroppedFiles/HandleDroppedAcademics";
import { useStyles } from "../styles";
import UploadedDocuments from "../UploadedDocuments";
import { IAcademicChildrenProps } from "./AcademicCertificates";

const Graduation = ({
  handleChange,
  setIsSubmit,
  showToast,
  getEndpoint,
  imgName,
  imgSize,
  setImgName,
  setImgSize,
}: IAcademicChildrenProps) => {
  const [, setIsFileSelected] = useRecoilState(
    checkAcademicDocumentSelectionAtom
  );
  const [, setRewriteFileDownload] = useRecoilState(documentDownloadAtom);
  const { academicProgress, academicProofs, setAcademicProof } =
    useContext(UploadsContext);
  const classes = useStyles();
  const mainUrl = process.env.REACT_APP_BACKEND_URL;
  const basicDetails = useRecoilValue(recruiteeBasicDetails);
  const [isViewEnabled, setIsViewEnabled] =
    useRecoilState(isUploadsViewEnabled);
  const [isDisable, setIsDisable] = useState<boolean>(false);

  const url = mainUrl + getEndpoint;
  const parentKey = "academicCertificates";
  const childKey = "graduation";

  const getDocs = async () => {
    const response = await getDocuments(url, parentKey, childKey);
    if (response.status === 200 && response.data !== "") {
      setImgName((prev) => ({
        ...prev,
        graduation: `Graduation Marksheet of ${basicDetails.name}`,
      }));
      setImgSize((prev) => ({
        ...prev,
        graduation: 0,
      }));
      setIsViewEnabled((prev) => ({
        ...prev,
        academic: true,
      }));
      setIsSubmit((prev: any) => ({
        ...prev,
        grad: true,
      }));
      setAcademicProof((prev) => [
        ...prev,
        {
          name: "graduation",
          value: response.data,
        },
      ]);
    } else {
      setIsViewEnabled((prev) => ({
        ...prev,
        academic: false,
      }));
    }
  };

  useEffect(() => {
    getDocs();
  }, []);

  useEffect(() => {
    const length = academicProofs.length;
    if (length > 0) {
      for (let i = 0; i < length; i++) {
        if (
          academicProofs[i].name === "graduation" &&
          imgName.graduation !== ""
        ) {
          setIsDisable(true);
          break;
        }
        if (imgName.graduation === "") {
          setIsDisable(false);
        }
      }
    }
  }, [academicProofs, imgName]);

  const { getRootProps: getRootPropsGrad, getInputProps: getInputPropsGrad } =
    useDropzone({
      onDrop: (acceptedFilesGrad) => {
        const file = acceptedFilesGrad[0];
        const docType = "Graduation";

        if (!isDisable) {
          HandleDroppedAcademics({
            docType,
            file,
            academicProofs,
            setAcademicProof,
            setIsFileSelected,
            setImgName,
            setImgSize,
            setRewriteFileDownload,
            setIsSubmit,
            showToast,
          });
        }
      },
      noClick: true,
    });

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      className={classes.docContainer}
    >
      <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
        <Paper
          variant="outlined"
          elevation={4}
          className={classes.paperContainer}
        >
          <InputLabel variant="outlined" className={classes.label} shrink>
            <b className={classes.labelColor}>Graduation Certificate</b>
          </InputLabel>

          <Grid container direction="column" className={classes.dropContainer}>
            <Grid item>
              <Grid
                container
                direction="column"
                spacing={2}
                className={classes.dashedContainer}
                {...getRootPropsGrad()}
              >
                <input {...getInputPropsGrad()} />
                <Grid item className={classes.itemStyle}>
                  <Typography className={classes.boldText}>
                    Drag and Drop files to upload
                  </Typography>
                </Grid>
                <Grid item className={classes.itemStyle}>
                  <Typography className={classes.instruction}>
                    Maximum upload size 1MB
                  </Typography>
                </Grid>

                <Grid item className={classes.itemStyle}>
                  <input
                    type="file"
                    name="graduation"
                    id="graduation"
                    className={classes.inputfile}
                    onChange={(event) => handleChange(event)}
                    onClick={(event) => {
                      //@ts-ignore
                      event.target.value = null;
                    }}
                    disabled={isDisable}
                  />
                  <label htmlFor="graduation" className={classes.inputlabel}>
                    <i className={classes.inputButtonIcon}>
                      <img src={uploadDocument} />
                    </i>
                    &nbsp; Upload File
                  </label>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className={classes.itemStyle}
            >
              {imgName.graduation !== "" ? (
                <UploadedDocuments
                  progress={academicProgress}
                  imageName={imgName.graduation}
                  imageSize={imgSize.graduation}
                  label="graduation"
                  getEndpoint={getEndpoint}
                  setImgName={setImgName}
                />
              ) : (
                <Typography className={classes.instruction}>
                  No files selected
                </Typography>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Graduation;
