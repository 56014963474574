import { useCallback, useState } from "react";
import { useRecoilState } from "recoil";
import { saveDocument } from "../../apis/onboarding";
import { documentDownloadAtom } from "../../recoil/downloadAtoms";
import { Toast } from "../../store/uploadsStore";

export type File = {
  name: string;
  value: Blob | string;
};

export const useWorkExperience = () => {
  const [experienceProof, setExperienceProof] = useState<File[]>([]);
  const [workExperienceProgress, setWorkExperienceProgress] =
    useState<number>(0);
  const [, setRewriteFileDownload] = useRecoilState(documentDownloadAtom);

  const mainUrl = process.env.REACT_APP_BACKEND_URL;

  const handlePostWorkExperience = useCallback(
    async (showToast: Toast, uploadDocsEndpointPatch: string) => {
      const formData = new FormData();

      experienceProof.forEach((file) => {
        formData.append(file.name, file.value);
      });

      const url = mainUrl + uploadDocsEndpointPatch;
      const response: any = await saveDocument(url, formData);
      if (response.status === 200) {
        showToast.success("Documents Saved!");
        setRewriteFileDownload((prev) => ({
          ...prev,
          reliving: false,
          lastSalSlip: false,
          secondLastSalSlip: false,
          thirdLastSalSlip: false,
        }));
      } else {
        showToast.error("Something Went Wrong!");
      }
    },
    [experienceProof]
  );

  return {
    experienceProof,
    setExperienceProof,
    handlePostWorkExperience,
    workExperienceProgress,
    setWorkExperienceProgress,
  };
};
