import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    coloredBar: {
        width: '7px',
        height: '2px',
        marginRight: '2px',
        borderRadius: '10px'
    }
}))
