import { Button, Grid } from "@material-ui/core";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
  getPassportPhotoAPI,
  getRecruiteeInfo,
  handleBasicDetailsFormSubmit,
} from "../../../../../apis/onboarding";
import {
  onboardingIdAtom,
  profileAvatarAtom,
  recruiteeBasicDetails,
  recruiteeDetailsViewEnabled,
  recruiteeEditEnabled,
} from "../../../../../recoil/atoms";
import { birthYearRegex } from "../../../../../utils/constants";
import DetailsEdit from "./DetailsEdit";
import { setFormikValues } from "./setFormikValues";
import { useStyles } from "./styles";
import { setDetailsData } from "./utils";
import { validationSchema } from "./validationSchema";
import ViewDetails from "./ViewDetails";

interface DetailsProps {
  showToast: any;
  index: number;
}

const Details: React.FC<DetailsProps> = ({ showToast }) => {
  const classes = useStyles();
  const [basicDetails, setBasicDetails] = useRecoilState(recruiteeBasicDetails);
  const [isViewEnabled, setIsViewEnabled] = useRecoilState(
    recruiteeDetailsViewEnabled
  );
  const [toggleAge, setToggleAge] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useRecoilState(recruiteeEditEnabled);
  const [avatarImage, setAvatarImage] = useRecoilState(profileAvatarAtom);
  const [onboardingIds, setOnboardingIds] = useRecoilState(onboardingIdAtom);
  const [edited, setEdited] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [sessionUserRole, setSessionUserRole] = useState<string>("");
  const [accessToken, setAccessToken] = useState<string>("");
  const [toggleUser, setToggleUser] = useState<boolean>(false);
  const [enableLoader, setEnableLoader] = useState<boolean>(false);

  useEffect(() => {
    const userRole = window.sessionStorage.getItem("userRole") as string;
    const access_token = window.sessionStorage.getItem(
      "access_token"
    ) as string;
    setSessionUserRole(userRole);
    setAccessToken(access_token);
  }, [toggleUser]);

  useEffect(() => {
    getAge();
  }, [toggleAge]);

  const submitData = async () => {
    try {
      const res = await handleBasicDetailsFormSubmit(formik.values);
      if (res?.status == 403) {
        showToast.error("Something went wrong!");
        return;
      }
      if (res?.status == 200) {
        showToast.success("BASIC DETAILS SAVED");

        setBasicDetails((prevState) => ({
          ...prevState,
          id: res.data._id,
        }));
        setIsViewEnabled((prevState) => ({
          ...prevState,
          basicDetailsViewEnabled: true,
        }));

        // if (avatarImage.profileImage !== "") {
        //   window.localStorage.setItem("age", "0");
        //   window.localStorage.setItem("bloodGrp", "");

        // } else {
        //   showToast.error("COULDN'T UPLOAD IMAGE!");
        // }

        return;
      } else {
        showToast.error("Invalid field entered!");
      }
    } catch (err: any) {
      setToggleUser(!toggleUser);
      showToast.error(`Error ${err.message}`);
      return;
    }
  };

  const getAge = () => {
    const today = new Date();

    const birthdate = new Date(formik.values.birthDate);
    const age = today.getFullYear() - birthdate.getFullYear();
    if (age == 0) {
      setFieldValue("age", "0");
    } else {
      setFieldValue("age", age.toString());
    }
  };

  const getBasicDetailsFunc = async () => {
    setEnableLoader(false);
    if (
      basicDetails.id === undefined ||
      basicDetails.id === null ||
      basicDetails.id === ""
    ) {
      return;
    } else {
      const getBasicDetails = await getRecruiteeInfo(basicDetails.id);
      // if (
      //   getBasicDetails?.profileImage?.url !== "" &&
      //   getBasicDetails?.profileImage?.url !== undefined
      // ) {
      //   setAvatarImage({ profileImage: getBasicDetails?.profileImage?.url });
      // } else {
      //   setAvatarImage({ profileImage: "" });
      // }

      if (getBasicDetails) {
        setDetailsData(
          setBasicDetails,
          getBasicDetails,
          setAvatarImage,
          basicDetails,
          setOnboardingIds
        );
      }
    }
  };

  useEffect(() => {
    setEnableLoader(true);
    getBasicDetailsFunc();
    setFormikFormData();
  }, [window.localStorage.getItem("onbId"), basicDetails.id]);

  const handleEdit = () => {
    setEdited(!edited);
    setIsEdit((prevState) => ({
      ...prevState,
      basicDetailsEdit: true,
    }));
    setIsViewEnabled((prev) => ({
      ...prev,
      basicDetailsViewEnabled: false,
    }));
  };

  const checkDobYear = () => {
    const result: boolean = birthYearRegex.test(
      formik.values.birthDate.split("-")[0]
    );
    return result;
  };

  const checkPermanentAddress = () => {
    if (!formik.values.isPermanentAddressDifferent) {
      setFieldValue(
        "address.permanent.addressField",
        formik.values.address.present.addressField
      );
      setFieldValue(
        "address.permanent.city",
        formik.values.address.present.city
      );
      setFieldValue(
        "address.permanent.state",
        formik.values.address.present.state
      );
      setFieldValue("address.permanent.pin", formik.values.address.present.pin);
    }
  };

  const { setFieldValue, ...formik } = useFormik({
    initialValues: basicDetails,
    validationSchema: validationSchema,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: () => {
      const checkDob: boolean = checkDobYear();
      checkPermanentAddress();

      // if (avatarImage.profileImage === "") {
      //   showToast.error("Please select a profile image!");
      //   setIsSubmitting(false);
      // }
      if (!checkDob) {
        showToast.error("Date of Birth is invalid!");
        setIsSubmitting(false);
      } else {
        setIsSubmitting(true);
        submitData();
        //
      }
    },
  });

  const setFormikFormData = () => {
    setFormikValues(basicDetails, setFieldValue);
  };

  useEffect(() => {
    if (formik.values.birthDate !== "") {
      setToggleAge(!toggleAge);
    }
  }, [formik.values.birthDate]);

  const getImageDetails = async (id: string) => {
    try {
      let response = await getPassportPhotoAPI(
        basicDetails.id,
        "photos",
        "passportPhoto"
      );
      let imageURL = response?.data;
      if (imageURL !== "") {
        setAvatarImage({ profileImage: imageURL });
      } else {
        setAvatarImage({ profileImage: "" });
      }
    } catch (error) {
      setAvatarImage({ profileImage: "" });
    }
  };

  useEffect(() => {
    getImageDetails(basicDetails.id);
  }, [basicDetails.id]);

  return (
    <Grid container className={classes.root}>
      <Grid item>
        {isViewEnabled.basicDetailsViewEnabled ? (
          <Grid container justifyContent="center">
            <ViewDetails formik={formik} />
          </Grid>
        ) : (
          <Grid container justifyContent="center">
            <DetailsEdit
              formik={formik}
              setFieldValue={setFieldValue}
              showToast={showToast}
            />
          </Grid>
        )}
        <Grid container justifyContent="flex-end" spacing={10}>
          <Grid item>
            {isViewEnabled.basicDetailsViewEnabled ? (
              <Button
                type="button"
                variant="contained"
                className={classes.saveButton}
                onClick={handleEdit}
              >
                Edit
              </Button>
            ) : (
              <form onSubmit={formik.handleSubmit}>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.saveButton}
                >
                  Update
                </Button>
              </form>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Details;
