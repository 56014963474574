import React, { useEffect } from "react";
import BasicLayout from "../../../components/BasicLayout";
import { useStyles } from "./styles";
import { Grid, Paper, Box, Typography } from "@material-ui/core";
import TabsComponent from "../../../components/TabsComponent";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import EmployeeDetailsTab1 from "./EmployeeDetailsTab1";
import { useHistory, useLocation, useParams } from "react-router-dom";
import employeeDetailsType from "../../../models/employeeDetailsType";
import axios from "axios";
import IconButton from "@material-ui/core/IconButton";
import UserDesignation from "./UserDesignation";
import { useBasicNav } from "../../../utils/useBasicNav";

const EmployeeDetails: React.FC = () => {
  useBasicNav("employees");
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* {console.log("rendering EmployeeDetails")} */}
      <Paper elevation={0}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container direction="row" className={classes.headerStyle}>
              <Grid item xs={12}>
                <Grid container direction="row" justifyContent="space-evenly">
                  <UserDesignation />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TabsComponent TabContent={() => <EmployeeDetailsTab1 />} />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default EmployeeDetails;
