import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  topTier: {
    display: "flex",
    justifyContent: "space-between",
    padding: " 1.5em 0.2em",
  },
  uploadButn: {
    border: "1px solid #14213D",
    borderRadius: "10px",
    color: "#14213D",
    fontSize: ".9em",
    background: "#EDF6F9",
    padding: ".7em 1em",
    height:"100%",
    "&:hover": {
      background: "#EDF6F9",
    },
  },
  downloadButn: {
    border: "1px solid #14213D",
    borderRadius: "10px",
    color: "#ffffff",
    fontSize: ".9em",
    background: "#140047",
    padding: ".7em 1em",
    "&:hover": {
      background: "#140047",
    },
  },
  root: {
    padding: "2em",
    display: "flex",
  },
  mainContainer: { padding: "2em", background: "white", borderRadius: "10px" },
  topBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginBottom: "1em",
  },
  secondDiv: {
    padding: "1.8em",
    marginTop: "1em",
    background: "white",
    borderRadius: "10px",
  },
  thirdDiv: {
    padding: "1.8em",
    marginTop: "1em",
    background: "white",
    borderRadius: "10px",
  },
  filterBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: ".7em",
    border: "1px solid rgba(20, 33, 61, 0.15)",
    borderRadius: "8px",
    cursor: "pointer",
  },
  filterHead: {
    fontSize: "1em",
    fontWeight: 400,
    paddingRight: ".6em",
    // paddingright:".3em !important"
  },
  popDiv: {
    position: "absolute",
    top: "55px",
    padding: "0.5em",
    background: "#F9F9F9",
    width: "100%",
    borderRadius: "10px",
    border: "1px solid rgba(20, 33, 61, 0.15)",
  },
  popDivDash: {
    position: "absolute",
    top: "55px",
    right: "10px",
    padding: "2em",
    background: "#F9F9F9",
    width: "400px",
    borderRadius: "10px",
    border: "1px solid rgba(20, 33, 61, 0.15)",
  },
  option: {
    fontSize: ".8em",
    fontWeight: 400,
    cursor: "pointer",
    marginTop: ".7em",
    // boxShadow: "1px 1px 1px 1px grey",
    "&:hover": {
      borderBottom: "1px solid black",
    },
  },
  search: {
    borderRadius: "2em !important",
    marginBottom: "1em",
    width: "100%",
  },
  filterHeadTitle: {
    fontSize: "1.1em",
    fontWeight: 500,
  },
  apply: {
    borderRadius: "5px",
    padding: ".5em 1.5em",
    marginTop: "1.5em",
  },
  autoSelect: {
    ["> .MuiInputBase-root "]: {
      borderRadius: "10px",
    },
  },
  input: {
    display: 'none',
    maxWidth:"fit-content",
    overflow:"hidden",
  },
}));
