import React from "react";
import {
  ThemeProvider,
  StylesProvider,
  createGenerateClassName,
} from "@material-ui/core/styles";
import { BrowserRouter as Router } from "react-router-dom";
import TvRouter from "./TvRouter";
//import ErrorBoundary from './components/utility/ErrorBoundary';
import { theme } from "./theme/theme";
import { RecoilRoot } from "recoil";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GOOGLE_REDIRECT_LINK } from "./config";

const generateClassName = createGenerateClassName({
  productionPrefix: "tv",
});

function App() {
  return (
    // <ErrorBoundary>
    <GoogleOAuthProvider clientId={`${GOOGLE_REDIRECT_LINK}`}>
      <RecoilRoot>
        <StylesProvider generateClassName={generateClassName}>
          <ThemeProvider theme={theme}>
            <Router>
              <TvRouter />
            </Router>
          </ThemeProvider>
        </StylesProvider>
      </RecoilRoot>
    </GoogleOAuthProvider>
    // </ErrorBoundary>
  );
}

export default App;

//hello frontend
