import BasicLayout from "../../components/BasicLayout";
import { useBasicNav } from "../../utils/useBasicNav";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import RoleList from "./RoleList";
import RolePermission from "./RolePermission";

interface IProps {
  loadPrimary: (event: boolean, basic?: boolean) => void;
  showToast: {
    error: (message: string) => void;
    success: (message: string) => void;
    warning: (message: string) => void;
  };
}

export default function RolesPage({ loadPrimary, showToast }: IProps) {
  useBasicNav("roles");
  const { path } = useRouteMatch();
  return (
    <BasicLayout>
      <Switch>
        <Route path={path} exact>
          <RoleList loadPrimary={loadPrimary} showToast={showToast} />
        </Route>
        <Route path={`${path}/:rid`} exact>
          <RolePermission showToast={showToast} />
        </Route>
      </Switch>
    </BasicLayout>
  );
}
