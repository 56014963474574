import { SetterOrUpdater } from "recoil";

interface setEducationDataProps {
  getEduDetails: any;
  setEducationForm: SetterOrUpdater<{
    class10: {
      institution: string;
      yearOfPassing: string;
      subjects: string;
      percentage: string;
    };
    class12: {
      institution: string;
      yearOfPassing: string;
      subjects: string;
      percentage: string;
    };
    graduation: {
      institution: string;
      yearOfPassing: string;
      subjects: string;
      percentage: string;
    };
    postGraduation: {
      institution: string;
      yearOfPassing: string;
      subjects: string;
      percentage: string;
    };
  }>;
}

export const setEducationData = ({
  getEduDetails,
  setEducationForm,
}: setEducationDataProps) => {
  setEducationForm({
    class10: {
      institution: getEduDetails?.class10?.institution,
      percentage: getEduDetails?.class10?.percentage,
      subjects: getEduDetails?.class10?.subjects,
      yearOfPassing: getEduDetails?.class10?.yearOfPassing,
    },
    class12: {
      institution: getEduDetails?.class12?.institution,
      percentage: getEduDetails?.class12?.percentage,
      subjects: getEduDetails?.class12?.subjects,
      yearOfPassing: getEduDetails?.class12?.yearOfPassing,
    },
    graduation: {
      institution: getEduDetails?.graduation?.institution,
      percentage: getEduDetails?.graduation?.percentage,
      subjects: getEduDetails?.graduation?.subjects,
      yearOfPassing: getEduDetails?.graduation?.yearOfPassing,
    },
    postGraduation: {
      institution: getEduDetails?.postGraduation?.institution,
      percentage: getEduDetails?.postGraduation?.percentage,
      subjects: getEduDetails?.postGraduation?.subjects,
      yearOfPassing: getEduDetails?.postGraduation?.yearOfPassing,
    },
  });
};
