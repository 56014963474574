import { makeStyles } from "@material-ui/core";
import { theme } from "../../../theme/theme";

export const useStyles = makeStyles({
  sideNav: {
    background: theme.palette.primary.main,
    width: theme.typography.pxToRem(233),
    height: `calc(100vh - ${theme.typography.pxToRem(55)})`,
    position: "fixed",
    display: "flex",
    paddingTop: theme.typography.pxToRem(37),
    paddingLeft: theme.typography.pxToRem(10),
    zIndex: 10,
    borderRadius: "10px",
    flexDirection: "column",
    overflowY: "scroll",
    scrollbarWidth: "thin",
  },
  navItems: {
    marginTop: theme.typography.pxToRem(40),
    lineHeight: theme.typography.pxToRem(14),
  },
  navItemsHide: {
    display: "none !important",
  },
  navItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    padding: theme.typography.pxToRem(12),
    marginBottom: theme.typography.pxToRem(7),
    cursor: "pointer",
    "& img": {
      width: theme.typography.pxToRem(16),
      marginRight: theme.typography.pxToRem(19),
      //height: theme.typography.pxToRem(40),
    },
    "& div": {
      color: "#DEF0F2",
    },
  },
  navActive: {
    borderRadius: "5px",
    background: "#fff",
    width: "100%",
    marginRight: theme.typography.pxToRem(31),
    "& div": {
      color: theme.palette.primary.main,
    },
  },

  logoClass: {
    height: theme.typography.pxToRem(38),
    width: theme.typography.pxToRem(135),
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    // paddingBottom: theme.typography.pxToRem(20),
  },
  navContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  rightBorder: {
    padding: theme.typography.pxToRem(2),
    marginBottom: theme.typography.pxToRem(7),
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
    cursor: "pointer",
    background: "#fff",
  },
  noBorder: {
    marginBottom: theme.typography.pxToRem(7),
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
    cursor: "pointer",
    background: "#fff",
  },

  text: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(12),
  },

  //submenu

  leaveroot: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  leave: {
    display: "flex",
    alignItems: "left",
    justifyContent: "center",
  },
  leavetext: {
    display: "flex",
    position: "relative",
    alignItems: "end",

    justifyContent: "center",
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(12),
  },
  leaveactive: {},
  leavenotactive: {
    display: "none",
  },
  dropDown: {
    display: "none",
    background: "black",
    top: "20.6rem",
    zIndex: 9,
    left: "0.8rem",
    borderRadius: theme.typography.pxToRem(10),
    color: "white",
    transition: "all .6s ease-in-out",
    height: "7rem",
  },
  dropDownActive: {
    position: "relative",
    top: "-0.4rem",
    background: theme.palette.primary.main,
    minwidth: "50%",
    maxWidth: "80.5%",
    // border:"1px solid silver",
    zIndex: 9,
    borderRadius: theme.typography.pxToRem(10),
    color: "white",
    transition: "all .6s ease-in-out",
    height: "7rem",
  },
  dropDownContent: {
    position: "relative",
    left: "1rem",
    minWidth: "fit-content",
    maxWidth: "fit-content",
    display: "flex",
    justifyContent: "space-between",
  },
  options: {
    position: "relative",
    top: "1.5rem",
    marginLeft: theme.typography.pxToRem(5),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "3.7rem",
  },
  optionsIcon: {
    height: "5rem",
  },
  applyLeave: {
    position: "relative",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "10px",
    lineHeight: "15px",
    color: "white",
  },
  report: {
    position: "relative",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "10px",
    lineHeight: "15px",
    color: "#E3E3E3B2",
  },
  activeLink: {
    textDecoration: "none",
    color: "white",
  },

  notActiveLink: {
    textDecoration: "none",
    color: "#E3E3E3B2",
  },

  tempLogoutRoot: {
    cursor: "pointer",
    transitionDuration: "0.5s",
    "&:hover": {
      backgroundColor: "#39256b",
    },
  },
  tempLogoutLink: {
    paddingTop: "1em",
  },
  tempLogoutText: {
    color: "#d6d6d6",
    transitionDuration: "1s",
    "&:hover": {
      color: "#fff",
    },
  },

  //attendance

  attendancecontainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  attendancetab: {
    display: "flex",
    alignItems: "left",
    justifyContent: "center",
  },
  attendancetext: {
    display: "flex",
    position: "relative",
    alignItems: "end",
    justifyContent: "center",
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(12),
  },
  attendanceactive: {},
  attendancenotactive: {
    display: "none",
  },

});
