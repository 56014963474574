import { makeStyles } from "@material-ui/core/styles";

export const kraStyles = makeStyles(() => ({
    kraHeader: {
        // border: "1px solid red",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    table: {
        minWidth: 700,
        borderRadius: "10px",
    },
    stepper: {
        "&.MuiStepper-root": {
            padding: 0,
            width: "45em"
        },
    },
    stepLabel: {
        "& .MuiSvgIcon-root": {
            height: "1.5em",
            width: "1.5em"
        }
    },
    spanError: {
        color: "red",
        fontSize: ".625em",
        fontStyle: "italic",
    }

}))