import React from "react";
import { useStyles } from "./styles";

interface Props {
  type: "full" | "half";
}

const ColoredBar: React.FC<Props> = ({ type }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.coloredBar}
      style={{ backgroundColor: type === "full" ? "green" : "red" }}
    ></div>
  );
};

export default ColoredBar;
