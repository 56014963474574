import React, { useState } from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

interface Props {
  children: any;
}
interface State {
  show: boolean;
  type: any;
  message: any;
}

export const Toast: React.FC<Props> = ({ children, ...props }) => {
  const [state, setState] = useState<State>({
    show: false,
    message: "",
    type: "",
  });

  const showToast = {
    success: (message: string) =>
      setState({ show: true, message: message ?? "", type: "success" }),
    error: (message: string) =>
      setState({ show: true, message: message ?? "", type: "error" }),
  };

  const hideToast = () => setState({ show: false, message: "", type: "" });

  return (
    <div>
      <Snackbar
        open={state.show}
        autoHideDuration={6000}
        onClose={hideToast}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={hideToast}
          severity={state.type}
        >
          {typeof state.message === "object"
            ? Object.keys(state.message).reduce(
                (prev, curr) => prev + " " + state.message[curr][0],
                ""
              )
            : state.message}
        </MuiAlert>
      </Snackbar>
      {React.cloneElement(children, { ...props, showToast, hideToast })}
    </div>
  );
};
