import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@material-ui/core";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { useStyles } from "./styles";
import useParentStyles from "../../styles";
import EditorForm from "../../components/EditorForm";
import MdEditor from "../../components/Editor/MdEditor";
import { postData, Toast } from "../../KnowledgeHubPage";
import { getPersistentAuthentication } from "../../../../utils/functions";
import { KHubPost } from "../../../../recoil/atoms";
import { updatePost } from "../../../../apis/kHubPost";

interface props {
  postData: postData;
  setPostData: React.Dispatch<React.SetStateAction<postData>>;
}

const EditorView: React.FC<props> = ({ postData, setPostData }) => {
  const history = useHistory();
  const classes = useStyles();
  const parentClasses = useParentStyles();
  const { state } = useLocation<KHubPost>();
  const userId = getPersistentAuthentication()?.userId;
  const username = getPersistentAuthentication()?.username;
  const showToast = useContext(Toast);
  const param: any = useParams();

  // to control the md or preview view
  const [checked, setChecked] = useState(false);

  // to set the initial user data from either api or from first time creating the post
  useEffect(() => {
    if (state === undefined) return;
    setPostData({
      author: state.author._id,
      body: state.body,
      category: state.category,
      framework: state.framework,
      images: state.images,
      isDraft: state.isDraft,
      language: state.language,
      platform: state.platform,
      title: state.title,
      updatedBy: state.updatedBy._id,
    });
  }, [userId, state]);

  function back() {
    history.goBack();
    setPostData({
      author: "",
      updatedBy: "",
      category: "",
      framework: "",
      language: "",
      platform: "",
      title: "",
      body: "",
      images: [],
      isDraft: true,
    });
  }

  // function to control the preview and md view
  function handleCheckedState() {
    setChecked(!checked);
  }

  async function saveOrPublish(type: string) {
    const updatedData = {
      ...postData,
      isDraft: type === "save" ? true : false,
    };
    try {
      await updatePost(param.id, updatedData);
      if (type === "save") showToast.success("Post saved as Draft.");
      else
        showToast.success(
          state === undefined ? "Post published." : "Post updated and published"
        );
      if (type === "publish") {
        setPostData({
          author: "",
          updatedBy: "",
          category: "",
          framework: "",
          language: "",
          platform: "",
          title: "",
          body: "",
          images: [],
          isDraft: true,
        });
        history.replace(`/app/knowledgeHub`);
      }
    } catch (error: any) {
      showToast.error(error.message);
    }
  }

  return (
    <Grid container>
      <Grid item xs={12} className={parentClasses.backButton}>
        <Button
          onClick={back}
          variant="text"
          startIcon={
            <ArrowBackIosRoundedIcon className={parentClasses.backIcon} />
          }
        >
          <Typography variant="h6">Back</Typography>
        </Button>
      </Grid>
      <Grid item xs={7} className={classes.mb}>
        <FormControlLabel
          control={
            <Switch
              disableRipple
              classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
              }}
              checked={checked}
              onChange={handleCheckedState}
            />
          }
          label={checked ? "Preview" : "Edit"}
        />
      </Grid>
      <Grid container item xs={5} alignItems="center" className={classes.mb}>
        <div className={classes.headText}>
          <Typography variant="body1" className={classes.headTextLabels}>
            Created By: &nbsp;&nbsp;
            <span className={classes.headTextValues}>
              {state === undefined ? username : state.author.basicDetails.fullName}
            </span>
          </Typography>
          <Typography variant="body1" className={classes.headTextLabels}>
            Updated By: &nbsp;&nbsp;
            <span className={classes.headTextValues}>
              {state === undefined ? username : state.updatedBy.fullName}
            </span>
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.note}><strong>Note:</strong> Image link to be provided - ![](image link) or use img tag in this format </div> 
        <div className={classes.editorSec}>
          <div style={{
            maxWidth: "70rem"
          }}>
            <MdEditor
              setPostData={setPostData}
              postData={postData}
              showPreview={checked === true}
            />
          </div>
          <EditorForm setPostData={setPostData} postData={postData} />
        </div>
      </Grid>
      <div className={classes.actionBtns}>
        <Button
          onClick={() => saveOrPublish("save")}
          variant="contained"
          disableElevation
          className={parentClasses.btnBase}
        >
          Save
        </Button>
        <Button
          onClick={() => saveOrPublish("publish")}
          variant="contained"
          disableElevation
          className={parentClasses.btnBase}
        >
          {state === undefined ? "Publish" : "Update"}
        </Button>
      </div>
    </Grid>
  );
};

export default EditorView;
