import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
  Box,
  IconButton,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { kraStyles } from "../styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Link, useHistory } from "react-router-dom";
import { getAllKraListAPI } from "../../../apis/kra";
import moment from "moment";
import { IFilterData } from "../views/AdminView";
import useFetchMemorized from "../../../hooks/useFetchMemorized";

const StyledTableCell = withStyles((theme) => ({
  head: {
    fontSize: ".625em",
    fontWeight: 600,
    color: theme.palette.common.black,
    border: "1px solid rgba(0, 0, 0, 0.15)",
  },
  body: {
    fontSize: ".75em",
    fontWeight: 500,
    border: "1px solid rgba(0, 0, 0, 0.15)",
  },
  root: {
    justifyContent: "space-between",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      background: "rgba(0, 0, 0, 0.02)",
    },
  },
}))(TableRow);

export interface ITableData {
  empName: string;
  empId: string;
  empNo: number;
  date: string;
  quarter: string;
  months: string;
  score: number;
  _id: string;
}

interface IKRATable {
  searchText?: string;
  filterData?: IFilterData;
}

export interface IParams {
  page: number;
  search: string;
  limit?: number;
  quarter: string;
  year: number;
}

const KRATable = ({ searchText, filterData }: IKRATable) => {
  const [tableData, setTableData] = useState<{
    kra: ITableData[];
    noOfPages: number;
  }>({ kra: [], noOfPages: 0 });
  const [pageNo, setPageNo] = useState(1);

  const [params, setParams] = useState(() => {
    const params = new URLSearchParams();
    params.append("page", `1`);
    params.append("quarter", `${filterData?.quarter}`);
    params.append("year", `${filterData?.year}`);
    return params;
  });

  const classes = kraStyles();
  const history = useHistory();

  const { refetch } = useFetchMemorized({
    queryFn: () => getAllKraListAPI(params.toString()),
    onSuccess(data) {
      setTableData(data.data);
    },
    onError(error) {
      console.error(error);
    },
  });

  useEffect(() => {
    if (searchText) {
      setParams((prev) => {
        const params = new URLSearchParams(prev.toString());
        params.set("search", searchText);
        return params;
      });
    }
    if (pageNo) {
      setParams((prev) => {
        const params = new URLSearchParams(prev.toString());
        params.set("page", `${pageNo}`);
        return params;
      });
    }

    const url = new URLSearchParams();
    url.set("quarter", `${filterData?.quarter}`);
    url.set("year", `${filterData?.year}`);
    url.set("page", `${pageNo}`);
    history.push({ search: url.toString() });
  }, [history, searchText, filterData, pageNo]);

  useEffect(() => {
    params.set("quarter", `${filterData?.quarter}`);
    params.set("year", `${filterData?.year}`);
    if (filterData) {
      params.set("page", "1");
    }
    params.set("page", `${pageNo}`);
    if (pageNo || filterData || params) {
      refetch();
    }
  }, [params, filterData, pageNo]);

  return (
    <Box style={{ display: "flex", flexDirection: "column" }}>
      <TableContainer
        component={Paper}
        style={{ marginTop: "1.6em", borderRadius: "10px" }}
      >
        <Table
          className={classes.table}
          aria-label="customized table"
          padding="normal"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Employee Name</StyledTableCell>
              <StyledTableCell>Employee No.</StyledTableCell>
              <StyledTableCell>Date Created</StyledTableCell>
              <StyledTableCell>Quarter</StyledTableCell>
              <StyledTableCell>Months</StyledTableCell>
              <StyledTableCell>Score</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.kra.map((row, index) => (
              <StyledTableRow key={`${row.empId}${index}`}>
                <StyledTableCell component="th" scope="row">
                  {row.empName}
                </StyledTableCell>
                <StyledTableCell>{`EMP${row.empNo}`}</StyledTableCell>
                <StyledTableCell>
                  {moment(row.date).format("DD/MM/YYYY")}
                </StyledTableCell>
                <StyledTableCell>{row.quarter}</StyledTableCell>
                <StyledTableCell>{row.months}</StyledTableCell>
                <StyledTableCell
                  style={{
                    display: "flex",
                  }}
                >
                  {row.score.toFixed()}
                  <IconButton
                    component={Link}
                    to={`kra/report?status=new&kraID=${row._id}`}
                    style={{
                      padding: 0,
                      marginRight: ".2em",
                    }}
                  >
                    <VisibilityIcon fontSize="small" />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        style={{ marginTop: "1em", alignSelf: "flex-end" }}
        variant="outlined"
        color="primary"
        shape="rounded"
        defaultPage={1}
        onChange={(_e, page) => {
          setPageNo(page);
        }}
        count={Math.ceil(tableData.noOfPages)}
        showFirstButton
        showLastButton
      />
    </Box>
  );
};

export default KRATable;
