import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root:{
        position:"relative",
        right:"1rem"
       
    },
    filterIcon:{
        padding:0,
    }

}));
