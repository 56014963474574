import React, { memo } from "react";
import SideNav from "./SideNav";
import { useStyles } from "./Style";

interface Props {
  children: any;
}

interface classes {
  container: string;
  childs: string;
}

const BasicLayout: React.FC<Props> = ({ children }) => {
  const classes: classes = useStyles();
  return (
    <div className={classes.container}>
      <SideNav />
      <div className={classes.childs}>{children}</div>
    </div>
  );
};

export default memo(BasicLayout);
