import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useRecoilState } from "recoil";
import { io } from "socket.io-client";
import {
  getUserLeaveBalance,
  leaveRecords,
  leaveTypes,
  userLeave,
} from "../../apis/leaves";
import { getRoleData } from "../../apis/login";
import Accordion from "../../components/Accordion";
import BasicLayout from "../../components/BasicLayout";
import { WEB_SOCKET_URL } from "../../config";
import { getPersistentAuthentication } from "../../utils/functions";
import { useBasicNav } from "../../utils/useBasicNav";
import PoliciesAcceptancePage from "../PoliciesAcceptancePage";
import ApplyLeave from "./ApplyLeave";
import EmployeeLeaveDetails from "./EmployeeLeaveDetails";
import LeaveReportPage from "./LeaveReportPage";
import LeaveTable from "./LeaveTable";
import SideBar from "./SideBar";
import { leaveBalance, userleavedetails, RequestedLeave } from "./types";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useStyles } from "./styles";
import { usePermissionObjectFor } from "../../hooks/permission/usePermissionObjectFor";

interface Iprops {
  loadPrimary: (event: any, state: any) => void;
  showToast: any;
}

export type LeaveTypes = {
  _id: string;
  type: "priviledge_leave" | "casual_leave" | "sick_leave";
  total: number;
};

const LeavesPage: React.FC<Iprops> = ({ loadPrimary, showToast }) => {
  useBasicNav("leaves");
  const classes = useStyles();
  const [assigneeLeave, setAssigneeLeave] = useState<[]>([]);
  const accordionRoot = useRef<any>(null);
  const [rootHeight, setRootHeight] = useState<number>(400);
  const [leaveTypesData, setLeaveTypes] = useState<LeaveTypes[]>([]);
  const [plLeave, setPlLeave] = useState<string>("");
  const [clLeave, setClLeave] = useState<string>("");
  const [slLeave, setSlLeave] = useState<string>("");
  const [leaveData, setLeaveData] = useState<leaveBalance>();
  const [userLeaveData, setUserLeaveData] = useState<RequestedLeave[]>([]);
  const [socketStatus, setSocketStatus] = useState<Boolean>(false);
  const [pageNo, setPageNo] = useState<number>(1);
  const [refresh, setRefresh] = useState<Boolean>(false);
  const [isLoading, setIsLoading] = useState<Boolean>(false);

  const isPolicyAccepted = getPersistentAuthentication()?.isPolicyAccepted;
  const userId = getPersistentAuthentication()?.userId;
  const { path } = useRouteMatch();

  const permission = usePermissionObjectFor("leave");
  const hasCreateAccess = permission.includes("create");
  const hasGrantAccess = permission.includes("grant");
  const isHR = permission.includes("adjust");

  useEffect(() => {
    let socket = io(`${WEB_SOCKET_URL}`, { transports: ["websocket"] });
    socket.on("message", (message: string) => {
      setSocketStatus(!socketStatus);
    });

    return () => {
      
      socket.disconnect();
    };
  }, [socketStatus]);

  /**
   * @method getUserLeave
   * @description api call to get current user leaves
   */

  const getUserLeave = async () => {
    try {
      let response = await userLeave();
      let payload = response?.data?.map((item: userleavedetails) => {
        let tempData = item;
        tempData.status = item?.isApproved
          ? "Approved"
          : item?.isPending
          ? "Pending"
          : "Rejected";
        return tempData;
      });

      setUserLeaveData(
        payload?.map((application: RequestedLeave) => ({
          ...application,
          duration:
            typeof application.adjustAmount === "number"
              ? application.adjustAmount + application.duration
              : application.adjustAmount,
        }))
      );
      loadPrimary(false, false);
      return response;
    } catch (err: any) {
      showToast.error(err.response.data.message);
    }
  };

  /**
   * @description Function to get present user leave balance
   * @method getPresentUser
   * @returns nothing
   */
  const getPresentUser = async () => {
    try {
      let data = await getUserLeaveBalance(userId);
      setPlLeave(
        `${data?.data.privilegeLeave.taken}/${data?.data.privilegeLeave.allotted}`
      );
      setClLeave(
        `${data?.data.casualLeave.taken}/${data?.data.casualLeave.allotted}`
      );
      setSlLeave(
        `${data?.data.sickLeave.taken}/${data?.data.sickLeave.allotted}`
      );
      setLeaveData({
        PriviledgeLeave: {
          taken: data?.data.privilegeLeave.taken,
          total: data?.data.privilegeLeave.allotted,
        },
        CasualLeave: {
          taken: data?.data.casualLeave.taken,
          total: data?.data.casualLeave.allotted,
        },
        SickLeave: {
          taken: data?.data.sickLeave.taken,
          total: data?.data.sickLeave.allotted,
        },
      });
    } catch (error: any) {
      showToast.error(error.response.data.message);
    }
  };

  /**
   * @method getLeaveTypes
   * @description api call to get all leave types
   */

  const getLeaveTypes = async () => {
    try {
      let data = await leaveTypes();
      setLeaveTypes(data?.data);
      getLeaveRecords();
      loadPrimary(false, false);
    } catch (err: any) {
      showToast.error(err.response.data.message);
      loadPrimary(false, false);
    }
  };

  /**
   * @method getLeaveRecord
   * @description api call to get all leave record of user
   */

  const getLeaveRecords = async () => {
    try {
      await leaveRecords();
      getPresentUser();
    } catch (err: any) {
      loadPrimary(false, false);
      showToast.error(err.response.data.message);
    }
  };

  const toggleRefresh = () => {
    setRefresh(!refresh);
  };

  useLayoutEffect(() => {
    loadPrimary(true, true);
    setRootHeight(accordionRoot?.current?.clientHeight);
    getLeaveTypes();
    getUserLeave();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const refreshLeave = () => {
    getUserLeave();
  };

  const accordionData = [
    {
      title: "Apply Leave",
      component: (
        <ApplyLeave
          toggleRefresh={toggleRefresh}
          showToast={showToast}
          leaveTypesData={leaveTypesData}
          parentHeight={rootHeight}
          refreshLeaves={refreshLeave}
          getUserLeave={getUserLeave}
          setIsLoading={setIsLoading}
          
        />
      ),
      shouldDisplay: hasCreateAccess,
      shouldSort: false,
    },
    {
      title: "Your Leave Detail",
      component: (
        <LeaveTable
          data={userLeaveData}
          parentHeight={rootHeight}
          refresh={toggleRefresh}
          showToast={showToast}
          getUserLeave={getUserLeave}
        />
      ),
      shouldDisplay: hasCreateAccess,
      shouldSort: false,
    },

    {
      title: "Employees Leave Detail",
      component: <EmployeeLeaveDetails parentHeight={rootHeight} />,
      shouldDisplay: hasCreateAccess === false || isHR,
      shouldSort: false,
    },
  ];
  if (!isPolicyAccepted && !isHR && hasCreateAccess) {
    return <PoliciesAcceptancePage />;
  } else {
    return (
      <BasicLayout>
        <Switch>
          <Route path={`${path}`} exact>
            {isLoading ? (
              <div className={classes.loading}>
                <CircularProgress color="primary" />
              </div>
            ) : (
              <div className={classes.mainContainer}>
                <div className={classes.leftWrapper}>
                  {hasCreateAccess && (
                    <div className={classes.myLeavesContainer}>
                      <div className={classes.pl}>
                        <p className={classes.plTitle}>PRIVILEDGE LEAVE</p>
                        <p className={classes.plCount}>{plLeave}</p>
                      </div>
                      <div className={classes.cl}>
                        <p className={classes.clTitle}>CASUAL LEAVE</p>
                        <p className={classes.clCount}>{clLeave}</p>
                      </div>
                      <div className={classes.sl}>
                        <p className={classes.slTitle}>SICK LEAVE</p>
                        <p className={classes.slCount}>{slLeave}</p>
                      </div>
                    </div>
                  )}

                  <div className={classes.contentWrapper} ref={accordionRoot}>
                    <Accordion data={accordionData} />
                  </div>
                </div>

                {hasGrantAccess && (
                  <div className={classes.rightWrapper}>
                    <SideBar
                      showToast={showToast}
                      leaveData={userLeaveData}
                      refreshData={refresh}
                      pageNo={pageNo}
                    />
                  </div>
                )}
              </div>
            )}
          </Route>
          <Route path={`${path}/leavesReport`} exact>
            <LeaveReportPage
              loadPrimary={loadPrimary}
              pageNo={pageNo}
              setPageNo={setPageNo}
              showToast={showToast}
            />
          </Route>
        </Switch>
      </BasicLayout>
    );
  }
};

export default LeavesPage;
