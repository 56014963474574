import { StepConnector } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 10,
    marginRight: 10,
  },
  buttonGrid: {
    padding: "2%",
  },
  buttonSubmit: {
    width: 132,
    height: 44,
    fontSize: 14,
  },
  stepperBackground: {
    backgroundColor: "#F5F5F5",
    border: "1px solid #e3dede",
    boxShadow: "0px 2px 8px 0px #00000026",
  },
  stepActive: {
    borderRadius: "100%",
    border: "3px solid #424B5E",
    padding: 4,
  },
  stepInactive: {
    padding: 7,
    borderRadius: "100%",
    color: "#fff",
    backgroundColor: "#9F9E9E",
  },
  stepComplete: {
    borderRadius: "100%",
    backgroundColor: "#424B5E",
    color: "#fff",
    padding: 7,
  },
  completedText: {
    color: "#0DA600",
    fontSize: 12,
    lineHeight: "13.5px",
  },
  tabsContainer: {
    backgroundColor: "#fff",
    padding: "3%",
    marginTop: 5,
  },
  tabActive: {
    color: "#14213D",
    borderBottom: "2px solid #14213D",
    width: "20%",
    paddingLeft: 30,
    paddingBottom: 15,
    textAlign: "center",
    transitionDuration: "1s",
    "&:hover": {
      color: "#000",
      cursor: "pointer",
      transitionDuration: "1s",
    },
  },
  tabInactive: {
    color: "#89909E",
    borderBottom: "2px solid silver",
    width: "20%",
    paddingLeft: 30,
    paddingBottom: 15,
    textAlign: "center",
    transitionDuration: "1s",
    "&:hover": {
      color: "#000",
      cursor: "pointer",
      transitionDuration: "1s",
    },
  },
  accordionsContainer: {
    backgroundColor: "#fff",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
}));

export const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundColor: "#424B5E",
    },
  },
  completed: {
    "& $line": {
      backgroundColor: "#424B5E",
    },
  },
  line: {
    height: 2,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);
