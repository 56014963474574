// PLUGINS IMPORTS //
import React, { useState, useEffect } from "react";
import { useField } from "formik";
import Grid from "@material-ui/core/Grid";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
// COMPONENTS IMPORTS //

// EXTRA IMPORTS //

/////////////////////////////////////////////////////////////////////////////

const DatePickerField = (props: any) => {
  const [field, meta, helper] = useField(props);
  const { touched, error } = meta;
  const { setValue } = helper;
  const isError = touched && error && true;
  const { value } = field;
  const [selectedDate, setSelectedDate] = useState(props.value);

  let dependentFieldName: string;
  let dependentFieldFunction: (arg: string) => string;

  if (props?.dependentFieldChanges) {
    dependentFieldName = props.dependentFieldChanges.fieldName;
    dependentFieldFunction = props.dependentFieldChanges.functionChange;
  }

  useEffect(() => {
    if (value) {
      const date = new Date(value);
      setSelectedDate(date);

      if (props?.dependentFieldChanges) {
        //set age
        let newDependentData = dependentFieldFunction(date.toString());
        props.formikProps.setFieldValue(dependentFieldName, newDependentData);
      }
    }
  }, [value]);

  function _onChange(date: any) {
    // const formattedDate = Moment(date).format("DD/MM/YYYY");
    let datePicked;
    try {
      datePicked = date?.toISOString();
      if (!datePicked) return;

      setSelectedDate(datePicked);
      setValue(datePicked);

      if (!props?.dependentFieldChanges) return;
      //set age
      let newDependentData = dependentFieldFunction(date.toString());
      props.formikProps.setFieldValue("age", newDependentData);
    } catch (err) {
      
    }
  }

  return (
    <Grid container>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          {...field}
          {...props}
          value={selectedDate}
          onChange={_onChange}
          error={isError}
          invalidDateMessage={isError && error}
          helperText={isError && error}
        />
      </MuiPickersUtilsProvider>
    </Grid>
  );
};

export default DatePickerField;
