import React from "react";
import { useParams } from "react-router-dom";
import EmployeeLeaveAccordion from "../../../components/Accordion/EmployeeLeaveAccordion";

const EmployeeLeaves = () => {
  const params: { id: string } = useParams();

  return (
    <div>
      <EmployeeLeaveAccordion employeeId={params.id} />
    </div>
  );
};

export default EmployeeLeaves;
