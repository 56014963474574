import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: 500,
      "& > * + *": {
        marginTop: theme.spacing(3),
      },
    },
    padding: {
      padding: theme.spacing(3),
    },
    resource: {
      minWidth: "7rem",
    },
    title: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "18px",
      color: "#14213D",
      marginBottom: ".5rem",
    },
    tabs: {
      borderRadius: "10px",
    },
    resourceTabs: {
      height: 310,
    },
    donebutton: {
      fontFamily: "Poppins",
      fontStyle: " normal",
      fontWeight: 500,
      backgroundColor: "#1C2943",
      color: "#FFFFFF",
      marginLeft: ".5rem",
      float: "right",
      marginTop: ".5rem",
      "&:hover":{
        backgroundColor:"#14213D"
      }
    },
    addnewcontent: {
      minWidth: "15%",
      float: "right",
      paddingTop: ".5rem",
    },
    dialog: {
      "&.MuiPaper-root": {
        boxShadow: "none",
        borderRadius: "10px",
      },
    },
    pagination: {
      float: "right",
    },
    paginate:{
      marginTop:".5rem"
    },
    view:{
      padding: "0.8em",
      width: "0.3em",
      height: "0.3em",
    },
    loading:{
     position:"relative",
     top:"50%",
     left:"50%",
    },
    incidentDescription:{
      display:"flex",
      justifyContent:"space-between",
      alignItems:"center",
    },
    desc:{
      whiteSpace:"nowrap",
      textOverflow: "ellipsis",
      overflow:"hidden",
      width:"3rem"
    },
    attachedfilestext: {
      fontWeight: 500,
    },
    innertext: {
      color: "grey",
      fontSize: "12px",
    },
    link: {
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      color: "black",
      cursor: "pointer",
      marginLeft:".5em",
      "&:hover": {
        color: "#6F8FAF",
      },
    },
    originContainer:{
      display:"flex",
      justifyContent:"space-between",
    },
    originText:{
      fontSize:"13px"
    },
    addIcon:{
      marginRight:"1rem",
      padding:0
    },
    titleContainer:{
      display: "flex", 
      alignItems: "center"
    },
    contentPagination:{
      display: "flex", 
      justifyContent: "end",
      marginTop:"1rem"
    },
    allContentButton:{
      height: "3rem"
    },
    arrowIcon:{
      transform: " rotate(180deg)"
    },
    buttonContainer:{
      display: "flex",
      justifyContent: "end",
       marginTop: ".3rem",
    },
    allContentContainer:{
      display: "flex",
      justifyContent: "end",
    }
    

    

  })
);
