import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from "@material-ui/lab";
import React, { ChangeEvent, useState } from "react";
import FilterIcon from "../../../assets/icons/filter-kra.svg";
import { IFilterData } from "../views/AdminView";

const month = new Date().getMonth() + 1; //Jan is 1, Feb is 2
let quarter = Math.ceil(month / 3);
const quarterNames = ["Q1", "Q2", "Q3", "Q4"];
const currentQuarter = quarterNames[quarter - 1];

const currentYear = new Date().getFullYear();
const lastThreeYears: number[] = [];
for (let i = 0; i < 3; i++) {
  lastThreeYears.push(currentYear - i);
}

const quarterYears: string[] = [];
for (let i = 0; i < 3; i++) {
  while (quarter > 0) {
    quarterYears.push(`Q${quarter} ${currentYear - i}`);
    quarter--;
  }
  quarter = 4;
}

interface IFilterBar {
  filterData: IFilterData;
  handleFilterData: (data: IFilterData) => void;
}

const FilterBar = ({ filterData, handleFilterData }: IFilterBar) => {
  const [modalOpen, setModalOpen] = useState(false);

  const [currentFilter, setCurrentFilter] = useState<IFilterData>(filterData);

  const [selectedQuartedYear, setSelectedQuarterYear] = useState<string | null>(
    null
  );

  const handleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleSubmit = () => {
    handleFilterData(currentFilter);
  };

  const handleChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    const { name, value } = e.target;
    setCurrentFilter((prev) => ({
      ...prev,
      [`${name}`]: value,
    }));
  };

  const handleQuarterYearChange = (
    event: ChangeEvent<{}>,
    value: string | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string> | undefined
  ) => {
    setSelectedQuarterYear(value);
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: ".8em",
          border: "1px solid rgba(20, 33, 61, 0.15)",
          borderRadius: "8px",
          cursor: "pointer",
        }}
        onClick={handleModal}
      >
        <Typography
          variant="body1"
          style={{
            fontSize: ".75em",
            fontWeight: 500,
            color: "#14213D",
            marginRight: "1.2em",
          }}
        >
          Filter
        </Typography>
        <img src={FilterIcon} />
      </Box>
      {modalOpen && (
        <Box
          style={{
            position: "absolute",
            top: "120%",
            right: "0%",
            background: "#F9F9F9",
            border: "1px solid rgba(20, 33, 61, 0.15)",
            borderRadius: "10px",
            width: "20em",
            padding: "1em 1.6em",
            zIndex: 2,
          }}
        >
          <Grid container item xs={12} style={{ alignItems: "center" }}>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                style={{ fontSize: "1em", fontWeight: 500 }}
              >
                By Year
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ marginBottom: "1em", fontWeight: 500 }}>
              <Select
                // defaultValue={filterData.year}
                style={{
                  width: "100%",
                  padding: ".1em",
                  background: "white",
                }}
                name="year"
                value={currentFilter.year}
                onChange={handleChange}
              >
                {lastThreeYears.map((curElem, index) => (
                  <MenuItem key={index} value={curElem}>
                    {curElem}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>

          <Grid container item xs={12}>
            <Grid item xs={6}>
              <Typography variant="h6" style={{ fontSize: "1em" }}>
                By Quarter
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ marginBottom: "1em" }}>
              <Select
                style={{
                  width: "100%",
                  padding: ".1em",
                  background: "white",
                }}
                name="quarter"
                value={currentFilter.quarter}
                onChange={handleChange}
              >
                <MenuItem value={"Q1"}>Q1</MenuItem>
                <MenuItem value={"Q2"}>Q2</MenuItem>
                <MenuItem value={"Q3"}>Q3</MenuItem>
                <MenuItem value={"Q4"}>Q4</MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ display: "flex", justifyContent: "end" }}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Apply
            </Button>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default FilterBar;
