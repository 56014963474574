import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
    editor: {
        // "& .monaco-editor": {
        //     borderRadius: "0.8rem",
        //     "& .overflow-guard": {
        //         borderRadius: "0.8rem",
        //     },
        // },
        "& .cm-editor": {
            borderRadius: "1rem",
            "& .cm-gutters": {
                borderRadius: "1rem",
            },
            "& .cm-content": {
                paddingTop: "1rem",
            },
        },
    },
    preview: {
        backgroundColor: "rgb(250, 250, 250)",
        padding: "1rem",
        borderRadius: "0.8rem",
        overflowY: "auto",
        border: "1px solid rgba(0, 0, 0, 0.25)",
    },
}));
