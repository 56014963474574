import { SetterOrUpdater } from "recoil";

interface setFamilyAndLanguageDataProps {
  getFamNLangDetails: any;
  setFamLanguage: any;
}

export const setFamilyAndLanguageData = ({
  getFamNLangDetails,
  setFamLanguage,
}: setFamilyAndLanguageDataProps) => {
  setFamLanguage({
    family: {
      spouse: {
        name: getFamNLangDetails?.family?.spouse?.name,
        dob: getFamNLangDetails?.family?.spouse?.dob,
        dependent: getFamNLangDetails?.family?.spouse?.dependent,
        occupation: getFamNLangDetails?.family?.spouse?.occupation,
      },
      father: {
        name: getFamNLangDetails?.family?.father?.name,
        dob: getFamNLangDetails?.family?.father?.dob,
        dependent: getFamNLangDetails?.family?.father?.dependent,
        occupation: getFamNLangDetails?.family?.father?.occupation,
      },
      mother: {
        name: getFamNLangDetails?.family?.mother?.name,
        dob: getFamNLangDetails?.family?.mother?.dob,
        dependent: getFamNLangDetails?.family?.mother?.dependent,
        occupation: getFamNLangDetails?.family?.mother?.occupation,
      },
    },
    languages: [
      {
        languageName: getFamNLangDetails?.languages[0]?.languageName
          ? getFamNLangDetails?.languages[0]?.languageName
          : "English",
        read: getFamNLangDetails?.languages[0]?.read
          ? getFamNLangDetails?.languages[0]?.read
          : false,
        speak: getFamNLangDetails?.languages[0]?.speak
          ? getFamNLangDetails?.languages[0]?.speak
          : false,
        write: getFamNLangDetails?.languages[0]?.write
          ? getFamNLangDetails?.languages[0]?.write
          : false,
      },
      {
        languageName: getFamNLangDetails.languages[1]?.languageName
          ? getFamNLangDetails.languages[1]?.languageName
          : "Hindi",
        read: getFamNLangDetails.languages[1]?.read
          ? getFamNLangDetails.languages[1]?.read
          : false,
        speak: getFamNLangDetails.languages[1]?.speak
          ? getFamNLangDetails.languages[1]?.speak
          : false,
        write: getFamNLangDetails.languages[1]?.write
          ? getFamNLangDetails.languages[1]?.write
          : false,
      },
      {
        languageName: getFamNLangDetails.languages[2]?.languageName
          ? getFamNLangDetails.languages[2]?.languageName
          : "Assamese",
        read: getFamNLangDetails.languages[2]?.read
          ? getFamNLangDetails.languages[2]?.read
          : false,
        speak: getFamNLangDetails.languages[2]?.speak
          ? getFamNLangDetails.languages[2]?.speak
          : false,
        write: getFamNLangDetails.languages[2]?.write
          ? getFamNLangDetails.languages[2]?.write
          : false,
      },
      {
        languageName: getFamNLangDetails.languages[3]?.languageName
          ? getFamNLangDetails.languages[3]?.languageName
          : "Bengali",
        read: getFamNLangDetails.languages[3]?.read
          ? getFamNLangDetails.languages[3]?.read
          : false,
        speak: getFamNLangDetails.languages[3]?.speak
          ? getFamNLangDetails.languages[3]?.speak
          : false,
        write: getFamNLangDetails.languages[3]?.write
          ? getFamNLangDetails.languages[3]?.write
          : false,
      },
    ],
  });
};
