import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { createRoleAPI, getRoleNamesAPI } from "../../apis/roles";
import { useStyles } from "./styles";

interface IRoleList {
  loadPrimary: (event: boolean, basic?: boolean | undefined) => void;
  showToast: {
    error: (message: string) => void;
    success: (message: string) => void;
    warning: (message: string) => void;
  };
}

const RoleList = ({ loadPrimary, showToast }: IRoleList) => {
  const [roleNames, setRoleNames] = useState([]);
  const [roleName, setRoleName] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [roleNameError, setRoleNameError] = useState("");

  const classes = useStyles();

  const getRoleNames = async () => {
    const res = await getRoleNamesAPI();
    setRoleNames(res.data);

    loadPrimary(false, false);
  };

  useEffect(() => {
    loadPrimary(true, true);
    getRoleNames();
  }, []);

  const createNewRole = async (name: string) => {
    if (name === "") {
      setRoleNameError("Role Name cannot be empty");
      return;
    }

    try {
      //api call
      const res = await createRoleAPI(name);
      if (res.status === 201) {
        showToast.success("New role created");
      } else {
        showToast.error("something went wrong");
      }

      setRoleName("");
      setRoleNameError("");
      setOpenDialog(false);
      getRoleNames();
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
    setRoleName("");
    setRoleNameError("");
  };
  const handleOpen = () => setOpenDialog(true);

  return (
    <>
      {openDialog && (
        <Dialog open={openDialog} onClose={handleClose}>
          <DialogContent className={classes.dialogContent}>
            <TextField
              fullWidth
              value={roleName}
              variant="outlined"
              placeholder="Enter role name"
              onChange={(e) => setRoleName(e.target.value)}
              inputProps={{ style: { height: "1.6em", fontSize: "1.2em" } }}
              required
              style={{
                background: "white",
                borderRadius: "4px",
              }}
            />
            <Typography
              component={"h5"}
              style={{
                color: "red",
              }}
            >
              {roleNameError}
            </Typography>
            <DialogActions
              style={{
                padding: "10px 0px  20px 0",
              }}
            >
              <Button
                onClick={handleClose}
                color="primary"
                variant="contained"
                size="large"
                className={classes.dialogButton}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  createNewRole(roleName);
                }}
                color="primary"
                variant="outlined"
                size="large"
                className={classes.dialogButton}
              >
                Save
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      )}

      <Grid container item style={{ justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.newRoleButton}
          onClick={handleOpen}
        >
          Add a new role
        </Button>
      </Grid>

      <Paper elevation={2} className={classes.roleNameWrapper}>
        <Box borderRadius="10px" className={classes.roleNameContainer}>
          {roleNames.map((curElem: any) => {
            return (
              <Paper elevation={5} className={classes.roleCard}>
                <h3 className={classes.roleLabel}>{curElem.roleName}</h3>
                <Tooltip title={`Edit ${curElem.roleName} role`}>
                  <Link to={`roles/${curElem._id}`}>
                    <IconButton className={classes.editIcon} size="small">
                      <EditIcon fontSize="medium" />
                    </IconButton>
                  </Link>
                </Tooltip>
              </Paper>
            );
          })}
        </Box>
      </Paper>
    </>
  );
};

export default RoleList;
