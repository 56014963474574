import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
     contentWrapper: {
    width: "100%",
    height: "95%",
    // backgroundColor: 'black',
    borderRadius: "0.625rem",
  },
  pagination: {
    float:"right",
    marginTop: theme.spacing(2),
    
  },
  pendingIcon: {
    color: "#FF9F45",
  },
  approvedIcon: {
    color: "green",
  },
  rejectedIcon: {
    color: "red",
  },
  
 
}));
