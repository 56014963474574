import React, { useContext, useState } from "react";
import classNames from "classnames";
import { useStyles } from "./styles";
import { Box, Button, Grid, Paper } from "@material-ui/core";
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";
import PublishIcon from "@material-ui/icons/Publish";
import { uploadHolidayListAPI } from "../../../apis/holidays";
import { HolidayContext } from "../../../pages/DashboardPage/DashboardPage";
import { usePermissionObjectFor } from "../../../hooks/permission/usePermissionObjectFor";

interface Props {
  label: string;
  color: string;
  children: any;
  existButton?: boolean;
}

const DashboardCard: React.FC<Props> = ({
  label,
  color,
  children,
  existButton,
}) => {
  const classes = useStyles();

  const [holidayFile, setHolidayFile] = useState<File | string>();
  const { setHolidayListRefetch } = useContext(HolidayContext);
  const permissions = usePermissionObjectFor("attendance");
  const hasCreatePermission = permissions.includes("create");

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files?.length == 0) return;
    setHolidayFile(e.target.files[0]);
    uploadHolidayFile(e.target.files[0]);
  };

  const uploadHolidayFile = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    const res = await uploadHolidayListAPI(formData);
    if (res.data.success) {
      setHolidayListRefetch(true);
    }
  };

  return (
    <>
      <Paper className={classes.container}>
        <Box
          className={classNames(classes.headerBox, {
            [classes.mainContainerRed]: color === "red",
            [classes.mainContainerBlue]: color === "blue",
            [classes.mainContainerPurple]: color === "#DF08BD",
          })}
        >
          <h1
            style={{ margin: 0 }}
            className={classNames(classes.title, {
              [classes.titleRed]: color === "red",
              [classes.titleBlue]: color === "blue",
              [classes.titlePurple]: color === "#DF08BD",
            })}
          >
            {label}
          </h1>
          {existButton && hasCreatePermission && (
            // <Button
            //   className={classes.uploadButton}
            //   color="primary"
            //   variant="contained"
            //   endIcon={<BackupOutlinedIcon />}
            // >
            //   Upload
            // </Button>
            <Box
              // style={{
              //   display: "flex",
              //   border: "1px solid red",
              // }}
              className={classes.uploadButton}
            >
              <input
                accept="*/"
                // className={classes.input}
                id="contained-button-file"
                type="file"
                hidden
                onChange={handleFileUpload}
              />
              <label htmlFor="contained-button-file">
                <Button
                  style={{ width: "100%" }}
                  variant="outlined"
                  size="large"
                  // className={classes.uploadButn}
                  startIcon={<PublishIcon />}
                  component="div"
                  color="primary"
                >
                  Upload
                </Button>
              </label>
            </Box>
          )}
        </Box>
        <Box className={classes.bodyBox}>{children}</Box>
      </Paper>
    </>
  );
};

export default DashboardCard;

/*

 <Grid
      direction="column"
      justify="center"
      container
      className={classNames(classes.mainContainer, {
        [classes.mainContainerRed]: color === "red",
        [classes.mainContainerBlue]: color === "blue",
        [classes.mainContainerPurple]: color === "#DF08BD",
      })}
    >
      <Box className={classes.top}>
        <h1
          className={classNames(classes.title, {
            [classes.titleRed]: color === "red",
            [classes.titleBlue]: color === "blue",
            [classes.titlePurple]: color === "#DF08BD",
          })}
        >
          {label}
        </h1>
      </Box>
      <Grid container alignItems="center" className={classes.bottom}>
        {children}
      </Grid>
    </Grid>
*/
