import React, { useContext, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useStyles } from "./style";
import ProjectRequirementOverview from "./ProjectRequirementOverview";
import ProjectSOW from "./ProjectSOW";
import ProjectDesign from "./ProjectDesign";
import { fetchProjectRequirements } from "../../../../../apis/projects";
import { Toast } from "../../../ProjectsPage";
import { useParams } from "react-router-dom";
import { projectRequirement } from "../../../types";

interface Iprops {}
const RequirementsTab: React.FC<Iprops> = () => {
  const classes = useStyles();
  const showToast = useContext(Toast);
  const {id} = useParams<any>();

  const [projectRequirement, setProjectRequirement] =
    useState<projectRequirement>({
      requirementOverview: "",
      sampleOfWork: [],
      designDocs: [],
      designLinks: [],
      _id: "",
    });

  useEffect(() => {
    fetchRequirements();
  }, []);

  async function fetchRequirements() {
    try {
      let response = await fetchProjectRequirements(id);
      setProjectRequirement(response?.data[0]?.requirements);
    } catch (error: any) {
      showToast.error(error?.response?.data?.message);
    }
  }

  return (
    <Grid container xs={12} spacing={2}>
      <Grid item xs={12} md={12}>
        <Accordion className={classes.ReqOverview} expanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="RequirementOverview"
          >
            <Typography>Requirement Overview</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ProjectRequirementOverview
              projectRequirement={projectRequirement?.requirementOverview}
              fetchRequirements={fetchRequirements}
            />
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12}>
        <Accordion className={classes.projectSowContainer}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="sampleOfWork"
          >
            <Typography> SOW </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ProjectSOW
              projectRequirement={projectRequirement?.sampleOfWork}
              fetchRequirements={fetchRequirements}
            />
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12}>
        <Accordion className={classes.projectDesignContainer}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="projectDesign"
          >
            <Typography>Design</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ProjectDesign
              projectRequirement={projectRequirement}
              fetchRequirements={fetchRequirements}
            />
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};
export default RequirementsTab;
