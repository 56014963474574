import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import EyeOutlined from "@material-ui/icons/VisibilityOutlined";
import clsx from "clsx";
import * as React from "react";

import moment from "moment";
import { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { getEmployeeKraHistoryAPI } from "../../../../apis/kra";
import { NoKRAIcon } from "../../../../assets/custom-icons/NoKRA";
import { usePermissionObjectFor } from "../../../../hooks/permission/usePermissionObjectFor";
import useFetchMemorized from "../../../../hooks/useFetchMemorized";
import { modeAtom } from "../../../../recoil/kraAtom";
import { Adapter } from "../../../../utils/Adapter";
import { getPersistentAuthentication } from "../../../../utils/functions";
import { getNextQuarter } from "../../../../utils/getNextQuarter";
import { isFirstNDaysOfTheQuarter } from "../../../../utils/isFirstNDaysOfTheQuarter";
import { KRAFlatCard } from "../../components";
import KRALayout from "../../components/KRALayout";
import { RenderCreateKRAButton } from "../../components/RenderCreateKRAButton";
import { IKraAPI, IKraReport } from "../../types/KRA.interface";
import { Header } from "./Header";
import { IUserData } from "../../views/TeamLeadView";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    stack: {
      display: "flex",
      alignItems: "center",
    },
    flex1: {
      flex: 1,
    },
    px2: {
      paddingInline: "16px",
    },
    my2: {
      marginBlock: "16px",
    },
    py2: {
      paddingBlock: "16px",
    },
    centerAlign: {
      display: "grid",
      placeItems: "center",
    },
    height70: {
      height: "70vh",
    },
  })
);

// interface IEmpInfo {
//   empInfo: {
//     empId: string;
//     empName: string;
//   };
// }

interface IEmpInfo {
  fullName: string;
  _id: string;
}

const KRAHistory: React.FC<{}> = () => {
  const classes = useStyles();
  const location = useLocation();
  const params = useParams<{ id: string }>();
  const { start, end } = getNextQuarter();
  const user = getPersistentAuthentication();

  const permissions = usePermissionObjectFor("kras");
  const hasCreatePermission = permissions.includes("create");
  const isUnder15DaysOfTheQ = isFirstNDaysOfTheQuarter(15);
  // const isUnder15DaysOfTheQ = true;

  const [empInfo, setEmpInfo] = useState<IEmpInfo>();

  const [kraHistory, setKraHistory] = useState<IKraReport[]>();

  const [mode, setMode] = useRecoilState(modeAtom);
  const leadId: IUserData = JSON.parse(sessionStorage.tvcpsess);

  const { isLoading } = useFetchMemorized({
    queryFn: () => getEmployeeKraHistoryAPI(params.id),
    onSuccess(res) {
      const apiData = new Adapter<IKraAPI[]>(res.data.kra);
      const data = apiData.adapt<IKraReport[]>((data) =>
        data.map((data) => ({
          score: data.score,
          dateCreated: moment(data.date).format("DD-MM-YYYY"),
          KRAID: data._id,
          employee: data.employee,
          quarter: `${data.quarter} ${moment(data.date).format("YYYY")}`,
          approvedBy: data.approvedBy.fullName,
          createdBy: data.createdBy.fullName,
          attributes: data.attributes,
          isApproved: data.isApproved,
          hasTeamLeadRated: data.hasTeamLeadRated,
          hasUserRated: data.hasUserRated,
          teamLeadId: data.createdBy.id,
        }))
      );
      setKraHistory(data);
      setEmpInfo(res.data.empInf);
    },
    onError(error) {
      console.error(error);
    },
  });

  if (!kraHistory || isLoading) {
    return (
      <KRALayout>
        <div className={clsx([classes.centerAlign, classes.height70])}>
          <CircularProgress />
        </div>
      </KRALayout>
    );
  }

  return (
    <KRALayout>
      <Header
        employeeID={empInfo?._id!}
        employeeName={empInfo?.fullName!}
        renderText={() => (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <InfoOutlinedIcon style={{ marginInline: "4px" }} />
            <Typography variant="subtitle1">
              Next KRA can be created from{" "}
              <Typography
                component="span"
                style={{ fontWeight: "bold", color: grey[700] }}
              >
                {moment(start).format("Do MMM, YYYY")}
              </Typography>
            </Typography>
          </Box>
        )}
        renderCreateKraButton={(props) => (
          <Button
            onClick={() => setMode("CREATE")}
            component={Link}
            to={{
              pathname: `/app/kra/details/${props.employeeID}/create`,
              state: {
                empInfo: {
                  empId: empInfo?._id,
                  empName: empInfo?.fullName,
                },
              },
            }}
            variant="contained"
            size="large"
            style={{
              background: "#140047",
              color: "white",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            startIcon={<AddIcon />}
          >
            Create KRA
          </Button>
        )}
      />
      <Divider />

      {kraHistory.length === 0 && (
        <Box
          style={{ display: "grid", placeItems: "center", marginBlock: "6em" }}
        >
          <div>
            <NoKRAIcon />
            <Typography align="center">Oh hoo! No KRA History</Typography>
          </div>
          <RenderCreateKRAButton
            employeeID={empInfo?._id!}
            employeeName={empInfo?.fullName!}
            renderCreateKraButton={(props) => (
              <Button
                onClick={() => setMode("CREATE")}
                component={Link}
                to={{
                  pathname: `/app/kra/details/${props.employeeID}/create`,
                  state: {
                    empInfo: {
                      empId: props.employeeID,
                      empName: props.employeeName,
                    },
                  },
                }}
                variant="contained"
                size="large"
                style={{
                  background: "#140047",
                  color: "white",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                startIcon={<AddIcon />}
              >
                Create KRA
              </Button>
            )}
            renderText={(props) => (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <InfoOutlinedIcon style={{ marginInline: "4px" }} />
                <Typography variant="subtitle1">
                  {`${props.text} `}
                  <Typography
                    component="span"
                    style={{ fontWeight: "bold", color: grey[700] }}
                  >
                    {moment(start).format("Do MMM, YYYY")}
                  </Typography>
                </Typography>
              </Box>
            )}
          />
        </Box>
      )}
      <Grid container spacing={3}>
        {kraHistory.map((kra) => {
          let disableEditButton = false;
          if (kra.isApproved) {
            disableEditButton = true;
          }
          if (kra.hasTeamLeadRated) {
            disableEditButton = true;
          }
          // For Team Lead if not self rated then it will be disabed
          if (user.userId === kra.teamLeadId && !kra.hasUserRated) {
            disableEditButton = true;
          }
          return (
            <Grid item xs={12} lg={6} key={kra.KRAID}>
              <KRAFlatCard
                status="new"
                score={Number(kra.score.toFixed())}
                employeeID={params.id}
                kraID={kra.KRAID}
                dateCreated={kra.dateCreated}
                createdBy={kra.createdBy}
                approvedBy={kra.approvedBy}
                quarter={kra.quarter}
                renderEditButton={() => (
                  <Button
                    onClick={() => setMode("EDIT")}
                    startIcon={<EditOutlinedIcon />}
                    variant="contained"
                    component={Link}
                    disabled={disableEditButton}
                    to={{
                      pathname: `/app/kra/details/${kra.employee.id}/${kra.KRAID}/edit`,
                      state: {
                        kraInfo: {
                          kraId: kra.KRAID,
                          empName: `${kra.employee.fullName}`,
                        },
                      },
                    }}
                  >
                    Edit
                  </Button>
                )}
                renderViewReportButton={() => (
                  <Button
                    component={Link}
                    to={`/app/kra/report?status=${"new"}&employeeId=${
                      kra.employee.id
                    }&kraID=${kra.KRAID}`}
                    startIcon={<EyeOutlined />}
                    variant="outlined"
                  >
                    View Report
                  </Button>
                )}
              />
            </Grid>
          );
        })}
      </Grid>
    </KRALayout>
  );
};

export default KRAHistory;
