import { makeStyles } from "@material-ui/core";
// import { findLastIndex } from "lodash";

export const useStyles = makeStyles({
  tableHead: {
    // borderBottom: "2px solid #14213d",
    // borderTop: "2px solid rgba(99, 180, 209, 0.2)",
    background: "rgb(20, 33, 61)",
  },
  tableHeadCell: {
    color: "rgba(255,255,255,0.9)",
    textTransform: "uppercase",
    fontSize: "16px",
  },
  tableContainer: {
    // borderRadius: "6px",
    height: "90vh",
    borderRadius: "12px",
  },
  searchButton: {
    marginLeft: "5px",
    color: "white",
  },
  searchField: {
    padding: "5px 10px",
  },
  downloadButton: {
    margin: "5px 20px",
    color: "white",
    transition: "all 0.4s ease-out",
    "&:hover": {
      color: "black",
      background: "white",
    },
  },
});
