import { Grid, InputLabel, Paper, Typography } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  checkAcademicDocumentSelectionAtom,
  isUploadsViewEnabled,
  recruiteeBasicDetails
} from "../../../../../recoil/atoms";
import { UploadsContext } from "../../../../../store/uploadsStore";

import { useDropzone } from "react-dropzone";
import { getDocuments } from "../../../../../apis/onboarding";
import uploadDocument from "../../../../../assets/icons/uploadDocument.svg";
import { documentDownloadAtom } from "../../../../../recoil/downloadAtoms";
import { HandleDroppedAcademics } from "../HandleDroppedFiles/HandleDroppedAcademics";
import { useStyles } from "../styles";
import UploadedDocuments from "../UploadedDocuments";
import { IAcademicChildrenProps } from "./AcademicCertificates";

const Diploma = ({
  handleChange,
  setIsSubmit,
  showToast,
  getEndpoint,
  imgName,
  imgSize,
  setImgName,
  setImgSize,
}: IAcademicChildrenProps) => {
  const [, setIsFileSelected] = useRecoilState(
    checkAcademicDocumentSelectionAtom
  );
  const { academicProgress, academicProofs, setAcademicProof } =
    useContext(UploadsContext);
  const classes = useStyles();
  const mainUrl = process.env.REACT_APP_BACKEND_URL;

  const basicDetails = useRecoilValue(recruiteeBasicDetails);
  const [isViewEnabled, setIsViewEnabled] =
    useRecoilState(isUploadsViewEnabled);
  const [rewriteFileDownload, setRewriteFileDownload] =
    useRecoilState(documentDownloadAtom);
  const [isDisable, setIsDisable] = useState<boolean>(false);

  const url = mainUrl + getEndpoint;
  const parentKey = "academicCertificates";
  const childKey = "diploma";

  const getDocs = async () => {
    const response = await getDocuments(url, parentKey, childKey);
    if (response.status === 200 && response.data !== "") {
      setImgName((prev) => ({
        ...prev,
        diploma: `Diploma Certificate of ${basicDetails.name}`,
      }));
      setImgSize((prev) => ({
        ...prev,
        diploma: 0,
      }));
      setIsViewEnabled((prev) => ({
        ...prev,
        academic: true,
      }));
      setIsSubmit((prev: any) => ({
        ...prev,
        diploma: true,
      }));
      setAcademicProof((prev) => [
        ...prev,
        {
          name: "diploma",
          value: response.data,
        },
      ]);
    } else {
      setIsViewEnabled((prev) => ({
        ...prev,
        academic: false,
      }));
    }
  };

  useEffect(() => {
    getDocs();
  }, []);

  useEffect(() => {
    const length = academicProofs.length;
    if (length > 0) {
      for (let i = 0; i < length; i++) {
        if (academicProofs[i].name === "diploma" && imgName.diploma !== "") {
          setIsDisable(true);
          break;
        }
        if (imgName.diploma === "") {
          setIsDisable(false);
        }
      }
    }
  }, [academicProofs, imgName]);

  const {
    getRootProps: getRootPropsDiploma,
    getInputProps: getInputPropsDiploma,
  } = useDropzone({
    onDrop: (acceptedFilesDiploma) => {
      const file = acceptedFilesDiploma[0];
      const docType = "diploma";
      if (!isDisable) {
        HandleDroppedAcademics({
          docType,
          file,
          academicProofs,
          setAcademicProof,
          setIsFileSelected,
          setImgName,
          setImgSize,
          setRewriteFileDownload,
          setIsSubmit,
          showToast,
        });
      }
    },
    noClick: true,
  });

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      className={classes.docContainer}
    >
      <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
        <Paper
          variant="outlined"
          elevation={4}
          className={classes.paperContainer}
        >
          <InputLabel variant="outlined" className={classes.label} shrink>
            <b className={classes.longText}>Diploma Certificates</b>
          </InputLabel>

          <Grid container direction="column" className={classes.dropContainer}>
            <Grid item>
              <Grid
                container
                direction="column"
                spacing={2}
                className={classes.dashedContainer}
                {...getRootPropsDiploma()}
              >
                <input {...getInputPropsDiploma()} />
                <Grid item className={classes.itemStyle}>
                  <Typography className={classes.boldText}>
                    Drag and Drop files to upload
                  </Typography>
                </Grid>
                <Grid item className={classes.itemStyle}>
                  <Typography className={classes.instruction}>
                    Maximum upload size 1MB
                  </Typography>
                </Grid>

                <Grid item className={classes.itemStyle}>
                  <input
                    type="file"
                    name="diploma"
                    id="diploma"
                    className={classes.inputfile}
                    onChange={(event) => handleChange(event)}
                    onClick={(event) => {
                      //@ts-ignore
                      event.target.value = null;
                    }}
                    disabled={isDisable}
                  />
                  <label htmlFor="diploma" className={classes.inputlabel}>
                    <i className={classes.inputButtonIcon}>
                      <img src={uploadDocument} />
                    </i>
                    &nbsp; Upload File
                  </label>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className={classes.itemStyle}
            >
              {imgName.diploma !== "" ? (
                <UploadedDocuments
                  progress={academicProgress}
                  imageName={imgName.diploma}
                  imageSize={imgSize.diploma}
                  label="diploma"
                  getEndpoint={getEndpoint}
                  setImgName={setImgName}
                />
              ) : (
                <Typography className={classes.instruction}>
                  No files selected
                </Typography>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Diploma;
