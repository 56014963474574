import { Theme, makeStyles } from "@material-ui/core/styles";


export const useStyles = makeStyles((theme: Theme) => ({
    button:{
        
        backgroundColor:"#FFF",
        whiteSpace:"nowrap",

    }
}))