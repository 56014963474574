import { Popover, Typography } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useState } from "react";
import { useStyles } from "./styles";

interface StatusButtonProps {
  status: string;
}
const StatusButton: React.FC<StatusButtonProps> = ({ status }) => {
  const classes = useStyles();
  const isPending = status === "pending";

  const [anchorPending, setAnchorPending] = useState<HTMLElement | null>(null);
  const [anchorComplete, setAnchorComplete] = useState<HTMLElement | null>(
    null
  );

  const openComplete = Boolean(anchorComplete);
  const openPending = Boolean(anchorPending);

  // Open Complete Popover
  const openPopoverComplete = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorComplete(event.currentTarget);
  };

  // Close Complete Popover
  const closePopoverComplete = () => {
    setAnchorComplete(null);
  };

  // Open Pending Popover
  const openPopoverPending = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorPending(event.currentTarget);
  };

  // Close Pending Popover
  const closePopoverPending = () => {
    setAnchorPending(null);
  };

  return (
    <>
      {/* Status Button */}
      <div
        aria-owns={openPending ? "popover-pending" : "popover-completed"}
        aria-haspopup="true"
        onMouseEnter={isPending ? openPopoverPending : openPopoverComplete}
        onMouseLeave={isPending ? closePopoverPending : closePopoverComplete}
        className={
          status === "pending" ? classes.statusPending : classes.statusComplete
        }
      >
        <b>{status}</b>
        <VisibilityIcon fontSize="small" />
      </div>

      {/* Pending Popover */}
      <Popover
        id="popover-pending"
        className={classes.popover}
        classes={{
          paper: classes.popoverPaper,
        }}
        open={openPending}
        anchorEl={anchorPending}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={closePopoverPending}
        disableRestoreFocus
      >
        <Typography>Pending</Typography>
      </Popover>

      {/* Complete Popover */}
      <Popover
        id="popover-complete"
        className={classes.popover}
        classes={{
          paper: classes.popoverPaper,
        }}
        open={openComplete}
        anchorEl={anchorComplete}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={closePopoverComplete}
        disableRestoreFocus
      >
        <Typography>Completed</Typography>
      </Popover>
    </>
  );
};

export default StatusButton;
