import React, { useEffect, useState } from "react";
import { Grid, Paper, Box, Typography, Button } from "@material-ui/core";
import { useStyles } from "./styles";
import AvatarEdit from "../AvatarEdit";
import Checkbox from "@material-ui/core/Checkbox";
// import pencilIcon from "../../../../assets/icons/pencil.svg";
import profile from "../../assets/images/profileBG.svg";
import pencilIcon from "../../assets/icons/pencil.svg";
import FormValues from "../../models/employeeDetailsType";
import { TextFieldProps, TextField } from "@material-ui/core";
import {
  Formik,
  FormikHelpers,
  FormikProps,
  Form,
  Field,
  FormikValues,
} from "formik";
import * as formik from "formik";
import * as yup from "yup";
import { FormTextField } from "../FormtextField";
import KeyValueWrapper from "../KeyValueWrapper";
import CheckboxField from "../checkbox-field";
import ValidationSchema from "./ValidationSchema";
import { useRecoilState, useRecoilValue } from "recoil";
import { profileItemsAtom } from "../../recoil/atoms";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarComponent from "../SnackbarComponent";
import {
  updateBaiscDetails,
  updateBaiscDetailsProfile,
} from "../../apis/employees";
import { employeeNormalDataUpdatePermission } from "../../utils/functions";
import { basicDetailsBackendType } from "../../models/employeeModuleType";
import { usePermissionObjectFor } from "../../hooks/permission/usePermissionObjectFor";

//gender selection options
const genderSelectOptions = [
  {
    value: "male",
    label: "Male",
  },
  {
    value: "female",
    label: "Female",
  },
  {
    value: "other",
    label: "Other",
  },
];

//gender selection options
const maritalStatusSelectOptions = [
  {
    value: "Married",
    label: "Married",
  },
  {
    value: "Unmarried",
    label: "Unmarried",
  },
];

//bloodgroup selection options
const bloodGroupSelectOptions = [
  {
    value: "A +ve",
    label: "A +ve",
  },
  {
    value: "A -ve",
    label: "A -ve",
  },
  {
    value: "B +ve",
    label: "B +ve",
  },
  {
    value: "B -ve",
    label: "B -ve",
  },
  {
    value: "O +ve",
    label: "O +ve",
  },
  {
    value: "O -ve",
    label: "O -ve",
  },
  {
    value: "AB +ve",
    label: "AB +ve",
  },
  {
    value: "AB -ve",
    label: "AB -ve",
  },
];

const validationSchema = yup
  .object()
  .shape(ValidationSchema.BasicDetailsValidationSchema);

interface Props {
  employeeId: string;
  basicDetailsInitialData: any | null;
  setBasicDetailsInitialdataProp: React.Dispatch<React.SetStateAction<null>>;
  loaderStateProps?: boolean;
}

const BasicDetailsEdit: React.FC<Props> = ({
  basicDetailsInitialData,
  employeeId,
  setBasicDetailsInitialdataProp,
  loaderStateProps = true,
}) => {
  const classes = useStyles();
  const empInfoTobBarRecoilState = useRecoilValue(profileItemsAtom);

  const [empProfileIconData, setEmpProfileIconData] =
    useRecoilState(profileItemsAtom);
  //dummy data
  const userData: FormValues = {
    fullName: "",
    email: "",
    personalEmail: "",
    contactNo: "",
    dateOfBirth: "",
    gender: "",
    maritalStatus: "",
    bloodGroup: "",
    adharCardNo: "",
    voterIdNo: "",
    passportNo: "",
    presentCity: "", //incorrect key name
    presentAddress: "",
    presentState: "",
    presentPinCode: "",
    permanentCity: "",
    permanentAddress: "",
    permanentState: "",
    permanentPinCode: "",
    isPermanentAddressDifferent: false,
    emergencyContactName: "",
    emergencyContactNo: "",
    emergencyContactRelationship: "", ///incorrect key name
    age: 0, //incorrect key name
    panCardNo: "",
    profileImage: profile,
  };

  const [isEditableBasicDetails, setIsEditableBasicDetailsEdit] =
    useState(false);
  const [basicDetailsData, setBasicDetailsData] = useState(userData);

  const [currentProfileImage, setCurrentProfileImage] = useState(profile);

  //state for snackbar
  const [snackbarState, setSnackbarState] = useState({
    openBooleanProp: false,
    messageProp: "dummy message state",
    statusProp: "info",
  });

  const permission = usePermissionObjectFor("employees");
  const employeeNormalDataUpdatePermission =permission.required([ "upDate","read"]);
  
  const setInitialData = () => {
    const initData = {
      fullName: basicDetailsInitialData?.fullName
        ? basicDetailsInitialData?.fullName
        : "",
      email: basicDetailsInitialData?.email
        ? basicDetailsInitialData?.email
        : "",
      personalEmail: basicDetailsInitialData?.personalEmail
        ? basicDetailsInitialData?.personalEmail
        : "",
      contactNo: basicDetailsInitialData?.phoneNumber
        ? basicDetailsInitialData?.phoneNumber
        : "",
      dateOfBirth: basicDetailsInitialData?.dateOfBirth
        ? basicDetailsInitialData?.dateOfBirth
        : "",
      gender: basicDetailsInitialData?.gender
        ? basicDetailsInitialData?.gender
        : "",
      maritalStatus: basicDetailsInitialData?.martialStatus
        ? basicDetailsInitialData?.martialStatus
        : "",
      bloodGroup: basicDetailsInitialData?.bloodGroup
        ? basicDetailsInitialData?.bloodGroup
        : "",
      adharCardNo: basicDetailsInitialData?.adhaarNumber
        ? basicDetailsInitialData?.adhaarNumber
        : "",
      voterIdNo: basicDetailsInitialData?.voterID
        ? basicDetailsInitialData.voterID
        : "",
      passportNo: basicDetailsInitialData?.passportNumber
        ? basicDetailsInitialData.passportNumber
        : "",
      presentCity: basicDetailsInitialData?.presentAddress?.city
        ? basicDetailsInitialData.presentAddress.city
        : "",
      presentAddress: basicDetailsInitialData?.presentAddress?.address
        ? basicDetailsInitialData.presentAddress.address
        : "",
      presentState: basicDetailsInitialData?.presentAddress?.state
        ? basicDetailsInitialData.presentAddress.state
        : "",
      presentPinCode: basicDetailsInitialData?.presentAddress?.pinCode
        ? basicDetailsInitialData.presentAddress.pinCode
        : "",
      permanentCity: basicDetailsInitialData?.permanentAddress?.city
        ? basicDetailsInitialData.permanentAddress.city
        : "",
      permanentAddress: basicDetailsInitialData?.permanentAddress?.address
        ? basicDetailsInitialData.permanentAddress.address
        : "",
      permanentState: basicDetailsInitialData?.permanentAddress?.state
        ? basicDetailsInitialData.permanentAddress.state
        : "",
      permanentPinCode: basicDetailsInitialData?.permanentAddress?.pinCode
        ? basicDetailsInitialData.permanentAddress.pinCode
        : "",
      isPermanentAddressDifferent:
        basicDetailsInitialData?.isPermanentAddressDifferent
          ? basicDetailsInitialData.isPermanentAddressDifferent
          : true,

      emergencyContactName: basicDetailsInitialData?.emergencyContactName
        ? basicDetailsInitialData?.emergencyContactName
        : "",
      emergencyContactNo: basicDetailsInitialData?.emergencyContactNumber
        ? basicDetailsInitialData?.emergencyContactNumber
        : "",
      emergencyContactRelationship:
        basicDetailsInitialData?.emergencyContactRelation
          ? basicDetailsInitialData?.emergencyContactRelation
          : "",
      age: basicDetailsInitialData?.age ? basicDetailsInitialData?.age : 1,
      panCardNo: basicDetailsInitialData?.panNumber
        ? basicDetailsInitialData?.panNumber
        : "",
      profileImage: basicDetailsInitialData?.avatar
        ? basicDetailsInitialData?.avatar.url
        : profile,
    };
    setBasicDetailsData(initData);

    //set current profile image
    setCurrentProfileImage(
      basicDetailsInitialData?.avatar
        ? basicDetailsInitialData?.avatar.url
        : profile
    );
  };

  useEffect(() => {
    setInitialData();
  }, [basicDetailsInitialData]);

  const getAge = (dob: string) => {
    const today = new Date();

    const birthdate = new Date(dob);
    const age = today.getFullYear() - birthdate.getFullYear();
    return age;
  };

  const updateFormdata = async (data: FormValues) => {
    //format the data as in the backend
    let dataToUpdate: basicDetailsBackendType = {
      fullName: data.fullName,
      email: data.email,
      personalEmail: data.personalEmail,
      phoneNumber: data.contactNo.toString(),
      dateOfBirth: data.dateOfBirth,
      gender: data.gender,
      martialStatus: data.maritalStatus,
      bloodGroup: data.bloodGroup,
      adhaarNumber: data.adharCardNo.toString(),
      voterID: data.voterIdNo,
      panNumber: data.panCardNo,
      age: data.age,
      passportNumber: data.passportNo,
      presentAddress: {
        city: data.presentCity,
        address: data.presentAddress,
        state: data.presentState,
        pinCode: data.presentPinCode,
      },

      isPermanentAddressDifferent: data.isPermanentAddressDifferent,
      emergencyContactName: data.emergencyContactName,
      emergencyContactNumber: data.emergencyContactNo.toString(),
      emergencyContactRelation: data.emergencyContactRelationship,
    };

    if (data.isPermanentAddressDifferent) {
      dataToUpdate.permanentAddress = {
        city: data.permanentCity,
        address: data.permanentAddress,
        state: data.permanentState,
        pinCode: data.permanentPinCode,
      };
    } else {
      dataToUpdate.permanentAddress = {
        city: data.presentCity,
        address: data.presentAddress,
        state: data.presentState,
        pinCode: data.presentPinCode,
      };
    }

    //update profile image
    const updatedProfileImage = data.profileImage;

    //data update
    try {
      await updateBaiscDetails(employeeId, dataToUpdate);
      if (
        updatedProfileImage !== profile &&
        updatedProfileImage !== basicDetailsInitialData?.avatar?.url &&
        updatedProfileImage !== undefined
      ) {
        const avatarData = new FormData();
        avatarData.append("avatar", updatedProfileImage);

        await updateBaiscDetailsProfile(employeeId, avatarData);
        setBasicDetailsData(data);
        let obj = avatarData;
        dataToUpdate.avatar = obj;
        //show a toast

        //IF successful then change the button to edit else don't do
        setIsEditableBasicDetailsEdit((previous) => !previous);

        //set form + new profile update
        setEmpProfileIconData({
          name: data.fullName,
          src: currentProfileImage,
          designation: empInfoTobBarRecoilState?.designation,
          assignedTo:"",
          projectAssigned:"",
        });

        //show toast
        setSnackbarState({
          openBooleanProp: true,
          messageProp: "successfully updated",
          statusProp: "success",
        });
      } else {
        //no change in profile so no need to patch
        setBasicDetailsData(data);
        setIsEditableBasicDetailsEdit((previous) => !previous);
        setCurrentProfileImage(
          basicDetailsInitialData?.avatar
            ? basicDetailsInitialData?.avatar.url
            : profile
        );

        //set form + new profile update
        setEmpProfileIconData({
          name: data.fullName,
          src: currentProfileImage,
          designation: empInfoTobBarRecoilState?.designation,
          assignedTo:"",
          projectAssigned:"",
        });

        //show toast
        setSnackbarState({
          openBooleanProp: true,
          messageProp: "successfully updated",
          statusProp: "success",
        });
      }
    } catch (err: any) {
      //show toast
      setSnackbarState({
        openBooleanProp: true,
        messageProp: `something went wrong ${err}`,
        statusProp: "error",
      });
    }
  };

  const handleSubmit = (val: any) => {
    if (isEditableBasicDetails) {
      updateFormdata(val);
    }
  };

  return (
    <Box className={classes.basicDetailsViewMainContainer}>
      <SnackbarComponent snackbarStateProp={snackbarState} />
      {!loaderStateProps ? (
        <Box
          sx={{
            width: "100%",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Formik
          initialValues={basicDetailsData}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={(values, formikHelpers) => {
            // setIsEditableBasicDetailsEdit(!isEditableBasicDetails);
            formikHelpers.setSubmitting(false);
            handleSubmit(values);
          }}
        >
          {(formikProps: FormikProps<FormValues>) => (
            <Form noValidate autoComplete="off">
              <Grid container>
                <Box sx={{ width: "100%" }}>
                  <Grid
                    container
                    xs={12}
                    className={classes.basicDetailsViewMainContainer}
                  >
                    <Grid container item xs={12}>
                      <Grid item xs={4}>
                        <Grid container xs={12} direction="column">
                          <KeyValueWrapper
                            label={"Full Name"}
                            valueParam={formikProps.values.fullName}
                            name={"fullName"}
                            inputType={"textField"}
                            isEditable={isEditableBasicDetails}
                          />
                          <KeyValueWrapper
                            label={"Phone No."}
                            valueTypeProps={"number"}
                            valueParam={formikProps.values.contactNo}
                            name={"contactNo"}
                            inputType={"textField"}
                            isEditable={isEditableBasicDetails}
                          />
                          <KeyValueWrapper
                            label={"Date of Birth"}
                            valueParam={formikProps.values.dateOfBirth}
                            name={"dateOfBirth"}
                            inputType={"datePicker"}
                            isEditable={isEditableBasicDetails}
                            dependentFieldChanges={{
                              fieldName: "age",
                              functionChange: getAge,
                            }}
                            formikProps={formikProps}
                          />
                          <KeyValueWrapper
                            label={"Married"}
                            valueParam={formikProps.values.maritalStatus}
                            name={"maritalStatus"}
                            inputType={"selectField"}
                            isEditable={isEditableBasicDetails}
                            data={maritalStatusSelectOptions}
                          />
                          <KeyValueWrapper
                            label={"Aadhar No."}
                            valueTypeProps={"number"}
                            valueParam={formikProps.values.adharCardNo}
                            name={"adharCardNo"}
                            inputType={"textField"}
                            isEditable={isEditableBasicDetails}
                          />

                          <KeyValueWrapper
                            label={"Present Address"}
                            valueParam={formikProps.values.presentAddress}
                            name={"presentAddress"}
                            inputType={"textField"}
                            isEditable={isEditableBasicDetails}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <KeyValueWrapper
                          label={"Official Email Address"}
                          valueParam={formikProps.values.email}
                          name={"email"}
                          inputType={"textField"}
                          isEditable={isEditableBasicDetails}
                        />
                        <KeyValueWrapper
                          label={"Personal Email Address"}
                          valueParam={formikProps.values.personalEmail}
                          name={"personalEmail"}
                          inputType={"textField"}
                          isEditable={isEditableBasicDetails}
                        />

                        <KeyValueWrapper
                          label={"Gender"}
                          valueParam={formikProps.values.gender}
                          name={"gender"}
                          inputType={"selectField"}
                          isEditable={isEditableBasicDetails}
                          data={genderSelectOptions}
                        />
                        <KeyValueWrapper
                          label={"Age(Years)"}
                          valueTypeProps={"number"}
                          valueParam={formikProps.values.age ?? ""}
                          name={"age"}
                          inputType={"textField"}
                          isEditable={false}
                          endTextValue={" years"}
                        />

                        <KeyValueWrapper
                          label={"Blood Group"}
                          valueParam={formikProps.values.bloodGroup}
                          name={"bloodGroup"}
                          inputType={"selectField"}
                          isEditable={isEditableBasicDetails}
                          data={bloodGroupSelectOptions}
                        />
                        <KeyValueWrapper
                          label={"PAN NO."}
                          valueParam={formikProps.values.panCardNo}
                          name={"panCardNo"}
                          inputType={"textField"}
                          isEditable={isEditableBasicDetails}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <AvatarEdit
                          smallIcon={pencilIcon}
                          avatarIcon={formikProps.values.profileImage}
                          propFormik={formikProps}
                          isEditableProps={isEditableBasicDetails}
                          currentProfileImageProps={currentProfileImage}
                          setCurrentProfileImageProps={setCurrentProfileImage}
                        />

                        <KeyValueWrapper
                          label={"Voter ID No."}
                          valueParam={formikProps.values.voterIdNo}
                          name={"voterIdNo"}
                          inputType={"textField"}
                          isEditable={isEditableBasicDetails}
                        />

                        <KeyValueWrapper
                          label={"Passport No."}
                          valueParam={formikProps.values.passportNo}
                          name={"passportNo"}
                          inputType={"textField"}
                          isEditable={isEditableBasicDetails}
                        />
                      </Grid>
                    </Grid>

                    <Grid container item xs={12}>
                      <Grid item xs={4}>
                        <Grid container xs={12} direction="column">
                          <KeyValueWrapper
                            label={"City"}
                            valueParam={formikProps.values.presentCity ?? ""}
                            name={"presentCity"}
                            inputType={"textField"}
                            isEditable={isEditableBasicDetails}
                          />
                          <Grid
                            container
                            item
                            direction="row"
                            alignItems="center"
                            className={classes.checkBoxContainerStyle}
                          >
                            {/* <Checkbox
                          inputProps={{
                            "aria-label": "uncontrolled-checkbox",
                          }}
                          onChange={onHandleChange}
                        /> */}
                            <CheckboxField
                              name={"isPermanentAddressDifferent"}
                              isEditableProps={isEditableBasicDetails}
                              label={"Permanent Address different from above"}
                            />
                            {/* <Typography
                          className={classes.lightBigTypographyStyle}
                        >
                          Permanent Address different from above
                        </Typography> */}
                          </Grid>

                          {/* {formikProps.values.isPermanentAddressDifferent && (
                        <Grid container xs={12} direction="row">
                          <Grid container xs={12} direction="row">
                            <Grid item xs={12}>
                              <KeyValueWrapper
                                label={"Permanent Address"}
                                valueParam={
                                  formikProps.values.permanentAddress
                                }
                                name={"permanentAddress"}
                                inputType={"textField"}
                                isEditable={isEditableBasicDetails}
                              />
                            </Grid>
                          </Grid>
                          <Grid container xs={12} direction="row">
                            <Grid item xs={4}>
                              <KeyValueWrapper
                                label={"City"}
                                valueParam={formikProps.values.permanentCity}
                                name={"permanentCity"}
                                inputType={"textField"}
                                isEditable={isEditableBasicDetails}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <KeyValueWrapper
                                label={"State"}
                                valueParam={formikProps.values.permanentState}
                                name={"permanentState"}
                                inputType={"textField"}
                                isEditable={isEditableBasicDetails}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <KeyValueWrapper
                                label={"PIN Code"}
                                valueParam={
                                  formikProps.values.permanentPinCode
                                }
                                name={"permanentPinCode"}
                                inputType={"textField"}
                                isEditable={isEditableBasicDetails}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      )} */}
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <KeyValueWrapper
                          label={"State"}
                          valueParam={formikProps.values.presentState}
                          name={"presentState"}
                          inputType={"textField"}
                          isEditable={isEditableBasicDetails}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <KeyValueWrapper
                          label={"PIN Code"}
                          valueParam={formikProps.values.presentPinCode}
                          name={"presentPinCode"}
                          inputType={"textField"}
                          isEditable={isEditableBasicDetails}
                        />
                      </Grid>
                    </Grid>
                    {formikProps.values.isPermanentAddressDifferent && (
                      <>
                        <Grid container xs={12} direction="row">
                          <Grid item xs={4}>
                            <KeyValueWrapper
                              label={"Permanent Address"}
                              valueParam={formikProps.values.permanentAddress}
                              name={"permanentAddress"}
                              inputType={"textField"}
                              isEditable={isEditableBasicDetails}
                            />
                          </Grid>
                        </Grid>

                        <Grid container item xs={12}>
                          <Grid item xs={4}>
                            <Grid container xs={12} direction="column">
                              <KeyValueWrapper
                                label={"City"}
                                valueParam={formikProps.values.permanentCity}
                                name={"permanentCity"}
                                inputType={"textField"}
                                isEditable={isEditableBasicDetails}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={4}>
                            <KeyValueWrapper
                              label={"State"}
                              valueParam={formikProps.values.permanentState}
                              name={"permanentState"}
                              inputType={"textField"}
                              isEditable={isEditableBasicDetails}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <KeyValueWrapper
                              label={"PIN Code"}
                              valueParam={formikProps.values.permanentPinCode}
                              name={"permanentPinCode"}
                              inputType={"textField"}
                              isEditable={isEditableBasicDetails}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}

                    <Grid container item xs={4}>
                      <KeyValueWrapper
                        label={"Emergency Contact No."}
                        valueParam={"Whom we can contact in case of emergency"}
                        name={"emergencyContactNo"}
                        inputType={"textField"}
                        isEditable={false}
                      />
                    </Grid>

                    <Grid container item xs={12}>
                      <Grid item xs={4}>
                        <Grid container xs={12} direction="column">
                          <KeyValueWrapper
                            label={"Full Name"}
                            valueParam={formikProps.values.emergencyContactName}
                            name={"emergencyContactName"}
                            inputType={"textField"}
                            isEditable={isEditableBasicDetails}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <KeyValueWrapper
                          label={"Relationship"}
                          valueParam={
                            formikProps.values.emergencyContactRelationship
                          }
                          name={"emergencyContactRelationship"}
                          inputType={"textField"}
                          isEditable={isEditableBasicDetails}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <KeyValueWrapper
                          label={"Phone No."}
                          valueTypeProps={"number"}
                          valueParam={formikProps.values.emergencyContactNo}
                          name={"emergencyContactNo"}
                          inputType={"textField"}
                          isEditable={isEditableBasicDetails}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                {employeeNormalDataUpdatePermission && (
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    sx={{ width: "100%" }}
                  >
                    {isEditableBasicDetails ? (
                      <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        // onClick={() => setIsEditableBasicDetailsEdit(false)}
                        disabled={formikProps.isSubmitting}
                      >
                        Save
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsEditableBasicDetailsEdit(true);
                        }}
                      >
                        Edit
                      </Button>
                    )}
                  </Box>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};

export default BasicDetailsEdit;
