import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import { MenuItem, Select } from "@material-ui/core";
import useStyles from "./styles";

interface Props {
  type: "tracker" | "form";
  options: any;
  onSelect: any;
  label?: string;
  value?: any;
  name?: string;
  labelIdentifier?: string;
  valueIdentifier?: string;
  disabled?: boolean;
  multiple?: boolean;
  onBlur?: any;
}
const SelectField: React.FC<Props> = ({
  type,
  options,
  onSelect,
  label,
  value,
  labelIdentifier = "item",
  valueIdentifier = "item",
  name,
  disabled,
  multiple,
  onBlur,
  ...props
}) => {
  const classes = useStyles();

  return (
    <>
      <InputLabel className={classes.label} id="demo-simple-select-label">
        {label}
      </InputLabel>
      <Select
        className={type === "tracker" ? classes.dropdown : classes.form}
        variant="standard"
        disableUnderline={type === "tracker" ? true : false}
        onChange={onSelect}
        name={name}
        onBlur={onBlur}
        disabled={disabled}
        multiple={multiple}
        value={value}
        defaultValue={"this week"}
        {...props}
        inputProps={{
          classes: {
            root: classes.input,
          },
        }}
      >
        {options?.map((item: any) => {
          let label = typeof item === "object" ? item[labelIdentifier] : item;
          let valueField =
            typeof item === "object" ? item[valueIdentifier] : item;
          return <MenuItem value={valueField}>{label}</MenuItem>;
        })}
      </Select>
    </>
  );
};

export default SelectField;
