import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  searchField: {
    border: "1px solid rgba(20, 33, 61, 0.1)",
    backgroundColor: "#FFFFFF",
    borderRadius: "5px",
    maxWidth: "25rem",
    padding: " 0 .3rem",
  },
  filterButton: {
    maxWidth: "20%",
    position: "relative",
    left: "1.5rem",
  },
  leftHeader: {
    paddingTop: ".7rem",
  },
  footer: {
    marginTop: ".2rem",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  dialog: {
    "&.MuiPaper-root": {
      boxShadow: "none",
      borderRadius: "10px",
    },
  },

  addnewcontent: {
    minWidth: "25%",
    float: "right",
    paddingTop: ".5rem",
  },
  table: {
    marginTop: ".6rem",
  },

  categoryItems: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    padding: `${theme.typography.pxToRem(11)} ${theme.typography.pxToRem(16)}`,
    cursor: "pointer",
  },

  filterLabels: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    color: "#000",
    margin: 0,
    minWidth: "5rem",
  },

  filter: {
    border: "1px solid rgba(20, 33, 61, 0.1)",
    borderRadius: "5px",
    background: "#fff",
  },

  filterCategory: {
    position: "absolute",
    borderRadius: "5px",
    background: "#fff",
    border: "1px solid rgba(20, 33, 61, 0.1)",
    boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.1)",
    marginTop: theme.typography.pxToRem(2),
    minWidth: "10rem",
  },

  filterCategoryHide: {
    display: "none",
  },
  typemenu: {
    border: "1px solid grey",
    minwidth: "fit-content",
    padding: ".5rem",
    borderRadius: "5px",
  },
  published: {
    color: "#BBBE1A",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "15px",
  },
  approved: {
    color: "#1ABE2A",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "15px",
  },
  sentback: {
    color: "#BE1A90",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "15px",
  },
  rejected: {
    color: "#BE1A1A",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "15px",
  },
  adminStatus: {
    columnGap:"1.5rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  status: {
    display: "flex",
    alignItems: "center",
    maxWidth: "15rem",
    minWidth: "9rem",
    justifyContent: "space-between",
  },
  userStatus:{
    columnGap:"3rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  statusValue:{
    minWidth:"6rem"
  }
}));
