import { Box } from "@material-ui/core";
import { useStyles } from "./styles";

interface ILeaveCard {
  empName: string;
  duration: number;
  leaveType: string;
  adjustAmount: number;
}

interface ILeaveTypeList {
  [key: string]: string;
}

const leaveTypeList: ILeaveTypeList = {
  "61c456dea1419efe2fe52e37": "PL",
  "61c45719a1419efe2fe52e39": "CL",
  "61c45721a1419efe2fe52e3b": "SL",
};

const LeaveCard = ({
  empName,
  duration,
  leaveType,
  adjustAmount,
}: ILeaveCard) => {
  const classes = useStyles();

  return (
    <Box className={classes.leaveCard}>
      <h5 style={{ width: "75%", margin: 0, textAlign: "left" }}>{empName}</h5>
      <Box className={classes.leaveTooltip}>
        <p style={{ margin: 0, width: "50%" }}> {duration + adjustAmount}</p>
        <p
          style={{
            margin: 0,
            background: "#140047",
            color: "white",
            width: "50%",
          }}
        >
          {leaveTypeList[leaveType]}
        </p>
      </Box>
    </Box>
  );
};

export default LeaveCard;
