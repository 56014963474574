interface Props {
  dataProps: {
    value: string | number | boolean;
    label: string | number | boolean;
  }[];
  keyNameProp: string | number | boolean;
}

const SelectViewValueFinder = (dataProps: any, keyNameProp: any) => {
  let res = "";

  if (dataProps) {
    dataProps.find((item: any) => {
      if (keyNameProp === item.value) {
        res = item.label;
      }
    });
  }
  return res;
};

export default SelectViewValueFinder;
