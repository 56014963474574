import moment from "moment";

export function isFirstNDaysOfTheQuarter(n: number) {
  const currentDate = moment();
  const currentQuarterStart = moment().startOf("quarter");
  const firstNDaysOfQuarterEnd = moment(currentQuarterStart).add(n - 1, "days");
  const isTrue =
    currentDate.isSameOrAfter(currentQuarterStart) &&
    currentDate.isSameOrBefore(firstNDaysOfQuarterEnd);

  return isTrue;
}
