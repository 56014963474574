import React from "react";
import { Button, IconButton } from "@material-ui/core";
import CallMadeIcon from "@material-ui/icons/CallMade";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useStyles } from "./styles";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import { getPersistentAuthentication } from "../../utils/functions";
import { CheckAllAdmins } from "../../utils/CheckPermissions";

interface Props {
  name?: string;
  buttonOption?: string;
  permission: boolean;
  id: string;
  assignedId: string;
}

const AssignDiv: React.FC<Props> = ({
  name,
  buttonOption,
  permission,
  id,
  assignedId,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const userId = getPersistentAuthentication()?.userId;
  const username = getPersistentAuthentication()?.username;

  const { path } = useRouteMatch();

  const isAllAdmin = CheckAllAdmins();
  let canSeeDetailPage = false;
  if (id === userId || isAllAdmin || userId === assignedId ) {
    canSeeDetailPage = true;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        gap: "2em",
      }}
    >
      <div style={{ width: "42%" }}>{name}</div>
      {canSeeDetailPage && (
        <Link
          to={{
            pathname: `${path}/details/${id}`,
            state: { id },
          }}
        >
          <IconButton>
            <VisibilityIcon />
          </IconButton>
        </Link>
      )}
    </div>
  );
};

export default AssignDiv;
