import { useState, useEffect } from "react";

function useDebounce<T>(value: T, delay?: number): T {
    const [thisState, setThisState] = useState(value);

    useEffect(() => {
        const timer = setTimeout(() => {
            setThisState(value);
        }, delay || 500);

        return () => {
            clearTimeout(timer);
        };
    }, [value, delay]);

    return thisState;
}

export default useDebounce;
