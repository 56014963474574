import { Grid, Step, StepLabel, Stepper } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useBasicNav } from "../../utils/useBasicNav";
import { useStyles } from "./styles";
import { StepIconProps } from "@material-ui/core/StepIcon";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import PublishIcon from "@material-ui/icons/Publish";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import OnboardingAccordion from "../../components/Accordion/OnboardingAccordion";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  accrChecklistExpansion,
  accrCompanyEmailExpansion,
  accrExpansionState,
  accrUploadsExpansion,
} from "../../recoil/AccordionExpansion";
import {
  circularProgressStrokeAtom,
  enableClickAtom,
  onboardingAccordionStepsAtom,
  permissions,
  recruiteeBasicDetails,
} from "../../recoil/atoms";
import { UploadsProvider } from "../../store/uploadsStore";
import { StepperSubmitButton } from "./utils";
import { ColorlibConnector } from "./styles";
import StepTabs from "./StepTabs";

interface props {
  showToast: any;
  stepNumber: number;
  setStepNumber: React.Dispatch<React.SetStateAction<number>>;
  setIsFinish: React.Dispatch<React.SetStateAction<boolean>>;
  recruiteeDetails: {
    designation: string;
    emailID: string;
    name: string;
    mobileNo: number;
    status: string;
    _id: string;
  };
  loadPrimary: (event: any, state: any) => void;
}

const StepperConditional: React.FC<props> = ({
  stepNumber,
  setStepNumber,
  showToast,
  setIsFinish,
  loadPrimary,
}) => {
  useBasicNav("onboarding");
  const classes = useStyles();

  const [onboardingAccordionStep, setOnboardingAccordionStep] = useRecoilState(
    onboardingAccordionStepsAtom
  );

  const [permission, setPermission] = useRecoilState<any>(permissions);
  const allowOnboarding =
    permission?.roleName === "superadmin" ||
    permission?.roleName === "Admin" ||
    permission?.roleName === "Human Resource";

  const [completed1, setCompleted1] = useState<boolean>(false);
  const [completed2, setCompleted2] = useState<boolean>(false);
  const [completed3, setCompleted3] = useState<boolean>(false);
  const [completed4, setCompleted4] = useState<boolean>(false);

  const accordionState = useRecoilValue(accrExpansionState);
  const accrStepTwo = useRecoilValue(accrUploadsExpansion);
  const accrStepThree = useRecoilValue(accrCompanyEmailExpansion);
  const accrStepFour = useRecoilValue(accrChecklistExpansion);

  const [strokeVal, setStrokeVal] = useRecoilState(circularProgressStrokeAtom);
  const enableClick = useRecoilValue(enableClickAtom);
  const onboardUser = useRecoilValue(recruiteeBasicDetails);
  const [activeStep, setActiveStep] = useState(0);
  const [uploadDocsEndpointGet, setUploadDocsEndpointGet] =
    useState<string>("");
  const [uploadDocsEndpointPatch, setUploadDocsEndpointPatch] =
    useState<string>("");
  const [headerPropsGet, setHeaderPropsGet] = useState<any>({});
  const [headerPropsPatch, setHeaderPropsPatch] = useState<any>({});
  const [accessToken, setAccessToken] = useState<string>("");
  const [userRole, setUserRole] = useState<string>("");
  const [onboardUserID, setonboardUserID] = useState<string>("");

  useEffect(() => {
    setonboardUserID(window.sessionStorage.getItem("onbId") as string);
    const user_role = window.sessionStorage.getItem("userRole") as string;
    const access_token = window.sessionStorage.getItem(
      "access_token"
    ) as string;
    setUserRole(user_role);
    setAccessToken(access_token);
  }, []);

  useEffect(() => {
    getUserCredentials();
  }, [userRole]);

  useEffect(() => {
    setonboardUserID(window.sessionStorage.getItem("onbId") as string);
    getUserCredentials();
  }, [window.sessionStorage.getItem("onbId")]);

  const getUserCredentials = () => {
    if (userRole === "temp-user") {
      setUploadDocsEndpointGet("/api/on-boarding/temp-get-uploaded-documents");
      setUploadDocsEndpointPatch("/api/on-boarding/temp-upload-docs");
      setHeaderPropsPatch({
        "Content-type": "multipart/form-data",
        Authorization: "Bearer " + accessToken,
      });
      setHeaderPropsGet({
        Authorization: "Bearer " + accessToken,
      });
    } else {
      setUploadDocsEndpointGet(
        `/api/on-boarding/get-uploaded-documents/${window.sessionStorage.getItem(
          "onbId"
        )}`
      );
      setUploadDocsEndpointPatch(
        `/api/on-boarding/upload-docs/${window.sessionStorage.getItem("onbId")}`
      );
      setHeaderPropsPatch({
        "Content-type": "multipart/form-data",
      });
      setHeaderPropsGet({
        Authorization: "Bearer ",
        accessToken,
      });
    }
  };

  const getSteps = () => {
    if (allowOnboarding) {
      return ["Recruitee Details", "Uploads", "Company Email", "Checklist"];
    } else {
      return ["Recruitee Details", "Uploads"];
    }
  };

  const steps = getSteps();

  const setTabSteps = (tabStep: number) => {
    setActiveStep(tabStep);
    setStepNumber(tabStep);
    setOnboardingAccordionStep({ step: tabStep });
  };

  const setClasses = () => {
    return classes.stepActive;
  };
  const callSetClasses = setClasses();

  const ColorlibStepIcon = (props: StepIconProps) => {
    const icons: { [index: string]: React.ReactElement } = {
      1: (
        <Grid container direction="column">
          <Grid item>
            <DescriptionOutlinedIcon
              className={
                stepNumber === 0 ? classes.stepActive : classes.stepComplete
              }
              // className={callSetClasses}
              // className={strokeVal.strokeValue === 0 ? classes.stepActive : classes.stepComplete}
            />
            {stepNumber >= 1 && setCompleted1(true)}
          </Grid>
        </Grid>
      ),
      2: (
        <div>
          <PublishIcon
            className={
              stepNumber === 0
                ? classes.stepInactive
                : stepNumber === 1
                ? classes.stepActive
                : classes.stepComplete
            }
          />
          {stepNumber >= 2 && setCompleted2(true)}
        </div>
      ),
      3: (
        <div>
          <EmailOutlinedIcon
            className={
              stepNumber === 0 || stepNumber === 1
                ? classes.stepInactive
                : stepNumber === 2
                ? classes.stepActive
                : classes.stepComplete
            }
          />
          {stepNumber >= 3 && setCompleted3(true)}
        </div>
      ),
      4: (
        <div>
          <CheckOutlinedIcon
            className={
              stepNumber === 0 || stepNumber === 1 || stepNumber === 2
                ? classes.stepInactive
                : stepNumber === 3
                ? classes.stepActive
                : classes.stepComplete
            }
          />
          {stepNumber >= 4 && setCompleted4(true)}
        </div>
      ),
    };

    return <div>{icons[String(props.icon)]}</div>;
  };

  const handleNext = () => {
    if (activeStep !== steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setStepNumber((prevActiveStep: number) => prevActiveStep + 1);
      setOnboardingAccordionStep({ step: activeStep + 1 });
      setStrokeVal({ strokeValue: strokeVal.strokeValue + 1 });
      setIsFinish(false);
    } else {
      setIsFinish(true);
    }
  };

  const isComplete = (label: string) => {
    if (completed1 && label === "Recruitee Details") {
      return <span className={classes.completedText}>Completed</span>;
    }
    if (completed2 && label === "Uploads") {
      return <span className={classes.completedText}>Completed</span>;
    }
    if (completed3 && label === "Company Email") {
      return <span className={classes.completedText}>Completed</span>;
    }
    if (completed4 && label === "Checklist") {
      return <span className={classes.completedText}>Completed</span>;
    }
  };

  return (
    <div>
      <UploadsProvider>
        <div>
          <Stepper
            className={classes.stepperBackground}
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                  <br />
                  {isComplete(label)}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <Grid container className={classes.tabsContainer}>
          {/* {getStepTab(activeStep)} */}
          <StepTabs
            activeStep={activeStep}
            stepNumber={stepNumber}
            setTabSteps={setTabSteps}
          />
        </Grid>
        <div className={classes.accordionsContainer}>
          {stepNumber == 0 &&
            accordionState.map((accordion, index) => {
              return (
                <OnboardingAccordion
                  title={accordion.title}
                  showToast={showToast}
                  index={index}
                  stepNumber={stepNumber}
                  loadPrimary={loadPrimary}
                >
                  <accordion.component
                    showToast={showToast}
                    index={index}
                    // loadPrimary={loadPrimary}
                  />
                </OnboardingAccordion>
              );
            })}

          {stepNumber == 1 &&
            accrStepTwo.map((accordionTwo, index) => {
              return (
                <OnboardingAccordion
                  title={accordionTwo.title}
                  showToast={showToast}
                  index={index}
                  stepNumber={stepNumber}
                  loadPrimary={loadPrimary}
                >
                  <accordionTwo.component
                    showToast={showToast}
                    index={index}
                    getEndpoint={uploadDocsEndpointGet}
                    uploadDocsEndpointPatch={uploadDocsEndpointPatch}
                    headerPropsGet={headerPropsGet}
                    headerPropsPatch={headerPropsPatch}
                  />
                </OnboardingAccordion>
              );
            })}

          {allowOnboarding && stepNumber == 2 && (
            <OnboardingAccordion
              title={accrStepThree.title}
              showToast={showToast}
              index={0}
              stepNumber={stepNumber}
              loadPrimary={loadPrimary}
            >
              <accrStepThree.component showToast={showToast} index={0} />
            </OnboardingAccordion>
          )}

          {allowOnboarding &&
            stepNumber == 3 &&
            accrStepFour.map((accrFour, index) => {
              return (
                <OnboardingAccordion
                  title={accrFour.title}
                  showToast={showToast}
                  index={index}
                  stepNumber={stepNumber}
                  loadPrimary={loadPrimary}
                >
                  <accrFour.component showToast={showToast} index={index} />
                </OnboardingAccordion>
              );
            })}

          <Grid
            container
            justifyContent="flex-end"
            className={classes.buttonGrid}
          >
            {StepperSubmitButton(steps, activeStep, handleNext, showToast)}
          </Grid>
        </div>
      </UploadsProvider>
    </div>
  );
};

export default StepperConditional;
