import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({

  label:{
    margin: 0,
    fontSize:theme.typography.pxToRem(13),
    fontWeight:600,
    fontFamily:"poppins",
    color:"#000"
  },

  mainContainer:{
    marginTop:theme.typography.pxToRem(25),
  },

  fieldContainer:{
      background:"#FEFEFE",
      border: '1px solid #CDCDCD',
      borderRadius:"5px",
      padding:theme.typography.pxToRem(20),
      marginTop:theme.typography.pxToRem(13),
      marginBottom:theme.typography.pxToRem(17),
  },

  button:{
      marginLeft:theme.typography.pxToRem(13),
      marginTop:theme.typography.pxToRem(13),
  },

  dot:{
    background:"#0885DF",
    width:theme.typography.pxToRem(3),
    height:theme.typography.pxToRem(3),
    borderRadius:"50%",
    marginRight: theme.typography.pxToRem(5),
    marginLeft: theme.typography.pxToRem(5)
  },

  desigLabel:{
    margin: 0,
    fontSize:theme.typography.pxToRem(13),
    fontWeight:400,
    fontFamily:"Poppins",
    color:"#000",
    marginRight: theme.typography.pxToRem(5)
  },

  list:{
    marginTop: theme.typography.pxToRem(5)
  },

  deleteIconClass:{
    cursor:"pointer"
  },

  fieldContainerHide:{
    display:"none"
  },

  listHide:{
    display:"none"
  },
  
  deleteIconClassHide:{
    display:"none"
  },

  labelHide:{
    display:"none"
  }

}));
