import axios from "./axios.global";

const leadersEndPoint = "/employees/dev/assigned-to";

export const getAllEmployees = async (endpoint: string) => {
  try {
    return await axios.get(endpoint);
  } catch (err: any) {
    return err;
  }
};

export const getLeaders = async () => {
  try {
    return await axios.get(leadersEndPoint);
  } catch (err: any) {
    return err;
  }
};

export const getEmployeeLeave = async (employeeID: string) => {
  return await axios.get(`/employees/${employeeID}/leaves`);
};

//basic details
export const getBaiscDetails = async (employeeId: string) => {
  return await axios.get(`/employees/${employeeId}/basic-details`);
};

export const updateBaiscDetails = async (
  employeeId: string,
  payload: object
) => {
  return await axios.patch(`/employees/${employeeId}/basic-details`, payload);
};

export const updatedLeaveBalance = async (
  employeeId: string,
  payload: object
) => {
  return await axios.patch(`/leave-record/adjust/${employeeId}`, payload);
};

export const updateBaiscDetailsProfile = async (
  employeeId: string,
  payload: object
) => {
  try {
    return await axios.patch(
      `/employees/upload-profile-image/${employeeId}`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (err: any) {
    return err;
  }
};

//educational qualification
export const getEducationalQualification = async (employeeId: string) => {
  try {
    return await axios.get(`/employees/${employeeId}/education-qualification`);
  } catch (err: any) {
    return err;
  }
};

export const updateEducationalQualification = async (
  educationalQualificationEndPoint: string,
  payload: object
) => {
  try {
    return await axios.patch(educationalQualificationEndPoint, payload);
  } catch (err: any) {
    return err;
  }
};

//work experience
export const getWorkExperience = async (employeeId: string) => {
  try {
    return await axios.get(`/employees/${employeeId}/work-experience`);
  } catch (err: any) {
    return err;
  }
};

export const updateWorkExperience = async (
  workExperienceEndPoint: string,
  payload: object
) => {
  try {
    return await axios.patch(workExperienceEndPoint, payload);
  } catch (err: any) {
    return err;
  }
};

//family and language
export const getFamilyAndLanguage = async (employeeId: string) => {
  try {
    return await axios.get(`/employees/${employeeId}/family-and-language`);
  } catch (err: any) {
    return err;
  }
};

export const updateFamilyAndLanguage = async (
  familyAndLanguageEndPoint: string,
  payload: object
) => {
  try {
    return await axios.patch(familyAndLanguageEndPoint, payload);
  } catch (err: any) {
    return err;
  }
};

//bank details
export const getBankDetails = async (employeeId: string) => {
  try {
    return await axios.get(`/employees/${employeeId}/bank-detail`);
  } catch (err: any) {
    return err;
  }
};

export const updateBankDetails = async (
  employeeId: string,
  payload: object
) => {
  try {
    return await axios.patch(`/employees/${employeeId}/bank-detail`, payload);
  } catch (err: any) {
    return err;
  }
};

//employee information
export const getEmployeeInformation = async (employeeId: string) => {
  try {
    return await axios.get(`/employees/${employeeId}/employee-information`);
  } catch (err: any) {
    return err;
  }
};

export const updateEmployeeInformation = async (
  employeeId: string,
  payload: object
) => {
  try {
    return await axios.patch(
      `/employees/${employeeId}/employee-information`,
      payload
    );
  } catch (err: any) {
    return err;
  }
};

// asset details
export const getAssetDetails = async (employeeId: string) => {
  try {
    return await axios.get(`/employees/${employeeId}/assets-detail`);
  } catch (err: any) {
    return err;
  }
};

export const updateAssetDetails = async (
  employeeId: string,
  payload: object
) => {
  try {
    return await axios.patch(`/employees/${employeeId}/assets-detail`, payload);
  } catch (err: any) {
    return err;
  }
};

// get Roles
export const getRolesAPI = async () => {
  return await axios.get(`/roles`);
};

export const getEmployeeProjectDetails = async (empId: string) => {
  return await axios.get(`/employees/${empId}/projects`);
};

export const getEmployeeDocuments = async (empId: string) =>
  await axios.get<IEmployeeDocument[]>(`/employees/documents/${empId}`);

export interface IEmployeeDocument {
  filename: string;
  path: string;
}


//more-information
export const getMoreInformationAPI = async (employeeID: string) => {
  return await axios.get(`/employees/${employeeID}/more-information`);
}

export const updateMoreInformationAPI = async (employeeID: string, payload: { slackID: string, attendanceID: number }) => {
  return await axios.patch(`/employees/${employeeID}/more-information`, payload)
}

//kra
export const getEmployeeListKRA = async () => axios.get('/employees/employee-list-mock');