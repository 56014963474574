import { useState, useEffect } from "react";
import { useStyles } from "./style";
import { Input } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';


interface Iprops {
  setSearchActive :Function,
  searchActive ?: boolean,
  setQuery:any,
}

const Search: React.FC<Iprops> = ({setSearchActive ,searchActive = false,setQuery}) => {
  const classes = useStyles();
  const searchHandler=(e:any)=>{
    e.stopPropagation();
    setQuery(e.target.value);
  }

  const triggerSearch = () => {
    setSearchActive(!searchActive)
  }

  return (
    <div className={classes.searchBar}>
      <Input disableUnderline={true} fullWidth={true} placeholder="Search" onChange={(e)=>searchHandler(e)} className={classes.searchInput} />
      <SearchIcon  onClick={triggerSearch}/>

    </div>
  );
}

export default Search;


