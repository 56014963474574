import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: theme.typography.pxToRem(450),
    display: "flex",
    background: "#fff",
    borderRadius: "10px",
    padding: `${theme.typography.pxToRem(0)} ${theme.typography.pxToRem(
      25
    )} ${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(37)}`,
  },
}));
