import React, { useEffect, useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useStyles } from "./styles";
import BasicDetailsIcon from "../../assets/icons/basicDetailsIcon.svg";
import EducationQualificationIcon from "../../assets/icons/educationQualificationIcon.svg";
import WorkExperienceIcon from "../../assets/icons/workExperienceIcon.svg";
import FamilyAndLanguageIcon from "../../assets/icons/familyAndLanguageIcon.svg";
import BankDetailsIcon from "../../assets/icons/bankDetailsIcon.svg";
import EmployeeInfoIcon from "../../assets/icons/employeeInfoIcon.svg";
import ComputerIcon from "@material-ui/icons/Computer";
import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import BasicDetailsEdit from "./BasicDetailsEdit";
import EducationalQualificationEdit from "./EducationalQualificationEdit";
import WorkExperienceEdit from "./WorkExperienceEdit";
import FamilyAndLanguageEdit from "./FamilyAndLanguageEdit";
import BankDetailsEdit from "./BankDetailsEdit";
import EmployeeInformationEdit from "./EmployeeInformationEdit";
import AssetDetailsEdit from "./AssetDetailsEdit";
import { employeeDataReadPermission } from "../../utils/functions";
import {
  getAssetDetails,
  getBaiscDetails,
  getBankDetails,
  getEducationalQualification,
  getEmployeeInformation,
  getFamilyAndLanguage,
  getMoreInformationAPI,
  getWorkExperience,
  updateMoreInformationAPI,
} from "../../apis/employees";
import { useParams } from "react-router-dom";
import AccountBoxOutlinedIcon from "@material-ui/icons/AccountBoxOutlined";

const AccordionComponent = () => {
  const classes = useStyles();
  const params: { id: any } = useParams();
  const employeeId = params.id;

  // const employeeId = idProps.id; //Params Id extracted

  //constant url endpoints
  // const mainUrl = `http://192.168.29.200:3001`;
  // const mainUrl = `https://hrmsdev.techvariable.com`;
  const educationalQualificationEndPoint = `/employees/${employeeId}/education-qualification`;
  const workExperienceEndPoint = `/employees/${employeeId}/work-experience`;
  const familyAndLanguageEndPoint = `/employees/${employeeId}/family-and-language`;

  //state for progressBar loader
  const [loaderStateBasicDetails, setLoaderStateBasicDetails] = useState(false);
  const [
    loaderStateEducationalQualification,
    setLoaderStateEducationalQualification,
  ] = useState(false);
  const [loaderStateWorkExperience, setLoaderStateWorkExperience] =
    useState(false);
  const [loaderStateFamilyAndLanguage, setLoaderStateFamilyAndLanguage] =
    useState(false);
  const [loaderStateBankDetails, setLoaderStateBankDetails] = useState(false);
  const [loaderStateEmployeeInformation, setLoaderStateEmployeeInformation] =
    useState(false);
  const [loaderStateAssetDetails, setLoaderStateAssetDetails] = useState(false);

  //initialData for all Accordians
  const [basicDetailsInitialData, setBasicDetailsInitialData] = useState(null);
  const [
    educationalQualificationInitialData,
    setEducationalQualificationInitialData,
  ] = useState(null);
  const [workExperienceInitialData, setWorkExperienceInitialData] =
    useState(null);
  const [familyAndLanguageInitialData, setFamilyAndLanguageInitialData] =
    useState(null);
  const [bankDetailsInitialData, setBankDetailsInitialData] = useState(null);
  const [employeeInformationInitialData, setEmployeeInformationInitialData] =
    useState(null);
  const [assetDetailsInitialData, setAssetDetailsInitialData] = useState(null);

  //controls for accordian
  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const [slackID, setSlackID] = useState<string>("");
  const [attendanceID, setAttendanceID] = useState<number | null>(null);
  const [isMoreInformationEditable, setIsMoreInformationEditable] =
    useState<boolean>(false);

  const getInitialDataAllAccordians = () => {
    // get req for different accordians basic details
    getBaiscDetails(employeeId).then((res) => {
      if (res.status === 200) {
        setLoaderStateBasicDetails(true);
      }
      setBasicDetailsInitialData(res.data);
    });
    //educational qualification
    getEducationalQualification(employeeId).then((res) => {
      if (res.status === 200) {
        setLoaderStateEducationalQualification(true);
      }
      setEducationalQualificationInitialData(res.data);
    });
    //work experience
    getWorkExperience(employeeId).then((res) => {
      if (res.status === 200) {
        setLoaderStateWorkExperience(true);
      }
      setWorkExperienceInitialData(res.data);
    });
    //family and language
    getFamilyAndLanguage(employeeId).then((res) => {
      if (res.status === 200) {
        setLoaderStateFamilyAndLanguage(true);
      }
      setFamilyAndLanguageInitialData(res.data);
    });
    //bank details
    getBankDetails(employeeId).then((res) => {
      if (res.status === 200) {
        setLoaderStateBankDetails(true);
      }
      setBankDetailsInitialData(res.data);
    });
    // employee information
    getEmployeeInformation(employeeId).then((res) => {
      if (res.status === 200) {
        setLoaderStateEmployeeInformation(true);
      }
      // console.log("initial dtaaa", res.data);
      setEmployeeInformationInitialData(res.data);
    });
    // asset details
    getAssetDetails(employeeId).then((res) => {
      if (res.status === 200) {
        setLoaderStateAssetDetails(true);
      }
      setAssetDetailsInitialData(res.data);
    });
  };

  useEffect(() => {
    getInitialDataAllAccordians();
  }, []);

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleMoreInformation = async () => {
    try {
      if (!attendanceID) {
        alert("Attendance ID field cannot be empty");
        return;
      }
      const res = await updateMoreInformationAPI(employeeId, {
        slackID,
        attendanceID,
      });
      //show snackbar of res.data
      setIsMoreInformationEditable(false);
      getMoreInformation();
    } catch (error) {
      console.error(error);
    }
  };

  const getMoreInformation = async () => {
    const res = await getMoreInformationAPI(employeeId);
    setSlackID(res.data.slackId);
    setAttendanceID(res.data.attendanceId);
  };

  useEffect(() => {
    if (expanded !== "panel8") return;
    getMoreInformation();
  }, [expanded]);

  return (
    <div className={classes.root}>
      {employeeDataReadPermission() && (
        <Grid container>
          <Grid item xs={12}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              className={classes.basicDetailsAccordion}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.accordianSummaryStyle}
              >
                <img src={BasicDetailsIcon} />
                <Typography className={classes.heading}>
                  Basic Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <BasicDetailsEdit
                  employeeId={employeeId}
                  basicDetailsInitialData={basicDetailsInitialData}
                  setBasicDetailsInitialdataProp={setBasicDetailsInitialData}
                  loaderStateProps={loaderStateBasicDetails}
                />
              </AccordionDetails>
            </Accordion>
            <br />
          </Grid>
          <Grid item xs={12}>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
              className={classes.educationalAccordion}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.accordianSummaryStyle}
              >
                <img src={EducationQualificationIcon} />
                <Typography className={classes.heading}>
                  Educational Qualification
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <EducationalQualificationEdit
                  employeeId={employeeId}
                  educationalQualificationInitialData={
                    educationalQualificationInitialData
                  }
                  loaderStateProps={loaderStateEducationalQualification}
                  calledFromEmployee={true}
                />
              </AccordionDetails>
            </Accordion>
            <br />
          </Grid>
          <Grid item xs={12}>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
              className={classes.workAccordion}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.accordianSummaryStyle}
              >
                <img src={WorkExperienceIcon} />
                <Typography className={classes.heading}>
                  Work Experience
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <WorkExperienceEdit
                  employeeId={employeeId}
                  workExperienceInitialData={workExperienceInitialData}
                  loaderStateProps={loaderStateWorkExperience}
                  calledFromEmployee={true}
                />
              </AccordionDetails>
            </Accordion>
            <br />
          </Grid>
          <Grid item xs={12}>
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
              className={classes.familyAccordion}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.accordianSummaryStyle}
              >
                <img src={FamilyAndLanguageIcon} />
                <Typography className={classes.heading}>
                  Family and language
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FamilyAndLanguageEdit
                  employeeId={employeeId}
                  familyAndLanguageInitialData={familyAndLanguageInitialData}
                  loaderStateProps={loaderStateFamilyAndLanguage}
                  calledFromEmployee={true}
                />
              </AccordionDetails>
            </Accordion>
            <br />
          </Grid>
          <Grid item xs={12}>
            <Accordion
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
              className={classes.bankAccordion}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.accordianSummaryStyle}
              >
                <img src={BankDetailsIcon} />
                <Typography className={classes.heading}>
                  Bank Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <BankDetailsEdit
                  employeeId={employeeId}
                  bankDetailsInitialData={bankDetailsInitialData}
                  loaderStateProps={loaderStateBankDetails}
                />
              </AccordionDetails>
            </Accordion>
            <br />
          </Grid>
          <Grid item xs={12}>
            <Accordion
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
              className={classes.employeeAccordion}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.accordianSummaryStyle}
              >
                <img src={EmployeeInfoIcon} />
                <Typography className={classes.heading}>
                  Employee Information
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <EmployeeInformationEdit
                  employeeId={employeeId}
                  employeeInformationInitialData={
                    employeeInformationInitialData
                  }
                  loaderStateProps={loaderStateEmployeeInformation}
                />
              </AccordionDetails>
            </Accordion>
            <br />
          </Grid>
          <Grid item xs={12}>
            <Accordion
              expanded={expanded === "panel7"}
              onChange={handleChange("panel7")}
              className={classes.assetAccordion}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.accordianSummaryStyle}
              >
                <ComputerIcon fontSize="medium" />
                <Typography className={classes.heading}>
                  Asset Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <AssetDetailsEdit
                  employeeId={employeeId}
                  assetDetailsInitialData={assetDetailsInitialData}
                  loaderStateProps={loaderStateAssetDetails}
                />
              </AccordionDetails>
            </Accordion>
            <br />
          </Grid>
          <Grid item xs={12}>
            <Accordion
              expanded={expanded === "panel8"}
              onChange={handleChange("panel8")}
              className={classes.assetAccordion}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.accordianSummaryStyle}
              >
                <AccountBoxOutlinedIcon fontSize="medium" />
                <Typography className={classes.heading}>
                  More Information
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "1em 0",
                    width: "100%",
                  }}
                >
                  <form
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: "500",
                          marginRight: "1em",
                          fontSize: ".9em",
                        }}
                      >
                        SlackID
                      </Typography>
                      <TextField
                        disabled={!isMoreInformationEditable}
                        style={{ marginRight: "1em" }}
                        size="small"
                        variant="outlined"
                        value={slackID}
                        onChange={(e) => setSlackID(e.target.value)}
                      />
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: "500",
                          marginRight: "1em",
                          fontSize: ".9em",
                        }}
                      >
                        AttendanceID
                      </Typography>
                      <TextField
                        type="number"
                        disabled={!isMoreInformationEditable}
                        style={{ marginRight: "1em" }}
                        size="small"
                        variant="outlined"
                        value={attendanceID === 0 ? "" : attendanceID}
                        onChange={(e) => setAttendanceID(+e.target.value)}
                      />
                    </div>
                    <Box
                      style={{
                        marginRight: "1rem",
                      }}
                    >
                      {isMoreInformationEditable ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleMoreInformation}
                        >
                          Save
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          type="button"
                          onClick={() => setIsMoreInformationEditable(true)}
                        >
                          Edit
                        </Button>
                      )}
                    </Box>
                  </form>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default AccordionComponent;
