import { Button, Grid, Menu, MenuItem } from "@material-ui/core";
import React from "react";
import { useStyles } from "./styles";

interface EmployeeLeaveHeaderProps {
  anchorEl: null;
  handleClick: (event: any) => void;
  handleClose: () => void;
  handleSortData: (sortBy: string) => void;
}

export const EmployeeLeaveHeader: React.FC<EmployeeLeaveHeaderProps> = ({
  anchorEl,
  handleClick,
  handleClose,
  handleSortData,
}) => {
  const classes = useStyles();

  return (
    <Grid container justifyContent="flex-end" style={{ padding: "1em" }}>
      <Grid item className={classes.sortDiv} xs={12} sm={12} md={1}>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          className={classes.sortButton}
          onClick={handleClick}
        >
          Sort By :
        </Button>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem id="currentDate" onClick={() => handleSortData("date")}>
            Date
          </MenuItem>
          <MenuItem
            id="casualLeave"
            onClick={() => handleSortData("casual_leave")}
          >
            Casual Leave
          </MenuItem>
          <MenuItem id="sickLeave" onClick={() => handleSortData("sick_leave")}>
            Sick Leave
          </MenuItem>
          <MenuItem
            id="priviledgeLeave"
            onClick={() => handleSortData("priviledge_leave")}
          >
            Priviledge Leave
          </MenuItem>
          <MenuItem id="clearsort" onClick={() => handleSortData("")}>
            Clear Sort
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
};
