import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Search from "../../components/Search";
import ProjectsTable from "../../components/ProjectsTable";
import Pagination from "@material-ui/lab/Pagination";
import { projectsData } from "../../../../apis/projects";
import StatusDiv from "../../components/StatusDiv/StatusDiv";
import Filter from "../../components/Filter";
import { useRecoilState } from "recoil";
import { ProjectsAtom } from "../../../../recoil/atoms";
import useDebounce from "../../../../hooks/useDebounce";
import { useStyles } from "../../style";
import { HeadCell } from "../../types";
import { ProjectType } from "../ProjectDetailPage/types";

interface Iprops {
  showToast: {
    error: (message: string) => void;
    success: (message: string) => void;
    warning: (message: string) => void;
  };
}

const typeOptions = ["All", "Retainer", "Fixed Price"];
const StatusOptions = ["All", "Ongoing", "Completed"];
const limit = 10;

const MainPage: React.FC<Iprops> = ({ showToast }) => {
  const classes = useStyles();
  const [projectData, setProjectData] = useRecoilState(ProjectsAtom);
  const [filterType, setFilterType] = useState<string>("");
  const [filterStatus, setFilterStatus] = useState<string>("");
  const [query, setQuery] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const debouncedValue = useDebounce<string>(query, 1000);
  const [pageNo, setPageNo] = useState<number>(1);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [searchActive, setSearchActive] = useState<boolean>(false);
  const [pageCount, setPageCount] = useState<number>(1);

  const headCells: HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Project Name",
      width: "20%",
      filter: <></>,
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "Project Model",
      width: "13%",
      filter: <Filter setFilter={setFilterType} items={typeOptions} />,
    },
    {
      id: "clientname",
      numeric: false,
      disablePadding: false,
      label: "Client Name",
      width: "13%",
      filter: <></>,
    },
    {
      id: "clientemail",
      numeric: false,
      disablePadding: false,
      label: "Client Email",
      width: "18%",
      filter: <></>,
    },
    {
      id: "orientation",
      numeric: false,
      disablePadding: false,
      label: "Project Orientation",
      width: "13%",
      filter: <></>,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
      width: "20%",
      filter: <Filter setFilter={setFilterStatus} items={StatusOptions} />,
    },
  ];

  useEffect(() => {
    fetchData();
  }, [
    filterType,
    filterStatus,
    pageNo,
    isDeleted,
    searchActive,
    debouncedValue,
  ]);

  async function fetchData() {
    setIsLoading(true);
    try {
      let data = await projectsData(
        filterType,
        filterStatus,
        debouncedValue,
        limit,
        pageNo
      );

      if (data?.data?.projects.length === 0) {
        showToast.error("No Projects Found");
        setProjectData([]);
        setIsLoading(false);
        return;
      }
      let totalLength = data?.data.total;
      let totalPage = Math.floor(totalLength / 10);
      const hasNext = totalLength % 10;
      if (hasNext > 0) {
        totalPage += 1;
      }
      setPageCount(totalPage);
      let tempItem: any = [];
      data?.data.projects.forEach(
        ({
          projectName,
          model,
          client,
          projectStatus,
          _id,
          startDate,
          projectManager,
          orientation,
        }: ProjectType) => {
          let tempObjUser = {
            _id: _id,
            projectName: projectName,
            projectType: model,
            clientName: client?.clientCompany ?? "N/A",
            clientEmail: client?.clientEmail ?? "N/A",
            projectStatus: (
              <StatusDiv
                projectStatus={projectStatus}
                _id={_id}
                isDeleted={isDeleted}
                setIsDeleted={setIsDeleted}
              />
            ),
            projectManager: projectManager[0]?.basicDetails?.fullName ?? "N/A",
            startdate: startDate,
            orientation: orientation,
          };
          tempItem.push(tempObjUser);
        }
      );
      setProjectData(tempItem);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      showToast.error(error?.response?.data?.message);
    }
  }

  const paginateHandler = (e: React.ChangeEvent<unknown>, value: number) => {
    setPageNo(value);
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item xs={5}>
        <Search
          setQuery={setQuery}
          setSearchActive={setSearchActive}
          searchActive={searchActive}
        />
      </Grid>
      <Grid item xs={12}>
        <ProjectsTable
          isLoading={isLoading}
          items={projectData}
          headCells={headCells}
        />
      </Grid>
      <Grid item xs={12}>
        <Pagination
          className={classes.footer}
          defaultPage={pageNo}
          onChange={paginateHandler}
          count={pageCount}
          showFirstButton
          showLastButton
        />
      </Grid>
    </Grid>
  );
};

export default MainPage;
