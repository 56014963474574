import React, { useState } from "react";
import * as Yup from "yup";
import { Grid } from "@material-ui/core";
import deleteIcon from "../../../../assets/icons/delete.svg";
import {
  createDepartment,
  createDesignation,
  deleteDepartment,
  deleteDesignation,
} from "../../../../apis/designations_departments";
import { useFormik } from "formik";
import classNames from "classnames";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "../../../../components/CustomButton";
import TextField from "../../../../components/Fields/TextField";
import { useStyles } from "./styles";
import { useRecoilValue } from "recoil";
import { permissions } from "../../../../recoil/atoms";
import { usePermissionObjectFor } from "../../../../hooks/permission/usePermissionObjectFor";

interface designationObject {
  _id: string;
  designationName: string;
}
interface departmentObject {
  _id: string;
  departmentName: string;
}

interface IProps {
  type: string;
  name: string;
  showToast: any;
  items: designationObject[] | departmentObject[];
  toggleRefresh: (type: string) => void;
}

interface ITypeObj {
  [key: string]: {
    submitState: boolean;
    deleteState: boolean;
    setSubmitStateTrue: () => void;
    setDeleteStateTrue: () => void;
    indexState: number;
    setDeleteStateFalse: () => void;
    setSubmitStateFalse: () => void;
    create: () => void;
    delete: (id: string, index: number) => void;
    setIndex: (id: number) => void;
    createPermission: boolean;
    readPermission: boolean;
    deletePermission: boolean;
  };
}

const FormSection: React.FC<IProps> = ({
  type,
  name,
  showToast,
  items,
  toggleRefresh,
}) => {
  const schema = Yup.object().shape({
    [`${name}`]: Yup.string().required("*Required"),
  });

  const classes = useStyles();
  // const [initialValues, setInitialValues] = useState<any>({
  //   [name]: "",
  // });
  const [initialValues] = useState<any>({
    [name]: "",
  });
  const [isSubmittingDepartment, setSubmittingDepartment] =
    useState<boolean>(false);
  const [isDeletingDepartment, setDeletingDepartment] =
    useState<boolean>(false);
  const [isSubmittingDesignation, setSubmittingDesignation] =
    useState<boolean>(false);
  const [isDeletingDesignation, setDeletingDesignation] =
    useState<boolean>(false);
  const [clickedDesignationIndex, setClickedDesignationIndex] =
    useState<number>(0);
  const [clickedDepartmentIndex, setClickedDepartmentIndex] =
    useState<number>(0);

  const designationPermissions = usePermissionObjectFor("designation");
  const hasDesignationCreateAcc = designationPermissions.includes("create");
  const hasDesignationReadAcc = designationPermissions.includes("read");
  const hasDesignationDeleteAcc = designationPermissions.includes("del");

  const departmentPermission = usePermissionObjectFor("department");
  const hasDepartmentCreateAcc = departmentPermission.includes("create");
  const hasDepartmentReadAcc = departmentPermission.includes("read");
  const hasDepartmentDeleteAcc = departmentPermission.includes("del");

  const TYPE_OBJECT: ITypeObj = {
    Department: {
      submitState: isSubmittingDepartment,
      deleteState: isDeletingDepartment,
      indexState: clickedDepartmentIndex,
      createPermission: hasDesignationCreateAcc,
      readPermission: hasDesignationReadAcc,
      deletePermission: hasDesignationDeleteAcc,
      setSubmitStateTrue: () => setSubmittingDepartment(true),
      setDeleteStateTrue: () => setDeletingDepartment(true),
      setSubmitStateFalse: () => setSubmittingDepartment(false),
      setDeleteStateFalse: () => setDeletingDepartment(false),
      setIndex: (id) => setClickedDepartmentIndex(id),
      create: () => handleDepartmentCreate(),
      delete: (id, index) => handleDepartmentDelete(id, index),
    },
    Designation: {
      submitState: isSubmittingDesignation,
      deleteState: isDeletingDesignation,
      indexState: clickedDesignationIndex,
      setSubmitStateTrue: () => setSubmittingDesignation(true),
      setDeleteStateTrue: () => setDeletingDesignation(true),
      createPermission: hasDepartmentCreateAcc,
      readPermission: hasDepartmentReadAcc,
      deletePermission: hasDepartmentDeleteAcc,
      setSubmitStateFalse: () => setSubmittingDesignation(false),
      setDeleteStateFalse: () => setDeletingDesignation(false),
      setIndex: (id) => setClickedDesignationIndex(id),
      create: () => handleDesignationCreate(),
      delete: (id, index) => handleDesignationDelete(id, index),
    },
  };

  /**
   * @method handleDepartmentCreate
   * @description api call to create department
   **/
  const handleDepartmentCreate = () => {
    TYPE_OBJECT[type].setSubmitStateTrue();
    let isDepartmentDublicate = false;
    let departments: departmentObject[] = items as departmentObject[];
    departments.forEach((item: departmentObject) => {
      if (item.departmentName === values.departmentName) {
        showToast.error(`${type} already`);
        isDepartmentDublicate = true;
      }
    });

    if (!isDepartmentDublicate) {
      createDepartment(values)
        .then((res) => {
          showToast.success(`${type} created`);
          resetForm({ values: "" });
          toggleRefresh("department");
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  /**
   * @method handleDepartmentDelete
   * @description api call to delete department
   **/
  const handleDepartmentDelete = (id: string, index: number) => {
    TYPE_OBJECT[type].setDeleteStateTrue();
    TYPE_OBJECT[type].setIndex(index);
    deleteDepartment(id)
      .then((res) => {
        showToast.success(`${type} deleted`);
        TYPE_OBJECT[type].setDeleteStateFalse();
        toggleRefresh("department");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  /**
   * @method handleDesignationDelete
   * @description api call to delete Designation
   **/
  const handleDesignationDelete = (id: string, index: number) => {
    TYPE_OBJECT[type].setDeleteStateTrue();
    TYPE_OBJECT[type].setIndex(index);
    deleteDesignation(id)
      .then((res) => {
        showToast.success(`${type} deleted`);
        TYPE_OBJECT[type].setDeleteStateFalse();
        toggleRefresh("designation");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  /**
   * @method handleDesignationCreate
   * @description api call to create designation
   **/
  const handleDesignationCreate = () => {
    TYPE_OBJECT[type].setSubmitStateTrue();
    let isDesignationDublicate = false;
    let designations: designationObject[] = items as designationObject[];

    designations.forEach((item: designationObject) => {
      if (item.designationName === values.designationName) {
        showToast.error(`${type} already`);
        isDesignationDublicate = true;
      }
    });

    if (!isDesignationDublicate) {
      createDesignation(values)
        .then((res) => {
          showToast.success(`${type} created`);
          resetForm({ values: "" });
          toggleRefresh("designation");
        })
        .catch((err) => {
          showToast.error(`something went wrong`);
        });
    }
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    // setFieldValue,
    resetForm,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: ({ ...values }) => {
      TYPE_OBJECT[type].create();
    },

    validate: (values: any) => {
      let errors = {};

      return errors;
    },
  });

  /**
   * @method handleDelete
   * @description triggers deletion
   */

  const handleDelete = (id: string, index: number) => {
    TYPE_OBJECT[type].delete(id, index);
  };
  return (
    <Grid direction="column" container className={classes.mainContainer}>
      <p
        className={classNames(classes.label, {
          [classes.labelHide]: TYPE_OBJECT[type].createPermission === false,
        })}
      >
        {type}
      </p>
      <Grid
        container
        className={classNames(classes.fieldContainer, {
          [classes.fieldContainerHide]:
            TYPE_OBJECT[type].createPermission === false,
        })}
      >
        <TextField
          variant="standard"
          onChange={handleChange}
          name={name}
          value={values[name]}
          label={`Enter a new ${type}`}
          type=""
          error={
            (touched.designationName && Boolean(errors.designationName)) ||
            (touched.departmentName && Boolean(errors.departmentName))
          }
          helperText={
            (touched.designationName && errors.designationName) ||
            (touched.departmentName && errors.departmentName)
          }
        />

        <Grid container justify="flex-end">
          <Grid item className={classes.button}>
            <Button
              label="Discard"
              isDisabled={false}
              isBordered={false}
              startIcon=""
              endIcon=""
              isOutlined={true}
              onButtonClick={() => resetForm({ values: "" })}
              color="primary"
            />
          </Grid>
          <Grid item className={classes.button}>
            <Button
              label="Submit"
              isDisabled={TYPE_OBJECT[type].submitState}
              isBordered={false}
              startIcon=""
              endIcon=""
              isOutlined={false}
              onButtonClick={handleSubmit}
              color="primary"
            />
          </Grid>
        </Grid>
      </Grid>
      <p
        className={classNames(classes.label, {
          [classes.labelHide]: TYPE_OBJECT[type].createPermission === false,
        })}
      >
        List of {type}
      </p>
      <Grid container>
        {items.map((item: any, index) => {
          return (
            <Grid
              xs={12}
              className={classNames(classes.list, {
                [classes.listHide]: TYPE_OBJECT[type].readPermission === false,
              })}
              justify="flex-start"
              container
              alignItems="center"
            >
              <div className={classes.dot}></div>
              <p className={classes.desigLabel}>{item[name]}</p>
              {TYPE_OBJECT[type].deleteState &&
              TYPE_OBJECT[type].indexState === index ? (
                <CircularProgress size={15} />
              ) : (
                <img
                  className={classNames(classes.deleteIconClass, {
                    [classes.deleteIconClassHide]:
                      TYPE_OBJECT[type].deletePermission === false,
                  })}
                  onClick={() => handleDelete(item._id, index)}
                  src={deleteIcon}
                  alt="delete-icon"
                />
              )}
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default FormSection;
