import axios from "axios";

interface fetchFileLinkInterface {
  label: string | undefined;
  photos: {
    name: string;
    value: Blob | string;
  }[];
  // offerLetter: File | any | string;
  idProofs: File[] | any | string;
  academicProofs: File[] | any | string;
  experienceProof: File[] | any | string;
  userId: string;
  rewriteFileDownload?: {
    personalPhoto: boolean;
    passportPhoto: boolean;
    // offerLetter: boolean;
    pan: boolean;
    aadhar: boolean;
    class10: boolean;
    class12: boolean;
    grad: boolean;
    postGrad: boolean;
    diploma: boolean;
    reliving: boolean;
    lastSalSlip: boolean;
    secondLastSalSlip: boolean;
    thirdLastSalSlip: boolean;
  };
  getEndpoint?: any;
  headerPropsGet?: any;
}

export const fetchFileLink = async ({
  academicProofs,
  experienceProof,
  idProofs,
  label,
  // offerLetter,
  photos,
  userId,
  getEndpoint,
  headerPropsGet,
  rewriteFileDownload,
}: fetchFileLinkInterface) => {
  const mainUrl = process.env.REACT_APP_BACKEND_URL;
  getEndpoint =
    getEndpoint ?? `/api/on-boarding/get-uploaded-documents/${userId}`;

  const resp = await axios({
    method: "get",
    url: mainUrl + getEndpoint,
    headers: headerPropsGet,
  });

  // PHOTOS
  if (label === "passportDoc") {
    let photosURL;
    if (rewriteFileDownload?.passportPhoto) {
      photos.forEach((photo) => {
        if (photo.name === "passportPhoto") {
          photosURL = URL.createObjectURL(photo.value as Blob);
        }
      });
    } else {
      photosURL = resp.data?.photos?.passportPhoto;
    }
    return photosURL;
  }

  if (label === "personalDoc") {
    let photosURL;
    if (rewriteFileDownload?.personalPhoto) {
      photos.forEach((photo) => {
        if (photo.name === "personalPhoto") {
          photosURL = URL.createObjectURL(photo.value as Blob);
        }
      });
    } else {
      photosURL = resp.data?.photos?.personalPhoto;
    }
    return photosURL;
  }

  // OFFER LETTER
  // if (label === "tvDocs") {
  //   if (rewriteFileDownload?.offerLetter) {
  //     const offerLetterURL = URL.createObjectURL(offerLetter.value);
  //     return offerLetterURL;
  //   }

  //   if (resp.status === 200 && resp?.data?.acceptedOfferLetter) {
  //     return resp.data.acceptedOfferLetter;
  //   } else {
  //     const offerLetterURL = URL.createObjectURL(offerLetter.value);
  //     return offerLetterURL;
  //   }
  // }

  // IDENTITIES
  if (label === "aadharDoc") {
    if (rewriteFileDownload?.aadhar) {
      let aadharURL;
      idProofs.forEach((proof: any) => {
        if (proof.name === "adhaarCard") {
          aadharURL = URL.createObjectURL(proof.value);
        }
      });
      return aadharURL;
    }

    if (resp.status === 200 && resp?.data?.proofOfIdentity?.adhaarCard) {
      return resp.data.proofOfIdentity.adhaarCard;
    } else {
      let aadharURL;
      idProofs.forEach((proof: any) => {
        if (proof.name === "adhaarCard") {
          aadharURL = URL.createObjectURL(proof.value);
          // aadharURL = proof.value;
        }
      });
      return aadharURL;
    }
  }

  if (label === "panDoc") {
    if (rewriteFileDownload?.pan) {
      let panURL;
      idProofs.forEach((proof: any) => {
        if (proof.name === "panCard") {
          panURL = window.URL.createObjectURL(proof.value);
        }
      });
      return panURL;
    }

    if (resp.status === 200 && resp?.data?.proofOfIdentity?.panCard) {
      return resp.data.proofOfIdentity.panCard;
    } else {
      let panURL;
      idProofs.forEach((proof: any) => {
        if (proof.name === "panCard") {
          panURL = window.URL.createObjectURL(proof.value);
          // panURL = proof.value;
        }
      });
      return panURL;
    }
  }

  // ACADEMICS

  if (label === "class10") {
    if (rewriteFileDownload?.class10) {
      let class10URL;
      academicProofs.forEach((proof: any) => {
        if (proof.name === "class10th") {
          class10URL = URL.createObjectURL(proof.value);
        }
      });
      return class10URL;
    }

    if (resp.status === 200 && resp?.data?.academicCertificates?.class10th) {
      return resp.data.academicCertificates.class10th;
    } else {
      let class10URL;
      academicProofs.forEach((proof: any) => {
        if (proof.name === "class10th") {
          // class10URL = URL.createObjectURL(proof.value);
          class10URL = proof.value;
        }
      });
      return class10URL;
    }
  }

  if (label === "class12") {
    if (rewriteFileDownload?.class12) {
      let class12URL;
      academicProofs.forEach((proof: any) => {
        if (proof.name === "class12th") {
          class12URL = URL.createObjectURL(proof.value);
        }
      });
      return class12URL;
    }

    if (resp.status === 200 && resp?.data?.academicCertificates?.class12th) {
      return resp.data.academicCertificates.class12th;
    } else {
      let class12URL;
      academicProofs.forEach((proof: any) => {
        if (proof.name === "class12th") {
          // class12URL = URL.createObjectURL(proof.value);
          class12URL = proof.value;
        }
      });
      return class12URL;
    }
  }

  if (label === "graduation") {
    if (rewriteFileDownload?.grad) {
      let graduationURL;
      academicProofs.forEach((proof: any) => {
        if (proof.name === "graduation") {
          graduationURL = URL.createObjectURL(proof.value);
        }
      });
      return graduationURL;
    }

    if (resp.status === 200 && resp?.data?.academicCertificates?.graduation) {
      return resp.data.academicCertificates.graduation;
    } else {
      let graduationURL;
      academicProofs.forEach((proof: any) => {
        if (proof.name === "graduation") {
          // graduationURL = URL.createObjectURL(proof.value);
          graduationURL = proof.value;
        }
      });
      return graduationURL;
    }
  }

  if (label === "postGrad") {
    if (rewriteFileDownload?.postGrad) {
      let postGradURL;
      academicProofs.forEach((proof: any) => {
        if (proof.name === "postGraduation") {
          postGradURL = URL.createObjectURL(proof.value);
        }
      });
      return postGradURL;
    }

    if (
      resp.status === 200 &&
      resp?.data?.academicCertificates?.postGraduation
    ) {
      return resp.data.academicCertificates.postGraduation;
    } else {
      let postGradURL;
      academicProofs.forEach((proof: any) => {
        if (proof.name === "postGraduation") {
          // postGradURL = URL.createObjectURL(proof.value);
          postGradURL = proof.value;
        }
      });
      return postGradURL;
    }
  }

  if (label === "diploma") {
    if (rewriteFileDownload?.diploma) {
      let diplomaURL;
      academicProofs.forEach((proof: any) => {
        if (proof.name === "diploma") {
          diplomaURL = URL.createObjectURL(proof.value);
        }
      });
      return diplomaURL;
    }

    if (resp.status === 200 && resp?.data?.academicCertificates?.diploma) {
      return resp.data.academicCertificates.diploma;
    } else {
      let diplomaURL;
      academicProofs.forEach((proof: any) => {
        if (proof.name === "diploma") {
          diplomaURL = proof.value;
        }
      });
      return diplomaURL;
    }
  }

  // EXPERIENCES
  if (label === "relieving") {
    if (rewriteFileDownload?.reliving) {
      let relievingURL;
      experienceProof.forEach((proof: any) => {
        if (proof.name === "relivingLetter") {
          relievingURL = URL.createObjectURL(proof.value);
        }
      });
      return relievingURL;
    }

    if (resp.status === 200 && resp?.data?.workExperience?.relivingLetter) {
      return resp.data.workExperience.relivingLetter;
    } else {
      let relievingURL;
      experienceProof.forEach((proof: any) => {
        if (proof.name === "relivingLetter") {
          // relievingURL = URL.createObjectURL(proof.value);
          relievingURL = proof.value;
        }
      });
      return relievingURL;
    }
  }

  if (label === "lastSalSlip") {
    if (rewriteFileDownload?.lastSalSlip) {
      let lastSalSlipURL;
      experienceProof.forEach((proof: any) => {
        if (proof.name === "salarySlip1st") {
          lastSalSlipURL = URL.createObjectURL(proof.value);
        }
      });
      return lastSalSlipURL;
    }

    if (resp.status === 200 && resp?.data?.workExperience?.salarySlip1st) {
      return resp.data.workExperience.salarySlip1st;
    } else {
      let lastSalSlipURL;
      experienceProof.forEach((proof: any) => {
        if (proof.name === "salarySlip1st") {
          // lastSalSlipURL = URL.createObjectURL(proof.value);
          lastSalSlipURL = proof.value;
        }
      });
      return lastSalSlipURL;
    }
  }

  if (label === "seconLastSalSlip") {
    if (rewriteFileDownload?.secondLastSalSlip) {
      let seconLastSalSlipURL;
      experienceProof.forEach((proof: any) => {
        if (proof.name === "salarySlip2nd") {
          seconLastSalSlipURL = URL.createObjectURL(proof.value);
        }
      });
      return seconLastSalSlipURL;
    }

    if (resp.status === 200 && resp?.data?.workExperience?.salarySlip2nd) {
      return resp.data.workExperience.salarySlip2nd;
    } else {
      let seconLastSalSlipURL;
      experienceProof.forEach((proof: any) => {
        if (proof.name === "salarySlip2nd") {
          // seconLastSalSlipURL = URL.createObjectURL(proof.value);
          seconLastSalSlipURL = proof.value;
        }
      });
      return seconLastSalSlipURL;
    }
  }

  if (label === "thirdLastSalSlip") {
    if (rewriteFileDownload?.thirdLastSalSlip) {
      let thirdLastSalSlipURL;
      experienceProof.forEach((proof: any) => {
        if (proof.name === "salarySlip3rd") {
          thirdLastSalSlipURL = URL.createObjectURL(proof.value);
        }
      });
      return thirdLastSalSlipURL;
    }

    if (resp.status === 200 && resp?.data?.workExperience?.salarySlip3rd) {
      return resp.data.workExperience.salarySlip3rd;
    } else {
      let thirdLastSalSlipURL;
      experienceProof.forEach((proof: any) => {
        if (proof.name === "salarySlip3") {
          // thirdLastSalSlipURL = URL.createObjectURL(proof.value);
          thirdLastSalSlipURL = proof.value;
        }
      });
      return thirdLastSalSlipURL;
    }
  }
};
