import React, { useState, useEffect } from "react";
import { useStyles } from "./styles";
import { Grid, Typography } from "@material-ui/core";
import rightIcon from "../../../../assets/icons/rightIcon.svg";
import Popup from "../../../../components/Popup";
import LeaveAppliedForm from "../LeaveAppliedForm";
import { getAllLeaveRequests } from "../../../../apis/leaves";
import { RequestedLeave } from "../../types";

const leaveLimit: number = 30;

interface IProps {
  parentHeight?: number | undefined;
  showToast: any; 
}

const LeaveRequest: React.FC<IProps> = ({
  parentHeight,
  showToast,
}) => {
  const classes = useStyles();
  const [openLeaveDetails, setOpenLeaveDetails] = useState<boolean>(false);
  const [clickedCard, setClickedCard] = useState<RequestedLeave>();

  /**
   * @description Function to open leave details modal
   * @method handleOpenLeaveDetailsModal
   * @returns nothing
   */

  const handleOpenLeaveDetailsModal = () => {
    setOpenLeaveDetails(true);
  };

  /**
   * @description Function to close leave details modal
   * @method handleCloseLeaveDetailsModal
   * @returns nothing
   */
  const handleCloseLeaveDetailsModal = () => {
    setOpenLeaveDetails(false);
  };

  /**
   * @description Function to handle click on the card component
   * @method handleCardClick
   * @param {object} item
   * @returns nothing
   */
  const handleCardClick = (item: RequestedLeave) => {
    setClickedCard(item);
    handleOpenLeaveDetailsModal();
  };

  const [allleaveRequests, setAllLeaveRequests] = useState<RequestedLeave[]>(
    []
  );

  useEffect(() => {
    leaveRequests();
  }, []);

  async function leaveRequests() {
    try {
      let data = await getAllLeaveRequests();
      setAllLeaveRequests(data?.data);
    } catch (error: any) {
      showToast.error(error);
    }
  }
  return (
    <Grid direction="column" className={classes.container}>
      <Grid item direction="column" className={classes.requests}>
        {allleaveRequests.length === 0 ? (
          <Typography className={classes.noRequestText}> No pending requests..</Typography>
        ) : (
          allleaveRequests.map((item: RequestedLeave) => {
            return (
              <Grid
                alignItems="center"
                justifyContent="space-between"
                className={classes.card}
                container
                onClick={() => handleCardClick(item)}
              >
                <h3 className={classes.nameLabel}> {item?.emp_name}</h3>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  className={classes.circleButton}
                >
                  <img src={rightIcon} alt="right-icon" />
                </Grid>
              </Grid>
            );
          })
        )}
      </Grid>

      <Popup
        name="Leave Applied"
        open={openLeaveDetails}
        handleClose={handleCloseLeaveDetailsModal}
      >
        <LeaveAppliedForm
          data={clickedCard}
          leaveRequest
          showToast={showToast}
          handleClose={handleCloseLeaveDetailsModal}
        />
      </Popup>
    </Grid>
  );
};

export default LeaveRequest;
