import React, { useState, useEffect } from "react";
import {Paper,Tabs,Tab,Typography,Box} from '@material-ui/core';
import { useStyles, } from "./style";
import PropTypes from 'prop-types';
import ResourceTabs from "./ResourceTabs";
import IncidentManagement from "./IncidentManagement";
import RequirementsTab from "./RequirementsTab";
import ContentsTab from "./ContentsTab";
import { Resource } from "../../Page/ProjectDetailPage/types";

interface Iprops {
}
function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ProjectTabs: React.FC<Iprops> = () => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };


    return (
        <Paper className={classes.tabs}>
            <div>
                <Tabs indicatorColor="primary" value={value} onChange={handleChange} aria-label="simple tabs">
                    <Tab label="Resources" {...a11yProps(0)} />
                    <Tab label="Incident Management" {...a11yProps(1)} />
                    <Tab label="Requirements" {...a11yProps(2)} />
                    <Tab label="Contents" {...a11yProps(3)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <ResourceTabs />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <IncidentManagement/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <RequirementsTab/>
                </TabPanel>
                <TabPanel value={value} index={3} >
                    <ContentsTab/>
                </TabPanel>
            </div>
        </Paper>

    );
}

export default ProjectTabs;


