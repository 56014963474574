import SignIn from "./pages/SignIn";
import ForgotPassword from "./pages/ForgotPassword";
import DashboardPage from "./pages/DashboardPage";
import EmployeesPage from "./pages/EmployeesPage";
import AttendancePage from "./pages/AttendancePage";
import EmployeesAddPage from "./pages/EmployeesAddPage";
import LeavesPage from "./pages/LeavesPage";
import PoliciesPage from "./pages/PoliciesPage";
import RolesPage from "./pages/RolesPage";
import PerformancePage from "./pages/PerformancePage";
import knowledgeHubPage from "./pages/knowledgeHubPage";
import ContentBasket from "./pages/ContentBasketPage";
import ProjectsPage from "./pages/ProjectsPage";
import EmployeeDetails from "./pages/EmployeesPage/EmployeeDetails";
import NewRecruits from "./pages/NewRecruits";
import NewRecruitDetails from "./pages/NewRecruitDetails";
import SignInOTP from "./pages/SignInOTP";
import IndividualInfo from "./pages/AttendancePage/dashboard/individualInfo";
import KRAPage from "./pages/KRAPage";
import AttendancePageV2 from "./pages/AttendancePageV2/AttendancePageV2";

export const RouteConfig = {
  routes: [
    {
      path: "/signin",
      component: SignIn,
      defaultLoading: true,
      shouldAuthenticate: false,
      shouldHavePadding: false,
    },
    {
      path: "/forgotpassword",
      component: ForgotPassword,
      defaultLoading: true,
      shouldAuthenticate: false,
      shouldHavePadding: false,
    },
    {
      path: "/dashboard",
      component: DashboardPage,
      defaultLoading: true,
      shouldAuthenticate: true,
      shouldHavePadding: true,
    },
    {
      path: "/employees/add",
      component: EmployeesAddPage,
      defaultLoading: true,
      shouldAuthenticate: true,
      shouldHavePadding: true,
    },
    // {
    //   path: "/employees/details/:id",
    //   component: EmployeeDetails,
    //   defaultLoading: false,
    //   shouldAuthenticate: true,
    //   shouldHavePadding: true,
    // },


    // {
    //   path: "/attendance",
    //   component: AttendancePage,
    //   defaultLoading: true,
    //   shouldAuthenticate: true,
    //   shouldHavePadding: true,
    // },
    {
      path: "/attendance",
      component: AttendancePageV2,
      defaultLoading: true,
      shouldAuthenticate: true,
      shouldHavePadding: true,
    },
    {
      path: "/employees",
      component: EmployeesPage,
      defaultLoading: true,
      shouldAuthenticate: true,
      shouldHavePadding: true,
    },
    {
      path: "/leaves",
      component: LeavesPage,
      defaultLoading: true,
      shouldAuthenticate: true,
      shouldHavePadding: true,
    },

    {
      path: "/contentBasket",
      component: ContentBasket,
      defaultLoading: false,
      shouldAuthenticate: true,
      shouldHavePadding: true,
    },
    {
      path: "/projects",
      component: ProjectsPage,
      defaultLoading: true,
      shouldAuthenticate: true,
      shouldHavePadding: true,
    },

    {
      path: "/policies",
      component: PoliciesPage,
      defaultLoading: true,
      shouldAuthenticate: true,
      shouldHavePadding: true,
    },
    {
      path: "/knowledgeHub",
      component: knowledgeHubPage,
      defaultLoading: true,
      shouldAuthenticate: true,
      shouldHavePadding: true,
    },
    {
      path: "/kra",
      component: KRAPage,
      defaultLoading: true,
      shouldAuthenticate: true,
      shouldHavePadding: true,
    },
    {
      path: "/performance",
      component: PerformancePage,
      defaultLoading: true,
      shouldAuthenticate: true,
      shouldHavePadding: true,
    },
    {
      path: "/roles",
      component: RolesPage,
      defaultLoading: true,
      shouldAuthenticate: true,
      shouldHavePadding: true,
    },
    // otp based login:
    {
      path: "/signinotp",
      component: SignInOTP,
      defaultLoading: true,
      shouldAuthenticate: false,
      shouldHavePadding: false,
    },
    {
      path: "/logout/temp/user",
      component: SignInOTP,
      defaultLoading: true,
      shouldAuthenticate: false,
      shouldHavePadding: false,
    },
    {
      path: "/onboarding",
      component: NewRecruits,
      defaultLoading: true,
      shouldAuthenticate: true,
      shouldHavePadding: true,
    },
    {
      path: "/newrecruitdetails",
      component: NewRecruitDetails,
      defaultLoading: true,
      shouldAuthenticate: false,
      shouldHavePadding: true,
    },
    {
      path: "/attendance-details",
      component: IndividualInfo,
      defaultLoading: false,
      shouldAuthenticate: false,
      shouldHavePadding: true,
    }
  ],
  basePath: "/app",
};
