import {useStyles} from './style';
import { Button, Fade, Modal } from "@material-ui/core";
import Backdrop from '@material-ui/core/Backdrop';
import {ReactComponent as TvLogo } from '../../assets/images/logoComapct.svg';
import { Link } from 'react-router-dom';

const PoliciesAcceptanceModal = () => {
  const classes = useStyles();

  return(
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={true}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <div className={classes.paper}>
            <TvLogo className={classes.modalLogo} />
            <p className={classes.modalText}>Please accept our policies to proceed.</p>
            <p className={classes.modalLinkText}>
              Read about our policies.
            </p>
            <Link to="/app/policies" className={classes.modalLink}>
              <Button variant="contained" color="primary" className={classes.modalButton}>
                Go to Policies
              </Button>
            </Link>
          </div>
        </Fade>
      </Modal>
    </>
  )
}

export default PoliciesAcceptanceModal;