import React from 'react';
import {IconButton,Menu,MenuItem} from '@material-ui/core';
import FilterIcon from "../../../../assets/images/FilterIcon.svg";
import { useStyles } from "./style";

interface Iprops {
    items:string[],
    setFilter:React.Dispatch<React.SetStateAction<string>>,
}
const ITEM_HEIGHT = 48;

const Filter: React.FC<Iprops> = ({items,setFilter}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    
  };
  
  const handleClose = (value:string) => {
    items.forEach((item)=>{
      if(item === value){
        setFilter(value);
      }
    })
    setAnchorEl(null);
  };

  return (
    <div className={classes.root} >
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.filterIcon}
        
      >
        <img src={FilterIcon} alt=""/>
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
                 
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {items.map((item) => (
          <MenuItem key={item} onClick={()=>handleClose(item)}>
            {item}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default Filter;
