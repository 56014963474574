import React, { useEffect, useState } from "react";
import moment from "moment";
import { Grid, FormControl,FormLabel,TextField} from "@material-ui/core";
import RadioGroup from "../../../components/RadioGroup";
import { useStyles } from "./styles";
import { LeaveForm } from "./types";
import { LEAVE_TYPES_VALUES } from "../../../utils/constants";
import { LeaveTypes } from "../LeavesPage";

const DAY_TYPES = [
  { label: "Full Day", value: "fullDay" },
  { label: "First Half", value: "firstHalf" },
  { label: "Second Half", value: "secondHalf" },
];

interface IProps {
  fieldsState: LeaveForm;
  leaveTypesData: LeaveTypes[];
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleDelete: React.MouseEventHandler<SVGSVGElement> | undefined;
  disableTo?: boolean;
}

const ApplyLeaveForm: React.FC<IProps> = ({
  fieldsState,
  handleChange,
  handleDelete,
  leaveTypesData,
  disableTo,
}) => {
  const classes = useStyles();
  const [radioLeaveTypes, setRadioLeaveTypes] = useState<
    { label: string; value: string }[]
  >([]);

  
  useEffect(() => {
    const tempLeaveData: { label: string; value: string }[] = [];
    leaveTypesData.forEach((item) => {
      const temp_obj = {
        label: item.type.replace(/_/g, " "),
        value: item._id,
      };
      tempLeaveData.push(temp_obj);
    });
    setRadioLeaveTypes(tempLeaveData);
  }, [leaveTypesData]);

  const minDateForSickLeave =
    fieldsState.leave_type !== LEAVE_TYPES_VALUES.sickLeave
      ? moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD")
      : moment(new Date(), "YYYY-MM-DD")
          .subtract(6, "days")
          .format("YYYY-MM-DD");


  return (
      <Grid container className={classes.formContainer}>
        <Grid item md={6}>
          <RadioGroup
            label="Leave Type"
            name="leave_type"
            value={fieldsState.leave_type}
            radionButtons={radioLeaveTypes}
            handleChange={handleChange}
          />
        </Grid>

        <Grid item md={6} >
          <RadioGroup
            label="Day Type"
            name="day_type"
            value={fieldsState.day_type}
            radionButtons={DAY_TYPES}
            handleChange={handleChange}
          />
        </Grid>

        <Grid item md={4} spacing={3} className={classes.innerFormContainer}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend" className={classes.label}>
              From
            </FormLabel>
            <TextField
              id="from"
              variant="outlined"
              name="from"
              label="Select start date"
              type="date"
              value={moment(fieldsState.from).format("YYYY-MM-DD")}
              defaultValue={moment(new Date()).format("YYYY-MM-DD")}
              InputProps={{
                inputProps: {
                  min: minDateForSickLeave,
                },
              }}
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
              onKeyDown={(e) => e.preventDefault()}
              onKeyUp={(e) => e.preventDefault()}
            />
          </FormControl>
        </Grid>

        <Grid item md={4} spacing={3} className={classes.innerFormContainer}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend" className={classes.label}>
              To
            </FormLabel>
            <TextField
              InputProps={{
                inputProps: {
                  min: minDateForSickLeave,
                  max: moment(fieldsState.from, "YYYY-MM-DD")
                    .add(4, "days")
                    .format("YYYY-MM-DD"),
                },
              }}
              id="to"
              variant="outlined"
              name="to"
              label="Select end date"
              type="date"
              value={
                fieldsState.day_type === "firstHalf" ||
                fieldsState.day_type === "secondHalf"
                  ? moment(fieldsState.from).format("YYYY-MM-DD")
                  : moment(fieldsState.to).format("YYYY-MM-DD")
              }
              defaultValue={
                fieldsState.day_type === "firstHalf" ||
                fieldsState.day_type === "secondHalf"
                  ? moment(fieldsState.from).format("YYYY-MM-DD")
                  : moment(fieldsState.to).format("YYYY-MM-DD")
              }
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
              disabled={disableTo}
              onKeyDown={(e) => e.preventDefault()}
              onKeyUp={(e) => e.preventDefault()}
            />
          </FormControl>
        </Grid>

        <Grid item md={4} spacing={3} className={classes.innerFormContainer}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend" className={classes.label}>
              Duration
            </FormLabel>
            <TextField
              id="duration"
              variant="outlined"
              name="duration"
              label="Duration"
              disabled
              value={fieldsState.duration}
              className={classes.textField}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
        </Grid>

        <Grid item md={12} spacing={3} className={classes.innerFormContainer}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend" className={classes.label}>
              Reason
            </FormLabel>
            <TextField
              id="reason"
              variant="outlined"
              name="reason"
              label="Reason"
              multiline
              rows={5}
              onChange={handleChange}
              value={fieldsState.reason}
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
        </Grid>
      </Grid>
   
  );
};

export default ApplyLeaveForm;
