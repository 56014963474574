import { Box } from "@material-ui/core";
import LeaveCard from "./LeaveCard";
import { useStyles } from "./styles";
import { ILeave } from "./LeaveTracker";

const RemainingList = ({ data }: { data: ILeave[] }) => {
  const classes = useStyles();

  return (
    <Box className={classes.remainingList}>
      {data.length !== 0 &&
        data.map((item: ILeave) => {
          return (
            <LeaveCard
              key={item._id}
              empName={item.emp_name}
              duration={item.duration}
              adjustAmount={item.adjustAmount}
              leaveType={item.leave_type}
            />
          );
        })}
    </Box>
  );
};

export default RemainingList;
