import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    policyContainer: {
      display: "flex",
      gap: '1rem',
      borderRadius: 10,
      padding: '3%',
      textAlign: 'center'
    },
    policyText: {
      fontSize: 20
    },
    policyLink: {
      textDecoration: 'none'
    },
    policyButton: {
      backgroundColor: '#0E172A',
      color: '#fff',
    }
  })
);