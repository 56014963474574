import { makeStyles } from "@material-ui/core/styles";
// import { theme } from "../../../../theme/theme";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    background: "#fff",
    borderRadius: "10px",
  },

  top: {
    borderBottom: "1px solid #BFBFBF",
    padding: `${theme.typography.pxToRem(35)} ${theme.typography.pxToRem(25)}`,
  },

  mid: {
    padding: theme.typography.pxToRem(15),
  },

  bottom: {
    padding: theme.typography.pxToRem(15),
  },
  designation: {
    margin: 0,
    fontSize: theme.typography.pxToRem(10),
    fontWeight: 400,
    fontFamily: "Poppins",
  },

  name: {
    margin: 0,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
    fontFamily: "Poppins",
  },

  proPicContainer: {
    position: "relative",
  },

  editProfilePic: {
    position: "absolute",
    bottom: 0,
    left: "72%",
  },

  dropBoxContainer: {
    border: "1px dashed rgba(0, 0, 0, 0.25)",
    width: "100%",
    height: theme.typography.pxToRem(150),
    padding: theme.typography.pxToRem(20),
    borderRadius: "5px",
  },
  dropBoxLabel: {
    margin: 0,
    fontSize: theme.typography.pxToRem(10),
    fontWeight: 300,
    fontFamily: "Poppins",
    color: "#686868",
    marginBottom: theme.typography.pxToRem(5),
  },
  attachmentContainer: {
    textAlign: "center",
  },
  dropBoxLabelMain: {
    margin: 0,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
    color: "#000",
    fontFamily: "Poppins",
  },
  fileName: {
    margin: 0,
    fontSize: theme.typography.pxToRem(10),
    fontWeight: 500,
    color: "#000",
    fontFamily: "Poppins",
  },
  fileIconContainer: {
    textAlign: "center",
    marginBottom: theme.typography.pxToRem(15),
    position: "relative",
  },
  removeIcon: {
    position: "absolute",
    top: "-16%",
    right: "18%",
  },

  sectionTitle: {
    margin: 0,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
    color: "#000",
    fontFamily: "Poppins",
    marginBottom: theme.typography.pxToRem(30),
  },
  addAsset: {
    border: "1px dashed rgba(0, 0, 0, 0.25)",
    width: "100%",
    height: theme.typography.pxToRem(50),
    borderRadius: "5px",
    cursor: "pointer",
  },
  assetText: {
    margin: 0,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 500,
    color: "#000",
    fontFamily: "Poppins",
  },
  addIconClass: {
    height: theme.typography.pxToRem(20),
    width: theme.typography.pxToRem(20),
    marginRight: theme.typography.pxToRem(10),
  },
  assetFieldContainer: {
    marginBottom: theme.typography.pxToRem(10),
  },

  proPic: {
    height: theme.typography.pxToRem(100),
    width: theme.typography.pxToRem(100),
    borderRadius: "50%",
  },
  assignedDropDown: {},
  transferOwnershipButton: {
    marginTop: "20px",
  },
  transferOwnershipForm: {
    marginTop: "10px",
  },
  transferOwnershipLabel: {
    fontSize: "16px",
  },
}));
