import react,{ useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import BasicLayout from "../../components/BasicLayout";
import { useBasicNav } from "../../utils/useBasicNav";
import Table from "../../components/Table";
import Typography from "@material-ui/core/Typography";
import {
  getPolicies,
  uploadPolicies,
  deletePolicy,
  acceptPolicies,
} from "../../apis/policies";
import { useStyles } from "./style";
import {
  getPersistentAuthentication,
  setPersistentAuthentication,
} from "../../utils/functions";
import pdfIcon from "../../assets/icons/pdf.svg";
import docIcon from "../../assets/icons/doc.svg";
import closeIcon from "../../assets/icons/blackCross.svg";
import uploadButton from "../../assets/images/uploadButton.svg";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CircularProgress from "@material-ui/core/CircularProgress";
import { API_URL_DEL } from "../../config";
import { getUsers } from "../../apis/users";
import { DoneAll } from "@material-ui/icons";
import { HourglassEmptyOutlined } from "@material-ui/icons";
import { permissions } from "../../recoil/atoms";
import { useRecoilState } from "recoil";
import { useHistory } from "react-router-dom";
import { policies, policiesData, PolicyResponse } from "./types";

const HEAD_CELLS = [
  { key: "fullname", label: "Name" },
  { key: "empStatus", label: "Status" },
  { key: "acceptedOn", label: "Accepted On" },
];

interface Iprops {
  loadPrimary: (event: boolean, basic?: boolean) => void;
  showToast: any;
}
/**
 * @description Functional component which renders the policy details - files, accept button, upload button and a list of employees who has accepted the policy
 * @param {any} loadPrimary
 * @param {any} showToast
 * @returns a node
 */
const PoliciesPage: React.FC<Iprops> = ({ loadPrimary, showToast }) => {
  useBasicNav("policies");

  const classes = useStyles();
  const [permission, setPermission] = useRecoilState<any>(permissions);
  const [policyData, setPolicyData] = useState<policiesData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [toggle, setToggle] = useState<boolean>(false);
  const [tableItems, setTableItems] = useState<policies[]>([]);
  const userId = getPersistentAuthentication()?.userId;
  const createPermission = permission?.policies?.create;
  const deletePermission = permission?.policies?.del;
  const isPolicyAccepted = getPersistentAuthentication()?.isPolicyAccepted;
  const isSuperAdmin = ["superadmin","admin","Admin"].includes(permission?.roleName);
  const history = useHistory();

  useEffect(() => {
    loadPrimary(true, true);
    getPoliciesData();
    getPoliciesStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @description Function which handles file upload
   * @method handleUpload
   * @param {any} event
   * @returns nothing
   */
  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    showToast.success("Uploading file");
    setLoading(true);
    let formData = new FormData();
    let file = event.target.files as FileList;
    const uploadedFile = file[0];
    file && formData.append("attachment", uploadedFile);
    try {
      await uploadPolicies(formData);
      showToast.success("File was uploaded successfully");
      await getPoliciesData();
      setLoading(false);
      event.target.value = "";
    } catch (error: any) {
      setLoading(false);
      showToast.error("Something went wrong");
      event.target.value = "";
    }
  };

  /**
   * @description Function to change employee policy acceptance state
   * @method acceptPolicy
   * @param {string} id
   * @returns nothing
   */
  const acceptPolicy = async (id: string) => {
    try {
      const res = await acceptPolicies(id);
      const sessionData = getPersistentAuthentication();
      sessionData.isPolicyAccepted = true;
      setPersistentAuthentication(sessionData);
      setToggle(!toggle);
      setTimeout(() => {
        history.push("/app/dashboard");
      }, 1000);
    } catch (error: any) {
      showToast.error("Something went wrong!");
    }
  };

  /**
   * @description Function to delete a policy
   * @method deletePolicyHandler
   * @param {string} id
   * @returns nothing
   */
  const deletePolicyHandler = async (id: string) => {
    showToast.success("Deleting file");
    setLoading(true);
    try {
      await deletePolicy(id);
      showToast.success("File was deleted successfully");
      await getPoliciesData();
      setLoading(false);
    } catch(error:any){
      setLoading(false);
      showToast.error("Something went wrong!");
    }
  };

  /**
   * @description Function which fetches all the policy files and stores them in a state
   * @method getPolicyData
   * @returns nothing
   */
  async function getPoliciesData() {
    try {
      const res = await getPolicies();
      let tempItem: policiesData[] = [];
      res?.data.forEach((item :policiesData)=>{
        let tempData={
          policy:item.policy,
          _id:item._id,
        }
        tempItem.push(tempData);
      })
      setPolicyData(tempItem);
      loadPrimary(false);
    } catch (error) {
      showToast.error("Something went wrong");
    }
  }

  /**
   * @description Function to get the policy acceptance state of all employees and put them into a table item list
   * @method getPoliciesStatus
   * @returns nothing
   */
  const getPoliciesStatus = async () => {
    try {
      let response = await getUsers();
      let tempItem: policies[] = [];
      response?.data?.docs?.forEach((user: PolicyResponse) => {
        let tempObjUser = {
          fullname: user?.basicDetails?.fullName,
          empStatus: user?.isPolicyAccepted,
          acceptedOn: user?.policyAcceptedDate,
          id: user?.id,
        };
        tempItem.push(tempObjUser);
      });
      setTableItems([...tempItem]);
    } catch (error: any) {
      showToast.error("Something went wrong!");
    }
  };

  const generatePolicies = () => {
    return tableItems.map((item) => ({
      fullname: item.fullname,
      empStatus:
        item.empStatus === true ? (
          <DoneAll className={classes.approvedIcon} />
        ) : (
          <HourglassEmptyOutlined className={classes.pendingIcon} />
        ),
      acceptedOn: item.acceptedOn,
      id: item.id,
    }));
  };
  return (
    <BasicLayout>
      <Box className={classes.root}>
        <Box maxWidth="457px">
          <Typography color="primary" gutterBottom>
            Policy Documents :
          </Typography>

          {/* Upload Section */}
          {createPermission && (
            <Box
              border="2px dashed rgba(0, 0, 0, 0.25)"
              borderRadius="10px"
              padding="39px"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gridGap="3px"
              my="30px"
            >
              <Typography>Drag & drop files to upload</Typography>

              <Typography className={classes.subtitle}>
                Maximum file upload size 12 MB
              </Typography>

              <Button component="label">
                <img src={uploadButton} alt="upload button" />
                <input
                  type="file"
                  hidden
                  onChange={(e) => handleUpload(e)}
                  accept=".docx, .doc, .pdf"
                />
              </Button>
            </Box>
          )}
        </Box>
        {/* Policy File List */}
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="flex-end"
          flexWrap="wrap"
        >
          {loading ? (
            <Box
              m={3}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          ) : (
            policyData?.map((item: policiesData) => {
              return (
                <Box
                  m={1}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <div className={classes.imageGroup}>
                    <img
                      alt=""
                      onClick={() =>
                        window.open(`${API_URL_DEL}/${item.policy}`)
                      }
                      src={item.policy.slice(-3) === "pdf" ? pdfIcon : docIcon}
                    />
                    {deletePermission && (
                      <img
                        alt=""
                        onClick={() => deletePolicyHandler(item._id)}
                        src={closeIcon}
                        className={classes.closeImage}
                      />
                    )}
                  </div>
                  <Typography noWrap className={classes.fileName} gutterBottom>
                    {item.policy.split("-")[0]}
                  </Typography>
                </Box>
              );
            })
          )}
          {createPermission && deletePermission && (
            <Grid className={classes.tableContainer} item xs={12}>
              <Table
                headCells={HEAD_CELLS}
                items={generatePolicies()}
                isStatusIndicator={false}
                isAction={false}
              />
            </Grid>
          )}
        </Box>
        {/* Policy Accept Button */}
        {!isSuperAdmin && policyData?.length > 0 && (
          <Box my={4}>
            {!isPolicyAccepted ? (
              <Button
                onClick={() => acceptPolicy(userId)}
                startIcon={<CheckBoxOutlineBlankIcon />}
              >
                I have read and accepted all the policies
              </Button>
            ) : (
              <Button
                className={classes.successButton}
                startIcon={<DoneAllIcon />}
              >
                Policies accepted
              </Button>
            )}
          </Box>
        )}
      </Box>
    </BasicLayout>
  );
};

export default PoliciesPage;
