import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  radioBtn: {
    "& svg": {
      width: theme.typography.pxToRem(13),
      height: theme.typography.pxToRem(13),
    },
  },

  label: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    color: "#686868",
    opacity: "0.9",
    textTransform: "uppercase",
    marginBottom:".5em",
  },

  inputLabel: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "13px !important",
    lineHeight: theme.typography.pxToRem(12),
    opacity: "0.5",
  },

  inputLabelChecked: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "13px !important",
    lineHeight: theme.typography.pxToRem(12),
  },
}));
