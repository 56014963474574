import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  container: {
    padding: "3%",
  },
  formContainer: {
    width: "100%",
  },
  submitButton: {
    backgroundColor: "#14213D",
    color: "#fff",
  },
  formControlsWidth: {
    minWidth: "60%",
  },
  viewPadding: {
    paddingTop: "1em",
  },
  viewTextLabel: {
    textAlign: "left",
    width: "100%",
  },
  viewText: {
    textAlign: "left",
    paddingLeft: "1em",
    color: "#000",
  },
});
