import {
  Box,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { FormikErrors } from "formik";
import moment from "moment";
import { useParams } from "react-router-dom";
import AddKraIcon from "../../../assets/icons/add-kra.svg";
import DeleteKraIcon from "../../../assets/icons/delete-kra.svg";
import { usePermissionObjectFor } from "../../../hooks/permission/usePermissionObjectFor";
import { IAttributeFields } from "../../../recoil/kraAtom";
import { kraStyles } from "../styles";
import { IAttribute } from "../sub-pages/BehavioralAttributes";

interface IKRACard {
  kraData: IAttribute;
  kraError: (FormikErrors<IAttribute> | undefined)[];
  type?: string;
  addHandler?: (idx: number) => void;
  deleteHandler?: (idx: number) => void;
  idx: number;
  // handleChange: (id: string, fieldName: string, fieldValue: string) => void;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
  };
  name: string[];
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<(FormikErrors<IAttributeFields> | undefined)[]>;
}

const kraCreatedDate = moment("24/03/2023", "DD/MM/YYYY");
const currentDate = moment("24/03/2023", "DD/MM/YYYY");

const KRACard = ({
  // attribute,
  kraData,
  kraError,
  type,
  addHandler,
  deleteHandler,
  handleChange,
  idx,
  name,
  setFieldValue,
}: IKRACard) => {
  const classes = kraStyles();
  const permissions = usePermissionObjectFor("kras");
  const hasApprovePermission = permissions.includes("approve");
  const hasCreatePermission = permissions.includes("create");
  const hasReadPermission = permissions.includes("read");
  const hasUpdatePermission = permissions.includes("upDate");
  const params = useParams<{ kraId?: string; userId: string }>();
  let user = "employee";
  if (hasApprovePermission) {
    user = "admin";
  }
  if (hasCreatePermission) {
    user = "teamLead";
  }
  let mode = "create";
  if (params.userId && params.kraId) {
    mode = "edit";
  }

  let disableAppraisalRating = true;
  if (user.includes("teamLead") && mode.includes("edit")) {
    disableAppraisalRating = false;
  }
  let disableSelfRating = true;
  if (user.includes("employee") && mode.includes("edit")) {
    disableSelfRating = false;
  }
  let disableKraField = false;
  if (mode.includes("edit")) {
    disableKraField = true;
  }
  let disableActionButtons = false;
  if (mode.includes("edit")) {
    disableActionButtons = true;
  }

  return (
    <Box
      style={{
        border: "1px solid rgba(20, 0, 71, 0.25)",
        borderRadius: "10px",
        padding: "0 1em",
        paddingTop: "0.2em",
        paddingBottom: "3em",
        marginTop: ".7em",
      }}
    >
      <Grid container>
        <Grid item xs={12} style={{ marginBottom: "1.5em" }}>
          {type === "behavioralAttribute" ? (
            <Box style={{ padding: "1em" }}></Box>
          ) : (
            <>
              {mode.includes("edit") && <Box style={{ padding: "1em" }}></Box>}
              {mode.includes("create") && (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {deleteHandler && (
                    <IconButton
                      onClick={() => deleteHandler(idx!)}
                      disabled={idx === 0 || disableActionButtons}
                    >
                      <img src={DeleteKraIcon} />
                    </IconButton>
                  )}

                  {addHandler && (
                    <IconButton
                      onClick={() => addHandler(idx!)}
                      disabled={disableActionButtons}
                    >
                      <img src={AddKraIcon} />
                    </IconButton>
                  )}
                </Box>
              )}
            </>
          )}
        </Grid>

        {/*1st row  */}
        <Grid item container xs={12} style={{ padding: "0 3em" }}>
          <Grid item xs={5}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="body1"
                style={{ fontSize: ".8em", fontWeight: 600 }}
              >
                KRA*
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  width: "60%",
                }}
              >
                {type === "behavioralAttribute" ? (
                  <TextField
                    inputProps={{ style: { fontSize: ".8em" } }}
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                    name={name[0]}
                    value={kraData?.KRA}
                    onChange={handleChange}
                  />
                ) : (
                  // KRA field
                  <TextField
                    inputProps={{ style: { fontSize: ".8em" } }}
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled={disableKraField}
                    name={name[0]}
                    value={kraData?.KRA}
                    onChange={handleChange}
                  />
                )}
                <span className={classes.spanError}>{kraError[idx]?.KRA}</span>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={5}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="body1"
                style={{
                  fontSize: ".8em",
                  fontWeight: 600,
                }}
              >
                Weight in KRA*
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  width: "60%",
                }}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  InputProps={{
                    style: { fontSize: ".8em" },
                  }}
                  disabled
                  name={name[1]}
                  value={kraData.weight}
                  onChange={handleChange}
                />
                <span>{kraError[idx]?.weight}</span>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* 2nd row */}
        <Grid
          item
          container
          xs={12}
          style={{ marginTop: "2em", padding: "0 3em" }}
        >
          <Grid item xs={5}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="body1"
                style={{ fontSize: ".8em", fontWeight: 600 }}
              >
                Self rating
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  width: "60%",
                }}
              >
                {/* disabled for TL */}
                {/* Self Rating */}
                <TextField
                  disabled={disableSelfRating}
                  fullWidth
                  variant="outlined"
                  size="small"
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: type === "behavioralAttribute" ? 5 : 10,
                    },
                    style: { fontSize: ".8em" },
                  }}
                  name={name[2]}
                  value={kraData?.selfRating}
                  onChange={handleChange}
                  onWheel={(e: React.WheelEvent<HTMLDivElement>) => {
                    const target = e.target as HTMLDivElement;
                    target.blur();
                  }}
                  // onFocus={() => setFieldValue(name[2], "", false)}
                />
                <span>{kraError[idx]?.selfRating}</span>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={5}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="body1"
                style={{
                  fontSize: ".8em",
                  fontWeight: 600,
                }}
              >
                Appraiser's rating
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  width: "60%",
                }}
              >
                {/* disabled for employee */}
                {/* Appraisal Rating */}
                <TextField
                  disabled={disableAppraisalRating}
                  fullWidth
                  variant="outlined"
                  size="small"
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: type === "behavioralAttribute" ? 5 : 10,
                    },
                    style: { fontSize: ".8em" },
                  }}
                  name={name[3]}
                  value={kraData?.appraiserRating}
                  onChange={handleChange}
                  onWheel={(e: React.WheelEvent<HTMLDivElement>) => {
                    const target = e.target as HTMLDivElement;
                    target.blur();
                  }}
                  // onFocus={() => setFieldValue(name[3], "", false)}
                />
                {kraError[idx]?.appraiserRating}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default KRACard;
