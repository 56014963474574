import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useStyles from "./styles";
import { AccordionIcons, handleAccorExpansions } from "./utils";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  accrChecklistExpansion,
  accrCompanyEmailExpansion,
  accrExpansionState,
  accrUploadsExpansion,
} from "../../recoil/AccordionExpansion";

interface OnboardingAccordionProps {
  title: string;
  showToast: any;
  index: number;
  stepNumber: number;
  loadPrimary: (event: any, state: any) => void;
}
const OnboardingAccordion: React.FC<OnboardingAccordionProps> = ({
  title,
  showToast,
  index,
  children,
  stepNumber,
  loadPrimary,
}) => {
  const classes = useStyles();
  let [accordionState, setAccordionState] = useRecoilState(accrExpansionState);
  let [accorTwo, setAccorTwo] = useRecoilState(accrUploadsExpansion);
  let [accorThree, setAccorThree] = useRecoilState(accrCompanyEmailExpansion);
  let [accorFour, setAccorFour] = useRecoilState(accrChecklistExpansion);

  const getClasses = () => {
    if (
      title === "Basic Details" ||
      title === "Techvariable Documents" ||
      title === "Company Email" ||
      title === "Tasks"
    ) {
      return classes.rootBasicDetails;
    } else if (
      title === "Educational Qualification" ||
      title === "Proof of Identity" ||
      title === "Asset Allocation"
    ) {
      return classes.rootEduQual;
    } else if (
      title === "Work Experience" ||
      title === "Academic Certificates"
    ) {
      return classes.rootWorkExperience;
    } else if (
      title === "Family and Language" ||
      title === "Work Experience Documents"
    ) {
      return classes.rootFamilyNlanguage;
    } else if (title === "Bank Details") {
      return classes.rootBankDetails;
    } else {
      return classes.rootEmployeeInfo;
    }
  };

  const callClasses = getClasses();

  return (
    <div className={classes.rootOnboarding}>
      <Accordion
        defaultExpanded={title === "Basic Details" ? true : false}
        className={callClasses}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordionTitle}
        >
          <Typography className={classes.heading}>
            {AccordionIcons(title)}
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
      <br />
    </div>
  );
};
export default OnboardingAccordion;
