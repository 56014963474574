import axios from "./axios.global";

const BASE_URL = "content-basket";

export const contentformData = async (userId: string, payload: object) => {
  const { title, contentArea, contentType } = payload as {title: string; contentArea: string; contentType: string}; //sending form data
  try {
    let response = await axios.post(`${BASE_URL}/${userId}`, {
      title,
      contentArea,
      contentType,
    });
    return { data: response, error: undefined };
  } catch (err: any) {
    return { data: undefined, error: err.response.data };
  }
};

export const contentformUpload = async (formData: FormData, cid: string) => {
  //sending uploaded file

  try {
    let response = await axios.patch(`${BASE_URL}/upload/${cid}`, formData, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
    return response;
  } catch (err: any) {
    return err.response.data;
  }
};
export const deleteContent = async (cid:string)=>{
  try {
    let response = await axios.delete(`${BASE_URL}/delete/${cid}`);
    return response;
  } catch (err: any) {
    return err.response.data;
  }

}

export const getcontentData = async (
  pageNo: number,
  searchKey: string,
  status: string
) => {
  //getting content data

  try {
      let response = await axios.get(`${BASE_URL}`,{
        params:{
          page:pageNo,
          search:searchKey,
          status:status,
        }
      });
      return response;
  } catch (err: any) {
    return err.response.data;
  }
};
export const getcontentIdData = async (contentId: string) => {
  try {
    let response = await axios.get(`${BASE_URL}/${contentId}`);

    return response;
  } catch (err: any) {
    return err.response.data;
  }
};
export const setStatus = async (contentId: string, Avalue: string) => {
  const payload = {
    status: Avalue,
  };
  try {
    let response = await axios.patch(
      `${BASE_URL}/update-status/${contentId}`,
      payload
    );

    return response;
  } catch (err: any) {
    return err.response.data;
  }
};

export const updateContent = async (contentId: string, payload: object) => {
  try {
    let response = await axios.patch(
      `${BASE_URL}/update/${contentId}`,
      payload
    );
    return response;
  } catch (err: any) {
    return err.response.data;
  }
};
