import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from "@material-ui/core";
import rightArrow from "../../assets/images/rightArrow.png";
import React from "react";
import useStyles from "./styles";
import mail from "../../assets/images/mail.png";
import lock from "../../assets/images/lock.png";
import { Visibility, VisibilityOff } from "@material-ui/icons";

interface signInFormProps {
  handleSubmit: (event: any) => void;
  handleChange: (event: any) => void;
  handleChangeLogin?: () => void;
  handleForgotPassword?: () => void;
  values: {
    username?: string;
    email?: string;
    password: string;
    repassword?: string;
  };
  isSubmitting: boolean;
  isLogin: boolean;
  submitText: string;
}

const SignInForm: React.FC<signInFormProps> = ({
  handleSubmit,
  handleChange,
  values,
  isSubmitting,
  handleChangeLogin,
  isLogin,
  submitText,
  handleForgotPassword,
}) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = React.useState<any>({
    password: false,
    repassword: false,
  });

  /**
   * @description Function to show or hide password text
   * @method handleClickShowPassword
   * @param {any} val
   * @returns nothing
   */
  const handleClickShowPassword = (val: any) => {
    setShowPassword({
      ...showPassword,
      [val]: !showPassword[val],
    });
  };

  /**
   * @description Function to stop event propagation on the parent element when password hide/show button is clicked
   * @method handleMouseDownPassword
   * @param {React.MouseEvent<HTMLButtonElement>} event
   */
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Paper className={classes.paper} variant="elevation" elevation={0}>
          <Grid container spacing={1}>
            {isLogin && (
              <Grid item xs={12} className={classes.inputSection}>
                <Grid container>
                  <Grid item xs={2} className={classes.adornmentGrid}>
                    <img src={mail} alt="lock" height="45px" />
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      name="username"
                      label="Email"
                      //type='email'
                      autoFocus
                      size="small"
                      className={classes.textField}
                      value={values.username}
                      onChange={handleChange}
                      InputProps={{
                        classes: { input: classes.inputField },
                        disableUnderline: true,
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} className={classes.inputSection}>
              <Grid container>
                <Grid item xs={2} className={classes.adornmentGrid}>
                  <img src={lock} alt="lock" height="45px" />
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    name="password"
                    label="Password"
                    variant="standard"
                    size="small"
                    className={classes.textField}
                    type={showPassword.password ? "text" : "password"}
                    value={values.password}
                    onChange={handleChange}
                    InputProps={{
                      classes: { input: classes.inputField },
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              handleClickShowPassword("password");
                            }}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword.password ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            {!isLogin && (
              <Grid item xs={12} className={classes.inputSection}>
                <Grid container>
                  <Grid item xs={2} className={classes.adornmentGrid}>
                    <img src={lock} alt="lock" height="45px" />
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      name="repassword"
                      label="Confirm Password"
                      variant="standard"
                      size="small"
                      className={classes.textField}
                      type={showPassword.repassword ? "text" : "password"}
                      value={values.repassword}
                      onChange={handleChange}
                      InputProps={{
                        classes: { input: classes.inputField },
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => {
                                handleClickShowPassword("repassword");
                              }}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword.repassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelRoot,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            {isLogin && (
              <Grid
                item
                xs={12}
                className={classes.forgotPass}
                onClick={handleForgotPassword}
              >
                <span className={classes.link}>Forgot Password?</span>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              className={
                isLogin ? classes.buttonSection : classes.resetPassSection
              }
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
                disableElevation
                className={classes.button}
              >
                {submitText}
              </Button>
            </Grid>
          </Grid>
          {isLogin && (
            <div className={classes.employeeLinkContainer}>
              <span
                className={classes.link}
                onClick={() => {
                  handleChangeLogin?.();
                }}
              >
                Login as Employee <img src={rightArrow} alt="arrow" />
              </span>
            </div>
          )}
        </Paper>
      </form>
    </>
  );
};

export default SignInForm;
