import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import rhr from "rehype-raw";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import * as pt from "react-syntax-highlighter/dist/cjs/styles/prism";
import { useStyles } from "./styles";
import { postData } from "../../KnowledgeHubPage";
import useDebounce from "../../../../hooks/useDebounce";
import { updatePost } from "../../../../apis/kHubPost";
import { useParams } from "react-router-dom";
import CodeMirror from "@uiw/react-codemirror";
import { markdown } from "@codemirror/lang-markdown";

export const themes: { [key: string]: any } = {
    a11yDark: pt.a11yDark,
    atomDark: pt.atomDark,
    base16AteliersulphurpoolLight: pt.base16AteliersulphurpoolLight,
    coldarkCold: pt.coldarkCold,
    coldarkDark: pt.coldarkDark,
    coy: pt.coy,
    darcula: pt.darcula,
    dark: pt.dark,
    duotoneEarth: pt.duotoneEarth,
    duotoneForest: pt.duotoneForest,
    duotoneLight: pt.duotoneLight,
    duotoneSea: pt.duotoneSea,
    duotoneSpace: pt.duotoneSpace,
    funky: pt.funky,
    ghcolors: pt.ghcolors,
    hopscotch: pt.hopscotch,
    materialDark: pt.materialDark,
    materialLight: pt.materialLight,
    materialOceanic: pt.materialOceanic,
    nord: pt.nord,
    okaidia: pt.okaidia,
    pojoaque: pt.pojoaque,
    prism: pt.prism,
    shadesOfPurple: pt.shadesOfPurple,
    solarizedlight: pt.solarizedlight,
    synthwave84: pt.synthwave84,
    tomorrow: pt.tomorrow,
    twilight: pt.twilight,
    vs: pt.vs,
    vsDark: pt.vsDark,
    vscDarkPlus: pt.vscDarkPlus,
    xonokai: pt.xonokai,
};

export const CodeBlock = function (selectedTheme: string) {
    const theme = themes[selectedTheme];
    return {
        code({
            node,
            inline,
            className,
            children,
            ...props
        }: {
            [key: string]: any;
        }) {
            const match = /language-(\w+)/.exec(className || "");
            return !inline && match ? (
                <SyntaxHighlighter
                    customStyle={{
                        backgroundColor: "rgb(236, 239, 241)",
                    }}
                    style={theme}
                    language={match[1]}
                    PreTag="div"
                    {...props}
                >
                    {String(children).replace(/\n$/, "")}
                </SyntaxHighlighter>
            ) : (
                <code className={className} {...props}>
                    {children}
                </code>
            );
        },
    };
};

interface props {
    showPreview: boolean;
    setPostData: React.Dispatch<React.SetStateAction<postData>>;
    postData: postData;
}

const MdEditor: React.FC<props> = ({ showPreview, setPostData, postData }) => {
    const classes = useStyles();
    const param: any = useParams();

    const [theme, setTheme] = useState<any>(themes.atomDark);
    const dbState = useDebounce(postData.body, 1000);

    /**
     * @description Function to set the markdown value to be provided for parsing.
     * @param value
     */
    function handleEditorChange(value: any) {
        setPostData({ ...postData, body: value });
    }

    // function that auto saves the content for the post
    async function autoSave() {
        await updatePost(param.id, postData);
    }

    useEffect(() => {
        autoSave();
    }, [dbState]);

    if (showPreview) {
        return (
            <ReactMarkdown
                components={CodeBlock(theme)}
                className={classes.preview}
                remarkPlugins={[gfm]}
                rehypePlugins={[rhr]}
            >
                {postData.body}
            </ReactMarkdown>
        );
    }

    return (
        <CodeMirror
            value={postData.body}
            extensions={[markdown()]}
            theme="dark"
            height="47rem"
            className={classes.editor}
            onChange={handleEditorChange}
        />
    );
};

export default MdEditor;
