import React from "react";
import { SetterOrUpdater } from "recoil";

interface setAssetAllocationProps {
  assetInfo: {
    assetID: string;
    assetName: string;
  }[];
  getAssetData: any;
  setAssetInfo: SetterOrUpdater<
    {
      assetID: string;
      assetName: string;
    }[]
  >;
}

export const setAssetAllocation = ({
  assetInfo,
  getAssetData,
  setAssetInfo,
}: setAssetAllocationProps) => {
  let assetArray: any = [];

  if (getAssetData.length > 0) {
    getAssetData.forEach(
      (value: { assetID: string; assetName: string }, index: number) => {
        let assets = [...assetInfo];
        let asset: any = { ...assets[index] };
        asset.assetID = value.assetID;
        asset.assetName = value.assetName;
        assetArray.push(asset);
      }
    );
    console.warn({ assetArray });
    setAssetInfo(() => assetArray);
  }
};
