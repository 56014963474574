import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  ReqOverview: {
    borderLeft: "5px solid #F3D90C",
    borderRadius: "8px",
  },
  projectDesignContainer: {
    borderLeft: "5px solid #EFA2CC",
    borderRadius: "8px",
  },
  button: {
    fontFamily: "Poppins",
    fontStyle: " normal",
    fontWeight: 500,
    backgroundColor: "#1C2943",
    color: "#FFFFFF",
  },
  acceptedfilestext: {
    color: "grey",
    fontSize: "11px",
  },
  attachedfilestext: {
    fontWeight: 500,
  },
  innertext: {
    color: "grey",
    fontSize: "12px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  projectSowContainer: {
    borderLeft: "5px solid #CB8EE0",
    borderRadius: "8px",
  },
  imageGroup: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    cursor: "pointer",
  },
  fileName: {
    position: "relative",
    left: "1rem",
    "&:hover": {
      color: "#6F8FAF",
    },
  },
  link: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "grey",
    cursor: "pointer",
    "&:hover": {
      color: "black",
    },
  },
  linkText: {
    marginLeft: ".5em",
  },
  innerlinktext: {
    fontSize: "12px",
  },
  docsIcon: {
    "&:hover": {
      color: "#6F8FAF",
    },
  },
  withDeleteContainer:{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",  
  },
  buttonWithNoPadding:{
    padding:0,
    marginRight:".5rem"
  },
  linkcontainer:{
    display: "flex", 
    justifyContent: "space-between"
  },
  loading:{
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
  },
  deleteicon:{
    padding: 0, 
    marginLeft: "auto"
  }

}));
