import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import { Color } from "./AdminMenu";


export const useStyles = makeStyles<Theme, Color>((theme: Theme) => ({


  published: {
    color: "#BBBE1A",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "15px",
  },

  approved: {
    color: "#1ABE2A",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "15px",
    fontSize: "12px",


  },
  sentback: {
    color: "#BE1A90",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "15px",
  },
  rejected: {
    color: "#BE1A1A",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "15px",

  },
  underreview: {
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "15px",
    color: "#1A65BE",
  },
  select: (p) =>  ({
    
    "&.MuiInputBase-root": {
      fontSize: "12px",
      color: p.color,
      height:"3em",
      "&:hover fieldset":{
        borderColor: "silver",
        padding:0,
      }
    }
    
  })

}))