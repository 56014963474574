import { makeStyles, withStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  roundedCornerContainer: {
    border: "1px solid rgba(20, 33, 61, 0.2)",
    marginBlock: 13,
    boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.2)",
    borderRadius: 8,
  },
  smallTypoTopLeft: {
    marginTop: -11,
    marginLeft: 13,
    padding: "0 5",
    backgroundColor: "white",
   
  },
  horizontalBoxContainerStyle: {
    padding: 15,
    display: "flex",
    justifyContent: "space-between",
  },
  horizontalBoxLessStyleContainerStyle: {
    width: "100%",
    paddingInline: 25,
  },
  roundedBoxTitle: {
    fontFamily: "Poppins",
    fontSize:".9rem",
    fontWeight: 500,
    color: "#000000",
  },
  
}));
