import React, { useContext, useEffect, useState } from "react";
import { useStyles } from "./styles";
import { getHolidayListAPI } from "../../../apis/holidays";
import moment from "moment";
import { Box, Paper, Typography } from "@material-ui/core";
import { HolidayContext } from "../DashboardPage";

interface IHoliday {
  occassion: string;
  date: string;
}

const MONTH_NAMES = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const HolidayList = () => {
  const classes = useStyles();
  const [holidayArray, setHolidayArray] = useState<IHoliday[]>([]);
  const { holidayListRefetch } = useContext(HolidayContext);

  const getHolidayList = async () => {
    const res = await getHolidayListAPI();
    if (!res.data.length) {
      return;
    }
    setHolidayArray(res.data);
  };

  useEffect(() => {
    getHolidayList();
  }, []);

  useEffect(() => {
    getHolidayList();
  }, [holidayListRefetch]);

  return (
    <Box className={classes.container}>
      {holidayArray.length == 0 && (
        <Typography style={{ marginTop: "2em" }}>
          No data found for the current year!!
        </Typography>
      )}
      {holidayArray.length !== 0 &&
        holidayArray.map((item: IHoliday) => {
          const date = moment(item.date).format("DD");
          const monthName = moment(item.date).format("MMMM").slice(0, 3);
          return (
            <Paper
              elevation={2}
              className={classes.holidayCard}
              key={item.occassion}
            >
              <div className={classes.dateBox}>
                <p style={{ margin: 0, fontSize: ".8em", fontWeight: 600 }}>
                  {date}
                </p>
                <p
                  style={{
                    margin: 0,
                    marginLeft: ".2em",
                    fontSize: ".8em",
                    fontWeight: 600,
                  }}
                >
                  {monthName}
                </p>
              </div>
              <p className={classes.day}>{moment(item.date).format("dddd")}</p>
              <p className={classes.occassionName}>{item.occassion}</p>
            </Paper>
          );
        })}
    </Box>
  );
};

export default HolidayList;
