import React, { useState, useEffect, useCallback } from "react";
import { Grid, Button } from "@material-ui/core";
import ProfileIcon from "../../../../assets/images/profile.svg";
import addIcon from "../../../../assets/icons/addIcon.svg";
import Dropzone from "react-dropzone";
import TextField from "../../../Fields/TextField";
import docIcon from "../../../../assets/icons/doc.svg";
import pdfIcon from "../../../../assets/icons/pdf.svg";
import crossIcon from "../../../../assets/icons/blackCross.svg";
import pencilIcon from "../../../../assets/icons/pencil.svg";
import uploadButton from "../../../../assets/images/uploadButton.svg";
import { deleteAttachment } from "../../../../apis/users";
import { useDropzone } from "react-dropzone";
import "./dropzone.css";
import { useStyles } from "./styles";
import SelectField from "../../../Fields/SelectField/index";

interface IProps {
  handleInputAssetChange: any;
  handleAddField: () => void;
  profileImage: any;
  setProfileImage: any;
  uploadedFiles: any;
  setUploadedFiles: any;
  assetList: any;
  fullname: string;
  showToast: any;
  userId: string;
  ownershipList?: any;
  currentUserId?: any;
}

const AttachmentSection: React.FC<IProps> = ({
  handleInputAssetChange,
  handleAddField,
  assetList,
  profileImage,
  uploadedFiles,
  setUploadedFiles,
  setProfileImage,
  fullname,
  userId,
  showToast,
  ownershipList,
  currentUserId,
}) => {
  const classes = useStyles();

  useEffect(() => {}, [assetList]);

  const [displayImage, setDisplayImage] = useState<any>("");
  const [newOwner, setNewOwner] = React.useState<any>(ownershipList[0]._id);
  

  const onDrop = useCallback((acceptedFiles) => {
    let type = acceptedFiles[0]?.name?.split(".").pop();
    
    if (
      type === "svg" ||
      type === "xls" ||
      type === "pdf" ||
      type === "docx" ||
      type === "jpg" ||
      type === "jpeg"
    ) {
      setUploadedFiles((file: any) => {
        return [...file, acceptedFiles[0]];
      });
    } else {
      showToast.error("Not a valid type");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    // acceptedFiles,
    getRootProps,
    getInputProps,
  } = useDropzone({
    onDrop,
  });

  const onDropImage = (accepted: any) => {
    let type = accepted[0]?.name?.split(".").pop();
    if (
      type === "svg" ||
      type === ".png" ||
      type === "jpg" ||
      type === "jpeg"
    ) {
      setProfileImage(accepted[0]);
      var blobPromise = new Promise((resolve, reject) => {
        const reader = new window.FileReader();
        reader.readAsDataURL(accepted[0]);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
        };
      });
      blobPromise.then((value) => {
        setDisplayImage(value);
      });
    } else {
      showToast.error("Not a valid type");
    }
  };

  const handleRemoveFile = (index: number, val: { name: string }) => {
    deleteAttachment(val.name, userId)
      .then((res) => {
        uploadedFiles.splice(index, 1);
        setUploadedFiles([...uploadedFiles]);
      })
      .catch((err) => {});
  };

  const handleOwnershipChange = (e: any) => {
    setNewOwner(e.target.value);
  };

  const transferOwnershipToNewUser = (oldId: any, newId: any) => {
    
    showToast.success("Ownership is successfully transfered");
  };

  

  return (
    <Grid direction="column" container className={classes.mainContainer}>
      <Grid
        direction="column"
        justify="center"
        alignItems="center"
        container
        className={classes.top}
      >
        <Dropzone onDrop={(acceptedFiles) => onDropImage(acceptedFiles)}>
          {({ getRootProps, getInputProps }) => (
            <Grid {...getRootProps()} className={classes.proPicContainer}>
              <input {...getInputProps()} />
              <img
                className={classes.proPic}
                src={
                  displayImage?.length
                    ? displayImage
                    : profileImage.length
                    ? profileImage
                    : ProfileIcon
                }
                alt="profile-icon"
              />
              <img
                className={classes.editProfilePic}
                src={pencilIcon}
                alt="edit-icon"
              />
            </Grid>
          )}
        </Dropzone>
        <Grid direction="column" justify="center" alignItems="center" container>
          <h3 className={classes.name}>
            {fullname.length ? fullname : "Full name"}
          </h3>
          <p className={classes.designation}>Designation</p>
        </Grid>
      </Grid>
      <Grid direction="column" container className={classes.mid}>
        <Grid direction="column" container>
          <h4 className={classes.sectionTitle}>Files uploaded:</h4>
          <Grid alignItems="baseline" container>
            {uploadedFiles.map((item: any, i: number) => {
              let type = item?.name?.split(".").pop();
              return (
                <Grid
                  xs={4}
                  className={classes.fileIconContainer}
                  direction="column"
                  justify="center"
                  container
                >
                  <img
                    onClick={() => handleRemoveFile(i, item)}
                    className={classes.removeIcon}
                    src={crossIcon}
                    alt="cross-icon"
                  />
                  <img
                    src={type === "docx" ? docIcon : pdfIcon}
                    alt="doc-icon"
                  />
                  <p className={classes.fileName}>{item?.name}</p>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid
          justify="center"
          alignItems="center"
          className={classes.dropBoxContainer}
          container
        >
          <section className="container">
            <div className={classes.attachmentContainer} {...getRootProps()}>
              <input {...getInputProps()} />
              <p className={classes.dropBoxLabelMain}>
                Drag and drop files to upload
              </p>
              <p className={classes.dropBoxLabel}>
                Maximum file upload size 12 MB
              </p>
              <img src={uploadButton} alt="upload-button" />
            </div>
          </section>
        </Grid>
      </Grid>
      <Grid direction="column" container className={classes.bottom}>
        <h4 className={classes.sectionTitle}>Asset details:</h4>

        {assetList.map((item: any, i: number) => {
          
          return (
            <Grid
              justify="space-between"
              className={classes.assetFieldContainer}
              container
            >
              <Grid item xs={5}>
                <TextField
                  variant="standard"
                  name="assetName"
                  value={item.asset_name}
                  type=""
                  label="Asset Name"
                  onChange={(e: any) => handleInputAssetChange(e, i)}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  variant="standard"
                  name="assetId"
                  value={item.asset_id}
                  type=""
                  label="Asset ID"
                  onChange={(e: any) => handleInputAssetChange(e, i)}
                />
              </Grid>
            </Grid>
          );
        })}

        <Grid
          container
          alignItems="center"
          justify="center"
          onClick={handleAddField}
          className={classes.addAsset}
        >
          <img className={classes.addIconClass} src={addIcon} alt="add-icon" />
          <p className={classes.assetText}> Add a new asset</p>
        </Grid>

        {currentUserId && (
          <>
            <Grid className={classes.transferOwnershipForm}>
              <SelectField
                options={ownershipList}
                onSelect={handleOwnershipChange}
                value={newOwner}
                name="transferOwnership"
                type="form"
                label="Transfer ownership to"
                labelIdentifier="fullname"
                valueIdentifier="_id"
              />
            </Grid>
            <Grid
              container
              alignItems="center"
              justify="flex-end"
              className={classes.transferOwnershipButton}
            >
              <Button
                onClick={() => {
                  transferOwnershipToNewUser(currentUserId, newOwner);
                }}
                color="primary"
                variant="contained"
              >
                Transfer Ownership
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default AttachmentSection;
