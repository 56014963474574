import React, { useRef, useState, useLayoutEffect, useEffect } from "react";
import DetailedLeaveReport from "../DetailedLeaveReport";
import { useStyles } from "./Styles";
import Accordion from "../../../components/Accordion";
import { allLeaves } from "../../../apis/leaves";
import { capitalizeString,lowerCaseString } from "../../../utils/functions";
import { io } from "socket.io-client";
import { WEB_SOCKET_URL } from "../../../config";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import {
  detailedLeaveReport,
  leaveDetails,
  leaveDetailsResponse,
} from "../types";
import { HourglassEmptyOutlined } from "@material-ui/icons";
import { DoneAll } from "@material-ui/icons";
import { NotInterested } from "@material-ui/icons";
import { usePermissionObjectFor } from "../../../hooks/permission/usePermissionObjectFor";

interface Iprops {
  loadPrimary: (event: any, state: any) => void;
  showToast: any;
  setPageNo: React.Dispatch<React.SetStateAction<number>>;
  pageNo: number;
}

const limit = 10;

const LeaveReportPage: React.FC<Iprops> = ({
  showToast,
  loadPrimary,
  setPageNo,
  pageNo,
}) => {
  const classes = useStyles();
  const [rootHeight, setRootHeight] = useState<number>(600);
  const [sortTag, setSortTag] = useState<string>("");
  const [searchString, setSearchString] = useState<string>("");

  const permission = usePermissionObjectFor("leave");
  const hasCreateAccess = permission.includes("create");
  const hasGrantAccess = permission.includes("grant");
  const isHR = permission.includes("adjust");
  
  const accordionRoot = useRef<any>(null);
  const [socketStatus, setSocketStatus] = useState<boolean>(false);
  const [tableItems, setTableItems] = useState<detailedLeaveReport[]>([]);
  const [totalData, setTotalData] = useState<number>(0);

  useEffect(() => {
    let socket = io(`${WEB_SOCKET_URL}`, { transports: ["websocket"] });
    socket.on("message", (message: string) => {
      setSocketStatus(!socketStatus);
    });

    return () => {
      socket.disconnect();
    };
  }, [socketStatus]);

  useEffect(() => {
    getAllLeaves();
  }, [pageNo, searchString,sortTag]);

  const changeSortTag = (tag: string) => {
    setSortTag(tag);
    setPageNo(1);
  };

  const handleSearch = (nameString: string) => {
    setSearchString(nameString);
    setPageNo(1);
  };

  function PageCount(totalLength: number) {
    let totalPage = Math.ceil(totalLength / limit);
    return totalPage === 0 ? 1 : totalPage;
  }

  /**
   * @description Function to fetch leaves data of all employees (including the assignee leaves)
   * @method getAllLeaves
   * @return nothing
   */
  const getAllLeaves = async () => {
    try {
      let response = await allLeaves(pageNo, limit, searchString,lowerCaseString(sortTag));
      if (response?.data?.data.length === 0) {
        setPageNo(1);
      }
      setTotalData(response?.data?.total);
      let tempItem: leaveDetails[] = [];
      response?.data?.data?.forEach((user: leaveDetailsResponse) => {
        let tempObjUser = {
          name: user?.emp_name ?? "N/A",
          startDate: moment(user?.from).format("DD-MM-YYYY"),
          endDate: moment(user?.to).format("DD-MM-YYYY"),
          by: (user?.approved_by || user?.rejected_by) ?? "N/A",
          type: user?.leave_type ?? "N/A",
          reason: user?.reason ?? "N/A",
          isPending: user?.isPending ?? false,
          isApproved: user?.isApproved ?? false,
        };
        tempItem.push(tempObjUser);
      });
      setTableItems(tempItem);
     
    } catch (error: any) {
      showToast.error(error.response.data.message);
    }
  };

  const generateLeaveDetails = () => {
    return tableItems.map((item) => ({
      name: item.name,
      startDate: item.startDate,
      endDate: item.endDate,
      by: item.by,
      type: capitalizeString(item.type.replace(/_/, " ")),
      reason: item.reason,
      status: item.isPending ? (
        <HourglassEmptyOutlined className={classes.pendingIcon} />
      ) : item.isApproved ? (
        <DoneAll className={classes.approvedIcon} />
      ) : (
        <NotInterested className={classes.rejectedIcon} />
      ),
    }));
  };

  const handleChangePagination = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageNo(value);
  };

  const accordionData = [
    {
      title: "Detailed Leave Report",
      component: (
        <DetailedLeaveReport
          parentHeight={rootHeight}
          sortTag={sortTag}
          searchName={searchString}
          tableItems={generateLeaveDetails()}
        />
      ),

      shouldDisplay: hasCreateAccess === false || isHR || hasGrantAccess,
      shouldSort: true,
      changeSortTag: changeSortTag,
      handleNameSearch: handleSearch,
    },
  ];
 let totalPageCount = PageCount(totalData);
  return (
    <>
      <div className={classes.contentWrapper} ref={accordionRoot}>
        <Accordion data={accordionData} />
      </div>

      <div className={classes.pagination}>
        <Pagination
          defaultPage={pageNo}
          onChange={handleChangePagination}
          count={totalPageCount}
          color="primary"
        />
      </div>
    </>
  );
};

export default LeaveReportPage;
