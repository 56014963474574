import { Button, Grid } from "@material-ui/core";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  checkWorkDocumentSelectionAtom,
  enableClickAtom,
  isUploadsViewEnabled,
  uploadDocumentsStepsAtom,
} from "../../../../../recoil/atoms";
import { UploadsContext } from "../../../../../store/uploadsStore";
import { useStyles } from "../styles";
import { handleWorkFiles } from "../utils";
import RelievingLetter from "./RelievingLetter";
import LastSalarySlip from "./LastSalarySlip";
import SecondLastSalary from "./SecondLastSalarySlip";
import ThirdLastSalarySlip from "./ThirdLastSalarySlip";
import { documentDownloadAtom } from "../../../../../recoil/downloadAtoms";

export interface  IExperienceChildrenProps {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setIsSubmit: React.Dispatch<any>;
  showToast: {
    error: (message: string) => void;
    success: (message: string) => void;
    warning: (message: string) => void;
  };
  getEndpoint: string;
  imgName: {
    relivingLetter: string;
    salarySlip1st: string;
    salarySlip2nd: string;
    salarySlip3rd: string;
  };
  imgSize: {
    relivingLetter: number;
    salarySlip1st: number;
    salarySlip2nd: number;
    salarySlip3rd: number;
  };
  setImgName: Dispatch<
    SetStateAction<{
      relivingLetter: string;
      salarySlip1st: string;
      salarySlip2nd: string;
      salarySlip3rd: string;
    }>
  >;
  setImgSize: Dispatch<
    SetStateAction<{
      relivingLetter: number;
      salarySlip1st: number;
      salarySlip2nd: number;
      salarySlip3rd: number;
    }>
  >;
}

interface WorkExperienceDocumentsProps {
  showToast: any;
  index: number;
  getEndpoint: string;
  uploadDocsEndpointPatch: string;
  headerPropsGet: any;
  headerPropsPatch: any;
}

const WorkExperienceDocuments: React.FC<WorkExperienceDocumentsProps> = ({
  showToast,
  getEndpoint,
  uploadDocsEndpointPatch,
  headerPropsGet,
  headerPropsPatch,
}) => {
  const classes = useStyles();
  const { experienceProof, setExperienceProof, handlePostWorkExperience } =
    useContext(UploadsContext);

  const [, setIsFileSelected] = useRecoilState(checkWorkDocumentSelectionAtom);
  const [, setIsSubmit] = useState<any>({});
  const [, setClickable] = useRecoilState(enableClickAtom);
  const [, setUploadDocsStepsatom] = useRecoilState(uploadDocumentsStepsAtom);
  const isViewEnabled = useRecoilValue(isUploadsViewEnabled);
  const [, setRewriteFileDownload] = useRecoilState(documentDownloadAtom);

  const [imageName, setImageName] = useState<{
    relivingLetter: string;
    salarySlip1st: string;
    salarySlip2nd: string;
    salarySlip3rd: string;
  }>({
    relivingLetter: "",
    salarySlip1st: "",
    salarySlip2nd: "",
    salarySlip3rd: "",
  });

  const [imageSize, setImageSize] = useState<{
    relivingLetter: number;
    salarySlip1st: number;
    salarySlip2nd: number;
    salarySlip3rd: number;
  }>({
    relivingLetter: 0,
    salarySlip1st: 0,
    salarySlip2nd: 0,
    salarySlip3rd: 0,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileHandler = handleWorkFiles(
      event,
      setIsSubmit,
      setImageName,
      setImageSize,
      setIsFileSelected,
      experienceProof,
      setExperienceProof,
      showToast,
      setRewriteFileDownload
    );
    const handler = fileHandler[event.target.name];
    handler();
  };

  const handleSubmit = () => {
    experienceProof.forEach((value, index) => {
      if (value.name === "relivingLetter" && value.value !== "") {
        setIsSubmit((prev: any) => ({
          ...prev,
          reliving: true,
        }));
      }

      if (value.name === "salarySlip1st" && value.value !== "") {
        setIsSubmit((prev: any) => ({
          ...prev,
          fileSalary1: true,
        }));
      }

      if (value.name === "salarySlip2nd" && value.value !== "") {
        setIsSubmit((prev: any) => ({
          ...prev,
          fileSalary2: true,
        }));
      }

      if (value.name === "salarySlip3rd" && value.value !== "") {
        setIsSubmit((prev: any) => ({
          ...prev,
          fileSalary3rd: true,
        }));
      }
    });

    setUploadDocsStepsatom((prev) => ({
      ...prev,
      academicProof: true,
      identityProof: true,
      acceptedOfferLetter: true,
      worExperienceProof: true,
    }));
    handlePostWorkExperience(
      showToast,
      uploadDocsEndpointPatch,
      headerPropsPatch
    );
    setClickable((prev) => ({
      ...prev,
      uploads: true,
    }));
  };

  return (
    <Grid container item xs={12} spacing={3} style={{ paddingTop: "2em" }}>
      <Grid item xs={12} md={6} lg={6} xl={12}>
        <RelievingLetter
          handleChange={handleChange}
          setIsSubmit={setIsSubmit}
          showToast={showToast}
          getEndpoint={getEndpoint}
          imgName={imageName}
          setImgName={setImageName}
          imgSize={imageSize}
          setImgSize={setImageSize}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6} xl={12}>
        <LastSalarySlip
          handleChange={handleChange}
          setIsSubmit={setIsSubmit}
          showToast={showToast}
          getEndpoint={getEndpoint}
          imgName={imageName}
          setImgName={setImageName}
          imgSize={imageSize}
          setImgSize={setImageSize}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={6} xl={12}>
        <SecondLastSalary
          handleChange={handleChange}
          setIsSubmit={setIsSubmit}
          showToast={showToast}
          getEndpoint={getEndpoint}
          imgName={imageName}
          setImgName={setImageName}
          imgSize={imageSize}
          setImgSize={setImageSize}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6} xl={12}>
        <ThirdLastSalarySlip
          handleChange={handleChange}
          setIsSubmit={setIsSubmit}
          showToast={showToast}
          getEndpoint={getEndpoint}
          imgName={imageName}
          setImgName={setImageName}
          imgSize={imageSize}
          setImgSize={setImageSize}
        />
      </Grid>

      <Grid xs={12} className={classes.submitDiv}>
        {isViewEnabled.workExp ? (
          <Button className={classes.submitButtonDisabled} disabled>
            Saved
          </Button>
        ) : (
          <Button
            className={classes.submitButton}
            // onClick={handlePostWorkExperience}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default WorkExperienceDocuments;
