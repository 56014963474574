import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@material-ui/core";
import React,{useContext} from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { KHubPost } from "../../../../recoil/atoms";
import { getPersistentAuthentication } from "../../../../utils/functions";
import { deletePost } from "../../../../apis/kHubPost";
import { Toast } from "../../KnowledgeHubPage";
import { useStyles } from "./styles";

interface props {
  post:KHubPost;
  fetch:()=>Promise<void>;
}

const Post: React.FC<props> = ({post,fetch}) => {
  const classes = useStyles();
  const { path } = useRouteMatch();
  const userId = getPersistentAuthentication()?.userId;
  const showToast = useContext(Toast);

  const deleteHandler = async () => {
     const confirm = window.confirm(
      "Are you sure you want to delete the Article?"
    );
    if(confirm){
    try {
        await deletePost(post._id);
        await fetch();     
    } catch (error: any) {
      showToast.error(error.message);
    }
  }
  };

  return (
    <Card className={classes.card} elevation={0}>
      <CardContent className={classes.cardContent}>
        <div className={classes.heading}>
          <Avatar
            className={classes.avatar}
            src={post.author.profilePic}
          ></Avatar>
          <Typography variant="body2" className={classes.name}>
            {post.author.basicDetails.fullName}
          </Typography>
        </div>
        <Typography variant="body1" component="p" className={classes.content}>
          {post.title}
        </Typography>
      </CardContent>
      <CardActions className={classes.cardActions}>
        {post.author._id === userId && (
          <>
            <Link
              to={{
                pathname: `${path}/editor-view/${post._id}`,
                state: { ...post },
              }}
            >
              Edit
            </Link>
            <div></div>
          </>
        )}
        <Link
          to={{
            pathname: `${path}/post/${post._id}`,
            state: { ...post },
          }}
        >
          View
        </Link>
        
        {post.author._id === userId && <><div></div> <span className={classes.delete} onClick={deleteHandler}>Delete</span></>}
      </CardActions>
    </Card>
  );
};

export default Post;
