import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Button, Grid, Typography } from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import { useRecoilState, useRecoilValue } from "recoil";
import { Dispatch, SetStateAction, useContext } from "react";
import { useStyles } from "./styles";
import {
  isUploadsViewEnabled,
  recruiteeBasicDetails,
} from "../../../../../recoil/atoms";
import { fetchFileLink } from "./utils";
import { UploadsContext } from "../../../../../store/uploadsStore";
import { documentDownloadAtom } from "../../../../../recoil/downloadAtoms";

type Document =
  // | "tvDocs"
  | "passportDoc"
  | "personalDoc"
  | "aadharDoc"
  | "panDoc"
  | "class10"
  | "class12"
  | "graduation"
  | "postGrad"
  | "diploma"
  | "relieving"
  | "lastSalSlip"
  | "seconLastSalSlip"
  | "thirdLastSalSlip";

interface UploadedDocumentsProps {
  imageName: string;
  progress: number;
  imageSize: number;
  setImgName: Dispatch<
    SetStateAction<
      | {
          passportPhoto: string;
          personalPhoto: string;
        }
      | {
          class10th: string;
          class12th: string;
          graduation: string;
          postGraduation: string;
          diploma: string;
        }
      | {
          offerLetter: string;
        }
      | {
          relivingLetter: string;
          salarySlip1st: string;
          salarySlip2nd: string;
          salarySlip3rd: string;
        }
      | {
          adhaarCard: string;
          panCard: string;
        }
      | any
    >
  >;
  setImgSize?: any;
  label: Document;
  getEndpoint?: string;
}

const UploadedDocuments = ({
  imageName,
  progress,
  setImgName,
  setImgSize,
  label,
  getEndpoint,
}: UploadedDocumentsProps) => {
  const classes = useStyles();

  const {
    // offerLetter,
    // setOfferLetter,
    idProofs,
    setIdentityProof,
    academicProofs,
    setAcademicProof,
    experienceProof,
    setExperienceProof,
    photos,
    setPhotos,
  } = useContext(UploadsContext);

  const [, setIsViewEnabled] = useRecoilState(isUploadsViewEnabled);
  const basicDetails = useRecoilValue(recruiteeBasicDetails);
  const rewriteFileDownload = useRecoilValue(documentDownloadAtom);

  const openNewTab = async () => {
    const userId = basicDetails.id;
    const getLink = await fetchFileLink({
      label,
      photos,
      // offerLetter,
      idProofs,
      academicProofs,
      experienceProof,
      userId,
      rewriteFileDownload,
      getEndpoint,
    });
    window.open(getLink, "_blank", "noopener,noreferrer");
  };

  // const updateOfferLetter = () => {
  //   setOfferLetter(() => ({ name: "", value: "" }));
  //   setImgName({ offerLetter: "" });
  //   setIsViewEnabled((prev) => ({ ...prev, offerLetter: false }));
  // };

  const updatePhotos = (name: string) => {
    photos.forEach((photo, index) => {
      if (photo.name === name) {
        setPhotos((prev) => {
          let temp = prev;
          temp.splice(index, 1);
          return temp;
        });
        setImgName(
          (prev: { passportPhoto: string; personalPhoto: string }) => ({
            ...prev,
            [name]: "",
          })
        );
        setIsViewEnabled((prev) => ({ ...prev, photos: false }));
      }
    });
  };

  const updateIdProofs = (label: string) => {
    idProofs.forEach((proof, index) => {
      if (proof.name === label) {
        setIdentityProof((prev) => {
          let temp = prev;
          temp.splice(index, 1);
          return temp;
        });

        setImgName((prev: { adhaarCard: string; panCard: string }) => ({
          ...prev,
          [label]: "",
        }));
        setIsViewEnabled((prev) => ({ ...prev, identity: false }));
      }
    });
  };

  const updateAcademics = (label: string) => {
    academicProofs.forEach((academic, index) => {
      if (academic.name === label) {
        setAcademicProof((prev) => {
          let temp = prev;
          temp.splice(index, 1);
          return temp;
        });
        setImgName(
          (prev: {
            class10th: string;
            class12th: string;
            graduation: string;
            postGraduation: string;
            diploma: string;
          }) => ({ ...prev, [label]: "" })
        );
        setIsViewEnabled((prev) => ({ ...prev, academic: false }));
      }
    });
  };

  const updateExperience = (label: string) => {
    experienceProof.forEach((experience, index) => {
      if (experience.name === label) {
        setExperienceProof((prev) => {
          let temp = prev;
          temp.splice(index, 1);
          return temp;
        });
        setImgName(
          (prev: {
            relivingLetter: string;
            salarySlip1st: string;
            salarySlip2nd: string;
            salarySlip3rd: string;
          }) => ({ ...prev, [label]: "" })
        );
        setIsViewEnabled((prev) => ({ ...prev, workExp: false }));
      }
    });
  };

  const actionMap: { [key in Document]: () => void } = {
    // tvDocs: updateOfferLetter,
    passportDoc: () => updatePhotos("passportPhoto"),
    personalDoc: () => updatePhotos("personalPhoto"),
    aadharDoc: () => updateIdProofs("adhaarCard"),
    panDoc: () => updateIdProofs("panCard"),
    class10: () => updateAcademics("class10th"),
    class12: () => updateAcademics("class12th"),
    graduation: () => updateAcademics("graduation"),
    postGrad: () => updateAcademics("postGraduation"),
    diploma: () => updateAcademics("diploma"),
    relieving: () => updateExperience("relivingLetter"),
    lastSalSlip: () => updateExperience("salarySlip1st"),
    seconLastSalSlip: () => updateExperience("salarySlip2nd"),
    thirdLastSalSlip: () => updateExperience("salarySlip3rd"),
  };

  const removeDoc = () => {
    const action = actionMap[label];
    if (action) {
      action();
    }
  };

  // const removeDoc = () => {
  //   switch (label) {
  //     case "tvDocs":
  //       updateOfferLetter();
  //       break;
  //     case "passportDoc":
  //       updatePhotos("passportPhoto");
  //       break;
  //     case "personalDoc":
  //       updatePhotos("personalPhoto");
  //       break;
  //     case "aadharDoc":
  //       updateIdProofs("adhaarCard");
  //       break;
  //     case "panDoc":
  //       updateIdProofs("panCard");
  //       break;
  //     case "class10":
  //       updateAcademics("class10th");
  //       break;

  //     case "class12":
  //       updateAcademics("class12th");
  //       break;

  //     case "graduation":
  //       updateAcademics("graduation");
  //       break;

  //     case "postGrad":
  //       updateAcademics("postGraduation");
  //       break;

  //     case "diploma":
  //       updateAcademics("diploma");
  //       break;

  //     case "relieving":
  //       updateExperience("relivingLetter");
  //       break;

  //     case "lastSalSlip":
  //       updateExperience("salarySlip1st");
  //       break;

  //     case "seconLastSalSlip":
  //       updateExperience("salarySlip2nd");
  //       break;
  //     case "thirdLastSalSlip":
  //       updateExperience("salarySlip3rd");
  //       break;

  //     default:
  //       break;
  //   }
  // };

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      className={classes.container}
    >
      <Grid item sm={9} md={9} lg={9} xl={9} className={classes.fileContainer}>
        <Grid container direction="row">
          <Grid item md={2}>
            <DescriptionIcon />
          </Grid>
          <Grid item md={10}>
            <Typography className={classes.text}>{imageName}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={3} md={3} lg={3} xl={3}>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            style={{ backgroundColor: "white" }}
          >
            <DeleteOutlineOutlinedIcon
              fontSize="small"
              style={{
                color: "#ed1c24",
                paddingTop: "1em",
                cursor: "pointer",
              }}
              onClick={removeDoc}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            style={{ backgroundColor: "white" }}
          >
            <div>
              <Button
                disableRipple
                disableTouchRipple
                component="a"
                variant="text"
                color="default"
                download
                onClick={() => openNewTab()}
              >
                <GetAppOutlinedIcon
                  fontSize="medium"
                  style={{
                    color: "#0885DF",
                    paddingTop: "0.5em",
                    paddingRight: "0.5em",
                  }}
                />
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid spacing={1} container direction="column">
        <Grid xs item>
          {progress === 0 ? (
            <></>
          ) : (
            <>
              <LinearProgress
                value={progress}
                variant="determinate"
                style={{ marginLeft: "2em", marginRight: "2em" }}
              />
              <br />
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UploadedDocuments;
