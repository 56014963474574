import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import KRACard from "../components/KRACard";
import * as yup from "yup";
import { IDetailsProps } from "./BasicInfo";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import {
  formDataAtom,
  IAttributes,
  IFormData,
  modeAtom,
} from "../../../recoil/kraAtom";
import { createEmployeeKraAPI, patchEmployeeKraAPI } from "../../../apis/kra";
import { KRAContext } from "../KRAPage";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { usePermissionObjectFor } from "../../../hooks/permission/usePermissionObjectFor";

export interface IAttribute {
  id: string;
  KRA: string;
  weight: number;
  selfRating: number;
  appraiserRating: number;
}

export const initialBehavioralAttributes = [
  {
    id: "1",
    KRA: "Communication with coworkers",
    weight: 5,
    selfRating: 0,
    appraiserRating: 0,
  },
  {
    id: "2",
    KRA: "Communication with client",
    weight: 5,
    selfRating: 0,
    appraiserRating: 0,
  },
  {
    id: "3",
    KRA: "Communication over email",
    weight: 5,
    selfRating: 0,
    appraiserRating: 0,
  },
  {
    id: "4",
    KRA: "Communication over responsiveness",
    weight: 5,
    selfRating: 0,
    appraiserRating: 0,
  },
  {
    id: "5",
    KRA: "Team Player- able to engage with all team members and collaborate",
    weight: 5,
    selfRating: 0,
    appraiserRating: 0,
  },
  {
    id: "6",
    KRA: "Relationship Maintaining and building with other Development Teams",
    weight: 5,
    selfRating: 0,
    appraiserRating: 0,
  },
  {
    id: "7",
    KRA: "Collaboration with Marketing Team",
    weight: 5,
    selfRating: 0,
    appraiserRating: 0,
  },
  {
    id: "8",
    KRA: "Time Management",
    weight: 5,
    selfRating: 0,
    appraiserRating: 0,
  },
  {
    id: "9",
    KRA: "Client Orientation",
    weight: 5,
    selfRating: 0,
    appraiserRating: 0,
  },
  {
    id: "10",
    KRA: "Ownership and accountability",
    weight: 5,
    selfRating: 0,
    appraiserRating: 0,
  },
  {
    id: "11",
    KRA: "Willingness to learn and improve",
    weight: 5,
    selfRating: 0,
    appraiserRating: 0,
  },
  {
    id: "12",
    KRA: "Good work ethic",
    weight: 5,
    selfRating: 0,
    appraiserRating: 0,
  },
  {
    id: "13",
    KRA: "Dependability",
    weight: 5,
    selfRating: 0,
    appraiserRating: 0,
  },
  {
    id: "14",
    KRA: "Proactiveness",
    weight: 5,
    selfRating: 0,
    appraiserRating: 0,
  },
  {
    id: "15",
    KRA: "Adaptability",
    weight: 5,
    selfRating: 0,
    appraiserRating: 0,
  },
  {
    id: "16",
    KRA: "Ask for help whenever required",
    weight: 5,
    selfRating: 0,
    appraiserRating: 0,
  },
];

interface IParams {
  userId: string;
  kraId?: string;
}

const BehavioralAttributes = ({ customCB }: IDetailsProps) => {
  const [formData, setFormData] = useRecoilState(formDataAtom);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const { showToast } = useContext(KRAContext);

  const permissions = usePermissionObjectFor("kras");
  const hasCreatePermission = permissions.includes("create");

  const history = useHistory();
  const { userId, kraId } = useParams() as IParams;

  const resetFormData = useResetRecoilState(formDataAtom);
  const mode = useRecoilValue(modeAtom);

  const ValidationSchema = yup.array().of(
    yup.object().shape({
      id: yup.string(),
      KRA: yup.string().required("Required"),
      weight: yup.number().required("Required"),
      selfRating: yup.number().required("Required"),
      appraiserRating: yup.number().required("Required"),
    })
  );

  const { values, errors, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: formData.attributes.behavioral,
      validationSchema: ValidationSchema,
      validateOnChange: false,
      onSubmit: (vals) => {
        setFormData((prev) => ({
          ...prev,
          attributes: {
            ...prev.attributes,
            behavioral: vals,
          },
        }));

        if (mode === "EDIT") {
          let isBehavioralRatingZero = vals.some(
            (curElem) => curElem.appraiserRating === 0
          );
          if (hasCreatePermission && isBehavioralRatingZero) {
            setOpenDialog(true);
            return;
          }

          updateKRA(kraId!, {
            technical: formData.attributes.technical,
            behavioral: vals,
          });
          return;
        }

        //create KRA
        createKRA(formData);
      },
    });

  const createKRA = async (payload: IFormData) => {
    try {
      const res = await createEmployeeKraAPI(userId, payload);
      if (res.status === 201) {
        showToast.success("KRA created successfully!!");
        resetFormData();
        history.push(`/app/kra`);
      }
    } catch (error: any) {
      showToast.error(error.response.data.message);
    }
  };

  const updateKRA = async (kraID: string, payload: IAttributes) => {
    try {
      const res = await patchEmployeeKraAPI(kraID, payload);
      if (res.status === 200) {
        showToast.success("KRA updated successfully!!");
        resetFormData();
        history.push(`/app/kra`);
      }
    } catch (error: any) {
      showToast.error(error.response.data.message);
      // console.error("hello error 2", error);
    }
  };

  const handleClose = () => setOpenDialog(false);

  const handleYes = () => {
    handleClose();
    if (mode === "EDIT") {
      updateKRA(kraId!, {
        technical: formData.attributes.technical,
        behavioral: values,
      });
    }
  };

  return (
    <Box style={{ display: "flex", flexDirection: "column" }}>
      {openDialog && (
        <Dialog open={openDialog} onClose={handleClose}>
          <DialogContent style={{ paddingTop: "2em" }}>
            <DialogContentText
              id="alert-dialog-description"
              style={{ color: "black" }}
            >
              Some KRA fields are rated as 0. Do you wish to continue ?
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ padding: "1em 2em" }}>
            <Button onClick={handleYes} color="primary" variant="contained">
              Yes
            </Button>
            <Button onClick={handleClose} color="primary" variant="outlined">
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <form onSubmit={handleSubmit} id="form3">
        {values.map((curElem, index) => (
          <div key={curElem.id}>
            <KRACard
              type={"behavioralAttribute"}
              name={[
                `[${index}].KRA`,
                `[${index}].weight`,
                `[${index}].selfRating`,
                `[${index}].appraiserRating`,
              ]}
              idx={index}
              kraData={curElem}
              kraError={errors}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />
          </div>
        ))}
      </form>

      {/* button */}
      {/* <Button
        variant="contained"
        color="primary"
        style={{
          alignSelf: "flex-end",
          marginTop: "2em",
          fontSize: ".875em",
          fontWeight: 600,
          padding: ".6em 2em",
        }}
      >
        Save
      </Button> */}
    </Box>
  );
};

export default BehavioralAttributes;
