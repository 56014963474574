import { SetterOrUpdater } from "recoil";

interface setEmployeeInformationProps {
  getEmpInfoDetails: any;
  setEmployeeInfo: SetterOrUpdater<{
    employeeNo: number;
    department: string;
    status: boolean;
    designation: string;
    role: string;
    assignedTo: string;
  }>;
}

export const handleChangeEmployeeInfo = (
  event: any,
  setEmployeeInfo: any,
  radioValue: boolean,
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>,
  setIsEmpNo: React.Dispatch<React.SetStateAction<boolean>>,
  setIsDept: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const allHandlers: any = {
    employeeNumber: () => {
      if (event.target.value.length < 1) {
        setIsEmpNo(false);
      }
      if (event.target.value === 0) {
        setIsEmpNo(false);
      } else {
        setIsEmpNo(true);
        setEmployeeInfo((prevState: any) => ({
          ...prevState,
          employeeNo: event.target.value,
        }));
      }
    },
    department: () => {
      if (event.target.value == "") {
        setIsDept(false);
      } else {
        setIsDept(true);
        setEmployeeInfo((prevState: any) => ({
          ...prevState,
          department: event.target.value,
        }));
      }
    },
    role: () => {
      setEmployeeInfo((prevState: any) => ({
        ...prevState,
        role: event.target.value,
      }));
    },
    assignedTo: () => {
      setEmployeeInfo((prevState: any) => ({
        ...prevState,
        assignedTo: event.target.value,
      }));
    },
  };
  return allHandlers;
};

export const setEmployeeInformation = ({
  getEmpInfoDetails,
  setEmployeeInfo,
}: setEmployeeInformationProps) => {
  setEmployeeInfo({
    department: getEmpInfoDetails?.department
      ? getEmpInfoDetails?.department
      : "",
    designation: getEmpInfoDetails?.designation
      ? getEmpInfoDetails?.designation
      : "",
    employeeNo: getEmpInfoDetails?.employeeNo
      ? getEmpInfoDetails?.employeeNo
      : 0,
    status: true,
    role: getEmpInfoDetails?.role,
    assignedTo: getEmpInfoDetails?.assignedTo,
  });
};
