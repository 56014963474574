export function fillKraGraphData(data: number[], label: string[]) {
  const filledData = [...data, ...new Array(4 - data.length).fill(0)];
  const filledLabels = [
    ...label,
    ...new Array(4 - data.length).fill("Q-YYYY"),
  ].splice(0, 4);
  filledData.reverse();
  filledLabels.reverse();
  return { data: filledData, label: filledLabels };
}
