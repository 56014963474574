import { makeStyles } from "@material-ui/core";

const leaveCountRoot = {
  padding: ".5em",
  width: "11em",
};

const leaveCountText = {
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "12px",
  lineHeight: "15px",
};

const leaveCountValues = {
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "33px",
  lineHeight: "46px",
};

export const useStyles = makeStyles({
  leaveResponseLoader: {
    padding: "3em",
  },
  leaveResponseRoot: {
    padding: ".5em",
  },
  leaveResponseText: {
    marginTop:".5em",
    left:"1em",
    padding: ".3em",
    position:"relative",
    fontWeight:600,
    width:"100%",
  },
  leaveWarningText: {
    border:"1px solid #d5de2a",
    color: "#d6ad06",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    borderRadius:"10px",
  },
  leaveConfirmText: {
    color: "black",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    fontWeight:500,
  },

  leaveCountRootPL: {
    ...leaveCountRoot,
    border: "1px solid #0885DF",
    borderRadius: "0.625rem",
    backgroundColor: "rgba(8, 133, 223, 0.05)",
  },
  leaveCountTextPL: {
    ...leaveCountText,
    color: "#0885DF",
    textAlign: "center",
  },
  leaveCountValuesPL: {
    ...leaveCountValues,
    color: "#0885DF",
    textAlign: "center",
  },

  leaveCountRootCL: {
    ...leaveCountRoot,
    border: "1px solid #DD0909",
    borderRadius: "0.625rem",
    backgroundColor: "rgba(217, 2, 2, 0.05)",
  },
  leaveCountTextCL: {
    ...leaveCountText,
    color: "#DD0909",
    textAlign: "center",
  },
  leaveCountValuesCL: {
    ...leaveCountValues,
    color: "#DD0909",
    textAlign: "center",
  },

  leaveCountRootSL: {
    ...leaveCountRoot,
    border: "1px solid #F3D90C",
    borderRadius: "0.625rem",
    backgroundColor: "rgba(243, 217, 12, 0.05)",
  },
  leaveCountTextSL: {
    ...leaveCountText,
    color: "#F3D90C",
    textAlign: "center",
  },
  leaveCountValuesSL: {
    ...leaveCountValues,
    color: "#F3D90C",
    textAlign: "center",
  },
  leaveNoteText:{
    color: "#092b4f",
    border:"1px solid #124d8c",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    borderRadius:"10px",
  },
  warning:{
    padding:"0 1rem",
    marginBottom:".5rem",
  },
  notes:{
    marginBottom:".5rem",
    padding:" 0 1rem"
   
  },
  labels:{
    fontWeight:500
  },
  formdetails:{
    border:"1px solid silver",
    borderRadius:"7px",
    padding:".5em",

  },
  containerOne:{
    display: "flex",
  },
  labelValue:{
    position:"relative",
    left:".5em",
  },
  containerTwo:{
    display: "flex",
    marginTop:".5rem",
  },
  containerThree:{
    width:"100%",
    display: "flex",
    marginTop:".1rem",
    padding:"5px",
    maxHeight:"10rem",
    overflowY:"scroll",
    overflowX:"hidden",
    border:"1px solid silver",
    borderRadius:"8px"
  },
  formContainer:{
    padding:".8rem",
  },
  requestValue:{
    position:"relative",
    left:".5em",
    fontSize:".9rem"
  },
  leaveResponsefirstText:{
    padding: ".4em",
    left:"1em",
    position:"relative",
    fontWeight:600,
  },
  reasoncontainer:{
    marginTop:".5em",
  },
  firstTextContainer:{
    width:"100%",
  },
  leaveResponseTextContainer:{
    width:"100%",
  },
  footerText:{
    marginBottom:"1rem"
  }
});
