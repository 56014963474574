import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getTeamMembersAPI } from "../../../apis/kra";
import useFetchMemorized from "../../../hooks/useFetchMemorized";
import { getPersistentAuthentication } from "../../../utils/functions";
import KRAEmployeeCard from "../components/KRAEmployeeCard";
import KRALayout from "../components/KRALayout";
import SparkLine from "../components/Sparkline";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { getNextQuarter } from "../../../utils/getNextQuarter";
import { grey } from "@material-ui/core/colors";
import { fillKraGraphData } from "../../../utils/fillKraGraphData";
import { Link } from "react-router-dom";

interface ITeamMembers {
  id: number;
  empName: string;
  overallScore: number;
  graphData: number[];
  graphLabels: string[];
}

export interface IUserData {
  isPolicyAccepted: boolean;
  role: string;
  token: string;
  userId: string;
  username: string;
}

const TeamLeadView = () => {
  const [teamMembers, setTeamMembers] = useState<ITeamMembers[]>([]);
  const user = getPersistentAuthentication();
  const { start, end } = getNextQuarter();

  const { isLoading, refetch } = useFetchMemorized({
    queryFn: () => getTeamMembersAPI(),
    onSuccess(data) {
      setTeamMembers(data.data);
    },
    onError(error) {
      console.error(error);
    },
  });

  //
  const leadId: IUserData = JSON.parse(sessionStorage.tvcpsess);

  if (isLoading) {
    return (
      <KRALayout>
        <Box
          style={{
            height: "100%",
            width: "100%",
            display: "grid",
            placeItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      </KRALayout>
    );
  }

  return (
    <KRALayout>
      <Box
        sx={
          {
            // mb: 1,
          }
        }
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Typography variant="h5" style={{ flex: 1 }}>
            Howdy,{""}
            <Typography
              component="span"
              variant="h5"
              style={{ fontWeight: "bold" }}
            >
              {user.username}
            </Typography>
          </Typography>
          <Link
            to={`/app/kra/history/${leadId.userId}`}
            style={{
              marginRight: "1.5em",
              display: "grid",
              placeItems: "center",
              textDecoration: "none",
            }}
          >
            <Button
              variant="outlined"
              size="large"
              style={{
                background: "#140047",
                color: "white",
              }}
            >
              Personal KRA
            </Button>
          </Link>
          <Typography variant="h6">{`Your Team Members (${teamMembers.length})`}</Typography>
        </Box>
        <Divider style={{ marginBlock: "8px" }} />
      </Box>

      <Grid container spacing={4}>
        {teamMembers.map((curElem) => (
          <Grid item xs={6} key={curElem.id}>
            <KRAEmployeeCard
              empName={curElem.empName}
              overallScore={curElem.overallScore ?? 0}
              empId={curElem.id}
              maxHeight={200}
              renderSparkLine={() => {
                const { data, label } = fillKraGraphData(
                  curElem.graphData,
                  curElem.graphLabels
                );

                if (curElem.graphData.length) {
                  return (
                    <SparkLine
                      data={data}
                      labels={label}
                      color="#140047"
                      minHeight="180px"
                      minWidth="360px"
                      displayY={false}
                    />
                  );
                } else {
                  return (
                    <div
                      style={{
                        height: 180,
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      <div>
                        <Typography style={{ textAlign: "center" }}>
                          No KRA available!
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <InfoOutlinedIcon style={{ marginInline: "4px" }} />
                          <Typography variant="subtitle1">
                            Next KRA can be created from{" "}
                            <Typography
                              component="span"
                              style={{ fontWeight: "bold", color: grey[700] }}
                            >
                              {moment(start).format("Do MMM, YYYY")}
                            </Typography>
                          </Typography>
                        </Box>
                      </div>
                    </div>
                  );
                }
              }}
            />
          </Grid>
        ))}
      </Grid>
    </KRALayout>
  );
};

export default TeamLeadView;
