import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        // height: '100%',
        // overflowY: "scroll",
        padding: '.4em',
        justifyContent: "center",

    },
    holidayCard: {
        boxSizing: 'border-box',
        display: 'flex',
        borderRadius: '4px',
        width: '100%',
        alignItems: 'center',
        justifyContent: "center",
        padding: '0 .5em',
        marginBottom: '.4em',
        "&:nth-child(even)": {
            background: "rgba(189, 189, 189, .4)",
        },
    },
    dateBox: {
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        width: '10%',
        background: '#140047',
        color: 'white',
        padding: '.6em',
    },
    day: {
        paddingLeft: '2em',
        flex: .8,
        fontSize: '.8em',
        fontWeight: 500,
        color: "#140047"
    },
    occassionName: {
        flex: 1,
        paddingLeft: '2em',
        color: "#140047",
        fontSize: '.8em',
        fontWeight: 600,
    }
}));