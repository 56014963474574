import axios from "./axios.global";

export const createUser = async (payload: any) => {
  try {
    return await axios.post(`/user`, payload);
  } catch (err: any) {
    return err;
  }
};

export const getUsers = async () => {
  try {
    return await axios.get(`/employees`,{
      params :{
        include: 'basicDetails.fullName,isPolicyAccepted,leaveBalance,policyAcceptedDate',
        limit: 100
      }
    });
  } catch (err: any) {
    return err;
  }
};

export const assignedUserLeaves = async (payload: any) => {
  try {
    return await axios.get(`/assignees/leaverecord`, payload);
  } catch (err: any) {
    return err;
  }
};

export const getCurrentUser = async () => {
  try {
    return await axios.get(`/user/profile`);
  } catch (err: any) {
    return err;
  }
};

export const inviteUser = async (id: string) => {
  try {
    return await axios.put(`/user/invite/${id}`);
  } catch (err: any) {
    return err;
  }
};

export const editUser = async (payload: object, id: string) => {
  try {
    return await axios.put(`/user/${id}`, payload);
  } catch (err: any) {
    return err;
  }
};

export const deleteAttachment = async (fileName: string, id: string) => {
  try {
    return await axios.delete(`/${id}/${fileName}`);
  } catch (err: any) {
    return err;
  }
};

export const setEmployeeStatus = async (id: string) => {
  try {
    return await axios.put(`/empstatusupdate/${id}`);
  } catch (error: any) {
    return error;
  }
};
