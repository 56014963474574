import React, { useState, useEffect, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import { useStyles } from "./style";
import ProjectForm from "../../components/Form/ProjectForm";
import { useLocation } from "react-router-dom";
import {
  projectDetails,
  updateProjectdetails,
  projectManagerDetails,
} from "../../../../apis/projects";
import { Toast } from "../../ProjectsPage";
import moment from "moment";
import ProjectTabs from "../../components/ProjectTabs";
import {
  Location,
  ProjectFormData,
  Promise,
  developers,
  Resource,
} from "./types";

interface Iprops {}

const ProjectDetailPage: React.FC<Iprops> = () => {
  const classes = useStyles();
  const { state }: Location = useLocation();
  const _id = state._id;
  const [isEditable, setIsEditable] = useState(false);
  const [selectedProjectManager, setSelectedProjectManager] = useState<{
    id: string;
    email: string;
    name: string;
  }>({
    id: "",
    email: "",
    name: "",
  });
  const showToast = useContext(Toast);

  const [projectFormData, setProjectFormData] = useState<ProjectFormData>({
    projectName: "",
    clientCompanyName: "",
    clientContact: 0,
    clientEmail: "",
    startDate: "",
    projectType: "",
    orientation: "",
  });
  const [projectManagers, setProjectManagers] = useState<
    { id: string; email: string; name: string }[]
  >([]);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      let datafetched: Promise = await projectDetails(_id); //to get project details with specific id
      setProjectFormData({
        projectName: datafetched.data.projectName,
        clientCompanyName: datafetched.data.client.clientCompany ?? "N/A",
        clientContact: datafetched.data.client?.clientContact ?? "N/A",
        clientEmail: datafetched.data.client.clientEmail ?? "N/A",
        startDate: moment(datafetched.data?.createdAt).format("L") ?? "",
        projectType: datafetched.data.model,
        orientation: datafetched.data.orientation ?? "N/A",
      });
      const pm = datafetched.data?.projectManager[0];
      setSelectedProjectManager({
        id: pm?._id ?? "N/A",
        email: pm?.basicDetails.email ?? "N/A",
        name: pm?.basicDetails.fullName ?? "N/A",
      });

      let managersDetails = await projectManagerDetails(); //to get project managers
      let tempItems: { id: string; email: string; name: string }[] = [];
      managersDetails?.data?.forEach(({ id, email, name }: any) => {
        let tempObjUser = {
          id: id ?? "N/A",
          email: email ?? "N/A",
          name: name ?? "N/A",
        };
        tempItems.push(tempObjUser);
      });
      setProjectManagers(tempItems);
    } catch (error: any) {
      showToast.error(error.response.data.message);
    }
  }

  const editHandler = () => {
    setIsEditable(!isEditable);
  };
  const discardHandler = () => {
    setIsEditable(false);
    fetchData();
    // setSelectedProjectManager(selectedProjectManager);
  };
  const saveHandler = async () => {
    try {
      await updateProjectdetails(_id, {
        projectManagers: [selectedProjectManager],
      });
      showToast.success("Updated Successfully");
    } catch (err: any) {
      showToast.error(err.response.data.message);
    }
    setIsEditable(false);
  };

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <ProjectForm
          projectFormData={projectFormData}
          setProjectFormData={setProjectFormData}
          editHandler={editHandler}
          isEditable={isEditable}
          discardHandler={discardHandler}
          saveHandler={saveHandler}
          projectManagers={projectManagers}
          selectedProjectManager={selectedProjectManager}
          setSelectedProjectManager={setSelectedProjectManager}
        />
      </Grid>
      <Grid item xs={12} className={classes.tab}>
        <ProjectTabs/>
      </Grid>
    </Grid>
  );
};

export default ProjectDetailPage;
