import axios from "axios";
import { useCallback, useState } from "react";
import { useRecoilState } from "recoil";
import { saveDocument } from "../../apis/onboarding";
import { isUploadsViewEnabled } from "../../recoil/atoms";
import { documentDownloadAtom } from "../../recoil/downloadAtoms";
import { Toast } from "../../store/uploadsStore";

export type File = {
  name: string;
  value: Blob | string;
};
export const useIdentityProofs = () => {
  const [idProofs, setIdentityProof] = useState<File[]>([]);
  const [idProofProgress, setIdProofProgress] = useState<number>(0);

  const [, setIsViewEnabled] = useRecoilState(isUploadsViewEnabled);
  const [, setRewriteFileDownload] = useRecoilState(documentDownloadAtom);

  const mainUrl = process.env.REACT_APP_BACKEND_URL;

  const handlePostIdentityProof = useCallback(
    async (showToast: Toast, uploadDocsEndpointPatch: string) => {
      const formData = new FormData();

      idProofs.forEach((file) => {
        formData.append(file.name, file.value);
      });

      const url = mainUrl + uploadDocsEndpointPatch;
      const response: any = await saveDocument(url, formData);
      if (response.status === 200) {
        showToast.success("Documents Saved!");
        setIsViewEnabled((prev) => ({
          ...prev,
          identity: true,
        }));
        setRewriteFileDownload((prev) => ({
          ...prev,
          aadhar: false,
          pan: false,
        }));
      } else {
        showToast.error("Something Went Wrong!");
      }
    },
    [idProofs]
  );

  return {
    idProofs,
    setIdentityProof,
    handlePostIdentityProof,
    idProofProgress,
    setIdProofProgress,
  };
};
