import React, { useState } from "react";
import { Grid, Paper, Box, Typography, Button } from "@material-ui/core";
import { useStyles } from "../AccordionComponent/styles";
import {
  Formik,
  FormikHelpers,
  FormikProps,
  Form,
  Field,
  useField,
} from "formik";
import * as yup from "yup";
import { FormTextField } from "../FormtextField";
import FormValues from "../../models/employeeInformationType";
import { useLocation } from "react-router-dom";
import { boolean } from "yup/lib/locale";
import SelectField from "../select-field";
import RadioButtonsGroup from "../radio-button";
import DatePickerField from "../date-picker-field";
import TextFieldComponent from "../TextFieldComponent";
import moment from "moment";
import SelectViewValueFinder from "../../utils/SelectViewValueFinder";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import DateFnsUtils from "@date-io/date-fns";
import { Role } from "../../recoil/atoms";
import Moment from "react-moment";
import { RoleList } from "../AccordionComponent/EmployeeInformationEdit";

type Props = {
  errorParamKey?: string;
  errorParam?: any;
  label: string;
  valueParam: string | number | boolean | Role[];
  name: string;
  inputType: string;
  data?: any;
  formikProps?: FormikProps<any>;
  isEditable: boolean;
  xsProps?: any;
  valueTypeProps?: string;
  startTextvalue?: string;
  endTextValue?: string;
  dateFormat?: string;
  dateView?: string[];
  momentDateFormat?: string;
  booleanDisplayvalue?: string[]; //text to display if the value is boolean. E.g for true=Yes and false=No
  dependentFieldChanges?: {
    fieldName: string;
    functionChange: (arg: string) => number | string;
  };
  // roleData?: RoleList[];
};

const KeyValueWrapper: React.FC<Props> = ({
  errorParam,
  errorParamKey,
  label,
  valueParam,
  inputType,
  name,
  data = [],
  formikProps,
  isEditable,
  xsProps = 12,
  valueTypeProps = "text",
  startTextvalue = "",
  endTextValue = "",
  dateFormat = "dd/MM/yy",
  dateView = ["year", "month", "date"],
  momentDateFormat = "DD/MM/YYYY",
  booleanDisplayvalue = ["true", "false"], //text to display if the value is boolean. E.g for true=Yes and false=No
  dependentFieldChanges,
  // roleData = [],
}) => {
  //
  const classes = useStyles();

  return (
    <Grid item xs={xsProps} style={{ width: "100%" }}>
      <Box sx={{ marginBottom: 25, width: "80%" }}>
        {label === "hiddenDiv" ? (
          <div style={{ visibility: "hidden" }}>hidden</div>
        ) : (
          <Typography className={classes.keyLightTypographyStyle}>
            {label}
          </Typography>
        )}

        {isEditable ? (
          <>
            {inputType === "selectField" && (
              <SelectField
                name={name}
                label={label}
                valueProps={valueParam}
                data={data}
                fullWidth={true}
              />
            )}
            {inputType === "radioButton" && (
              <RadioButtonsGroup
                name={name}
                label={label}
                data={data}
                fullWidth={true}
              />
            )}
            {inputType === "textField" && (
              <TextFieldComponent
                valueTypeProps={valueTypeProps}
                defaultProps={{
                  name: name,
                  // label: "Name",
                  fullWidth: true,
                  // placeholder: "Enter name",
                }}
              />
            )}
            {inputType === "datePicker" && (
              <DatePickerField
                name={name}
                // label={Props.label}
                format={dateFormat}
                // inputFormat={"dd/MM/yy"}
                views={dateView}
                // formatDate={(date:any) => moment(new Date()).format("MM-DD-YYYY")}
                maxDate={new Date()}
                fullWidth={true}
                dependentFieldChanges={dependentFieldChanges}
                formikProps={formikProps}
              />
            )}
            {inputType === "Autocomplete" && (
              <Autocomplete
                size="small"
                key={formikProps?.values.role.length}
                options={data}
                // options={roleData}
                multiple
                value={formikProps?.values.role}
                getOptionLabel={(option: any) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Role"
                  />
                )}
                onChange={(e, value) => {
                  formikProps?.setFieldValue("role", value);
                }}
              />
            )}
            {inputType === "onBoardingDatePicker" && (
              <>
                <TextField
                  variant="standard"
                  name={name}
                  type="date"
                  value={moment(new Date(valueParam as string)).format(
                    "YYYY-MM-DD"
                  )}
                  // className={classes.textField}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) =>
                    formikProps?.setFieldValue(
                      name,
                      new Date(e.target.value).toISOString()
                    )
                  }
                  onKeyDown={(e) => e.preventDefault()}
                  onKeyUp={(e) => e.preventDefault()}
                  InputProps={{
                    inputProps: {
                      max: moment(new Date().toISOString()).format(
                        "YYYY-MM-DD"
                      ),
                    },
                  }}
                  // helperText={
                  //   errorParam ? errorParam[errorParamKey as string] : ""
                  // }
                />
                <p style={{ color: "red", fontSize: ".7em" }}>
                  {errorParam ? errorParam[errorParamKey as string] : ""}
                </p>
              </>
            )}
          </>
        ) : (
          <>
            {inputType === "datePicker" && (
              <Typography className={classes.valueStrongStye}>
                {moment(valueParam as string).format(momentDateFormat) ===
                "Invalid date"
                  ? "NA"
                  : // : moment(valueParam as string).format(momentDateFormat) === ""
                    // ? "NA"
                    startTextvalue +
                    moment(valueParam as string).format(momentDateFormat) +
                    endTextValue}
              </Typography>
            )}
            {inputType === "textField" && (
              <Typography className={classes.valueStrongStye}>
                {valueParam?.toString() === "0"
                  ? "NA"
                  : valueParam?.toString() === ""
                  ? "NA"
                  : startTextvalue + valueParam + endTextValue}
              </Typography>
            )}
            {inputType === "radioButton" && (
              <Typography className={classes.valueStrongStye}>
                {valueParam === true ? data[0]?.label : data[1]?.label}
              </Typography>
            )}
            {inputType === "selectField" && (
              <Typography className={classes.valueStrongStye}>
                {valueParam.toString() === "0"
                  ? "NA"
                  : valueParam.toString() === ""
                  ? "NA"
                  : valueParam.toString() === "Not Selected"
                  ? "NA"
                  : valueParam === "true"
                  ? booleanDisplayvalue[0]
                  : valueParam === "false"
                  ? booleanDisplayvalue[1]
                  : startTextvalue +
                    SelectViewValueFinder(data, valueParam).toString() +
                    endTextValue}
              </Typography>
            )}
            {inputType === "Autocomplete" && (
              <Autocomplete
                multiple
                size="small"
                options={data}
                // options={roleData}
                key={formikProps?.values.role.length}
                value={formikProps?.values.role}
                getOptionLabel={(option: any) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Role"
                  />
                )}
                disabled
              />
            )}
            {inputType === "onBoardingDatePicker" && (
              <Typography className={classes.valueStrongStye}>
                {moment(new Date(valueParam as string)).format("DD/MM/YYYY")}
              </Typography>
            )}
          </>
        )}
      </Box>
    </Grid>
  );
};

export default KeyValueWrapper;
