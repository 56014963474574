import {
  createStyles,
  makeStyles,
  TableRow,
  Theme,
  withStyles,
} from "@material-ui/core";
import { theme } from "../../theme/theme";

export const useStyles = makeStyles({
  header: {
    backgroundColor: "#14213D",
    color: "#fff",
    borderRight: "1px solid #d6d6d6",
    textAlign: "left",
    width: 239,
  },
  headerMigrate: {
    backgroundColor: "#14213D",
    paddingLeft: "1em",
    color: "#fff",
    textAlign: "left",
    width: 239,
  },
  headerStatus: {
    backgroundColor: "#14213D",
    borderRight: "1px solid #fff",
    color: "#fff",
    textAlign: "left",
    width: "100px",
  },
  headerFilter: {
    backgroundColor: "#14213D",
    color: "#fff",
    textAlign: "left",
  },
  tableRow: {
    "&:hover": {
      boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.15)",
      zIndex: 1,
      position: "relative",
    },

    "&:nth-of-type(even)": {
      backgroundColor: "#f8f8f8",
    },

    "&:nth-of-type(odd)": {
      "&:hover": {
        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.15)",
      },
    },
  },

  colOne: {
    justify: "space-between",
  },
  statusPending: {
    margin: "0.5em",
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    justifyContent: "center",
    padding: "0.7em",
    color: "#E08530",
    backgroundColor: "#DC9E6433",
    borderRadius: "17px",
    cursor: "pointer",
  },

  statusComplete: {
    margin: "0.5em",
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    justifyContent: "center",
    padding: "0.7em",
    color: "#169E0A",
    backgroundColor: "#6FCB6733",
    borderRadius: "17px",
    cursor: "pointer",
  },
  statusIcon: {
    paddingLeft: "5%",
    borderLeft: "1px solid #fff",
  },

  deleteStyles: {
    cursor: "pointer",
    paddingLeft: "30%",
    width: "1em",
  },
  filterSpan: {
    padding: "2em",
  },
  filterIcon: {
    cursor: "pointer",
  },

  popover: {
    pointerEvents: "none",
  },
  popoverPaper: {
    padding: theme.spacing(1),
    paddingLeft: "2%",
    paddingRight: "2%",
    marginTop: "-2%",
    backgroundColor: "#757A7A",
    color: "#fff",
  },
  paperFilter: {
    zIndex: 1,
    padding: theme.spacing(1),
    overflowX: "unset",
    overflowY: "unset",
    marginTop: "2%",
    backgroundColor: "#E2E2E2",
    width: 110,
    "&::before": {
      content: '""',
      position: "absolute",
      bottom: 0,
      right: 10,
      width: 10,
      height: 10,
      backgroundColor: "#E2E2E2",
      boxShadow: theme.shadows[1],
      transform: "translate(-50%, -560%) rotate(315deg)",
      clipPath:
        "polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))",
    },
  },

  convertToEmployeeButton: {
    border: "2px solid rgba(20, 0, 71, 0.65)",
    borderRadius: "17px",
    color: "#000",
    transition: "all 0.3s",
    "&:hover": {
      backgroundColor: "#140047",
      color: "#fff",
    },
  },

  migratedToEmployeeButton: {
    border: "2px solid rgba(20, 0, 71, 0.65)",
    borderRadius: "17px",
    backgroundColor: "#6FCB6733",
  },
});

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);
