import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./styles";

interface LeaveCountsProps {
  leaveType: string;
  leaveTotal: number;
  leaveApplied: number;
}

const LeaveMap = [
  {
    type: "priviledge_Leave",
    upperCase: "PRIVILEDGE lEAVE",
  },
  {
    type: "casual_leave",
    upperCase: "CASUAL LEAVE",
  },
  {
    type: "sick_leave",
    upperCase: "SICK LEAVE",
  },
];

const LeaveCounts = ({
  leaveType,
  leaveTotal,
  leaveApplied,
}: LeaveCountsProps) => {
  const classes = useStyles();

  const getRootClass = () => {
    if (leaveType === "priviledge_Leave") {
      return classes.leaveCountRootPL;
    } else if (leaveType === "casual_leave") {
      return classes.leaveCountRootCL;
    } else {
      return classes.leaveCountRootSL;
    }
  };

  const getTextClass = () => {
    if (leaveType === "priviledge_Leave") {
      return classes.leaveCountTextPL;
    } else if (leaveType === "casual_leave") {
      return classes.leaveCountTextCL;
    } else {
      return classes.leaveCountTextSL;
    }
  };

  const getValueClass = () => {
    if (leaveType === "priviledge_Leave") {
      return classes.leaveCountValuesPL;
    } else if (leaveType === "casual_leave") {
      return classes.leaveCountValuesCL;
    } else {
      return classes.leaveCountValuesSL;
    }
  };

  const rootClass = getRootClass();
  const textClass = getTextClass();
  const valueClass = getValueClass();

  return (
    <Grid item className={rootClass}>
      <Typography className={textClass}>
        {LeaveMap.map((leaveName: { type: string; upperCase: string }) => {
          return leaveName.type === leaveType && leaveName.upperCase;
        })}
      </Typography>
      <Typography className={valueClass}>
        {leaveApplied}/{leaveTotal}
      </Typography>
    </Grid>
  );
};

export default LeaveCounts;
