import { makeStyles, Theme } from "@material-ui/core/styles";
import { props } from "./TechnologyCard";

export const useStyles = makeStyles<Theme, props>((theme: Theme) => ({
    paper: {
        "&.MuiPaper-root": {
            padding: "0.6rem",
            minWidth: "15rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "stretch",
            columnGap: "1.5rem",
            borderRadius: "0.8rem",
            flex: "1",
            cursor: "pointer",
            transition: "all 0.1s ease-in",
        },
        "&:hover": {
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        },
    },
    avatar: (p)=> ({
        "&.MuiAvatar-root": {
            backgroundColor: p.styleProps.avatarBgColor,
            padding: "0.6rem",
            borderRadius: "0.8rem",
            "& .MuiAvatar-img": {
                width: "2rem",
                height: "2rem",
            },
        },
        pointerEvents: "none",
    }),
    typo: {
        textTransform: "uppercase",
        pointerEvents: "none",
    },
}));
