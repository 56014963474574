import { Theme, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    headText: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        columnGap: "6rem",
        width: "100%",
    },
    headTextLabels: {
        color: theme.palette.primary.main,
        fontWeight: 500,
        flexShrink: 0
    },
    headTextValues: {
        color: "#9AA6AC",
        fontWeight: 500,
    },
    mb: {
        marginBottom: "1rem",
    },
    editorSec: {
        display: "grid",
        gridTemplateColumns: "2.5fr 1fr",
        gap: "3rem",
        height: "47rem",
    },
    actionBtns: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        columnGap: "1rem",
        width: "100%",
        marginTop: "2.2rem",
    },
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 5,
        "&$checked": {
            transform: "translateX(16px)",
            color: theme.palette.common.white,
            "& + $track": {
                backgroundColor: theme.palette.primary.main,
                opacity: 1,
                border: "none",
            },
        },
        "&$focusVisible $thumb": {
            color: theme.palette.primary.main,
            border: "6px solid #fff",
        },
    },
    thumb: {
        width: 16,
        height: 16,
    },
    track: {
        borderRadius: "1.5rem",
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        //transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    note:{
        marginBottom:"1rem"
    }
}));
