import { postData } from "../pages/knowledgeHubPage/KnowledgeHubPage";
import axios from "./axios.global";

const BASE_URL = "knowledge-hub";

export const createPost = async (payload: postData) => {
  return await axios.post(`${BASE_URL}`, payload);
};

export const updatePost = async (postId: string, postData: postData) => {
  try {
    const res = await axios.patch(`${BASE_URL}/${postId}`, postData);
    return res;
  } catch (error) {}
};

export const searchPost = async (query: {
  title: string;
  page: number;
  category: string;
}) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}?title=${query.title}&page=${query.page}&category=${query.category}`
    );
    return data;
  } catch (error) {}
};

export const uploadImage = async (formData: FormData, id: string) => {
  try {
    const { data } = await axios.post(
      `${BASE_URL}/${id}/image-upload`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return data;
  } catch (error) {}
};

export const deletePost = async (id: string) => {
  try {
    const { data } = await axios.delete(`${BASE_URL}/delete/${id}`);
    return data;
  } catch (error) {}
};

export const getPost = async (id:string)=>{
return await axios.get(`${BASE_URL}/post/${id}`);
}