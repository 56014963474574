import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  getBankInfo,
  getEducationInfo,
  getEmployeeInfo,
  getFamNLangInfo,
  getRecruiteeInfo,
} from "../../apis/onboarding";
import BasicLayout from "../../components/BasicLayout";
import { setBankDetails } from "../../components/Forms/OnBoardingForm/RecruiteeDetails/BankDetailsForm/utils";
import { setDetailsData } from "../../components/Forms/OnBoardingForm/RecruiteeDetails/BasicDetails/utils";
import { setEducationData } from "../../components/Forms/OnBoardingForm/RecruiteeDetails/EductionalQualifications/utils";
import { setEmployeeInformation } from "../../components/Forms/OnBoardingForm/RecruiteeDetails/EmployeeInformation/utils";
import { setFamilyAndLanguageData } from "../../components/Forms/OnBoardingForm/RecruiteeDetails/FamilyAndLanguage/getData";
import {
  bankDetailsAtom,
  educationFormAtom,
  employeeInformation,
  onboardingIdAtom,
  profileAvatarAtom,
  recruiteeBasicDetails,
} from "../../recoil/atoms";
import { familyAndLangageAtom } from "../../recoil/familyAndLanguageAtom";
import { experienceAtom } from "../../recoil/workExperienceAtom";
import { useBasicNav } from "../../utils/useBasicNav";
import NewRecruiteeDetailsHeader from "../NewRecruiteeDetailsHeader";
import StepperConditional from "./StepperConditional";
import { useStyles } from "./styles";

interface props {
  loadPrimary: (event: any, state: any) => void;
  showToast: any;
}

const NewRecruitDetails: React.FC<props> = ({ loadPrimary, showToast }) => {
  loadPrimary(false, true);
  useBasicNav("onboarding");
  const classes = useStyles();
  const data: any = useLocation();
  const details = data?.state?.state;

  const [basicDeta, setBasicDetails] = useRecoilState(recruiteeBasicDetails);
  const [empInfo, setEmployeeInfo] = useRecoilState(employeeInformation);
  const [avatarImage, setAvatarImage] = useRecoilState(profileAvatarAtom);
  const [educationForm, setEducationForm] = useRecoilState(educationFormAtom);
  const [experienceInfo, setExperienceInfo] = useRecoilState(experienceAtom);
  const [famLanguage, setFamLanguage] = useRecoilState(familyAndLangageAtom);
  const [bankDetailsForm, setBankDetailsForm] = useRecoilState(bankDetailsAtom);
  const [onboardingIds, setOnboardingIds] = useRecoilState(onboardingIdAtom);

  const getUserData = async () => {
    const getBasicDetails = await getRecruiteeInfo(details._id);
    setDetailsData(
      setBasicDetails,
      getBasicDetails,
      setAvatarImage,
      basicDeta,
      setOnboardingIds
    );

    const getEduDetails = await getEducationInfo(details._id);
    if (getEduDetails.class10) {
      setEducationData({ getEduDetails, setEducationForm });
    }

    // const getWorkExpDetails = await getWorkExpInfo(details._id);
    // setWorkExpDetails({
    //   getWorkExpDetails,
    //   experienceInfo,
    //   setExperienceInfo,
    // });

    const getFamNLangDetails = await getFamNLangInfo(details._id);
    setFamilyAndLanguageData({ getFamNLangDetails, setFamLanguage });

    const getBankDetails = await getBankInfo(details._id);
    setBankDetails({ getBankDetails, setBankDetailsForm });

    const getEmpInfoDetails = await getEmployeeInfo(details._id);
    setEmployeeInformation({ getEmpInfoDetails, setEmployeeInfo });
  };

  useEffect(() => {
    getUserData();
  }, []);

  const [stepNumber, setStepNumber] = useState<number>(0);
  const [isFinish, setIsFinish] = useState<boolean>(false);
  // const {} = useSetRecoilState(profileAvatarAtom)

  useEffect(() => {
    return () => {
      setAvatarImage({ profileImage: "" });
    };
  }, []);

  return (
    <BasicLayout>
      <div className={classes.root}>
        <NewRecruiteeDetailsHeader
          showToast={showToast}
          stepNumber={stepNumber}
          isFinish={isFinish}
          recruiteeDetails={details}
        />

        <StepperConditional
          stepNumber={stepNumber}
          setStepNumber={setStepNumber}
          setIsFinish={setIsFinish}
          showToast={showToast}
          recruiteeDetails={details}
          loadPrimary={loadPrimary}
        />
      </div>
    </BasicLayout>
  );
};

export default NewRecruitDetails;
