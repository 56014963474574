import { Paper } from "@material-ui/core";
import React, { ReactNode } from "react";

interface IChildren {
  children: JSX.Element | JSX.Element[] | ReactNode;
}

const KRALayout = ({ children }: IChildren) => {
  return (
    <Paper
      elevation={2}
      style={{ padding: "2em", borderRadius: "10px", minHeight: "90vh" }}
    >
      {children}
    </Paper>
  );
};

export default KRALayout;
