import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import ColorDiv from "./colorDiv";
import AssignDiv from "./assignDiv";
import { useStyles } from "./styles";
import { Grid } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { useHistory } from "react-router-dom";
import { permissions } from "../../recoil/atoms";
import SwitchField from "../../components/SwitchField";
import {
  Formik,
  FormikHelpers,
  FormikProps,
  Form,
  Field,
  useField,
} from "formik";
import EmployeeStatus from "./EmployeeStatus";
import { Rowing } from "@material-ui/icons";
import { CheckAllAdmins } from "../../utils/CheckPermissions";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { employeePageTableAtoms } from "../../recoil/atoms";
import SelectViewValueFinder from "../../utils/SelectViewValueFinder";
import { getDesignation } from "../../apis/designations_departments";
import { getLeaders } from "../../apis/employees";
import SnackbarComponent from "../../components/SnackbarComponent";
import { employeeSensitiveDataEditPermission } from "../../utils/functions";
import { usePermissionObjectFor } from "../../hooks/permission/usePermissionObjectFor";

interface IProps {
  items?: any;
  totalNumberOfPagesProps: number;
  headCells?: {
    label: string;
    key: string;
  }[];
  permission: boolean;
}

const SimpleTableDis: React.FC<IProps> = ({
  items,
  totalNumberOfPagesProps = 1,
  headCells,
  permission,
}) => {
  let valFlag = CheckAllAdmins();

  const classes = useStyles();
  const history = useHistory();

  const [tableData, setTableData] = useState<any>([]);
  const [rowsTable, setRowsTable] = useState<any>([]);
  const [renderflag, setRenderFlag] = useState(false);
  const [designationSelectOptions, setDesignationSelectOptions] =
    useState<object[]>();
  const [leadersSelectOptions, setLeadersSelectOptions] = useState<object[]>();

  //state for snackbar
  const [snackbarState, setSnackbarState] = useState({
    openBooleanProp: false,
    messageProp: "dummy message state",
    statusProp: "info",
  });

  const SensitiveDatapermission = usePermissionObjectFor("employees");
  const hasCreateAndEditAccess = SensitiveDatapermission.required([
    "create",
    "upDate",
  ]);
  const hasAllAccess = SensitiveDatapermission.required([
    "read",
    "create",
    "upDate",
    "del",
  ]);

  //fetch designation data
  const getAllDesignation = async () => {
    try {
      let response = await getDesignation();
      if (response?.status === 200) {
        const res = response?.data;
        //
        let fetchedDesignations: object[] = [];
        res.map((key: any, index: any) => {
          fetchedDesignations.push({
            value: key?._id,
            label: key?.designationName,
          });
        });

        setDesignationSelectOptions(fetchedDesignations);
      }
    } catch (err: any) {
      //show toast
      setSnackbarState({
        openBooleanProp: true,
        messageProp: "Error : " + err,
        statusProp: "error",
      });
    }
  };

  const getAllLeaders = async () => {
    try {
      let response = await getLeaders();
      const res = response?.data;
      //
      let fetchedLeaders: object[] = [];

      if (res.length !== 0) {
        res.map((key: any, index: any) => {
          let checkKeys =
            key.hasOwnProperty("id") & key.hasOwnProperty("leadName");

          if (checkKeys) {
            fetchedLeaders.push({
              value: key?.id,
              label: key?.leadName,
            });
          }
        });
      }
      setLeadersSelectOptions(fetchedLeaders);
    } catch (err: any) {
      //show toast
      setSnackbarState({
        openBooleanProp: true,
        messageProp: "Error : " + err,
        statusProp: "error",
      });
    }
  };

  //recoil code
  const setEmployeePageTableState = useSetRecoilState(employeePageTableAtoms); //to update recoil state
  const employeePageTableState = useRecoilValue(employeePageTableAtoms); // to read recoil state

  const handleRouting = (item: any) => {
    //
    history.push({
      pathname: `employees/details`,
      state: {
        data: item,
      },
    });
  };

  const createRows = (data: any) => {
    const rows: any = [];
    data &&
      data?.map((item: any, key: any) => {
        rows.push({
          id: key,
          userId: item?._id,
          isInviteSent: item?.isInviteSent,
          isVerified: item?.isVerified,
          color: (
            <ColorDiv
              color={item?.employeeInformation?.isActive ? "#14213d" : "red"}
              name={item?.basicDetails?.fullName}
            />
          ),
          phn_no: item?.basicDetails?.phoneNumber,
          email: item?.basicDetails?.email,
          designation: SelectViewValueFinder(
            designationSelectOptions,
            item?.employeeInformation?.designation
          ).toString(),
          employeeInformation: item?.employeeInformation,
          assigned_to: (
            <AssignDiv
              name={SelectViewValueFinder(
                leadersSelectOptions,
                item?.employeeInformation?.assignedTo
              ).toString()}
              buttonOption={
                item?.isInviteSent
                  ? item?.isVerified
                    ? "Successful"
                    : "Pending"
                  : "Send invite"
              }
              permission={permission}
              id={item?._id}
              assignedId={item.employeeInformation.assignedTo}
            ></AssignDiv>
          ),
        });
      });
    //
    setRowsTable(rows);
    //make table data
    setTableData(rows);
  };

  useEffect(() => {
    getAllDesignation();
    getAllLeaders();
  }, []);

  useEffect(() => {
    // setPaginationTableData();

    createRows(items);
    setRenderFlag(valFlag);
  }, [items, designationSelectOptions, leadersSelectOptions]);

  const setUpPagination = (event?: any, value?: any) => {
    //set the current page number value in recoil
    setEmployeePageTableState({
      tableData: employeePageTableState?.tableData,
      search: employeePageTableState?.search,
      filterBy: employeePageTableState?.filterBy,
      currentPage: value,
      pages: employeePageTableState?.pages,
    });
  };

  return (
    <Grid container>
      <SnackbarComponent snackbarStateProp={snackbarState} />
      <Grid item xs={12} className={classes.tableContain}>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead className={classes.tableHeaderWrapper}>
              <TableRow>
                <TableCell
                  className={classes.tableHeader}
                  align="center"
                  width="20%"
                >
                  <Box className={classes.tableHeaderInside}>Name </Box>
                </TableCell>
                <TableCell
                  align="left"
                  className={classes.tableHeader}
                  width="15%"
                >
                  <Box className={classes.tableHeaderInside}>Email Address</Box>
                </TableCell>
                <TableCell
                  align="left"
                  className={classes.tableHeader}
                  width="15%"
                >
                  <Box className={classes.tableHeaderInside}>Phone Number </Box>
                </TableCell>
                <TableCell
                  align="left"
                  className={classes.tableHeader}
                  width={"20%"}
                >
                  <Box className={classes.tableHeaderInside}>Designation </Box>
                </TableCell>
                {hasCreateAndEditAccess && (
                  <TableCell
                    align="left"
                    className={classes.tableHeader}
                    width={"10%"}
                  >
                    <Box className={classes.tableHeaderInside}>Emp Status</Box>
                  </TableCell>
                )}
                <TableCell
                  align="left"
                  className={classes.tableHeader}
                  width={permission ? "35%" : "20%"}
                >
                  Assigned To{" "}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData?.map((row: any, key: any) => (
                <TableRow key={key} className={classes.tableRowsParent}>
                  <TableCell className={classes.tableRows} align="center">
                    {" "}
                    {row.color}{" "}
                  </TableCell>
                  <TableCell className={classes.tableRows} align="left">
                    {row.email}
                  </TableCell>
                  <TableCell className={classes.tableRows} align="left">
                    {row.phn_no}
                  </TableCell>
                  <TableCell className={classes.tableRows} align="left">
                    {row.designation}
                  </TableCell>
                  {hasCreateAndEditAccess && (
                    <TableCell className={classes.tableRows} align="left">
                      <EmployeeStatus
                        valueProp={row.employeeInformation}
                        idProp={row.userId}
                      />
                    </TableCell>
                  )}
                  <TableCell className={classes.tableRows} align="left">
                    {row.assigned_to}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} className={classes.paginate}>
        <Pagination
          count={totalNumberOfPagesProps}
          color="primary"
          page={employeePageTableState?.currentPage}
          onChange={setUpPagination}
        />
      </Grid>
    </Grid>
  );
};
export default SimpleTableDis;
