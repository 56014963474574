import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        padding: "0.8rem 0.2rem 0.8rem 1rem",
        border: "1px solid #DDE2E4",
        borderRadius: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        columnGap: "1rem",
        backgroundColor: "white",
        position: "relative",
    },
    formControl: {
        minWidth: "10rem",
        flexShrink: 0,
        "&.MuiFormControl-root": {
            "& ::after": {
                border: "none",
            },
            "& ::before": {
                border: "none",
            },
            "& :hover::before": {
                border: "none",
            },
        },
    },
    select: {
        "& .MuiSelect-select:focus": {
            backgroundColor: "transparent",
        },
    },
    break: {
        width: "0.1rem",
        height: "1.7rem",
        backgroundColor: "#DDE2E4",
    },
    textField: {
        "&.MuiTextField-root": {
            "& ::after": {
                border: "none",
            },
            "& ::before": {
                border: "none",
            },
            "& :hover::before": {
                border: "none",
            },
        },
    },
    iconButton:{
        "&.MuiIconButton-root":{
            padding: "0.3em",
        }
    },
    selectMenu:{
        "& .MuiMenu-paper":{
            boxShadow: "none",
            border: "1px solid rgba(0,0,0,0.1)",
        },
    },
}));
