import React, { useContext, useState } from "react";
import {
  Grid,
  Button,
  Typography,
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  CircularProgress,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import BackupIcon from "@material-ui/icons/Backup";
import { useStyles } from "../style";
import Tables from "../../../Tables";
import { fileTypes, HeadCell, projectRequirement } from "../../../../types";
import EditIcon from "@material-ui/icons/Edit";
import DescriptionIcon from "@material-ui/icons/Description";
import LinkIcon from "@material-ui/icons/Link";
import moment from "moment";
import { useDropzone } from "react-dropzone";
import {
  addProjectDesignDocs,
  addProjectDesignLinks,
  deleteDesignDocs,
  deleteDesignLink,
} from "../../../../../../apis/projects";
import { Toast } from "../../../../ProjectsPage";
import { useParams } from "react-router";
import DeleteIcon from "@material-ui/icons/Delete";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { usePermissionObjectFor } from "../../../../../../hooks/permission/usePermissionObjectFor";

const MAXFILESIZE = 2097152;
interface Iprops {
  projectRequirement: projectRequirement | undefined;
  fetchRequirements: () => Promise<void>;
}
const ProjectDesign: React.FC<Iprops> = ({
  projectRequirement,
  fetchRequirements,
}) => {
  const classes = useStyles();
  const { id } = useParams<any>();
  const showToast = useContext(Toast);
  const [isLinkUploading, setIsLinkUploading] = useState<boolean>(false);
  const [designLinks, setDesignLinks] = useState<string[]>([]);
  const [isLinkEditable, setIsLinkEditable] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const permission = usePermissionObjectFor("projects");
  const hasCreateAccess = permission.required("upDate");

  const headCells: HeadCell[] = [
    {
      id: "url",
      disablePadding: true,
      label: "Files",
      width: "60%",
    },
    {
      id: "createdAt",
      disablePadding: false,
      label: "Uploaded Date",
      width: "20%",
    },
    {
      id: "addedBy",
      disablePadding: false,
      label: "Uploaded By",
      width: "20%",
    },
  ];

  const editHandler = () => {
    setIsLinkEditable(true);
  };

  const showFiles = (item: fileTypes) => {
    return (
      <div
        className={classes.imageGroup}
        onClick={() => window.open(item.url, "_blank", "noopener,noreferrer")}
      >
        <DescriptionIcon />
        <Typography className={classes.fileName}> {item.name}</Typography>
      </div>
    );
  };

  const saveDesignLinksHandler = async () => {
    setIsLinkUploading(true);
    try {
      await addProjectDesignLinks(id, {
        designLinks: designLinks,
      });
      showToast.success("Successfully saved!");
      setIsLinkUploading(false);
      setIsLinkEditable(false);
      fetchRequirements();
    } catch (err: any) {
      showToast.error(err.response.data.message);
      setIsLinkUploading(false);
    }
  };

  const uploadDesignDocsHandler = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    showToast.success("Uploading File..");
    setIsLoading(true);
    const formData = new FormData();
    let files = e.target.files as FileList;

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > MAXFILESIZE) {
        showToast.error("File Size too large!");
        setIsLoading(false);
        return;
      }
      formData.append("attachment", files[i]);
    }
    try {
      await addProjectDesignDocs(id, formData);
      showToast.success("Successfully Uploaded!");
      setIsLoading(false);
      fetchRequirements();
    } catch (err: any) {
      showToast.error(err.response.data.message);
      setIsLoading(false);
      fetchRequirements();
    }
  };

  const deleteHandler = async (designDocsId: string) => {
    setIsLoading(true);
    try {
      await deleteDesignDocs(id, designDocsId);
      showToast.success("Deleted Successfully!");
      setIsLoading(false);
      fetchRequirements();
    } catch (err: any) {
      showToast.error(err.response.data.message);
      setIsLoading(false);
      fetchRequirements();
    }
  };

  const showWithdeleteButton = (item: fileTypes) => {
    return (
      <div className={classes.withDeleteContainer}>
        <span>{item.addedBy}</span>
        {hasCreateAccess && (
          <IconButton
            className={classes.buttonWithNoPadding}
            onClick={() => deleteHandler(item._id)}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </div>
    );
  };

  const generateTableRow = () => {
    return projectRequirement?.designDocs?.map((item) => ({
      url: showFiles(item),
      createdAt: moment(item.createdAt).format("DD-MM-YYYY"),
      addedBy: showWithdeleteButton(item),
    }));
  };
  const linkChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let input = e.target.value;
    let values = input.split(",");
    setDesignLinks(values);
  };

  const deleteLinkHandler = async (link: string) => {
    try {
      await deleteDesignLink(id, link);
      showToast.success("Deleted Successfully!");
      fetchRequirements();
    } catch (err: any) {
      showToast.error(err.response.data.message);
      fetchRequirements();
    }
  };

  const { getRootProps: getRootPropsDocs, getInputProps: getInputPropsDocs } =
    useDropzone({
      onDrop: async (acceptedFiles) => {
        setIsLoading(true);
        const formData = new FormData();
        let files = acceptedFiles;

        for (let i = 0; i < files.length; i++) {
          if (files[i].size > MAXFILESIZE) {
            showToast.error("File Size too large!");
            setIsLoading(false);
            return;
          }
          formData.append("attachment", files[i]);
        }
        try {
          await addProjectDesignDocs(id, formData);
          showToast.success("Successfully Uploaded!");
          setIsLoading(false);
          fetchRequirements();
        } catch (err: any) {
          showToast.error(err.response.data.message);
          setIsLoading(false);
          fetchRequirements();
        }
      },
      noClick: true,
    });

  const designFiles = generateTableRow();
  return (
    <Grid container xs={12} spacing={2}>
      {hasCreateAccess && (
        <>
          <Grid item xs={12}>
            <Box
              border="2px dashed rgba(0, 0, 0, 0.25)"
              borderRadius="10px"
              padding="2rem"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              my="1rem"
              {...getRootPropsDocs()}
            >
              <input {...getInputPropsDocs()} />
              {isLoading ? (
                <div>
                  <CircularProgress color="primary" />
                </div>
              ) : (
                <Button component="label" color="primary">
                  <BackupIcon fontSize="large" />
                  <input
                    type="file"
                    hidden
                    onChange={(e) => uploadDesignDocsHandler(e)}
                    accept=".docx, .doc, .pdf"
                    multiple
                  />
                </Button>
              )}
              <Typography>Drag & drop files or Browse</Typography>

              <Typography className={classes.acceptedfilestext}>
                .doc, .pdf {"( max 2mb )"}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              {isLinkEditable ? (
                <>
                  <InputLabel htmlFor="figmalink">Figma link</InputLabel>
                  <OutlinedInput
                    type="url"
                    placeholder="http://example.com"
                    id="figmalink"
                    onChange={(e) => linkChangeHandler(e)}
                    rows={6}
                    endAdornment={
                      <Button
                        variant="contained"
                        color="default"
                        className={classes.button}
                        onClick={saveDesignLinksHandler}
                      >
                        save
                      </Button>
                    }
                    labelWidth={70}
                  />
                </>
              ) : (
                <>
                  <InputLabel htmlFor="figmalink">Figma link</InputLabel>
                  <OutlinedInput
                    type="url"
                    placeholder="https://example.com"
                    id="figmalink"
                    disabled
                    value=""
                    endAdornment={
                      isLinkUploading ? (
                        <div>
                          <CircularProgress color="primary" />
                        </div>
                      ) : (
                        <Button
                          variant="contained"
                          color="default"
                          className={classes.button}
                          onClick={editHandler}
                        >
                          <EditIcon className={classes.docsIcon} />
                        </Button>
                      )
                    }
                    labelWidth={70}
                  />
                </>
              )}
            </FormControl>
          </Grid>
        </>
      )}
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Typography className={classes.innertext}>
            Figma Links for the project
          </Typography>
        </Grid>
        {projectRequirement?.designLinks?.length === 0 ||
        projectRequirement?.designLinks === undefined ? (
          <Grid item xs={12}>
            <Typography className={classes.innerlinktext}>
              No link Attached
            </Typography>
          </Grid>
        ) : (
          projectRequirement?.designLinks?.map((links: string, i: number) => (
            <Grid
              container
              item
              xs={12}
              key={i}
              className={classes.linkcontainer}
            >
              <a className={classes.link} href={links} target="_blank">
                <LinkIcon />
                <Typography
                  className={classes.linkText}
                >
                  <Tooltip title={links}>
                    <span>{links.slice(0, 100) + "..."}</span>
                  </Tooltip>
                </Typography>
              </a>
              <IconButton
                onClick={() => deleteLinkHandler(links)}
                className={classes.deleteicon}
              >
                <HighlightOffIcon fontSize="small" />
              </IconButton>
            </Grid>
          ))
        )}
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Typography className={classes.attachedfilestext}>
            Attached Files
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.innertext}>
            Design related files for the project
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {isLoading ? (
            <div className={classes.loading}>
              <CircularProgress color="primary" />
            </div>
          ) : (
            <Tables headCells={headCells} items={designFiles ?? []} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ProjectDesign;
