import {
  createTheme,
  // Theme as AugmentedTheme
} from "@material-ui/core/styles";
import { responsiveFontSizes } from "@material-ui/core";

declare module "@material-ui/core/styles/createTheme" {
  interface Theme {
    textColor: {
      primary: string;
      white: string;
      black: string;
    };

    backgroundColor: {
      paper: string;
      panels: string;
    };
  }

  interface ThemeOptions {
    textColor?: {
      primary?: string;
      white?: string;
      black?: string;
    };
    backgroundColor?: {
      paper?: string;
      panels?: string;
    };
  }
}

export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        light: '#434d63',
        main: '#140047',
        dark: '#0e172a',
      },
      secondary: {
        light: "#f0f7fa",
        main: "#EDF6F9",
        dark: "#a5acae",
      },

      warning: {
        main: "#FFBA08",
      },
      error: {
        main: "#D00000",
      },
    },

    textColor: {
      primary: '#140047',
      white: '#FFFFFF',
      black: '#001219',
    },

    backgroundColor: {
      paper: "#FFFFFF",
      panels: "#EDF6F9",
    },
    overrides: {
      MuiTableCell: {
        root: {
          borderBottom: "none",
          padding: "16px 0 16px 10px",
        },
      },

      MuiInputLabel: {
        shrink: {
          color: "#686868 !important",
        },
      },
      MuiInput: {
        underline: {
          "&::before": {
            borderBottom: "1px solid #c1c1c1",
          },
          "&::after": {
            borderBottom: "2px solid #c1c1c1",
          },
        },
      },
      MuiCircularProgress: {
        circle: {
          background: "red",
        },
      },

      // MuiTableRow: {
      //   root: {
      //     '&:nth-of-type(even)': {
      //       backgroundColor: '#f8f8f8',
      //     },
      //   },
      // },
    },

    typography: {
      fontFamily: "Poppins, sans-serif",
      button: {
        textTransform: "none",
        padding: "14px 31px",
      },
      // h1: {
      //   fontSize: "24px",
      //   fontWeight: 600,
      //   color: "#363434",
      // },
      // allVariants: {
      //   color: "#363434",
      // },
    },
  })
);
