import { Hidden, makeStyles } from "@material-ui/core";
import { theme } from "../../theme/theme";

const useStyles = makeStyles({
  dialogWrapper: {
    position: "absolute",
    margin: "0px",
    marginLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
    zIndex: 2000,
    background: "#EAF3F6",
    borderRadius: "10px",
  },
  statusHead: {
    background: "#140047",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  titleWrapper: {
    border: "1px solid rgba(0, 0, 0, 0.25)",
    padding: 0,
  },

  closeIconClass: {
    marginRight: theme.typography.pxToRem(13),
  },

  icons: {
    height: theme.typography.pxToRem(19),
    width: theme.typography.pxToRem(19),
  },
  titleText: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    marginLeft: theme.typography.pxToRem(20),
    color: theme.palette.primary.main,
  },
  labelText: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(18),
    color: "#ffffff",
  },

  close: {
    marginLeft: "auto",
    cursor: "pointer",
  },
  content: {
    display: "flex",
    padding: 0,
    justifyContent: "center",
    width:"100%"
  },
});

export default useStyles;
