import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: "19px solid #14213D",
    borderRadius: "12px",
  },
  details: {
    padding: "2.5rem",
    // height: "18em",
  },
  detailsinner: {
    marginTop: "2rem",
  },
  label: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    color: "#14213D",
  },
  textfields: {
    position: "relative",
    top: ".5rem",
  },
  button: {
    border: "1px solid black",
    backgroundColor: "#FFFFFF",
  },
  buttondiv: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    position: "relative",
  },
  disabledfields: {
    fontFamily: "Montserrat",
    fontStyle: " normal",
    fontWeight: 500,
    fontSize: "13px",
    lineHeight: " 12px",
    color: "#000000",
    opacity: 0.45,
    marginTop: ".5rem",
  },
  savebutton: {
    fontFamily: "Poppins",
    fontStyle: " normal",
    fontWeight: 500,
    backgroundColor: "#1C2943",
    color: "#FFFFFF",
    marginLeft: ".5rem",
  },
}));
