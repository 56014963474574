import React from "react";
import { Button, TextField } from "@material-ui/core";
import { useStyles } from "./styles";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import SelectField from "../../components/Fields/SelectField/index";
import Tooltip from "@material-ui/core/Tooltip";

/**
 * Dummy data
 */
const criteria = [
  {
    tag: "Criteria One",
    point: 5,
  },
  {
    tag: "Criteria Two",
    point: 2,
  },
  {
    tag: "Criteria Three",
    point: 6,
  },
  {
    tag: "Criteria Four",
    point: 1,
  },
];

interface Props {
  row: any;
  alternateTableRow: boolean;
}

const CollapsableTableRow: React.FC<Props> = ({ row, alternateTableRow }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState<boolean>(false);
  const [openForm, setOpenForm] = React.useState<boolean>(false);
  const [selectedCriteria, setSelectedCriteria] = React.useState(
    criteria[0].point
  );
  const [value, setValue] = React.useState<any>({
    remarks: "",
  });

  /**
   * @description Function to select a remark criteria from the drop down list
   * @method handleChange
   * @param {any} event
   * @returns nothing
   */
  const handleChange = (event: any) => {
    setSelectedCriteria(event.target.value);
  };

  /**
   * @description Function to handle remarks form value changes
   * @method handleRemarksChange
   * @param {any} e
   * @returns nothing
   */
  const handleRemarksChange = (e: any) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  /**
   * Opens the form to add remarks
   * @returns nothing
   */
  const handleOpen = () => {
    setOpenForm(true);
  };

  /**
   * Closes the form to add remarks
   * @returns nothing
   */
  const handleClose = () => {
    setOpenForm(false);
  };

  /**
   * @description Function to add points
   * @method addPoints
   * @returns nothing
   */
  const addPoints = () => {
    
    handleClose();
  };

  return (
    <>
      <TableRow className={alternateTableRow ? classes.alternateTableRow : ""}>
        <TableCell align="center">
          <Tooltip title={open ? "Hide history" : "Show history"}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell component="th" scope="row" className={classes.mainTableCell}>
          {row.name}
        </TableCell>
        <TableCell align="left" className={classes.mainTableCell}>
          {row.points}
        </TableCell>
        <TableCell align="center">
          <Button
            className={classes.addButton}
            variant="outlined"
            color="primary"
            onClick={handleOpen}
          >
            Add Points
          </Button>
          <Dialog open={openForm} onClose={handleClose}>
            <DialogTitle>Allocate Points</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Select a criteria from the predefined list
              </DialogContentText>
              <SelectField
                options={criteria}
                onSelect={handleChange}
                value={selectedCriteria}
                name="criteria"
                type="form"
                labelIdentifier="tag"
                valueIdentifier="point"
              />
              <Box mt={2}></Box>
              <TextField
                autoFocus
                multiline
                rows={4}
                maxRows={8}
                margin="dense"
                id="remarks"
                name="remarks"
                label="Remarks"
                type="text"
                fullWidth
                onChange={handleRemarksChange}
                variant="outlined"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={addPoints}>Add</Button>
            </DialogActions>
          </Dialog>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table
                className={alternateTableRow ? classes.historyTableCell : ""}
                size="small"
                aria-label="purchases"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCell} align="center">
                      Criteria
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      Remarks
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      Points
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow: any) => (
                    <TableRow key={historyRow.date}>
                      <TableCell
                        className={classes.tableCell}
                        align="center"
                        component="th"
                        scope="row"
                      >
                        {historyRow.criteria}
                      </TableCell>
                      <TableCell
                        className={classes.tableCellText}
                        align="center"
                      >
                        {historyRow.remarks}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {historyRow.points}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default CollapsableTableRow;
