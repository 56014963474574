import { Grid, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "../../../components/CustomButton";
import LeaveCounts from "./LeaveCounts";
import { useStyles } from "./styles";
import { RequestedLeave } from "../types";
import moment from "moment";

interface RequestLeaveResponseProps {
  handleSubmit: () => void;
  handleDiscard: () => void;
  requestedLeave: RequestedLeave | undefined;
  applyHandler: () => Promise<void>;
  loadingRequestedLeave: boolean;
}

const RequestLeaveResponse = ({
  handleSubmit,
  handleDiscard,
  requestedLeave,
  applyHandler,
  loadingRequestedLeave,
}: RequestLeaveResponseProps) => {
  const classes = useStyles();

  return (
    <>
      {loadingRequestedLeave ? (
        <Grid
          container
          justifyContent="center"
          className={classes.leaveResponseLoader}
        >
          <CircularProgress color="primary" />
        </Grid>
      ) : (
        <Grid container direction="row">
          <Grid item xs={10} className={classes.firstTextContainer}>
          <Typography className={classes.leaveResponsefirstText}>
            Your Updated Leave Balance:
          </Typography>
          </Grid>
          

          <Grid item container xs={12} className={classes.leaveResponseRoot}>
            <Grid container direction="row" justifyContent="space-evenly">
              <LeaveCounts
                leaveType={"priviledge_Leave"}
                leaveApplied={
                  requestedLeave?.updatedBalance.privilegeLeave.taken as number
                }
                leaveTotal={
                  requestedLeave?.updatedBalance.privilegeLeave
                    .allotted as number
                }
              />
              <LeaveCounts
                leaveType={"casual_leave"}
                leaveApplied={
                  requestedLeave?.updatedBalance.casualLeave.taken as number
                }
                leaveTotal={
                  requestedLeave?.updatedBalance.casualLeave.allotted as number
                }
              />
              <LeaveCounts
                leaveType={"sick_leave"}
                leaveApplied={
                  requestedLeave?.updatedBalance.sickLeave.taken as number
                }
                leaveTotal={
                  requestedLeave?.updatedBalance.sickLeave.allotted as number
                }
              />
            </Grid>
          </Grid>

          {requestedLeave?.warnings.map((item) => (
            <Grid item xs={12} className={classes.warning}>
              <Typography className={classes.leaveWarningText}>
                {item}
              </Typography>
            </Grid>
          ))}

          {requestedLeave?.notes.map((item) => (
            <Grid item className={classes.notes} xs={12}>
              <Typography className={classes.leaveNoteText}>{item}</Typography>
            </Grid>
          ))}
          <Grid item xs={10} className={classes.leaveResponseTextContainer}>
          <Typography className={classes.leaveResponseText}>
            Applied Leave Information:
          </Typography>
          </Grid>
          
          <Grid
            item
            container
            xs={12}
            direction="row"
            className={classes.formContainer}
          >
            <Grid item container className={classes.formdetails}>
              <Grid item container direction="column" xs={5}>
                <Grid item className={classes.containerOne}>
                  <Typography className={classes.labels}>
                    Leave Type:
                  </Typography>
                  <Typography className={classes.labelValue}>
                    {requestedLeave?.leaveType.replace(/_/g, " ")}
                  </Typography>
                </Grid>
                <Grid item className={classes.containerTwo}>
                  <Typography className={classes.labels}>From:</Typography>
                  <Typography className={classes.labelValue}>
                    {moment(requestedLeave?.from).format("DD-MM-YYYY")}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                container
                direction="column"
                justifyContent="space-evenly"
                xs={4}
              >
                <Grid item className={classes.containerOne}>
                  <Typography className={classes.labels}>Day Type:</Typography>
                  <Typography className={classes.labelValue}>
                    {requestedLeave?.day_type}
                  </Typography>
                </Grid>

                <Grid item className={classes.containerTwo}>
                  <Typography className={classes.labels}>To:</Typography>
                  <Typography className={classes.labelValue}>
                    {moment(requestedLeave?.to).format("DD-MM-YYYY")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container direction="column" xs={3}>
                <Grid item className={classes.containerOne}>
                  <Typography className={classes.labels}>Duration:</Typography>
                  <Typography className={classes.labelValue}>
                    {requestedLeave?.adjustAmount
                      ? `${
                          requestedLeave?.duration +
                          requestedLeave?.adjustAmount
                        }`
                      : requestedLeave?.duration}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                container
                direction="row"
                className={classes.reasoncontainer}
              >
                <Grid item xs={12}>
                  <Typography className={classes.labels}>Reason:</Typography>
                </Grid>
                <Grid item xs={12} className={classes.containerThree}>
                  <Typography>
                    {requestedLeave?.reason}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.footerText}>
            <Typography className={classes.leaveConfirmText}>
              Are you sure to apply this leave?
            </Typography>
          </Grid>

          <Grid
            item
            container
            xs={12}
            direction="row"
            justifyContent="space-evenly"
            className={classes.leaveResponseRoot}
          >
            <Grid item>
              <Button
                label="Cancel"
                isDisabled={false}
                isBordered={false}
                startIcon=""
                endIcon=""
                isOutlined={true}
                onButtonClick={handleDiscard}
                color="primary"
              />
            </Grid>
            <Grid item>
              <Button
                label="Apply"
                isDisabled={false}
                isBordered={false}
                startIcon=""
                endIcon=""
                isOutlined={false}
                onButtonClick={applyHandler}
                color="primary"
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default RequestLeaveResponse;
