import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
} from "@material-ui/core";
import { useStyles } from "./styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import { RequestedLeave } from "../types";

interface Iprops {
  items: RequestedLeave[] | undefined;
  headCells: {
    key: string;
    label: string;
  }[];
  isLoading: Boolean;
  tableClickHandler: (values: RequestedLeave) => void;
  clickedTableIndex: number;
}

const LTable: React.FC<Iprops> = ({
  items = [],
  headCells,
  isLoading,
  tableClickHandler,
}) => {
  const classes = useStyles();
  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table>
        <TableHead className={classes.tableHeaderRoot}>
          <TableRow>
            <TableCell className={classes.tableHeader} width="2%"></TableCell>
            {headCells.map((headCells) => (
              <TableCell
                height="40"
                key={headCells.key}
                align="left"
                className={classes.tableHeader}
              >
               <div
                  className={
                    headCells.key === "status"
                      ? classes.tableHeaderNoBorder
                      : classes.tableHeaderInside
                  }
                >
                  <span>{headCells.label}</span>
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {isLoading ? (
          <div className={classes.loading}>
            <CircularProgress color="primary" />
          </div>
        ) : (
          <TableBody>
            {items.map((item: any, i: number) => (
              <TableRow
                className={classes.tableRow}
                onClick={(e) => {
                  tableClickHandler(item);
                }}
                key={item.id}
              >
                <TableCell></TableCell>
                <TableCell component="th" scope="row" align="left">
                  {item.leave_type.replace(/_/g, " ")}
                </TableCell>
                <TableCell component="th" scope="row" align="left">
                  {item.duration}
                </TableCell>
                <TableCell component="th" scope="row" align="left">
                  {moment(item.from).format("DD-MM-YYYY")}
                </TableCell>
                <TableCell component="th" scope="row" align="left">
                  {moment(item.to).format("DD-MM-YYYY")}
                </TableCell>
                <TableCell component="th" scope="row" align="left">
                  {item.cancelRequested && item.leaveStatus == "cancelled"
                    ? "cancelled"
                    : item.cancelRequested
                    ? "cancel request pending"
                    : item.leaveStatus}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default LTable;
