import { makeStyles } from "@material-ui/core";
import { theme } from "../../theme/theme";

export const useStyles = makeStyles({
  container: {
    width: "100%",
    display: "flex",
  },
  childs: {
    marginLeft: theme.typography.pxToRem(255),
    width: `calc(100vw - ${theme.typography.pxToRem(108)})`
  },
});
