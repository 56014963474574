import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2em",
  },
  mainContainer: {
    width: "100%",
    display: "flex",
    paddingLeft: theme.typography.pxToRem(25),
    paddingRight: theme.typography.pxToRem(25),
    height: "97vh",
  },

  top: {
    flex: 1,
  },

  mid: {
    flex: 3,
    paddingTop: theme.typography.pxToRem(15),
  },

  bottom: {
    flex: 5,
  },

  welcome: {},

  welcomeText: {
    margin: 0,
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 300,
    fontFamily: "poppins",
  },

  nameText: {
    margin: 0,
    fontSize: theme.typography.pxToRem(28),
    fontWeight: 500,
    fontFamily: "poppins",
    marginRight: theme.typography.pxToRem(12),
  },

  eventBox: {
    padding: theme.typography.pxToRem(12),
  },

  button: {
    marginRight: theme.typography.pxToRem(14),
  },
  imageGroup: {
    width: "100%",
    // paddingBottom: "3rem",
    // marginTop: "6rem",
  },
  closeImage: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  randomBox: {
    height: "2rem",
  },
  acceptButton: {
    width: "200px",
  },
  header:{
    display: "flex"
  },
  mockinterview:{
    marginRight: "1rem" 
  },
  link:{
    textDecoration:"none"
  },
  interviewbutton:{
    border: "1px solid black",
    paddingTop:theme.typography.pxToRem(8) ,
    paddingBottom:theme.typography.pxToRem(8),
    paddingLeft:theme.typography.pxToRem(25),
    paddingRight:theme.typography.pxToRem(25),
    width:'100%',
    justifyContent: 'space-around',
    fontFamily: "Poppins",
    fontSize:theme.typography.pxToRem(12),
  },
  action:{
    border:"1px solid black",
    paddingTop:theme.typography.pxToRem(8) ,
    paddingBottom:theme.typography.pxToRem(8),
    paddingLeft:theme.typography.pxToRem(25),
    paddingRight:theme.typography.pxToRem(25),
    width:'100%',
    justifyContent: 'space-around',
    fontFamily: "Poppins",
    fontSize:theme.typography.pxToRem(12),
    backgroundColor:"#14213D",
    color:"#FFFFFF",
    "&:hover":{
      backgroundColor: "none",
      color:"black"
  }
  }
}));
