import { useCallback, useState } from "react";
import { useRecoilState } from "recoil";
import { saveDocument } from "../../apis/onboarding";
import { isUploadsViewEnabled } from "../../recoil/atoms";
import { documentDownloadAtom } from "../../recoil/downloadAtoms";

type File = {
  name: string;
  value: Blob | string;
};

export const usePhotos = () => {
  const [photos, setPhotos] = useState<File[]>([]);
  const [, setIsViewEnabled] = useRecoilState(isUploadsViewEnabled);
  const [, setRewriteFileDownload] = useRecoilState(documentDownloadAtom);

  const mainUrl = process.env.REACT_APP_BACKEND_URL;

  const handlePostPhotos = useCallback(
    async (
      showToast: {
        error: (message: string) => void;
        success: (message: string) => void;
      },
      uploadDocsEndpointPatch: string
    ) => {
      if (photos.length !== 2) {
        showToast.error("Both Files are required!");
        return;
      }

      const formData = new FormData();

      photos.forEach((file) => {
        formData.append(file.name, file.value);
      });

      const url = mainUrl + uploadDocsEndpointPatch;
      const response: any = await saveDocument(url, formData);
      if (response.status === 200) {
        showToast.success("Photos Saved!");
        setIsViewEnabled((prev) => ({
          ...prev,
          photos: true,
        }));
        setRewriteFileDownload((prev) => ({
          ...prev,
          passportPhoto: false,
          personalPhoto: false,
        }));
      } else {
        showToast.error("Something Went Wrong!");
      }
    },
    [photos]
  );

  return {
    photos,
    setPhotos,
    handlePostPhotos,
  };
};
