import { useStyles } from "./styles";
import {
  Grid,
  Paper,
  InputLabel,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { ChangeEvent, useState } from "react";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { TASK } from "./CheckLists";

interface IUncheckedTasks {
  tasks: TASK[];
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const UncheckedTasks = ({ tasks, handleChange }: IUncheckedTasks) => {
  const classes = useStyles();
  const [showUnchecked, setShowUnchecked] = useState<boolean>(false);

  return (
    <Paper variant="outlined" elevation={4} className={classes.paperContainer}>
      <InputLabel variant="outlined" className={classes.label} shrink>
        <b className={classes.labelColor}>Tasks</b>
      </InputLabel>
      {tasks.length > 0 && showUnchecked
        ? tasks.map((task, index) => {
            return (
              <Grid
                container
                direction="column"
                className={
                  task.checked
                    ? classes.taskContainerClicked
                    : classes.taskContainer
                }
                key={index}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={task.checked}
                      onChange={handleChange}
                      name="checked"
                      color="primary"
                      value={task.item}
                    />
                  }
                  label={task.item}
                />
              </Grid>
            );
          })
        : tasks.length >= 2 &&
          tasks
            .filter((task, index) => index <= 2)
            .map((data, index) => {
              return (
                <Grid
                  container
                  direction="column"
                  key={index}
                  className={
                    data.checked
                      ? classes.taskContainerClicked
                      : classes.taskContainer
                  }
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={<CheckCircleOutlineIcon />}
                        checked={data.checked}
                        onChange={handleChange}
                        name="checked"
                        color="primary"
                        value={data.item}
                      />
                    }
                    label={data.item}
                  />
                </Grid>
              );
            })}
      <div
        className={classes.toggleLabels}
        onClick={() =>
          setShowUnchecked((prev) => {
            return !prev;
          })
        }
      >
        {tasks.length > 0 ? (
          showUnchecked ? (
            <Typography>
              <ExpandLessIcon fontSize="medium" />
              Hide items
            </Typography>
          ) : (
            <Typography>
              <ExpandMoreIcon fontSize="medium" />
              Show More Items
            </Typography>
          )
        ) : (
          <Typography>All Tasks Completed</Typography>
        )}
      </div>
    </Paper>
  );
};

export default UncheckedTasks;
