import axios from "./axios.global";

export const holidays = async () => await axios.get("/holiday");



export const addHoliday = async (payload: object) => {
  try {
    return await axios.post(`/holiday`, payload);
  } catch (err: any) {
    return err;
  }
};

export const deleteHoliday = async (id: string | undefined) => {
  try {
    return await axios.delete(`/holiday/del/${id}`);
  } catch (err: any) {
    return err;
  }
};

export const getHolidayListAPI = async () => await axios.get('/holiday');

export const uploadHolidayListAPI = async (file: FormData) => await axios.post('/holiday/upload', file)
