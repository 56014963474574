import React, { useEffect, useState } from "react";
import BasicLayout from "../../components/BasicLayout";
import { useBasicNav } from "../../utils/useBasicNav";
import reactLogo from "../../assets/images/react.svg";
import pythonLogo from "../../assets/images/python.svg";
import javascriptLogo from "../../assets/images/javascript.svg";
import flutterLogo from "../../assets/images/flutter.svg";
import devopsLogo from "../../assets/images/devops.svg";
import reactNativeLogo from "../../assets/images/react.svg";
import dataEngineeringLogo from "../../assets/images/dataEngineering.svg";
import othersLogo from "../../assets/images/others.svg";
import Dialog from "@material-ui/core/Dialog";
import ModalBody from "./components/ModalBody";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import DashboardView from "./views/DashboardView";
import EditorView from "./views/EditorView";
import Container from "./layouts/Container";
import ArticleView from "./views/ArticleView";

import useStyles from "./styles";

interface props {
    loadPrimary: (event: any, state: any) => void;
    showToast: any;
}
export interface postData {
    author: any;
    updatedBy: any;
    category: string;
    language: string;
    framework: string;
    platform: string;
    title: string;
    body: string;
    images: any[];
    isDraft: boolean;
}

export const Toast = React.createContext<any>({});

const KnowledgeHubPage: React.FC<props> = ({ loadPrimary, showToast }) => {
    useBasicNav("knowledgeHub");

    const classes = useStyles();
    const { path } = useRouteMatch();

    // state that controls the show and hide of the modal
    const [modalState, setModalState] = useState<boolean>(false);

    // stores the information of the newly created post
    const [postData, setPostData] = useState<postData>({
        author: "",
        updatedBy: "",
        category: "",
        framework: "",
        language: "",
        platform: "",
        title: "",
        body: "",
        images: [],
        isDraft: true,
    });

    const [pageNo, setPageNo] = useState<number>(1);
    const [isLoadedByPageNo, setIsLoadedByPageNo] = useState<boolean>(false);

    useEffect(() => {
        loadPrimary(false, false);
    }, []);

    function paginate() {
        setPageNo((prev) => prev + 1);
        setIsLoadedByPageNo(true);
    }

    function showModal() {
        setModalState(true);
    }

    function hideModalAndClear() {
        setPostData({
            author: "",
            updatedBy: "",
            category: "",
            framework: "",
            language: "",
            platform: "",
            title: "",
            body: "",
            images: [],
            isDraft: true,
        });
        setModalState(false);
    }

    function hideModal() {
        setModalState(false);
    }

    const logos = [
        reactLogo,
        pythonLogo,
        javascriptLogo,
        flutterLogo,
        devopsLogo,
        reactNativeLogo,
        dataEngineeringLogo,
        othersLogo,
    ];

    return (
        <Toast.Provider value={showToast}>
            <BasicLayout>
                <Container>
                    <Switch>
                        <Route path={`${path}`} exact>
                            <DashboardView
                                logos={logos}
                                showModal={showModal}
                                pageNo={pageNo}
                                setPageNo={setPageNo}
                                paginate={paginate}
                                isLoadedByPageNo={isLoadedByPageNo}
                                setIsLoadedByPageNo={setIsLoadedByPageNo}
                            />
                        </Route>
                        <Route path={`${path}/editor-view/:id`} exact>
                            <EditorView setPostData={setPostData} postData={postData} />
                        </Route>
                        <Route path={`${path}/post/:id`} exact>
                            <ArticleView />
                        </Route>
                    </Switch>
                </Container>
                <Dialog open={modalState} onClose={hideModalAndClear} className={classes.dialog}>
                    <ModalBody
                        setPostData={setPostData}
                        postData={postData}
                        hideModal={hideModal}
                        hideModalAndClear={hideModalAndClear}
                    />
                </Dialog>
            </BasicLayout>
        </Toast.Provider>
    );
};

export default KnowledgeHubPage;
