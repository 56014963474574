import {makeStyles, Theme} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme)=>({
    topBar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        columnGap: "6rem",
        marginBottom: "3rem"
    },
    techSec:{
        width: "100%",
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
        gap: "2rem",
        marginBottom: "3rem"
    },
    addNewDoc: {
        "&.MuiButton-contained": {
            paddingTop: "1rem",
            paddingBottom: "1rem",
        },
    },
    seeMore: {
        "&.MuiButton-contained": {
           marginTop: "0.6rem",
        },
        marginBottom: "0.6rem"
    },
    searchBar: {
        flex: "1",
    },
    iconAndButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        columnGap: "2rem",
    },
    postSec:{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center"
    },
}))