import React from "react";
import Loader from "../components/utility/Loader/Loader";

interface IProps {
  isLoading?: boolean;
  label?: string;
  color?: string;
  inviteUserHandler?: (event: any, id: string) => void;
  userId?: string;
  showList?: boolean;
  showToast?: any;
  roles?: object[];
  setShowList?: any;
  toggleRefresh?: (type?: string) => void;
  type?: string;
  name?: string;
  editRoleId?: string | undefined;
  items?: object[];
  permissions?: any;
  toggleEditRole?: any;
  parentHeight?: number | undefined;
  data?: any;
  isEditEnabled?: boolean;
  showListHandler?: Function;
  [key : string] : any
}

const test = (LoadedComponent: any, styles = {}) => {
  const WithLoadingEffect: React.FC<IProps> = ({ isLoading, ...props }) => {
    
    return isLoading ? (
      <Loader styles={styles} />
    ) : (
      <LoadedComponent {...props} />
    );
  };

  return WithLoadingEffect;
};

export default test;
