import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { HourglassEmptyOutlined } from "@material-ui/icons";
import { DoneAll } from "@material-ui/icons";
import { NotInterested } from "@material-ui/icons";
import moment from "moment";
import _ from "lodash";

import React, { ChangeEvent, useEffect, useState } from "react";
import { leaveDataType } from "../Accordion/EmployeeLeaveAccordion/EmployeeLeaveAccordion";
import { EmployeeLeaveHeader } from "./EmployeeLeaveHeader";

import { useStyles } from "./styles";
import { ellipsify, formatToDate } from "./utils";

interface EmployeeLeaveTableProps {
  tableHeaders: string[];
  tableData: {
    applied_on: string;
    from: string;
    to: string;
    approved_by: string;
    leave_type: string;
    isApproved: boolean;
    isPending: boolean;
    reason: string;
  }[];
  setSearchedData: React.Dispatch<React.SetStateAction<leaveDataType[]>>;
  searchedData: leaveDataType[];
  sortTitle: string;
  setSortTitle: React.Dispatch<React.SetStateAction<string>>;
  setSortByDate: React.Dispatch<React.SetStateAction<string>>;
  setPageNo: React.Dispatch<React.SetStateAction<number>>;
}

const EmployeeLeaveTable: React.FC<EmployeeLeaveTableProps> = ({
  tableHeaders,
  tableData,
  setSearchedData,
  searchedData,
  sortTitle,
  setSortTitle,
  setSortByDate,
  setPageNo,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const leaveTypeList: any = {
    sick_leave: "Sick Leave",
    casual_leave: "Casual Leave",
    priviledge_leave: "Priviledge Leave",
  };

  useEffect(() => {
    setSearchedData(tableData);
  }, []);

  useEffect(() => {
    sortByType();
  }, [sortTitle]);

  const sortByType = () => {
    const sortingData: leaveDataType[] = [];

    tableData.forEach((value) => {
      if (
        value.leave_type.toLowerCase().search(sortTitle.toLowerCase()) != -1
      ) {
        
        sortingData.push({
          applied_on: value.applied_on,
          from: value.from,
          to: value.to,
          approved_by: value.approved_by,
          leave_type: value.leave_type,
          isApproved: value.isApproved,
          isPending: value.isPending,
          reason: value.reason,
        });
        setSearchedData(sortingData);
      } else {
        setSearchedData(tableData);
      }
    });
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSortData = (sortBy: string) => {
    if (sortBy === "date") {
      setSortByDate("date");
      setPageNo(1);
    } else {
      setSortTitle(sortBy);
      setPageNo(1);
    }
    handleClose();
  };

  return (
    <>
      <EmployeeLeaveHeader
        anchorEl={anchorEl}
        handleClick={handleClick}
        handleClose={handleClose}
        // handleSearch={handleSearch}
        handleSortData={handleSortData}
      />
      <Grid container justifyContent="center">
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead className={classes.tableHeader}>
              <TableRow className={classes.tableRow}>
                {tableHeaders.map((headerText, index) => {
                  return (
                    <TableCell className={classes.tableHeaderCell}>
                      {headerText}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {searchedData.length > 0 ? (
                searchedData.map((rowData, index) => {
                  return (
                    <TableRow>
                      <TableCell component="th" scope="row" align="left">
                        {moment(rowData.applied_on).format("YYYY-MM-DD")}
                      </TableCell>
                      <TableCell component="th" scope="row" align="left">
                        {moment(rowData.from).format("YYYY-MM-DD")}
                      </TableCell>
                      <TableCell component="th" scope="row" align="left">
                        {moment(rowData.to).format("YYYY-MM-DD")}
                      </TableCell>
                      <TableCell component="th" scope="row" align="left">
                        {rowData.approved_by}
                      </TableCell>
                      <TableCell component="th" scope="row" align="left">
                        {leaveTypeList[rowData.leave_type]}
                      </TableCell>
                      <TableCell component="th" scope="row" align="left">
                        {rowData.isPending ? (
                          <HourglassEmptyOutlined
                            className={classes.statusPending}
                          />
                        ) : !rowData.isPending && rowData.isApproved ? (
                          <DoneAll className={classes.statusApproved} />
                        ) : (
                          <NotInterested className={classes.statusRegected} />
                        )}
                      </TableCell>
                      <TableCell component="th" scope="row" align="left">
                        <Tooltip title={rowData.reason}>
                          <Typography>
                            {ellipsify(0, 11, rowData.reason)}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <Grid container justifyContent="center">
                  <Typography style={{ paddingTop: "1em" }}>
                    No Leaves Applied Yet
                  </Typography>
                </Grid>
              )}
              <TableRow>
                <TableCell component="th" scope="row" align="left"></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

export default EmployeeLeaveTable;
