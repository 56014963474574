import React from "react";
import "./styles";
import AccordionComponent from "../../../components/AccordionComponent";

const EmployeeDetailsTab1 = () => {
  return (
    <div>
      <AccordionComponent />
    </div>
  );
};

export default EmployeeDetailsTab1;
