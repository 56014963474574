import React, { useEffect } from "react";
import SignInForm from "../../components/SignInForm";
import SignInGoogle from "../../components/SignInGoogle";
import { useBasicNav } from "../../utils/useBasicNav";
import { googleLogin } from "../../apis/login";
// import queryString from "query-string";
import { login } from "../../apis/login";
import {
  getPersistentAuthentication,
  resetPersistentAuthentication,
  setPersistentAuthentication,
  getPersistentPermissions,
  setPersistentPermissions,
  setNewPersistentPermissions,
} from "../../utils/functions";
import LoginLayout from "../../layout/LoginLayout";
import { useHistory } from "react-router-dom";

import { permissions } from "../../recoil/atoms";
import { useRecoilState } from "recoil";
// const currentStateData = useSetRecoilState(stateData);

const INITIAL_VALUES = {
  username: "",
  password: "",
};

interface props {
  loadPrimary: (event: any) => void;
  showToast: any;
}

/**
 * @description Functional component to render the sign in page
 * @param {Function} loadPrimary
 * @param {any} showToast
 * @returns a node
 */
const SignIn: React.FC<props> = ({ loadPrimary, showToast }) => {
  const [permission, setPermission] = useRecoilState<any>(permissions);
  const [values, setValues] = React.useState(INITIAL_VALUES);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [isAdminPage, setIsAdminPage] = React.useState(false);
  const { setActiveTab } = useBasicNav();
  const history = useHistory();
  // const inviteToken = queryString.parse(history.location.search).token;

  useEffect(() => {
    resetPersistentAuthentication();
    setPermission({
      ...permission,
      attendance: {},
      department: {},
      designation: {},
      leave: {},
      policies: {},
      role: {},
      user: {},
      contentBasket: {},
      projects: {},
      incidents: {},
      _id: "",
      roleName: "",
    });
    loadPrimary(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @description Function to handle login form submission
   * @method handleSubmit
   * @param {any} e
   * @return nothing
   */
  const handleSubmit = (e: any) => {
    e.preventDefault();
    setSubmitting(true);
    login(values)
      .then((response) => {
        response[0]
          ? showToast.success("Successfully logged in")
          : showToast.error("Wrong credentials!");
        //Add data to recoil state
     
        setPermission({
          ...permission,
          attendance: response[1]?.permissions?.attendance,
          department: response[1]?.permissions?.department,
          designation: response[1]?.permissions?.designation,
          leave: response[1]?.permissions?.leave,
          policies: response[1]?.permissions?.policies,
          role: response[1]?.permissions?.role,
          user: response[1]?.permissions?.user,
          contentBasket: response[1]?.permissions?.contentBasket,
          projects: response[1]?.permissions?.projects,
          incidents: response[1]?.permissions?.incidents,
          _id: response[1]?.permissions?._id,
          roleName: response[1]?.roleName,
        });

        setPersistentAuthentication({
          token: response[0]?.access_token,
          // role: response[1]?.roleName,
          username: response[0]?.fullname,
          userId: response[0]?._id
        });

        /**
         * @function setPersistentPermissions
         *  @description set the persisted permission data
         */
        setPersistentPermissions(response[1]);
        

        getPersistentAuthentication()?.token && setActiveTab("dashboard");
        setSubmitting(false);
      })
      .catch((err) => {
        setSubmitting(false);
        showToast.error("Something went wrong!");
      });
  };

  /**
   * @description Function to handle google sign in
   * @method responseGoogle
   * @param {any} response
   * @returns nothing
   */
  const responseGoogle = (response: any) => {
    setSubmitting(true);

    googleLogin(response)
      .then((response) => {
        if (response.error) {
          showToast.error(response.message);
          return;
        }

        //Add data to recoil state
        setPermission({
          ...permission,
          attendance: response[1]?.permissions?.attendance,
          department: response[1]?.permissions?.department,
          designation: response[1]?.permissions?.designation,
          leave: response[1]?.permissions?.leave,
          policies: response[1]?.permissions?.policies,
          role: response[1]?.permissions?.role,
          user: response[1]?.permissions?.user,
          _id: response[1]?.permissions?._id,
          roleName: response[1]?.roleName,
        });

        setPersistentAuthentication({
          token: response[0]?.access_token,
          username: response[0]?.fullname,
          userId: response[0]?._id,
          role: response[1]?.roleName,
          // role: "something",
          isPolicyAccepted: response[0]?.isPolicyAccepted,
        });

        /**
         * @function setPersistentPermissions
         *  @description set the persisted permission data
         */
        setPersistentPermissions(response[1]);
        setNewPersistentPermissions(response[1].newPermissions);

        getPersistentAuthentication()?.token && setActiveTab("dashboard");
        setSubmitting(false);
      })
      .catch((err) => {
        setSubmitting(true);
        
        showToast.error("Something went wrong!");
      });
  };

  /**
   * @description Function to handle changes in the sign in form field values
   * @method handleChange
   * @param {any} e
   * @returns nothing
   */
  const handleChange = (e: any) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  /**
   * @description Function to show or hide login form
   * @method handleChangeLogin
   * @returns nothing
   */
  const handleChangeLogin = () => {
    setIsAdminPage(!isAdminPage);
  };

  /**
   * @description Function for redirecting to forgot password page
   * @method handleForgotPassword
   * @returns nothing
   */
  const handleForgotPassword = () => {
    history.push("/app/forgotpassword");
  };

  return (
    <LoginLayout text="Log into your account">
      {isAdminPage ? (
        <SignInForm
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          values={values}
          isSubmitting={isSubmitting}
          handleChangeLogin={handleChangeLogin}
          isLogin={true}
          submitText="LOGIN"
          handleForgotPassword={handleForgotPassword}
        />
      ) : (
        <SignInGoogle
          responseGoogle={responseGoogle}
          handleChangeLogin={handleChangeLogin}
          submitting={isSubmitting}
        />
      )}
    </LoginLayout>
  );
};

export default SignIn;
