import React, { useState, useEffect } from "react";
import { useStyles } from "./styles";
import { Grid, Typography } from "@material-ui/core";
import rightIcon from "../../../../assets/icons/rightIcon.svg";
import Popup from "../../../../components/Popup";
import LeaveAppliedForm from "../LeaveAppliedForm";
import { getAllCancelRequest, cancelTheRequest } from "../../../../apis/leaves";

export interface CancelRequestType {
  applied_on: Date;
  approved_by: string;
  assignedTo: [];
  cancelRequested: Boolean;
  day_type: string;
  duration: number;
  emp_id: string;
  emp_name: string;
  from: Date;
  isApproved: Boolean;
  isPending: Boolean;
  leave_type: string;
  reason: string;
  reasonCancelled: string;
  to: Date;
  _id: string;
}

interface IProps {
  parentHeight?: number | undefined;
  showToast: any;
}

const CancelRequest: React.FC<IProps> = ({
  showToast,
}) => {
  const classes = useStyles();
  const [openLeaveDetails, setOpenLeaveDetails] = useState<boolean>(false);
  const [clickedCard, setClickedCard] = useState<CancelRequestType>();
  const [cancelRequest, setCancelRequest] = useState<CancelRequestType[]>([]);

  const handleOpenLeaveDetailsModal = () => {
    setOpenLeaveDetails(true);
  };

  const handleCloseLeaveDetailsModal = () => {
    setOpenLeaveDetails(false);
  };

  const handleCardClick = (item: CancelRequestType) => {
    setClickedCard(item);
    handleOpenLeaveDetailsModal();
  };

  useEffect(() => {
    getCancelRequests();
  }, []);

  async function getCancelRequests() {
    try {
      let data = await getAllCancelRequest();
      setCancelRequest(data?.data);
    } catch (error: any) {
      showToast.error(error.response.data.message);
    }
  }

  const cancelleaveRequest = async () => {
    try {
      await cancelTheRequest(clickedCard?._id as string);
      showToast.success("Cancelled Request approved!");
      getCancelRequests();
      setOpenLeaveDetails(false);
    } catch (error: any) {
      showToast.error(error.response.data.message);
    }
  };

  return (
    <Grid direction="column" className={classes.container}>
      <Grid item direction="column" className={classes.requests}>
        {cancelRequest.length === 0 ? (
          <Typography className={classes.noRequestText}>
            No pending requests..
          </Typography>
        ) : (
          cancelRequest?.map((item: CancelRequestType) => {
            return (
              <Grid
                alignItems="center"
                justifyContent="space-between"
                className={classes.card}
                container
                onClick={() => handleCardClick(item)}
              >
                <h3 className={classes.nameLabel}> {item?.emp_name}</h3>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  className={classes.circleButton}
                >
                  <img src={rightIcon} alt="right-icon" />
                </Grid>
              </Grid>
            );
          })
        )}
      </Grid>

      <Popup
        name="Cancel Request"
        open={openLeaveDetails}
        handleClose={handleCloseLeaveDetailsModal}
      >
        <LeaveAppliedForm
          data={clickedCard}
          cancelRequestData={cancelRequest}
          leaveRequest
          showToast={showToast}
          handleClose={handleCloseLeaveDetailsModal}
          cancelleaveRequest={cancelleaveRequest}
          getCancelRequests={getCancelRequests}
        />
      </Popup>
    </Grid>
  );
};

export default CancelRequest;
