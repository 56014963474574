import { Grid, InputLabel, Paper, Typography } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  checkAcademicDocumentSelectionAtom,
  isUploadsViewEnabled,
  recruiteeBasicDetails
} from "../../../../../recoil/atoms";
import { UploadsContext } from "../../../../../store/uploadsStore";

import { useDropzone } from "react-dropzone";
import { getDocuments } from "../../../../../apis/onboarding";
import uploadDocument from "../../../../../assets/icons/uploadDocument.svg";
import { documentDownloadAtom } from "../../../../../recoil/downloadAtoms";
import { HandleDroppedAcademics } from "../HandleDroppedFiles/HandleDroppedAcademics";
import { useStyles } from "../styles";
import UploadedDocuments from "../UploadedDocuments";
import { IAcademicChildrenProps } from "./AcademicCertificates";

const Class10 = ({
  handleChange,
  setIsSubmit,
  showToast,
  getEndpoint,
  imgName,
  setImgName,
  imgSize,
  setImgSize,
}: IAcademicChildrenProps) => {
  const classes = useStyles();

  const [, setIsFileSelected] = useRecoilState(
    checkAcademicDocumentSelectionAtom
  );
  const basicDetails = useRecoilValue(recruiteeBasicDetails);
  const { academicProgress, academicProofs, setAcademicProof } =
    useContext(UploadsContext);
  const [, setRewriteFileDownload] = useRecoilState(documentDownloadAtom);
  const mainUrl = process.env.REACT_APP_BACKEND_URL;
  const [, setIsViewEnabled] = useRecoilState(isUploadsViewEnabled);
  const [isDisable, setIsDisable] = useState<boolean>(false);

  const url = mainUrl + getEndpoint;
  const parentKey = "academicCertificates";
  const childKey = "class10th";

  const getDocs = async () => {
    const response = await getDocuments(url, parentKey, childKey);
    if (response?.status === 200 && response?.data !== "") {
      setImgName((prev) => ({
        ...prev,
        class10th: `Class 10 Marksheet of ${basicDetails.name}`,
      }));
      setIsViewEnabled((prev) => ({
        ...prev,
        academic: true,
      }));
      setIsSubmit((prev: any) => ({
        ...prev,
        class10: true,
      }));
      setAcademicProof((prev) => [
        ...prev,
        {
          name: "class10th",
          value: response.data,
        },
      ]);
    } else {
      setIsViewEnabled((prev) => ({
        ...prev,
        academic: false,
      }));
    }
  };

  useEffect(() => {
    getDocs();
  }, []);

  useEffect(() => {
    const length = academicProofs.length;
    if (length > 0) {
      for (let i = 0; i < length; i++) {
        if (
          academicProofs[i].name === "class10th" &&
          imgName.class10th !== ""
        ) {
          setIsDisable(true);
          break;
        }
        if (imgName.class10th === "") {
          setIsDisable(false);
        }
      }
    }
  }, [academicProofs, imgName]);

  const { getRootProps: getRootProps10, getInputProps: getInputProps10 } =
    useDropzone({
      onDrop: (acceptedFiles10: any) => {
        const file = acceptedFiles10[0];
        const docType = "ClassTen";

        if (!isDisable) {
          HandleDroppedAcademics({
            docType,
            file,
            academicProofs,
            setAcademicProof,
            setIsFileSelected,
            setImgName,
            setImgSize,
            setIsSubmit,
            showToast,
            setRewriteFileDownload,
          });
        }
      },
      noClick: true,
    });

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      className={classes.docContainer}
    >
      <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
        <Paper
          variant="outlined"
          elevation={4}
          className={classes.paperContainer}
        >
          <InputLabel variant="outlined" className={classes.label} shrink>
            <b className={classes.labelColor}>Class 10 Marksheet</b>
          </InputLabel>

          <Grid container direction="column" className={classes.dropContainer}>
            <Grid item>
              <Grid
                container
                direction="column"
                spacing={2}
                className={classes.dashedContainer}
                {...getRootProps10()}
              >
                <input {...getInputProps10()} />
                <Grid item className={classes.itemStyle}>
                  <Typography className={classes.boldText}>
                    Drag and Drop files to upload
                  </Typography>
                </Grid>
                <Grid item className={classes.itemStyle}>
                  <Typography className={classes.instruction}>
                    Maximum upload size 1MB
                  </Typography>
                </Grid>

                <Grid item className={classes.itemStyle}>
                  <input
                    type="file"
                    name="class10th"
                    id="class10th"
                    className={classes.inputfile}
                    onChange={(event) => handleChange(event)}
                    onClick={(event) => {
                      //@ts-ignore
                      event.target.value = null;
                    }}
                    disabled={isDisable}
                  />
                  <label htmlFor="class10th" className={classes.inputlabel}>
                    <i className={classes.inputButtonIcon}>
                      <img src={uploadDocument} />
                    </i>
                    &nbsp; Upload File
                  </label>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className={classes.itemStyle}
            >
              {imgName.class10th !== "" ? (
                <UploadedDocuments
                  progress={academicProgress}
                  imageName={imgName.class10th}
                  imageSize={imgSize.class10th}
                  label="class10"
                  getEndpoint={getEndpoint}
                  setImgName={setImgName}
                />
              ) : (
                <Typography className={classes.instruction}>
                  No files selected
                </Typography>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Class10;
