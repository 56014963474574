import { makeStyles, Theme } from "@material-ui/core";
export const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
  },
  container: {
    minWidth: "34rem",
  },
  dialog: {
    "& .MuiPaper-root": {
      boxShadow: "none",
      borderRadius: "10px",
    },
  },
  heading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid rgba(0, 0, 0, 0.25)",
    padding: "0.5rem 1.5rem",
  },
  labels: {
    color: "#140047",
  },
  form: {
    padding: "2rem 3rem",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-Start",
    flexDirection: "column",
  },
  mb: {
    marginBottom: "1.7rem",
  },
  link: {
    textDecoration: "none",
    color: "white",
    padding: "0.6rem 1rem",
    backgroundColor: "#140047",
    borderRadius: "0.5rem",
  },
  btn: {
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  menuItem: {
    textTransform: "capitalize",
  },
  btnBase: {
    "&.MuiButton-root": {
      backgroundColor: "#140047",
      color: "white",
      borderRadius: "5px",
    },
  },

  select: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: "theme.palette.secondary.main",
      },
      '&:hover fieldset': {
        borderColor: theme.palette.secondary.dark,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
}));

// export const useStyles = makeStyles((theme: Theme) => ({
//   modal: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   },
//   paper: {
//     backgroundColor: theme.palette.background.paper,
//     boxShadow: theme.shadows[5],
//     outline: "none",
//     width: 580,
//     height: 284,
//     borderRadius: 10,
//     padding: "2em",
//     textAlign: "center",
//   },
//   textFields: {
//     width: "20em",
//   },
//   saveButton: {
//     backgroundColor: "#14213D",
//     color: "#fff",
//   },
// }));
