import {
  Button,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  getBankInfo,
  handleBankDetailsSubmit,
} from "../../../../../apis/onboarding";
import { accrExpansionState } from "../../../../../recoil/AccordionExpansion";
import {
  bankDetailsAtom,
  onboardingIdAtom,
  recruiteeBasicDetails,
  recruiteeDetailsStepsAtom,
  recruiteeDetailsStepsTempAtom,
  recruiteeDetailsViewEnabled,
  recruiteeEditEnabled,
} from "../../../../../recoil/atoms";
import {
  bankAccountNoRegex,
  ifscRegex,
  lettersRegex,
} from "../../../../../utils/constants";
import { handleExpansion } from "../../utils";
import { useStyles } from "./styles";
import { handleChangeBankDetailsForm, setBankDetails } from "./utils";

interface BankProps {
  showToast: any;
  index: number;
}
const BankDetailsForm: React.FC<BankProps> = ({ showToast, index }) => {
  const classes = useStyles();
  const [isValid, setIsValid] = useState(false);
  const [bankDetailsForm, setBankDetailsForm] = useRecoilState(bankDetailsAtom);
  const [accordionState, setAccordionState] =
    useRecoilState(accrExpansionState);
  const [isViewEnabled, setIsViewEnabled] = useRecoilState(
    recruiteeDetailsViewEnabled
  );
  const [isEdit, setIsEdit] = useRecoilState(recruiteeEditEnabled);
  const getOnboardingId = useRecoilValue(recruiteeBasicDetails);
  const [recruiteeDetailsStepsatom, setRecruiteeDetailsStepsatom] =
    useRecoilState(recruiteeDetailsStepsTempAtom);
  const [onboardingIds, setOnboardingIds] = useRecoilState(onboardingIdAtom);

  const [isBankName, setIsBankName] = useState<boolean>(false);
  const [isAccNo, setIsAccNo] = useState<boolean>(false);
  const [isIfsc, setIsIfsc] = useState<boolean>(false);
  const [toggleUser, setToggleUser] = useState<boolean>(false);
  const [accessToken, setAccessToken] = useState<string>("");
  const [userRole, setUserRole] = useState<string>("");

  useEffect(() => {
    if (
      bankDetailsForm.accountNo !== "" &&
      bankAccountNoRegex.test(bankDetailsForm.accountNo)
    ) {
      setIsAccNo(true);
    } else {
      setIsAccNo(false);
    }
    if (bankDetailsForm.IFSC !== "" && ifscRegex.test(bankDetailsForm.IFSC)) {
      setIsIfsc(true);
    } else {
      setIsIfsc(false);
    }
    if (
      bankDetailsForm.bankName !== "" &&
      lettersRegex.test(bankDetailsForm.bankName)
    ) {
      setIsBankName(true);
    } else {
      setIsBankName(false);
    }
  }, [bankDetailsForm]);

  useEffect(() => {
    const role = window.sessionStorage.getItem("userRole") as string;
    const token = window.sessionStorage.getItem("access_token") as string;

    setUserRole(role);
    setAccessToken(token);
  }, [toggleUser]);

  useEffect(() => {
    getBankDetailsFunc();
  }, [onboardingIds.BankDetails]);

  const getBankDetailsFunc = async () => {
    if (
      getOnboardingId.id === "" ||
      getOnboardingId.id === null ||
      getOnboardingId.id === undefined
    ) {
      return;
    } else {
      const getBankDetails = await getBankInfo(getOnboardingId.id);
      setBankDetails({ getBankDetails, setBankDetailsForm });
    }
  };

  const handleChange = (event: any) => {
    const allHandlers = handleChangeBankDetailsForm(
      event,
      setBankDetailsForm,
      setIsBankName,
      setIsAccNo,
      setIsIfsc
    );
    const myHandler = allHandlers[event.target.name];
    myHandler();
  };

  const handleEdit = () => {
    setIsEdit((prevState) => ({
      ...prevState,
      bankDetailsEdit: true,
    }));
    setIsViewEnabled((prevState) => ({
      ...prevState,
      bankDetailsViewEnabled: false,
    }));
  };

  const handleSubmit = (event: any) => {
    if (!isBankName) {
      showToast.error("Account Name is Invalid!");
      return;
    }

    if (isAccNo === false) {
      showToast.error("Account Number is Invalid!");
      return;
    }

    if (!isIfsc) {
      showToast.error("IFS Code is Invalid!");
      return;
    }

    handleBankDetailsSubmit(
      bankDetailsForm,
      getOnboardingId.id,
    )
      .then((res) => {
        setToggleUser(!toggleUser);
        if (res?.status == 400) {
          showToast.error(res?.message);
          return;
        }
        if (res?.status == 200) {
          setOnboardingIds((prev: any) => ({
            ...prev,
            BankDetails: res.data._id,
          }));

          showToast.success("BANK DETAILS SAVED");
          handleExpansion(index, accordionState, setAccordionState);
          setIsViewEnabled((prevState) => ({
            ...prevState,
            bankDetailsViewEnabled: true,
          }));
          if (userRole === "temp-user") {
            setRecruiteeDetailsStepsatom((prev) => ({
              ...prev,
              bankDetails: true,
            }));
          }
          return;
        }
      })
      .catch((err: { message: any }) => {
        setToggleUser(!toggleUser);
        showToast.error(`Error ${err.message}`);
        return;
      });
  };

  return (
    <>
      <Grid
        container
        xs={12}
        md={12}
        xl={12}
        style={{ width: "100%", margin: "2% 2%" }}
      >
        <form style={{ width: "100%" }}>
          <Grid
            container
            className={classes.gridContainer}
            style={{ paddingTop: "3%" }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              className={classes.gridItem}
            >
              {isViewEnabled.bankDetailsViewEnabled ? (
                <>
                  <InputLabel
                    variant="outlined"
                    shrink
                    className={classes.viewTextLabel}
                  >
                    <b>Name</b>
                  </InputLabel>
                  <Typography className={classes.viewText}>
                    {bankDetailsForm.bankName}
                  </Typography>
                </>
              ) : (
                <TextField
                  id="bankName"
                  name="bankName"
                  label="Bank Name"
                  value={bankDetailsForm.bankName}
                  onChange={(event) => handleChange(event)}
                />
              )}
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              className={classes.gridItem}
            >
              {isViewEnabled.bankDetailsViewEnabled ? (
                <>
                  <InputLabel
                    variant="outlined"
                    shrink
                    className={classes.viewTextLabel}
                  >
                    <b>Account No.</b>
                  </InputLabel>
                  <Typography className={classes.viewText}>
                    {bankDetailsForm.accountNo}
                  </Typography>
                </>
              ) : (
                <TextField
                  type="number"
                  id="accountNo"
                  name="accountNo"
                  label="Account No."
                  value={bankDetailsForm.accountNo}
                  onChange={(event) => handleChange(event)}
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              className={classes.gridItem}
            >
              {isViewEnabled.bankDetailsViewEnabled ? (
                <>
                  <InputLabel
                    variant="outlined"
                    shrink
                    className={classes.viewTextLabel}
                  >
                    <b>IFS Code</b>
                  </InputLabel>
                  <Typography className={classes.viewText}>
                    {bankDetailsForm.IFSC}
                  </Typography>
                </>
              ) : (
                <TextField
                  id="ifsc"
                  name="ifsc"
                  label="IFS Code"
                  value={bankDetailsForm.IFSC}
                  onChange={(event) => handleChange(event)}
                />
              )}
            </Grid>
          </Grid>

          <Grid
            container
            className={classes.gridContainerButton}
            justifyContent="flex-end"
          >
            {isViewEnabled.bankDetailsViewEnabled ? (
              <Button
                variant="contained"
                className={classes.saveButton}
                onClick={handleEdit}
              >
                Edit
              </Button>
            ) : isEdit.bankDetailsEdit ? (
              <Button
                variant="contained"
                className={classes.saveButton}
                onClick={handleSubmit}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                className={classes.saveButton}
                onClick={handleSubmit}
              >
                Save
              </Button>
            )}
          </Grid>
        </form>
      </Grid>
    </>
  );
};

export default BankDetailsForm;
