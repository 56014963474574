import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height:"100%",
    padding: `${theme.typography.pxToRem(7)}`,
  },

  card: {
    border: "1px solid rgba(0, 0, 0, 0.15)",
    borderRadius: "10px",
    marginBottom: theme.typography.pxToRem(10),
    height: theme.typography.pxToRem(50),
    padding: `${theme.typography.pxToRem(0)} ${theme.typography.pxToRem(7)}`,
    cursor:"pointer",
  },

  nameLabel: {
    margin: 0,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
    fontFamily: "poppins",
    color: "#686868",
  },
  circleButton: {
    borderRadius: "50%",
    height: theme.typography.pxToRem(28),
    width: theme.typography.pxToRem(28),
    border: "0.5px solid #989898",
    background: "#F9F9F9",
  },
  noRequestText:{
    color:"silver",
    fontSize:"11px",
  },
  requests:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
  }
}));
