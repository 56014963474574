import React, { useState, useEffect } from "react";
import { useBasicNav } from "../../utils/useBasicNav";
import BasicLayout from "../../components/BasicLayout";
import { useRecoilState } from "recoil";
import { pQueryAtom } from "../../recoil/atoms";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import ProjectDetailPage from "./Page/ProjectDetailPage";
import MainPage from "./Page/MainPage";

interface Iprops {
  loadPrimary: (event: boolean, basic?: boolean) => void;
  showToast: any;
}

export const Toast = React.createContext<any>({});

const ProjectsPage: React.FC<Iprops> = ({ loadPrimary, showToast }) => {
  useBasicNav("projects");
  const { path } = useRouteMatch();

  useEffect(() => {
    loadPrimary(false, false);
}, []);
 
  return (
    <Toast.Provider value={showToast}>
      <BasicLayout>
        <Switch>
          <Route path={`${path}`} exact>
            <MainPage showToast={showToast}/>
          </Route>
          <Route path={`${path}/projectdetails/:id`} exact>
            <ProjectDetailPage />
          </Route>
        </Switch>
      </BasicLayout>
    </Toast.Provider>
  );
};

export default ProjectsPage;
