import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import EmployeeLeaves from "../../pages/EmployeesPage/EmployeeLeaves";
import EmployeeProjectDetails from "../../pages/EmployeesPage/EmployeeProjectDetails";
import { EmployeeDocument } from "../../pages/EmployeesPage/EmployeeDocuments";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "white",
    [`& > .MuiPaper-root`]: {
      backgroundColor: "white",
      color: "#222e48",
      boxShadow: "0",
    },
  },
  indicator: {
    backgroundColor: "#222e48",
    height: "4px",
  },
}));

type TabsComponentProps = {
  TabContent: React.ElementType;
};

export default function TabsComponent({ TabContent }: TabsComponentProps) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" elevation={1}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          // TabIndicatorProps={{ className: classes.indicator }}
          classes={{
            indicator: classes.indicator,
          }}
        >
          <Tab label="Employee details" {...a11yProps(0)} />
          {/* <Tab label="Uploads" {...a11yProps(1)} /> */}
          <Tab label="Leaves" {...a11yProps(1)} />
          <Tab label="Project Assigned" {...a11yProps(2)} />
          <Tab label="Documents" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <TabContent />
      </TabPanel>
      {/* <TabPanel value={value} index={1}>
        Item Two
      </TabPanel> */}
      <TabPanel value={value} index={1}>
        <EmployeeLeaves />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <EmployeeProjectDetails />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <EmployeeDocument />
      </TabPanel>
    </div>
  );
}
