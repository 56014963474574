import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    my2: {
      marginBlock: "16px",
    },
    my1: {
      marginBlock: "8px",
    },
    mx1: {
      marginInline: "8px",
    },
    px1: {
      paddingInline: "8px",
    },
    px2: {
      paddingInline: "16px",
    },
    py2: {
      paddingBlock: "16px",
    },
    flex: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "8px",
    },
    fontBold: {
      fontWeight: "bold",
    },
    gridGap1: {
      gap: "8px",
    },
    verticalScroll: {
      boxSizing: "border-box",
      maxHeight: "40.625em",
      overflowY: "scroll",
    },
    border: {
      border: "1px solid black",
    },
    flex1: {
      flex: 1,
    },
    centerAlign: {
      display: "grid",
      placeItems: "center",
    },
    height70: {
      height: "70vh",
    },
  })
);
