import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Authenticator from "./components/Authenticator";
import { Loader } from "./components/Preloader/Preloader";
import { Toast } from "./components/Toast";
import { RouteConfig } from "./routes";
import { getPath } from "./utils/functions";

const TvRouter: React.FC = () => {
  const [role, setRole] = useState("");
  useEffect(() => {
    const userRole = window.sessionStorage.getItem("userRole");
    if (userRole) {
      setRole(userRole);
    }
  }, [role]);

  return (
    <Switch>
      {RouteConfig.routes.map(({ component: Component, ...route }, index) => (
        <Route
          key={index}
          path={getPath(route.path, RouteConfig.basePath)}
          render={(props: any) => (
            <Authenticator
              shouldHavePadding={route.shouldHavePadding}
              shouldAuthenticate={route.shouldAuthenticate}
            >
              <Loader {...{ ...props, ...route }}>
                <Toast {...props}>
                  {<Component loadPrimary={props.loadPrimary} {...props} />}
                </Toast>
              </Loader>
            </Authenticator>
          )}
        />
      ))}
      {role === "temp-user" ? (
        <Redirect from="/" exact to={`/app/signinotp`} />
      ) : (
        <Redirect from="/" exact to={`/app/signin`} />
      )}
    </Switch>
  );
};

export default TvRouter;
