import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '100%',
    height: '100%',
    padding: theme.typography.pxToRem(20),
  },

  textFieldWrapper:{
    background:"#fff"
  },

  label:{
      fontFamily:"Poppins",
      fontWeight:400,
      color:"#14213D50",
      fontSize:theme.typography.pxToRem(14),
      margin:0

  },

  bottomGrid:{
      marginTop:theme.typography.pxToRem(30),
  },

  buttonGroup:{
    marginTop:theme.typography.pxToRem(20),
  },

  button:{
      marginRight:theme.typography.pxToRem(20),
  }


}));
