import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  buttonRoot: {
    textAlign: "center",
  },
  googleButtonContainer: {
    backgroundColor: "#0FA9DD",
    width: theme.typography.pxToRem(300),
    height: theme.typography.pxToRem(50),
    fontSize: "10px",
    paddingLeft: 8,
    borderRadius: 50,
    "&:hover": {
      backgroundColor: "#0a769a",
    },
  },
  googleIcon: {
    marginTop: theme.typography.pxToRem(5.5),
    marginLeft: theme.typography.pxToRem(-5),
  },
  textContainer: {
    marginTop: 12,
  },

  adminLinkContainer: {
    display: "flex",
    justifyContent: "center",
  },

  link: {
    cursor: "pointer",
    color: theme.palette.primary.light,
    "&:hover": {
      boxShadow: `0 1px 0 ${theme.palette.primary.dark}`,
    },
    marginTop: 10,
  },

  textColor: {
    color: theme.textColor.white,
  },
}));

export default useStyles;
