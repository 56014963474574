import React, { useEffect, useState } from "react";
import LoginLayout from "../../layout/LoginLayout";
import SignInOTPForm from "../../components/SignInOTPForm";
import { emailRegex } from "../../utils/constants";
import {
  getOnboardUser,
  regenerateOtp,
  verifyOTP,
} from "../../apis/onboarding";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { employeeInformation, recruiteeBasicDetails } from "../../recoil/atoms";
import { setDetails } from "../NewRecruits/utils";

const INITIAL_VALUES = {
  emailID: "",
  otp: "",
};

interface props {
  loadPrimary: (event: any) => void;
  showToast: any;
}

const SignInOTP: React.FC<props> = ({ loadPrimary, showToast }) => {
  const history = useHistory();
  const [values, setValues] = useState(INITIAL_VALUES);
  const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
  const [isValidOtp, setIsValidOtp] = useState<boolean>(false);
  const [basicDetails, setBasicDetails] = useRecoilState(recruiteeBasicDetails);
  const [employeeInfom, setEmployeeInfo] = useRecoilState(employeeInformation);

  loadPrimary(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValidEmail) {
      showToast.error("INVALID EMAIL ID!");
      return;
    }
    if (!isValidOtp) {
      showToast.error("INVALID OTP!");
      return;
    }

    try {
      const res = await verifyOTP(values);
      if (res.status === 200 || res.status === 201) {
        window.sessionStorage.setItem("access_token", res.data.access_token);
        const access_token = window.sessionStorage.getItem("access_token");
        if (access_token) {
          const resp = await getOnboardUser(access_token);
          if (resp.status === 200) {
            setBasicDetails((prev) => ({
              ...prev,
              id: resp.data.data._id,
            }));

            window.sessionStorage.setItem("userRole", resp.data.role);
            const recruiteeDetails = {
              designation: resp.data.data.employeeInformation.designation,
              emailID: resp.data.data.emailID,
              name: resp.data.data.name,
              mobileNo: resp.data.data.mobileNo,
              status: "pending",
              _id: resp.data.data._id,
            };

            setDetails({
              recruiteeDetails,
              setBasicDetails,
              setEmployeeInfo,
            });
            history.push("/app/newrecruitdetails", {
              state: recruiteeDetails,
            });
          } else {
            showToast.error("Something went wrong!");
          }
        }
      } else if (res.statusCode === 404) {
        showToast.error("Invalid OTP!");
      } else if (res.statusCode === 500) {
        showToast.error("User Unavailable");
      } else {
        showToast.error(res.message);
      }
    } catch (err: any) {
      showToast.error(err.response.data);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (e.target.name === "emailID") {
      if (e.target.value.match(emailRegex)) {
        setIsValidEmail(true);
        setValues({
          ...values,
          emailID: e.target.value,
        });
      } else {
        setIsValidEmail(false);
      }
    }
    if (e.target.name === "otp") {
      if (e.target.value !== "") {
        setIsValidOtp(true);
        setValues({
          ...values,
          otp: e.target.value,
        });
      } else {
        setIsValidOtp(false);
      }
    }
  };

  const handleResendOTP = () => {
    if (!isValidEmail) {
      showToast.error("INVALID EMAIL ID!");
      return;
    } else {
      const email = values.emailID;
      // api call to regenrate
      regenerateOtp(email).then((res) => {
        if (res.statusCode === 200) {
          showToast.success("Otp Regenerated! Please Check Your Email Inbox!");
        }
        if (res.statusCode === 500) {
          showToast.error(
            "Services Are Currently Unavailable, Please Try Again Later!"
          );
        }
      });
    }
  };

  return (
    <LoginLayout text="Enter OTP sent to your email ID">
      <SignInOTPForm
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        handleResendOTP={handleResendOTP}
      />
    </LoginLayout>
  );
};

export default SignInOTP;
