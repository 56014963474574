import { Box } from "@material-ui/core";
import { previousDay } from "date-fns";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import * as yup from "yup";
import { getEmployeeKraAPI } from "../../../apis/kra";
import { formDataAtom, IFormData } from "../../../recoil/kraAtom";
import { Adapter } from "../../../utils/Adapter";
import KRAForm from "../components/KRAForm";
import { KRAContext } from "../KRAPage";

export interface IBasicInfoForm {
  empId: string;
  date: string;
  quarter: string;
  months: string;
}

// export interface IDetailsProps {
//   customCB?: (...args: any) => void;
// }

export interface IDetailsProps {
  customCB?: (step: number) => void;
}

export interface IKRALocationState {
  kraInfo: {
    kraId: string;
    empName: string;
  };
  empInfo: {
    empId: string;
    empName: string;
  };
}

const BasicInfo = ({ customCB }: IDetailsProps) => {
  // const [formData, setFormData] = useState<IBasicInfoForm>({
  //   empId: "",
  //   date: "",
  //   quarter: "",
  //   months: "",
  // });

  const [formData, setFormData] = useRecoilState<IFormData>(formDataAtom);

  const location = useLocation();
  const { empInfo, kraInfo } = location.state as IKRALocationState;

  const { showToast } = useContext(KRAContext);

  const getData = async () => {
    const res = await getEmployeeKraAPI(kraInfo?.kraId);
    const apiData = new Adapter(res.data);
    const data = apiData.adapt<IFormData>((data) => ({
      attributes: data.attributes,
      date: data.date,
      empId: data.employee.fullName,
      months: data.months,
      quarter: data.quarter,
    }));
    setFormData(data);
    setValues(data);
  };

  useEffect(() => {
    if (kraInfo) {
      getData();
    }
  }, []);

  useEffect(() => {
    setFieldValue("empId", empInfo?.empName || kraInfo?.empName);
  }, [empInfo, kraInfo]);

  const ValidationSchema = yup.object().shape({
    empId: yup.string().required("Required"),
    date: yup.string().required("Required"),
    quarter: yup.string().required("Required"),
    months: yup.string().required("Required"),
  });

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: formData,
    validationSchema: ValidationSchema,
    validateOnChange: false,
    onSubmit: (vals) => {
      setFormData({ ...formData, ...vals });
      customCB?.(1);
    },
  });

  return (
    <Box sx={{ marginTop: "2em" }}>
      <KRAForm
        formData={values}
        formErrors={errors}
        setFieldValue={setFieldValue}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </Box>
  );
};

export default BasicInfo;
