import React, { useState, useEffect } from "react";
import { useBasicNav } from "../../utils/useBasicNav";
import BasicLayout from "../../components/BasicLayout";
import { Grid, Dialog } from "@material-ui/core";
import Button from "../../components/CustomButton";
import { useStyles } from "./styles";
import addNewContent from "../../assets/images/addNewContent.svg";
import ContentTable from "./components/ContentTable";
import Pagination from "@material-ui/lab/Pagination";
import FormModal from "./components/FormModal";
import { getPersistentAuthentication } from "../../utils/functions";
import {
  contentformData,
  contentformUpload,
  getcontentData,
  deleteContent,
} from "../../apis/contentBasket";
import { capitalizeString } from "../../utils/functions";
import FilterButton from "./components/FilterButton";
import StatusMenu from "./components/StatusMenu";
import moment from "moment";
import { permissions } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";
import AdminMenu from "./components/AdminMenu";
import Search from "./components/Search";
import useDebounce from "../../hooks/useDebounce";
import { ContentData, contentData } from "./types";

interface Iprops {
  loadPrimary: (event: boolean, basic?: boolean) => void;
  showToast: any;
}
const HEAD_CELLS = [
  { key: "sl_no", label: "S.No", width: "3%" },
  { key: "title", label: "Title", width: "19%" },
  { key: "type", label: "Type", width: "5%" },
  { key: "contributor", label: "Contributor", width: "5%" },
  { key: "date", label: "Date", width: "5%" },
  { key: "file", label: "File", width: "3%" },
  { key: "status", label: "Status", width: "3%" },
];
const FilterOptions = [
  { label: "All", value: "all" },
  { label: "Published", value: "published" },
  { label: "Under Review", value: "under_review" },
  { label: "Approved", value: "approved" },
  { label: "Sent Back", value: "sent_back" },
  { label: "Rejected", value: "rejected" },
];

const ContentBasketPage: React.FC<Iprops> = ({ showToast, loadPrimary }) => {
  useBasicNav("contentBasket");
  const classes = useStyles();
  const [modalState, setModalState] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState("");
  const [contentData, setContentData] = useState<ContentData>({
    title: "",
    contentType: "",
    contentArea: "",
    file: null,
    id: "",
  });
  const userId = getPersistentAuthentication()?.userId;
  const username = getPersistentAuthentication()?.username;
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [pageNo, setPageNo] = useState(1);
  const [uploadedFile, setUploadedFile] = useState<{
    name: string;
    value: File | string;
  }>({
    name: "",
    value: "",
  });
  const [fileName, setfileName] = useState("");
  const [userContentData, setUserContentData] = useState<contentData[]>([]);
  const [Cstatus, setCStatus] = useState<string>(FilterOptions[0].value);
  const [pageCount, setPageCount] = useState(0);
  const [contentId, setcontentId] = useState("");
  const [reupload, setReupload] = useState(false);
  const permission = useRecoilValue<any>(permissions);
  const isAdmin = permission?.roleName === "Admin" && true;
  const isSuperAdmin = permission?.roleName === "superadmin" && true;
  const [isUpdated, setIsUpdated] = useState(true);
  const debouncedValue = useDebounce(searchKey, 1000);

  useEffect(() => {
    loadPrimary(true, true);
  }, []);

  useEffect(() => {
    fetchdata();
  }, [debouncedValue, Cstatus, pageNo, isUpdated]);

  function PageCount(totalLength: number) {
    let totalPage = Math.ceil(totalLength / 10);
    setPageCount(totalPage);
  }

  async function fetchdata() {
    setIsLoading(true);
    try {
      let data = await getcontentData(pageNo, debouncedValue, Cstatus);
      loadPrimary(false, true);
      let totalLength = data?.data?.length;
      PageCount(totalLength);
      let tempItem: contentData[] = [];
      data?.data?.data.forEach((item: contentData) => {
        let tempObjUser: contentData = {
          _id: item._id,
          sl_number: item.sl_number ?? "N/A",
          title: item.title ?? "N/A",
          contentType:
            capitalizeString(item.contentType.replace(/_/, " ")) ?? "N/A",
          contributor: {
            basicDetails:
              {
                fullName: item.contributor?.basicDetails?.fullName,
              } ?? "N/A",
          },
          updatedAt: moment(item.updatedAt).format("DD-MM-YYYY"),
          file: item.file,
          status: item.status ?? "N/A",
          contentArea: item.contentArea,
        };
        tempItem.push(tempObjUser);
      });
      setUserContentData(tempItem);
      setIsLoading(false);
      if (data?.data?.data.length === 0) {
        setPageNo(1);
        return;
      }
    } catch (error: any) {
      showToast.error(error.message);
    }
  }

  const generateStatus = () => {
    return userContentData.map((value: contentData) => ({
      id: value._id,
      sl_no: value.sl_number,
      contentArea: value.contentArea,
      contentType: value.contentType,
      title: value.title,
      file: value.file,
      date: value.updatedAt,
      status: formatStatus(value),
      contributor: value.contributor,
    }));
  };

  function showModal() {
    setReupload(false);
    setModalState(true);
    setContentData({
      title: "",
      contentType: "",
      contentArea: "",
      file: null,
      id: "",
    });
    setUploadedFile({
      name: "",
      value: "",
    });
    setfileName("");
  }
  function hideModalAndClear() {
    setModalState(false);
  }

  const uploadFileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    var filePath = e.target.value;
    var allowedExtensions = /(\.doc|\.docx|\.odt|\.pdf)$/i;
    const uploadedFiles = e.target.files as FileList;
    const uplFile = uploadedFiles[0];
    // const uploadedFile = uploadedFiles && uploadedFiles[0];

    if (!allowedExtensions.exec(filePath)) {
      showToast.error(
        "Invalid file type(only .doc,.docx,.odt,.pdf are allowed!)"
      );
      e.target.value = "";
      return false;
    }
    setUploadedFile((prev) => ({
      ...prev,
      name: e.target.name,
      value: uplFile,
    }));

    setfileName(uplFile.name);
    setContentData((prevState) => ({
      ...prevState,
      fileUpload: uplFile,
    }));
  };

  const titleChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setContentData((prevState) => ({
      ...prevState,
      title: e.target.value,
    }));
  };
  const typeChangeHandler = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown | string;
    }>
  ) => {
    setContentData((prevState) => ({
      ...prevState,
      contentType: e.target.value,
    }));
  };
  const areaChangeHandler = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown | string;
    }>
  ) => {
    setContentData((prevState) => ({
      ...prevState,
      contentArea: e.target.value,
    }));
  };

  async function createContentHandler(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    //create content handler
    e.preventDefault();
    if (contentData.title === "") {
      showToast.error("Title can't be Empty");
      return;
    }
    if (contentData.contentType === "") {
      showToast.error("Type can't be Empty");
      return;
    }
    if (contentData.contentArea === "") {
      showToast.error("Area can't be Empty");
      return;
    }
    if (uploadedFile?.value === "") {
      showToast.error("File can't be Empty");
      return;
    }
    hideModalAndClear();
    setIsLoading(true);
    try {
      let { data, error } = await contentformData(userId, contentData);
      if (error) {
        showToast.error(error.message);
        setIsLoading(false);
        return;
      }
      if (data) {
        let formData = new FormData();
        formData.append("file", uploadedFile.value);
        let cid;
        contentData.title = "";
        contentData.contentArea = "";
        contentData.contentType = "";
        cid = data.data._id;
        setcontentId(cid);

        await contentformUpload(formData, cid);
        uploadedFile.name = "";
        uploadedFile.value = "";
        formData.delete("value");
        setIsUpdated(true);
        setfileName("");
        showToast.success("CONTENT SAVED");
        setIsUpdated(false);
        setIsLoading(false);
      }

    } catch (error: any) {
      showToast.error(error.message);
    }
  }

  const updateHandler = (itemValue: contentData) => {
    //form update handler
    setModalState(true);
    setReupload(true);
    setContentData({
      title: itemValue.title,
      contentType: itemValue.contentType,
      contentArea: itemValue.contentArea,
      file: itemValue.file,
      id: itemValue._id,
    });
  };

  const deleteHandler = async (itemValue: contentData) => {
    try {
      await deleteContent(itemValue._id);
      fetchdata();
    } catch (error: any) {
      showToast.error(error.message);
    }
  };

  const handleChangePagination = (
    //handling pagination
    event: React.ChangeEvent<unknown>,
    value: any
  ) => {
    setPageNo(value);
  };

  const formatStatus = (value: contentData) => {
    return (
      <Grid container direction="row" className={classes.status}>
        {isAdmin || isSuperAdmin ? (
          <div className={classes.adminStatus}>
            <Grid item>
              <AdminMenu
                _id={value._id}
                status={value.status}
                showToast={showToast}
              />
            </Grid>
            <Grid item>
              {username === value.contributor.basicDetails.fullName && (
                <StatusMenu
                  updateHandler={updateHandler}
                  deleteHandler={deleteHandler}
                  value={value}
                />
              )}
            </Grid>
          </div>
        ) : (
          <div className={classes.userStatus}>
            <Grid item className={classes.statusValue}>
              {capitalizeString(value.status.replace(/_/, " "))}
            </Grid>
            <Grid item>
              {username === value.contributor.basicDetails.fullName && (
                <StatusMenu
                  updateHandler={updateHandler}
                  deleteHandler={deleteHandler}
                  value={value}
                />
              )}
            </Grid>
          </div>
        )}
      </Grid>
    );
  };

  return (
    <BasicLayout>
      <Grid container>
        <Grid item xs={12} md={12} sm={12}>
          <Grid container direction="row">
            <Grid className={classes.leftHeader} item xs={12} md={6}>
              <div style={{ display: "flex" }}>
                <Search setSearchKey={setSearchKey} />
                <div className={classes.filterButton}>
                  <FilterButton
                    FilterOptions={FilterOptions}
                    setCStatus={setCStatus}
                    Cstatus={Cstatus}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.addnewcontent}>
                <Button
                  label="Add New Content"
                  isBordered={false}
                  startIcon={addNewContent}
                  endIcon=""
                  isOutlined={false}
                  onButtonClick={showModal}
                  color="primary"
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.table} item xs={12} md={12} sm={12}>
          <ContentTable
            headCells={HEAD_CELLS}
            items={generateStatus()}
            setModalState={setModalState}
            contentId={contentId}
            isLoading={isLoading}
          />
        </Grid>
        <Grid className={classes.footer} item xs={12} md={12} sm={12}>
          <Pagination
            page={pageNo}
            defaultPage={pageNo}
            count={pageCount}
            color="primary"
            onChange={handleChangePagination}
          />
        </Grid>
      </Grid>
      <Dialog
        open={modalState}
        onClose={hideModalAndClear}
        className={classes.dialog}
      >
        <FormModal
          showToast={showToast}
          hideModalAndClear={hideModalAndClear}
          createContentHandler={createContentHandler}
          uploadFileHandler={uploadFileHandler}
          titleChangeHandler={titleChangeHandler}
          typeChangeHandler={typeChangeHandler}
          areaChangeHandler={areaChangeHandler}
          uploadedFile={uploadedFile}
          setUploadedFile={setUploadedFile}
          setfileName={setfileName}
          fileName={fileName}
          contentData={contentData}
          reupload={reupload}
          setIsUpdated={setIsUpdated}
          setIsLoading={setIsLoading}
        />
      </Dialog>
    </BasicLayout>
  );
};
export default ContentBasketPage;
