import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '100%',
    display: 'flex',
    padding:`${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(25)}`
  },

  top: {
    flex:1
  },
  
  bottom:{
    paddingTop: theme.typography.pxToRem(20),
    flex:5
  },

  backContainer:{
    cursor:"pointer"
  },

  back:{
    margin: 0,
    fontSize:theme.typography.pxToRem(14),
    fontWeight:600,
    fontFamily:"poppins",
    marginRight: theme.typography.pxToRem(12)
  },

  buttonSave:{
      marginLeft: theme.typography.pxToRem(10)
  }



}));
