import axios from "./axios.global";

export const createDepartment = async (payload: object) => {
  try {
    return await axios.post(`/department`, payload);
  } catch (err: any) {
    return err;
  }
};

export const getDepartment = async () => {
  try {
    return await axios.get(`/department`);
  } catch (err: any) {
    return err;
  }
};

export const deleteDepartment = async (id: string) => {
  try {
    return await axios.delete(`/department/del/${id}`);
  } catch (err: any) {
    return err;
  }
};

export const createDesignation = async (payload: object) => {
  try {
    return await axios.post(`/designation`, payload);
  } catch (err: any) {
    return err;
  }
};

export const getDesignation = async () => {
  try {
    return await axios.get(`/designation`);
  } catch (err: any) {
    return err;
  }
};

export const deleteDesignation = async (id: string) => {
  try {
    return await axios.delete(`/designation/del/${id}`);
  } catch (err: any) {
    return err;
  }
};
