import { getPersistentPermissions } from "../../utils/functions";

export type Resource =
  | "leave"
  | "employees"
  | "contentBaskets"
  | "projects"
  | "incidents"
  | "kHub"
  | "designation"
  | "department"
  | "attendance"
  | "kras"
  | string;

export type Permissions =
  | "*"
  | "read"
  | "upDate"
  | "del"
  | "cancel"
  | "grant"
  | "create"
  | "adjust"
  | "approve"
  | string;

export class Permission {
  private permissions: Permissions[];

  constructor(permissions: Permissions[]) {
    this.permissions = permissions;
  }

  includes(permission: Permissions | Permissions[]): boolean {
    if (permission === "*") {
      return true;
    } else if (Array.isArray(permission)) {
      //@ts-ignore
      return this.permissions.some((role) =>
        //@ts-ignore
        permission.every((key: string) => role[key])
      );
    } else {
      //@ts-ignore
      return this.permissions.some((role) => role[permission]);
    }
  }

  required(permission: Permissions | Permissions[]): boolean {
    if (Array.isArray(permission)) {
      //@ts-ignore
      return this.permissions.some((role) =>
        //@ts-ignore
        permission.every((key: string) => role[key])
      );
    } else {
      //@ts-ignore
      return this.permissions.some((role) => role[permission]);
    }
  }
}
/**
 *  **Returns an permission object with two methods**
 *  - `includes` if any of the permission includes
 *  - `required` if given set of permissions are included.
 *
 * example:
 *
 * ```ts
 *    const permissions = usePermissionObjectFor("leave");
 *    const hasGrant = permission.includes("grant");
 *    const hasGrant&cancel = permission.required(["grant", "cancel"])
 * ```
 */
export function usePermissionObjectFor(resource: Resource) {
  const permissions = getPersistentPermissions();
  const newPermissions = permissions.newPermissions;
  // @ts-ignore
  const resources = [];
  //@ts-ignore
  newPermissions?.forEach((object) => {
    resources.push(object[`${resource}`]);
  });

  const permissionArr = Object.entries(
    permissions.permissions[`${resource}`]
  ) as unknown as Permissions[];

  // @ts-ignore
  return new Permission(resources);
}
