import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    monthBox: {
        width: "100%",
        background: "white",
        marginRight: "1em",
        height: '2em',
        "& .MuiSelect-select": {
            minWidth: '6em',
        },
    },
    yearBox: {
        width: "100%",
        height: '2em',
        background: "white",
        "& .MuiSelect-select": {
            minWidth: '4em',
        },
    }
}))