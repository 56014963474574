import { Button, Grid } from "@material-ui/core";
import { useRecoilState, useRecoilValue } from "recoil";
import { useStyles } from "../styles";
import PassportPhotos from "./PassportPhotos";
import PersonalPhotos from "./PersonalPhotos";
import { UploadsContext } from "../../../../../store/uploadsStore";
import {
  isUploadsViewEnabled,
  recruiteeBasicDetails,
} from "../../../../../recoil/atoms";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { documentDownloadAtom } from "../../../../../recoil/downloadAtoms";
import { UPLOAD_DOCUMENT_SIZE } from "../../../../../utils/constants";

export interface PhotosInterface {
  imageName: {
    passportPhoto: string;
    personalPhoto: string;
  };
  setImageName: Dispatch<
    SetStateAction<{
      passportPhoto: string;
      personalPhoto: string;
    }>
  >;
  imageSize: {
    passportPhoto: number;
    personalPhoto: number;
  };
  setImageSize: Dispatch<
    SetStateAction<{
      passportPhoto: number;
      personalPhoto: number;
    }>
  >;
  fileSetter: (file: File, type: string) => void;
  showToast: {
    error: (message: string) => void;
    success: (message: string) => void;
  };
}

interface PhotosProps {
  showToast: {
    error: (message: string) => void;
    success: (message: string) => void;
    warning: (message: string) => void;
  };
  getEndpoint: string;
  uploadDocsEndpointPatch: string;
}

export const FILE_TYPES = ["image/jpeg", "image/jpg", "image/png"];

const Photos = ({ showToast }: PhotosProps) => {
  const classes = useStyles();
  const { photos, setPhotos, handlePostPhotos } = useContext(UploadsContext);
  const isViewEnabled = useRecoilValue(isUploadsViewEnabled);
  const getOnboardingId = useRecoilValue(recruiteeBasicDetails);
  const [, setRewriteFileDownload] = useRecoilState(documentDownloadAtom);
  const uploadDocsEndpointPatch = `/api/on-boarding/upload-docs/${getOnboardingId.id}`;

  const [imageName, setImageName] = useState<{
    passportPhoto: string;
    personalPhoto: string;
  }>({
    passportPhoto: "",
    personalPhoto: "",
  });

  const [imageSize, setImageSize] = useState<{
    passportPhoto: number;
    personalPhoto: number;
  }>({
    passportPhoto: 0,
    personalPhoto: 0,
  });

  // functions
  const handleSubmit = () => {
    handlePostPhotos(showToast, uploadDocsEndpointPatch);
  };

  const fileSetter = (file: File, type: string) => {
    if (!FILE_TYPES.includes(file.type)) {
      showToast.error("Accepted File Types: JPG/JPEG/PNG!");
    } else if (!(file.size <= UPLOAD_DOCUMENT_SIZE)) {
      showToast.error("Accepted File Size: 1MB!");
    } else {
      setImageName((prev) => ({
        ...prev,
        [type]: file.name,
      }));

      setImageSize((prev) => ({
        ...prev,
        [type]: file.size,
      }));

      setPhotos([
        ...photos,
        {
          name: type,
          value: file,
        },
      ]);

      setRewriteFileDownload((prev) => ({
        ...prev,
        [type]: true,
      }));
    }
  };

  return (
    <Grid container item xs={12} spacing={3} style={{ paddingTop: "2em" }}>
      <Grid item xs={12} md={6} lg={6} xl={12}>
        <PassportPhotos
          imageName={imageName}
          setImageName={setImageName}
          imageSize={imageSize}
          setImageSize={setImageSize}
          showToast={showToast}
          fileSetter={fileSetter}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6} xl={12}>
        <PersonalPhotos
          imageName={imageName}
          setImageName={setImageName}
          imageSize={imageSize}
          setImageSize={setImageSize}
          showToast={showToast}
          fileSetter={fileSetter}
        />
      </Grid>

      <Grid xs={12} className={classes.submitDiv}>
        {isViewEnabled.photos ? (
          <Button className={classes.submitButtonDisabled}>Submit</Button>
        ) : (
          <Button className={classes.submitButton} onClick={handleSubmit}>
            Submit
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default Photos;
