import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
} from "@material-ui/core";
import { useStyles } from "./style";
import { HeadCell } from "../../types";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Projects } from "../../../../recoil/atoms";

interface Iprops {
  items: Projects[];
  headCells: HeadCell[];
  isLoading: boolean;
}

const ProjectsTable: React.FC<Iprops> = ({
  items = [],
  headCells,
  isLoading,
}) => {
  const classes = useStyles();
  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader} width="2%"></TableCell>
            {headCells.map((headCells) => (
              <TableCell
                height="40"
                key={headCells.id}
                width={
                  headCells.id === "name" || headCells.id === "status"
                    ? "15%"
                    : "10%"
                }
                align="left"
                className={classes.tableHeader}
              >
                <div
                  className={
                    headCells.id === "status"
                      ? classes.tableHeaderNoBorder
                      : classes.tableHeaderInside
                  }
                >
                  <span>{headCells.label}</span>
                  <span>{headCells.filter}</span>
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {isLoading ? (
          <div className={classes.loading}>
            <CircularProgress color="primary" />
          </div>
        ) : (
          <TableBody>
            {items.map((item: Projects, i: number) => (
              <TableRow className={classes.tableRow} key={item._id}>
                <TableCell></TableCell>
                <TableCell component="th" scope="row" align="left">
                  {item.projectName}
                </TableCell>
                <TableCell align="left">{item.projectType}</TableCell>
                <TableCell align="left">{item.clientName}</TableCell>
                <TableCell align="left">{item.clientEmail}</TableCell>
                <TableCell align="left">{item.orientation}</TableCell>
                <TableCell align="left">{item.projectStatus}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default ProjectsTable;
