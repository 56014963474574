import { atom } from "recoil";

export const downloadDocState = {
  personalPhoto: false,
  passportPhoto: false,
  // offerLetter: false,
  pan: false,
  aadhar: false,
  class10: false,
  class12: false,
  grad: false,
  postGrad: false,
  diploma: false,
  reliving: false,
  lastSalSlip: false,
  secondLastSalSlip: false,
  thirdLastSalSlip: false,
};

export const documentDownloadAtom = atom({
  key: "documentDownloadAtom",
  default: downloadDocState,
});
