import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { useRef, useState } from "react";
import KRACard from "../components/KRACard";
import { IAttribute } from "./BehavioralAttributes";
import { v4 as uuidv4 } from "uuid";
import { useFormik } from "formik";
import * as yup from "yup";
import { IDetailsProps } from "./BasicInfo";
import { useRecoilState, useRecoilValue } from "recoil";
import { formDataAtom, modeAtom } from "../../../recoil/kraAtom";
import { usePermissionObjectFor } from "../../../hooks/permission/usePermissionObjectFor";
import { useParams } from "react-router-dom";

export const initialTechnicalAttributes = [
  {
    id: "1",
    KRA: "Edit KRA",
    weight: 10,
    selfRating: 0,
    appraiserRating: 0,
  },
];

const TechnicalAttributes = ({ customCB }: IDetailsProps) => {
  // const [technicalAttributes, setTechnicalAttributes] = useState<IAttribute[]>(
  //   initialTechnicalAttributes
  // );

  const [formData, setFormData] = useRecoilState(formDataAtom);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const permissions = usePermissionObjectFor("kras");
  const hasCreatePermission = permissions.includes("create");
  const params = useParams<{ userId: string; kraId?: string }>();
  const mode = useRecoilValue(modeAtom);

  const deleteKraHandler = (idx: number) => {
    if (idx === 0) return;
    setValues((prev) => {
      let currentKRAs = [...prev];
      currentKRAs.splice(idx, 1);
      return currentKRAs;
    });
  };

  const addKraHandler = (idx: number) => {
    setValues((prev) => {
      let currentKRAs = [...prev];
      currentKRAs.push({
        id: uuidv4(),
        KRA: "",
        weight: 10,
        selfRating: 0,
        appraiserRating: 0,
      });
      return currentKRAs;
    });
  };

  const ValidationSchema = yup.array().of(
    yup.object().shape({
      id: yup.string(),
      KRA: yup.string().required("Required"),
      weight: yup.number(),
      selfRating: yup.number(),
      appraiserRating: yup.number(),
    })
  );

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
  } = useFormik({
    // initialValues: technicalAttributes,
    initialValues: formData.attributes.technical,
    validationSchema: ValidationSchema,
    validateOnChange: false,
    onSubmit: (vals) => {
      if (mode === "EDIT") {
        let isTechnicalRatingZero = vals.some(
          (curElem) => curElem.appraiserRating === 0
        );
        if (hasCreatePermission && isTechnicalRatingZero) {
          setOpenDialog(true);
          return;
        }
      }

      setFormData((prev) => ({
        ...prev,
        attributes: {
          ...prev.attributes,
          technical: vals,
        },
      }));

      customCB?.(2);
    },
  });

  const handleYes = () => {
    handleClose();
    setFormData((prev) => ({
      ...prev,
      attributes: {
        ...prev.attributes,
        technical: values,
      },
    }));

    customCB?.(2);
  };

  const handleClose = () => setOpenDialog(false);

  return (
    <Box style={{ display: "flex", flexDirection: "column" }}>
      {openDialog && (
        <Dialog open={openDialog} onClose={handleClose}>
          <DialogContent style={{ paddingTop: "2em" }}>
            <DialogContentText
              id="alert-dialog-description"
              style={{ color: "black" }}
            >
              Some KRA fields are rated as 0. Do you wish to continue ?
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ padding: "1em 2em" }}>
            <Button onClick={handleYes} color="primary" variant="contained">
              Yes
            </Button>
            <Button onClick={handleClose} color="primary" variant="outlined">
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <form onSubmit={handleSubmit} id="form2">
        {values.map((curElem, index) => (
          <div key={index}>
            <KRACard
              idx={index}
              kraData={curElem}
              kraError={errors}
              name={[
                `[${index}].KRA`,
                `[${index}].weight`,
                `[${index}].selfRating`,
                `[${index}].appraiserRating`,
              ]}
              handleChange={handleChange}
              addHandler={addKraHandler}
              deleteHandler={deleteKraHandler}
              setFieldValue={setFieldValue}
            />
          </div>
        ))}
      </form>
      {/* button */}
      {/* {values.length >= 5 && (
        <Button
          variant="contained"
          color="primary"
          style={{
            alignSelf: "flex-end",
            marginTop: "2em",
            fontSize: ".875em",
            fontWeight: 600,
            padding: ".6em 2em",
          }}
        >
          Save
        </Button>
      )} */}
    </Box>
  );
};

export default TechnicalAttributes;
