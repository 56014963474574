export const setFormikValues = (basicDetails: any, setFieldValue: any) => {
  setFieldValue("id", basicDetails.id);
  setFieldValue("name", basicDetails.name);
  setFieldValue("age", basicDetails.age ? basicDetails.age : "0");
  setFieldValue("gender", basicDetails.gender);
  setFieldValue("married", basicDetails.married);
  setFieldValue("bloodGroup", basicDetails.bloodGroup);
  setFieldValue("birthDate", basicDetails.birthDate);
  setFieldValue("emailID", basicDetails.emailID);
  setFieldValue("mobileNo", basicDetails.mobileNo);

  setFieldValue(
    "isPermanentAddressDifferent",
    basicDetails.isPermanentAddressDifferent
  );

  setFieldValue("idProofs.aadharNo", basicDetails?.idProofs?.aadharNo);
  setFieldValue("idProofs.panNo", basicDetails?.idProofs?.panNo);
  setFieldValue("idProofs.voterIdNo", basicDetails?.idProofs?.voterIdNo);
  setFieldValue("idProofs.passportNo", basicDetails?.idProofs?.passportNo);
  setFieldValue(
    "address.present.addressField",
    basicDetails.address.present.addressField
  );
  setFieldValue("address.present.city", basicDetails.address.present.city);
  setFieldValue("address.present.state", basicDetails.address.present.state);
  setFieldValue("address.present.pin", basicDetails.address.present.pin);
  setFieldValue(
    "address.permanent.addressField",
    basicDetails.address.permanent.addressField
  );
  setFieldValue("address.permanent.city", basicDetails.address.permanent.city);
  setFieldValue(
    "address.permanent.state",
    basicDetails.address.permanent.state
  );
  setFieldValue("address.permanent.pin", basicDetails.address.permanent.pin);
  setFieldValue("emergencyContact.name", basicDetails.emergencyContact.name);
  setFieldValue(
    "emergencyContact.relationship",
    basicDetails.emergencyContact.relationship
  );
  setFieldValue(
    "emergencyContact.contactNo",
    basicDetails.emergencyContact.contactNo
  );
};
