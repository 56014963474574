import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { FormikErrors } from "formik";
import moment from "moment";
import React, { useEffect } from "react";
import { kraStyles } from "../styles";
import { IBasicInfoForm } from "../sub-pages/BasicInfo";

const employees = [
  {
    id: 1,
    name: "Abhigyan Borah",
  },
  {
    id: 2,
    name: "Ayon Sarma",
  },
  {
    id: 3,
    name: "Durga Phukan",
  },
];

const quarters = [
  {
    id: 1,
    quarter: "Q1",
  },
  {
    id: 2,
    quarter: "Q2",
  },
  {
    id: 3,
    quarter: "Q3",
  },
  {
    id: 4,
    quarter: "Q4",
  },
];

interface IQuarterToMonths {
  [key: string]: string;
}

const quarterToMonths: IQuarterToMonths = {
  Q1: "Jan-Mar",
  Q2: "Apr-Jun",
  Q3: "Jul-Sep",
  Q4: "Oct-Dec",
};

interface IKRAForm {
  formData: IBasicInfoForm;
  formErrors: FormikErrors<IBasicInfoForm>;
  // setFormErrors: React.Dispatch<React.SetStateAction<IBasicInfoForm>>;

  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<IBasicInfoForm>>;
  handleChange: (
    event: React.ChangeEvent<
      | HTMLInputElement
      | HTMLSelectElement
      | HTMLTextAreaElement
      | { name?: string; value: unknown }
    >
  ) => void;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
}

// export const getMonthRange = (quarter: string) =>
//   quarters.find((curElem) => curElem.quarter === quarter)?.months;

const KRAForm = ({
  formData,
  formErrors,
  // setFormErrors,
  setFieldValue,
  handleChange,
  handleSubmit,
}: IKRAForm) => {
  useEffect(() => {
    if (formData.quarter) {
      setFieldValue("months", quarterToMonths[formData.quarter]);
    } else {
      setFieldValue("months", "");
    }
  }, [formData.quarter]);

  const classes = kraStyles();

  return (
    <Box
      style={{
        border: "1px solid rgba(20, 0, 71, 0.25)",
        borderRadius: "10px",
        padding: "3em",
        marginTop: ".7em",
      }}
    >
      <form onSubmit={handleSubmit} id="form1">
        <Grid container>
          {/* 1st row */}
          <Grid item container xs={12} style={{ padding: "0 3em" }}>
            <Grid item xs={5}>
              <Box
                sx={{
                  display: "flex",
                  // border: "1px solid red",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  style={{ fontSize: ".8em", fontWeight: 600 }}
                >
                  Assigned to*
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    width: "60%",
                    // border: "1px solid red",
                  }}
                >
                  <TextField
                    inputProps={{ style: { fontSize: ".8em" } }}
                    variant="outlined"
                    fullWidth
                    size="small"
                    name="empId"
                    value={formData.empId}
                    onChange={handleChange}
                    disabled
                  />
                  <span className={classes.spanError}>{formErrors.empId}</span>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={5}>
              <Box
                sx={{
                  display: "flex",
                  // border: "1px solid red",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  style={{ fontSize: ".8em", fontWeight: 600 }}
                >
                  Date created*
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    // border: "1px solid red",
                    width: "60%",
                  }}
                >
                  <TextField
                    // style={{ width: "60%" }}
                    fullWidth
                    variant="outlined"
                    size="small"
                    type="date"
                    // inputProps={{
                    //   min: new Date().toISOString().substring(0, 10),
                    // }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="date"
                    value={moment(formData?.date).format("YYYY-MM-DD")}
                    onChange={handleChange}
                    disabled
                  />
                  <span className={classes.spanError}>{formErrors.date}</span>
                </Box>
              </Box>
            </Grid>
          </Grid>

          {/*2nd row  */}
          <Grid
            container
            item
            xs={12}
            style={{ marginTop: "2em", padding: "0 3em" }}
          >
            <Grid item xs={5}>
              <Box
                sx={{
                  display: "flex",
                  // border: "1px solid red",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  style={{ fontSize: ".8em", fontWeight: 600 }}
                >
                  For quarter
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    width: "60%",
                    // border: "1px solid red",
                  }}
                >
                  <TextField
                    inputProps={{ style: { fontSize: ".8em" } }}
                    variant="outlined"
                    fullWidth
                    size="small"
                    name="quarter"
                    // defaultValue="Q1"
                    value={formData?.quarter}
                    onChange={handleChange}
                    disabled
                  />
                  {/* <Select
                    variant="outlined"
                    style={{ height: "2.3em" }}
                    fullWidth
                    name="quarter"
                    value={formData.quarter}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {quarters.map((curElem) => (
                      <MenuItem value={curElem.quarter}>
                        {curElem.quarter}
                      </MenuItem>
                    ))}
                  </Select> */}
                  <span className={classes.spanError}>
                    {formErrors.quarter}
                  </span>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={5}>
              <Box
                sx={{
                  display: "flex",

                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  style={{
                    fontSize: ".8em",
                    fontWeight: 600,
                  }}
                >
                  Months*
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    width: "60%",
                    // border: "1px solid red",
                  }}
                >
                  <TextField
                    // style={{ width: "60%" }}
                    fullWidth
                    inputProps={{ style: { fontSize: ".8em" } }}
                    variant="outlined"
                    size="small"
                    name="months"
                    // value={getMonthRange(formData.quarter)}
                    value={formData.months}
                    onChange={handleChange}
                    disabled
                  />
                  <span className={classes.spanError}>{formErrors.months}</span>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {/* <Button variant="contained" type="submit">
          Save
        </Button> */}
      </form>
    </Box>
  );
};

export default KRAForm;

/* 
textfield
date
dropdown
disabled field
*/
