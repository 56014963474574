import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Box from "@material-ui/core/Box";
import { useStyles } from "./styles";

interface IProps {
  label: string;
  name: string;
  value: string;
  radionButtons: {
    label: string;
    value: string;
  }[];
  handleChange:
    | ((event: React.ChangeEvent<HTMLInputElement>) => void)
    | undefined;
}

const RadioButton: React.FC<IProps> = ({
  label = "",
  name = "",
  value = "",
  radionButtons = [],
  handleChange,
}) => {
  const classes = useStyles();

  return (
    <FormControl component="fieldset" >
      <FormLabel component="legend" className={classes.label}>
        {label}
      </FormLabel>
      <RadioGroup
        aria-label={name}
        name={name}
        value={value}
        onChange={handleChange}
      >
        <Box display="flex">
          {radionButtons.map((radio, index) => (
            <FormControlLabel
              key={index}
              classes={{
                root: classes.radioBtn,
                label:
                  value === radio.value
                    ? classes.inputLabelChecked
                    : classes.inputLabel,
              }}
              value={radio.value}
              control={<Radio color="primary" />}
              label={radio.label}
            />
          ))}
        </Box>
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButton;
