import React, { useContext, useEffect, useState } from "react";
import { Button, CircularProgress, Grid, IconButton, Typography } from "@material-ui/core";
import Tables from "../../Tables";
import {
  AddedContent,
  contentData,
  HeadCell,
  ProjectContents,
} from "../../../types";
import { useStyles } from "../style";
import {
  addContents,
  deleteContent,
  fetchAllContents,
  getAllProjectContents,
} from "../../../../../apis/projects";
import { Toast } from "../../../ProjectsPage";
import DescriptionIcon from "@material-ui/icons/Description";
import { Link, useParams } from "react-router-dom";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { capitalizeString } from "../../../../../utils/functions";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import Search from "../../Search";
import useDebounce from "../../../../../hooks/useDebounce";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CheckIcon from "@material-ui/icons/Check";
import Pagination from "@material-ui/lab/Pagination";
import { usePermissionObjectFor } from "../../../../../hooks/permission/usePermissionObjectFor";

const limit = 5;

interface Iprops {}
const ContentsTab: React.FC<Iprops> = () => {
  const classes = useStyles();
  const [allContent, setAllContents] = useState<ProjectContents[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const showToast = useContext(Toast);
  const [searchContents, setSearchContent] = useState<boolean>(false);
  const [searchAllContentQuery, setSearchAllContentQuery] =
    useState<string>("");
  const allContentDebouncedValue = useDebounce(searchAllContentQuery, 1000);
  const [showContentList, setShowContentList] = useState<boolean>(false);
  const [addedContent, setAddedContent] = useState<AddedContent[]>([]);
  const [pageNo, setPageNo] = useState<number>(1);
  const [totalData, setTotaldata] = useState<number>(0);
  const { id } = useParams<any>();
  const permission = usePermissionObjectFor("incidents");
  const hasUpdateAccess = permission.required("upDate");

  const headCells: HeadCell[] = [
    {
      id: "title",
      disablePadding: true,
      label: "File Name",
      width: "60%",
    },
    {
      id: "category",
      disablePadding: false,
      label: "Category",
      width: "20%",
    },

    {
      id: "key",
      disablePadding: false,
      label: "Origin",
      width: "20%",
    },
  ];

  useEffect(() => {
    fetch();
  }, [allContentDebouncedValue, pageNo]);

  async function fetch() {
    try {
      let response = await fetchAllContents(
        searchAllContentQuery,
        pageNo,
        limit
      );
      setAllContents(response?.data?.data);
      setTotaldata(response?.data?.total);

      let projectContents = await getAllProjectContents(id);
      let tempItem: any = [];
      projectContents?.data?.forEach(
        ({ _id, model, projectId, content }: contentData) => {
          let tempObjUser = {
            contentId: _id,
            key: model,
            projectId: projectId,
            keyId: content._id,
            title: content.title,
            category:
              model === "khubposts" ? content.category : content.contentArea,
            url: model === "khubposts" ? "" : content.file,
          };
          tempItem.push(tempObjUser);
        }
      );
      setAddedContent(tempItem);
    } catch (err: any) {
      showToast.error(err?.response?.data?.message);
    }
  }

  const AddedItems = allContent
    .filter((contentlist) =>
      addedContent.some((addeditem) => addeditem.keyId === contentlist._id)
    )
    .map((match) => match._id);

  const showTitles = (_id: string, key: string, title: string, url: string) => {
    return (
      <div className={classes.titleContainer}>
        <DescriptionIcon />
        {key === "khubposts" ? (
          <>
            <Link to={`/app/knowledgeHub/post/${_id}`} className={classes.link}>
              {title}
            </Link>
          </>
        ) : (
          <>
            <a href={url} className={classes.link}>
              {title}
            </a>
          </>
        )}
      </div>
    );
  };

  const addContentHandler = async (keyId: string, origin: string) => {
    setIsLoading(true);
    try {
      await addContents(id, { keyId, origin });
      setIsLoading(false);
      showToast.success("Successfully Added!");
      fetch();
    } catch (err: any) {
      showToast.error(err.response.data.message);
      fetch();
      setIsLoading(false);
    }
  };

  const removeContentHandler = async (contentId: string) => {
    setIsLoading(true);
    try {
      await deleteContent(contentId);
      fetch();
      setIsLoading(false);
      showToast.success("Content Removed!");
    } catch (err: any) {
      showToast.error(err.response.data.message);
      fetch();
      setIsLoading(false);
    }
  };

  const showOrigin = (
    keyId: string,
    key: string,
    from: string,
    contentId: string
  ) => {
    const addedItem = AddedItems.includes(keyId);
    return (
      <div className={classes.originContainer}>
        <Typography className={classes.originText}>
          {key === "khubposts" ? "Knowledge-Hub" : "Content-Basket"}
        </Typography>
        {hasUpdateAccess && (
          <>
            {from === "addedContents" ? (
              <IconButton
                className={classes.addIcon}
                onClick={() => removeContentHandler(contentId)}
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
            ) : addedItem ? (
              <IconButton className={classes.addIcon} disabled>
                <CheckIcon />
              </IconButton>
            ) : (
              <IconButton
                className={classes.addIcon}
                onClick={() => addContentHandler(keyId, key)}
              >
                <AddCircleIcon />
              </IconButton>
            )}
          </>
        )}
      </div>
    );
  };

  const showContentItems = () => {
    return allContent.map((items: ProjectContents) => ({
      title: showTitles(items._id, items.key, items.title, items.url),
      category: items.category
        ? capitalizeString(items.category.replace(/_/, " "))
        : "",
      key: showOrigin(items._id, items.key, "allContents", ""),
    }));
  };

  const contentListHandler = () => {
    setShowContentList(true);
  };

  const addedcontentListHandler = () => {
    setShowContentList(false);
  };

  const showAddedContents = () => {
    return addedContent.map((items: AddedContent) => ({
      title: showTitles(items.keyId, items.key, items.title, items.url),
      category: capitalizeString(items.category.replace(/_/, " ")),
      key: showOrigin(items.keyId, items.key, "addedContents", items.contentId),
    }));
  };

  function PageCount(totalLength: number) {
    let totalPage = Math.ceil(totalLength / limit);
    return totalPage === 0 ? 1 : totalPage;
  }

  const paginateHandler = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageNo(value);
  };

  const allContents = showContentItems();
  const contentsAdded = showAddedContents();
  return (
    <Grid container justifyContent="flex-start" xs={12}>
      <Grid container item xs={12} justifyContent="space-between">
        {showContentList ? (
          <>
            <Grid item xs={5}>
              <Search
                setSearchActive={setSearchContent}
                setQuery={setSearchAllContentQuery}
              />
            </Grid>
            <Grid item xs={6} className={classes.buttonContainer}>
              <Button
                variant="outlined"
                color="default"
                onClick={addedcontentListHandler}
                startIcon={<ArrowBackIcon />}
              >
                Fetch Added Contents
              </Button>
            </Grid>
          </>
        ) : (
          <Grid item xs={12} className={classes.allContentContainer}>
            <Button
              variant="outlined"
              color="default"
              onClick={contentListHandler}
              className={classes.allContentButton}
              endIcon={
                <ArrowBackIcon fontSize="small" className={classes.arrowIcon} />
              }
            >
              Fetch All Contents
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        {isLoading ? (
          <div className={classes.loading}>
            <CircularProgress color="primary" />
          </div>
        ) : (
          <Tables
            headCells={headCells}
            items={showContentList ? allContents : contentsAdded}
          />
        )}
      </Grid>
      {showContentList && (
        <Grid item xs={12} className={classes.contentPagination}>
          <Pagination
            size="small"
            page={pageNo}
            showFirstButton
            showLastButton
            defaultPage={pageNo}
            onChange={paginateHandler}
            count={PageCount(totalData)}
          />
        </Grid>
      )}
    </Grid>
  );
};
export default ContentsTab;
