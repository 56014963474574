import { IKraAPI } from "../pages/KRAPage/types/KRA.interface";
import { IAttributes, IFormData } from "../recoil/kraAtom";
import axios from "./axios.global";

const BASE_URL = "kra";

//GET ALL KRA
export const getAllKraListAPI = async (filter: string) => {
  return await axios.get(`${BASE_URL}?${filter}`);
};

//CREATE KRA
export const createEmployeeKraAPI = async (id: string, payload: IFormData) => {
  const payloadObj = { ...payload, empId: id };
  return await axios.post(`${BASE_URL}/`, payloadObj);
};

//TEAM MEMBERS + GRAPH
export const getTeamMembersAPI = async () => {
  return await axios.get(`${BASE_URL}/get-team-members`);
};

//KRA REPORT
export const getEmployeeKraAPI = async (kraId: string) => {
  // return await axios.get(`${BASE_URL}/getemployee-kra/:id`)
  return await axios.get<IKraAPI>(`${BASE_URL}/report/${kraId}`);
};

//UPDATE KRA
export const patchEmployeeKraAPI = async (
  kraId: string,
  payload: IAttributes
) => {
  return await axios.patch(`${BASE_URL}/${kraId}`, payload);
};

//EMPLOYEE KRA HISTORY
export const getEmployeeKraHistoryAPI = async (empId: string) => {
  return await axios.get(`${BASE_URL}/history/${empId}`);
};

//APPROVE KRA
export const approveKra = async (kraId: string) => {
  return await axios.patch(`${BASE_URL}/approve/${kraId}`);
};

//employee view: get employee KRA
export const getEmployeeKraListAPI = async () => {
  return await axios.get(`${BASE_URL}/employee-kra-list`);
};

export const getEmployeeHistoryGraph = (id: string) =>
  axios.get<IGraphData>(`${BASE_URL}/history-graph/${id}`);

export interface IGraphData {
  empId: string;
  graphData: number[];
  graphLabels: string[];
}
