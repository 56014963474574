import axios from "./axios.global";
import { RequestedLeave } from "../pages/LeavesPage/types";

const BASE_URL = "leave-application";

export const leaveTypes = async () => {
  try {
    let response = await axios.get("/leave-settings");
    return response;
  } catch (err: any) {
    return err.response.data;
  }
};

export const leaveRecords = async () => {
  try {
    let response = await axios.get("/leave-record");
    return response;
  } catch (err: any) {
    return err.response.data;
  }
};

export const leaveRequest = async (payload: object) => {
  return await axios.post("/leave-application/request", payload);
};

export const leaveApply = async (payload: RequestedLeave) => {
  return await axios.post("/leave-application/apply", payload);
};
export const allLeaves = async (pageNo: number,limit?:number,searchString?:string,sortTag?:string) => {
  try {
    let response = await axios.get(`${BASE_URL}/records`,{
      params:{
        page:pageNo,
        limit:limit ?? 10,
        emp_name:searchString ?? "",
        leave_type:sortTag ?? "",
      }
    });
    return response;
  } catch (err: any) {
    return err.response.data;
  }
};

export const allLeavesPerLimit = async (limit: number) => {
  try {
    let response = await axios.get(`${BASE_URL}/record?limit=${limit}`);
    return response;
  } catch (err: any) {
    return err.response.data;
  }
};

export const onLeave = async () => {
  try {
    let response = await axios.get("/leave-application/users/on-leave-today");
    return response;
  } catch (err: any) {
    return err.response.data;
  }
};

export const userLeave = async () => {
  try {
    let response = await axios.get("/leave-application/record/user");
    return response;
  } catch (err: any) {
    return err.response.data;
  }
};

export const leaveApprove = async (id: string) => {
  try {
    let response = await axios.patch(`/leave-application/approve-leave/${id}`);
    return response;
  } catch (err: any) {
    return err.response.data;
  }
};
export const cancelTheRequest = async (applicationID: string) => {
  return await axios.patch(`/leave-application/cancel/${applicationID}`);
};

export const leaveReject = async (id: string, reason: string) => {
  return await axios.post(`/leave-application/reject/${id}`, { reason });
};

export const leaveDelete = async (id: string) => {
  return await axios.delete(`/leave-application/del/${id}`);
};

export const cancelRequest = async (id: string, reason: string) => {
  return await axios.post(`/leave-application/cancel-request/${id}`, {
    reason,
  });
};

export const getAllCancelRequest = async () => {
  return await axios.get(`/leave-application/cancelled`);
};

export const getUserLeaveBalance = async (userId: string) => {
  return await axios.get(`/employees/${userId}/leaves`);
};
export const getAllLeaveRequests = async () => {
  return await axios.get(`/leave-application/to-approve`);
};

export const assigneeLeaves = async (payload: object) => {
  try {
    let response = await axios.get(
      "/leave-application/assignedto/applications",
      payload
    );
    return response;
  } catch (err: any) {
    return err.response.data;
  }
};

// get leave data by employee ID
export const getLeaveById = async (
  empId: string,
  pageNo: number,
  limit: number,
  leave_type?: string,
  sort_type?: string
) => {
  let leaveType = ``;
  if (leave_type === undefined) {
    leaveType = ``;
  } else {
    leaveType = leave_type;
  }

  try {
    const response = await axios.get(
      `/leave-application/employee?emp_id=${empId}&page=${pageNo}&limit=${limit}&leave_type=${leaveType}&sort=${sort_type}`
    );
    return response;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getEmployeeLeaveById = async (empId: string) => {
  try {
    const data = await axios.get(`/employees/${empId}/leaves`);
    return data.data;
  } catch (error: any) {
    return error.response.data;
  }
};
