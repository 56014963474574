import { SetterOrUpdater } from "recoil";
import { emailRegex, phoneRegex } from "../../utils/constants";
import { recruiteeDetailsProps } from "./NewRecruits";

interface setDetailsProps {
  recruiteeDetails: {
    designation: string;
    emailID: string;
    name: string;
    mobileNo: number;
    status: string;
    _id: string;
  };
  setBasicDetails: any;
  setEmployeeInfo: SetterOrUpdater<{
    employeeNo: number;
    status: boolean;
    department: string;
    designation: string;
    role: string;
    assignedTo: string;
    // department: {
    //   id: string;
    //   label: string;
    // };
    // designation: {
    //   id: string;
    //   label: string;
    // };
    // role: {
    //   id: string;
    //   label: string;
    // };
    // assignedTo: {
    //   id: string;
    //   label: string;
    // };
  }>;
}

export const handleRecruiteeForm = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  setRecruiteeDetails: React.Dispatch<
    React.SetStateAction<{} | recruiteeDetailsProps>
  >,
  setIsValidFields: React.Dispatch<React.SetStateAction<boolean>>,
  setIsValidEmail: React.Dispatch<React.SetStateAction<boolean>>,
  setIsValidPhoneNo: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const handlers: any = {
    employeeName: () => {
      if (event.target.value !== "") {
        setIsValidFields(true);
        setRecruiteeDetails((prev) => ({
          ...prev,
          employeeName: event.target.value,
        }));
      } else {
        setIsValidFields(false);
      }
    },
    employeeEmailId: () => {
      if (event.target.value !== "" && event.target.value.match(emailRegex)) {
        setIsValidEmail(true);
        setRecruiteeDetails((prev) => ({
          ...prev,
          employeeEmail: event.target.value,
        }));
      } else {
        setIsValidEmail(false);
      }
    },
    employeePhoneNumber: () => {
      const phone = +event.target.value;

      if (
        event.target.value !== "" &&
        typeof phone === "number" &&
        phoneRegex.test(event.target.value)
      ) {
        setIsValidPhoneNo(true);
        setRecruiteeDetails((prev) => ({
          ...prev,
          employeePhoneNumber: phone,
        }));
      } else {
        setIsValidPhoneNo(false);
      }
    },
    employeeDesignation: () => {
      if (event.target.value !== "") {
        setIsValidFields(true);
        setRecruiteeDetails((prev) => ({
          ...prev,
          employeeDesignation: event.target.value,
        }));
      } else {
        setIsValidFields(false);
      }
    },
  };
  return handlers;
};

export const setDetails = ({
  recruiteeDetails,
  setBasicDetails,
  setEmployeeInfo,
}: setDetailsProps) => {
  setBasicDetails((prev: any) => ({
    ...prev,
    name: recruiteeDetails.name,
    emailID: recruiteeDetails.emailID,
    mobileNo: recruiteeDetails.mobileNo,
    id: recruiteeDetails._id,
  }));

  setEmployeeInfo((prev) => ({
    ...prev,
    designation: recruiteeDetails.designation,
  }));
};
