import React from "react";
import { Box, Chip, Grid, Typography } from "@material-ui/core";
import WatchLaterOutlinedIcon from "@material-ui/icons/WatchLaterOutlined";
import moment from "moment";
import { IHistoryShredded } from "../attendance.interface";

const AttendanceCard = ({ item }: { item: IHistoryShredded }) => {
  return (
    <Box
      style={{
        padding: "1em",
        background: "#f5f5f5",
        borderRadius: "6px",
        boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 1px 3px rgba(0,0,0,0.23)",
        minHeight: "150px",
      }}
    >
      {/* header */}
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1em",
        }}
      >
        <Box style={{ display: "flex", alignItems: "center" }}>
          <WatchLaterOutlinedIcon style={{ marginRight: ".3em" }} />
          <Typography variant="body1" color="initial">
            {moment(item.date).date() +
              " " +
              moment(item.date).format("MMMM").substring(0, 3) +
              " " +
              +moment(item.date).year()}
          </Typography>
        </Box>

        {/* {item.leaveApplied ? (
          <Chip
            style={{
              background: "lightgray",
              color: "darkgray",
              fontWeight: "600",
            }}
            label="Absent"
          />
        ) : (
          <Chip
            style={{
              background: "lightgreen",
              color: "darkgreen",
              fontWeight: "600",
            }}
            label="On Time"
          />
        )} */}
        <Chip
          style={{
            background: "lightgreen",
            color: "darkgreen",
            fontWeight: "600",
          }}
          label={moment(item.date).format("dddd")}
        />
      </Box>

      {item.inTime !== null && item.outTime !== null ? (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography style={{ fontWeight: "600" }}>Check In Time</Typography>
            <Typography>{moment(item.inTime).format("LT")}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography style={{ fontWeight: "600" }}>
              Check Out Time
            </Typography>
            <Typography>{moment(item.outTime).format("LT")}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography style={{ fontWeight: "600" }}>Break Time</Typography>
            <Typography>{item.breakTime}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography style={{ fontWeight: "600" }}>Active Hours</Typography>
            <Typography>{item.activeHour}</Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              color="textSecondary"
              align="center"
              style={{
                fontWeight: "600",
                padding: "24px",
                fontFamily: "monospace",
              }}
            >
              ABSENT
            </Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default AttendanceCard;
