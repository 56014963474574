import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.typography.pxToRem(14),
    },
    statusdivcomplete: {
        display: "flex",
        alignItems: "center",
        padding: ".5rem",
        background: " #EBFFF1",
        borderRadius: "9px",
        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "12px",
        lineHeight: "24px",
        color: " #119C2B",

    },
    statusdivongoing: {
        display: "flex",
        alignItems: "center",
        padding: ".5rem",
        background: "#FEF4EB",
        borderRadius: "9px",
        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "12px",
        lineHeight: "24px",
        color: "#E08530",


    },
    statusicon: {
        marginRight: ".3rem",

    },


    footer: {
        display: "flex",
        justifyContent: "end",
        paddingTop: "1.5rem",
    },
    statusroot: {
        display: "flex",
        justifyContent: "space-between",
        marginRight: "2rem",

    },

}));
