import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./style";
import CircularProgress from "@material-ui/core/CircularProgress";
import { HeadCell} from "../../types";

interface Iprops {
  items: Array<Object>;
  headCells: HeadCell[];
  isLoading?: boolean;
  [key: string]: any
}

const Tables: React.FC<Iprops> = ({ items, headCells, isLoading }) => {
  const classes = useStyles();
  const lastItem = headCells.length - 1;
  return (
    <TableContainer component={Paper} className={classes.table} >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader} width="2%"></TableCell>
            {headCells.map((headCells, index) => (
              <TableCell
                height="40"
                align="left"
                className={classes.tableHeader}
                width={headCells.width}
              >
                <div
                  className={
                    index === lastItem
                      ? classes.tableHeaderNoBorder
                      : classes.tableHeaderInside
                  }
                >
                  <span>{headCells.label}</span>
                  <span>{headCells?.filter}</span>
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.length === 0 || items === undefined ? (
            <TableRow className={classes.tableRow}>
              <TableCell></TableCell>
              <Typography className={classes.innerText}>No data</Typography>
            </TableRow>
          ) : (
            items?.map((item: any, i: number) => {
              return (
                <TableRow className={classes.tableRow} key={i}>
                  <TableCell></TableCell>
                  {Object.values(item).map((row,i) => (
                    <TableCell key={i} component="th" scope="row" align="left">
                      {row}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Tables;
