import { makeStyles } from "@material-ui/core";
import { theme } from "../../../theme/theme";

const useStyles = makeStyles({
  textfield: {
    width: "100%",
  },
  file: {
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(10)}`,
    background: "#F9F9F9",
    border: "1px solid rgba(0, 0, 0, 0.25)",
    borderRadius: "3px",
  },
  input: {
    '&input[type="date"]:before': {
      content: "attr(placeholder) !important",
      color: "#aaa",
      marginRight: "0.5em",
    },
    '&input[type="date"]:focus': "before",
    '&input[type="date"]:valid:before': {
      content: "",
    },
  },
});

export default useStyles;
