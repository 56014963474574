import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import TextField from "../../Fields/TextField";
import SelectField from "../../Fields/SelectField";
import { subYears } from "date-fns";
import clsx from "clsx";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import moment from "moment";
import ValidationError from "../ValidationError";
import AttachmentSection from "./AttachmentSection";
import { useStyles } from "./styles";
import Button from "@material-ui/core/Button";
import { FileCopy } from "@material-ui/icons";

interface IProps {
  handleSubmit: any;
  handleChange: any;
  handleInputAssetChange: any;
  assetList: any;
  uploadedFiles: any;
  setUploadedFiles: any;
  roles: object[];
  designations: object[];
  departments: object[];
  profileImage: any;
  showToast: any;
  setProfileImage: any;
  assignedToArr: string[];
  handleBlur: any;
  setAssignedToArr: any;
  touched: any;
  assignedToUsers: any;
  userId: string;
  ownershipList: any;
  currentUserId?: any;
  handleAddField: () => void;
  values: {
    [key: string]: string;
  };
  errors: {
    [key: string]: any;
  };
}

const EmployeesAddForm: React.FC<IProps> = ({
  handleSubmit,
  handleChange,
  values,
  handleInputAssetChange,
  profileImage,
  setProfileImage,
  handleAddField,
  assetList,
  showToast,
  uploadedFiles,
  setUploadedFiles,
  roles,
  designations,
  errors,
  departments,
  userId,
  assignedToArr,
  setAssignedToArr,
  touched,
  handleBlur,
  ownershipList,
  assignedToUsers,
  currentUserId,
}) => {
  const classes = useStyles();
  const [assignedDropdown, setAssignedDropdown] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const handleAssignedDropdown = () => {
    setAssignedDropdown(!assignedDropdown);
  };

  useEffect(() => {}, [refresh]);

  const refreshData = () => {
    setRefresh(!refresh);
  };

  // console.log("assign", moment(subYears(new Date(), 16)).format("YYYY-MM-DD"));

  return (
    <Grid container className={classes.mainContainer}>
      <Grid container xs={8}>
        <form
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSubmit();
            }
          }}
        >
          <Grid
            justify="space-between"
            container
            className={classes.formContainer}
          >
            <Grid xs={5} className={classes.fieldontainer}>
              <TextField
                variant="standard"
                onChange={handleChange}
                onBlur={handleBlur}
                name="fullname"
                value={values.fullname}
                label="Full name"
                type=""
              />
              <div className="text-danger">
                {touched.fullname && errors.fullname ? (
                  <ValidationError message={errors.fullname} />
                ) : null}
              </div>
            </Grid>
            <Grid xs={5} className={classes.fieldontainer}>
              <TextField
                onChange={handleChange}
                variant="standard"
                label="Date of birth"
                onBlur={handleBlur}
                InputProps={{
                  inputProps: {
                    max: moment(subYears(new Date(), 16)).format("YYYY-MM-DD"),
                  },
                }}
                type="date"
                name="dateOfBirth"
                value={values.dateOfBirth}
                InputLabelProps={{ shrink: true }}
              />
              <div className="text-danger">
                {touched.dateOfBirth && errors.dateOfBirth ? (
                  <ValidationError message={errors.dateOfBirth} />
                ) : null}
              </div>
            </Grid>
            <Grid xs={5} className={classes.fieldontainer}>
              <TextField
                variant="standard"
                onChange={handleChange}
                label="Emp Number"
                onBlur={handleBlur}
                name="empNo"
                value={values.empNo}
                type=""
              />
              <div className="text-danger">
                {touched.empNo && errors.empNo ? (
                  <ValidationError message={errors.empNo} />
                ) : null}
              </div>
            </Grid>
            <Grid xs={5} className={classes.fieldontainer}>
              <TextField
                onChange={handleChange}
                variant="standard"
                label="Date of joining"
                onBlur={handleBlur}
                InputLabelProps={{ shrink: true }}
                name="dateOfJoining"
                value={values.dateOfJoining}
                type="date"
              />
              <div className="text-danger">
                {touched.dateOfJoining && errors.dateOfJoining ? (
                  <ValidationError message={errors.dateOfJoining} />
                ) : null}
              </div>
            </Grid>
            <Grid xs={5} className={classes.fieldontainer}>
              <SelectField
                options={["male", "female"]}
                onSelect={handleChange}
                value={values.gender}
                onBlur={handleBlur}
                name="gender"
                type="form"
                label="Gender"
              />
              <div className="text-danger">
                {touched.gender && errors.gender ? (
                  <ValidationError message={errors.gender} />
                ) : null}
              </div>
            </Grid>
            <Grid xs={5} className={classes.fieldontainer}>
              <SelectField
                options={designations}
                onSelect={handleChange}
                value={values.designation}
                name="designation"
                onBlur={handleBlur}
                type="form"
                label="Designation"
                labelIdentifier="designationName"
                valueIdentifier="_id"
              />
              <div className="text-danger">
                {touched.designation && errors.designation ? (
                  <ValidationError message={errors.designation} />
                ) : null}
              </div>
            </Grid>
            <Grid xs={5} className={classes.fieldontainer}>
              <TextField
                onChange={handleChange}
                name="contactNo"
                value={values.contactNo}
                onBlur={handleBlur}
                variant="standard"
                type=" "
                label="Contact number"
              />
              <div className="text-danger">
                {touched.contactNo && errors.contactNo ? (
                  <ValidationError message={errors.contactNo} />
                ) : null}
              </div>
            </Grid>
            <Grid xs={5} className={classes.fieldontainer}>
              <TextField
                onChange={handleChange}
                variant="standard"
                name="qualification"
                onBlur={handleBlur}
                value={values.qualification}
                type=""
                label="Qualification"
              />
              <div className="text-danger">
                {touched.qualification && errors.qualification ? (
                  <ValidationError message={errors.qualification} />
                ) : null}
              </div>
            </Grid>
            <Grid xs={5} className={classes.fieldontainer}>
              <TextField
                variant="standard"
                name="officialEmailId"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.officialEmailId}
                type=""
                label="Official Email Address"
              />
              <div className="text-danger">
                {touched.officialEmailId && errors.officialEmailId ? (
                  <ValidationError message={errors.officialEmailId} />
                ) : null}
              </div>
            </Grid>
            <Grid xs={5} className={classes.fieldontainer}>
              <TextField
                name="personalEmailId"
                onChange={handleChange}
                value={values.personalEmailId}
                onBlur={handleBlur}
                variant="standard"
                type=""
                label="Personal Email Address"
              />
              <div className="text-danger">
                {touched.personalEmailId && errors.personalEmailId ? (
                  <ValidationError message={errors.personalEmailId} />
                ) : null}
              </div>
            </Grid>
            <Grid xs={5} className={classes.fieldontainer}>
              <TextField
                name="permanentAddress"
                onChange={handleChange}
                value={values.permanentAddress}
                onBlur={handleBlur}
                variant="standard"
                type=""
                label="Permanent Address"
              />
              <div className="text-danger">
                {touched.permanentAddress && errors.permanentAddress ? (
                  <ValidationError message={errors.permanentAddress} />
                ) : null}
              </div>
            </Grid>
            <Grid xs={5} className={classes.fieldontainer}>
              <TextField
                name="presentAddress"
                onChange={handleChange}
                value={values.presentAddress}
                onBlur={handleBlur}
                variant="standard"
                type=""
                label="Present Address"
                InputProps={{
                  endAdornment: (
                    <div className={classes.sameAsPermanentAddress}>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          values.presentAddress = values.permanentAddress;
                          refreshData();
                        }}
                      >
                        <FileCopy />
                      </Button>
                    </div>
                  ),
                }}
              />
              <div className="text-danger">
                {touched.presentAddress && errors.presentAddress ? (
                  <ValidationError message={errors.presentAddress} />
                ) : null}
              </div>
            </Grid>
            <Grid xs={5} className={classes.fieldontainer}>
              <TextField
                name="fatherName"
                onChange={handleChange}
                value={values.fatherName}
                variant="standard"
                onBlur={handleBlur}
                type=""
                label="Father's name"
              />
              <div className="text-danger">
                {touched.fatherName && errors.fatherName ? (
                  <ValidationError message={errors.fatherName} />
                ) : null}
              </div>
            </Grid>
            <Grid xs={5} className={classes.fieldontainer}>
              <TextField
                name="motherName"
                onChange={handleChange}
                value={values.motherName}
                onBlur={handleBlur}
                variant="standard"
                type=""
                label="Mother's name"
              />
              <div className="text-danger">
                {touched.motherName && errors.motherName ? (
                  <ValidationError message={errors.motherName} />
                ) : null}
              </div>
            </Grid>
            <Grid xs={5} className={classes.fieldontainer}>
              <TextField
                name="workExperience"
                onChange={handleChange}
                value={values.workExperience}
                onBlur={handleBlur}
                variant="standard"
                type=""
                label="Work Experience"
              />
              <div className="text-danger">
                {touched.workExperience && errors.workExperience ? (
                  <ValidationError message={errors.workExperience} />
                ) : null}
              </div>
            </Grid>
            <Grid xs={5} className={classes.fieldontainer}>
              <SelectField
                options={["O+", "O-", "A+", "A-", "B+", "B-", "AB+", "AB-"]}
                name="bloodGroup"
                onSelect={handleChange}
                value={values.bloodGroup}
                onBlur={handleBlur}
                type="form"
                label="Blood Group"
              />
              <div className="text-danger">
                {touched.bloodGroup && errors.bloodGroup ? (
                  <ValidationError message={errors.bloodGroup} />
                ) : null}
              </div>
            </Grid>
            <Grid xs={5} className={classes.fieldontainer}>
              <div onClick={handleAssignedDropdown}>
                <SelectField
                  options={assignedToUsers}
                  onSelect={handleChange}
                  value={values.assignedTo}
                  onBlur={handleBlur}
                  multiple
                  name="assignedTo"
                  type="form"
                  label="Assigned to"
                  labelIdentifier="fullname"
                  valueIdentifier="_id"
                />
                <div className="text-danger">
                  {touched.assignedTo && errors.assignedTo ? (
                    <ValidationError message={errors.assignedTo} />
                  ) : null}
                </div>
              </div>
            </Grid>
            <Grid xs={5} className={classes.fieldontainer}></Grid>
            <Grid xs={5} className={classes.fieldontainer}>
              <SelectField
                options={roles}
                onSelect={handleChange}
                value={values.role}
                name="role"
                onBlur={handleBlur}
                type="form"
                label="Select a role"
                labelIdentifier="roleName"
                valueIdentifier="_id"
              />
              <div className="text-danger">
                {touched.role && errors.role ? (
                  <ValidationError message={errors.role} />
                ) : null}
              </div>
            </Grid>
            <Grid xs={5} className={classes.fieldontainer}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Marital status</FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="maritalStatus"
                  className={classes.radioGroup}
                  value={values.maritalStatus}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="married"
                    //classes={{ root: classes.formControl }}
                    control={
                      <Radio
                        checkedIcon={
                          <span
                            className={clsx(classes.icon, classes.checkedIcon)}
                          />
                        }
                        icon={<span className={classes.icon} />}
                      />
                    }
                    label="Married"
                  />
                  <FormControlLabel
                    value="single"
                    control={
                      <Radio
                        checkedIcon={
                          <span
                            className={clsx(classes.icon, classes.checkedIcon)}
                          />
                        }
                        icon={<span className={classes.icon} />}
                      />
                    }
                    label="Single"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid xs={5} className={classes.fieldontainer}>
              <TextField
                name="emergencyContactName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.emergencyContactName}
                variant="standard"
                type=""
                label="Emergency Contact(Name)"
              />
              <div className="text-danger">
                {touched.emergencyContactName && errors.emergencyContactName ? (
                  <ValidationError message={errors.emergencyContactName} />
                ) : null}
              </div>
            </Grid>
            {values.maritalStatus === "married" && (
              <Grid xs={5} className={classes.fieldontainer}>
                <TextField
                  name="spouseName"
                  onChange={handleChange}
                  value={values.spouseName}
                  variant="standard"
                  onBlur={handleBlur}
                  type=""
                  label="Spouse Name"
                />
              </Grid>
            )}
            <Grid xs={5} className={classes.fieldontainer}>
              <TextField
                name="emergencyContactNo"
                onChange={handleChange}
                value={values.emergencyContactNo}
                onBlur={handleBlur}
                variant="standard"
                type=""
                label="Emergency Contact(Number)"
              />
              <div className="text-danger">
                {touched.emergencyContactNo && errors.emergencyContactNo ? (
                  <ValidationError message={errors.emergencyContactNo} />
                ) : null}
              </div>
            </Grid>
            {values.maritalStatus === "married" && (
              <Grid xs={5} className={classes.fieldontainer}>
                <TextField
                  name="marriageDate"
                  onChange={handleChange}
                  value={values.marriageDate}
                  onBlur={handleBlur}
                  variant="standard"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  label="Marriage Date"
                />
              </Grid>
            )}
            <Grid xs={5} className={classes.fieldontainer}>
              <TextField
                name="emergencyContactRelation"
                onChange={handleChange}
                value={values.emergencyContactRelation}
                variant="standard"
                onBlur={handleBlur}
                type=""
                label="Emergency Contact(Relation)"
              />
              <div className="text-danger">
                {touched.emergencyContactRelation &&
                errors.emergencyContactRelation ? (
                  <ValidationError message={errors.emergencyContactRelation} />
                ) : null}
              </div>
            </Grid>
            <Grid xs={5} className={classes.fieldontainer}>
              <TextField
                name="panCardNo"
                onChange={handleChange}
                value={values.panCardNo}
                onBlur={handleBlur}
                variant="standard"
                type=""
                label="Pan Card Number"
              />
              <div className="text-danger">
                {touched.panCardNo && errors.panCardNo ? (
                  <ValidationError message={errors.panCardNo} />
                ) : null}
              </div>
            </Grid>
            <Grid xs={5} className={classes.fieldontainer}>
              <TextField
                name="adharCardNo"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.adharCardNo}
                variant="standard"
                type="number"
                label="Adhar Card Number"
              />
              <div className="text-danger">
                {touched.adharCardNo && errors.adharCardNo ? (
                  <ValidationError message={errors.adharCardNo} />
                ) : null}
              </div>
            </Grid>
            <Grid xs={5} className={classes.fieldontainer}>
              <TextField
                name="passportNo"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.passportNo}
                variant="standard"
                type=""
                label="Passport Number"
              />
              <div className="text-danger">
                {touched.passportNo && errors.passportNo ? (
                  <ValidationError message={errors.passportNo} />
                ) : null}
              </div>
            </Grid>
            <Grid xs={5} className={classes.fieldontainer}>
              <TextField
                name="bankName"
                onChange={handleChange}
                value={values.bankName}
                onBlur={handleBlur}
                variant="standard"
                type=""
                label="Bank Name and Branch"
              />
              <div className="text-danger">
                {touched.bankName && errors.bankName ? (
                  <ValidationError message={errors.bankName} />
                ) : null}
              </div>
            </Grid>
            <Grid xs={5} className={classes.fieldontainer}>
              <TextField
                name="accountNo"
                onChange={handleChange}
                value={values.accountNo}
                onBlur={handleBlur}
                variant="standard"
                type=""
                label="Bank A/C Number"
              />
              <div className="text-danger">
                {touched.accountNo && errors.accountNo ? (
                  <ValidationError message={errors.accountNo} />
                ) : null}
              </div>
            </Grid>
            <Grid xs={5} className={classes.fieldontainer}>
              <TextField
                name="ifscCode"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.ifscCode}
                variant="standard"
                type=""
                label="IFSC code"
              />
              <div className="text-danger">
                {touched.ifscCode && errors.ifscCode ? (
                  <ValidationError message={errors.ifscCode} />
                ) : null}
              </div>
            </Grid>
            <Grid xs={5} className={classes.fieldontainer}>
              <SelectField
                options={["true", "false"]}
                onSelect={handleChange}
                value={values.empStatus}
                onBlur={handleBlur}
                name="empStatus"
                type="form"
                label="Employee status"
              />
              <div className="text-danger">
                {touched.empStatus && errors.empStatus ? (
                  <ValidationError message={errors.empStatus} />
                ) : null}
              </div>
            </Grid>
            <Grid xs={5} className={classes.fieldontainer}>
              <SelectField
                options={departments}
                onSelect={handleChange}
                value={values.department}
                name="department"
                onBlur={handleBlur}
                type="form"
                label="Department"
                labelIdentifier="departmentName"
                valueIdentifier="_id"
              />
              <div className="text-danger">
                {touched.department && errors.department ? (
                  <ValidationError message={errors.department} />
                ) : null}
              </div>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid container xs={4}>
        <AttachmentSection
          handleInputAssetChange={handleInputAssetChange}
          handleAddField={handleAddField}
          profileImage={profileImage}
          userId={userId}
          fullname={values.fullname}
          setUploadedFiles={setUploadedFiles}
          uploadedFiles={uploadedFiles}
          setProfileImage={setProfileImage}
          assetList={assetList}
          showToast={showToast}
          ownershipList={ownershipList}
          currentUserId={currentUserId}
        />
      </Grid>
    </Grid>
  );
};

export default EmployeesAddForm;
