import { Grid, InputLabel, Paper, Typography } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useRecoilState, useRecoilValue } from "recoil";
import { getDocuments } from "../../../../../apis/onboarding";
import uploadDocument from "../../../../../assets/icons/uploadDocument.svg";
import {
  checkIdDocumentSelectionAtom,
  isUploadsViewEnabled,
  recruiteeBasicDetails
} from "../../../../../recoil/atoms";
import { documentDownloadAtom } from "../../../../../recoil/downloadAtoms";
import { UploadsContext } from "../../../../../store/uploadsStore";
import { HandleDroppedIdProofs } from "../HandleDroppedFiles/HandleDroppedIdProofs";
import { useStyles } from "../styles";
import UploadedDocuments from "../UploadedDocuments";
import { IIdentityChildrenProps } from "./ProofOfIdentity";

const PanCard = ({
  handleChange,
  setIsSubmit,
  showToast,
  getEndpoint,
  imgName,
  imgSize,
  setImgName,
  setImgSize,
}: IIdentityChildrenProps) => {
  const classes = useStyles();
  const mainUrl = process.env.REACT_APP_BACKEND_URL;
  const [, setIsFileSelected] = useRecoilState(checkIdDocumentSelectionAtom);
  const [isDisable, setIsDisable] = useState<boolean>(false);

  const { idProofProgress, idProofs, setIdentityProof } =
    useContext(UploadsContext);
  const basicDetails = useRecoilValue(recruiteeBasicDetails);
  const [, setIsViewEnabled] = useRecoilState(isUploadsViewEnabled);

  const [, setRewriteFileDownload] = useRecoilState(documentDownloadAtom);

  const url = mainUrl + getEndpoint;
  const parentKey = "proofOfIdentity";
  const childKey = "panCard";

  const getDocs = async () => {
    const response = await getDocuments(url, parentKey, childKey);
    if (response.status === 200 && response.data !== "") {
      setIsSubmit((prev: any) => ({
        ...prev,
        pan: true,
      }));
      setImgName((prev) => ({
        ...prev,
        panCard: `PAN Card of ${basicDetails.name}`,
      }));
      setImgSize((prev) => ({
        ...prev,
        panCard: 0,
      }));
      setIsViewEnabled((prev) => ({
        ...prev,
        identity: true,
      }));
      setIdentityProof((prev) => [
        ...prev,
        {
          name: "panCard",
          value: response.data,
        },
      ]);
    } else {
      setIsViewEnabled((prev) => ({
        ...prev,
        identity: false,
      }));
    }
  };

  useEffect(() => {
    getDocs();
  }, []);

  useEffect(() => {
    const length = idProofs.length;
    if (length > 0) {
      if (imgName.panCard !== "") {
        setIsDisable(true);
      } else {
        setIsDisable(false);
      }
    }
  }, [idProofs, imgName]);

  const { getRootProps: getRootPropsPan, getInputProps: getInputPropsPan } =
    useDropzone({
      onDrop: (acceptedFilesPan: any) => {
        const file = acceptedFilesPan[0];
        const docType = "PanCard";

        if (!isDisable) {
          HandleDroppedIdProofs({
            docType,
            file,
            idProofs,
            setIdentityProof,
            setIsFileSelected,
            setImgName,
            setImgSize,
            setIsSubmit,
            showToast,
            setRewriteFileDownload,
          });
        }
      },
      noClick: true,
    });

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      className={classes.docContainer}
    >
      <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
        <Paper
          variant="outlined"
          elevation={4}
          className={classes.paperContainer}
        >
          <InputLabel variant="outlined" className={classes.label} shrink>
            <b className={classes.labelColor}>PAN Card</b>
          </InputLabel>

          <Grid container direction="column" className={classes.dropContainer}>
            <Grid item>
              <Grid
                container
                direction="column"
                spacing={2}
                className={classes.dashedContainer}
                {...getRootPropsPan()}
              >
                <input {...getInputPropsPan()} />
                <Grid item className={classes.itemStyle}>
                  <Typography className={classes.boldText}>
                    Drag and Drop files to upload
                  </Typography>
                </Grid>
                <Grid item className={classes.itemStyle}>
                  <Typography className={classes.instruction}>
                    Maximum upload size 1MB
                  </Typography>
                </Grid>

                <Grid item className={classes.itemStyle}>
                  <input
                    type="file"
                    name="panCard"
                    id="panCard"
                    className={classes.inputfile}
                    onChange={(event) => {
                      handleChange(event);
                    }}
                    onClick={(event) => {
                      //@ts-ignore
                      event.target.value = null;
                    }}
                    disabled={isDisable}
                  />

                  <label htmlFor="panCard" className={classes.inputlabel}>
                    <i className={classes.inputButtonIcon}>
                      <img src={uploadDocument} />
                    </i>
                    &nbsp; Upload File
                  </label>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className={classes.itemStyle}
            >
              {imgName.panCard !== "" ? (
                <UploadedDocuments
                  progress={idProofProgress}
                  imageName={imgName.panCard}
                  imageSize={imgSize.panCard}
                  label="panDoc"
                  getEndpoint={getEndpoint}
                  setImgName={setImgName}
                />
              ) : (
                <Typography className={classes.instruction}>
                  No Files Selected
                </Typography>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PanCard;
