import { Box, Button, Grid } from "@material-ui/core";
import { Form, Formik, FormikProps } from "formik";
import React, { memo, useEffect, useState } from "react";
import * as yup from "yup";
import FormValues from "../../models/employeeInformationType";
import KeyValueWrapper from "../KeyValueWrapper";
import { useStyles } from "./styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  getDepartment,
  getDesignation,
} from "../../apis/designations_departments";
import {
  getLeaders,
  getRolesAPI,
  updateEmployeeInformation,
} from "../../apis/employees";
import { usePermissionObjectFor } from "../../hooks/permission/usePermissionObjectFor";
import {
  AllRolesType,
  employeeInformationAccordianType,
} from "../../models/employeeModuleType";
import { EmployeeInformation, profileItemsAtom } from "../../recoil/atoms";
import { employeeDataReadPermission } from "../../utils/functions";
import ValidationSchema from "./ValidationSchema";
import SelectViewValueFinder from "../../utils/SelectViewValueFinder";
import SnackbarComponent from "../SnackbarComponent";

const EmployeeInformationValidationSchema = yup
  .object()
  .shape(ValidationSchema.EmployeeInformationValidationSchema);

//select-field initial data - department

// Radio Group - employeeStatusOption initial data
const employeeStatusOptions = [
  {
    value: true,
    label: "Active",
  },
  {
    value: false,
    label: "Inactive",
  },
];

interface Props {
  employeeId: string;
  employeeInformationInitialData: any;
  loaderStateProps?: boolean;
}

export interface RoleList {
  value: string;
  label: string;
}

const EmployeeInformationEdit: React.FC<Props> = ({
  employeeId,
  employeeInformationInitialData,
  loaderStateProps = true,
}) => {
  const classes = useStyles();

  const empInfoTobBarRecoilState = useRecoilValue(profileItemsAtom);
  const [empProfileIconData, setEmpProfileIconData] =
    useRecoilState(profileItemsAtom);

  const [isEditableBasicDetails, setIsEditableBasicDetailsEdit] =
    useState(false);

  //state for snackbar
  const [snackbarState, setSnackbarState] = useState({
    openBooleanProp: false,
    messageProp: "dummy message state",
    statusProp: "info",
  });

  const permission = usePermissionObjectFor("employees");
  const employeeSensitiveDataEditPermission = permission.required([
    "create",
    "upDate",
    "read",
  ]);

  const [employeeInformationData, setEmployeeInformationData] =
    useRecoilState(EmployeeInformation);

  const [designationSelectOptions, setDesignationSelectOptions] =
    useState<object[]>();
  const [departmentsSelectOptions, setDepartmentsSelectOptions] =
    useState<object[]>();

  const [leadersSelectOptions, setLeadersSelectOptions] = useState<object[]>();
  const [rolesSelectOptions, setRolesSelectOptions] = useState<RoleList[]>([]);

  const getAllDepartments = () => {
    getDepartment()
      .then((response) => {
        if (response?.status == 200) {
          const res = response?.data;
          let fetchedDepartments: object[] = [];

          if (res.length !== 0) {
            res.map((key: any) => {
              let checkKeys =
                key.hasOwnProperty("_id") &
                key.hasOwnProperty("departmentName");
              if (checkKeys) {
                fetchedDepartments.push({
                  value: key._id,
                  label: key.departmentName,
                });
              }
            });
          }

          setDepartmentsSelectOptions(fetchedDepartments);
        }
      })
      .catch((err) => {
        setSnackbarState({
          openBooleanProp: true,
          messageProp: "Error : " + err,
          statusProp: "error",
        });
      });
  };

  const getAllDesignation = () => {
    getDesignation()
      .then((response) => {
        if (response?.status === 200) {
          const res = response?.data;
          let fetchedDesignations: object[] = [];

          if (res.length !== 0) {
            res.map((key: any, index: any) => {
              let checkKeys =
                key.hasOwnProperty("_id") &
                key.hasOwnProperty("designationName");

              if (checkKeys) {
                fetchedDesignations.push({
                  value: key?._id,
                  label: key?.designationName,
                });
              }
            });
          }

          setDesignationSelectOptions(fetchedDesignations);
        }
      })
      .catch((err) => {
        setSnackbarState({
          openBooleanProp: true,
          messageProp: "Error : " + err,
          statusProp: "error",
        });
      });
  };

  const getAllLeaders = () => {
    getLeaders()
      .then((response) => {
        if (response?.status == 200) {
          const res = response?.data;
          let fetchedLeaders: object[] = [];

          if (res.length !== 0) {
            res.map((key: any) => {
              let checkKeys =
                key.hasOwnProperty("id") & key.hasOwnProperty("leadName");

              if (checkKeys) {
                fetchedLeaders.push({
                  value: key?.id,
                  label: key?.leadName,
                });
              }
            });
          }

          setLeadersSelectOptions(fetchedLeaders);
        }
      })
      .catch((err) => {
        setSnackbarState({
          openBooleanProp: true,
          messageProp: "Error : " + err,
          statusProp: "error",
        });
      });
  };

  const getRoles = async () => {
    try {
      let response: any = await getRolesAPI();
      const res: AllRolesType[] = response?.data;
      let fetchedRoles: RoleList[] = [];

      if (res.length !== 0) {
        res.map((key: any) => {
          let checkKeys =
            key.hasOwnProperty("_id") & key.hasOwnProperty("roleName");

          if (checkKeys) {
            fetchedRoles.push({
              value: key?._id,
              label: key?.roleName,
            });
          }
        });
      }

      setRolesSelectOptions(fetchedRoles);
    } catch (err: any) {
      setSnackbarState({
        openBooleanProp: true,
        messageProp: "Error : " + err,
        statusProp: "error",
      });
    }
  };

  const generateEmployeeInfRole = (role: string[]) => {
    return rolesSelectOptions?.filter((item) => role.includes(item.value));
    // const result = role.map(
    //   (curElem) =>
    //     rolesSelectOptions.filter((item) => item.value === curElem)[0]
    // );
    // // console.log({ result });
    // return result;
  };

  const setInitialData = () => {
    const initData: FormValues = {
      empNo: employeeInformationInitialData?.employeeNumber
        ? employeeInformationInitialData?.employeeNumber
        : 0,
      department: employeeInformationInitialData?.department
        ? employeeInformationInitialData?.department
        : "Not Selected",
      assignedTo: employeeInformationInitialData?.assignedTo
        ? employeeInformationInitialData?.assignedTo
        : "Not Selected",
      empStatus: employeeInformationInitialData?.isActive
        ? employeeInformationInitialData?.isActive
        : false,
      designation: employeeInformationInitialData?.designation
        ? employeeInformationInitialData?.designation
        : false,
      role: employeeInformationInitialData?.role
        ? generateEmployeeInfRole(employeeInformationInitialData.role)
        : [],
    };
    setEmployeeInformationData(initData);
    getAllDepartments();
    getAllDesignation();
    getAllLeaders();
    getRoles();
  };

  useEffect(() => {
    setInitialData();
  }, [employeeInformationInitialData]);

  const updateFormdata = async (data: FormValues) => {
    let dataToUpdate = {
      employeeNumber: data.empNo,
      department: data.department,
      assignedTo: data.assignedTo,
      isActive: data.empStatus,
      designation: data.designation,
      role: data.role.map((role) => role.value),
    };

    try {
      await updateEmployeeInformation(employeeId, dataToUpdate);
      setEmployeeInformationData(data);
      setIsEditableBasicDetailsEdit((previous) => !previous);

      //set top bar emp info data
      setEmpProfileIconData({
        name: empInfoTobBarRecoilState?.name,
        src: empInfoTobBarRecoilState?.src,
        designation: SelectViewValueFinder(
          designationSelectOptions,
          data?.designation
        ).toString(),
        assignedTo: "",
        projectAssigned: "",
      });

      //show toast
      setSnackbarState({
        openBooleanProp: true,
        messageProp: "successfully updated",
        statusProp: "success",
      });
    } catch (err: any) {
      //show toast
      setSnackbarState({
        openBooleanProp: true,
        messageProp: "Error : " + err,
        statusProp: "error",
      });
    }
  };

  const handleSubmit = (val: FormValues) => {
    if (isEditableBasicDetails) {
      updateFormdata(val);
    }
  };

  return (
    <Box className={classes.basicDetailsViewMainContainer}>
      {employeeDataReadPermission() ? (
        <>
          <SnackbarComponent snackbarStateProp={snackbarState} />
          {!loaderStateProps ? (
            <Box
              sx={{
                width: "100%",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Formik
              initialValues={employeeInformationData}
              enableReinitialize
              validationSchema={EmployeeInformationValidationSchema}
              onSubmit={(values, formikHelpers) => {
                formikHelpers.setSubmitting(false);
                handleSubmit(values);
              }}
            >
              {(formikProps: FormikProps<FormValues>) => (
                <Form noValidate autoComplete="off">
                  <Grid container>
                    <Box sx={{ width: "100%" }}>
                      <Grid container xs={12} className={classes.widthHundred}>
                        <Grid
                          container
                          item
                          xs={12}
                          className={classes.horizontalBoxContainerStyle}
                          style={{ paddingBlock: 16 }}
                        >
                          <KeyValueWrapper
                            label={"Employee No."}
                            valueParam={formikProps.values.empNo}
                            name={"empNo"}
                            inputType={"textField"}
                            valueTypeProps={"number"}
                            isEditable={isEditableBasicDetails}
                            xsProps={2}
                          />
                          <KeyValueWrapper
                            label={"Department"}
                            valueParam={formikProps.values.department}
                            name={"department"}
                            inputType={"selectField"}
                            isEditable={isEditableBasicDetails}
                            data={departmentsSelectOptions}
                            xsProps={2}
                          />
                          <KeyValueWrapper
                            label={"Assigned To"}
                            valueParam={formikProps.values.assignedTo}
                            name={"assignedTo"}
                            inputType={"selectField"}
                            isEditable={isEditableBasicDetails}
                            data={leadersSelectOptions}
                            xsProps={2}
                          />
                          <KeyValueWrapper
                            label={"Designation"}
                            valueParam={formikProps.values.designation}
                            name={"designation"}
                            inputType={"selectField"}
                            isEditable={isEditableBasicDetails}
                            data={designationSelectOptions}
                            xsProps={3}
                          />

                          <KeyValueWrapper
                            label={"Employee Status"}
                            valueParam={formikProps.values.empStatus}
                            name={"empStatus"}
                            inputType={"radioButton"}
                            isEditable={isEditableBasicDetails}
                            data={employeeStatusOptions}
                            xsProps={2}
                          />
                          <KeyValueWrapper
                            label={"Role"}
                            valueParam={formikProps.values.role}
                            name={"role"}
                            inputType={"Autocomplete"}
                            isEditable={isEditableBasicDetails}
                            data={rolesSelectOptions}
                            // roleData={rolesSelectOptions}
                            xsProps={3}
                            formikProps={formikProps}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    {employeeSensitiveDataEditPermission && (
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        sx={{ width: "100%" }}
                      >
                        {isEditableBasicDetails ? (
                          <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            // onClick={() => setIsEditableBasicDetailsEdit(false)}
                            disabled={formikProps.isSubmitting}
                          >
                            Save
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={(e) => {
                              e.preventDefault();
                              setIsEditableBasicDetailsEdit(true);
                            }}
                          >
                            Edit
                          </Button>
                        )}
                      </Box>
                    )}
                  </Grid>
                </Form>
              )}
            </Formik>
          )}
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};

const customComparator = (
  prevProps: employeeInformationAccordianType,
  nextProps: employeeInformationAccordianType
) => {
  return (
    nextProps.employeeInformationInitialData ===
    prevProps.employeeInformationInitialData
  );
};

export default memo(EmployeeInformationEdit, customComparator);
