import { Theme, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    header: {
        padding: "1.5rem 1rem",
        backgroundColor: "#6633F2",
        borderRadius: "0.8rem",
        "& $img": {
            width: "2rem",
            height: "2rem",
            borderRadius: "50%",
            border: "1px solid white",
        },
        color: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        rowGap: "1rem",
    },
    header__heading: {
        textAlign: "center",
        width: "50%",
    },
    header__subHeading: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        columnGap: "1rem",
    },
    md: {
        marginTop: "3rem",
    },
    preview: {
        backgroundColor: "rgb(250, 250, 250)",
        padding: "1rem",
        borderRadius: "0.8rem",
        overflowY: "auto",
        border: "1px solid rgba(0, 0, 0, 0.25)"
    },
}));
