import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  gridContainer: {
    width: "100%",
    padding: "3.5%",
    display: "flex",
    justifyContent: "space-between",
  },
  gridItem: {
    paddingLeft: 20,
    display: "flex",
    flexDirection: "column",
  },
  formControl: {
    minWidth: 120,
  },
  saveButton: {
    backgroundColor: "#14213D",
    color: "#fff",
    // width: "160px",
    // height: "50px",
  },
  yearDropdown: {
    width: "10em",
  },

  educationBox: {
    borderRadius: "20px",
    borderColor: "grey",
    width: "100%",
    marginTop: "1.5em",
  },
  titleContainer: {
    backgroundColor: "white",
    position: "absolute",
    marginLeft: "3em",
    marginTop: "-.7em",
    fontSize: "1.2em",
  },
  gridContainerButton: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "4em",
  },
  viewPadding: {
    paddingTop: "1em",
  },
  viewTextLabel: {
    textAlign: "left",
    width: "100%",
  },
  viewText: {
    textAlign: "left",
    paddingLeft: "1em",
  },
});
