import moment from "moment";
import { isFirstNDaysOfTheQuarter } from "./isFirstNDaysOfTheQuarter";

export function getNextQuarter() {
  const currentDate = moment();
  const currentQuarterStart = moment(currentDate).startOf("quarter");
  const currentQuarterEnd = moment(currentQuarterStart).add(3, "months").subtract(1, "days");

  // Check if the current date is under 15 days of the current quarter
  const isWithin15Days = isFirstNDaysOfTheQuarter(15);

  // If current date is under 15 days of the current quarter, return the current quarter as the next quarter
  if (isWithin15Days) {
    return { start: currentQuarterStart, end: currentQuarterEnd };
  }

  // Otherwise, return the next quarter
  const nextQuarterStart = moment(currentDate).add(1, "quarters").startOf("quarter");
  const nextQuarterEnd = moment(nextQuarterStart).add(3, "months").subtract(1, "days");

  return { start: nextQuarterStart, end: nextQuarterEnd };
}
