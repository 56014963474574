export const handleExpansion = (
  index: number,
  accordionState: any,
  setAccordionState: any
) => {
  let currentAccordionStates = [...accordionState];
  let currentAccordion = { ...currentAccordionStates[index] };

  currentAccordion.expanded = false;
  currentAccordion.expansionEnabled = true;
  currentAccordionStates[index] = currentAccordion;

  let nextAccordion = { ...currentAccordionStates[index + 1] };
  nextAccordion.expanded = true;
  currentAccordionStates[index + 1] = nextAccordion;
  setAccordionState(currentAccordionStates);
};

export const handleExpansionUploads = (
  index: number,
  accordionState: any,
  setAccordionState: any
) => {
  let currentAccordionStates = [...accordionState];
  let currentAccordion = { ...currentAccordionStates[index] };

  currentAccordion.expanded = false;
  currentAccordion.expansionEnabled = true;
  currentAccordionStates[index] = currentAccordion;

  let nextAccordion = { ...currentAccordionStates[index + 1] };
  nextAccordion.expanded = true;
  currentAccordionStates[index + 1] = nextAccordion;
  setAccordionState(currentAccordionStates);
};

export const handleChecklistExpansion = (
  index: number,
  accordState: any,
  setAccordState: any
) => {
  let currentAccordStates = [...accordState];
  let currentAccord = { ...currentAccordStates[index] };

  currentAccord.expanded = false;
  currentAccord.expansionEnabled = true;
  currentAccordStates[index] = currentAccord;

  let nextAccord = { ...currentAccordStates[index + 1] };
  nextAccord.expanded = true;
  currentAccordStates[index + 1] = nextAccord;
  setAccordState(currentAccordStates);
};
