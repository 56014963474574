import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { getWorkExpInfo } from "../../../../../apis/onboarding";
import { onboardingIdAtom } from "../../../../../recoil/atoms";
import WorkExperienceEdit from "../../../../AccordionComponent/WorkExperienceEdit";

const WorkExperiencess = () => {
  const onboardingIds = useRecoilValue(onboardingIdAtom);
  const [experienceData, setExperienceData] = useState({});
  const [toggleUser, setToggleUser] = useState(false);

  const getWorkExpFunc = async () => {
    if (
      onboardingIds.FamLang === null ||
      onboardingIds.FamLang === undefined ||
      onboardingIds.FamLang === ""
    ) {
      return;
    }

    setExperienceData(await getWorkExpInfo(onboardingIds.workExp));
    setToggleUser(!toggleUser);
  };
  useEffect(() => {
    getWorkExpFunc();
  }, [onboardingIds.workExp]);

  return (
    <div>
      WOrk Experiences
      <WorkExperienceEdit
        employeeId={onboardingIds.workExp}
        workExperienceInitialData={experienceData}
        calledFromEmployee={false}
      />
    </div>
  );
};

export default WorkExperiencess;
