import {Theme, makeStyles} from "@material-ui/core/styles";


export const useStyles = makeStyles((theme: Theme)=>({

    search:{        
        background:"#FFFFFF",
        border: "1px solid rgba(20, 33, 61, 0.1)",
        borderRadius: "5px",
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        padding:"0 .5rem",
        boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 11%), 0px 1px 5px 0px rgb(0 0 0 / 9%)",
        minWidth:"22rem",
    },
    searchInput:{
        "& ::-webkit-input-placeholder": {
            color: "black !important"
          }
    },
}))