import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  containerTop: {
    paddingTop: "3em",
    paddingBottom: "3em",
    paddingLeft: "1em",
    paddingRight: "1em",
  },
  text: {
    color: "#000000BF",
    fontWeight: 400,
    fontSize: "17px",
  },
  sendEmailButton: {
    backgroundColor: "#14213D",
    color: "#fff",
  },
  sendingEmailButton: {
    backgroundColor: "#14213D",
    color: "#fff",
    width: "100%",
  },
  loadingIcon: {
    animation: "$spin 2s linear infinite",
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(360deg)",
    },
    "100%": {
      transform: "rotate(0deg)",
    },
  },
  containerBottom: {
    borderTop: "1px solid #d6d6d6",
    paddingTop: "3em",
    paddingBottom: "3em",
    paddingLeft: "1em",
    paddingRight: "1em",
  },
  emailSentButton: {
    color: "#fff !important",
    width: "100%",
  },
  dateText: {
    marginTop: "-1em",
  },
});
