import { Box, Button, IconButton, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { ChangeEvent, useState } from "react";
import { Link } from "react-router-dom";
import FilterBar from "./components/FilterBar";
import SearchBar from "./components/SearchBar";
import { kraStyles } from "./styles";
import ViewAgendaIcon from "@material-ui/icons/ViewAgenda";
import ReorderIcon from "@material-ui/icons/Reorder";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import { IFilterData } from "./views/AdminView";
import KRAModal from "./components/Modal/KRAModal";

interface IKRAHeader {
  searchText?: string;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  filterData: IFilterData;
  // handleFilterData: (
  //   e: ChangeEvent<{
  //     name?: string | undefined;
  //     value: unknown;
  //   }>
  // ) => void;
  handleFilterData: (data: IFilterData) => void;
}

const KRAHeader = ({
  searchText,
  handleChange,
  filterData,
  handleFilterData,
}: IKRAHeader) => {
  const classes = kraStyles();
  const [open, setOpen] = useState(false);

  const createKRAHandler = () => {};
  const handleOpen = () => setOpen(true);

  return (
    <Box className={classes.kraHeader}>
      <KRAModal open={open} setOpen={setOpen} />
      <SearchBar searchText={searchText!} handleChange={handleChange!} />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {/*views: card view and list view  */}
        <Box
          style={{
            display: "flex",
            marginRight: "1em",
          }}
        >
          <Button
            variant="outlined"
            size="medium"
            style={{
              marginRight: "1em",
              borderRadius: "8px",
              backgroundColor: "#140047",
              color: "white",
            }}
            onClick={handleOpen}
          >
            Employees list
          </Button>
          <IconButton style={{ padding: 0 }}>
            <ViewModuleIcon
              fontSize="large"
              style={{
                border: "1px solid gray",
                borderRadius: "2px",
                marginRight: ".3em",
              }}
            />
          </IconButton>
          <IconButton style={{ padding: 0 }}>
            <ReorderIcon
              fontSize="large"
              style={{ border: "1px solid gray", borderRadius: "2px" }}
            />
          </IconButton>
        </Box>

        {/* filter and createKRA button */}
        <Box style={{ display: "flex" }}>
          <FilterBar
            filterData={filterData}
            handleFilterData={handleFilterData}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default KRAHeader;

{
  /* <KRAButton bg="#fff" icon={AddIcon} position="end" text="Filter" />
        <KRAButton
          bg="#140047"
          icon={AddIcon}
          position="start"
          text="Create KRA"
        /> */
}

/* 
<Button
            variant="outlined"
            // component={Link}
            // to={"kra/details/1"}
            style={{
              background: "#140047",
              color: "white",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 .8em",
              marginLeft: "1em",
            }}
            startIcon={<AddIcon />}
            onClick={createKRAHandler}
          >
            <Typography
              variant="body1"
              style={{ fontSize: ".75em", fontWeight: 500 }}
            >
              Create KRA
            </Typography>
          </Button>


*/
