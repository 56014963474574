import * as React from "react";
import { Box, Button, IconButton, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useStyles } from "./style";
import clsx from "clsx";
import { ArrowBack } from "@material-ui/icons";

export const Header: React.FC<IHeader> = ({
  renderAction,
  employeeName,
  quarter,
  employeeId,
}) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      <Box sx={{ my: 1, display: "flex", alignItems: "center" }}>
        <IconButton
          className={clsx([classes.mx1])}
          onClick={() => history.push(`/app/kra/history/${employeeId}`)}
        >
          <ArrowBack />
        </IconButton>
        <Typography style={{ flex: 1 }} variant="h5">
          KRA of{" "}
          <Typography
            style={{ fontWeight: "bold" }}
            component="span"
            variant="h5"
          >
            {employeeName}
          </Typography>{" "}
          For the{" "}
          <Typography
            style={{ fontWeight: "bold" }}
            component="span"
            variant="h5"
          >
            {quarter}
          </Typography>
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            // width: "40%",
            gap: "8px",
          }}
        >
          {renderAction()}
        </div>
      </Box>
    </>
  );
};

export interface IHeader {
  renderAction: () => React.ReactNode;
  employeeName: string;
  quarter: string;
  employeeId: string;
}
