import axios from "./axios.global";

const BASE_URL = "projects";

export const projectsData = async (
  filterType: string,
  filterStatus: string,
  searchKey: string,
  limit: number,
  pageNo: number
) => {
  return await axios.get(`${BASE_URL}`, {
    params: {
      limit: limit,
      page: pageNo,
      search: searchKey,
      status: filterStatus,
      model: filterType,
    },
  });
};

export const deleteProjectData = async (id: string) => {
  return await axios.delete(`${BASE_URL}/delete/${id}`);
   
};

export const projectDetails = async (id: string) => {
  return await axios.get(`${BASE_URL}/${id}`);
 
};
export const updateProjectdetails = async (id: string, payload: object) => {
 return await axios.patch(`${BASE_URL}/update/${id}`, payload);

};
export const developerDetails = async (value: string) => {
  try {
    // let response = await axios.get(`${BASE_URL}/developers?search=${value}`);
    let response = await axios.get(`${BASE_URL}/developers`, {
      params: {
        search: value,
      },
    });
    return { data: response, error: undefined };
  } catch (err: any) {
    console.error(err);
    return {
      data: undefined,
      error: err.response.data,
    };
  }
};
export const projectManagerDetails = async () => {
  return await axios.get(`${BASE_URL}/projectManagers`);
    
};
export const updateResources = async (id: string, payload: object) => {
  return await axios.patch(
      `${BASE_URL}/updateResources/${id}`,
      payload
    );
  
};
export const getAllIncident = async (
  id: string,
  searchKey: string,
  limit: number,
  pageNo: number
) => {
  return await axios.get(`${BASE_URL}/${id}/incidents`, {
      params: {
        limit: limit,
        page: pageNo,
        search: searchKey,
      },
    });
   
};

export const createIncident = async (_id: string, payload: any) => {
  payload = {
    date: new Date(payload.date).toString(),
    severity: payload.severity,
    description: payload.description,
  };

 return await axios.post(`${BASE_URL}/${_id}/incidents`, payload);

};

export const deleteIncident = async (id: string, incidentid: string) => {
 return await axios.delete(
      `${BASE_URL}/${id}/incidents/${incidentid}`
    );

};
export const updateIncident = async (
  id: string,
  incidentid: string,
  payload: any
) => {
  payload = {
    date: new Date(payload.date).toString(),
    severity: payload.severity,
    description: payload.description,
  };
  return await axios.patch(
      `${BASE_URL}/${id}/incidents/${incidentid}`,
      payload
    );
  
};

export const addRequirementOverview = async(id:string,overview:string)=>{
  return await axios.patch(`${BASE_URL}/${id}/requirements/overview`, {overview});
}

export const fetchProjectRequirements =async(id:string)=>{
  return await axios.get(`${BASE_URL}/${id}/requirements`);
}
export const addProjectSOW = async (id:string,payload:object)=>{
return await axios.patch(`${BASE_URL}/${id}/requirements/sow`, payload);
}

export const addProjectDesignDocs = async(id:string,payload:object)=>{
  return await axios.patch(`${BASE_URL}/${id}/requirements/designdocs`,payload)
}
export const addProjectDesignLinks = async(id:string,payload:object)=>{
  return await axios.patch(`${BASE_URL}/${id}/requirements/designLink`,payload)
}

export const fetchAllContents = async(search:string,pageNo:number,limit:number)=>{
  return await axios.get(`${BASE_URL}/allProjectContents`,{
    params: {
      search: search,
      page:pageNo,
      limit:limit,
    },
  })
}

export const addContents = async(id:string,payload:object)=>{
  return await axios.patch(`${BASE_URL}/${id}/addProjectContents`,payload)
}
export const getAllProjectContents = async(id:string)=>{
  return await axios.get(`${BASE_URL}/${id}/ProjectContents`);
}

export const deleteContent = async(contentId:string)=>{
  return await axios.delete(`${BASE_URL}/deleteContent/${contentId}`)

}

export const deleteSowFile = async (id:string,sowId:string)=>{
  return await axios.delete(`${BASE_URL}/${id}/requirements/${sowId}`)
}

export const deleteDesignDocs = async (id:string,designDocsId:string)=>{
  return await axios.delete(`${BASE_URL}/delete/${id}/requirements/${designDocsId}`)
}

export const deleteDesignLink = async (id:string,link:string)=>{
const encodeLink = encodeURIComponent(link);
  return await axios.delete(`${BASE_URL}/design-link/${id}/requirements?link=list(${encodeLink})`)
}
