// PLUGINS IMPORTS //
import React, { FC, useEffect, useState } from "react";
import { at } from "lodash";
import { useField } from "formik";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core";

import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Switch, { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";
import { updateEmployeeInformation } from "../../apis/employees";
import SnackbarComponent from "../../components/SnackbarComponent";

// COMPONENTS IMPORTS //

// EXTRA IMPORTS //

/////////////////////////////////////////////////////////////////////////////
interface PropsType {
  valueProp: any;
  idProp: string;
  label?: string;
  colorNotCheckedBall?: string;
  colorCheckedBall?: string;
  colorCheckedBg?: string;
}

const EmployeeStatus: FC<PropsType> = ({ valueProp, idProp }) => {
  //style
  let colorNotCheckedBall = "red";
  let colorCheckedBall = "#14213D";
  let colorCheckedBg = "blue";
  if (colorNotCheckedBall) {
    colorNotCheckedBall = colorNotCheckedBall;
  }
  if (colorCheckedBall) {
    colorCheckedBall = colorCheckedBall;
  }
  if (colorCheckedBg) {
    colorCheckedBg = colorCheckedBg;
  }

  const CustomSwitch = withStyles({
    switchBase: {
      color: colorNotCheckedBall,
      "&$checked": {
        color: colorCheckedBall,
      },
      "&$checked + $track": {
        backgroundColor: colorCheckedBg,
      },
    },
    checked: {},
    track: {},
  })(Switch);

  //state for snackbar
  const [snackbarState, setSnackbarState] = useState({
    openBooleanProp: false,
    messageProp: "dummy message state",
    statusProp: "info",
  });

  const [switchState, setswitchState] = React.useState(valueProp?.isActive);
  // 

  const onChange = async (e: any) => {
    const result = e.target.checked;
    // 
    const tempEmployeeInfoData = valueProp;
    tempEmployeeInfoData.isActive = result;

    try {
      await updateEmployeeInformation(idProp, tempEmployeeInfoData);
      setswitchState(result);
    } catch (err: any) {
      //show toast
      setSnackbarState({
        openBooleanProp: true,
        messageProp: "Error : " + err,
        statusProp: "error",
      });
    }
  };

  useEffect(() => {
    setswitchState(valueProp?.isActive);
  }, [valueProp]);

  return (
    <div>
      <SnackbarComponent snackbarStateProp={snackbarState} />
      <Switch
        checked={switchState}
        onChange={(event) => onChange(event)}
        color="primary"
        name="checkedB"
        inputProps={{ "aria-label": "primary checkbox" }}
      />
    </div>
  );
};

export default EmployeeStatus;
