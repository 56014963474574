import * as yup from "yup";
import {
  adharRegex,
  dobFormatRegex,
  emailRegex,
  lettersRegex,
  panRegex,
  passportRegex,
  phoneRegex,
  PINregex,
  voterIDregex,
} from "../../../../../utils/constants";

export const validationSchema: any = yup.object().shape({
  name: yup.string().required("Name is required!"),
  emailID: yup
    .string()
    .email("Email should be valid!")
    .matches(emailRegex, "Email should be valid!")
    .max(200)
    .required("Email is required!"),
  mobileNo: yup
    .string()
    .required("Phone Number is required!")
    .matches(phoneRegex, "Enter a valid phone number!"),
  gender: yup.string().required("Gender is required!"),
  birthDate: yup
    .string()
    // .matches(dobFormatRegex, "Date is invalid")
    .required("Date of Birth is required!"),
  married: yup.string().required("Marital Status is required!"),
  bloodGroup: yup.string().required("Blood Group is required!"),

  idProofs: yup.object().shape({
    voterIdNo: yup
      .string()
      .required("Voter ID is required!")
      .matches(voterIDregex, "Voter ID should be valid!"),
    aadharNo: yup
      .string()
      .required("Aadhar Number is required!")
      .matches(adharRegex, "Aadhar Number should be valid!"),
    panNo: yup
      .string()
      .required("PAN is required!")
      .matches(panRegex, "PAN should be valid!"),
    passportNo: yup
      .string()
      .matches(passportRegex, "Passport number should be valid!"),
  }),

  isPermanentAddressDifferent: yup.boolean(),

  address: yup.object().when("isPermanentAddressDifferent", {
    is: (value: boolean) => value === true,
    then: yup.object().shape({
      present: yup.object().shape({
        addressField: yup.string().required("Address is required!"),
        city: yup
          .string()
          .matches(lettersRegex, "Only alphabets are allowed for this field ")
          .required("City is required!"),
        state: yup
          .string()
          .matches(lettersRegex, "Only alphabets are allowed for this field ")
          .required("State is required!"),
        pin: yup
          .string()
          .required("PIN is required!")
          .matches(PINregex, "PIN should be valid!"),
      }),
      permanent: yup.object().shape({
        addressField: yup.string().required("Address Field is required!"),
        city: yup
          .string()
          .matches(lettersRegex, "Only alphabets are allowed for this field ")
          .required("City is required!"),
        state: yup
          .string()
          .matches(lettersRegex, "Only alphabets are allowed for this field ")
          .required("State is required!"),
        pin: yup
          .string()
          .required("PIN is required!")
          .matches(PINregex, "PIN should be valid!"),
      }),
    }),
    otherwise: yup.object().shape({
      present: yup.object().shape({
        addressField: yup.string().required("Address is required!"),
        city: yup
          .string()
          .matches(lettersRegex, "Only alphabets are allowed for this field ")
          .required("City is required!"),
        state: yup
          .string()
          .matches(lettersRegex, "Only alphabets are allowed for this field ")
          .required("State is required!"),
        pin: yup
          .string()
          .required("PIN is required!")
          .matches(PINregex, "PIN should be valid!"),
      }),
    }),
  }),

  emergencyContact: yup.object().shape({
    name: yup.string().required("Emergency contact name is required!"),
    relationship: yup
      .string()
      .matches(lettersRegex, "Only alphabets are allowed for this field ")
      .required("Relationship is required!"),
    contactNo: yup
      .string()
      .required("Phone number is required!")
      .matches(phoneRegex, "Phone number should be valid")
      .min(10, "Phone number should be of 10 numbers")
      .max(10, "Phone number should be of 10 numbers"),
  }),
});
