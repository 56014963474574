import { makeStyles, withStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    color: "#222e48",
    fontWeight: 400,
    paddingLeft: "0.5rem",
  },
  basicDetailsAccordion: {
    borderLeft: "4px solid #F3D90C",
    borderRadius: "4px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
  educationalAccordion: {
    borderLeft: "4px solid #CB8EE0",
    borderRadius: "4px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
  workAccordion: {
    borderLeft: "4px solid #EFA2CC",
    borderRadius: "4px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
  familyAccordion: {
    borderLeft: "4px solid #83CAC6",
    borderRadius: "4px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
  bankAccordion: {
    borderLeft: "4px solid #F5918B",
    borderRadius: "4px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
  employeeAccordion: {
    borderLeft: "4px solid #93A5E1",
    borderRadius: "4px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
  assetAccordion: {
    borderLeft: "4px solid #93A5E1",
    borderRadius: "4px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
  basicDetailsViewMainContainer: {
    width: "100%",
  },
  widthHundred: {
    width: "100%",
  },
  test: {
    backgroundColor: "pink",
    width: "100%",
  },
  test1: {
    backgroundColor: "cyan",
  },
  headerStyle: {
    padding: "25px",
  },
  valueStrongStye: {
    ontFamily: "Poppins",
    color: "#000000",
    fontWeight: 500,
    fontStyle: "normal",
    fontSize: 14,
    marginTop: 4,
  },
  keyLightTypographyStyle: {
    fontFamily: "Poppins",
    color: "#686868",
    fontWeight: 500,
    fontStyle: "normal",
    fontSize: 12,
  },
  lightBigTypographyStyle: {
    color: "#5F537C",
    fontFamily: "Montserrat",
    fontSize: 14,
  },
  checkBoxContainerStyle: {
    marginBottom: 40,
  },
  roundedCornerContainer: {
    border: "1px solid rgba(20, 33, 61, 0.2)",
    marginBlock: 13,
    boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.2)",
    borderRadius: 8,
  },
  smallTypoTopLeft: {
    marginTop: -16,
    marginLeft: 13,
    padding: 5,
    backgroundColor: "white",
  },
  horizontalBoxContainerStyle: {
    padding: 25,
    display: "flex",
    justifyContent: "space-between",
  },
  horizontalBoxLessStyleContainerStyle: {
    width: "100%",
    paddingInline: 25,
  },
  greyThinBigTypoStyle: {
    fontFamily: "Poppins",
    fontWeight: 500,
    color: "rgba(94, 94, 94, 0.62)",
  },
  greyStrongNormalStyle: {
    fontFamily: "Poppins",
    fontWeight: 600,
    color: "#5E5E5E",
  },
  widthHunderWIthInlinePadding: {
    width: "100%",
    paddingInline: 20,
  },
  roundedBoxTitle: {
    fontFamily: "Poppins",
    fontWeight: 600,
    color: "#000000",
  },
  flexEndContent: {
    display: "flex",
    justifyContent: "flex-end",
  },
  keyValueMarginTop: {
    marginBottom: 25,
  },
  flexStartContent: {
    display: "flex",
    justifyContent: "flex-start",
  },
  accordianSummaryStyle: {
    borderBottom: "1px solid #DFDFDF",
    alignItems: "center",
  },
}));
