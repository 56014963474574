import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  Grid,
  Button,
} from "@material-ui/core";
import filterIcon from "../../../assets/images/filterIcon.png";
import { useStyles } from "../attendenceSub/styles";
import SearchIcon from "@material-ui/icons/Search";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { getAttendanceFilterForm } from "../../../apis/attendanceApis";

interface Filterprops {
  isDashboard?: boolean;
  filterOptions?: string[];
  getData(filterSelect: string): void;
}

const Filters: React.FC<Filterprops> = ({
  isDashboard = false,
  filterOptions = [
    "This week",
    "Last 1 month",
    "Last 3 months",
    "Last 6 months",
    "Last 1 year",
  ],
  getData = () => {},
}) => {
  const classes = useStyles();

  const [empName, setEmpName] = useState("");
  const [selectedOption, setSelectedOption] = useState("This week");
  const [filterValue, setFilterValue] = useState("This week");
  const [filterTimeValue, setFilterTimeValue] = useState("After 11 AM");
  const [customToggle, setCustomToggle] = useState(false);
  const [customTimeToggle, setCustomTimeToggle] = useState(false);
  const [popOpen, setPopOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));
  const [selectedDateTo, setSelectedDateTo] = useState(new Date(Date.now()));

  const filterSelect = (selected: string) => {
    if (!isDashboard) {
      setSelectedOption(selected);
      setPopOpen(false);
      getData(selected);
    }
  };
  const popupModal = () => {
    setPopOpen(!popOpen);
    setCustomTimeToggle(false);
    setCustomToggle(false);
  };

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };
  const handleDateChangeTo = (date: any) => {
    setSelectedDateTo(date);
  };
  const handleSelectDateChange = (event: any) => {
    setFilterValue(event.target.value);
    if (event.target.value == "Custom") {
      setCustomToggle(true);
    } else setCustomToggle(false);
  };
  const handleSelectTimeChange = (event: any) => {
    setFilterTimeValue(event.target.value);
    if (event.target.value == "Custom") {
      setCustomTimeToggle(true);
    } else setCustomTimeToggle(false);
  };
  const handleSubmit = async () => {
    const date = new Date(
      "Thu Feb 23 2023 22:16:16 GMT+0530 (India Standard Time)"
    );
    const formattedDate = `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()}`;

    const params = {
      empName: empName,
      byDate: filterValue,
      fromDate: `${selectedDate.getDate()}/${
        selectedDate.getMonth() + 1
      }/${selectedDate.getFullYear()}`,
      toDate: `${selectedDateTo.getDate()}/${
        selectedDateTo.getMonth() + 1
      }/${selectedDateTo.getFullYear()}`,
      fromTime: `${("0" + selectedDateTo.getHours()).slice(-2)}:${(
        "0" + selectedDateTo.getMinutes()
      ).slice(-2)}`,
      toTime: `${("0" + selectedDateTo.getHours()).slice(-2)}:${(
        "0" + selectedDateTo.getMinutes()
      ).slice(-2)}`,
      fromDayInterval: `${("0" + selectedDateTo.getHours()).slice(-2)}:${(
        "0" + selectedDateTo.getMinutes()
      ).slice(-2)}`,
      toTimeInterval: `${("0" + selectedDateTo.getHours()).slice(-2)}:${(
        "0" + selectedDateTo.getMinutes()
      ).slice(-2)}`,
    };
    setPopOpen(false);
  };

  const searchData = () => {};

  useEffect(() => {
    searchData();
  }, [empName]);

  const handleChangeEmp = (event: any) => {
    setEmpName(event.target.value);
  };

  return (
    <Box style={{ position: "relative" }}>
      <Box className={classes.filterBox} onClick={popupModal}>
        <Typography variant="h6" className={classes.filterHead}>
          {selectedOption}
        </Typography>
        <img
          src={filterIcon}
          alt="filter"
          style={{ width: "17px", height: "17px" }}
        />
      </Box>
      {popOpen && !isDashboard && (
        <Box className={classes.popDiv}>
          <Box style={{ display: "flex", flexDirection: "column" }}>
            {filterOptions.map((item, key) => (
              <Typography
                variant="body1"
                className={classes.option}
                onClick={() => filterSelect(item)}
              >
                {item}
              </Typography>
            ))}
          </Box>
        </Box>
      )}
      {popOpen && isDashboard && (
        <Box className={classes.popDivDash}>
          <TextField
            className={classes.search}
            //  label="Search for employee"
            variant="outlined"
            placeholder="Search for employee"
            value={empName}
            onChange={handleChangeEmp}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Grid
            container
            item
            xs={12}
            style={{ alignItems: "center", paddingLeft: ".2em" }}
          >
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.filterHeadTitle}>
                By Date
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ marginBottom: "1em" }}>
              <Select
                defaultValue={"This week"}
                style={{ width: "100%", padding: ".1em", background: "white" }}
                value={filterValue}
                onChange={handleSelectDateChange}
              >
                <MenuItem value={"This week"}>This week</MenuItem>
                <MenuItem value={"Last week"}>Last week</MenuItem>
                <MenuItem value={"Last 30 days"}>Last 30 days</MenuItem>
                <MenuItem value={"Last 90 days"}>Last 90 days</MenuItem>
                <MenuItem value={"Custom"}>Custom</MenuItem>
              </Select>
            </Grid>

            {customToggle && (
              <>
                {" "}
                <Grid
                  item
                  xs={6}
                  style={{ marginBottom: "1em", paddingRight: ".5em" }}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="From"
                      value={selectedDate}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ marginBottom: "1em", paddingLeft: ".5em" }}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="To"
                      value={selectedDate}
                      onChange={handleDateChangeTo}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </>
            )}

            <Grid item xs={6}>
              <Typography variant="h6" className={classes.filterHeadTitle}>
                By Time
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Select
                style={{ width: "100%", padding: ".1em", background: "white" }}
                value={filterTimeValue}
                onChange={handleSelectTimeChange}
              >
                <MenuItem value={"After 11 AM"}>After 11 AM</MenuItem>
                <MenuItem value={"After 6 PM"}>After 6 PM</MenuItem>
                <MenuItem value={"Custom"}>Custom</MenuItem>
              </Select>
            </Grid>
            {customTimeToggle && (
              <>
                <Grid
                  item
                  xs={6}
                  style={{ marginBottom: "1em", paddingRight: ".5em" }}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      margin="normal"
                      id="time-picker"
                      label="From"
                      value={selectedDate}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ marginBottom: "1em", paddingLeft: ".5em" }}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      margin="normal"
                      id="time-picker"
                      label="To"
                      value={selectedDateTo}
                      onChange={handleDateChangeTo}
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </>
            )}
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "end" }}
            >
              <Button
                variant="contained"
                color="primary"
                className={classes.apply}
                onClick={handleSubmit}
              >
                Apply
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default Filters;
