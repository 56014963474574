import React from "react";
interface Props {
  color?: string;
  name?: string;
}

const ColorDiv: React.FC<Props> = ({ color, name }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        gap: "2em",
        // width:"70%",
        margin:"auto",
        paddingLeft:"2em"
      }}
    >
      <div
        style={{
          width: "8px",
          height: "8px",
          borderRadius: "50%",
          backgroundColor: color,
        }}
      ></div>
      <div>{name}</div>
    </div>
  );
};

export default ColorDiv;
