import { Button, Grid } from "@material-ui/core";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  checkAcademicDocumentSelectionAtom,
  isUploadsViewEnabled,
} from "../../../../../recoil/atoms";
import { useStyles } from "../styles";
import { UploadsContext } from "../../../../../store/uploadsStore";
import { handleAcademicFiles } from "../utils";
import Class10 from "./Class10";
import Class12 from "./Class12";
import Graduation from "./Graduation";
import PostGraduation from "./PostGraduation";
import { documentDownloadAtom } from "../../../../../recoil/downloadAtoms";
import Diploma from "./Diploma";

interface AcademicCertificatesProps {
  showToast: {
    error: (message: string) => void;
    success: (message: string) => void;
    warning: (message: string) => void;
  };
  index: number;
  getEndpoint: string;
  uploadDocsEndpointPatch: string;
}

export interface IAcademicChildrenProps {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setIsSubmit: React.Dispatch<any>;
  showToast: {
    error: (message: string) => void;
    success: (message: string) => void;
    warning: (message: string) => void;
  };
  getEndpoint: string;
  imgName: {
    class10th: string;
    class12th: string;
    graduation: string;
    postGraduation: string;
    diploma: string;
  };
  setImgName: Dispatch<
    SetStateAction<{
      class10th: string;
      class12th: string;
      graduation: string;
      postGraduation: string;
      diploma: string;
    }>
  >;
  imgSize: {
    class10th: number;
    class12th: number;
    graduation: number;
    postGraduation: number;
    diploma: number;
  };
  setImgSize: Dispatch<
    SetStateAction<{
      class10th: number;
      class12th: number;
      graduation: number;
      postGraduation: number;
      diploma: number;
    }>
  >;
}

const AcademicCertificates: React.FC<AcademicCertificatesProps> = ({
  showToast,
  getEndpoint,
  uploadDocsEndpointPatch,
}) => {
  const classes = useStyles();

  const [imageName, setImageName] = useState<{
    class10th: string;
    class12th: string;
    graduation: string;
    postGraduation: string;
    diploma: string;
  }>({
    class10th: "",
    class12th: "",
    graduation: "",
    postGraduation: "",
    diploma: "",
  });

  const [imageSize, setImageSize] = useState<{
    class10th: number;
    class12th: number;
    graduation: number;
    postGraduation: number;
    diploma: number;
  }>({
    class10th: 0,
    class12th: 0,
    graduation: 0,
    postGraduation: 0,
    diploma: 0,
  });

  const [, setIsFileSelected] = useRecoilState(
    checkAcademicDocumentSelectionAtom
  );

  const { academicProofs, setAcademicProof, handlePostAcademicProof } =
    useContext(UploadsContext);

  const [, setRewriteFileDownload] = useRecoilState(documentDownloadAtom);

  const [isSubmit, setIsSubmit] = useState<{
    class10: boolean;
    class12: boolean;
    grad: boolean;
    postGrad: boolean;
  }>({
    class10: false,
    class12: false,
    grad: false,
    postGrad: false,
  });
  const isViewEnabled = useRecoilValue(isUploadsViewEnabled);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileHandler = handleAcademicFiles(
      event,
      setImageName,
      setImageSize,
      setIsFileSelected,
      setIsSubmit,
      setRewriteFileDownload,
      showToast,
      academicProofs,
      setAcademicProof
    );
    const handler = fileHandler[event.target.name];
    handler();
  };

  const handleSubmit = () => {
    academicProofs.forEach((value, index) => {
      if (value.name === "class10th" && value.value !== "") {
        setIsSubmit((prev) => ({
          ...prev,
          class10: true,
        }));
      }

      if (value.name === "class12th" && value.value !== "") {
        setIsSubmit((prev) => ({
          ...prev,
          class12: true,
        }));
      }

      if (value.name === "graduation" && value.value !== "") {
        setIsSubmit((prev) => ({
          ...prev,
          grad: true,
        }));
      }

      if (value.name === "postGraduation" && value.value !== "") {
        setIsSubmit((prev) => ({
          ...prev,
          postGrad: true,
        }));
      }
    });

    if (
      academicProofs.length > 0 &&
      imageName.class10th !== "" &&
      imageName.class12th !== ""
    ) {
      handlePostAcademicProof(showToast, uploadDocsEndpointPatch);
    } else {
      showToast.error("HS and HSLC Marksheets are mandatory!");
    }
  };

  return (
    <Grid container item xs={12} spacing={3} style={{ paddingTop: "2em" }}>
      <Grid item xs={12} md={6} lg={6} xl={12}>
        <Class10
          handleChange={handleChange}
          setIsSubmit={setIsSubmit}
          showToast={showToast}
          getEndpoint={getEndpoint}
          imgName={imageName}
          setImgName={setImageName}
          imgSize={imageSize}
          setImgSize={setImageSize}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6} xl={12}>
        <Class12
          handleChange={handleChange}
          setIsSubmit={setIsSubmit}
          showToast={showToast}
          getEndpoint={getEndpoint}
          imgName={imageName}
          setImgName={setImageName}
          imgSize={imageSize}
          setImgSize={setImageSize}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={6} xl={12}>
        <Graduation
          handleChange={handleChange}
          setIsSubmit={setIsSubmit}
          showToast={showToast}
          getEndpoint={getEndpoint}
          imgName={imageName}
          setImgName={setImageName}
          imgSize={imageSize}
          setImgSize={setImageSize}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6} xl={12}>
        <PostGraduation
          handleChange={handleChange}
          setIsSubmit={setIsSubmit}
          showToast={showToast}
          getEndpoint={getEndpoint}
          imgName={imageName}
          setImgName={setImageName}
          imgSize={imageSize}
          setImgSize={setImageSize}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6} xl={12}>
        <Diploma
          handleChange={handleChange}
          setIsSubmit={setIsSubmit}
          showToast={showToast}
          getEndpoint={getEndpoint}
          imgName={imageName}
          setImgName={setImageName}
          imgSize={imageSize}
          setImgSize={setImageSize}
        />
      </Grid>

      <Grid xs={12} className={classes.submitDiv}>
        {isViewEnabled.academic ? (
          <Button className={classes.submitButtonDisabled}>Submit</Button>
        ) : (
          <Button className={classes.submitButton} onClick={handleSubmit}>
            Submit
          </Button>
        )}
      </Grid>
    </Grid>
  );
};
export default AcademicCertificates;
