import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import { useState, MouseEvent } from "react";
import { useHistory, useParams } from "react-router-dom";
import KRALayout from "../../components/KRALayout";
import StepperComponent from "../../components/StepperComponent";
import BasicInfo from "../BasicInfo";
import BehavioralAttributes from "../BehavioralAttributes";
import TechnicalAttributes from "../TechnicalAttributes";
import { useResetRecoilState } from "recoil";
import { formDataAtom } from "../../../../recoil/kraAtom";

export interface IKRADetails {
  id: number;
  title: string;
  component: (...args: any) => JSX.Element;
}

const KRADetailsArray: IKRADetails[] = [
  {
    id: 0,
    title: "Basic info",
    component: BasicInfo,
  },
  {
    id: 1,
    title: "Technical attributes",
    component: TechnicalAttributes,
  },
  {
    id: 2,
    title: "Behavioral attributes",
    component: BehavioralAttributes,
  },
];

const KRADetailPage = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [isNext, setIsNext] = useState<boolean>(false);
  const history = useHistory();
  const params = useParams<{ userId: string; kraId?: string }>();
  const resetFormData = useResetRecoilState(formDataAtom);

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  let mode = "create";
  if (params.kraId && params.userId) {
    mode = "edit";
  }

  const prevHandler = () => {
    if (activeStep === 0) return;
    setActiveStep((prev) => prev - 1);
  };

  // const nextHandler = () => {
  //   console.log("next button hit");
  //   if (activeStep === 2) return;
  //   setActiveStep((prev) => prev + 1);
  //   setIsNext(false);
  // };

  // const saveHandler = () => {
  //   console.log("save button hit", activeStep);
  // };

  const handleYes = () => {
    resetFormData();
    history.push(`/app/kra/history/${params.userId}`);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const backHandler = () => {
    setOpenDialog(true);
  };

  const nextHandler = () => {
    if (activeStep === 2) return;
    // setActiveStep((prev) => prev + 1);
  };

  return (
    <KRALayout>
      <Box
        sx={{
          // border: "1px solid red",
          display: "flex",
          alignItems: "start",
          justifyContent: "space-between",
          marginBottom: "2em",
        }}
      >
        <Button
          variant="text"
          startIcon={<ArrowBackIos style={{ fontSize: "small" }} />}
          style={{ fontSize: ".875em", fontWeight: 600, color: "#140047" }}
          onClick={backHandler}
        >
          Back
        </Button>
        <StepperComponent activeStep={activeStep} steps={KRADetailsArray} />
        <Box>
          {activeStep > 0 && (
            <Button
              variant="outlined"
              color="primary"
              style={{
                marginRight: ".7em",
                fontSize: ".875em",
                fontWeight: 600,
                padding: ".6em 1em",
                borderRadius: "10px",
              }}
              startIcon={<ArrowBackIos style={{ fontSize: "small" }} />}
              onClick={prevHandler}
            >
              Previous
            </Button>
          )}

          {/* {isNext ? (
            <Button
              variant="contained"
              color="primary"
              style={{
                fontSize: ".875em",
                fontWeight: 600,
                padding: ".6em 2em",
                borderRadius: "10px",
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                nextHandler();
              }}
            >
              Next
            </Button>
          ) : (
            <Button
              form={`form${activeStep + 1}`}
              type="submit"
              variant="contained"
              color="primary"
              style={{
                fontSize: ".875em",
                fontWeight: 600,
                padding: ".6em 2em",
                borderRadius: "10px",
              }}
              onClick={saveHandler}
            >
              Save
            </Button>
          )} */}

          {/* new flow*/}
          <Button
            form={`form${activeStep + 1}`}
            type="submit"
            variant="contained"
            color="primary"
            style={{
              fontSize: ".875em",
              fontWeight: 600,
              padding: ".6em 2em",
              borderRadius: "10px",
            }}
            // onClick={(e) => {
            //   e.preventDefault();
            //   e.stopPropagation();
            //   nextHandler();
            // }}
            onClick={nextHandler}
          >
            {activeStep < 2 ? "Next" : "Submit"}
          </Button>
        </Box>
      </Box>
      {openDialog && (
        <Dialog open={openDialog} onClose={handleClose}>
          <DialogContent style={{ paddingTop: "2em" }}>
            <DialogContentText
              id="alert-dialog-description"
              style={{ color: "black" }}
            >
              Your form state will be completely lost. Do you wish to continue ?
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ padding: "1em 2em" }}>
            <Button onClick={handleYes} color="primary" variant="contained">
              Yes
            </Button>
            <Button onClick={handleClose} color="primary" variant="outlined">
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {KRADetailsArray.map((curElem) =>
        curElem.id === activeStep ? (
          <curElem.component
            key={curElem.id}
            customCB={(nextStep: number) => setActiveStep(nextStep)}
          />
        ) : (
          <></>
        )
      )}
    </KRALayout>
  );
};

export default KRADetailPage;
