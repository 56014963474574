import useStyles from "./styles";
import basicDetailsIcon from "../../assets/icons/basicDetailsIcon.svg";
import educationQualificationIcon from "../../assets/icons/educationQualificationIcon.svg";
import workExperienceIcon from "../../assets/icons/workExperienceIcon.svg";
import familyAndLanguageIcon from "../../assets/icons/familyAndLanguageIcon.svg";
import bankDetailsIcon from "../../assets/icons/bankDetailsIcon.svg";
import employeeInfoIcon from "../../assets/icons/employeeInfoIcon.svg";
import techVariableDocs from "../../assets/icons/techVariableDocs.svg";
import identityProof from "../../assets/icons/identityProof.svg";
import academicCertificate from "../../assets/icons/academicCertificate.svg";
import ExpCertificate from "../../assets/icons/ExpCertificate.svg";
import companyEmail from "../../assets/icons/companyEmail.svg";
import task from "../../assets/icons/task.svg";
import laptop from "../../assets/icons/Laptop.svg";
import PhotoSizeSelectActualOutlinedIcon from "@material-ui/icons/PhotoSizeSelectActualOutlined";

export const handleAccorExpansions = (
  state: any,
  setState: any,
  isExpanded: boolean,
  index: number
) => {
  if (state[index].expansionEnabled) {
    let items = [...state];
    let item = { ...items[index] };
    item.expanded = isExpanded;
    items[index] = item;
    setState(items);
  }
};

export const AccordionIcons = (title: string) => {
  const classes = useStyles();
  if (title === "Basic Details") {
    return <img src={basicDetailsIcon} className={classes.accordionIcon} />;
  }

  if (title === "Educational Qualification") {
    return (
      <img src={educationQualificationIcon} className={classes.accordionIcon} />
    );
  }

  if (title === "Work Experience") {
    return <img src={workExperienceIcon} className={classes.accordionIcon} />;
  }

  if (title === "Family and Language") {
    return (
      <img src={familyAndLanguageIcon} className={classes.accordionIcon} />
    );
  }

  if (title === "Bank Details") {
    return <img src={bankDetailsIcon} className={classes.accordionIcon} />;
  }

  if (title === "Employee Information") {
    return <img src={employeeInfoIcon} className={classes.accordionIcon} />;
  }

  if (title === "Photos") {
    return <PhotoSizeSelectActualOutlinedIcon fontSize="small" style={{marginRight: "0.5em"}} />;
  }

  if (title === "Techvariable Documents") {
    return <img src={techVariableDocs} className={classes.accordionIcon} />;
  }

  if (title === "Proof of Identity") {
    return <img src={identityProof} className={classes.accordionIcon} />;
  }

  if (title === "Academic Certificates") {
    return <img src={academicCertificate} className={classes.accordionIcon} />;
  }

  if (title === "Work Experience Documents") {
    return <img src={ExpCertificate} className={classes.accordionIcon} />;
  }

  if (title === "Company Email") {
    return <img src={companyEmail} className={classes.accordionIcon} />;
  }

  if (title === "Tasks") {
    return <img src={task} className={classes.accordionIcon} />;
  }

  if (title === "Asset Allocation") {
    return <img src={laptop} className={classes.accordionIcon} />;
  }
};
