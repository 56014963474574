import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog:{
            "& .MuiPaper-root":{
                boxShadow: "none",
                borderRadius: "10px",
            }
        },
        btnBase: {
            "&.MuiButton-root":{
                backgroundColor: "#140047",
                color: "white",
                borderRadius: "5px",
            }
        },
        backButton: {
            marginBottom: "3rem",
            "& $h6": {
                fontWeight: 600,
            },
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        backIcon: {
            width: "0.9rem",
            height: "0.9rem",
        },
    })
);

export default useStyles;
