import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingLeft: theme.typography.pxToRem(100),
    paddingRight: theme.typography.pxToRem(40),
    backgroundColor: theme.backgroundColor.panels,
    width: theme.typography.pxToRem(350),
  },
  textField: {
    width: theme.typography.pxToRem(280),
    marginLeft: theme.typography.pxToRem(10),
    marginTop: theme.typography.pxToRem(-4),
    [`& fieldset`]: {
      marginTop: theme.typography.pxToRem(10),
    },
  },

  labelRoot: {
    fontSize: 14,
  },

  inputSection: {
    width: "100%",
    height: theme.typography.pxToRem(56),
    border: "0.5px solid rgba(0,0,0,0.25)",
    borderRadius: 50,
    backgroundColor: theme.backgroundColor.paper,
    marginTop: theme.typography.pxToRem(20),
  },

  inputField: {
    height: theme.typography.pxToRem(12),
  },

  buttonSection: {
    width: "100%",
    display: "flex",
    justifyContent: "center   ",
  },
  button: {
    width: "100%",
    height: theme.typography.pxToRem(52),
    borderRadius: 50,
    marginTop: theme.typography.pxToRem(10),
  },

  resetPassSection: {
    marginTop: theme.typography.pxToRem(50),
  },

  forgotPass: {
    display: "flex",
    justifyContent: "flex-end",
  },

  link: {
    cursor: "pointer",
    color: theme.palette.primary.light,
    "&:hover": {
      boxShadow: `0 1px 0 ${theme.palette.primary.dark}`,
    },
    marginTop: 10,
  },

  employeeLinkContainer: {
    display: "flex",
    justifyContent: "center",
  },

  adornmentGrid: {
    marginTop: -1,
  },

  regenrateOtp: {
    cursor: "not-allowed",
    color: theme.palette.primary.light,
    "&:hover": {
      boxShadow: `0 1px 0 ${theme.palette.primary.dark}`,
    },
    marginTop: 10,
  },
}));

export default useStyles;
