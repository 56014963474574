import { createContext, useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import BasicLayout from "../../components/BasicLayout";
import { useBasicNav } from "../../utils/useBasicNav";
import EmployeeView from "./views/EmployeeView";

interface IShowToast {
  error: (message: string) => void;
  success: (message: string) => void;
  warning: (message: string) => void;
}

interface IAttendanceContext {
  showToast: IShowToast;
}

interface IProps {
  loadPrimary: (event: boolean, basic?: boolean) => void;
  showToast: IShowToast;
}

export const AttendanceContext = createContext<IAttendanceContext>({
  showToast: {
    error: () => {},
    success: () => {},
    warning: () => {},
  },
});

const AttendancePageV2 = ({ showToast, loadPrimary }: IProps) => {
  useBasicNav("attendance");
  const { path } = useRouteMatch();

  useEffect(() => {
    loadPrimary(false, true); //make it true after a api fetch is success
  }, []);

  let user = "employee";

  return (
    <AttendanceContext.Provider value={{ showToast }}>
      <BasicLayout>
        <Switch>
          <Route path={`${path}`} exact>
            <EmployeeView />
          </Route>
          {/* {user === "admin" && (
            <Route path={`${path}`} exact>
              <AdminView />
            </Route>
          )}
          <Route path={`${path}/dashboard`} exact>
            <Dashboard />
          </Route> */}
        </Switch>
      </BasicLayout>
    </AttendanceContext.Provider>
  );
};

export default AttendancePageV2;

//change
