import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  headerContainer: {
    paddingLeft: "2em",
    padding: "1em",
    marginTop: "0.2em",
  },
  backButton: {
    marginTop: "3%",
    cursor: "pointer",
    transitionDuration: "1s",

    "&:hover": {
      borderRadius: "10px",
      backgroundColor: "#faf7f7",
      transitionDuration: "1s",
    },
  },
  infoContainer: {
    padding: "2em",
  },
  infoActions: {
    padding: "1%",
    paddingTop: "3%",
    paddingRight: "2%",
  },
  item: {
    paddingTop: "10%",
  },
  items: {
    paddingTop: "5%",
  },
  itemText: {
    marginTop: "1em",
  },
  arrowLeft: {
    marginTop: "3%",
  },
  image: {
    width: "4em",
    height: "4em",
    borderRadius: "100%",
    padding: "0.5em",
    objectFit: "cover"
  },
  progressBarDimensions: {
    // width: 120,
    // height: 120,
  },
  convertToEmployee: {
    padding: "3em",
  },
  convertToEmployeeButton: {
    backgroundColor: "#140047",
    color: "#fff",
    transition: "all 0.3s",
    "&:hover": {
      backgroundColor: "rgba(20, 0, 71, 0.8)",
    },
  },
}));
