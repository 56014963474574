import React, { createContext } from "react";
import { useAcademicProofs } from "../hooks/uploadHooks/useAcademicProofs";
import { useAcceptedOfferLetter } from "../hooks/uploadHooks/useAcceptedOfferLetter";
import { File } from "../hooks/uploadHooks/useAcceptedOfferLetter";
import { useIdentityProofs } from "../hooks/uploadHooks/useIdentityProofs";
import { usePhotos } from "../hooks/uploadHooks/usePhotos";
import { useWorkExperience } from "../hooks/uploadHooks/useWorkExperience";
type UploadChildren = {
  children: React.ReactNode;
};

export type Toast = {
  error: (message: string) => void;
  success: (message: string) => void;
  warning: (message: string) => void;
};

type Context = {
  photos: File[];
  handlePostPhotos: (showToast: Toast, uploadDocsEndpointPatch: string) => void;
  setPhotos: React.Dispatch<React.SetStateAction<File[]>>;

  // offerLetter: {
  //   name: string;
  //   value: Blob | string;
  // };
  // handlePostOfferLetter: (
  //   showToast: Toast,
  //   uploadDocsEndpointPatch: string
  // ) => void;
  // setOfferLetter: React.Dispatch<React.SetStateAction<File>>;
  // offerLetterProgress: number;
  // setOfferLetterProgress: React.Dispatch<React.SetStateAction<number>>;

  idProofs: File[];
  handlePostIdentityProof: (
    showToast: Toast,
    uploadDocsEndpointPatch: string
  ) => void;
  setIdentityProof: React.Dispatch<React.SetStateAction<File[]>>;
  idProofProgress: number;

  academicProofs: File[];
  handlePostAcademicProof: (
    showToast: Toast,
    uploadDocsEndpointPatch: string
  ) => void;
  setAcademicProof: React.Dispatch<React.SetStateAction<File[]>>;
  academicProgress: number;

  experienceProof: File[];
  handlePostWorkExperience: (
    showToast: Toast,
    uploadDocsEndpointPatch: string,
    headerPropsPatch: any
  ) => void;
  setExperienceProof: React.Dispatch<React.SetStateAction<File[]>>;
  workExperienceProgress: number;
};

export const UploadsContext = createContext<Context>({
  // offerLetter: {
  //   name: "",
  //   value: "",
  // },
  // handlePostOfferLetter: () => {},
  // setOfferLetter: () => {},
  // setOfferLetterProgress: () => {},
  // offerLetterProgress: 0,

  idProofs: [],
  handlePostIdentityProof: () => {},
  setIdentityProof: () => {},
  idProofProgress: 0,

  photos: [],
  handlePostPhotos: () => {},
  setPhotos: () => {},

  academicProofs: [],
  handlePostAcademicProof: () => {},
  setAcademicProof: () => {},
  academicProgress: 0,

  experienceProof: [],
  setExperienceProof: () => {},
  handlePostWorkExperience: () => {},
  workExperienceProgress: 0,
});
export const UploadsProvider = ({ children }: UploadChildren) => {
  // const {
  //   offerLetter,
  //   handlePostOfferLetter,
  //   setOfferLetter,
  //   offerLetterProgress,
  //   setOfferLetterProgress,
  // } = useAcceptedOfferLetter();

  const {
    idProofs,
    handlePostIdentityProof,
    setIdentityProof,
    idProofProgress,
  } = useIdentityProofs();

  const { photos, handlePostPhotos, setPhotos } = usePhotos();

  const {
    academicProofs,
    handlePostAcademicProof,
    setAcademicProof,
    academicProgress,
  } = useAcademicProofs();

  const {
    experienceProof,
    setExperienceProof,
    handlePostWorkExperience,
    workExperienceProgress,
  } = useWorkExperience();
  return (
    <UploadsContext.Provider
      value={{
        // offerLetter,
        // handlePostOfferLetter,
        // setOfferLetter,
        // offerLetterProgress,
        // setOfferLetterProgress,

        idProofs,
        handlePostIdentityProof,
        setIdentityProof,
        idProofProgress,

        photos,
        handlePostPhotos,
        setPhotos,

        academicProofs,
        handlePostAcademicProof,
        setAcademicProof,
        academicProgress,

        experienceProof,
        setExperienceProof,
        handlePostWorkExperience,
        workExperienceProgress,
      }}
    >
      {children}
    </UploadsContext.Provider>
  );
};
