import { makeStyles } from "@material-ui/core";
import {theme} from "../../theme/theme";

export const useStyles = makeStyles({
  bordered: {
    border: "1px solid black",
    paddingTop:theme.typography.pxToRem(8) ,
    paddingBottom:theme.typography.pxToRem(8),
    paddingLeft:theme.typography.pxToRem(25),
    paddingRight:theme.typography.pxToRem(25),
    width:'100%',
    justifyContent: 'space-around',
    fontFamily: "Poppins",
    fontSize:theme.typography.pxToRem(12),
  },
  red:{
    background:"#D90202",
    border:"none",
    '&:hover':{
      background:"#AA0000",
    }
  },

  orange:{
    background:"#FF7A00",
    border:"none",
    '&:hover':{
      background:"#C86000",
    }
  },

  green:{
    background:"#13D902",
    border:"none",
      '&:hover':{
      background:"#119B05",
    }
  },

  white:{
    background:"#fff",
    border:"none",
    color:"#000",
    '&:hover':{
      background:"#fdfdfd",
    }
  },

  whiteNoHover:{
    background:"#fff",
    border:"none",
    color:"#000",
        '&:hover':{
      background:"#fff",
    }
  },

  borderedWhite:{
    border: "1px solid white",
    paddingTop: theme.typography.pxToRem(12),
    paddingBottom: theme.typography.pxToRem(12),
    paddingLeft: theme.typography.pxToRem(25),
    fontSize:theme.typography.pxToRem(17),
    paddingRight: theme.typography.pxToRem(25),

   
  },
  image: {
    maxWidth: '70%',
    height: theme.typography.pxToRem(23),
    width: theme.typography.pxToRem(23)


  },
  redText: {
    color: 'white'
  }
});
