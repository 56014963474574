import Moment from "moment";

export const ellipsify = (start: number, end: number, value: string) => {
  return value.slice(start, end) + "...";
};

export const formatToDate = (
  current_date: string,
  current_date_format: string,
  to_date_format: string
) => {
  return Moment(current_date, current_date_format).format(to_date_format);
};
