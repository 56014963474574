import React, { useContext } from "react";
import { useStyles } from "../../style";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import VisibilityIcon from "@material-ui/icons/Visibility";
import complete from "../../../../assets/images/complete.svg";
import ongoing from "../../../../assets/images/ongoing.svg";
import { Grid, IconButton } from "@material-ui/core";
import { deleteProjectData } from "../../../../apis/projects";
import { Link, useRouteMatch } from "react-router-dom";
import { Toast } from "../../ProjectsPage";
import { usePermissionObjectFor } from "../../../../hooks/permission/usePermissionObjectFor";

interface Iprops {
  projectStatus: string;
  _id: string;
  setIsDeleted: React.Dispatch<React.SetStateAction<boolean>>;
  isDeleted: boolean;
}

const StatusDiv: React.FC<Iprops> = ({
  projectStatus,
  _id,
  setIsDeleted,
  isDeleted,
}) => {
  const classes = useStyles();
  const { path } = useRouteMatch();
  const showToast = useContext(Toast);

  const permission = usePermissionObjectFor("projects");
  const hasDeleteAccess = permission.includes("del");

  const deletedHandler = (_id: string) => {
    const confirm = window.confirm(
      "Are you sure you want to delete the project?"
    );
    if (confirm) {
      deleteProjectData(_id)
        .then((res) => {
          showToast.success("Deleted Successfully");
          setIsDeleted(!isDeleted);
        })
        .catch((err: any) => {
          showToast.error(err.response.data.mesaage);
          return;
        });
    }
  };

  return (
    <Grid container className={classes.statusroot}>
      {projectStatus === "completed" ? (
        <Grid item xs={6} className={classes.statusdivcomplete}>
          <img src={complete} alt="" className={classes.statusicon} />
          <span>{projectStatus}</span>
        </Grid>
      ) : (
        <Grid item xs={6} className={classes.statusdivongoing}>
          <img src={ongoing} alt="" className={classes.statusicon} />
          <span>{projectStatus}</span>
        </Grid>
      )}
      <Grid item xs={6}>
        {hasDeleteAccess ? (
          <IconButton aria-label="delete" onClick={() => deletedHandler(_id)}>
            <DeleteOutlineIcon htmlColor="#B0BABF" />
          </IconButton>
        ) : (
          <IconButton aria-label="delete" disabled>
            <DeleteOutlineIcon htmlColor="#B0BABF" />
          </IconButton>
        )}

        <Link
          to={{
            pathname: `${path}/projectdetails/${_id}`,
            state: { _id },
          }}
        >
          <IconButton aria-label="view">
            <VisibilityIcon htmlColor="#B0BABF" />
          </IconButton>
        </Link>
      </Grid>
    </Grid>
  );
};

export default StatusDiv;
