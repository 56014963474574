import { Button, Grid } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import BasicLayout from "../../components/BasicLayout";
import { useBasicNav } from "../../utils/useBasicNav";
import { useStyles } from "./styles";

/**
 * @description Functional component which renders a warning for employees not accepting the policies of the company.
 * @returns a node
 */

const PoliciesAcceptancePage = () =>{
  const classes = useStyles();
  let location = useLocation();
  let activeTab;
  location.pathname === '/app/attendance' ? activeTab='attendance' : activeTab='leaves';
  useBasicNav(activeTab);

  return(
    <BasicLayout>
      <div className={classes.policyContainer}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <p className={classes.policyText}>
              Please accept the policies of TechVariable to continue using the HRMS.
            </p><br/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Link to="/app/policies" className={classes.policyLink}>
              <Button variant="contained" color="primary" className={classes.policyButton}>
                Go to Policies
              </Button>
            </Link>
          </Grid>
        </Grid>
      </div>
    </BasicLayout>
  ) 
}

export default PoliciesAcceptancePage;