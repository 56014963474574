import {
  Box,
  Typography,
  Divider,
  Grid,
  Paper,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
} from "@material-ui/core";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";

import * as React from "react";
import clsx from "clsx";
import { useStyles } from "./style";
import { Link, useLocation } from "react-router-dom";
import useQuery from "../../../../hooks/useQuery";
import KRALayout from "../../components/KRALayout";
import SparkLine from "../../components/Sparkline";

import { Header } from "./Header";
import { Actions } from "./Actions";
import useFetchMemorized from "../../../../hooks/useFetchMemorized";
import { approveKra, getEmployeeKraAPI } from "../../../../apis/kra";
import { IKraAPI, IKraReport } from "../../types/KRA.interface";
import { Adapter } from "../../../../utils/Adapter";
import moment from "moment";

export type QueryStatus = "new" | "old";

export interface IAttributeStripProps {
  attribute: string;
  appraisalRating: number;
  selfRating: number;
  weight: number;
  renderValue: (text: string) => React.ReactNode;
}

const AttributeStrip: React.FC<IAttributeStripProps> = ({
  attribute,
  appraisalRating,
  selfRating,
  weight,
  renderValue,
}) => {
  const classes = useStyles();

  const score = Number((appraisalRating + selfRating) / 2).toFixed(1);
  return (
    <Paper
      className={clsx([classes.my1, classes.px2, classes.py2])}
      variant="outlined"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Typography className={clsx([classes.flex1])}>{attribute}</Typography>
        <>{renderValue(`${score}/${weight}`)}</>
      </div>
    </Paper>
  );
};

const Report: React.FC = () => {
  const classes = useStyles();
  let query = useQuery();
  const status = query.get("status") ?? "old";
  const empID = query.get("employeeId");
  const kraID = query.get("kraID");
  const isNewKRA = status === "new";
  const [quarter, setQuarter] = React.useState("Q1 2023");
  const [report, setReport] = React.useState<IKraReport>();
  const [approved, setApproved] = React.useState(false);

  const { refetch } = useFetchMemorized({
    queryFn: async () => getEmployeeKraAPI(kraID!),
    onSuccess(data) {
      const apiData = new Adapter(data);
      const values = apiData.adapt<IKraReport>((data) => ({
        approvedBy: data.data.approvedBy.fullName,
        createdBy: data.data.createdBy.fullName,
        dateCreated: data.data.date,
        employee: data.data.employee,
        KRAID: data.data._id,
        quarter: `${data.data.quarter} ${moment(data.data.date).format(
          "YYYY"
        )}`,
        score: 0,
        attributes: data.data.attributes,
        isApproved: data.data.isApproved,
        hasTeamLeadRated: data.data.hasTeamLeadRated,
        hasUserRated: data.data.hasUserRated,
        teamLeadId: data.data.createdBy.id,
      }));
      setReport(values);
      setApproved(values.isApproved);
    },
    onError(error) {
      console.error(error);
    },
  });

  const handleApprove = React.useCallback(async () => {
    try {
      if (!report) {
        console.warn("Couldn't find report ID");
        return;
      }
      const { statusText } = await approveKra(report.KRAID);
      if (statusText === "OK") {
        refetch();
      } else {
        console.warn("Unable to approve");
      }
    } catch (error) {}
  }, [report?.KRAID]);

  if (!report) {
    return (
      <KRALayout>
        <div className={clsx([classes.centerAlign, classes.height70])}>
          <CircularProgress />
        </div>
      </KRALayout>
    );
  }

  return (
    <KRALayout>
      <Header
        employeeName={report.employee.fullName}
        employeeId={report.employee.id}
        quarter={report.quarter}
        renderAction={() => (
          <Actions
            status={status as QueryStatus}
            approved={approved}
            renderApproveButton={(props) => (
              <Button
                variant="outlined"
                onClick={handleApprove}
                disabled={approved}
                startIcon={props.icon}
              >
                {"Approve"}
              </Button>
            )}
            renderEditButton={() => (
              <Button
                component={Link}
                to={{
                  pathname: `details/${report.employee.id}/${report.KRAID}/edit`,
                  state: {
                    empInfo: {
                      empId: report.employee.id,
                      empName: report.employee.fullName,
                    },
                  },
                }}
                className={clsx([classes.mx1, classes.flex1])}
                variant="outlined"
                startIcon={<CreateOutlinedIcon />}
                disabled={approved}
              >
                Edit
              </Button>
            )}
            renderHistoryButton={() => (
              <Button
                variant="outlined"
                component={Link}
                to={{
                  pathname: `history/${empID}`,
                  state: {
                    empInfo: {
                      empId: empID,
                      empName: "Biswarup Bouri",
                    },
                  },
                }}
                style={{ flex: 1 }}
              >
                View History
              </Button>
            )}
          />
        )}
      />
      <Divider className={clsx([classes.my2])} />
      <div className={clsx([classes.mx2, classes.flex])}>
        <div></div>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <div>
            <Typography
              className={clsx([classes.fontBold])}
              variant="h6"
              align="center"
            >
              Technical Attributes
            </Typography>
          </div>
          <div
            className={clsx([classes.verticalScroll, classes.px2, classes.py2])}
          >
            {report.attributes.technical.map((item) => (
              <AttributeStrip
                key={item.id}
                attribute={item.KRA}
                renderValue={(text) => (
                  <Typography className={clsx([])}>{text}</Typography>
                )}
                appraisalRating={item.appraiserRating}
                selfRating={item.selfRating}
                weight={item.weight}
              />
            ))}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div>
            <Typography
              className={clsx([classes.fontBold])}
              variant="h6"
              align="center"
            >
              Behavioral Attributes
            </Typography>
          </div>
          <div
            className={clsx([classes.verticalScroll, classes.px2, classes.py2])}
          >
            {report.attributes.behavioral.map((item) => (
              <AttributeStrip
                key={item.id}
                attribute={item.KRA}
                renderValue={(text) => (
                  <Typography className={clsx([])}>{text}</Typography>
                )}
                appraisalRating={item.appraiserRating}
                selfRating={item.selfRating}
                weight={item.weight}
              />
            ))}
          </div>
        </Grid>
      </Grid>
    </KRALayout>
  );
};

export default Report;

export const SparkLineGraph = () => {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <div>
        <Typography
          className={clsx([classes.fontBold])}
          variant="h6"
          align="center"
        >
          Overall Performance from last 4 Quarter
        </Typography>
      </div>
      <Box
        style={{
          height: "200px",
          display: "flex",
          alignItems: "end",
          width: "100%",
        }}
      >
        <SparkLine
          data={[0, 0, 0, 85]}
          labels={["Q2 2022", "Q3 2022", "Q4 2022", "Q1 2023"]}
          color={""}
          minHeight="240px"
          minWidth="120px"
        />
      </Box>
      <Divider className={clsx([classes.my2])} />
    </Grid>
  );
};

// {
//   isNewKRA || (
//     <Box sx={{ flex: 3, marginRight: "1em" }}>
//       <Button
//         fullWidth
//         component={Link}
//         to={{
//           pathname: "history/546",
//           state: {
//             empInfo: {
//               empId: "1214",
//               empName: "Biswarup Bouri",
//             },
//           },
//         }}
//         className={clsx([classes.mx1, classes.flex1])}
//         variant="outlined"
//         // startIcon={<CreateOutlinedIcon />}
//       >
//         View History
//       </Button>
//     </Box>
//   );
// }
