import { Button, Menu, MenuItem } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  deleteRecruiteeAPI,
  migrateToEmployeeAPI,
} from "../../apis/onboarding";
import deleteRecruitee from "../../assets/icons/deleteRecruitee.svg";
import empFilter from "../../assets/icons/empFilter.svg";
import { setDetails } from "../../pages/NewRecruits/utils";
import { employeeInformation, recruiteeBasicDetails } from "../../recoil/atoms";
import EmptyTable from "../EmptyTable";
import { IAllDesignations } from "../Forms/OnBoardingForm/RecruiteeDetails/EmployeeInformation/EmployeeInformation";
import StatusButton from "./StatusButton";
import { useStyles } from "./styles";

interface NewRecruitsTableProps {
  headCells: {
    label: string | JSX.Element;
    key: string;
  }[];
  items: {
    designation: string;
    emailID: string;
    name: string;
    mobileNo: number;
    status: string;
    _id: string;
    migrated: boolean;
  }[];
  setDataFetched: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDeleted: React.Dispatch<React.SetStateAction<boolean>>;
  setIsMigrated: React.Dispatch<React.SetStateAction<boolean>>;
  showToast: any;
  allDesignations: IAllDesignations[];
}

const NewRecruitsTable: React.FC<NewRecruitsTableProps> = ({
  allDesignations,
  headCells,
  items,
  setDataFetched,
  setIsDeleted,
  setIsMigrated,
  showToast,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [filteredData, setFilteredData] = useState<
    NewRecruitsTableProps["items"]
  >([]);

  const [, setBasicDetails] = useRecoilState(recruiteeBasicDetails);
  const [, setEmployeeInfo] = useRecoilState(employeeInformation);

  const [checkFilter, setCheckFilter] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [checkedState, setCheckedState] = useState(
    new Array(items.length).fill(false)
  );

  const handleClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const handleDeleteRecruitee = async (id: string) => {
    const confirm = window.confirm("ARE YOU SURE TO DELETE? ");
    if (confirm) {
      const resp: any = await deleteRecruiteeAPI(id);

      if (resp?.status === 200) {
        showToast.success("DETAILS DELETED!");
        setIsDeleted(true);
        setDataFetched(true);
      } else {
        showToast.error("Couldn't Delete!");
        setDataFetched(false);
        setIsDeleted(false);
      }
    } else {
      return;
    }
  };

  // FILTERS:
  const filterPendingStatus = () => {
    setFilteredData([]);
    const pendingData = items.filter((item: any) =>
      item.status.includes("pending")
    );
    setFilteredData(pendingData);
    setCheckFilter(true);
    handleCloseFilter();
  };

  const filterCompletedStatus = () => {
    setFilteredData([]);
    const pendingData = items.filter((item: any) =>
      item.status.includes("complete")
    );
    setFilteredData(pendingData);
    setCheckFilter(true);
    handleCloseFilter();
  };

  const filterUndo = () => {
    setFilteredData(items);
    setCheckFilter(false);
    handleCloseFilter();
  };

  const fullDetails = (row: {
    designation: string;
    emailID: string;
    name: string;
    mobileNo: number;
    status: string;
    _id: string;
    migrated: boolean;
  }) => {
    let recruiteeDetails = row;

    setDetails({ recruiteeDetails, setBasicDetails, setEmployeeInfo });
    history.push("/app/newrecruitdetails", { state: row });
  };

  const getDesignatonName = (currentDesignation: string) => {
    return (
      allDesignations.find((des) => des._id === currentDesignation)
        ?.designationName || ""
    );
  };

  const convertToEmployee = async (passedId: string) => {
    const confirm = window.confirm(
      "Are you sure to convert User to Employee? This can't be undone."
    );
    if (confirm) {
      try {
        const res = await migrateToEmployeeAPI(passedId);
        setIsMigrated(true);
        showToast.success(res.data.message);
      } catch (error) {
        showToast.error("Something went wrong");
      }
    }
  };

  return (
    <>
      <br />
      {items?.length > 0 ? (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow style={{ height: 10 }}>
                  {headCells.map((header) => {
                    return (
                      <TableCell
                        className={
                          header.label == "Status"
                            ? classes.headerStatus
                            : header.key === "migrate"
                            ? classes.headerMigrate
                            : classes.header
                        }
                      >
                        <div>
                          {header.label != "Status" ? (
                            header.label
                          ) : (
                            <>
                              {header.label}
                              <span className={classes.filterSpan}>
                                <img
                                  alt="filter"
                                  onClick={handleClick}
                                  src={empFilter}
                                  className={classes.filterIcon}
                                />
                              </span>
                            </>
                          )}
                        </div>
                      </TableCell>
                    );
                  })}
                  <TableCell className={classes.headerFilter}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!checkFilter
                  ? items?.map(
                      (
                        row: {
                          designation: string;
                          emailID: string;
                          name: string;
                          mobileNo: number;
                          status: string;
                          _id: string;
                          migrated: boolean;
                        },
                        index: number
                      ) => (
                        <TableRow key={index} className={classes.tableRow}>
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            className={classes.colOne}
                          >
                            <span>{row.name}</span>
                          </TableCell>
                          <TableCell component="th" scope="row" align="left">
                            {row.emailID}
                          </TableCell>
                          <TableCell component="th" scope="row" align="left">
                            {row.mobileNo}
                          </TableCell>
                          <TableCell component="th" scope="row" align="left">
                            {getDesignatonName(row.designation)}
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            onClick={() => fullDetails(row)}
                          >
                            <StatusButton status={row?.status} />
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              fullWidth
                              className={
                                row.migrated
                                  ? classes.migratedToEmployeeButton
                                  : classes.convertToEmployeeButton
                              }
                              variant="outlined"
                              disabled={
                                row?.status === "pending"
                                  ? true
                                  : row?.migrated
                                  ? true
                                  : false
                              }
                              onClick={() => convertToEmployee(row._id)}
                            >
                              {row.migrated
                                ? "Converted"
                                : "Convert to Employee"}
                            </Button>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            style={{ padding: "2em" }}
                          >
                            <img
                              onClick={() => handleDeleteRecruitee(row._id)}
                              src={deleteRecruitee}
                              className={classes.deleteStyles}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    )
                  : filteredData?.map(
                      (
                        data: {
                          designation: string;
                          emailID: string;
                          name: string;
                          mobileNo: number;
                          status: string;
                          _id: string;
                          migrated: boolean;
                        },
                        index: number
                      ) => {
                        return (
                          <TableRow key={data._id} className={classes.tableRow}>
                            <TableCell
                              component="th"
                              scope="row"
                              align="left"
                              className={classes.colOne}
                            >
                              <span>{data.name}</span>
                            </TableCell>
                            <TableCell component="th" scope="row" align="left">
                              {data.emailID}
                            </TableCell>
                            <TableCell component="th" scope="row" align="left">
                              {data.mobileNo}
                            </TableCell>
                            <TableCell component="th" scope="row" align="left">
                              {getDesignatonName(data.designation)}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                              onClick={() => fullDetails(data)}
                            >
                              <StatusButton status={data.status} />
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                fullWidth
                                className={
                                  data.migrated
                                    ? classes.migratedToEmployeeButton
                                    : classes.convertToEmployeeButton
                                }
                                variant="outlined"
                                disabled={
                                  data?.status === "pending"
                                    ? true
                                    : data?.migrated
                                    ? true
                                    : false
                                }
                                onClick={() => convertToEmployee(data._id)}
                              >
                                {data?.migrated
                                  ? "Converted"
                                  : "Convert to Employee"}
                              </Button>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="left"
                              style={{ padding: "2em" }}
                            >
                              <img
                                onClick={() => handleDeleteRecruitee(data._id)}
                                src={deleteRecruitee}
                                className={classes.deleteStyles}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <EmptyTable headCells={headCells} />
      )}

      <Menu
        id="filter-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseFilter}
      >
        <MenuItem onClick={filterCompletedStatus}>Completed</MenuItem>
        <MenuItem onClick={filterPendingStatus}>Pending</MenuItem>
        <MenuItem onClick={filterUndo}>All Recruits</MenuItem>
      </Menu>
    </>
  );
};

export default NewRecruitsTable;
