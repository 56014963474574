import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
    button:{
        width: "100%",
        display:"flex",
        justifyContent:"flex-end"
    },
    editButton:{
        marginRight:"1.5rem",
        backgroundColor:"#14213D",
        color:"white",

    }
});
