import React, { useEffect, useState } from "react";
import { TextField, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { leaveBalancetype } from "../LeaveBalanceUpdate";
import { useStyles } from "../style";
import { number } from "yup/lib/locale";

interface IValue {
    allotted: number;
    taken: number;
}

interface LeaveBalanceUpdate {
    balanceKey: string;
    onChange: (key: string, value: IValue)=> void
  isEditable: boolean;
  leaveBalance:{
    taken:number;
    allotted:number;
  } ;
  item: {
    key: string;
    label: string;
}
}

const LeaveBalanceForm: React.FC<LeaveBalanceUpdate> = ({
  isEditable,
  leaveBalance,
  item,
  onChange,
  balanceKey
}) => {
  const classes = useStyles();
//   const [value, setValue] = useState<IValue>();
  const [taken, setTaken] = useState<number>();
  const [allotted, setAllotted] = useState<number>();

  useEffect(()=> {
    onChange(balanceKey, {
        taken: taken ?? leaveBalance.taken,
        allotted: allotted ?? leaveBalance.allotted,
       
    })
  },[taken, allotted]);


  return (
    <Grid container spacing={2}>
      {isEditable ? (
        <>
          <Grid item xs={6}>
            <Typography>Taken</Typography>
            <TextField type="number"  defaultValue={leaveBalance.taken} onChange={(e)=> setTaken(Number(e.target.value))}  id="outlined-basic"  variant="outlined" />
          </Grid>
          <Grid item xs={6}>
          <Typography>Allotted</Typography>
            <TextField type="number" defaultValue={leaveBalance.allotted} onChange={(e)=>setAllotted(Number(e.target.value))}  id="outlined-basic" variant="outlined" />
          </Grid>
        </>
      ) : (
        <>
          
          <Grid item xs={6}>
          <Typography>Taken</Typography>
            <TextField
              disabled
              id="outlined-basic"
              variant="outlined"
              value={leaveBalance.taken}
            />
          </Grid>
          <Grid item xs={6}>
          <Typography>Allotted</Typography>
            <TextField
              disabled
              id="outlined-basic"
              variant="outlined"
              value={leaveBalance.allotted}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default LeaveBalanceForm;
