import { DetailsType } from "../pages/AttendancePageV2/attendance.interface";
import CallMadeOutlinedIcon from "@material-ui/icons/CallMadeOutlined";
import CallReceivedOutlinedIcon from "@material-ui/icons/CallReceivedOutlined";
import EventAvailableOutlinedIcon from "@material-ui/icons/EventAvailableOutlined";
import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined";
import { SvgIconProps } from '@material-ui/core'


export const graphData = {
    labels: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
        22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    ],
    datasets: [
        {
            label: "Daily Attendance",
            data: [
                73, 89, 87, 43, 53, 24, 98, 98, 75, 68, 60, 31, 74, 26, 47, 70, 69, 28,
                49, 97, 61, 30, 42, 87, 65, 26, 71, 50, 28, 84, 34,
            ],
            backgroundColor: ["rgba(20, 0, 71, 0.8)"],
            borderColor: ["rgb(20, 0, 71)"],
            borderWidth: 1,
        },
    ],
};


export const lastThreeYears: number[] = [];
for (let i = 0; i < 3; i++) {
    lastThreeYears.push(new Date().getFullYear() - i);
}

export const allMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const userInfo = [
    {
        id: 1,
        label: "designation",
        title: "Designation",
        value: "",
    },
    {
        id: 2,
        label: "department",
        title: "Department",
        value: "",
    },
    {
        id: 3,
        label: "email",
        title: "Email Address",
        value: "",
    },
    {
        id: 4,
        label: "phone",
        title: "Phone Number",
        value: "",
    },
    {
        id: 5,
        label: "role",
        title: "Role",
        value: "",
    },
];

export const detailsBox: DetailsType[] = [
    {
        id: 1,
        label: "totalAttendance",
        title: "Total Attendance",
        icon: EventAvailableOutlinedIcon,
        desc: "The number of days present out of the total working days for the current month.",
    },
    {
        id: 2,
        label: "avgInTime",
        title: "Avg Check In Time",
        icon: CallReceivedOutlinedIcon,
        desc: "Typical time of arrival in the morning.",
    },
    {
        id: 3,
        label: "avgOutTime",
        title: "Avg Check Out Time",
        icon: CallMadeOutlinedIcon,
        desc: "Typical time of departure in the evening.",
    },
    {
        id: 4,
        label: "avgActiveHours",
        title: "Avg Active Hours",
        icon: VerifiedUserOutlinedIcon,
        desc: "Average duration of active work hours per day.",
    },
];

