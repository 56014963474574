import React, { Dispatch, SetStateAction } from "react";
import { SetterOrUpdater } from "recoil";
import { UPLOAD_DOCUMENT_SIZE } from "../../../../../utils/constants";

interface HandleDroppedWorkExperiencePrope {
  docType: string;
  file: any;
  experienceProof: File[] | any;
  setExperienceProof: React.Dispatch<React.SetStateAction<File[]>> | any;
  setIsFileSelected: SetterOrUpdater<{
    relieving: boolean;
    lastSal: boolean;
    secondLast: boolean;
    thirdLast: boolean;
  }>;
  setImgName: Dispatch<
    SetStateAction<{
      relivingLetter: string;
      salarySlip1st: string;
      salarySlip2nd: string;
      salarySlip3rd: string;
    }>
  >;
  setImgSize: Dispatch<
    SetStateAction<{
      relivingLetter: number;
      salarySlip1st: number;
      salarySlip2nd: number;
      salarySlip3rd: number;
    }>
  >;
  setIsSubmit: React.Dispatch<any>;
  showToast: any;
  setRewriteFileDownload: SetterOrUpdater<{
    personalPhoto: boolean;
    passportPhoto: boolean;
    // offerLetter: boolean;
    pan: boolean;
    aadhar: boolean;
    class10: boolean;
    class12: boolean;
    grad: boolean;
    postGrad: boolean;
    diploma: boolean;
    reliving: boolean;
    lastSalSlip: boolean;
    secondLastSalSlip: boolean;
    thirdLastSalSlip: boolean;
  }>;
}

export const HandleDroppedWorkExperience = ({
  docType,
  file,
  experienceProof,
  setExperienceProof,
  setImgName,
  setImgSize,
  setIsFileSelected,
  setIsSubmit,
  showToast,
  setRewriteFileDownload,
}: HandleDroppedWorkExperiencePrope) => {
  // RELIEVING
  if (docType === "RelievingLetter") {
    if (file.type == "application/pdf" && file.size <= UPLOAD_DOCUMENT_SIZE) {
      if (file) {
        setRewriteFileDownload((prev) => ({
          ...prev,
          reliving: true,
        }));
        setIsFileSelected((prev: any) => ({
          ...prev,
          relieving: true,
        }));
        setImgName((prev) => ({
          ...prev,
          relivingLetter: file.name,
        }));
        setImgSize((prev) => ({
          ...prev,
          relivingLetter: file.size,
        }));

        experienceProof.forEach((exp: any, index: number) => {
          if (exp.name === "relivingLetter") {
            experienceProof.splice(index, 1);
          }
        });

        setExperienceProof([
          ...experienceProof,
          {
            name: "relivingLetter",
            value: file,
          },
        ]);
        setIsSubmit((prev: any) => ({
          ...prev,
          reliving: true,
        }));
      } else {
        setRewriteFileDownload((prev) => ({
          ...prev,
          reliving: false,
        }));
      }
    } else {
      showToast.error("SELECT ONLY PDF FILES WITH MAXIMUM SIZE 1MB!");
      setIsSubmit((prev: any) => ({
        ...prev,
        reliving: false,
      }));
    }
  }
  // LAST SALARY SLIP
  else if (docType === "LastSalarySlip") {
    if (file.type == "application/pdf" && file.size <= UPLOAD_DOCUMENT_SIZE) {
      if (file) {
        setRewriteFileDownload((prev) => ({
          ...prev,
          lastSalSlip: true,
        }));
        setIsFileSelected((prev: any) => ({
          ...prev,
          lastSal: true,
        }));
        setImgName((prev) => ({
          ...prev,
          salarySlip1st: file.name,
        }));
        setImgSize((prev) => ({
          ...prev,
          salarySlip1st: file.size,
        }));

        experienceProof.forEach((exp: any, index: number) => {
          if (exp.name === "salarySlip1st") {
            experienceProof.splice(index, 1);
          }
        });

        setExperienceProof([
          ...experienceProof,
          {
            name: "salarySlip1st",
            value: file,
          },
        ]);
        setIsSubmit((prev: any) => ({
          ...prev,
          fileSalary1: true,
        }));
      } else {
        setRewriteFileDownload((prev) => ({
          ...prev,
          lastSalSlip: false,
        }));
      }
    } else {
      showToast.error("SELECT ONLY PDF FILES WITH MAXIMUM SIZE 1MB!");
      setIsSubmit((prev: any) => ({
        ...prev,
        fileSalary1: false,
      }));
    }
  }

  // SECOND LAST SAL SLIP
  else if (docType === "SecondLastSalarySlip") {
    if (file.type == "application/pdf" && file.size <= UPLOAD_DOCUMENT_SIZE) {
      if (file) {
        setRewriteFileDownload((prev) => ({
          ...prev,
          secondLastSalSlip: true,
        }));
        setIsFileSelected((prev: any) => ({
          ...prev,
          secondLast: true,
        }));
        setImgName((prev) => ({
          ...prev,
          salarySlip2nd: file.name,
        }));
        setImgSize((prev) => ({
          ...prev,
          salarySlip2nd: file.size,
        }));

        experienceProof.forEach((exp: any, index: number) => {
          if (exp.name === "salarySlip2nd") {
            experienceProof.splice(index, 1);
          }
        });

        setExperienceProof([
          ...experienceProof,
          {
            name: "salarySlip2nd",
            value: file,
          },
        ]);
        setIsSubmit((prev: any) => ({
          ...prev,
          fileSalary2: true,
        }));
      } else {
        setRewriteFileDownload((prev) => ({
          ...prev,
          secondLastSalSlip: false,
        }));
      }
    } else {
      showToast.error("SELECT ONLY PDF FILES WITH MAXIMUM SIZE 1MB!");
      setIsSubmit((prev: any) => ({
        ...prev,
        fileSalary2: false,
      }));
    }
  }

  // 3RD LAST SAL SLIP
  else {
    if (file.type == "application/pdf" && file.size <= UPLOAD_DOCUMENT_SIZE) {
      if (file) {
        setRewriteFileDownload((prev) => ({
          ...prev,
          thirdLastSalSlip: true,
        }));
        setIsFileSelected((prev: any) => ({
          ...prev,
          thirdLast: true,
        }));
        setImgName((prev) => ({
          ...prev,
          salarySlip3rd: file.name,
        }));
        setImgSize((prev) => ({
          ...prev,
          salarySlip3rd: file.size,
        }));

        experienceProof.forEach((exp: any, index: number) => {
          if (exp.name === "salarySlip3rd") {
            experienceProof.splice(index, 1);
          }
        });

        setExperienceProof([
          ...experienceProof,
          {
            name: "salarySlip3rd",
            value: file,
          },
        ]);
        setIsSubmit((prev: any) => ({
          ...prev,
          fileSalary3: true,
        }));
      } else {
        setRewriteFileDownload((prev) => ({
          ...prev,
          thirdLastSalSlip: false,
        }));
      }
    } else {
      showToast.error("SELECT ONLY PDF FILES WITH MAXIMUM SIZE 1MB!");
      setIsSubmit((prev: any) => ({
        ...prev,
        fileSalary3: false,
      }));
    }
  }
};
