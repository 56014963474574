import moment from "moment";
import { atom } from "recoil";
import { initialBehavioralAttributes } from "../pages/KRAPage/sub-pages/BehavioralAttributes";
import { initialTechnicalAttributes } from "../pages/KRAPage/sub-pages/TechnicalAttributes";

export interface IAttributeFields {
  id: string;
  KRA: string;
  weight: number;
  selfRating: number;
  appraiserRating: number;
}

export interface IAttributes {
  technical: IAttributeFields[];
  behavioral: IAttributeFields[];
}

export interface IFormData {
  empId: string;
  date: string;
  quarter: string;
  months: string;
  attributes: IAttributes;
}

const currentDate = new Date().toISOString();
const currentQuarter = moment().quarter().toString();

const formData: IFormData = {
  empId: "",
  date: currentDate,
  quarter: `Q${currentQuarter}`,
  months: "",
  attributes: {
    technical: initialTechnicalAttributes,
    behavioral: initialBehavioralAttributes,
  },
};

export const formDataAtom = atom({
  key: "formDataAtom",
  default: formData,
});

export type TMODE = "CREATE" | "EDIT" | "";

export const modeAtom = atom<TMODE>({
  key: "modeAtom",
  default: "",
});
