import React from 'react';
import LoginLayout from '../../layout/LoginLayout';
import OTPForm from '../../components/OTPForm';
import { Button, Grid, Typography } from '@material-ui/core';
import useStyles from './styles';
import SignInForm from '../../components/SignInForm';

const INITIAL_VALUES = {
  password: '',
  repassword:''
};

interface Props {
  loadPrimary: (event: any) => void;
}

const ForgotPassword: React.FC<Props> = ({loadPrimary}) => {
  loadPrimary(false)
  const classes = useStyles();
  const [minutes, setMinutes] = React.useState(1);
  const [seconds, setSeconds] = React.useState(0);
  const [values, setValues] = React.useState(INITIAL_VALUES);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [changePassword, setChangePassword] = React.useState(false);

  React.useEffect(() => {
    let interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });

  const resendOtpHandler = () => {
    setMinutes(1);
    setSeconds(0);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setSubmitting(true);
    
  };

  const handleChange = (e: any) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const verifyOTPHandler = () => {
    setChangePassword(true);
  };
  
  return (
    <>
      {!changePassword && (
        <LoginLayout text='An OTP has been sent to your email address'>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <OTPForm />
            </Grid>
            <Grid item xs={12}>
              <div>
                {minutes === 0 && seconds === 0 ? (
                  <Typography variant='subtitle2' align='center'>
                    OTP has expired
                  </Typography>
                ) : (
                  <Typography variant='subtitle2' align='center'>
                    {' '}
                    {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                  </Typography>
                )}
              </div>
              <div className={classes.verifyBtnContainer}>
                <Button
                  color='primary'
                  variant='contained'
                  className={classes.verifyBtn}
                  onClick={verifyOTPHandler}
                >
                  {' '}
                  VERIFY{' '}
                </Button>
              </div>
              {minutes === 0 && seconds === 0 && (
                <Typography align='center' variant='subtitle2'>
                  Didn't receive OTP?{' '}
                  <strong
                    className={classes.resendOtp}
                    onClick={resendOtpHandler}
                  >
                    RESEND
                  </strong>
                </Typography>
              )}
            </Grid>
          </Grid>
        </LoginLayout>
      )}
      {changePassword && (
        <LoginLayout text='Reset Your Password'>
          <SignInForm
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            values={values}
            isSubmitting={isSubmitting}
            isLogin={false}
            submitText='RESET'
          />
        </LoginLayout>
      )}
    </>
  );
};

export default ForgotPassword;
