import { makeStyles } from "@material-ui/core/styles";
import zIndex from "@material-ui/core/styles/zIndex";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: "100%",
    display: "flex",
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(20)}`,
  },

  buttonContainer: {
    flex: 1,
  },

  tableContainer: {
    flex: 3,
    paddingTop: theme.typography.pxToRem(12),
  },

  button: {
    position: "relative",
  },

  buttonHide: {
    display: "none",
  },

  roleAdd: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(13),
    color: "#0885DF",
    marginLeft: theme.typography.pxToRem(20),
    cursor: "pointer",
  },

  sort: {
    border: "1px solid rgba(20, 33, 61, 0.1)",
    borderRadius: "5px",
    background: "#fff",
  },

  sortCategory: {
    position: "absolute",
    borderRadius: "5px",
    background: "#fff",
    border: "1px solid rgba(20, 33, 61, 0.1)",
    boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.1)",
    marginTop: theme.typography.pxToRem(5),
    zIndex: 99,
  },

  sortCategoryHide: {
    display: "none",
  },

  categoryItems: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    padding: `${theme.typography.pxToRem(11)} ${theme.typography.pxToRem(16)}`,
    cursor: "pointer",
  },

  sortLabels: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    color: "#000",
    margin: 0,
  },

  back: {
    margin: 0,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
    fontFamily: "poppins",
    marginRight: theme.typography.pxToRem(12),
  },

  title: {
    margin: 0,
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 600,
    color: "#0885DF",
    fontFamily: "Poppins",
  },

  settings: {
    borderRadius: "5px",
    border: "1px solid rgba(20, 33, 61, 0.1)",
    height: theme.typography.pxToRem(38),
    width: theme.typography.pxToRem(38),
    display: "flex",
  },
  settingsHidden: {
    display: "none",
  },

  drawerContainer: {
    padding: `${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(
      25
    )} ${theme.typography.pxToRem(0)} ${theme.typography.pxToRem(37)}`,
  },

  backContainer: {
    cursor: "pointer",
  },

  tableContainerHide: {
    display: "none",
  },

  roleAddHide: {
    display: "none",
  },
  tableContain: {
    width: "100%",
    paddingTop: "2em",
    paddingBottom: "1em",
    borderRadius: "25px !important",
    height: "100%",
  },
  table: {
    width: "100%",
    // height: "40rem",
    borderRadius: "25px !important",
  },
  tableHeader: {
    padding: "1.5em .5em !important",
    background: "#14213D !important",
    color: "#ffffff !important",
    // "&:hover": {
    //   borderBottom: "1px solid black !important",
    //   background:"red"
    // },
  },
  tableHeaderWrapper: {
    // padding:"2em .5em !important"
  },
  tableHeaderInside: {
    borderRight: "2px solid white",
  },
  tableRowsParent: {
    "&:hover": {
      boxShadow: "0px 5px 10px rgb(0 0 0 / 15%)",
    },
  },
  tableRows: {
    padding: "1em ",
  },
  buttonInvite: {
    background: "rgb(6, 116, 226) !important",
    borderRadius: "20px",
    minWidth: "80%",
  },
  buttonSuccess: {
    background: "#0eab38",
    borderRadius: "20px",
    minWidth: "80%",
  },
  buttonPending: {
    background: "#f59842",
    // background:"#FBEF3C",
    borderRadius: "20px",
    minWidth: "80%",
  },
  paginate: {
    display: "flex",
    justifyContent: "end",
  },
}));
