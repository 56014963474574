import React from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from './styles';

interface IProps {
  message: string;
}

const ValidationError: React.FC<IProps> = ({ message }) => {
  const classes = useStyles();
  return (
    <Grid>
      <p className={classes.message}>{message}</p>
    </Grid>
  );
};

export default ValidationError;
