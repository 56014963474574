import React from "react";
import { useStyles } from "./styles";

interface Props {
  name: string;
  color: string;
}

const Legend: React.FC<Props> = ({ name, color }) => {
  const classes = useStyles();
  return (
    <div className={classes.legendContainer}>
      <div className={classes.dot} style={{ backgroundColor: color }}></div>
      <div className={classes.legend}>{name}</div>
    </div>
  );
};

export default Legend;
