import axios from "./axios.global";

export const login = async (payload: object) => {
  try {
    let response = await axios.post("/auth/login", payload);
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const googleLogin = async (payload: object) => {
  try {
    let response = await axios.post("/auth/google", payload);
    
    return response.data;
  } catch (err: any) {
    
    return err.response.data;
  }
};

export const getRoleData = async (payload: object) => {
  try {
    let response = await axios.get("/roles/role", payload);
    
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};
