import { useEffect, useState } from "react";
import axios from "../../apis/axios.global";

export const useEmployees = () => {
  const [employees, setEmployees] = useState<Employee[]>();
  useEffect(() => {
    (async () => {
      const { data } = await axios.get<IEmployee>(
        "/employees?include=_id,basicDetails.fullName&limit=100"
      );
      (() => {
        setEmployees(() =>
          data.docs.map((employee) => ({
            id: employee._id,
            fullName: employee.basicDetails.fullName,
          }))
        );
      })();
    })();
  }, []);
  return { employees };
};

export type IEmployee = {
  docs: {
    _id: string;
    basicDetails: {
      fullName: string;
    };
  }[];
};

export type Employee = {
  id: string;
  fullName: string;
};
