import { Button, Grid, InputLabel, Paper, Typography } from "@material-ui/core";
import { useRecoilState, useRecoilValue } from "recoil";
import AddCircleSharpIcon from "@material-ui/icons/AddCircleSharp";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { useStyles } from "./styles";
import {
  assetAllocationAtom,
  assetAllocationDetailsViewEnabled,
  AssetAllocationEditEnabled,
  recruiteeBasicDetails,
} from "../../../../../recoil/atoms";
import TextField from "../../../../Fields/TextField";
import { ChangeEvent, useEffect, useState } from "react";
import {
  assetAllocationGetInfo,
  assetAllocationInfoSubmit,
} from "../../../../../apis/onboarding";
import { setAssetAllocation } from "./utils";
import { lettersRegex } from "../../../../../utils/constants";

interface AssetAllocationsProps {
  showToast: any;
  index: number;
}

const AssetAllocations: React.FC<AssetAllocationsProps> = ({ showToast }) => {
  const classes = useStyles();
  const onboardUser = useRecoilValue(recruiteeBasicDetails);
  const [assetInfo, setAssetInfo] = useRecoilState(assetAllocationAtom);
  const [isViewEnabled, setIsViewEnabled] = useRecoilState(
    assetAllocationDetailsViewEnabled
  );

  const [isEdit, setIsEdit] = useRecoilState(AssetAllocationEditEnabled);
  const [isValidAssetId, setIsValidAssetId] = useState<boolean>(false);
  const [isValidAssetName, setIsValidAssetName] = useState<boolean>(false);
  const [toggle, setToggle] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const addMoreAsset = () => {
    setAssetInfo([
      ...assetInfo,
      {
        assetID: "",
        assetName: "",
      },
    ]);
  };

  const getAssetDetails = async () => {
    const getAsset = await assetAllocationGetInfo(onboardUser.id);
    const getAssetData = getAsset.data;
    if (getAssetData.length > 0) {
      setAssetAllocation({ assetInfo, getAssetData, setAssetInfo });
      getAssetData.forEach((data: any, index: number) => {
        if (data.assetID === "") {
          setIsValidAssetId(false);
        } else {
          setIsValidAssetId(true);
        }
        if (data.assetName === "") {
          setIsValidAssetName(false);
        } else {
          setIsValidAssetName(true);
        }
      });
    }
  };

  useEffect(() => {
    getAssetDetails();
  }, []);

  const checkSubmit = () => {
    let resultSubmit = true;

    const isEmptyString = assetInfo.some(
      (item) => item.assetID === "" || item.assetName === ""
    );

    //if isEmptyString true that means fields are empty
    resultSubmit = !isEmptyString;
    return resultSubmit;
  };

  const handleRemove = (index: number) => {
    const values = [...assetInfo];
    values.splice(index, 1);
    setAssetInfo(values);
    setIsValidAssetId(true);
    setIsValidAssetName(true);
  };

  const handleEdit = () => {
    setIsViewEnabled({
      assetAllocationViewEnabled: false,
    });
  };

  const handleChangeAssets = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number
  ) => {
    let val = [...assetInfo];

    if (
      event.target.name === "assetName" &&
      lettersRegex.test(event.target.value)
    ) {
      val.splice(index, 1, {
        ...assetInfo[index],
        assetName: event.target.value,
      });
      setAssetInfo(val);
      setIsValidAssetName(true);
    }
    if (event.target.name === "assetId") {
      val.splice(index, 1, {
        ...assetInfo[index],
        assetID: event.target.value,
      });
      setAssetInfo(val);
      setIsValidAssetId(true);
    }
  };

  const handleSubmit = () => {
    if (!isValidAssetName) {
      showToast.error("required");
      return;
    }
    if (!isValidAssetId) {
      showToast.error("required");
      return;
    }

    if (checkSubmit() && isValidAssetName && isValidAssetId) {
      assetAllocationInfoSubmit(assetInfo, onboardUser.id).then((res) => {

        if (res.status == 200) {
          showToast.success("ASSET DETAILS SAVED");
          getAssetDetails();

          setToggle(!toggle);

          setIsViewEnabled({
            assetAllocationViewEnabled: true,
          });
          return;
        } else {
          showToast.error("All Fields are required");
          return;
        }
      });
    } else {
      showToast.error("All fields are required!");
      return;
    }
  };

  return (
    <div className={classes.assetRoot}>
      <Grid container spacing={2} className={classes.container} direction="row">
        {assetInfo.length > 0 ? (
          assetInfo.map((value, index) => {
            return (
              <Grid item xs={12} sm={12} md={4}>
                <Paper
                  variant="outlined"
                  square
                  elevation={2}
                  className={classes.assetBox}
                >
                  <Typography className={classes.titleContainer}>
                    Asset {index + 1}
                  </Typography>

                  <Grid
                    container
                    direction="column"
                    className={classes.assetFormContainer}
                  >
                    <Grid item>
                      {isViewEnabled.assetAllocationViewEnabled ? (
                        <>
                          <InputLabel variant="outlined" shrink>
                            <b>{`Asset Name`}</b>
                          </InputLabel>
                          <Typography className={classes.viewText}>
                            {assetInfo[index].assetName}
                          </Typography>
                        </>
                      ) : (
                        <TextField
                          type="text"
                          name="assetName"
                          id="assetName"
                          label="Asset Name"
                          fullWidth
                          className={classes.assetTextField}
                          value={value.assetName}
                          variant={undefined}
                          onChange={(
                            event: ChangeEvent<
                              HTMLTextAreaElement | HTMLInputElement
                            >
                          ) => handleChangeAssets(event, index)}
                        />
                      )}
                    </Grid>
                    <Grid item>
                      {isViewEnabled.assetAllocationViewEnabled ? (
                        <>
                          <InputLabel variant="outlined" shrink>
                            <b>{`Asset ID`}</b>
                          </InputLabel>
                          <Typography className={classes.viewText}>
                            {assetInfo[index].assetID}
                          </Typography>
                        </>
                      ) : (
                        <TextField
                          type="text"
                          name="assetId"
                          id="assetId"
                          label="Asset ID"
                          fullWidth
                          className={classes.assetTextField}
                          value={value.assetID}
                          variant={undefined}
                          onChange={(
                            event: ChangeEvent<
                              HTMLTextAreaElement | HTMLInputElement
                            >
                          ) => handleChangeAssets(event, index)}
                        />
                      )}
                    </Grid>
                  </Grid>

                  {isViewEnabled.assetAllocationViewEnabled ? (
                    <></>
                  ) : (
                    <Button
                      onClick={() => handleRemove(index)}
                      disableRipple={true}
                      disableFocusRipple={true}
                      title="Remove"
                      className={classes.removeButton}
                    >
                      <RemoveCircleIcon /> &nbsp; Remove
                    </Button>
                  )}
                </Paper>
              </Grid>
            );
          })
        ) : (
          <Typography>No Asset Allocated</Typography>
        )}
      </Grid>
      <Grid>
        {isViewEnabled.assetAllocationViewEnabled ? (
          <></>
        ) : (
          <Button
            disableFocusRipple={true}
            disableRipple={true}
            onClick={addMoreAsset}
          >
            <AddCircleSharpIcon />
            Add New Asset
          </Button>
        )}
      </Grid>

      <Grid item xs={12} md={12} sm={12} className={classes.actionarea}>
        {isViewEnabled.assetAllocationViewEnabled ? (
          <Button
            variant="contained"
            className={classes.submitButton}
            onClick={handleEdit}
          >
            Edit
          </Button>
        ) : isEdit.assetAllocationDetailsEdit ? (
          <Button
            variant="contained"
            className={classes.submitButton}
            onClick={handleSubmit}
          >
            Done
          </Button>
        ) : (
          <Button
            variant="contained"
            className={classes.submitButton}
            onClick={handleSubmit}
          >
            Done
          </Button>
        )}
      </Grid>
    </div>
  );
};

export default AssetAllocations;
