import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  message: {
    margin: 0,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 500,
    color: "red",
    fontFamily: "Poppins",
    marginBottom: theme.typography.pxToRem(5),
  },
}));
