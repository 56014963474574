import { Box, TextField } from "@material-ui/core";
import { ChangeEvent } from "react";
import SearchIcon from "../../../assets/icons/search.svg";

export interface ISearchBar {
  searchText: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const SearchBar = ({ searchText, handleChange }: ISearchBar) => {
  return (
    <TextField
      variant="outlined"
      size="small"
      placeholder="Search for employee"
      style={{
        fontSize: ".75em",
        fontWeight: 500,
        color: "rgba(20, 33, 61, 0.5)",
        borderRadius: "10px",
        border: "1px solid rgba(20, 33, 61, 0.15)",
      }}
      value={searchText}
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <img
            src={SearchIcon}
            alt="search-icon"
            style={{ marginRight: ".5em" }}
          />
        ),
      }}
    />
    // </Box>
  );
};

export default SearchBar;
