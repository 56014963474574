import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  root: {
    padding: "3%",
  },
  formControlsWidthFull: {
    minWidth: "100%",
  },
  formControlsWidth: {
    minWidth: "100%",
  },
  formControlsWidth6: {
    minWidth: "100%",
  },
  uploadImgContainerView: {
    textAlign: "center",
    position: "relative",
  },
  uploadImgContainer: {
    textAlign: "center",
    position: "relative",
    // marginTop: "7%",
    // display: "inline-block",
  },
  profileImage: {
    // display: "block",
    width: 130,
    height: 130,
    borderRadius: "100%",
  },
  uploadImgButton: {
    position: "relative",
    bottom: 0,
    right: 40,
    width: 10,
    // left: "75%",
    // top: "55%",
    // transform: "translate(-50%, -50%)",
    borderRadius: "100%",
    paddingTop: "1em",
    paddingBottom: "1em",
    // paddingTop: "1rem",
    // paddingBottom: "1rem",
  },
  checkAddressContainer: {
    marginTop: "0.3em",
  },
  checkBox: {
    paddingTop: "0.5em",
  },
  checkText: {
    color: "#4C4C4CB2",
  },

  permanentAdrsRoot: {
    paddingBottom: "2em",
  },

  emergencyHead: {
    marginBottom: "2em",
    color: "#5E5E5E",
    fontSize: "14px",
  },
  emergencyContent: {
    fontSize: "13px",
  },
  saveButton: {
    marginTop: "2em",
    backgroundColor: "#14213D",
    color: "#fff",
    width: "132px",
    height: "44px",
  },
  viewPadding: {
    paddingTop: "1em",
  },
  viewPadding2em: {
    paddingTop: "2em",
  },
  viewTextLabel: {
    textAlign: "left",
    width: "100%",
  },
  viewAddressTextLabel: {
    textAlign: "left",
    width: "100%",
    paddingTop: "1em",
  },
  viewText: {
    textAlign: "left",
    paddingLeft: "1em",
    width: "100%",
  },
  viewAddressText: {
    textAlign: "left",
    paddingLeft: "1em",
    paddingTop: "1.5em",
  },
  errorMessage: {
    color: "#9e0208",
    fontSize: "13px",
  },
});
