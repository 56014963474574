import React from "react";
import { Input } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useStyles } from "./styles";

interface props {
    setSearchKey:React.Dispatch<React.SetStateAction<string>>,
}

const Search: React.FC<props> = ({setSearchKey}) => {
  const classes = useStyles();
  const searchHandler = (e: any) => {
    setSearchKey(e.target.value);
  };
  return(
    <div className={classes.search}>
    <Input
      disableUnderline={true}
      fullWidth={true}
      placeholder="search"
      onChange={(e) => searchHandler(e)}
      className={classes.searchInput}
    />
    <SearchIcon />
  </div>
  );
};

export default Search;
