import React, { useContext, useEffect, useState } from "react";
import CirclePlusIcon from "../../../../components/customIcons/circlePlusIcon/CirclePlusIcon";
import TechnologyCard from "../../../../components/Cards/TechnologyCard";
import SelectSearchBar from "../../../../components/selectSearchBar";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import Post from "../../components/Post";
import classNames from "classnames";
import { useStyles } from "./styles";
import useParentStyles from "../../styles";
import { searchPost } from "../../../../apis/kHubPost";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  kHubCategoryAtom,
  KHubPost,
  kHubPostAtom,
  kHubPostTechsAtom,
  kHubQueryAtom,
} from "../../../../recoil/atoms";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { getPersistentAuthentication } from "../../../../utils/functions";
import { Toast } from "../../KnowledgeHubPage";

interface props {
  showModal: () => void;
  logos: any;
  pageNo: number;
  paginate: () => void;
  isLoadedByPageNo: boolean;
  setIsLoadedByPageNo: React.Dispatch<React.SetStateAction<boolean>>;
  setPageNo: React.Dispatch<React.SetStateAction<number>>;
}

const DashBoardView: React.FC<props> = ({
  showModal,
  logos,
  pageNo,
  paginate,
  isLoadedByPageNo,
  setIsLoadedByPageNo,
  setPageNo,
}) => {
  const classes = useStyles();
  const parentClasses: ClassNameMap = useParentStyles();

  const [posts, setPosts] = useRecoilState(kHubPostAtom);
  const [category, setCategory] = useRecoilState(kHubCategoryAtom);
  const [loading, setLoading] = useState<boolean>(false);
  const [isBySearch, setIsBySearch] = useState(false);
  const techs = useRecoilValue(kHubPostTechsAtom);
  const query = useRecoilValue(kHubQueryAtom);
  const userId = getPersistentAuthentication()?.userId;
  const showToast = useContext(Toast);

  // function to fetch the posts based on category
  async function fetch() {
    if (!isLoadedByPageNo) setLoading(true);
    let data = [];
    if (query.length > 0) {
      try {
        data = await searchPost({ title: query, page: 200, category: "" });
      } catch (error: any) {
        showToast.error(error.message);
      }
    } else {
      try {
        data = await searchPost({
          title: "",
          page: pageNo,
          category: category,
        });
      } catch (error: any) {
        showToast.error(error.message);
      }
    }
    setPosts(data);
    setLoading(false);
    setIsLoadedByPageNo(false);
    setIsBySearch(false);
  }

  // use effect to trigger fetch post for the first time and re trigger when page no. or category changes
  useEffect(() => {
    fetch();
  }, [pageNo, category]);

  // function to set the selected category
  function searchByCategory(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    const category = (e.target as any).id;
    if (category === "") return;
    setCategory(category);
    setPageNo(1);
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <section className={classes.topBar}>
          <div className={classes.searchBar}>
            <SelectSearchBar pageNo={pageNo} setIsBySearch={setIsBySearch} />
          </div>
          <div className={classes.iconAndButton}>
            <Button
              onClick={showModal}
              className={classNames(parentClasses.btnBase, classes.addNewDoc)}
              disableElevation
              size="large"
              startIcon={<CirclePlusIcon />}
              variant="contained"
            >
              Add New Documentation
            </Button>
          </div>
        </section>
      </Grid>
      <Grid item xs={12}>
        <section onClick={searchByCategory} className={classes.techSec}>
          {techs.map((tech: string, i: number) => (
            <TechnologyCard
              key={i}
              image={logos[i]}
              label={tech}
              styleProps={{
                avatarBgColor: "#6633F2",
              }}
            />
          ))}
        </section>
      </Grid>
      <Grid item xs={12}>
        <section className={classes.postSec}>
          {loading ? (
            <CircularProgress color="primary" />
          ) : (
            posts.map((post: KHubPost) => {
                return <Post key={post._id} post={post} fetch={fetch} />;
            })
          )}
          {!loading &&
            posts.length >= 5 &&
            (isLoadedByPageNo ? (
              <CircularProgress color="primary" />
            ) : (
              !isBySearch && (
                <Button
                  onClick={paginate}
                  className={classNames(parentClasses.btnBase, classes.seeMore)}
                  variant="contained"
                  disableElevation
                >
                  See More
                </Button>
              )
            ))}
        </section>
      </Grid>
    </Grid>
  );
};

export default DashBoardView;
