import React from 'react';
import OtpInput from 'react-otp-input';
import useStyles from './styles';

const OTPForm: React.FC = () => {
  const [otp, setOtp] = React.useState('');
  const classes = useStyles();
  const handleChange = (otp: string) => {
    setOtp(otp);
  };

  return (
    <div className={classes.otpInput}> 
      <OtpInput
        value={otp}
        onChange={handleChange}
        numInputs={6}
        separator={
          <span>
            <strong>.</strong>
          </span>
        }
        inputStyle={{
          width: 40,
          height: 40,
          margin: '6px',
          fontSize: 20,
          borderRadius: 50,
          border: '1px solid rgba(0,0,0,0.3)',
        }}
      />
    </div>
  );
};

export default OTPForm;
