import moment from "moment";

export const formatData = (data: any[], startDate: string, endDate: string) => {
  // console.log("dataMainDate", data[0]?._id);

  let dataWeek: string[] = weekHeaders();
  let result: any[] = [];
  for (let i = 0; i < data.length; i++) {
    // console.log("dataa", data[i]?._id == dataWeek[i]);
    // if (dataWeek.includes(data[i].date)) {

    let attendance = data[i].employees;

    for (let j = 0; j < attendance?.length; j++) {
      let employee = attendance[j];
      // Check if the employee exists in the result array
      let index = result.findIndex((e) => e.employeeName === employee.empName);

      if (
        index === -1 &&
        employee?.empName !== "Name" &&
        employee?.empName !== "Founders" &&
        employee?.empName !== ""
      ) {
        // If the employee does not exist in the result array, add a new entry
        // console.log("dataMain", data[i]);
        result.push({
          employeeName: employee?.empName,
          date: data[i]?._id,
          weeklyAttendance: [
            {
              activeHour: employee?.activeHour,
              breakTime: employee?.breakTime,
              empName: employee?.empName,
              floor: employee?.floor,
              inTime: employee?.inTime,
              outTime: employee?.outTime,
              date: data[i]?._id,
            },
          ],
        });
      } else {
        // If the employee exists in the result array, add the attendance information
        result[index]?.weeklyAttendance.push({
          activeHour: employee?.activeHour,
          breakTime: employee?.breakTime,
          empName: employee?.empName,
          floor: employee?.floor,
          inTime: employee?.inTime,
          outTime: employee?.outTime,
          date: data[i]?._id,
        });
      }
    }
    // }
  }
  // console.log("result", result);

  let weekdata: any[] = weekHeaders();

  result.forEach((item: any, key: any) => {
    if (item.weeklyAttendance.length < 5) {
      for (let k = item.weeklyAttendance.length; k < 5; k++) {
        item.weeklyAttendance[k] = {
          activeHour: "NA",
          breakTime: "NA",
          empName: "NA",
          floor: "NA",
          inTime: "NA",
          outTime: "NA",
          date: "NA",
        };
      }
    }
  });

  function filterWeek(date: string) {
    const date1 = moment(date, "DD/MM/YYYY");
    // console.log(
    //   "itemdate",
    //   moment(result[0]?.date, "DD/MM/YYYY").format("YYYY-MM-DD")
    // );
    // console.log(
    //   "itemstartDay",
    //   moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD")
    // );
    // console.log(
    //   "itemendDay",
    //   moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD")
    // );
    // Format the dates as "YYYY-MM-DD" for comparison
    return date1.format("YYYY-MM-DD");
  }
  let start_date = filterWeek(startDate);
  let end_date = filterWeek(endDate);
  let weeklyData = result.filter(
    (item: any) =>
      filterWeek(item.date) >= start_date && filterWeek(item.date) <= end_date
  );
  // console.log("fffff", weeklyData);
  result.sort(function (event1, event2) {
    const date1 = moment(event1.date, "YYYY-MM-DD");
    const date2 = moment(event2.date, "YYYY-MM-DD");

    if (date1.isBefore(date2)) {
      return -1;
    } else if (date1.isAfter(date2)) {
      return 1;
    } else {
      return 0;
    }
  });
  // console.log("fffff", result);

  return result;
};

export const weekHeaders2 = () => {
  let lastFiveDays = [];

  // Loop 5 times to get the previous 4 days
  for (let i = 0; i < 5; i++) {
    // Subtract one day from the current date
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - i - 1);

    // Format the date as "DD/MM/YYYY" and add it to the array
    lastFiveDays.push(currentDate.toLocaleDateString("en-GB"));
  }

  // Print the array of last 5 days
  // console.log({ lastFiveDays });
  return lastFiveDays;
};

export const weekHeaders = () => {
  let today = moment();
  let monday = moment().startOf("week").add(1, "days");
  let friday = moment().startOf("week").add(5, "days");
  let dates = [];

  for (
    let date = moment(monday);
    date.diff(friday, "days") <= 0;
    date.add(1, "days")
  ) {
    dates.push(date.format("DD/MM/YYYY"));
  }

  return dates;
};

export const paginateData = (pageNo: number, data: any[]) => {
  let startPage = pageNo - 1;
  let pageIndex = startPage * 12;
  let paginateData = data?.slice(pageIndex, pageIndex + 12);
  return paginateData;
};
export const formatDataSearch = (data: any[]) => {
  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const formattedData = [];

  // Loop through each object in the data array
  for (const obj of data) {
    // Get the date from the _id property of the object
    const date = obj._id;
    const day = new Date(date).getDay();

    // Check if the day is not a weekend day
    if (day !== 0 && day !== 6) {
      // Loop through each employee in the employees array
      for (const emp of obj.employees) {
        // Check if the employee has inTime and outTime
        if (emp.inTime && emp.outTime) {
          // Calculate the activeHour
          const inTime: any = new Date(
            `2023-01-${date.split("/")[0]}T${emp.inTime}:00`
          );
          const outTime: any = new Date(
            `2023-01-${date.split("/")[0]}T${emp.outTime}:00`
          );
          const diff: any = Math.abs(outTime - inTime);
          const activeHour = `${Math.floor(diff / 3600000)}:${Math.floor(
            (diff % 3600000) / 60000
          )}`;

          // Add the formatted object to the formattedData array
          const formattedObj = {
            employeeName: emp.empName,
            weeklyAttendance: [
              {
                empName: emp.empName,
                inTime: emp.inTime,
                outTime: emp.outTime,
                activeHour: activeHour,
                breakTime: emp.breakTime,
                floor: emp.floor,
                date: date,
              },
            ],
          };

          // Check if the employee already exists in the formattedData array
          const index = formattedData.findIndex(
            (item) => item.employeeName === emp.empName
          );
          if (index === -1) {
            formattedData.push(formattedObj);
          } else {
            formattedData[index].weeklyAttendance.push(
              formattedObj.weeklyAttendance[0]
            );
          }
        }
      }
    }
  }
  formattedData.forEach((item: any, key: any) => {
    if (item.weeklyAttendance.length < 5) {
      for (let k = item.weeklyAttendance.length; k < 5; k++) {
        item.weeklyAttendance[k] = {
          activeHour: "NA",
          breakTime: "NA",
          empName: "NA",
          floor: "NA",
          inTime: "NA",
          outTime: "NA",
          date: "NA",
        };
      }
    }
  });
  return formattedData;
};

export const formatNewAttendanceInfo = (
  data: any,
  startDate: string,
  endDate: string,
  weekDate: string[]
) => {
  // Initialize variables for last week's date range
  const today = moment();
  const lastWeekStart = today
    .clone()
    .subtract(1, "week")
    .startOf("week")
    .add(1, "day"); // Monday of last week
  const lastWeekEnd = today
    .clone()
    .subtract(1, "week")
    .endOf("week")
    .subtract(1, "day"); // Friday of last week

  // Initialize an empty object to store weekly attendance data for each employee
  const weeklyAttendanceData: any = {};

  // Loop through the original array of objects
  data.forEach((obj: any) => {
    // If date is within last week and there are employees present in the object

    // console.log("hiiiiii",isDateInLastWeek)

    const dateObj = moment(`${obj._id}`, "DD/MM/YYYY").unix();
    const start_date = moment(startDate, "DD/MM/YYYY").unix();
    const end_date = moment(endDate, "DD/MM/YYYY").unix();

    if (obj.employees.length && dateObj >= start_date && dateObj <= end_date) {
      obj.employees.forEach((emp: any) => {
        if (
          emp.inTime == "INTime" ||
          emp.empName == "" ||
          emp.empName == "Founders"
        )
          return null;

        if (emp.inTime !== "INTime" || emp.empName !== "") {
          // Ignore employees with non-calculative active hours
          const empName = emp.empName;

          // Initialize a new object to store attendance data for this employee
          const attendanceData = {
            empName: empName,
            inTime: emp.inTime,
            outTime: emp.outTime,
            activeHour: emp.activeHour,
            breakTime: emp.breakTime,
            floor: emp.floor,
            date: obj._id,
          };

          // If employee already has attendance data for this week, add the new data to the existing array
          if (weeklyAttendanceData.hasOwnProperty(empName)) {
            weeklyAttendanceData[empName].weeklyAttendance.push(attendanceData);
          }
          // Otherwise, create a new object for this employee and initialize the weeklyAttendance array
          else {
            weeklyAttendanceData[empName] = {
              employeeName: empName,
              weeklyAttendance: [attendanceData],
            };
          }
        }
      });
    }
  });
  // console.log({ weeklyAttendanceData });

  // Output the final weekly attendance data array for all employees
  const weeklyAttendanceArray = Object.values(weeklyAttendanceData);

  let mainTableData: any = [];
  let weekArray: string[] = weekDate;

  let tableFormattedData: any = [];

  weeklyAttendanceArray.forEach((item: any, key: any) => {
    let output: any = {
      employeeName: item.employeeName,
      weeklyAttendance: [],
    };

    for (let date of weekArray) {
      let attendanceObj: any = item.weeklyAttendance.find(
        (obj: any) => obj.date == date
      );
      if (attendanceObj) {
        output.weeklyAttendance.push(attendanceObj);
      } else {
        output.weeklyAttendance.push({
          empName: "NA",
          inTime: "NA",
          outTime: "NA",
          activeHour: "NA",
          breakTime: "NA",
          floor: "NA",
          date: "NA",
        });
      }
    }
    tableFormattedData.push(output);
  });

  return tableFormattedData;
};

export const formatIndividualAttendanceInfo = (
  data: any,
  startDate: string,
  endDate: string
) => {
  // console.log("ddhghd",endDate)
  // Initialize variables for last week's date range
  const today = moment();
  const lastWeekStart = today
    .clone()
    .subtract(1, "week")
    .startOf("week")
    .add(1, "day"); // Monday of last week
  const lastWeekEnd = today
    .clone()
    .subtract(1, "week")
    .endOf("week")
    .subtract(1, "day"); // Friday of last week

  let filterData: object[] = [];

  // Loop through the original array of objects
  data.forEach((obj: any) => {
    // If date is within last week and there are employees present in the object

    // console.log("hiiiiii",isDateInLastWeek)

    const dateObj = moment(`${obj.date}`, "DD/MM/YYYY").unix();
    const start_date = moment(startDate, "DD/MM/YYYY").unix();
    const end_date = moment(endDate, "DD/MM/YYYY").unix();

    if (obj.employees.length && dateObj >= start_date && dateObj <= end_date) {
      filterData.push(obj);
    }
  });
  return filterData;
};

export const breakNumberCalculation = (data: any) => {
  let emptyFieldName = "_0";

  for (let i = 1; i <= 14; i++) {
    const fieldName = `_${i}`;

    if (data[fieldName] == "--:--" || data["_1"] == "") {
      emptyFieldName = "_0";
      break;
    } else if (i > 2 && data[fieldName] == "") {
      emptyFieldName = fieldName;
      break;
    }
    else emptyFieldName = "_14"
  }

  let breakTaken = emptyFieldName.split("_");
  let breakTakenFinal = Math.round(JSON.parse(breakTaken[1]) / 2);
  // console.log("o", breakTakenFinal);
  return breakTakenFinal;
};
