import { AxiosResponse } from "axios";
import { useState, useEffect, useMemo } from "react";
import axios from "../apis/axios.global";

export interface IUseFetchMemorized<T> {
  queryFn: () => Promise<T>;
  staleTime?: number;
  onSuccess?: (data: T) => void;
  onError?: (error: Error) => void;
}

function useFetchMemorized<T>({
  queryFn,
  staleTime = 30000,
  onSuccess,
  onError,
}: IUseFetchMemorized<T>) {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(0);

  const fetchData = async () => {
    setIsLoading(true);
    setIsFetching(true);
    try {
      const response = await queryFn();
      setData(response);
      setIsLoading(false);
      setIsFetching(false);
      if (onSuccess) {
        await onSuccess(response);
      }
      setLastUpdated(Date.now());
    } catch (error: any) {
      setError(error);
      setIsLoading(false);
      setIsFetching(false);
      if (onError) {
        onError(error);
      }
    }
  };

  useEffect(() => {
    const shouldFetchData = Date.now() - lastUpdated > staleTime;
    if (shouldFetchData) {
      fetchData();
    }
  }, []);

  const memoizedData = useMemo(() => data, [data]);

  return {
    data: memoizedData,
    error,
    isLoading,
    isFetching,
    refetch: fetchData,
  };
}

export default useFetchMemorized;
