import React from "react";
import { useStyles } from "./styles";

interface Props {
  type: string;
}

const CalendarDot: React.FC<Props> = ({ type }) => {
  const classes = useStyles();
  const getDotColor = (leavetype: string) => {
    switch (leavetype) {
      case "casualLeave":
        return "#F3D90C";
      case "priviledgeLeave":
        return "#56F30C";
      case "sickLeave":
        return "#F3890C";
      case "halfDayLeave":
        return "#0CF3F3";
      case "holiday":
        return "#D30CF3";

      default:
        return "";
    }
  };

  return (
    <div
      className={classes.dot}
      style={{ backgroundColor: getDotColor(type) }}
    ></div>
  );
};

export default CalendarDot;
