import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: "100%",
    height: "100%",
    minWidth: "150px",
    background: "#0885DF",
    borderRadius: "17px",
    zIndex: 10,
    margin: "auto",
  },

  mainContainerYellow: {
    background: "#E38630",
  },

  mainContainerGreen: {
    background: "#34E330",
  },

  mainContainerGreenWithBorder: {
    background: "#DCFFD9",
    border: "0.5px solid #12A005",
  },
  mainContainerYellowWithBorder: {
    background: "#FFF2E6",
    border: "0.5px solid #FF7A00",
  },

  mainContainerRedWithBorder: {
    background: "#FFE6E6",
    border: "0.5px solid #D90202",
  },

  label: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(12),
    color: "#fff",
  },
  labelGreen: {
    color: "#12A005",
    fontSize: theme.typography.pxToRem(9),
  },
  labelYellow: {
    color: "#FF7A00",
    fontSize: theme.typography.pxToRem(9),
  },

  labelRed: {
    color: "#D90202",
    fontSize: theme.typography.pxToRem(9),
  },

  labelBlack: {
    color: "#000",
  },

  mainContainerLightYellow: {
    background: "#F3D90C",
  },

  icon: {
    marginLeft: theme.typography.pxToRem(5),
  },
}));
