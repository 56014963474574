import React, { useContext, useState } from "react";
import {
  Grid,
  Button,
  Box,
  Typography,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import BackupIcon from "@material-ui/icons/Backup";
import { useStyles } from "../style";
import Tables from "../../../Tables";
import { HeadCell, fileTypes } from "../../../../types";
import DescriptionIcon from "@material-ui/icons/Description";
import moment from "moment";
import { addProjectSOW, deleteSowFile } from "../../../../../../apis/projects";
import { useParams } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import { Toast } from "../../../../ProjectsPage";
import { useDropzone } from "react-dropzone";
import { usePermissionObjectFor } from "../../../../../../hooks/permission/usePermissionObjectFor";

const MAXFILESIZE = 2097152;
interface Iprops {
  projectRequirement: fileTypes[] | undefined;
  fetchRequirements: () => Promise<void>;
}
const ProjectSOW: React.FC<Iprops> = ({
  projectRequirement,
  fetchRequirements,
}) => {
  const classes = useStyles();
  const showToast = useContext(Toast);
  const { id } = useParams<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const permission = usePermissionObjectFor("projects");
  const hasCreateAccess = permission.required("upDate");

  const headCells: HeadCell[] = [
    {
      id: "url",
      disablePadding: true,
      label: "Files",
      width: "60%",
    },
    {
      id: "createdAt",
      disablePadding: false,
      label: "Uploaded Date",
      width: "20%",
    },
    {
      id: "addedBy",
      disablePadding: false,
      label: "Uploaded By",
      width: "20%",
    },
  ];

  const deleteHandler = async (sowId: string) => {
    setIsLoading(true);
    try {
      await deleteSowFile(id, sowId);
      showToast.success("Deleted Successfully!");
      setIsLoading(false);
      fetchRequirements();
    } catch (err: any) {
      showToast.error(err.response.data.message);
      setIsLoading(false);
      fetchRequirements();
    }
  };

  const showFiles = (item: fileTypes) => {
    return (
      <div
        className={classes.imageGroup}
        onClick={() => window.open(item.url, "_blank", "noopener,noreferrer")}
      >
        <DescriptionIcon />
        <Typography className={classes.fileName}> {item.name}</Typography>
      </div>
    );
  };

  const showWithdeleteButton = (item: fileTypes) => {
    return (
      <div className={classes.withDeleteContainer}>
        <span>{item.addedBy}</span>
        {hasCreateAccess && (
          <IconButton
            className={classes.buttonWithNoPadding}
            onClick={() => deleteHandler(item._id)}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </div>
    );
  };

  const generateTableRow = () => {
    return projectRequirement?.map((item) => ({
      url: showFiles(item),
      createdAt: moment(item.createdAt).format("DD-MM-YYYY"),
      addedBy: showWithdeleteButton(item),
    }));
  };

  const sowItems = generateTableRow();

  const uploadFileHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    showToast.success("Uploading File..");
    setIsLoading(true);
    const formData = new FormData();
    let files = e.target.files as FileList;

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > MAXFILESIZE) {
        showToast.error("File Size too large!");
        setIsLoading(false);
        return;
      }
      formData.append("attachment", files[i]);
    }

    try {
      await addProjectSOW(id, formData);
      showToast.success("Successfully Uploaded!");
      setIsLoading(false);
      fetchRequirements();
    } catch (err: any) {
      showToast.error(err.response.data.message);
      setIsLoading(false);
      fetchRequirements();
    }
  };

  const { getRootProps: getRootPropsDocs, getInputProps: getInputPropsDocs } =
    useDropzone({
      onDrop: async (acceptedFiles) => {
        setIsLoading(true);
        const formData = new FormData();
        let files = acceptedFiles;
        for (let i = 0; i < files.length; i++) {
          if (files[i].size > MAXFILESIZE) {
            showToast.error("File Size too large!");
            setIsLoading(false);
            return;
          }
          formData.append("attachment", files[i]);
        }

        try {
          await addProjectSOW(id, formData);
          showToast.success("Successfully Uploaded!");
          setIsLoading(false);
          fetchRequirements();
        } catch (err: any) {
          showToast.error(err.response.data.message);
          setIsLoading(false);
          fetchRequirements();
        }
      },
      noClick: true,
    });

  return (
    <Grid container direction="row" xs={12} spacing={2}>
      {hasCreateAccess && (
        <Grid item xs={12}>
          <Box
            border="2px dashed rgba(0, 0, 0, 0.25)"
            borderRadius="10px"
            padding="2rem"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            my="1rem"
            {...getRootPropsDocs()}
          >
            <input {...getInputPropsDocs()} />
            {isLoading ? (
              <div>
                <CircularProgress color="primary" />
              </div>
            ) : (
              <Button component="label">
                <BackupIcon fontSize="large" />
                <input
                  type="file"
                  hidden
                  onChange={(e) => uploadFileHandler(e)}
                  accept=".docx, .doc, .pdf, .xls"
                  multiple
                />
              </Button>
            )}
            <Typography>Drag & drop files or Browse</Typography>

            <Typography className={classes.acceptedfilestext}>
              .docx, .doc, .pdf , .xls {"( max 2mb )"}
            </Typography>
          </Box>
        </Grid>
      )}
      <Grid container item xs={12} direction="row">
        <Grid item xs={12}>
          <Typography className={classes.attachedfilestext}>
            Attached Files
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.innertext}>
            Sample of files and assets that have been attached to this project
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {isLoading ? (
            <div className={classes.loading}>
              <CircularProgress color="primary" />
            </div>
          ) : (
            <Tables headCells={headCells} items={sowItems ?? []} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ProjectSOW;
