import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { getFamNLangInfo } from "../../../../../apis/onboarding";
import { onboardingIdAtom } from "../../../../../recoil/atoms";
import FamilyAndLanguageEdit from "../../../../AccordionComponent/FamilyAndLanguageEdit";

type famAndLangType = {
  family: {
    spouse: {
      name: "";
      dependent: "";
      occupation: "";
      dob: "";
    };
    mother: {
      name: "";
      dependent: "";
      occupation: "";
      dob: "";
    };
    father: {
      name: "";
      dependent: "";
      occupation: "";
      dob: "";
    };
  };
  languages: [
    {
      languageName: "English";
      speak: false;
      read: false;
      write: false;
    },
    {
      languageName: "Hindi";
      speak: false;
      read: false;
      write: false;
    },
    {
      languageName: "Assamese";
      speak: false;
      read: false;
      write: false;
    },
    {
      languageName: "Bengali";
      speak: false;
      read: false;
      write: false;
    }
  ];
  married: "";
};

const FamilyAndLanguages = () => {
  const onboardingIds = useRecoilValue(onboardingIdAtom);
  const [famLangData, setFamLangData] = useState<famAndLangType | null>(null);
  const [toggleUser, setToggleUser] = useState(false);

  const getFamilyLangFunc = async () => {
    if (
      onboardingIds.FamLang === null ||
      onboardingIds.FamLang === undefined ||
      onboardingIds.FamLang === ""
    ) {
      return;
    }
    setFamLangData(await getFamNLangInfo(onboardingIds.FamLang));
    setToggleUser(!toggleUser);
  };

  useEffect(() => {
    getFamilyLangFunc();
  }, [onboardingIds.FamLang]);

  return (
    <div>
      FamilyAndLanguages
      <FamilyAndLanguageEdit
        employeeId={onboardingIds.FamLang}
        familyAndLanguageInitialData={famLangData}
        calledFromEmployee={false}
      />
    </div>
  );
};

export default FamilyAndLanguages;
