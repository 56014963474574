import React, { useState, useEffect, createContext } from "react";
import BasicLayout from "../../components/BasicLayout";
import waveHandIcon from "../../assets/images/waving-hand.svg";
import Button from "../../components/CustomButton";
import { useBasicNav } from "../../utils/useBasicNav";
import moment from "moment";
import { Grid } from "@material-ui/core";
import { useStyles } from "./styles";
import DashboardCard from "../../components/Cards/DashboardCard";
import { useHistory } from "react-router-dom";
import LeaveTracker from "./LeaveTracker";
import { userLeave, onLeave } from "../../apis/leaves";
import { holidays } from "../../apis/holidays";
import CalendarComponent from "../../components/Calendar";
import Events from "./Events";
import TrackerGraph from "../../components/TrackerGraph";
import { getPersistentAuthentication } from "../../utils/functions";
import { getPolicies } from "../../apis/policies";
import { permissions } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";
import PoliciesAcceptanceModal from "../PoliciesAcceptanceModal";
import KnowledgeHub from "./knowledgeHub";
import MuiButton from "@material-ui/core/Button";
import { ILeave } from "./LeaveTracker/LeaveTracker";
import { AxiosResponse } from "axios";
import HolidayList from "./HolidayList";

interface props {
  loadPrimary: (event: any, state: any) => void;
}

interface IHoliday {
  date: string;
  occassion: string;
  _v: number;
  _id: string;
}

interface IHolidayContext {
  holidayListRefetch: boolean;
  setHolidayListRefetch: React.Dispatch<React.SetStateAction<boolean>>;
}

export const HolidayContext = createContext<IHolidayContext>({
  holidayListRefetch: false,
  setHolidayListRefetch: () => {},
});

const DashboardPage: React.FC<props> = ({ loadPrimary }) => {
  useBasicNav("dashboard");
  const permission = useRecoilValue<any>(permissions);

  const [onLeaveUsers, setOnLeaveUsers] = useState<ILeave[]>([]);
  const [pl, setPl] = useState<any>([]);
  const [cl, setCl] = useState<any>([]);
  const [sl, setSl] = useState<any>([]);
  const [halfDay, setHalfDay] = useState<any>([]);
  const [policyData, setPolicyData] = useState<any>([]);
  const [holidayDates, setHolidayDates] = useState<string[]>([]);
  const username = getPersistentAuthentication()?.username;
  const isFirstTimeLoggedIn = getPersistentAuthentication()?.isFirstTimeLogin;
  const history = useHistory();
  const classes = useStyles();
  const isPolicyAccepted = getPersistentAuthentication()?.isPolicyAccepted;

  const [holidayListRefetch, setHolidayListRefetch] = useState(false);

  const isSuperAdmin = ["superadmin", "admin", "Admin"].includes(
    permission?.roleName
  );
  let token = getPersistentAuthentication()?.token;
  const MOCK_INTERVIEW_URL = process.env.REACT_APP_MOCK_INTERVIEW;

  /**
   * @method getPoliciesData
   * @description Function to fetch all policy data
   * @returns nothing
   */
  async function getPoliciesData() {
    const res = await getPolicies();
    let data = res.data.map((item: any) => ({
      title: item.policy.split("-")[0],
      component: <div dangerouslySetInnerHTML={{ __html: item.data }}></div>,
    }));
    setPolicyData(data);
  }

  /**
   * @description Fetches all user leave data (casual leaves, priviledge leaves and sick leaves)
   * @method getUserLeave
   * @return nothing
   */
  const getUserLeave = () => {
    userLeave()
      .then((response: any) => {
        response?.data?.forEach((item: any) => {
          if (item.day_type !== "fullDay" && item?.isApproved) {
            let now = moment(item?.from).clone();
            const dates = [];

            while (now.isSameOrBefore(moment(item?.to))) {
              dates.push(now.format("D-M-YYYY"));
              now.add(1, "days");
            }
            setHalfDay(dates);
          } else if (
            item.leave_type === "priviledge_leave" &&
            item?.isApproved
          ) {
            let now = moment(item?.from).clone();
            let dates = [];

            while (now.isSameOrBefore(moment(item?.to))) {
              dates.push(now.format("D-M-YYYY"));
              now.add(1, "days");
            }
            setPl(dates);
          } else if (item.leave_type === "casual_leave" && item?.isApproved) {
            let now = moment(item?.from).clone();
            let dates = [];

            while (now.isSameOrBefore(moment(item?.to))) {
              dates.push(now.format("D-M-YYYY"));
              now.add(1, "days");
            }
            setCl(dates);
          } else if (item.leave_type === "sick_leave" && item?.isApproved) {
            let now = moment(item?.from).clone();
            let dates = [];

            while (now.isSameOrBefore(moment(item?.to))) {
              dates.push(now.format("D-M-YYYY"));
              now.add(1, "days");
            }
            setSl(dates);
          }
        });
      })
      .catch((err: any) => {
        loadPrimary(false, false);
      });
  };

  /**
   * @method getUserOnLeave
   * @description Function to fetch leave data of employees currently on leave
   */

  const getUserOnLeave = async () => {
    try {
      const res = await onLeave();
      setOnLeaveUsers(res.data);
      loadPrimary(false, false);
    } catch (error) {
      console.error(error);
      loadPrimary(false, false);
    }
  };

  /**
   * @description Function to fetch holidays data
   * @method getHolidays
   * @return nothing
   */
  // const getHolidays = async () => {
  //   let dates: string[] = [];
  //   try {
  //     const res: AxiosResponse = await holidays();
  //     res.data.forEach((item: IHoliday) => {
  //       const now = moment(item.date).clone().format("D-M-YYYY");
  //       dates.push(now);
  //     });
  //     setHolidayDates(dates);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  useEffect(() => {
    loadPrimary(true, true);
    getUserLeave();
    getUserOnLeave();
    // getHolidays();
    getPoliciesData();
  }, []);

  return (
    <BasicLayout>
      <Grid
        container
        direction="column"
        justifyContent="center"
        className={classes.root}
      >
        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid
            container
            alignItems="flex-end"
            item
            xs={6}
            className={classes.header}
          >
            <Grid item>
              <p className={classes.welcomeText}>Welcome</p>
              <h3 className={classes.nameText}>{username}</h3>
            </Grid>
            <Grid item>
              <img src={waveHandIcon} alt="wave-icon"></img>
            </Grid>
          </Grid>
          <Grid container item xs={6}>
            <Grid
              container
              item
              direction="row"
              justifyContent="flex-end"
              xs={12}
            >
              <Grid
                item
                xs={3}
                justifyContent="center"
                alignItems="center"
                className={classes.mockinterview}
              >
                <a
                  className={classes.link}
                  // href={`http://interview.techvariable.com?token=${token}`}
                  href={`${MOCK_INTERVIEW_URL}?token=${token}`}
                  target="_blank"
                >
                  <MuiButton className={classes.interviewbutton}>
                    Mock Interview
                  </MuiButton>
                </a>
              </Grid>

              {isSuperAdmin ? (
                <Grid item xs={3}>
                  <MuiButton
                    className={classes.action}
                    onClick={() => {
                      history.push("/app/employees");
                    }}
                  >
                    Add a user
                  </MuiButton>
                </Grid>
              ) : (
                <Grid item xs={3}>
                  <MuiButton
                    className={classes.action}
                    onClick={() => {
                      history.push("/app/leaves");
                    }}
                  >
                    Apply Leave
                  </MuiButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container item spacing={2} className={classes.mid}>
            <Grid item xs={4}>
              <DashboardCard color="red" label="UPCOMING EVENTS">
                <Events />
              </DashboardCard>
            </Grid>
            <Grid item xs={4}>
              <DashboardCard color="blue" label="LEAVE TRACKER">
                <LeaveTracker data={onLeaveUsers} />
              </DashboardCard>
            </Grid>
            <Grid item xs={4}>
              <HolidayContext.Provider
                value={{ holidayListRefetch, setHolidayListRefetch }}
              >
                <DashboardCard
                  color="#DF08BD"
                  label="HOLIDAY LIST"
                  existButton={true}
                >
                  <HolidayList />
                  {/* <KnowledgeHub /> */}
                </DashboardCard>
              </HolidayContext.Provider>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} md={12} xl={12}>
          <Grid container item className={classes.bottom}>
            <Grid item xs={6}>
              <CalendarComponent
                data={{ pl, cl, sl, halfDay, holidayDates: [""] }}
              />
            </Grid>
            <Grid item xs={6}>
              <TrackerGraph />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!isPolicyAccepted && !isSuperAdmin ? (
        <>
          <PoliciesAcceptanceModal />
        </>
      ) : (
        <></>
      )}
    </BasicLayout>
  );
};

export default DashboardPage;
