import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./styles";
import CloseIcon from "@material-ui/icons/Close";
import { getDesignation } from "../../../../apis/designations_departments";
import { IAllDesignations } from "../RecruiteeDetails/EmployeeInformation/EmployeeInformation";

interface AddNewRecruiteeFormProps {
  allDesignations: IAllDesignations[];
  formik: any;
  open: boolean;
  handleClose: () => void;
}

const AddNewRecruiteeForm: React.FC<AddNewRecruiteeFormProps> = ({
  allDesignations,
  formik,
  open,
  handleClose,
}) => {
  const classes = useStyles();
  // const [allDesignations, setAllDesignations] = useState<IAllDesignations[]>(
  //   []
  // );

  // const getAllDesignations = async () => {
  //   try {
  //     const res = await getDesignation();
  //     
  //     setAllDesignations(res.data);
  //   } catch (error) {}
  // };

  // useEffect(() => {
  //   getAllDesignations();
  // }, []);

  return (
    <>
      <Dialog open={open} onClose={handleClose} className={classes.dialog}>
        <div className={classes.container}>
          <div className={classes.heading}>
            <Typography variant="h6">Add New Recruitee</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <form className={classes.form} onSubmit={formik.handleSubmit}>
            <Grid container>
              <Grid
                item
                container
                xs={12}
                alignItems="center"
                className={classes.mb}
              >
                <Grid item xs={5}>
                  <InputLabel htmlFor="language">
                    <Typography className={classes.labels} variant="body1">
                      Name
                    </Typography>
                  </InputLabel>
                </Grid>
                <Grid item xs={7} container justifyContent="flex-end">
                  <TextField
                    fullWidth
                    required
                    type="text"
                    id="employeeName"
                    name="employeeName"
                    variant="outlined"
                    placeholder="Enter Employee Name"
                    value={formik.values.employeeName}
                    onChange={formik.handleChange}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                alignItems="center"
                className={classes.mb}
              >
                <Grid item xs={5}>
                  <InputLabel htmlFor="framework">
                    <Typography className={classes.labels} variant="body1">
                      Email ID
                    </Typography>
                  </InputLabel>
                </Grid>
                <Grid item xs={7} container justifyContent="flex-end">
                  <TextField
                    type="email"
                    fullWidth
                    required
                    id="employeeEmailId"
                    name="employeeEmailId"
                    variant="outlined"
                    placeholder="Enter Employee's Email ID"
                    value={formik.values.employeeEmailId}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.employeeEmailId ? (
                    <span style={{ color: "#ed1c24" }}>Invalid Email ID</span>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                alignItems="center"
                className={classes.mb}
              >
                <Grid item xs={5}>
                  <InputLabel htmlFor="platform">
                    <Typography className={classes.labels} variant="body1">
                      Phone Number
                    </Typography>
                  </InputLabel>
                </Grid>
                <Grid item xs={7} container justifyContent="flex-end">
                  <TextField
                    type={"number"}
                    fullWidth
                    required
                    id="employeePhoneNumber"
                    name="employeePhoneNumber"
                    variant="outlined"
                    placeholder="Enter Employee's Phone Number"
                    value={formik.values.employeePhoneNumber}
                    onChange={formik.handleChange}
                    inputProps={{ maxLength: 10 }}
                  />
                  {formik.errors.employeePhoneNumber &&
                  formik.values.employeePhoneNumber.toString().length === 10 ? (
                    <span style={{ color: "#ed1c24" }}>
                      Invalid Phone Number
                    </span>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
              {/* previous designation */}
              {/* <Grid
                item
                container
                xs={12}
                alignItems="center"
                className={classes.mb}
                style={{ border: "1px solid red" }}
              >
                <Grid item xs={5}>
                  <InputLabel htmlFor="title">
                    <Typography className={classes.labels} variant="body1">
                      Designation
                    </Typography>
                  </InputLabel>
                </Grid>
                <Grid item xs={7} container justifyContent="flex-end">
                  <TextField
                    type="text"
                    fullWidth
                    required
                    id="employeeDesignation"
                    name="employeeDesignation"
                    multiline
                    maxRows={10}
                    variant="outlined"
                    placeholder="Enter Employee's Designation"
                    value={formik.values.employeeDesignation}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.employeeDesignation ? (
                    <span style={{ color: "#ed1c24" }}>
                      Invalid Designation
                    </span>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid> */}
              {/* new designation */}
              <Grid
                item
                container
                xs={12}
                alignItems="center"
                className={classes.mb}
              >
                <Grid item xs={5}>
                  <InputLabel htmlFor="title">
                    <Typography className={classes.labels} variant="body1">
                      Designation
                    </Typography>
                  </InputLabel>
                </Grid>
                <Grid item xs={7} container justifyContent="flex-end">
                  <Select
                    required
                    variant="outlined"
                    fullWidth
                    // labelId="designation"
                    id="employeeDesignation"
                    name="employeeDesignation"
                    placeholder="Enter Employee's Designation"
                    value={formik.values.employeeDesignation}
                    onChange={formik.handleChange}
                  >
                    {allDesignations.map((designation, index) => {
                      return (
                        <MenuItem key={designation._id} value={designation._id}>
                          {designation.designationName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {/* {formik.errors.employeeDesignation ? (
                    <span style={{ color: "#ed1c24" }}>
                      Invalid Designation
                    </span>
                  ) : (
                    <></>
                  )} */}
                </Grid>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              disableElevation
              type="submit"
              className={classes.btnBase}
            >
              Save
            </Button>
          </form>
        </div>
      </Dialog>
    </>
  );
};

export default AddNewRecruiteeForm;
