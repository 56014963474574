import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
   dot: {
       width : '10px',
       height: '10px',
       borderRadius: '100%',
       marginRight: '5px'
   },
   legend: {
        fontSize: '11px'
   },
   legendContainer: {
       display: 'flex',
       alignItems: 'center'
   }
  
  }));