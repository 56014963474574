import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { getUsers, assignedUserLeaves } from "../../../apis/users";
import { getPersistentAuthentication } from "../../../utils/functions";
import Table from "../../../components/Table";
import { useStyles } from "./styles";
import { WEB_SOCKET_URL } from "../../../config";
import { io } from "socket.io-client";
import { permissions } from "../../../recoil/atoms";
import { userType, user } from "../types";
import { useRecoilState } from "recoil";
import EmployeeLeaveTable from "./EmployeeLeaveTable";

const HEAD_CELLS = [
  { key: "name", label: "Name" },
  { key: "priviledgeLeave", label: "Priviledge Leave" },
  { key: "casualLeave", label: "Casual Leave" },
  { key: "sickLeave", label: "Sick Leave" },
  { key: "leavesTaken", label: "Leaves Taken" },
];
interface IProps {
  parentHeight?: number | undefined;
}

const EmployeeLeaveDetails: React.FC<IProps> = ({ parentHeight }) => {
  const classes = useStyles();
  const [permission, setPermission] = useRecoilState<any>(permissions);
  const [tableItems, setTableItems] = useState<user[]>([]);
  const permissionUser = permission?.user?.read;
  const isHumanResource = permission?.roleName === "Human Resource" && true;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [socketStatus, setSocketStatus] = useState<boolean>(false);

  /**
   * @method getAllUsers
   * @description api call to get all users
   */

  useEffect(() => {
    let socket = io(`${WEB_SOCKET_URL}`, { transports: ["websocket"] });
    socket.on("message", (message: string) => {
      setSocketStatus(!socketStatus);
    });

    return () => {
      socket.disconnect();
    };
  }, [socketStatus]);

  useEffect(() => {
     getAllUsers();
  }, []);

  async function getAllUsers() {
    setIsLoading(true);
    try {
      let response = await getUsers();
      let tempItem: user[] = [];
      response?.data?.docs?.forEach(
        ({ id, basicDetails, leaveBalance }: userType) => {
          let tempObjUser = {
            id: id,
            name: basicDetails.fullName,
            privilegeLeave: ` ${leaveBalance.privilegeLeave.taken} / ${leaveBalance.privilegeLeave.allotted}`,
            casualLeave: `${leaveBalance.casualLeave.taken} / ${leaveBalance.casualLeave.allotted}`,
            sickLeave: `${leaveBalance.sickLeave.taken} / ${leaveBalance.sickLeave.allotted}`,
            leavesTaken: `${
              leaveBalance.privilegeLeave.taken +
              leaveBalance.casualLeave.taken +
              leaveBalance.sickLeave.taken
            } / ${
                  leaveBalance.privilegeLeave.allotted +
                  leaveBalance.casualLeave.allotted +
                  leaveBalance.sickLeave.allotted
                }`,
          };
          tempItem.push(tempObjUser);
        }
      );
      setTableItems([...tempItem]);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  return (
    <Grid
      container
      className={classes.tableContainer}
    >
      <EmployeeLeaveTable
        headCells={HEAD_CELLS}
        items={tableItems}
        isLoading={isLoading}
      />
    </Grid>
  );
};

export default EmployeeLeaveDetails;
