import { useStyles } from "./styles";
import { Grid, Input } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import menuListActive from "../../assets/icons/menuListActive.svg";
import menuGridInactive from "../../assets/icons/menuGridInactive.svg";
import { ChangeEvent, useEffect, useState } from "react";
import { getAllUserByName } from "../../apis/onboarding";
import useDebounce from "../../hooks/useDebounce";

interface employeeSearchProps {
  placeholder?: string;
  setSearchedData?: any;
  items: {
    designation: string;
    emailID: string;
    name: string;
    mobileNo: number;
    status: string;
    migrated: boolean;
  }[];
}

const EmployeeSearch: React.FC<employeeSearchProps> = ({
  placeholder,
  setSearchedData,
  items,
}) => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const debounce = useDebounce(name, 500);

  useEffect(() => {
    clearSearch();
  }, [items]);

  useEffect(() => {
    searchByName();
  }, [debounce]);

  const handleSearch = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setName(e.target.value);
  };

  const searchByName = () => {
    const searchingData: {
      designation: string;
      emailID: string;
      name: string;
      mobileNo: number;
      status: string;
      _id: string;
      migrated: boolean;
    }[] = [];

    getAllUserByName(debounce).then((res) => {
      res.data.docs.map((value: any) => {
        searchingData.push({
          name: value.name,
          emailID: value.emailID,
          mobileNo: value.mobileNo,
          designation: value.employeeInformation?.designation,
          status: value.status,
          _id: value._id,
          migrated: value.migrated,
        });
      });
      setSearchedData(searchingData);
    });
  };

  const clearSearch = () => {
    setName("");
    setSearchedData(items);
  };

  return (
    <Grid container spacing={2}>
      <Grid container className={classes.searchDiv}>
        <Grid item>
          <Input
            disableUnderline
            value={name}
            className={classes.searchInput}
            placeholder={placeholder}
            onChange={(e) => handleSearch(e)}
          />
        </Grid>
        <Grid item>
          <CloseIcon
            className={classes.closeSearchIcon}
            fontSize="medium"
            onClick={clearSearch}
          />
          <SearchIcon className={classes.searchIcon} fontSize="medium" />
        </Grid>
      </Grid>
      <Grid item>{/* <img src={menuListActive} alt="List View" /> */}</Grid>
    </Grid>
  );
};

export default EmployeeSearch;
