import { Dispatch, SetStateAction, useContext } from "react";
import { SetterOrUpdater } from "recoil";
import { UploadsContext } from "../../../../../store/uploadsStore";
import { UPLOAD_DOCUMENT_SIZE } from "../../../../../utils/constants";

interface HandleDroppedIdProofsProps {
  docType: string;
  file: any;
  idProofs: File[] | any;
  setIdentityProof: Dispatch<SetStateAction<File[]>> | any;
  setIsFileSelected: SetterOrUpdater<{
    pan: boolean;
    aadhar: boolean;
  }>;
  setImgName: Dispatch<
    SetStateAction<{
      adhaarCard: string;
      panCard: string;
    }>
  >;
  setImgSize: Dispatch<
    SetStateAction<{
      adhaarCard: number;
      panCard: number;
    }>
  >;
  setIsSubmit: Dispatch<any>;
  showToast: any;
  setRewriteFileDownload: Dispatch<
    SetStateAction<{
      personalPhoto: boolean;
      passportPhoto: boolean;
      // offerLetter: boolean;
      pan: boolean;
      aadhar: boolean;
      class10: boolean;
      class12: boolean;
      grad: boolean;
      postGrad: boolean;
      diploma: boolean;
      reliving: boolean;
      lastSalSlip: boolean;
      secondLastSalSlip: boolean;
      thirdLastSalSlip: boolean;
    }>
  >;
}

export const HandleDroppedIdProofs = ({
  docType,
  file,
  idProofs,
  setIdentityProof,
  setIsFileSelected,
  setImgName,
  setImgSize,
  setIsSubmit,
  showToast,
  setRewriteFileDownload,
}: HandleDroppedIdProofsProps) => {
  if (file.type == "application/pdf" && file.size <= UPLOAD_DOCUMENT_SIZE) {
    if (file) {
      if (docType === "PanCard") {
        setIsFileSelected((prev: any) => ({
          ...prev,
          pan: true,
        }));
        setImgName((prev: any) => ({
          ...prev,
          panCard: file.name,
        }));
        setImgSize((prev: any) => ({
          ...prev,
          panCard: file.size,
        }));
        setRewriteFileDownload((prev) => ({
          ...prev,
          pan: true,
        }));

        idProofs.forEach((exp: any, index: number) => {
          if (exp.name === "panCard") {
            idProofs.splice(index, 1);
          }
        });

        setIdentityProof([
          ...idProofs,
          {
            name: "panCard",
            value: file,
          },
        ]);
        setIsSubmit((prev: any) => ({
          ...prev,
          aadhar: false,
          pan: true,
        }));
      } else {
        setRewriteFileDownload((prev) => ({
          ...prev,
          pan: false,
          aadhar: true,
        }));
        setIsFileSelected((prev: any) => ({
          ...prev,
          aadhar: true,
        }));
        setImgName((prev: any) => ({
          ...prev,
          adhaarCard: file.name,
        }));
        setImgSize((prev: any) => ({
          ...prev,
          adhaarCard: file.size,
        }));

        idProofs.forEach((aca: any, index: number) => {
          if (aca.name === "adhaarCard") {
            idProofs.splice(index, 1);
          }
        });

        setIdentityProof([
          ...idProofs,
          {
            name: "adhaarCard",
            value: file,
          },
        ]);
        setIsSubmit((prev: any) => ({
          ...prev,
          aadhar: true,
        }));
      }
    }
  } else {
    showToast.error("SELECT ONLY JPG, PNG & PDF FILES WITH MAXIMUM SIZE 1MB!");
    setIsSubmit((prev: any) => ({
      ...prev,
      pan: false,
    }));
  }
};
