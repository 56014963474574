import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  root: {
    margin: "2rem 2rem",
    width: "100%",
  },
  text: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: "128.91%",
    color: "#5E5E5E",
    marginBottom: "1rem",
  },
  submitButton: {
    backgroundColor: "#14213D",
    color: "#fff",
  },
  actionarea: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "flex-end",
  },
  detailsArea: {
    marginLeft: "2.7rem",
  },
  fresherDetails: {
    //      '&:target':{
    //          outline: "none !important",
    // }
  },
  experiencedetail: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "2rem",
    columnGap: "5rem",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    borderRadius: ".5rem",
    marginLeft: "2.7rem",
    flexWrap: "wrap",
  },
  textfields: {
    width: "10rem",
  },
  textfield: {
    "& .MuiFormLabel-root": {
      color: "black",
    },
  },

  assetRoot: {
    // border: "1px solid red",
    padding: "1em",
    width: "100%",
  },
  container: {
    // border: "1px solid blue",
    padding: "1em",
  },
  assetBox: {
    borderRadius: "20px",
    borderColor: "grey",
    width: "100%",
    marginTop: "1.5em",
  },
  titleContainer: {
    marginTop: "-.7em",
    marginLeft: "3em",
    position: "absolute",
    paddingLeft: "0.5em",
    paddingRight: "0.5em",
    backgroundColor: "white",
    fontSize: "1.2em",
  },
  assetFormContainer: {
    padding: "2em",
  },
  assetTextField: {
    width: "50%",
  },
  removeButton: {
    marginLeft: "2em",
    cursor: "pointer",
    color: "red"
  },
  viewText: {
    paddingLeft: "2em",
    paddingBottom: "2em",
  },
});
