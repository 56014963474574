import { Button, Grid, Switch, TextField, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useStyles } from "./styles";
import loading from "../../../../assets/icons/loading.svg";
import {
  companyEmailStepsAtom,
  recruiteeBasicDetails,
} from "../../../../recoil/atoms";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  getCompanyEmailAPI,
  getRecruiteeInfo,
  handleCompanyEmailSubmit,
  setCompanyEmailAPI,
} from "../../../../apis/onboarding";
import { techvariableEmail } from "../../../AccordionComponent/ValidationSchema";

interface CompanyEmailProps {
  showToast: any;
  index: number;
}

const CompanyEmail: React.FC<CompanyEmailProps> = ({ showToast, index }) => {
  const [payLoad, setPayLoad] = useState<{
    emailID: string;
    name: string;
  }>({ emailID: "", name: "" });

  const [isDateSelected, setIsDateSelected] = useState<boolean>(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isSlackEnabled, setIsSlackEnabled] = useState(false);
  const getRecruiteeDetails = useRecoilValue(recruiteeBasicDetails);
  const [companyEmailStepsatom, setCompanyEmailStepsatom] = useRecoilState(
    companyEmailStepsAtom
  );
  const classes = useStyles();

  const [officialEmail, setOfficialEmail] = useState("");

  const recruiteeName = getRecruiteeDetails.name;
  const recruiteeEmailId = getRecruiteeDetails.emailID;

  const getDetails = async () => {
    const resp = await getRecruiteeInfo(getRecruiteeDetails.id);

    setPayLoad({
      emailID: resp.emailID,
      name: resp.name,
    });
  };

  useEffect(() => {
    getDetails();
  }, []);

  const handleSend = async () => {
    setIsSendingEmail(true);
    setCompanyEmailStepsatom((prev) => ({
      ...prev,
      companyEmail: true,
    }));

    try {
      const res = await handleCompanyEmailSubmit(payLoad);
      if (res.status === 201) {
        showToast.success("Email Sent!");
        setIsSendingEmail(false);
        setIsEmailSent(true);
      }
    } catch (error) {
      showToast.error(`Error ${error}`);
    }

    // .then((res) => {
    //   if (res?.status == 200) {

    //     return;
    //   } else {
    //     return;
    //   }
    // })
    // .catch((err: { message: any }) => {
    //   showToast.error(`Error ${err.message}`);
    //   return;
    // });
  };

  const handleSwitch = (event: any) => {
    setIsSlackEnabled(event.target.checked);
  };

  const assignCompanyEmail = async () => {
    if (!techvariableEmail.test(officialEmail)) {
      showToast.error(
        "The email address is not valid. It must be a techvariable email."
      );
      return;
    }
    try {
      const res = await setCompanyEmailAPI(
        getRecruiteeDetails.id,
        officialEmail
      );
      showToast.success(res.data.message);
    } catch (error) {}
  };

  const getCompanyEmail = async () => {
    const res = await getCompanyEmailAPI(getRecruiteeDetails.id);
    setOfficialEmail(res.data);
  };

  useEffect(() => {
    getCompanyEmail();
  }, []);

  return (
    <Grid container>
      <Grid
        container
        item
        justifyContent="space-between"
        spacing={1}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={classes.containerTop}
      >
        <Grid item md={8}>
          <Typography className={classes.text}>
            Email contains introduction to TechVariable, its mission and vision,
            culture, perks and benefits.
          </Typography>
        </Grid>

        <Grid item md={2}>
          {isSendingEmail ? (
            <Button
              variant="contained"
              className={classes.sendingEmailButton}
              onClick={handleSend}
            >
              Sending &nbsp;
              <img
                src={loading}
                width={20}
                height={20}
                className={classes.loadingIcon}
              />
            </Button>
          ) : isEmailSent ? (
            <Button
              disabled
              variant="contained"
              className={classes.emailSentButton}
              onClick={handleSend}
            >
              Email Sent
            </Button>
          ) : (
            <Button
              variant="contained"
              className={classes.sendEmailButton}
              onClick={handleSend}
            >
              Send Email
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        item
        justifyContent="space-between"
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={classes.containerBottom}
        // style={{ border: "1px solid red" }}
      >
        <Grid item>
          <Typography className={classes.text}>
            Enable slack notification about the recruitee
          </Typography>
        </Grid>
        <Grid item>
          <Switch color="primary" onChange={(event) => handleSwitch(event)} />
        </Grid>
      </Grid>

      <Grid
        container
        item
        justifyContent="space-between"
        className={classes.containerBottom}
      >
        <Grid item>
          <Typography className={classes.text}>
            Assign Company Email to Recruitee
          </Typography>
        </Grid>
        <Grid item alignItems="center" justifyContent="center">
          <TextField
            variant="outlined"
            size="small"
            value={officialEmail}
            onChange={(e) => setOfficialEmail(e.target.value)}
          />
          <Button
            style={{ marginLeft: "1em", height: "100%" }}
            variant="contained"
            className={classes.sendEmailButton}
            onClick={assignCompanyEmail}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CompanyEmail;
