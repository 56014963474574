import { Button, Grid, Paper, TextField } from "@material-ui/core";
import React from "react";
import useStyles from "../SignInForm/styles";
import mail from "../../assets/images/mail.png";
import lock from "../../assets/images/lock.png";

interface signInOTPFormProps {
  handleSubmit: (event:  React.FormEvent<HTMLFormElement>) => void;
  handleChange: (event:  React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  handleResendOTP?: () => void;
}

const SignInOTPForm: React.FC<signInOTPFormProps> = ({
  handleSubmit,
  handleChange,
  handleResendOTP,
}) => {
  const classes = useStyles();

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Paper className={classes.paper} variant="elevation" elevation={0}>
          <Grid container spacing={1}>
            <Grid item xs={12} className={classes.inputSection}>
              <Grid container>
                <Grid item xs={2} className={classes.adornmentGrid}>
                  <img src={mail} alt="lock" height="45px" />
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    name="emailID"
                    label="Email"
                    autoFocus
                    size="small"
                    className={classes.textField}
                    onChange={(e) => handleChange(e)}
                    InputProps={{
                      classes: { input: classes.inputField },
                      disableUnderline: true,
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.inputSection}>
              <Grid container>
                <Grid item xs={2} className={classes.adornmentGrid}>
                  <img src={lock} alt="lock" height="45px" />
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    name="otp"
                    label=" O T P "
                    variant="standard"
                    size="small"
                    className={classes.textField}
                    type="password"
                    onChange={(e) => handleChange(e)}
                    InputProps={{
                      classes: { input: classes.inputField },
                      disableUnderline: true,
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.forgotPass}>
              {/* change class name to classes.link if 72 hours have been passed , else: classes.regenrateOtp */}
              <span className={classes.link} onClick={handleResendOTP}>
                {/* Disable onclick if 72 hours have been passed */}
                Regenerate OTP
              </span>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disableElevation
                className={classes.button}
              >
                LOGIN
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </>
  );
};

export default SignInOTPForm;
