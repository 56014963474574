import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  container: {
    border: "1px solid silver",
    borderRadius: 7,
    marginTop: "2em",
  },
  fileContainer: {
    padding: "0.5em",
  },
  text: {
    paddingLeft: "1em",
    textAlign: "left",
  },
  fileSize: {
    color: "silver",
  },
});
