// PLUGINS IMPORTS //
import { TextField, TextFieldProps } from "@material-ui/core";
import { useField } from "formik";
import { at } from "lodash";
import { FC } from "react";

// COMPONENTS IMPORTS //

// EXTRA IMPORTS //

/////////////////////////////////////////////////////////////////////////////

interface PropsType {
  errorText?: string;
  valueTypeProps?: string;
  defaultProps: TextFieldProps;
  // OnChangeHandler(): any;
}

const TextFieldComponent: FC<PropsType> = ({
  errorText,
  valueTypeProps = "text",
  defaultProps,
  // OnChangeHandler,
}) => {
  // const {valueTypeProps, errorText, ...restProps } = props;
  const [field, meta] = useField(defaultProps.name as string);
  // 
  // 

  // 
  const [touched, error] = at(meta, "touched", "error");

  const renderHelperText = () => {
    const [touched, error] = at(meta, "touched", "error");

    if (touched && error) {
      return error;
    }
  };

  return (
    <TextField
      type={valueTypeProps}
      error={Boolean(touched && error && true)}
      helperText={renderHelperText()}
      {...field}
      {...defaultProps}
    />
  );
};

export default TextFieldComponent;
