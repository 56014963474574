import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  accordionRoot: {
    width: "100%",
  },
  leaveacc:{
    marginBottom:"1rem"
  }
});
