import { SetterOrUpdater } from "recoil";
import { UPLOAD_DOCUMENT_SIZE } from "../../../../../utils/constants";

interface HandleDroppedAcademicsProps {
  docType: string;
  file: any;
  academicProofs: File[] | any;
  setAcademicProof: React.Dispatch<React.SetStateAction<File[]>> | any;
  setIsFileSelected: SetterOrUpdater<{
    class10: boolean;
    class12: boolean;
    grad: boolean;
    postGrad: boolean;
    diploma: boolean;
  }>;
  setImgName: React.Dispatch<
    React.SetStateAction<{
      class10th: string;
      class12th: string;
      graduation: string;
      postGraduation: string;
      diploma: string;
    }>
  >;
  setImgSize: React.Dispatch<
    React.SetStateAction<{
      class10th: number;
      class12th: number;
      graduation: number;
      postGraduation: number;
      diploma: number;
    }>
  >;
  setIsSubmit: React.Dispatch<any>;
  showToast: any;
  setRewriteFileDownload: React.Dispatch<
    React.SetStateAction<{
      personalPhoto: boolean;
      passportPhoto: boolean;
      // offerLetter: boolean;
      pan: boolean;
      aadhar: boolean;
      class10: boolean;
      class12: boolean;
      grad: boolean;
      postGrad: boolean;
      diploma: boolean;
      reliving: boolean;
      lastSalSlip: boolean;
      secondLastSalSlip: boolean;
      thirdLastSalSlip: boolean;
    }>
  >;
}

export const HandleDroppedAcademics = ({
  docType,
  file,
  academicProofs,
  setAcademicProof,
  setImgName,
  setImgSize,
  setIsFileSelected,
  setIsSubmit,
  showToast,
  setRewriteFileDownload,
}: HandleDroppedAcademicsProps) => {
  // CLASS TEN
  if (docType === "ClassTen") {
    if (file.type == "application/pdf" && file.size <= UPLOAD_DOCUMENT_SIZE) {
      if (file) {
        setRewriteFileDownload((prev) => ({
          ...prev,
          class10: true,
        }));
        setIsFileSelected((prev: any) => ({
          ...prev,
          class10: true,
        }));

        setImgName((prev) => ({
          ...prev,
          class10th: file.name,
        }));

        setImgSize((prev) => ({
          ...prev,
          class10th: file.size,
        }));

        academicProofs.forEach((aca: any, index: number) => {
          if (aca.name === "class10th") {
            academicProofs.splice(index, 1);
          }
        });

        setAcademicProof([
          ...academicProofs,
          {
            name: "class10th",
            value: file,
          },
        ]);
        setIsSubmit((prev: any) => ({
          ...prev,
          class10: true,
        }));
      }
    } else {
      setRewriteFileDownload((prev) => ({
        ...prev,
        class10: false,
      }));
      showToast.error("SELECT ONLY PDF FILES WITH MAXIMUM SIZE 1MB!");
      setIsSubmit((prev: any) => ({
        ...prev,
        class10: false,
      }));
    }
  }
  // CLASS TWELVE
  else if (docType === "ClassTwelve") {
    if (file.type == "application/pdf" && file.size <= UPLOAD_DOCUMENT_SIZE) {
      if (file) {


        setRewriteFileDownload((prev) => ({
          ...prev,
          class12: true,
        }));
        setIsFileSelected((prev: any) => ({
          ...prev,
          class12: true,
        }));
        setImgName((prev: any) => ({
          ...prev,
          class12th: file.name,
        }));
        setImgSize((prev: any) => ({
          ...prev,
          class12th: file.size,
        }));

        academicProofs.forEach((aca: any, index: number) => {
          if (aca.name === "class12th") {
            academicProofs.splice(index, 1);
          }
        });

        setAcademicProof([
          ...academicProofs,
          {
            name: "class12th",
            value: file,
          },
        ]);

        setIsSubmit((prev: any) => ({
          ...prev,
          class12: true,
        }));
      }
    } else {


      setRewriteFileDownload((prev) => ({
        ...prev,
        class12: false,
      }));
      showToast.error("SELECT ONLY PDF FILES WITH MAXIMUM SIZE 1MB!");
      setIsSubmit((prev: any) => ({
        ...prev,
        class12: false,
      }));
    }
  }
  // GRADUATION
  else if (docType === "Graduation") {
    if (file.type == "application/pdf" && file.size <= UPLOAD_DOCUMENT_SIZE) {
      if (file) {
        setRewriteFileDownload((prev) => ({
          ...prev,
          grad: true,
        }));
        setIsFileSelected((prev: any) => ({
          ...prev,
          grad: true,
        }));
        setImgName((prev) => ({
          ...prev,
          graduation: file.name,
        }));
        setImgSize((prev) => ({
          ...prev,
          graduation: file.size,
        }));

        academicProofs.forEach((aca: any, index: number) => {
          if (aca.name === "graduation") {
            academicProofs.splice(index, 1);
          }
        });

        setAcademicProof([
          ...academicProofs,
          {
            name: "graduation",
            value: file,
          },
        ]);
        setIsSubmit((prev: any) => ({
          ...prev,
          grad: true,
        }));
      }
    } else {
      setRewriteFileDownload((prev) => ({
        ...prev,
        grad: false,
      }));
      showToast.error("SELECT ONLY PDF FILES WITH MAXIMUM SIZE 1MB!");
      setIsSubmit((prev: any) => ({
        ...prev,
        grad: false,
      }));
    }
  }
  // POSTGRADUATION
  else if (docType === "PostGraduation") {
    if (file.type == "application/pdf" && file.size <= UPLOAD_DOCUMENT_SIZE) {
      if (file) {
        setRewriteFileDownload((prev) => ({
          ...prev,
          postGrad: true,
        }));
        setIsFileSelected((prev: any) => ({
          ...prev,
          postGrad: true,
        }));
        setImgName((prev) => ({
          ...prev,
          postGraduation: file.name,
        }));
        setImgSize((prev) => ({
          ...prev,
          postGraduation: file.size,
        }));

        academicProofs.forEach((aca: any, index: number) => {
          if (aca.name === "postGraduation") {
            academicProofs.splice(index, 1);
          }
        });

        setAcademicProof([
          ...academicProofs,
          {
            name: "postGraduation",
            value: file,
          },
        ]);
        setIsSubmit((prev: any) => ({
          ...prev,
          postGrad: true,
        }));
      }
    } else {
      setRewriteFileDownload((prev) => ({
        ...prev,
        postGrad: false,
      }));
      showToast.error("SELECT ONLY PDF FILES WITH MAXIMUM SIZE 1MB!");
      setIsSubmit((prev: any) => ({
        ...prev,
        postGrad: false,
      }));
    }
  }
  // diploma
  else {
    if (file.type == "application/pdf" && file.size <= UPLOAD_DOCUMENT_SIZE) {
      if (file) {
        setRewriteFileDownload((prev) => ({
          ...prev,
          diploma: true,
        }));
        setIsFileSelected((prev: any) => ({
          ...prev,
          diploma: true,
        }));
        setImgName((prev) => ({
          ...prev,
          diploma: file.name,
        }));
        setImgSize((prev) => ({
          ...prev,
          diploma: file.size,
        }));

        academicProofs.forEach((aca: any, index: number) => {
          if (aca.name === "diploma") {
            academicProofs.splice(index, 1);
          }
        });

        setAcademicProof([
          ...academicProofs,
          {
            name: "diploma",
            value: file,
          },
        ]);
        setIsSubmit((prev: any) => ({
          ...prev,
          diploma: true,
        }));
      }
    } else {
      setRewriteFileDownload((prev) => ({
        ...prev,
        diploma: false,
      }));
      showToast.error("SELECT ONLY PDF FILES WITH MAXIMUM SIZE 1MB!");
      setIsSubmit((prev: any) => ({
        ...prev,
        diploma: false,
      }));
    }
  }
};
