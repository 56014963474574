import { PERSISTENT_PERMISSIONS,PERSISTENTNEW_PERMISSIONS } from "./constants";
// import qs from "querystring";
import { PERSISTENT_AUTHENTICATION } from "./constants";
import moment from "moment";

type Permissions = {
  [key: string]: {
    [key: string]: boolean;
  };
};

type persistentPermissionDataType = {
  permissions: Permissions;
  roleName: string;
};

/**
 * @function getPath
 * @description Constructs the absolute path for the routes in the application
 * If the basePath property of the routeConfig is set, it will be
 * appended to the path value of each route
 * @param {string} path  -   The route path
 * @param {string} basePath -The basePath of the routeConfig
 * @returns {string}
 */
export const getPath = (path: string, basePath: string) => {
  if (!basePath) return path;
  return `${basePath}${path}`;
};

/**
 * @function setPersistentAuthentication
 * @description Set the provided data in the session storage as persistent data
 * @param {*} data - the data to store
 *
 */
export const setPersistentAuthentication = (data: any) => {
  
  

  //Add data in recoil state
  // currentStateData(data);
  // 

  sessionStorage.setItem(
    PERSISTENT_AUTHENTICATION,
    JSON.stringify({
      ...data,
    })
  );
};

/**
 * @function setPersistentPermissions
 * @description Set the provided permission data in the session storage as persistent data
 * @param {*} data - the data to store
 *
 */
export const setPersistentPermissions = (
  data: persistentPermissionDataType
) => {
  try{
    let permissionData =  JSON.stringify({
      ...data,
    });
    sessionStorage.setItem(PERSISTENT_PERMISSIONS, permissionData );
  }
  catch(error){
    console.error(error);
  } 
};

export const setNewPersistentPermissions = (
  data: persistentPermissionDataType
) => {
  try{
    let newPermissionData = JSON.stringify({
      ...data,
    });
    sessionStorage.setItem(
      PERSISTENTNEW_PERMISSIONS,
      newPermissionData
    );
  }
  catch(err){
  console.error(err);
  }
  
};

/**
 * @function getPersistentAuthentication
 * @description get the persisted data
 * @returns {Object}
 */
export const getPersistentAuthentication = () => {
  let state: any = sessionStorage.getItem(PERSISTENT_AUTHENTICATION);
  return JSON.parse(state);
};

/**
 * ```
 *  @function getPersistentPermissions
 *  @description get the persisted permission data
 *  @returns {Object}
 */
export const getPersistentPermissions = () => {
  let state: any = sessionStorage.getItem(PERSISTENT_PERMISSIONS);
  return JSON.parse(state);
};

/**
 * @function resetPersistentAuthentication
 * @description resets the persistent store
 *
 */
export const resetPersistentAuthentication = () =>
  sessionStorage.setItem(
    PERSISTENT_AUTHENTICATION,
    JSON.stringify({
      token: "",
    })
  );

/**
 * @function formatDate
 * @description format the date in dd-mm-yyyy
 * @returns {string}
 */
export const formatDate = (date: Date) =>
  `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;

/**
 * @function capitalizeString
 * @description Capitalizes every character of every new word in the string
 * @returns {string}
 */
export const capitalizeString = (str: string) => {
  const arr = str.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(" ");
  return str2;
};

export const lowerCaseString = (str:string)=>{
 const str1 =  str.replace(" ", "_").toLowerCase();
 return str1;
}


export const stringToBoolean = (stringValue: string) => {
  // 
  // console.log("convert", stringValue?.toLowerCase().trim());
  switch (stringValue) {
    case "true":
    case "yes":
    case "1":
      return true;

    case "false":
    case "no":
    case "0":
    case null:
    case undefined:
      return false;

    default:
      return JSON.parse(stringValue);
  }
};

export const compareDate = (
  firstDate: Date | string,
  seconddate: Date | string
) => {
  const oneDate = moment(firstDate).format("MM/DD/YYYY");
  const twoDate = moment(seconddate).format("MM/DD/YYYY");
  if (oneDate === twoDate) {
    return true;
  } else {
    return false;
  }
};

export const employeeSensitiveDataEditPermission = () => {
  let res = Boolean(
    getPersistentPermissions().permissions.employees.read &&
      getPersistentPermissions().permissions.employees.create &&
      getPersistentPermissions().permissions.employees.upDate
  );
  return res;
};

export const employeeDataReadPermission = () => {
  let res = Boolean(getPersistentPermissions().permissions.employees.read);
  return res;
};

export const employeeNormalDataUpdatePermission = () => {
  let res = Boolean(
    getPersistentPermissions().permissions.employees.read &&
      getPersistentPermissions().permissions.employees.upDate
  );
  return res;
};

export const employeeRemoveInf = () => {
  let res = Boolean(
    getPersistentPermissions().permissions.employees.read &&
      getPersistentPermissions().permissions.employees.create &&
      getPersistentPermissions().permissions.employees.upDate &&
      getPersistentPermissions().permissions.employees.del
  );
  return res;
};

export const employeeLeaveAdjust = ()=>{
  let res = Boolean(
    getPersistentPermissions().permissions.leave.adjust ?? false
  );
  return res;
}
