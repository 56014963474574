import { CardContent, Grid, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getEmployeeProjectDetails } from "../../../apis/employees";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import { useStyles } from "./styles";

export interface projectTypes {
  orientation: String;
  projectName: String;
  projectStatus: String;
}

const EmployeeProjectDetails = () => {
  const classes = useStyles();
  const params: { id: string } = useParams();
  const [projectData, setProjectData] = useState<projectTypes[]>([]);

  useEffect(() => {
    getEmployeesProjectDetail();
  }, []);

  async function getEmployeesProjectDetail() {
    try {
      let data = await getEmployeeProjectDetails(params.id);
      setProjectData(data?.data?.projects);
    } catch (err: any) {}
  }
  return (
    <Grid container xs={12} spacing={2}>
      {projectData ? (
        projectData.map((item: projectTypes) => {
          return (
            <Grid item xs={4}>
              <Card className={classes.root}>
                <div className={classes.details}>
                  <CardContent className={classes.content}>
                    <Typography component="h6" variant="h6">
                      {item?.projectName}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      {item?.orientation}
                    </Typography>
                  </CardContent>
                </div>
                <CardMedia
                  className={classes.cover}
                  image="/static/images/cards/live-from-space.jpg"
                  title="Live from space album cover"
                />
              </Card>
            </Grid>
          );
        })
      ) : (
        <Grid item xs={12}>
          <Typography component="h6">NO PROJECT FOUND</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default EmployeeProjectDetails;
