import { Box, Select, MenuItem } from "@material-ui/core";
import React from "react";
import { useStyles } from "./styles";
import moment from "moment";

interface IGraphFilters {
  allMonths: number[];
  lastThreeYears: number[];
  handleMonthChange: (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
  handleYearChange: (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
  currentMonth: number;
  currentYear: number;
}

const GraphFilters = ({
  allMonths,
  lastThreeYears,
  handleMonthChange,
  handleYearChange,
  currentMonth,
  currentYear,
}: IGraphFilters) => {
  const classes = useStyles();

  return (
    <Box style={{ display: "flex" }}>
      <Select
        variant="outlined"
        className={classes.monthBox}
        name="month"
        renderValue={() => moment(currentMonth, "M").format("MMMM")}
        value={currentMonth}
        onChange={handleMonthChange}
      >
        {allMonths.map((curElem, index) => (
          <MenuItem key={index} value={curElem}>
            {moment(curElem, "M").format("MMMM")}
          </MenuItem>
        ))}
      </Select>
      <Select
        variant="outlined"
        className={classes.yearBox}
        name="year"
        value={currentYear}
        onChange={handleYearChange}
      >
        {lastThreeYears.map((curElem, index) => (
          <MenuItem key={index} value={curElem}>
            {curElem}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default GraphFilters;
