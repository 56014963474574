import { Box, Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { setUserDone, updateUserStatus } from "../../apis/onboarding";
import { onboardingIdAtom } from "../../recoil/atoms";
import { checkAdminSubmission } from "./Stepper/checkAdminSubmission";
import { checkUserSubmission } from "./Stepper/checkUserSubmission";
import { useStyles } from "./styles";

export const StepperSubmitButton = (
  steps: string[],
  activeStep: number,
  handleNext: () => void,
  showToast: any
) => {
  const classes = useStyles();
  const onboardingIds = useRecoilValue(onboardingIdAtom);
  const history = useHistory();
  const user_role = window.sessionStorage.getItem("userRole") as string;
  const access_token = window.sessionStorage.getItem("access_token") as string;
  const userId = window.sessionStorage.getItem("onbId") as string;
  const [enableLoader, setEnableLoader] = useState<boolean>(false);

  const handleFinish = async () => {
    setEnableLoader(true);
    if (user_role === "temp-user") {
      const confirm = window.confirm(
        "Are you sure to mark all process as complete? You won't be able to make any changes further."
      );
      if (confirm) {
        if (await checkUserSubmission(userId)) {
          setEnableLoader(false);
          // make api call to send done as true and disable the user from logging in again.
          showToast.success("For Submission Complete! ");
          setUserDone(true, access_token);
          handleNext();
          history.replace("/app/signinotp");
        } else {
          setEnableLoader(false);
          showToast.error("Please Fillup all forms!");
        }
      } else {
        setEnableLoader(false);
      }
    } else {
      const confirm = window.confirm(
        "Are you sure to mark all process as complete?"
      );
      if (confirm) {
        const onboardId = onboardingIds.eduId;
        // call api PATCH to change the status from pending to complete of an existing onboard user
        if (await checkAdminSubmission(userId)) {
          setEnableLoader(false);
          showToast.success("Form Submission Completed! ");
          handleNext();
          updateUserStatus(onboardId, "completed");
          history.push("/app/onboarding");
        } else {
          setEnableLoader(false);
          showToast.error("Please Fillup all forms!");
        }
      } else {
        setEnableLoader(false);
      }
    }
  };

  const EnabledButton = () => {
    return (
      <>
        {activeStep === steps.length - 1 ? (
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonSubmit}
            onClick={handleFinish}
          >
            {enableLoader && (
              <Box
                style={{
                  width: "100%",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <CircularProgress color="secondary" size={20} />
              </Box>
            )}
            Finish
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            className={classes.buttonSubmit}
          >
            Submit
          </Button>
        )}
      </>
    );
  };

  return <EnabledButton />;
};
