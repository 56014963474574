import { Button, Grid, Paper } from "@material-ui/core";
import { useEffect, useState } from "react";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { useHistory } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { getDesignation } from "../../apis/designations_departments";
import { migrateToEmployeeAPI } from "../../apis/onboarding";
import arrowLeft from "../../assets/images/arrowLeft.svg";
import profile from "../../assets/images/profileBG.svg";
import { IAllDesignations } from "../../components/Forms/OnBoardingForm/RecruiteeDetails/EmployeeInformation/EmployeeInformation";
import {
  circularProgressStrokeAtom,
  permissions,
  profileAvatarAtom,
  recruiteeBasicDetails,
  recruiteeDetailsViewEnabled,
  recruiteeEditEnabled,
} from "../../recoil/atoms";
import { useStyles } from "./styles";

interface IShowToast {
  error: (message: string) => void;
  success: (message: string) => void;
  warning: (message: string) => void;
}

interface RecruiteeHeaderProps {
  showToast: IShowToast;
  stepNumber: number;
  isFinish: boolean;
  recruiteeDetails: {
    designation: string;
    emailID: string;
    name: string;
    mobileNo: number;
    status: string;
    _id: string;
  };
}

const NewRecruiteeDetailsHeader: React.FC<RecruiteeHeaderProps> = ({
  showToast,
  stepNumber,
  isFinish,
  recruiteeDetails,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const avatar = useRecoilValue(profileAvatarAtom);
  const [permission, setPermission] = useRecoilState<any>(permissions);
  const [isViewEnabled, setIsViewEnabled] = useRecoilState(
    recruiteeDetailsViewEnabled
  );
  const [isEdit, setIsEdit] = useRecoilState(recruiteeEditEnabled);
  const [allDesignations, setAllDesignations] = useState<IAllDesignations[]>(
    []
  );

  const isSuperAdmin =
    permission?.roleName === ("superadmin" || "Human Resource" || "Admin") &&
    true;
  const allowOnboarding =
    permission?.roleName === "superadmin" ||
    permission?.roleName === "Admin" ||
    permission?.roleName === "Human Resource";

  const strokeVal = useRecoilValue(circularProgressStrokeAtom);

  const setStrokeValue = (stepNumber: number) => {
    // if isFinish or, user's status is complete
    if (recruiteeDetails.status === "completed" || isFinish) {
      return 100;
    }
    if (allowOnboarding) {
      if (strokeVal.strokeValue === 0) return 0;
      else if (strokeVal.strokeValue === 1) return 25;
      else if (strokeVal.strokeValue === 2) return 50;
      else if (strokeVal.strokeValue === 3) return 75;
      else return 100;
    } else {
      if (strokeVal.strokeValue === 0) return 0;
      else if (strokeVal.strokeValue === 1) return 50;
      else return 100;
    }
  };

  const [basicDetails, setBasicDetails] = useRecoilState(recruiteeBasicDetails);

  useEffect(() => {
    setBasicDetails((prevState: any) => ({
      ...prevState,
      name: recruiteeDetails.name,
      emailID: recruiteeDetails.emailID,
    }));
  }, []);

  const goBack = () => {
    setIsEdit((prevState) => ({
      ...prevState,
      basicDetailsEdit: false,
    }));
    setIsViewEnabled((prev) => ({
      ...prev,
      basicDetailsViewEnabled: true,
    }));
    history.push("/app/onboarding");
  };

  const convertToEmployee = async () => {
    const confirm = window.confirm(
      "Are you sure to convert User to Employee? This can't be undone."
    );
    if (confirm) {
      try {
        const res = await migrateToEmployeeAPI(recruiteeDetails._id);
        showToast.success(res.data.message);
      } catch (error) {
        showToast.error("Something went wrong");
      }
    }
  };

  const getAllDesignations = async () => {
    try {
      const res = await getDesignation();
      setAllDesignations(res.data);
    } catch (error) {}
  };

  useEffect(() => {
    getAllDesignations();
  }, []);

  const getDesignatonName = (currentDesignation: string) => {
    return (
      allDesignations.find((des) => des._id === currentDesignation)
        ?.designationName || ""
    );
  };

  return (
    <Paper elevation={0}>
      <Grid container justifyContent="space-between" direction="row">
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={8}
          lg={8}
          xl={8}
          justifyContent="flex-start"
          spacing={5}
          className={classes.headerContainer}
        >
          <Grid item className={classes.backButton} onClick={goBack}>
            <img src={arrowLeft} />
          </Grid>

          <Grid item className={classes.progressBarDimensions}>
            <CircularProgressbarWithChildren
              value={setStrokeValue(stepNumber)}
              // strokeWidth={7}
              styles={buildStyles({
                pathColor: "#424B5E",
                trailColor: "#d6d6d6",
              })}
            >
              <div>
                <img
                  src={avatar.profileImage ? avatar.profileImage : profile}
                  className={classes.image}
                />
              </div>
            </CircularProgressbarWithChildren>
          </Grid>
          <Grid item className={classes.itemText}>
            {basicDetails?.name ? basicDetails.name : recruiteeDetails.name}
            <br />
            {getDesignatonName(recruiteeDetails.designation)}
          </Grid>
        </Grid>
        {/* <Grid
          item
          className={classes.convertToEmployee}
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
        >
          <Button
            variant="contained"
            className={classes.convertToEmployeeButton}
            disabled={recruiteeDetails.status === "pending" ? true : false}
            onClick={convertToEmployee}
          >
            Convert to Employee
          </Button>
        </Grid> */}
      </Grid>
    </Paper>
  );
};

export default NewRecruiteeDetailsHeader;
