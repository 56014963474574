import React, { useState, useEffect } from "react";
import { useStyles } from "./style";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {FormControl,Typography,FormLabel,TextField,Button,Checkbox} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useRecoilState } from "recoil";
import { permissions } from "../../../../recoil/atoms";
import {ProjectFormData} from "../../Page/ProjectDetailPage/types";
import { usePermissionObjectFor } from "../../../../hooks/permission/usePermissionObjectFor";

interface Iprops {
  projectFormData: ProjectFormData;
  setProjectFormData: React.Dispatch<React.SetStateAction<ProjectFormData>>;
  editHandler: () => void;
  isEditable: boolean;
  discardHandler: () => void;
  saveHandler: () => void;
  projectManagers: { id: string; email: string; name: string }[];
  selectedProjectManager: { id: string; email: string; name: string };
  setSelectedProjectManager: React.Dispatch<
    React.SetStateAction<{ id: string; email: string; name: string }>
  >;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ProjectForm: React.FC<Iprops> = ({
  projectFormData,
  editHandler,
  isEditable,
  discardHandler,
  saveHandler,
  projectManagers,
  selectedProjectManager,
  setSelectedProjectManager,
}) => {
  const classes = useStyles(); 
    const permission = usePermissionObjectFor("projects");
    const hasAdminAccess = permission.required(["create","read","upDate","del"]);
  
  return (
    <Paper className={classes.root}>
      <Grid container direction="row" className={classes.details}>
        <Grid item xs={12}>
          <Grid container direction="row">
            <Grid item xs={4}>
              <FormControl component="fieldset">
                <FormLabel component="legend" className={classes.label}>
                  Project Name
                </FormLabel>
                <Typography className={classes.disabledfields}>
                  {projectFormData?.projectName}
                </Typography>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl component="fieldset">
                <FormLabel component="legend" className={classes.label}>
                  Client Organization Name
                </FormLabel>
                <Typography className={classes.disabledfields}>
                  {projectFormData?.clientCompanyName}
                </Typography>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl component="fieldset">
                <FormLabel component="legend" className={classes.label}>
                  Primary Client Contact
                </FormLabel>
                <Typography className={classes.disabledfields}>
                  {projectFormData?.clientContact}
                </Typography>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.detailsinner}>
          <Grid container direction="row">
            <Grid item xs={4}>
              <FormControl component="fieldset">
                <FormLabel component="legend" className={classes.label}>
                  Primary Client Email
                </FormLabel>
                <Typography className={classes.disabledfields}>
                  {projectFormData?.clientEmail}
                </Typography>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl component="fieldset">
                <FormLabel component="legend" className={classes.label}>
                  Project Manager
                </FormLabel>

                {isEditable ? (
                  <Autocomplete
                    id="projectmanager"
                    defaultValue={selectedProjectManager}
                    options={projectManagers}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.email}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(e, value) => {
                      setSelectedProjectManager(
                        value as { id: string; email: string; name: string }
                      );
                    }}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                          key={option.id}
                          color="primary"
                        />
                        {option.name}
                      </React.Fragment>
                    )}
                    style={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Project manager"
                      />
                    )}
                  />
                ) : (
                  <Typography className={classes.disabledfields}>
                    {selectedProjectManager?.email}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl component="fieldset">
                <FormLabel component="legend" className={classes.label}>
                  Start Date
                </FormLabel>
                <Typography className={classes.disabledfields}>
                  {projectFormData?.startDate}
                </Typography>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} className={classes.detailsinner}>
          <FormControl component="fieldset">
            <FormLabel component="legend" className={classes.label}>
              Project Type
            </FormLabel>
            <Typography className={classes.disabledfields}>
              {projectFormData?.projectType}
            </Typography>
          </FormControl>
        </Grid>
        <Grid item xs={4} className={classes.detailsinner}>
          <FormControl component="fieldset">
            <FormLabel component="legend" className={classes.label}>
              Project Orientation
            </FormLabel>
            <Typography className={classes.disabledfields}>
              {projectFormData?.orientation}
            </Typography>
          </FormControl>
        </Grid>
        {isEditable ? (
          <Grid item xs={4} className={classes.buttondiv}>
            <Button
              variant="contained"
              color="default"
              className={classes.button}
              onClick={discardHandler}
            >
              Discard
            </Button>
            <Button
              variant="contained"
              color="default"
              className={classes.savebutton}
              onClick={saveHandler}
            >
              Save
            </Button>
          </Grid>
        ) : (
          <Grid item xs={4} className={classes.buttondiv}>
            {hasAdminAccess  ? (
               <Button
               variant="contained"
               color="default"
               className={classes.button}
               startIcon={<EditIcon />}
               onClick={editHandler}
             >
               Edit
             </Button>
            ) : (
              <Button
              variant="contained"
              color="default"
              className={classes.button}
              startIcon={<EditIcon />}
              onClick={editHandler}
              disabled
            >
              Edit
            </Button>
            )}
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default ProjectForm;
