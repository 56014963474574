import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { ChangeEvent, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import {
  Grid,
  Paper,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
} from "@material-ui/core";

import { useStyles } from "./styles";
import {
  getCheckedTasks,
  handleCheckListSubmit,
} from "../../../../../apis/onboarding";
import { recruiteeBasicDetails } from "../../../../../recoil/atoms";
import CompletedTasks from "./CompletedTasks";
import UncheckedTasks from "./UncheckedTasks";

const CHECK_LIST_TASKS = [
  { item: "Create organisational Email", checked: false },
  { item: "Add to slack group", checked: false },
  {
    item: "Verify the employee details and convert the candidate to full time employee in HRMS",
    checked: false,
  },
  {
    item: "Walk through (Orientation) the policies and company culture.",
    checked: false,
  },
  { item: "Provide WIFI password", checked: false },
  {
    item: "Send welcome mail to all the employees introducing the candidate",
    checked: false,
  },
  { item: "Create event for birthday", checked: false },
  { item: "Tour office and allocate seat", checked: false },
  { item: "Welcome and Company Induction", checked: false },
  {
    item: "Send welcome note as soon as the email ID is created",
    checked: false,
  },
  { item: "Social Media Orientation", checked: false },
  { item: "HRMS Demo", checked: false },
  { item: "Create bank account", checked: false },
  { item: "Technology Induction", checked: false },
  { item: "Assign buddy", checked: false },
  { item: "Company News", checked: false },
  {
    item: "Move the candidate to hire in Rooster and mark the onboarding process complete",
    checked: false,
  },
];

export type TASK = {
  item: string;
  checked: boolean;
};

const CheckLists = ({
  showToast,
}: {
  showToast: {
    error: (message: string) => void;
    success: (message: string) => void;
    warning: (message: string) => void;
  };
}) => {
  const classes = useStyles();

  const userInfo = useRecoilValue(recruiteeBasicDetails);

  const [tasks, setTasks] = useState<TASK[]>(CHECK_LIST_TASKS);
  const [completedTasks, setCompletedTasks] = useState<TASK[]>([]);
  const [payload, setPayload] = useState<TASK[] | []>([]);

  const [showUnchecked, setShowUnchecked] = useState<boolean>(false);
  const [showCompleted, setShowCompleted] = useState<boolean>(false);

  // functions
  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    setCompletedTasks([]);
    let response = await getCheckedTasks(userInfo.id);
    if (response.status === 200 && response.data.length > 0) {
      response.data.map(
        (data: { item: string; checked: boolean; _id: string }) => {
          return setCompletedTasks((prev) => {
            let temp = [...prev];
            temp.push({
              checked: data.checked,
              item: data.item,
            });
            return temp;
          });
        }
      );
    }
  };

  useEffect(() => {
    if (completedTasks.length > 0) {
      completedTasks.map((data) => {
        let temp;
        temp = tasks.filter((task) => {
          return task.item !== data.item;
        });
        return setTasks(temp);
      });
    }
  }, [completedTasks]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const matchingIndex = tasks.findIndex((task) => {
      return task.item === event.target.value;
    });

    let items = [...tasks];
    let item = items[matchingIndex];
    item.item = event.target.value;
    item.checked = event.target.checked;
    items[matchingIndex] = item;
    setTasks(items);
  };

  useEffect(() => {
    updatePayload();
  }, [tasks]);

  const updatePayload = () => {
    let checkedTasks = tasks.filter((task) => {
      return task.checked === true;
    });

    if (checkedTasks.length > 0) {
      setPayload(() => {
        return [...checkedTasks, ...completedTasks];
      });
    } else {
      setPayload([]);
    }
  };

  const handleSubmit = async () => {
    if (payload.length > 0) {
      const response = await handleCheckListSubmit(payload, userInfo.id);

      if (response.status === 200) {
        fetchTasks();
        showToast.success("Task Added!");
        setPayload([]);
      }
      if (response.status !== 200) {
        showToast.error("Something went wrong.. Please try again later.");
      }
    } else {
      showToast.error("No Task Selected!");
    }
  };

  return (
    <Grid container className={classes.root}>
      {/* Pending */}
      <UncheckedTasks handleChange={handleChange} tasks={tasks} />

      {/* Completed */}
      <CompletedTasks completedTasks={completedTasks} />

      {/* Submit BUtton */}
      <Grid container justifyContent="flex-end">
        <Button
          variant="contained"
          className={classes.doneButton}
          onClick={handleSubmit}
          disabled={tasks.length < 1}
        >
          Done
        </Button>
      </Grid>
    </Grid>
  );
};

export default CheckLists;
