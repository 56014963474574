import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { employeePageTableAtoms } from "../../recoil/atoms";

interface Props {
  setdataProps: React.Dispatch<React.SetStateAction<never[]>>;
}

const useStyles = makeStyles((theme) => ({
  parentSearch: {
    paddingLeft: ".5em",
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
    border: "1px solid black",
    background: "#EDF6F9",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));

const SearchBox: React.FC<Props> = ({ setdataProps }) => {
  const classes = useStyles();

  //recoil code
  const setEmployeePageTableState = useSetRecoilState(employeePageTableAtoms); //to update recoil state
  const employeePageTableState = useRecoilValue(employeePageTableAtoms); // to read recoil state

  return (
    <Grid container item xs={12} className={classes.parentSearch}>
      <Paper component="form" className={classes.root}>
        <InputBase
          className={classes.input}
          placeholder="Search by name"
          inputProps={{ "aria-label": "search employees" }}
          onChange={(event) =>
            setTimeout(() => {
              //
              setEmployeePageTableState({
                tableData: employeePageTableState?.tableData,
                search: event.target.value,
                filterBy: employeePageTableState?.filterBy,
                currentPage: 1,
                pages: employeePageTableState?.pages,
              });
            }, 1000)
          }
        />
        <IconButton
          // type="submit"
          className={classes.iconButton}
          aria-label="search"
        >
          <SearchIcon fontSize="small" />
        </IconButton>
      </Paper>
    </Grid>
  );
};
export default SearchBox;
