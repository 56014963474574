import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import Table from "../../../components/Table";
import { useStyles } from "./styles";
import Popup from "../../../components/Popup";
import LeaveAppliedForm from "../SideBar/LeaveAppliedForm";
import LTable from "./Table";
import {RequestedLeave} from "../types";

const HEAD_CELLS = [
  { key: "leave_type", label: "Leave Type" },
  { key: "duration", label: "Duration" },
  { key: "from", label: "From" },
  { key: "to", label: "To" },
  { key: "status", label: "Status" },
];
interface IProps {
  parentHeight?: number | undefined;
  data?: RequestedLeave[];
  refresh: () => void;
  showToast?: any;
  getUserLeave:() => Promise<any>;
}

const LeaveTable: React.FC<IProps> = ({
  parentHeight,
  data,
  showToast,
  refresh,
  getUserLeave,
}) => {
  const classes = useStyles();
  let height = parentHeight ? `${parentHeight - 130}px` : "auto";
  const [clickedTableRow] = useState<number>(0);
  const [openLeaveDetails, setOpenLeaveDetails] = useState<boolean>(false);

  const [clickedCard, setClickedCard] = useState<RequestedLeave>();

  /**
   * @description Function to open leave details modal
   * @method handleOpenLeaveDetailsModal
   * @returns nothing
   */

  const handleOpenLeaveDetailsModal = () => {
    setOpenLeaveDetails(true);
  };

  /**
   * @description Function to cloes leave details modal
   * @method handleCloseLeaveDetailsModal
   * @returns nothing
   */
  const handleCloseLeaveDetailsModal = () => {
    setOpenLeaveDetails(false);
  };

  /**
   * @description Function to handle table row click
   * @method tableClickHandler
   * @param {any} values
   * @returns nothing
   */

  const tableClickHandler = (values: RequestedLeave) => {
    setClickedCard(values);
    handleOpenLeaveDetailsModal();
  };

  return (
    <Grid
      container
      className={classes.tableContainer}
      style={{ height: height }}
    >
      <LTable
        headCells={HEAD_CELLS}
        items={data}
        isLoading={false}
        tableClickHandler={tableClickHandler}
        clickedTableIndex={clickedTableRow}
      ></LTable>
      <Popup
        name="Leave Applied"
        open={openLeaveDetails}
        handleClose={handleCloseLeaveDetailsModal}
      >
        <LeaveAppliedForm
          data={clickedCard}
          handleClose={handleCloseLeaveDetailsModal}
          showToast={showToast}
          getUserLeave={getUserLeave}
        />
      </Popup>
    </Grid>
  );
};

export default LeaveTable;
