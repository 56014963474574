import { makeStyles } from "@material-ui/core";
import { theme } from "../../theme/theme";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
  },
  rootOnboarding: {
    paddingLeft: "2em",
    paddingRight: "2em",
  },
  expanded: {
    margin: "0px !important",
    minHeight: "100% !important",
    overflow: "auto",
    paddingRight: "0 !important",
  },

  accordionComp: {
    boxShadow: "none",
    borderRadius: `${theme.typography.pxToRem(10)} !important`,
    "&::before": {
      display: "none",
    },
    "&$expanded": {
      height: "100%",
      overflow: "hidden",
      margin: "auto",
    },
  },

  accordionHide: {
    display: "none",
  },

  accordionHeader: {
    border: "1px solid rgba(0, 0, 0, 0.15)",
    borderRadius: "0.625rem",
    paddingRight: "0.75rem",
    alignItems: "center",
  },

  heading: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    color: theme.textColor.primary,
    paddingRight: 15,
    display: "flex",
    alignItems: "center",
  },

  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },

  accordionBody: {
    overflow: "auto",
  },
  badge: {
    "& .MuiBadge-badge": {
      right: -3,
      top: 8,
      background: `green`,
      padding: "0 4px",
    },
  },
  sortContainer: {
    marginRight: "2px",
  },
  sortCategory: {
    position: "absolute",
    borderRadius: "5px",
    background: "#fff",
    width: "150px",
    border: "1px solid rgba(20, 33, 61, 0.1)",
    boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.1)",
    marginTop: theme.typography.pxToRem(5),
    zIndex: 99,
  },
  sortCategoryHide: {
    display: "none",
  },
  categoryItems: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    padding: `${theme.typography.pxToRem(11)} ${theme.typography.pxToRem(16)}`,
    cursor: "pointer",
  },
  sortLabels: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    color: "#000",
    margin: 0,
  },
  sortTag: {
    width: "auto",
    marginRight: "1.5rem",
  },
  searchField: {
    width: "auto",
    marginRight: "1.2rem",
    "&::focus": {},
  },

  rootBasicDetails: {
    boxShadow: "0px 4px 10px 0px #0000001A",
    borderLeft: "4px solid #F3D90C",
  },
  rootEduQual: {
    boxShadow: "0px 4px 10px 0px #0000001A",
    borderLeft: "4px solid #CB8EE0",
  },
  rootWorkExperience: {
    boxShadow: "0px 4px 10px 0px #0000001A",
    borderLeft: "4px solid #EFA2CC",
  },
  rootFamilyNlanguage: {
    boxShadow: "0px 4px 10px 0px #0000001A",
    borderLeft: "4px solid #83CAC6",
  },
  rootBankDetails: {
    boxShadow: "0px 4px 10px 0px #0000001A",
    borderLeft: "4px solid #F5918B",
  },
  rootEmployeeInfo: {
    boxShadow: "0px 4px 10px 0px #0000001A",
    borderLeft: "4px solid #93A5E1",
  },
  accordionTitle: {
    borderBottom: "1px solid silver",
  },
  accordionIcon: {
    paddingRight: 5,
    width: 24,
    height: 20.57,
  },
});

export default useStyles;
