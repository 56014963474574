import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EmployeeLeaveTable from "../../EmployeeLeaveTable";
import { useStyles } from "./styles";
import { getLeaveById } from "../../../apis/leaves";
import { LEAVE_LIMIT } from "./constants";
import LeaveBalanceUpdate from "./LeaveBalanceUpdate";

const tableHeaders = [
  "Applied Date",
  "Start Date",
  "End Date",
  "Approved/Rejected By",
  "Type",
  "Status",
  "Reason",
];

export type leaveDataType = {
  applied_on: string;
  from: string;
  to: string;
  approved_by: string;
  leave_type: string;
  isApproved: boolean;
  isPending: boolean;
  reason: string;
};

interface EmployeeLeaveAccordionProps {
  employeeId: string;
}

const EmployeeLeaveAccordion: React.FC<EmployeeLeaveAccordionProps> = ({
  employeeId,
}) => {
  const classes = useStyles();
  const [leaveData, setLeaveData] = useState<leaveDataType[]>([]);
  const [isDataFetched, setIsDataFetched] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageCount, setPageCount] = useState(1);
  const [searchedData, setSearchedData] = useState(leaveData);
  const [sortTitle, setSortTitle] = useState("");
  const [sortByDate, setSortByDate] = useState("");

  useEffect(() => {
    getAllLeaves();
  }, [pageNo, isDataFetched, sortTitle, sortByDate]);

  const getAllLeaves = async () => {
    try {
      const resp = await getLeaveById(
        employeeId,
        pageNo,
        LEAVE_LIMIT,
        sortTitle,
        sortByDate
      );

      let tempItem: leaveDataType[] = [];

      if (resp.status === 200 && resp.data.total > 0) {
        setIsDataFetched(true);

        let data = resp.data.total;
        let countPage = Math.ceil(data / LEAVE_LIMIT);

        setPageCount(countPage);

        resp.data.data.map((value: any) => {
          let tempUserObject: {
            applied_on: string;
            from: string;
            to: string;
            approved_by: string;
            leave_type: string;
            isApproved: boolean;
            isPending: boolean;
            reason: string;
          } = {
            applied_on: value.applied_on,
            from: value.from,
            to: value.to,
            approved_by: value?.approved_by || value?.rejected_by,
            leave_type: value.leave_type,
            isApproved: value.isApproved,
            isPending: value.isPending,
            reason: value.reason,
          };

          tempItem.push(tempUserObject);
        });
        setLeaveData([...tempItem]);
        setSearchedData([...tempItem]);
      } else {
        setIsDataFetched(false);
      }
    } catch (err: any) {
      console.error(err);
    }
  };

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: any) => {
    setPageNo(value);
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} className={classes.leaveacc}>
        <Accordion
          className={classes.accordionRoot}
          defaultExpanded={true}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            style={{
              border: "1px solid #d6d6d6",
              borderRadius: "5px",
            }}
          >
            <Typography>Leave Balance </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <LeaveBalanceUpdate employeeId={employeeId} />
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12}>
        <Accordion
          className={classes.accordionRoot}
          defaultExpanded={true}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            style={{
              border: "1px solid #d6d6d6",
              borderRadius: "5px",
            }}
          >
            <Typography>Detailed Leave Report </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="column">
              <Grid item>
                <EmployeeLeaveTable
                  tableHeaders={tableHeaders}
                  tableData={leaveData}
                  setSearchedData={setSearchedData}
                  searchedData={searchedData}
                  sortTitle={sortTitle}
                  setSortTitle={setSortTitle}
                  setSortByDate={setSortByDate}
                  setPageNo={setPageNo}
                />
              </Grid>
              <Grid
                container
                justifyContent="flex-end"
                style={{ padding: "1em" }}
              >
                <Pagination
                  defaultPage={pageNo}
                  onChange={handleChangePage}
                  count={pageCount}
                  color="primary"
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default EmployeeLeaveAccordion;
