import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  submitDiv: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "2em",
  },
  submitButton: {
    backgroundColor: "#14213D",
    color: "#fff",
    width: "7em",
  },
  submitButtonDisabled: {
    background: "#14213D4D",
    color: "#fff !important",
  },

  // doc styles:
  docContainer: {
    padding: "3%",
  },
  paperContainer: {
    position: "relative",
    justifyContent: "space-evenly",
    padding: 20,
    width: "100%",
  },
  label: {
    position: "absolute",
    left: 0,
    top: 0,
    transform: "translate(0, 24px) scale(1)",
  },
  labelColor: {
    backgroundColor: "#fff",
    color: "#000",
    fontSize: "18px",
    padding: 10,
  },
  longText: {
    backgroundColor: "#fff",
    color: "#000",
    fontSize: "17px",
    padding: 10,
  },
  dropContainer: {
    padding: "3%",
  },
  dashedContainer: {
    border: "2px dashed silver",
    borderRadius: 10,
    padding: "3%",
  },
  itemStyle: {
    textAlign: "center",
  },
  boldText: {
    fontSize: "20px",
    fontWeight: 600,
  },
  instruction: {
    color: "#686868",
    paddingTop: "1.5em",
  },

  inputfile: {
    width: "0.1px",
    height: "0.1px",
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,
  },

  inputButtonIcon: {
    backgroundColor: "#14213D",
    padding: "0.2em",
    paddingRight: "1em",
    paddingLeft: "1em",
    borderRadius: "10px",
    color: "#fff",
  },

  inputlabel: {
    padding: "0.01em",
    paddintTop: "1em",
    paddingRight: "1em",
    fontSize: "1.25em",
    fontWeight: 300,
    color: "#000",
    border: "1px solid grey",
    borderRadius: "0.5em",
    backgroundColor: "#fff",
    display: "inline-block",
    cursor: "pointer",
  },

  // inputfile:focus + label,
  // inputfile + label:hover {
  //     background-color: red,
  // }
});
