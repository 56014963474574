import * as React from "react";
import { isFirstNDaysOfTheQuarter } from "../../../utils/isFirstNDaysOfTheQuarter";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import { usePermissionObjectFor } from "../../../hooks/permission/usePermissionObjectFor";
import { IUserData } from "../views/TeamLeadView";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    my1EM: {
      marginBlock: "1em",
    },
  })
);

export const RenderCreateKRAButton: React.FC<IRenderCreateKRAButtonProps> = ({
  employeeID,
  employeeName,
  renderCreateKraButton,
  renderText,
}) => {
  const permission = usePermissionObjectFor("kras");
  const leadId: IUserData = JSON.parse(sessionStorage.tvcpsess);

  const classes = useStyles();
  let text = "";
  const isUnder15DaysOfTheQ = isFirstNDaysOfTheQuarter(15);
  // const isUnder15DaysOfTheQ = true;
  const hasCreatePermission = permission.includes("create");
  const hasApprovePermission = permission.includes("approve");

  if (hasCreatePermission) {
    text = `Next KRA of ${employeeName} can be created on or after`;
  }
  if (!hasCreatePermission && !hasApprovePermission) {
    text = "Your next KRA will be available from";
  }
  if (
    employeeID !== leadId.userId &&
    isUnder15DaysOfTheQ &&
    hasCreatePermission
  ) {
    return (
      <div className={classes.my1EM}>
        {renderCreateKraButton({ employeeID, employeeName })}
      </div>
    );
  } else {
    return <div className={classes.my1EM}>{renderText({ text })}</div>;
  }
};

interface IButtonProps
  extends Pick<IRenderCreateKRAButtonProps, "employeeID" | "employeeName"> {}
interface ITextProps {
  text: string;
}

interface IRenderCreateKRAButtonProps {
  employeeID: string;
  employeeName: string;
  renderCreateKraButton: (props: IButtonProps) => React.ReactNode;
  renderText: (props: ITextProps) => React.ReactNode;
}
