import { Theme, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    image: {
        position: "relative",
        "& > img": {
            width: "5.7rem",
            height: "5rem",
        },
        "&::before": {
            opacity: "var(--opacity)",
            top: 0,
            left: 0,
            position: "absolute",
            content: "var(--text)",
            width: "100%",
            height: "calc(100% - 6px)",
            transition: "opacity 0.2s ease-in",
            backgroundColor: "rgba(0,0,0,0.4)",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
        },
    },
}));
