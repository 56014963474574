import React from "react";
import { Avatar, Paper, Typography } from "@material-ui/core";
import { useStyles } from "./styles";

export interface props {
    image: string;
    label: string;
    styleProps: {
        avatarBgColor: string;
    };
}

const TechnologyCard: React.FC<props> = ({ image, label, styleProps }) => {
    const sp: props = { image, label, styleProps };
    const classes = useStyles(sp);

    return (
        <Paper
            component={"div"}
            id={label}
            variant="outlined"
            elevation={0}
            className={classes.paper}
        >
            <Avatar variant="rounded" src={image} className={classes.avatar}></Avatar>
            <Typography variant="h6" className={classes.typo}>
                {label}
            </Typography>
        </Paper>
    );
};

export default TechnologyCard;
