import * as React from "react";
import { DoneAllOutlined, DoneOutlined } from "@material-ui/icons";
import { usePermissionObjectFor } from "../../../../hooks/permission/usePermissionObjectFor";

/**
 * - Status => `new` render Edit button along with History Button
 * - Status => `old` render Filter and History Button
 *
 */
export const Actions: React.FC<IActions> = ({
  status,
  renderEditButton,
  renderApproveButton,
  renderHistoryButton,
  approved,
}) => {
  const permissions = usePermissionObjectFor("kras");
  const hasApprovePermission = permissions.includes("approve");
  const hasUpdatePermission = permissions.includes("upDate");
  const text = new Map();
  text.set(true, "Approve").set(false, "Approve");
  const icon = new Map<boolean, React.ReactNode>();
  icon
    .set(true, <DoneAllOutlined style={{ color: "green" }} />)
    .set(false, <DoneOutlined />);

  if (status === "new") {
    return (
      <>
        {/* <>{hasUpdatePermission && renderEditButton()}</> */}
        <>
          {hasApprovePermission &&
            renderApproveButton({
              text: text.get(approved),
              icon: icon.get(approved),
            })}
        </>
      </>
    );
  }
  if (status === "old") {
    return (
      <>
        {/* <>{renderFilter()}</> */}
        <>{renderHistoryButton()}</>
      </>
    );
  }

  return <></>;
};

interface IApproveButtonProps {
  text: string;
  icon: React.ReactNode;
}

export interface IActions {
  status: "new" | "old";
  approved: boolean;
  renderEditButton: () => React.ReactNode;
  renderHistoryButton: () => React.ReactNode;
  renderApproveButton: (props: IApproveButtonProps) => React.ReactNode;
}
