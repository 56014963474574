import {
  Paper,
  Typography,
  Box,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import { openNewTab } from "../../../utils/openNewTab";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  IEmployeeDocument,
  getEmployeeDocuments,
} from "../../../apis/employees";
import { Loader } from "../../../components/Preloader/Preloader";

export const EmployeeDocument: React.FC<{}> = () => {
  const fileHandler = openNewTab();
  const params: { id: string } = useParams();
  const [documents, setDocuments] = useState<IEmployeeDocument[]>();

  useEffect(() => {
    (async () => {
      try {
        const data = await getEmployeeDocuments(params.id);
        setDocuments(() => data.data);
      } catch (error) {
        console.warn(error);
      }
    })();
  }, []);

  if (!documents) {
    return (
      <div style={{ display: "grid", placeItems: "center" }}>
        NO DATA FOUND
      </div>
    );
  }

  return (
    <div>
      <Paper
        style={{ paddingInline: "32px", paddingBlock: "16px" }}
        variant="outlined"
      >
        {documents.map((data) => (
          <Paper
            key={data.filename}
            style={{
              marginBlock: "16px",
              paddingInline: "16px",
              paddingBlock: "16px",
            }}
            variant="outlined"
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ flex: 1 }}>
                <Typography variant="body1">{data.filename}</Typography>
              </Box>
              <Box sx={{ width: "320px", display: "flex" }}>
                <div style={{ flex: 1 }} />

                <Button
                  // startIcon={<CloudDownload />}
                  startIcon={<Visibility />}
                  variant="outlined"
                  onClick={() => {
                    fileHandler(data.path);
                  }}
                >
                  View
                </Button>
              </Box>
            </Box>
          </Paper>
        ))}
      </Paper>
    </div>
  );
};
