import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Menu,
  MenuProps,
  MenuItem,
  ListItemText,
  Select,
} from "@material-ui/core";
import SelectViewValueFinder from "../../../../utils/SelectViewValueFinder";
import { ClickAwayListener } from "@material-ui/core";
import { useStyles } from "./styles";

interface Iprops {
  setCStatus: React.Dispatch<React.SetStateAction<string>>;
  Cstatus: string;
  FilterOptions: {
    label: string;
    value: string;
  }[];
}

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const FilterButton: React.FC<Iprops> = ({
  setCStatus,
  Cstatus,
  FilterOptions,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (value: string) => {
    FilterOptions.forEach((item) => {
      if (value === item.value) {
        setCStatus(value);
      }
    });
    setAnchorEl(null);
  };
  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        className={classes.button}
        onClick={handleClick}
      >
        Filter by: {SelectViewValueFinder(FilterOptions, Cstatus)}
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {FilterOptions.map((option, index) => (
          <MenuItem
            key={option.label}
            selected={index === 0}
            onClick={(e) => handleClose(option.value)}
          >
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
};
export default FilterButton;
