export const experience = [
  {
    organizationName: "",
    tctc: 0,
    designation: "",
    workFunction: "",
    startDate: "",
    endDate: "",
    reasonForLeaving: "",
  },
];

const familyDetails = {
  spouse: {
    name: "",
    dependent: "",
    occupation: "",
    dob: "",
  },
  mother: {
    name: "",
    dependent: "",
    occupation: "",
    dob: "",
  },
  father: {
    name: "",
    dependent: "",
    occupation: "",
    dob: "",
  },
};

const languagesDetails = [
  {
    languageName: "English",
    speak: false,
    read: false,
    write: false,
  },
  {
    languageName: "Hindi",
    speak: false,
    read: false,
    write: false,
  },
  {
    languageName: "Assamese",
    speak: false,
    read: false,
    write: false,
  },
  {
    languageName: "Bengali",
    speak: false,
    read: false,
    write: false,
  },
];

export const familyAndLanguage = {
  family: familyDetails,
  languages: languagesDetails,
};

export const employeeInfo = {
  employeeNo: 0,
  status: true,
  department: "",
  designation: "",
  role: "",
  assignedTo: ""

};


export const assetAllocationfreshers = {
  freshers: {
    comment: "",
  },
};
export const assetAllocationexperience = {
  experienced: {
    slNoOfLaptop: "",
    slNoOfMouse: "",
    slNoOfAdapter: "",
  },
};

export const checkDocumentSelection = {
  class10: false,
  class12: false,
  grad: false,
  postGrad: false,
  diploma: false,
};

//work

export const checkWorkDocumentSelection = {
  relieving: false,
  lastSal: false,
  secondLast: false,
  thirdLast: false,
};

//identity

export const checkIdDocumentSelection = {
  pan: false,
  aadhar: false,
};
