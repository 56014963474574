import React from 'react';
import { Grid } from '@material-ui/core';
import TextField from '../../../../components/Fields/TextField';
import Button from '../../../../components/CustomButton';
import { useStyles } from './styles';

const EventForm: React.FC = () => {
  const classes = useStyles();

  const handleSubmit = () => {};

  return (
    <Grid direction="column" className={classes.mainContainer} container>
      <Grid direction="column" container>
        <p className={classes.label}>Event name</p>
        <Grid item className={classes.textFieldWrapper}>
          <TextField onChange={()=>console.log('')}  label="" type="" variant="outlined"/>
        </Grid>
      </Grid>
      <Grid className={classes.bottomGrid} direction="column" container>
        <p className={classes.label}>Date & time</p>
        <Grid item className={classes.textFieldWrapper}>
          <TextField onChange={()=>console.log('')} label="" variant="outlined" type="date" />
        </Grid>
      </Grid>
      <Grid justify="flex-end" container className={classes.buttonGroup}>
        <Grid xs={3} className={classes.button} item>
          <Button
            label="Delete"
            isDisabled={false}
            isBordered={false}
            startIcon=""
            endIcon=""
            isOutlined={true}
            onButtonClick={handleSubmit}
            color="primary"
          />
        </Grid>
        <Grid xs={3} item>
          <Button
            label="Create"
            isDisabled={false}
            isBordered={false}
            startIcon=""
            endIcon=""
            isOutlined={false}
            onButtonClick={handleSubmit}
            color="primary"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EventForm;
