import React, { useEffect, useContext } from "react";
import {
  Grid,
  Typography,
  IconButton,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Button,
  // Button,
  // Dialog,
} from "@material-ui/core";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import { useStyles } from "./styles";
import { useHistory, useRouteMatch } from "react-router-dom";
import { postData, Toast } from "../../KnowledgeHubPage";
import useParentStyles from "../../styles";
import { createPost } from "../../../../apis/kHubPost";
import {
  capitalizeString,
  getPersistentAuthentication,
} from "../../../../utils/functions";
import { useRecoilValue } from "recoil";
import { kHubPostTechsAtom } from "../../../../recoil/atoms";
// import AddRoundedIcon from "@material-ui/icons/AddRounded";
// import useParentStyles from "../../styles";

interface props {
  hideModal: () => void;
  hideModalAndClear: () => void;
  postData: postData;
  setPostData: React.Dispatch<React.SetStateAction<postData>>;
}

const ModalBody: React.FC<props> = ({
  hideModal,
  postData,
  setPostData,
  hideModalAndClear,
}) => {
  const classes = useStyles();
  const parentClasses = useParentStyles();
  const history = useHistory();
  const userId = getPersistentAuthentication()?.userId;
  const showToast = useContext(Toast);
  //const parentClasses = useParentStyles();
  const { path } = useRouteMatch();

  const techs = useRecoilValue(kHubPostTechsAtom);

  //const [selectedCategory, setSelectedCategory] = useState<string>(categories[0]);
  //const [showAddNewCategory, setShowAddNewCategory] = useState<boolean>(false);

  useEffect(() => {
    setPostData({
      ...postData,
      author: userId,
      updatedBy: userId,
      body: "# Drop that knowledge!!!",
    });
  }, []);

  function handleChange(e: any) {
    setPostData({ ...postData, [e.target.name]: e.target.value });
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    try {
      hideModal();
      const data = await createPost(postData);
      history.push(`${path}/editor-view/${data.data.data._id}`);
      showToast.success("Post saved as draft.");
    } catch (error: any) {
      // console.log("error khub",error)
      showToast.error(error.response.data.message);
    }
  }

  // function showCategoryModal() {
  //     setShowAddNewCategory(true);
  // }

  // function hideCategoryModal() {
  //     setShowAddNewCategory(false);
  // }

  return (
    <div className={classes.container}>
      <div className={classes.heading}>
        <Typography variant="h6">Create New Documentation</Typography>
        <IconButton onClick={hideModalAndClear}>
          <HighlightOffOutlinedIcon htmlColor="#140047" />
        </IconButton>
      </div>
      <form className={classes.form} onSubmit={handleSubmit}>
        <Grid container>
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            className={classes.mb}
          >
            <Grid item xs={5}>
              <Typography className={classes.labels} variant="body1">
                Category*
              </Typography>
            </Grid>
            <Grid item xs={7} container justifyContent="flex-end">
              <Select
                name="category"
                fullWidth
                variant="outlined"
                value={postData.category}
                onChange={handleChange}
                required
              >
                {/* <MenuItem value={""} onClick={showCategoryModal}>
                                    <Button
                                        startIcon={<AddRoundedIcon fontSize="small" />}
                                        variant="contained"
                                        fullWidth
                                        disableElevation
                                        disableTouchRipple
                                        disableFocusRipple
                                        disableRipple
                                        className={classes.btn}
                                    >
                                        Add new category
                                    </Button>
                                </MenuItem> */}
                {techs.map((category) => (
                  <MenuItem
                    key={category}
                    value={category}
                    className={classes.menuItem}
                  >
                    {capitalizeString(category)}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            className={classes.mb}
          >
            <Grid item xs={5}>
              <InputLabel htmlFor="language">
                <Typography className={classes.labels} variant="body1">
                  Language*
                </Typography>
              </InputLabel>
            </Grid>
            <Grid item xs={7} container justifyContent="flex-end">
              <TextField
                fullWidth
                required
                id="language"
                name="language"
                variant="outlined"
                placeholder="Enter Language"
                value={postData.language}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            className={classes.mb}
          >
            <Grid item xs={5}>
              <InputLabel htmlFor="framework">
                <Typography className={classes.labels} variant="body1">
                  Framework*
                </Typography>
              </InputLabel>
            </Grid>
            <Grid item xs={7} container justifyContent="flex-end">
              <TextField
                fullWidth
                required
                id="framework"
                name="framework"
                variant="outlined"
                placeholder="Enter Framework"
                value={postData.framework}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            className={classes.mb}
          >
            <Grid item xs={5}>
              <InputLabel htmlFor="platform">
                <Typography className={classes.labels} variant="body1">
                  Platform*
                </Typography>
              </InputLabel>
            </Grid>
            <Grid item xs={7} container justifyContent="flex-end">
              <TextField
                fullWidth
                required
                id="platform"
                name="platform"
                variant="outlined"
                placeholder="Enter Platform"
                value={postData.platform}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            className={classes.mb}
          >
            <Grid item xs={5}>
              <InputLabel htmlFor="title">
                <Typography className={classes.labels} variant="body1">
                  Title*
                </Typography>
              </InputLabel>
            </Grid>
            <Grid item xs={7} container justifyContent="flex-end">
              <TextField
                fullWidth
                required
                id="title"
                name="title"
                multiline
                maxRows={10}
                variant="outlined"
                placeholder="Enter Title"
                value={postData.title}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          disableElevation
          type="submit"
          className={parentClasses.btnBase}
        >
          Create
        </Button>
      </form>
      {/* <Dialog
                open={showAddNewCategory}
                onClose={hideCategoryModal}
                className={parentClasses.dialog}
            >
                <div className="">

                </div>
            </Dialog> */}
    </div>
  );
};

export default ModalBody;
