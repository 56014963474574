import axios from "./axios.global";
import moment from "moment";

const BASE_URL = "attendance";

export const getAttendanceFilterData = async (filter: string , empName: string) => {
  console.log("dhdgdh",empName)
  //getting attendance data

  try {
    let response = await axios.get(`${BASE_URL}/filters?`, {
      params: {
        date: filter,
        employeeName:empName
      },
    });
    return response;
  } catch (err: any) {
    return err;
  }
};

export const getAttendanceActiveHour = async (filter: string) => {
  //getting attendance data

  try {
    let response = await axios.get(`${BASE_URL}/active-hour?`, {
      params: {
        date: filter,
      },
    });
    return response;
  } catch (err: any) {
    return err;
  }
};

export const getAttendance = async (
  pageNo: number,
  limit: number,
) => {
  //getting attendance data

  try {
    let response = await axios.get(`${BASE_URL}?page=${pageNo}&limit=${limit}`);
    return response;
  } catch (err: any) {
    return err;
  }
};
export const getAttendanceByName = async (
  pageNo: number,
  limit: number,
  empName:string
) => {
  try {
    let response = await axios.get(`${BASE_URL}?page=${pageNo}&limit=${limit}&empName=${empName}`);
    return response;
  } catch (err: any) {
    return err;
  }
};

export const uploadAttendencelist = async (payload: object) => {
  // const { title, contentArea, contentType } = payload as {title: string; contentArea: string; contentType: string}; //sending form data
  try {
    let response = await axios.post(`${BASE_URL}`, payload);
    return { data: response, error: undefined };
  } catch (err: any) {
    return { data: undefined, error: err };
  }
};


export const getAttendanceFilterDataInOut = async (filter: string) => {
  //getting attendance data

  try {
    let response = await axios.get(`${BASE_URL}/daily-in-outs?`, {
      params: {
        date: filter,
      },
    });
    return response;
  } catch (err: any) {
    return err;
  }
};

export const getAttendanceFilterForm = async (filter: {[key: string]: any}) => {
  //getting attendance data

  try {
    let response = await axios.get(`${BASE_URL}/search-employee?`, {
      params: {
        empName: filter.empName,
        byDate:filter.byDate,
        fromDate :filter. fromDate,
        ToDate :filter. filter.toDate,
        fromTime:filter.fromTime,
        toTime:filter.toTime,
        fromDayInterval:filter.fromDayInterval,
        toTimeInterval:filter.fromTimeInterval
      },
    });
    return response;
  } catch (err: any) {
    return err;
  }
};

export const getIndividualData = (currentDate :any,limitDay:any,attendance=[]) => {
      // Get the current timestamp
      const currentTimestamp = Date.now();
      const monday = moment().startOf("week").add(1, "days");

      // Calculate the timestamp 30 days ago
      const thirtyDaysAgoTimestamp =
        currentTimestamp - 30 * 24 * 60 * 60 * 1000;
      const threeMonthsAgo = currentTimestamp - 90 * 24 * 60 * 60 * 1000;
      const sixMonthsAgo = currentTimestamp - 180 * 24 * 60 * 60 * 1000;
      const anYearAgo = currentTimestamp - 360 * 24 * 60 * 60 * 1000;
  
      const objectPattern = {
        "This week": monday,
        "Last 1 month": thirtyDaysAgoTimestamp,
        "Last 3 months": threeMonthsAgo,
        "Last 6 months": sixMonthsAgo,
        "Last 1 year": anYearAgo,
      };
      const filteredAttendance = attendance.filter(
        (item :any )=>
          dateToTimestamp(item.date) <= currentDate &&
          dateToTimestamp(item.date) >= limitDay,
      );
      return filteredAttendance
}

export const dateToTimestamp = (dateString: string): number => {
  const [day, month, year] = dateString.split("/").map(Number);
  const timestamp = new Date(year, month - 1, day).getTime();
  return timestamp;
};

export const getAttendanceByNameandDate = async (
  pageNo: number,
  limit: number,
  empName:string,
) => {
  try {
    let response = await axios.get(`${BASE_URL}/individual-attendance?page=${pageNo}&limit=${limit}&empName=${empName}&fromDate=${empName}&toDate=${empName}`);
    return response;
  } catch (err: any) {
    return err;
  }
};