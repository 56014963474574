import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      outline: 'none',
      width: 580,
      height: 284,
      borderRadius: 10,
      textAlign: 'center'
    },
    modalLogo: {
      width: 171,
      height: 51,
      paddingTop: 38
    },
    modalText: {
      fontWeight: 500,
      fontSize: 20
    },
    modalLink: {
      textDecoration: 'none',
      color: '#0066FF'
    },
    modalLinkText: {
      fontWeight: 400,
      fontSize: 15,
      paddingBottom: '3%'
    },
    modalButton: {
      backgroundColor: '#0E172A',
      color: '#fff',
    }
  }),
);
