import React, { useState } from "react";
import { Stepper, Step, StepLabel, Box, Button } from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import { IKRADetails } from "../sub-pages/KRADetailPage.tsx";
import { kraStyles } from "../styles";

interface IStepper {
  activeStep: number;
  steps: IKRADetails[];
  // prevHandler: () => void;
  // nextHandler: () => void;
  // backHandler: () => void;
}

const StepperComponent = ({ activeStep, steps }: IStepper) => {
  const classes = kraStyles();

  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      // style={{ border: "1px solid red" }}
      classes={{ root: classes.stepper }}
    >
      {steps.map((curElem) => (
        <Step key={curElem.id}>
          <StepLabel
            classes={{
              root: classes.stepLabel,
            }}
          >
            {curElem.title}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
    // </Box>
  );
};

export default StepperComponent;
