import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    searchBar: {
        marginTop: ".3rem",
        borderRadius: "5px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: ".5rem",
        minWidth: "22rem",
        border: "1px solid #000000",
        filter: "drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.15))",

    },
    searchInput: {

        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "12px",
        lineHeight: " 18px",
        color: "rgba(0, 0, 0, 0.50)",
    }

}));
