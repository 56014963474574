import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Box,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Avatar from "@material-ui/core/Avatar";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { profileItemsAtom, EmployeeInformation } from "../../../recoil/atoms";
import { useStyles } from "./styles";
import { getDesignation } from "../../../apis/designations_departments";
import SelectViewValueFinder from "../../../utils/SelectViewValueFinder";
import {
  getBaiscDetails,
  getEmployeeInformation,
  getLeaders,
} from "../../../apis/employees";
import SnackbarComponent from "../../../components/SnackbarComponent";
import { employeeDataReadPermission } from "../../../utils/functions";
import { usePermissionObjectFor } from "../../../hooks/permission/usePermissionObjectFor";

interface Props {
  employeeId: any;
}

const sxStyles = {
  headerLeftStyle: {
    pr: 2,
  },
};

const UserDesignation = () => {
  const classes = useStyles();
  const history = useHistory();

  const params: { id: string } = useParams();
  const employeeId = params.id;

  const [designationSelectOptions, setDesignationSelectOptions] =
    useState<object[]>();
  const empInfo = useRecoilValue(EmployeeInformation);
  const [leaderSelectOptions, setLeaderSelectOptions] = useState<object[]>();

  //state for snackbar
  const [snackbarState, setSnackbarState] = useState({
    openBooleanProp: false,
    messageProp: "dummy message state",
    statusProp: "info",
  });

  useEffect(()=>{
    getAllDesignation();
    getAllLeaders();
  },[]);

  useEffect(() => {
    setDetailsData();
  }, [designationSelectOptions]);

  const getAllDesignation = () => {
    getDesignation()
      .then((response) => {
        if (response?.status === 200) {
          const res = response?.data;
          // 
          let fetchedDesignations: object[] = [];
          res.map((key: any, index: any) => {
            fetchedDesignations.push({
              value: key?._id,
              label: key?.designationName,
            });
          });

          setDesignationSelectOptions(fetchedDesignations);
        }
      })
      .catch((err) => {
        setSnackbarState({
          openBooleanProp: true,
          messageProp: "Error : " + err,
          statusProp: "error",
        });
      });
  };

  const getAllLeaders = async () => {
    try {
      let response = await getLeaders();
      setLeaderSelectOptions(response?.data);
    } catch (err: any) {
      setSnackbarState({
        openBooleanProp: true,
        messageProp: "Error : " + err,
        statusProp: "error",
      });      
    }
  };

  //recoil state
  const [empProfileIconData, setEmpProfileIconData] =
    useRecoilState(profileItemsAtom);

  const setDetailsData = () => {
    let fullName = "";
    let srcImage = "";
    let designation = "";

    (async () => {
      //basic details data fetch
      try {
        let basicDetails = await getBaiscDetails(employeeId);
        fullName = basicDetails?.data?.fullName;
        srcImage = basicDetails?.data?.avatar?.url;
      } catch (err: any) {
        //show toast
        setSnackbarState({
          openBooleanProp: true,
          messageProp: "Error : " + err,
          statusProp: "error",
        });
      }

      //employee information data fetch
      try {
        let empInfo = await getEmployeeInformation(employeeId);
        designation = empInfo?.data?.designation;
      } catch (err: any) {
        //show toast
        setSnackbarState({
          openBooleanProp: true,
          messageProp: "Error : " + err,
          statusProp: "error",
        });
      }

      setEmpProfileIconData({
        name: fullName ? fullName : "",
        src: srcImage ? srcImage : "",
        designation: SelectViewValueFinder(
          designationSelectOptions,
          designation
        ).toString(),
        assignedTo: "",
        projectAssigned: "",
      });
    })();
  };

  const SelectValueFinder = (dataProps: any, keyNameProp: string) => {
    let res = "";
    if (dataProps) {
      dataProps.find((item: any) => {
        if (keyNameProp === item.id) {
          res = item.leadName;
        }
      });
    }
    return res;
  };

  const assignedTo = SelectValueFinder(
    leaderSelectOptions,
    empInfo.assignedTo
  ).toString();

  
  return (
    <Grid container xs={12}>
      <SnackbarComponent snackbarStateProp={snackbarState} />
      {employeeDataReadPermission() && (
        <>
          <Grid
            container
            item
            xs={10}
            alignItems="center"
           >
            <Grid item >
              <IconButton
                aria-label="back-button"
                onClick={() => history.push("/app/employees")}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Grid>
            <Grid item className={classes.avatar}>
              <Avatar alt="avatar pic" src={empProfileIconData.src} />
            </Grid>
            {empProfileIconData.name === "" && empProfileIconData.designation === "" ? (
              <CircularProgress />
            ) : (
              <Grid item xs={7}>
                <Grid container direction="column">
                  <Grid item>
                    <Typography variant="h6" className={classes.nameHeaderStye}>
                      {empProfileIconData.name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.lightTypographyStyle}>
                      {empProfileIconData.designation}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
         <Grid container item xs={2}>
              <Grid item direction="column" xs={12} style={{marginTop:".3em"}}>
                <Typography style={{ fontWeight: "500" }}>
                  Assigned to:
                </Typography>
                <Typography className={classes.innerTextValue}>
                  {assignedTo ?? "N/A"}
                </Typography>
              </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default UserDesignation;
