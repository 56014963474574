import React, { useEffect, useState } from "react";
import { Grid, Paper, Box, Typography, Button } from "@material-ui/core";
import { useStyles } from "./styles";
import { Formik, FormikHelpers, FormikProps, Form, Field } from "formik";
import * as yup from "yup";
import { FormTextField } from "../FormtextField";
import FormValues from "../../models/educationalQualification";
import { useLocation } from "react-router-dom";
import KeyValueWrapper from "../KeyValueWrapper";
import ValidationSchema from "./ValidationSchema";
import SwitchField from "../SwitchField";
import SnackbarComponent from "../SnackbarComponent";
import CircularProgress from "@material-ui/core/CircularProgress";
import { updateEducationalQualification } from "../../apis/employees";
import { onboardingIdAtom } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";
import { employeeNormalDataUpdatePermission } from "../../utils/functions";
import { usePermissionObjectFor } from "../../hooks/permission/usePermissionObjectFor";

const validationSchema = yup
  .object()
  .shape(ValidationSchema.EducationalQualificationValidationSchema);

//eductaional dummy data
const userDataProps = {
  //class 10
  c10instituion: "DPS",
  c10Year: "02/12/2012",
  c10Percentage: 93,
  c10Sub: ["English", "Maths"].join(","),

  //class 12
  c12instituion: "NPS",
  c12Year: "01/11/2014",
  c12Percentage: 96,
  c12Sub: ["English", "Maths"].join(","),

  //Graduation
  isGraduate: false,
  graduationinstituion: "",
  graduationYear: "",
  graduationPercentage: 0,
  graduationSub: "",

  //Post Graduation
  isPostGraduate: false,
  pginstituion: "",
  pgYear: "",
  pgPercentage: 0,
  pgSub: "",

  //Diploma
  doneDiploma: false,
  diplomainstituion: "",
  diplomaYear: "",
  diplomaPercentage: 0,
  diplomaSub: "",
};

interface Props {
  employeeId: string;
  educationalQualificationInitialData: any;
  headerProps?: any;
  loaderStateProps?: boolean;
  calledFromEmployee: boolean;
}

const EducationalQualificationEdit: React.FC<Props> = ({
  employeeId,
  educationalQualificationInitialData,
  headerProps = {
    Authorization: "Bearer ",
  },
  loaderStateProps = true,
  calledFromEmployee,
}) => {
  const classes = useStyles();
  const [isEditableBasicDetails, setIsEditableBasicDetailsEdit] =
    useState(false);
  const employeeEndpoint = `/employees/${employeeId}/education-qualification`;
  const onboardingEndPoint = `/on-boarding/education-details/${employeeId}`;

  //Main state that will be used
  const [educationalQualificationData, setEducationalQualificationData] =
    useState(userDataProps);

  //state for snackbar
  const [snackbarState, setSnackbarState] = useState({
    openBooleanProp: false,
    messageProp: "dummy message state",
    statusProp: "info",
  });

  const permission = usePermissionObjectFor("employees");
  const employeeNormalDataUpdatePermission =permission.required([ "upDate","read"]);

  const setInitialData = () => {
    //create data according to frontend model
    const initData = {
      //check isGraduate
      //class 10
      c10instituion: educationalQualificationInitialData?.class10?.institute
        ? educationalQualificationInitialData?.class10.institute
        : "",
      c10Year: educationalQualificationInitialData?.class10?.yearOfPassing
        ? educationalQualificationInitialData?.class10.yearOfPassing
        : "",
      c10Percentage: educationalQualificationInitialData?.class10?.percentage
        ? educationalQualificationInitialData?.class10.percentage
        : 0,
      c10Sub: educationalQualificationInitialData?.class10?.subjects //convert array to a single string comma separated
        ? educationalQualificationInitialData?.class10?.subjects?.join(",")
        : "",

      //class 12
      c12instituion: educationalQualificationInitialData?.class12?.institute
        ? educationalQualificationInitialData?.class12.institute
        : "",
      c12Year: educationalQualificationInitialData?.class12?.yearOfPassing
        ? educationalQualificationInitialData?.class12.yearOfPassing
        : "",
      c12Percentage: educationalQualificationInitialData?.class12?.percentage
        ? educationalQualificationInitialData?.class12.percentage
        : 0,
      c12Sub: educationalQualificationInitialData?.class12?.subjects
        ? educationalQualificationInitialData?.class12.subjects.join(",")
        : "",

      //Graduation
      isGraduate:
        educationalQualificationInitialData?.graduation === undefined
          ? false
          : educationalQualificationInitialData?.graduation?.institute ===
            ""
          ? false
          : true,
      graduationinstituion: educationalQualificationInitialData?.graduation
        ?.institute
        ? educationalQualificationInitialData?.graduation.institute
        : "",
      graduationYear: educationalQualificationInitialData?.graduation
        ?.yearOfPassing
        ? educationalQualificationInitialData?.graduation.yearOfPassing
        : "",
      graduationPercentage: educationalQualificationInitialData?.graduation
        ?.percentage
        ? educationalQualificationInitialData?.graduation.percentage
        : 0,
      graduationSub: educationalQualificationInitialData?.graduation?.subjects
        ? educationalQualificationInitialData?.graduation.subjects.join(",")
        : "",

      //Post Graduation
      isPostGraduate:
        educationalQualificationInitialData?.postGraduation === undefined
          ? false
          : educationalQualificationInitialData?.postGraduation?.institute ===
            ""
          ? false
          : true,
      pginstituion: educationalQualificationInitialData?.postGraduation
        ?.institute
        ? educationalQualificationInitialData?.postGraduation?.institute
        : "",
      pgYear: educationalQualificationInitialData?.postGraduation?.yearOfPassing
        ? educationalQualificationInitialData?.postGraduation.yearOfPassing
        : "",
      pgPercentage: educationalQualificationInitialData?.postGraduation
        ?.percentage
        ? educationalQualificationInitialData?.postGraduation?.percentage
        : 0,
      pgSub: educationalQualificationInitialData?.postGraduation?.subjects
        ? educationalQualificationInitialData?.postGraduation?.subjects.join(
            ","
          )
        : "",

      //Diploma
      doneDiploma:
        educationalQualificationInitialData?.diploma === undefined
          ? false
          : educationalQualificationInitialData?.diploma?.institute ===
            ""
          ? false
          : true,
      diplomainstituion: educationalQualificationInitialData?.diploma?.institute
        ? educationalQualificationInitialData?.diploma?.institute
        : "",
      diplomaYear: educationalQualificationInitialData?.diploma?.yearOfPassing
        ? educationalQualificationInitialData?.diploma?.yearOfPassing
        : "",
      diplomaPercentage: educationalQualificationInitialData?.diploma
        ?.percentage
        ? educationalQualificationInitialData?.diploma?.percentage
        : 0,
      diplomaSub: educationalQualificationInitialData?.diploma?.subjects
        ? educationalQualificationInitialData?.diploma?.subjects.join(",")
        : "",
    };

    //after checking set this in the data state
    setEducationalQualificationData(initData);
  };

  useEffect(() => {
    setInitialData();
  }, [educationalQualificationInitialData]);

  //after submitting form , format the data and patch it in database
  const updateFormdata = async (data: FormValues) => {
    //format the data as in the backend
    let dataToUpdate: any = {
      class10: {
        institute: data.c10instituion,
        yearOfPassing: data.c10Year.toString(),
        percentage: data.c10Percentage,
        subjects: data.c10Sub.split(","), //convert string to array (done)
      },
      class12: {
        institute: data.c12instituion,
        yearOfPassing: data.c12Year,
        percentage: data.c12Percentage,
        subjects: data.c12Sub.split(","), //convert string to array (done)
      },
    };

    //if graduate add the data
    if (data.isGraduate) {
      let addData = {
        institute: data.graduationinstituion,
        yearOfPassing: data.graduationYear.toString(),
        percentage: data.graduationPercentage,
        subjects: data.graduationSub.split(","), //convert string to array (TODO)
      };

      dataToUpdate.graduation = addData;
    }

    //if postGraduate add the data
    if (data.isPostGraduate) {
      let addData = {
        institute: data.pginstituion,
        yearOfPassing: data.pgYear.toString(),
        percentage: data.pgPercentage,
        subjects: data.pgSub.split(","), //convert string to array (TODO)
      };

      dataToUpdate.postGraduation = addData;
    }

    //if postGraduate add the data
    if (data.doneDiploma) {
      let addData = {
        institute: data.diplomainstituion,
        yearOfPassing: data.diplomaYear.toString(),
        percentage: data.diplomaPercentage,
        subjects: data.diplomaSub.split(","), //convert string to array (TODO)
      };

      dataToUpdate.diploma = addData;
    }

    // 
    try {
      await updateEducationalQualification(
        calledFromEmployee ? employeeEndpoint : onboardingEndPoint,
        dataToUpdate
      );
      setEducationalQualificationData(data);
      setIsEditableBasicDetailsEdit((previous) => !previous);
      //show toast
      setSnackbarState({
        openBooleanProp: true,
        messageProp: "successfully updated",
        statusProp: "success",
      });
    } catch (err: any) {
      //show toast
      setSnackbarState({
        openBooleanProp: true,
        messageProp: "Error : " + err,
        statusProp: "error",
      });
    }
  };

  const handleSubmit = (val: any) => {
    if (isEditableBasicDetails) {
      // 
      updateFormdata(val);
    }
  };

  return (
    <Box className={classes.basicDetailsViewMainContainer}>
      <SnackbarComponent snackbarStateProp={snackbarState} />
      {!loaderStateProps ? (
        <Box
          sx={{
            width: "100%",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Formik
          initialValues={educationalQualificationData} //give the state as input always [TODO]
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={(values, formikHelpers) => {
            formikHelpers.setSubmitting(false);
            handleSubmit(values);
          }}
        >
          {(formikProps: FormikProps<FormValues>) => (
            <Form noValidate autoComplete="off">
              <Grid container>
                <Box sx={{ width: "100%" }}>
                  <Grid
                    container
                    xs={12}
                    className={classes.widthHunderWIthInlinePadding}
                  >
                    <Grid item container>
                      <Grid item>
                        <SwitchField
                          name={"doneDiploma"}
                          label={"Is Diploma"}
                          isEditableProps={isEditableBasicDetails}
                          colorNotCheckedBall={"red"}
                          colorCheckedBall={"#14213D"}
                          colorCheckedBg={"blue"}
                          formikProps={formikProps}
                        />
                      </Grid>
                      <Grid item>
                        <SwitchField
                          name={"isGraduate"}
                          label={"Is Graduate"}
                          isEditableProps={isEditableBasicDetails}
                          colorNotCheckedBall={"red"}
                          colorCheckedBall={"#14213D"}
                          colorCheckedBg={"blue"}
                          formikProps={formikProps}
                          dependentFields={[
                            {
                              dependentFieldName: "isPostGraduate",
                              conditions: [
                                {
                                  currentFieldConditionValue: false,
                                  dependentFieldValue: false,
                                },
                              ],
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item>
                        <SwitchField
                          name={"isPostGraduate"}
                          label={"Is PostGraduate"}
                          isEditableProps={isEditableBasicDetails}
                          colorNotCheckedBall={"red"}
                          colorCheckedBall={"#14213D"}
                          colorCheckedBg={"blue"}
                        />
                      </Grid>
                      <Grid item>
                        <div
                          style={{
                            fontSize: ".8em",
                            color: "grey",
                            marginTop: ".7em",
                          }}
                        >
                          subjects are to be separated by comma
                        </div>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      item
                      xs={12}
                      className={classes.roundedCornerContainer}
                    >
                      <div className={classes.smallTypoTopLeft}>
                        <Typography>Class 10</Typography>
                      </div>
                      <Grid
                        container
                        item
                        xs={12}
                        className={classes.horizontalBoxContainerStyle}
                      >
                        <KeyValueWrapper
                          label={"Institution/University"}
                          valueParam={formikProps.values.c10instituion}
                          name={"c10instituion"}
                          inputType={"textField"}
                          isEditable={isEditableBasicDetails}
                          xsProps={4}
                        />

                        <KeyValueWrapper
                          label={"Year"}
                          valueParam={formikProps.values.c10Year}
                          name={"c10Year"}
                          inputType={"datePicker"}
                          momentDateFormat={"YYYY"}
                          isEditable={isEditableBasicDetails}
                          xsProps={2}
                        />

                        <KeyValueWrapper
                          valueTypeProps={"number"}
                          label={"Percentage"}
                          valueParam={formikProps.values.c10Percentage}
                          name={"c10Percentage"}
                          inputType={"textField"}
                          endTextValue={" %"}
                          isEditable={isEditableBasicDetails}
                          xsProps={2}
                        />

                        <KeyValueWrapper
                          label={"Subjects"}
                          valueParam={formikProps.values.c10Sub}
                          name={"c10Sub"}
                          inputType={"textField"}
                          isEditable={isEditableBasicDetails}
                          xsProps={4}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      className={classes.roundedCornerContainer}
                    >
                      <div className={classes.smallTypoTopLeft}>
                        <Typography>Class 12</Typography>
                      </div>
                      <Grid
                        container
                        item
                        xs={12}
                        className={classes.horizontalBoxContainerStyle}
                      >
                        <KeyValueWrapper
                          label={"Institution/University"}
                          valueParam={formikProps.values.c12instituion}
                          name={"c12instituion"}
                          inputType={"textField"}
                          isEditable={isEditableBasicDetails}
                          xsProps={4}
                        />

                        <KeyValueWrapper
                          label={"Year"}
                          valueParam={formikProps.values.c12Year}
                          name={"c12Year"}
                          inputType={"datePicker"}
                          momentDateFormat={"YYYY"}
                          isEditable={isEditableBasicDetails}
                          xsProps={2}
                        />

                        <KeyValueWrapper
                          valueTypeProps={"number"}
                          label={"Percentage"}
                          valueParam={formikProps.values.c12Percentage}
                          name={"c12Percentage"}
                          inputType={"textField"}
                          endTextValue={" %"}
                          isEditable={isEditableBasicDetails}
                          xsProps={2}
                        />

                        <KeyValueWrapper
                          label={"Subjects"}
                          valueParam={formikProps.values.c12Sub}
                          name={"c12Sub"}
                          inputType={"textField"}
                          isEditable={isEditableBasicDetails}
                          xsProps={4}
                        />
                      </Grid>
                    </Grid>
                    {formikProps.values.doneDiploma ? (
                      <Grid
                        container
                        item
                        xs={12}
                        className={classes.roundedCornerContainer}
                      >
                        <div className={classes.smallTypoTopLeft}>
                          <Typography>Diploma</Typography>
                        </div>
                        <Grid
                          container
                          item
                          xs={12}
                          className={classes.horizontalBoxContainerStyle}
                        >
                          <KeyValueWrapper
                            label={"Institution/University"}
                            valueParam={formikProps.values.diplomainstituion}
                            name={"diplomainstituion"}
                            inputType={"textField"}
                            isEditable={isEditableBasicDetails}
                            xsProps={4}
                          />

                          <KeyValueWrapper
                            label={"Year"}
                            valueParam={formikProps.values.diplomaYear}
                            name={"diplomaYear"}
                            inputType={"datePicker"}
                            momentDateFormat={"YYYY"}
                            isEditable={isEditableBasicDetails}
                            xsProps={2}
                          />

                          <KeyValueWrapper
                            valueTypeProps={"number"}
                            label={"Percentage"}
                            valueParam={formikProps.values.diplomaPercentage}
                            name={"diplomaPercentage"}
                            inputType={"textField"}
                            endTextValue={" %"}
                            isEditable={isEditableBasicDetails}
                            xsProps={2}
                          />

                          <KeyValueWrapper
                            label={"Subjects"}
                            valueParam={formikProps.values.diplomaSub}
                            name={"diplomaSub"}
                            inputType={"textField"}
                            isEditable={isEditableBasicDetails}
                            xsProps={4}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <></>
                    )}
                    {formikProps.values.isGraduate ? (
                      <Grid
                        container
                        item
                        xs={12}
                        className={classes.roundedCornerContainer}
                      >
                        <div className={classes.smallTypoTopLeft}>
                          <Typography>Graduation</Typography>
                        </div>
                        <Grid
                          container
                          item
                          xs={12}
                          className={classes.horizontalBoxContainerStyle}
                        >
                          <KeyValueWrapper
                            label={"Institution/University"}
                            valueParam={formikProps.values.graduationinstituion}
                            name={"graduationinstituion"}
                            inputType={"textField"}
                            isEditable={isEditableBasicDetails}
                            xsProps={4}
                          />

                          <KeyValueWrapper
                            label={"Year"}
                            valueParam={formikProps.values.graduationYear}
                            name={"graduationYear"}
                            inputType={"datePicker"}
                            momentDateFormat={"YYYY"}
                            isEditable={isEditableBasicDetails}
                            xsProps={2}
                          />

                          <KeyValueWrapper
                            valueTypeProps={"number"}
                            label={"Percentage"}
                            valueParam={formikProps.values.graduationPercentage}
                            name={"graduationPercentage"}
                            inputType={"textField"}
                            endTextValue={" %"}
                            isEditable={isEditableBasicDetails}
                            xsProps={2}
                          />

                          <KeyValueWrapper
                            label={"Subjects"}
                            valueParam={formikProps.values.graduationSub}
                            name={"graduationSub"}
                            inputType={"textField"}
                            isEditable={isEditableBasicDetails}
                            xsProps={4}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <></>
                    )}
                    {formikProps.values.isPostGraduate &&
                    formikProps.values.isGraduate ? (
                      <Grid
                        container
                        item
                        xs={12}
                        className={classes.roundedCornerContainer}
                      >
                        <div className={classes.smallTypoTopLeft}>
                          <Typography>Post Graduation</Typography>
                        </div>
                        <Grid
                          container
                          item
                          xs={12}
                          className={classes.horizontalBoxContainerStyle}
                        >
                          <KeyValueWrapper
                            label={"Institution/University"}
                            valueParam={formikProps.values.pginstituion}
                            name={"pginstituion"}
                            inputType={"textField"}
                            isEditable={isEditableBasicDetails}
                            xsProps={4}
                          />

                          <KeyValueWrapper
                            label={"Year"}
                            valueParam={formikProps.values.pgYear}
                            name={"pgYear"}
                            inputType={"datePicker"}
                            momentDateFormat={"YYYY"}
                            isEditable={isEditableBasicDetails}
                            xsProps={2}
                          />

                          <KeyValueWrapper
                            valueTypeProps={"number"}
                            label={"Percentage"}
                            valueParam={formikProps.values.pgPercentage}
                            name={"pgPercentage"}
                            inputType={"textField"}
                            endTextValue={" %"}
                            isEditable={isEditableBasicDetails}
                            xsProps={2}
                          />

                          <KeyValueWrapper
                            label={"Subjects"}
                            valueParam={formikProps.values.pgSub}
                            name={"pgSub"}
                            inputType={"textField"}
                            isEditable={isEditableBasicDetails}
                            xsProps={4}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Box>
                {employeeNormalDataUpdatePermission && (
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    sx={{ width: "100%" }}
                  >
                    {isEditableBasicDetails ? (
                      <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        // onClick={() => setIsEditableBasicDetailsEdit(false)}
                        disabled={formikProps.isSubmitting}
                      >
                        Save
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          // 
                          setIsEditableBasicDetailsEdit(true);
                        }}
                      >
                        Edit
                      </Button>
                    )}
                  </Box>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};

export default EducationalQualificationEdit;
