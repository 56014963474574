import { Grid, Typography } from "@material-ui/core";
import classNames from "classnames";
import React, { useState, memo, useEffect } from "react";
import Logo from "../../../assets/images/tvLogoWhite.svg";
import dashboardIcon from "../../../assets/icons/dashboard.svg";
import performanceIcon from "../../../assets/icons/performance.svg";
import performanceActiveIcon from "../../../assets/icons/performanceActive.svg";
import dashboardActiveIcon from "../../../assets/icons/dashboardActive.svg";
import leavesIcon from "../../../assets/icons/leaves.svg";
import leavesActiveIcon from "../../../assets/icons/leavesActive.svg";
import profileIcon from "../../../assets/icons/profiles.svg";
import profileActiveIcon from "../../../assets/icons/profileActive.svg";
import eventsIcon from "../../../assets/icons/events.svg";
import eventsActiveIcon from "../../../assets/icons/eventsActive.svg";
import attendanceIcon from "../../../assets/icons/attendance.svg";
import attendanceActiveIcon from "../../../assets/icons/attendanceActive.svg";
import logoutIcon from "../../../assets/icons/logout.svg";
import logoutActiveIcon from "../../../assets/icons/logoutActive.svg";
import policiesIcon from "../../../assets/icons/policies.svg";
import kHubActiveIcon from "../../../assets/icons/kHubActive.svg";
import kHubIcon from "../../../assets/icons/kHub.svg";
import rolesIcon from "../../../assets/icons/roles.svg";
import rolesActiveIcon from "../../../assets/icons/rolesActive.svg";
import { getPersistentAuthentication } from "../../../utils/functions";
import policiesActiveIcon from "../../../assets/icons/policiesActive.svg";
import employeesIcon from "../../../assets/icons/employees.svg";
import employeesActiveIcon from "../../../assets/icons/employeesActive.svg";
import onboardingIcon from "../../../assets/icons/onboardingIcon.svg";
import onboardingActiveIcon from "../../../assets/icons/onboardingActiveIcon.svg";
import { useBasicNav } from "../../../utils/useBasicNav";
import { useStyles } from "./Styles";
import { withRouter } from "react-router-dom";
import { permissions } from "../../../recoil/atoms";
import { useRecoilState } from "recoil";
import { getRoleData } from "../../../apis/login";
import { NavLink } from "react-router-dom";
import leaveOptions from "../../../assets/images/leaveOptions.png";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { useHistory } from "react-router-dom";
import contentBasket from "../../../assets/images/contentBasket.svg";
import contentBacketInActive from "../../../assets/icons/contentBacketInActive.svg";
import projects from "../../../assets/icons/projects.svg";
import projectInActive from "../../../assets/images/projectInActive.svg";
import kraActiveIcon from "../../../assets/icons/kra-active.svg";
import kraInActiveIcon from "../../../assets/icons/kra-inactive.svg";

const navConfig = [
  {
    text: "DASHBOARD",
    icon: dashboardIcon,
    navTag: "dashboard",
    activeIcon: dashboardActiveIcon,
  },
  // {
  //   text: "ATTENDANCE",
  //   icon: attendanceIcon,
  //   navTag: "attendance",
  //   activeIcon: attendanceActiveIcon,
  //   submenu: [
  //     {
  //       text: "Dashboard",
  //       icon: attendanceIcon,
  //       navTag: "attendance",
  //       activeIcon: attendanceActiveIcon,
  //     },
  //     {
  //       text: "Attendance",
  //       icon: attendanceIcon,
  //       navTag: "attendance",
  //       activeIcon: attendanceActiveIcon,
  //     },
  //   ],
  // },
  {
    text: "ATTENDANCE",
    icon: attendanceIcon,
    navTag: "attendance",
    activeIcon: attendanceActiveIcon,
  },
  {
    text: "EMPLOYEES",
    icon: employeesIcon,
    navTag: "employees",
    activeIcon: employeesActiveIcon,
  },
  {
    text: "LEAVES",
    icon: leavesIcon,
    navTag: "leaves",
    activeIcon: leavesActiveIcon,
    submenu: [
      {
        text: "Apply Leave",
        icon: leavesIcon,
        navTag: "leaves",
        activeIcon: employeesActiveIcon,
      },
      {
        text: "Report",
        icon: leavesIcon,
        navTag: "leaves",
        activeIcon: employeesActiveIcon,
      },
    ],
  },

  {
    text: "ONBOARDING",
    icon: onboardingIcon,
    navTag: "onboarding",
    activeIcon: onboardingActiveIcon,
  },
  {
    text: "CONTENT BASKET",
    icon: contentBacketInActive,
    navTag: "contentBasket",
    activeIcon: contentBasket,
  },
  {
    text: "PROJECTS",
    icon: projectInActive,
    navTag: "projects",
    activeIcon: projects,
  },

  {
    text: "EVENTS",
    icon: eventsIcon,
    navTag: "events",
    activeIcon: eventsActiveIcon,
  },
  {
    text: "PROFILE",
    icon: profileIcon,
    navTag: "profile",
    activeIcon: profileActiveIcon,
  },
  {
    text: "POLICIES",
    icon: policiesIcon,
    navTag: "policies",
    activeIcon: policiesActiveIcon,
  },
  {
    text: "KNOWLEDGE HUB",
    icon: kHubIcon,
    navTag: "knowledgeHub",
    activeIcon: kHubActiveIcon,
  },
  {
    text: "KRA",
    icon: kraInActiveIcon,
    navTag: "kra",
    activeIcon: kraActiveIcon,
  },
  {
    text: "ROLES",
    icon: rolesIcon,
    navTag: "roles",
    activeIcon: rolesActiveIcon,
  },
  // {
  //   text: "PERFORMANCE",
  //   icon: performanceIcon,
  //   navTag: "performance",
  //   activeIcon: performanceActiveIcon,
  // },
  {
    text: "LOGOUT",
    icon: logoutIcon,
    navTag: "logout",
    activeIcon: logoutActiveIcon,
  },
];

interface Props {}

interface NavConfig {
  text: string;
  icon: string;
  activeIcon: string;
  navTag: string;
  submenu?: object[];
}

const SideNav: React.FC<Props> = () => {
  const classes = useStyles();
  let history = useHistory();
  const { activeTab, setActiveTab } = useBasicNav("dashboard");
  const [permission, setPermission] = useRecoilState<any>(permissions);
  const isSuperAdmin = ["superadmin", "Admin"].includes(permission?.roleName);
  const [leaveActive, setleaveActive] = useState(false);
  const [attendanceActive, setAttendanceActive] = useState(false);
  const allowOnProjects = [
    "superadmin",
    "Admin",
    "Human Resource",
    "Project Manager",
  ].includes(permission?.roleName);
  const isHumanResource = ["Human Resource"].includes(permission?.roleName);

  const sessionStorageRole = window.sessionStorage.getItem(
    "userRole"
  ) as string;
  const isTempUser = sessionStorageRole === "temp-user";
  const allowOnboarding = ["superadmin", "Admin", "Human Resource"].includes(
    permission?.roleName
  );

  useEffect(() => {
    if (history.location.pathname === "/app/leaves/leavesReport") {
      setActiveTab("leaves", false);
    }
  }, [history.location]);

  useEffect(() => {
    if (activeTab === 3) {
      setleaveActive(true);
    }
    // else if (activeTab === 1) {
    //   setAttendanceActive(true);
    // }
  }, [activeTab, attendanceActive]);

  const openLeaveSubMenuHandler = () => {
    setleaveActive(!leaveActive);
  };

  // const openAttendanceSubMenu = () => {
  //   setAttendanceActive(!attendanceActive);
  // };

  useEffect(() => {
    let payload = {
      token: getPersistentAuthentication()?.token,
    };
    getRoleData(payload).then((response: any) => {
      setPermission({
        ...permission,
        attendance: response?.permissions?.attendance,
        department: response?.permissions?.department,
        designation: response?.permissions?.designation,
        leave: response?.permissions?.leave,
        policies: response?.permissions?.policies,
        role: response?.permissions?.role,
        user: response?.permissions?.user,
        _id: response?.permissions?._id,
        roleName: response?.roleName,
      });
      window.localStorage.setItem(
        "userRole",
        JSON.stringify(response?.permissions?.role)
      );
      // new key : userRoleName
      sessionStorage.setItem(
        "userRoleName",
        JSON.stringify(response?.roleName)
      );
      const ls = sessionStorage.getItem("userRoleName");
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const subNavActive = (navName: string) => {
    let navCurr = history.location.pathname.split("/");
    let navCurrName = navCurr[navCurr.length - 1];
    if (navName === navCurrName) return true;
    else return false;
  };

  const handleClickLogout = () => {
    history.replace("/app/signinotp");
    window.sessionStorage.setItem("access_token", "");
    window.sessionStorage.setItem("userRole", "");
  };

  const ActiveTabHandler = (item: NavConfig) => {
    // setActiveTab(item.navTag);
    if (item.navTag === "attendance") {
      setActiveTab(item.navTag);
      setAttendanceActive(!attendanceActive);
    } else if (item.navTag === "leaves") {
      setActiveTab(item.navTag);
      setleaveActive(!leaveActive);
      // setActiveTab(item.navTag);
    } else {
      setActiveTab(item.navTag);
    }
  };

  const showSubMenu = (navTag: string) => {
    // setleaveActive(!leaveActive);
    if (navTag == "attendance") {
      setAttendanceActive(!attendanceActive);
    } else setleaveActive(!leaveActive);
    // console.log("valOutside",navTag)
  };

  return (
    <div className={classes.sideNav}>
      <div className={classes.imageContainer}>
        <img src={Logo} className={classes.logoClass} alt="logo-icon"></img>
      </div>
      <div className={classes.navItems}>
        {!isTempUser ? (
          navConfig.map((item: NavConfig, index) => (
            <>
              <div
                className={classNames(
                  classes.navContainer,
                  {
                    [classes.navItemsHide]:
                      !isSuperAdmin && item.navTag === "roles",
                  },
                  {
                    [classes.navItemsHide]: item.navTag === "profile",
                  },
                  {
                    [classes.navItemsHide]: item.navTag === "events",
                  },
                  {
                    [classes.navItemsHide]: item.navTag === "performance",
                  },
                  {
                    [classes.navItemsHide]:
                      !allowOnboarding && item.navTag == "onboarding",
                  },
                  {
                    [classes.navItemsHide]:
                      !allowOnProjects && item.navTag == "projects",
                  }
                  // {
                  //   [classes.navItemsHide]:
                  //     !allowOnboarding && item.navTag == "attendancev2",
                  // }
                )}
              >
                <div
                  className={classNames(classes.navItem, {
                    [classes.navActive]: index === activeTab,
                  })}
                  onClick={() => ActiveTabHandler(item)}
                  // style={{border:"1px solid yellow"}}
                >
                  {(isSuperAdmin || isHumanResource) &&
                  item.text === "LEAVES" ? (
                    <div className={classes.leaveroot}>
                      <div className={classes.leave}>
                        <img
                          src={
                            index === activeTab ? item.activeIcon : item.icon
                          }
                          alt={item.text}
                        />
                        <Typography
                          className={classes.leavetext}
                          variant="caption"
                        >
                          LEAVES
                        </Typography>
                      </div>

                      {leaveActive ? (
                        <ArrowDropUpIcon
                          className={
                            index === activeTab
                              ? classes.leaveactive
                              : classes.leavenotactive
                          }
                        />
                      ) : (
                        <ArrowDropDownIcon
                          className={
                            index === activeTab
                              ? classes.leaveactive
                              : classes.leavenotactive
                          }
                        />
                      )}
                    </div>
                  ) : item.text === "ATTENDANCE" ? (
                    <div className={classes.attendancecontainer}>
                      <div className={classes.attendancetab}>
                        <img
                          src={
                            index === activeTab ? item.activeIcon : item.icon
                          }
                          alt={item.text}
                        />
                        <Typography
                          className={classes.attendancetext}
                          variant="caption"
                        >
                          ATTENDANCE
                        </Typography>
                      </div>
                      {/* {attendanceActive ? (
                        <ArrowDropUpIcon
                          className={
                            index === activeTab
                              ? classes.attendanceactive
                              : classes.attendancenotactive
                          }
                        />
                      ) : (
                        <ArrowDropDownIcon
                          className={
                            index === activeTab
                              ? classes.attendanceactive
                              : classes.attendancenotactive
                          }
                        />
                      )} */}
                    </div>
                  ) : (
                    <>
                      <img
                        src={index === activeTab ? item.activeIcon : item.icon}
                        alt={item.text}
                      />
                      <Typography
                        className={classes.text}
                        variant="caption"
                        component="div"
                      >
                        {item.text}
                      </Typography>
                    </>
                  )}
                </div>
                <div
                  className={classNames(classes.noBorder, {
                    [classes.rightBorder]: index === activeTab,
                  })}
                ></div>
              </div>
              {(isSuperAdmin || isHumanResource) &&
                item.text === "LEAVES" &&
                leaveActive && (
                  <div className={classes.dropDownActive}>
                    <div className={classes.dropDownContent}>
                      <img
                        src={leaveOptions}
                        alt=""
                        className={classes.optionsIcon}
                      />
                      <div className={classes.options}>
                        <Typography className={classes.applyLeave}>
                          <NavLink
                            to="/app/leaves"
                            className={
                              subNavActive("leaves")
                                ? classes.activeLink
                                : classes.notActiveLink
                            }
                          >
                            APPLY LEAVE
                          </NavLink>
                        </Typography>
                        <Typography className={classes.report}>
                          <NavLink
                            to="/app/leaves/leavesReport"
                            className={
                              subNavActive("leavesReport")
                                ? classes.activeLink
                                : classes.notActiveLink
                            }
                          >
                            REPORT
                          </NavLink>
                        </Typography>
                      </div>
                    </div>
                  </div>
                )}
              {item.text === "ATTENDANCE" &&
                attendanceActive &&
                item.submenu && (
                  <div className={classes.dropDownActive}>
                    <div className={classes.dropDownContent}>
                      <img
                        src={leaveOptions}
                        alt=""
                        className={classes.optionsIcon}
                      />
                      <div className={classes.options}>
                        <Typography className={classes.applyLeave}>
                          <NavLink
                            to="/app/attendance"
                            className={
                              subNavActive("attendance")
                                ? classes.activeLink
                                : classes.notActiveLink
                            }
                          >
                            DASHBOARD
                          </NavLink>
                        </Typography>
                        <Typography className={classes.report}>
                          <NavLink
                            to="/app/attendance/subAttendance"
                            className={
                              subNavActive("subAttendance")
                                ? classes.activeLink
                                : classes.notActiveLink
                            }
                          >
                            ATTENDANCE
                          </NavLink>
                        </Typography>
                      </div>
                    </div>
                  </div>
                )}

              {/* {item.text === 'ATTENDANCE V2'} */}
            </>
          ))
        ) : (
          <Grid
            container
            direction="row"
            spacing={5}
            justifyContent="flex-start"
            onClick={handleClickLogout}
            className={classes.tempLogoutRoot}
          >
            <Grid item>
              <img src={logoutIcon} />
            </Grid>
            <Grid className={classes.tempLogoutLink}>
              <Typography className={classes.tempLogoutText}>Logout</Typography>
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
};

export default withRouter(memo(SideNav));
