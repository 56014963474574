import React from "react";
import classNames from "classnames";
import { Button } from "@material-ui/core";
import { useStyles } from "./styles";

interface Props {
  label: string;
  isOutlined: boolean;
  isDisabled?: boolean;
  startIcon?: string;
  endIcon?: string;
  onButtonClick?: (event: any) => void;
  isBordered: boolean;
  color: string;
}

const CustomButton: React.FC<Props> = ({
  label,
  isOutlined,
  isDisabled,
  startIcon,
  endIcon,
  onButtonClick,
  isBordered,
  color,
}) => {
  const classes = useStyles();
  const handleClick = (event: any) => {
    if (onButtonClick) onButtonClick(event);
  };

  return (
    <Button
      variant={isOutlined ? "outlined" : "contained"}
      color="primary"
      className={classNames(classes.bordered, {
        [classes.borderedWhite]: isBordered,
        [classes.red]: color === "red",
        [classes.green]: color === "green",
        [classes.orange]: color === "orange",
        [classes.white]: color === "white",
        [classes.whiteNoHover]: color === "whiteNoHover",
      })}
      disabled={isDisabled}
      startIcon={
        startIcon && (
          <img className={classes.image} src={startIcon} alt="<Not Found>" />
        )
      }
      endIcon={
        endIcon && (
          <img className={classes.image} src={endIcon} alt="<Not Found>" />
        )
      }
      onClick={(event) => handleClick(event)}
    >
      {label}
    </Button>
  );
};

export default CustomButton;
