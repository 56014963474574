import axios from "./axios.global";

export const timerControl = async (payload: object) => {
  try {
    return await axios.post(`/attendance`, payload);
  } catch (err: any) {
    return err
  }
};

export const getAllAttendance = async () => {
  try {
    return await axios.get(`/attendance`);
  } catch (err: any) {
    return err
  }
};


export const workingTime = async () => {
  try {
    return await axios.get(`/attendance/working/time`);
  } catch (err: any) {
    return err
  }
};

export const breakTime = async () => {
  try {
    return await axios.get(`/attendance/break/time`);
  } catch (err: any) {
    return err
  }
};

export const aggregateByWeek = async () => {
  try {
    return await axios.get(`/attendance/week`);
  } catch (err: any) {
    return err
  }
};

export const aggregateByMonth = async () => {
  try {
    return await axios.get(`/attendance/month`);
  } catch (err: any) {
    return err
  }
};

export const aggregateByLastMonth = async () => {
  try {
    return await axios.get(`/attendance/lastmonth`);
  } catch (err: any) {
    return err
  }
};

interface IAttendanceHistoryQuery {
  empId: string;
  startDate: string;
  endDate: string;
  page: number
}

export const getAttendanceHistory = async (query: IAttendanceHistoryQuery) => {
  const { empId, startDate, endDate, page } = query;

  try {
    return await axios.get('/attendance/history', { params: { empId, startDate, endDate, page, limit: 8 } });
  } catch (error) {
    console.error(error)

  }
}

export const getEmpDetailsAPI = async (empId: string, startDate: string, endDate: string) => {
  try {
    return await axios.get('/attendance/emp-details', { params: { empId, startDate, endDate } });
  } catch (error) {
    console.error(error)
  }
}

export const uploadAttendanceAPI = async (data: any) => {
  try {
    return await axios.post(`/attendance/upload`, data)
  } catch (error: any) {
    console.error(error.message)
  }
}

export const getWorkdaysAPI = async (month: number, year: number): Promise<{ workdays: number }> => {
  try {
    return (await axios.get('/attendance/workdays', { params: { month, year } })).data
  } catch (error: any) {
    console.error(error.message)
    return { workdays: 0 }
  }
}