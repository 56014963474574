/* eslint-disable no-lone-blocks */
import React, { useState } from "react";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import {
  Theme,
  makeStyles,
  withStyles,
  createStyles,
} from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { env } from "process";
import { Formik, FormikHelpers, FormikProps, Form, Field } from "formik";
import SnackbarComponent from "../SnackbarComponent";

const SmallAvatar = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 31,
      height: 31,
    },
  })
)(Avatar);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    avatarStyle: {
      height: 130,
      width: 130,
    },
  })
);

//interface for props
interface props {
  smallIcon: string;
  avatarIcon: any;
  propFormik: any;
  isEditableProps?: boolean;
  currentProfileImageProps: any;
  setCurrentProfileImageProps: any;
}
const AvatarEdit: React.FC<props> = ({
  smallIcon,
  avatarIcon,
  propFormik,
  isEditableProps = true,
  currentProfileImageProps,
  setCurrentProfileImageProps,
}) => {
  const classes = useStyles();

  //state for snackbar
  const [snackbarState, setSnackbarState] = useState({
    openBooleanProp: false,
    messageProp: "dummy message state",
    statusProp: "info",
  });

  //upload state
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    // 
    const supportedFileType: string[] = [
      "image/jpeg",
      "image/jpg",
      "image/png",
    ];
    if (event.currentTarget) {
      if (event.currentTarget.files !== null) {
        if (event.currentTarget.files[0].size > 1048576) {
          // throw error for exceeding size limit
          setSnackbarState({
            openBooleanProp: true,
            messageProp: "Image should be less than 1 MB!",
            statusProp: "warning",
          });
          return;
        }
        if (!supportedFileType.includes(event.currentTarget.files[0].type)) {
          setSnackbarState({
            openBooleanProp: true,
            messageProp:
              "Image format not supported. Please upload in  - jpeg,png,jpg",
            statusProp: "error",
          });
          return;
        }
        //save the image;
        const fileUpload = { file: event.currentTarget.files[0] };
        propFormik.setFieldValue("profileImage", fileUpload.file);
        //just to display
        setCurrentProfileImageProps(
          URL.createObjectURL(event.currentTarget.files[0])
        );
      }
    }
  };

  return (
    <div className={classes.root}>
      <SnackbarComponent snackbarStateProp={snackbarState} />
      <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        badgeContent={
          <IconButton aria-label="upload picture" component="label">
            {isEditableProps && <SmallAvatar alt="edit icon" src={smallIcon} />}

            <input
              hidden
              accept="image/*"
              type="file"
              disabled={!isEditableProps}
              onChange={(event) => handleFileUpload(event)}
            />
          </IconButton>
        }
      >
        <Avatar
          className={classes.avatarStyle}
          alt="profile photo"
          src={currentProfileImageProps}
        />
      </Badge>
    </div>
  );
};

export default AvatarEdit;
