import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  verifyBtnContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.typography.pxToRem(10),
    marginBottom: theme.typography.pxToRem(10)
  },
  verifyBtn: {
    width: '65%',
    borderRadius: theme.typography.pxToRem(50),
    height: theme.typography.pxToRem(52)
  },
  resendOtp:{
    textDecoration: 'underline',
    cursor:'pointer'
  }
}));

export default useStyles;
