import { min } from "lodash";
import * as yup from "yup";
import { boolean } from "yup/lib/locale";
import { phoneRegex } from "../../utils/constants";

const rePhoneNumber =
  /((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}/;
const reAdhar = /^[2-9]{1}[0-9]{11}$/; //adhar contains 12 digit number which doesnot start with 0 and 1
const rePanCardNo = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
const reVoterIdNo = /^([a-zA-Z]){3}([0-9]){7}?$/;
// const rePassportNo = /^[A-PR-WYa-pr-wy][1-9]\d\s?\d{4}[1-9]$/;
const rePassportNo = /^[A-Za-z][0-9]{7,8}$/;
const rePinCode = /^[1-9]{1}[0-9]{5}$/;
const reIFSC = /^[A-Z]{4}0[A-Z0-9]{6}$/;
const reBankAccountNumber = /^[0-9]{9,18}$/;
const onlyAlphabet = /^[aA-zZ\s]+$/;
export const techvariableEmail = /^[\w-\.]+@techvariable\.com$/;
const onlyNumbers = /^[0-9]$/;

const ValidationSchema: any = {
  EmployeeInformationValidationSchema: {
    empNo: yup.number().required("Required").typeError("A number is required"),
    department: yup.string().required("Required"),
    assignedTo: yup.string().required("Required"),
    empStatus: yup.boolean().required("Required"),
  },
  BankDetailsValidationSchema: {
    bankName: yup
      .string()
      .required("Required")
      .matches(onlyAlphabet, "Only alphabets are allowed for this field "),
    accountNo: yup
      .string()
      .required("Required")
      .matches(reBankAccountNumber, "Account Number not valid"),
    ifscCode: yup
      .string()
      .required("Required")
      .min(11, "Too Short!")
      .max(11, "Too Long!")
      .matches(reIFSC, "IFSC format is not valid"),
  },
  WorkExperienceValidationSchema: {
    workExperience: yup.array().of(
      yup.object().shape({
        organization: yup.string().required("Required"),
        tctc: yup
          .number()
          .required("Required")
          .typeError("A number is required"),
        designation: yup.string().required("Required"),
        startDate: yup
          .date()
          .required("Required")
          .max(yup.ref("endDate"), "Start date can't be after End Date"),
        endDate: yup
          .date()
          .required("Required")
          .min(yup.ref("startDate"), "End date can't be before Start date"),
        reasonForLeaving: yup.string().required("Required"),
        reference: yup.object().shape({
          name: yup.string().required("Required"),
          designation: yup.string().required("Required"),
          contact: yup.string().required("Required").matches(phoneRegex, "Phone number is not valid"),
          relationship: yup.string().required("Required"),
        })
      })
    ),
  },
  EducationalQualificationValidationSchema: {
    //class 10 -  all required
    c10instituion: yup
      .string()
      .required("Required inst")
      .matches(onlyAlphabet, "Only alphabets are allowed for this field "),
    c10Year: yup.string().required("Required"),
    c10Percentage: yup
      .number()
      .required("Required")
      .typeError("A number is required")
      .min(1, "Percentage should be more than 0")
      .max(100, "Percentage should be less than or equal to 100"),
    // c10Sub: yup.string().required("Class 10  Subjects are required in comma separated format."),

    //class 12 -  all required
    c12instituion: yup
      .string()
      .required("Required")
      .matches(onlyAlphabet, "Only alphabets are allowed for this field "),
    c12Year: yup.string().required("Required"),
    c12Percentage: yup
      .number()
      .required("Required")
      .typeError("A number is required")
      .min(1, "Percentage should be more than or equal to 0")
      .max(100, "Percentage should be less than or equal to 100"),
    // c12Sub: yup.string().required("Class 12  Subjects are required in comma separated format."),

    //graduation - optional - not required
    isGraduate: yup.boolean(),
    graduationinstituion: yup
      .string()
      .matches(onlyAlphabet, "Only alphabets are allowed for this field ")
      .when("isGraduate", {
        is: (isGraduate: boolean) => {
          if (isGraduate) {
            return true;
          } return false
        },
        then: yup.string().required("graduation Instituion name is required."),
      }),
    graduationYear: yup.string().when("isGraduate", {
      is: (isGraduate: boolean) => {
        if (isGraduate) {
          return true;
        } return false
      },
      then: yup.string().required("graduation year is required."),
    }),
    graduationPercentage: yup
      .number()
      .typeError("A number is required")
      .min(0, "Percentage should be more than 0")
      .max(100, "Percentage should be less than or equal to 100")
      .when("isGraduate", {
        is: (isGraduate: boolean) => {
          if (isGraduate) {
            return true;
          } return false
        },
        then: yup.number().required("graduation percentage is required."),
      }),

    // graduationSub: yup.string().when("isGraduate", {
    //   is: (isGraduate: boolean) => {
    //     if (isGraduate) {
    //       // 
    //       return true;
    //     } else {
    //       // 
    //       return false;
    //     }
    //   },
    //   then: yup
    //     .string()
    //     .required(
    //       "Graduation Subjects are required in comma separated format."
    //     ),
    // }),

    //Post Graduation - optional - not required
    isPostGraduate: yup.boolean(),
    pginstituion: yup
      .string()
      .matches(onlyAlphabet, "Only alphabets are allowed for this field ")
      .when("isPostGraduate", {
        is: (isPostGraduate: boolean) => {
          if (isPostGraduate) {
            return true;
          } return false
        },
        then: yup
          .string()
          .required("Post Graduation Instituion name is required."),
      }),
    pgYear: yup.string().when("isPostGraduate", {
      is: (isPostGraduate: boolean) => {
        if (isPostGraduate) {
          return true;
        } return false
      },
      then: yup.string().required("Post Graduation year is required."),
    }),
    pgPercentage: yup
      .number()
      .typeError("A number is required")
      .min(0, "Percentage should be more than or equal to 0")
      .max(100, "Percentage should be less than or equal to 100")
      .when("isPostGraduate", {
        is: (isPostGraduate: boolean) => {
          if (isPostGraduate) {
            return true;
          } return false
        },
        then: yup.number().required("Post Graduation percentage is required."),
      }),

    // pgSub: yup.string().when("isPostGraduate", {
    //   is: (isPostGraduate: boolean) => {
    //     if (isPostGraduate) {
    //       // 
    //       return true;
    //     } else {
    //       // 
    //       return false;
    //     }
    //   },
    //   then: yup
    //     .string()
    //     .required(
    //       "Post graduation Subjects are required in comma separated format."
    //     ),
    // }),

    //Post Graduation - optional - not required
    doneDiploma: yup.boolean(),
    diplomainstituion: yup
      .string()
      .matches(onlyAlphabet, "Only alphabets are allowed for this field ")
      .when("doneDiploma", {
        is: (doneDiploma: boolean) => {
          if (doneDiploma) {
            return true;
          } return false
        },
        then: yup.string().required("Diploma Instituion name is required."),
      }),
    diplomaYear: yup.string().when("doneDiploma", {
      is: (doneDiploma: boolean) => {
        if (doneDiploma) {
          return true;
        } return false
      },
      then: yup.string().required("Diploma year is required."),
    }),

    diplomaPercentage: yup
      .number()
      .typeError("A number is required")
      .min(0, "Percentage should be more than or equal to 0")
      .max(100, "Percentage should be less than or equal to 100")
      .when("doneDiploma", {
        is: (doneDiploma: boolean) => {
          if (doneDiploma) {
            return true;
          } return false
        },
        then: yup.number().required("Diploma percentage is required."),
      }),
    // diplomaSub: yup.string().when("doneDiploma", {
    //   is: (doneDiploma: boolean) => {
    //     if (doneDiploma) {
    //       // 
    //       return true;
    //     } else {
    //       // 
    //       return false;
    //     }
    //   },
    //   then: yup
    //     .string()
    //     .required("Diploma Subjects are required in comma separated format."),
    // }),
  },
  FamilyAndlanguageValidationSchema: {
    // //Edge cases - If spouseName is not written  and date is selected by mistake it should be updated as emply string "" (DONE)
    // //spouse - required(spouseOccupation and spouseDOB) when spouse name is not empyty (DONE)

    // hasSpouse: yup.boolean(),
    // spouseName: yup.string().when("hasSpouse", {
    //   is: (hasSpouse: boolean) => {
    //     if (hasSpouse) {
    //       // 
    //       return true;
    //     } else {
    //       // 
    //       return false;
    //     }
    //   },
    //   then: yup.string().required("Spouse Name is required."),
    // }),

    // spouseDependent: yup.string(),
    // spouseOccupation: yup.string().when("hasSpouse", {
    //   is: (hasSpouse: boolean) => {
    //     if (hasSpouse) {
    //       // 
    //       return true;
    //     } else {
    //       // 
    //       return false;
    //     }
    //   },
    //   then: yup
    //     .string()
    //     .matches(onlyAlphabet, "Only alphabets are allowed for this field ")
    //     .required("Spouse Occupation is required."),
    // }),

    // spouseDOB: yup.string().when("hasSpouse", {
    //   is: (hasSpouse: boolean) => {
    //     if (hasSpouse) {
    //       // 
    //       return true;
    //     } else {
    //       // 
    //       return false;
    //     }
    //   },
    //   then: yup.string().required("Spouse DOB is required."),
    // }),



    //father
    fatherName: yup.string().required("Required"),
    fatherDependent: yup.string(),
    fatherOccupation: yup
      .string()
      .matches(onlyAlphabet, "Only alphabets are allowed for this field "),
    fatherDOB: yup.string().required("Required"),
    //mother
    motherName: yup.string().required("Required"),
    motherDependent: yup.string(),
    motherOccupation: yup
      .string()
      .matches(onlyAlphabet, "Only alphabets are allowed for this field "),
    motherDOB: yup.string().required("Required"),

    //first language
    firstLanguageName: yup
      .string()
      .matches(onlyAlphabet, "Only alphabets are allowed for this field ")
      .required("Required"),
    firstLanguageSpeak: yup.boolean().required("Required"),
    firstLanguageRead: yup.boolean().required("Required"),
    firstLanguageWrite: yup.boolean().required("Required"),

    //second language
    secondLanguageName: yup.string().required("Required"),
    secondLanguageSpeak: yup.boolean().required("Required"),
    secondLanguageRead: yup.boolean().required("Required"),
    secondLanguageWrite: yup.boolean().required("Required"),

    //third language
    thirdLanguageName: yup.string().required("Required"),
    thirdLanguageSpeak: yup.boolean().required("Required"),
    thirdLanguageRead: yup.boolean().required("Required"),
    thirdLanguageWrite: yup.boolean().required("Required"),

    //fourth language
    // fourthLanguageName: yup.string().required("Required"),
    // fourthLanguageSpeak: yup.boolean().required("Required"),
    // fourthLanguageRead: yup.boolean().required("Required"),
    // fourthLanguageWrite: yup.boolean().required("Required"),
  },
  BasicDetailsValidationSchema: {
    fullName: yup.string().required("This field is Required"),
    email: yup
      .string()
      .email("Field should contain a valid e-mail")
      .max(255)
      .required("E-mail is required")
      .matches(techvariableEmail, "Required format @techvariable.com"),
    personalEmail: yup
      .string()
      .email("Field should contain a valid e-mail")
      .max(255)
      .required("E-mail is required"),
    contactNo: yup
      .string()
      .required("This field is Required")
      .matches(rePhoneNumber, "Phone number is not valid")
      .min(7, "Too short")
      .max(15, "Too long"),
    dateOfBirth: yup.string().required("Required"),
    gender: yup.string().required("Required"),
    maritalStatus: yup.string().required("Required"),
    bloodGroup: yup.string().required("Required"),
    adharCardNo: yup
      .string()
      .required("Required")
      //   .min(12, "Too short")
      .max(12, "Too long")
      .matches(reAdhar, "Adhar format is not valid"),
    voterIdNo: yup
      .string()
      .required("Required")
      .matches(reVoterIdNo, "Voter Id number is not valid"),
    passportNo: yup
      .string()
      .matches(rePassportNo, "Passport number is not valid"),
    presentCity: yup.string().required("Required"), //incorrect key name
    presentAddress: yup.string().required("Required"),
    presentState: yup
      .string()
      .required("Required")
      .matches(onlyAlphabet, "Only alphabets are allowed for this field "),
    presentPinCode: yup
      .string()
      .required("Required")
      .matches(rePinCode, "Pincode is not valid"),
    isPermanentAddressDifferent: yup.boolean(),
    permanentCity: yup.string().when("isPermanentAddressDifferent", {
      is: (isPermanentAddressDifferent: boolean) => {
        if (isPermanentAddressDifferent) {
          return true;
        } return false
      },
      then: yup.string().required("Permanent City is required."),
    }),
    permanentAddress: yup.string().when("isPermanentAddressDifferent", {
      is: (isPermanentAddressDifferent: boolean) => {
        if (isPermanentAddressDifferent) {
          return true;
        } return false
      },
      then: yup.string().required("Permanent Address is required."),
    }),
    permanentState: yup
      .string()
      .matches(onlyAlphabet, "Only alphabets are allowed for this field ")
      .when("isPermanentAddressDifferent", {
        is: (isPermanentAddressDifferent: boolean) => {
          if (isPermanentAddressDifferent) {
            return true;
          } return false
        },
        then: yup.string().required("Permanent State is required."),
      }),
    permanentPinCode: yup
      .string()

      .matches(rePinCode, "Pincode is not valid")
      .when("isPermanentAddressDifferent", {
        is: (isPermanentAddressDifferent: boolean) => {
          if (isPermanentAddressDifferent) {
            return true;
          } return false
        },
        then: yup.string().required("Permanent Pincode is required."),
      }),
    emergencyContactName: yup.string().required("Required"),
    emergencyContactNo: yup
      .string()
      .required("This field is Required")
      .matches(rePhoneNumber, "Phone number is not valid")
      .min(7, "Too short")
      .max(15, "Too long"),
    emergencyContactRelationship: yup
      .string()
      .required("Required")
      .matches(onlyAlphabet, "Only alphabets are allowed for this field "), ///incorrect key name
    age: yup
      .number()
      .required("Required")
      .typeError("A number is required")
      .min(5, "Too young")
      .max(150, "Not valid age"), //incorrect key name
    panCardNo: yup
      .string()
      .required("Required")
      .matches(rePanCardNo, "Pan number is not valid"),
  },
  AssetDetailsValidationSchema: {
    assetDetails: yup.array().of(
      yup.object().shape({
        assetName: yup.string().required("Required"),
        assetID: yup.string().required("Required"),
      })
    ),
  },
};

export default ValidationSchema;
