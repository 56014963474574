import React from "react";
import { API_URL } from "../../../../../config";
import { useStyles } from "./styles";

interface props {
  img: any;
}

const ImageDiv: React.FC<props> = ({ img }) => {
  const classes = useStyles();

  return (
    <div
      id={`${img.path}`}
      className={classes.image}
      style={
        {
          "--opacity": 0,
        } as React.CSSProperties
      }
      onMouseEnter={(e) => {
        e.currentTarget.style.setProperty("--opacity", "1");
        e.currentTarget.style.setProperty("--text", "'Copy link'");
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.setProperty("--opacity", "0");
      }}
      onClick={(e) => {
        e.currentTarget.style.setProperty("--text", "'Copied'");
      }}
    >
      <img src={`${img.path}`} alt={"uploaded"} />
    </div>
  );
};

export default ImageDiv;
