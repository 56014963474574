import React from "react";
import { Grid, Paper, Box, Typography, Button } from "@material-ui/core";
import { useStyles } from "./style";

type Props = {
  title: string;
  ChildrenContent: React.ReactNode;
};

const RoundedBoxWIthTitle: React.FC<Props> = ({ title, ChildrenContent }) => {
  const classes = useStyles();

  return (
    <Grid container item xs={12} className={classes.roundedCornerContainer}>
      <div className={classes.smallTypoTopLeft}>
        <Typography className={classes.roundedBoxTitle}>{title}</Typography>
      </div>
      <Grid
        container
        item
        xs={12}
        className={classes.horizontalBoxContainerStyle}
      >
        {ChildrenContent}
      </Grid>
    </Grid>
  );
};

export default RoundedBoxWIthTitle;
