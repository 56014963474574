import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Snackbar, { SnackbarOrigin } from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps, Color } from "@material-ui/lab/Alert";
import { string } from "prop-types";

export interface State extends SnackbarOrigin {
  open: boolean;
}

interface snackbarProps {
  openBooleanProp: boolean;
  messageProp: string;
  statusProp: any;
}

interface Props {
  snackbarStateProp: snackbarProps;
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SnackbarComponent: React.FC<Props> = ({ snackbarStateProp }) => {
  const [snackbarState, setSnackbarState] =
    useState<snackbarProps>(snackbarStateProp);
  const [status, setStatus] = useState<any>("info");

  useEffect(() => {
    setSnackbarState({
      openBooleanProp: snackbarStateProp?.openBooleanProp,
      messageProp: snackbarStateProp?.messageProp,
      statusProp: snackbarStateProp?.statusProp,
    });
    setStatus(snackbarStateProp.statusProp);
  }, [snackbarStateProp]);

  const handleClose = () => {
    setSnackbarState({
      openBooleanProp: false,
      messageProp: snackbarState?.messageProp,
      statusProp: snackbarState?.statusProp,
    });
  };

  // setTimeout(() => setState({ ...state, open: false }), 2000);

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbarState?.openBooleanProp}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        {snackbarStateProp && (
          <Alert onClose={handleClose} severity={status}>
            {snackbarState?.messageProp}
          </Alert>
        )}
      </Snackbar>
    </div>
  );
};

export default SnackbarComponent;
