import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  searchText : {
    borderRadius :"10px !important",
    marginBottom:"1em",
    width:"100%"
  },
  head: {
    border: "1px solid rgba(0, 0, 0, 0.15)",
    width: "16%",
    padding: "0px !important",
  },
  body: {
    fontSize: 14,
    padding:"0px !important"
  },
  table: {
    minWidth: "100%",
    borderRadius: "10px !important",
  },
  topTier: {
    display: "flex",
    justifyContent: "space-between",
    padding: " 1.5em 1em",
    background:"#FFFFFF",
    borderRadius:"10px",
    alignItems: "center",
  },
  uploadButn: {
    border: "1px solid #14213D",
    borderRadius: "10px",
    color: "#14213D",
    fontSize: ".9em",
    background: "#EDF6F9",
    padding: ".7em 1em",
    "&:hover": {
      background: "#EDF6F9",
    },
  },
  downloadButn: {
    border: "1px solid #14213D",
    borderRadius: "10px",
    color: "#ffffff",
    fontSize: ".9em",
    background: "#140047",
    padding: ".7em 1em",
    "&:hover": {
      background: "#140047",
    },
  },


  contentDiv : {
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
  insideDiv: {
    borderRight: "1px solid rgba(0, 0, 0, 0.15)",
    textAlign: "center",
    width: "30%",
    padding:"1em 0em"
  },
  contentText : { fontSize: ".9em", fontWeight: 500 },
  contentTextActive :{ textAlign: "center", width: "40%",padding:"1em 0em" },
  headerDiv :{
    margin: "auto",
    display: "block",
    textAlign: "center"
  },
  contentTextHeader : { fontSize: "1.1em", fontWeight: 600, padding: " 0.5em" },
  contentTextHeaderSec : { borderRight: "1px solid rgba(0, 0, 0, 0.15)", textAlign: "center",width:"30%" },
  contentTextHeaderThird :{ fontSize: ".9em", fontWeight: 600, padding: " 0.5em" },
  conteTextHeaderLast : { textAlign: "center",width:"40%", },

  ////===== INDIDUAL PAGE CSS ======////
  mainBox : {
    background: "#ffffff",
    borderRadius: "5px",
    padding: "1em",
    marginTop: "2em",
    position: "relative",
  },
  nameFloater :{
    position: "absolute",
    left: "47%",
    top: "-2em",
    borderRadius: "50%",
    background: "#181947",
  },
  titleDes : { display: "block", margin: "auto", padding: "1.5em" },
  titleText : { fontWeight: "bold", color: "#ffffff" },
  parentCardDes: { background: "green", borderRadius: "20px",padding:".2em" },
  parentCardDesAbs: { background: "gray", borderRadius: "20px",padding:".2em" },
  parentCardDesLessActive: { background: "#abab05", borderRadius: "20px",padding:".2em" },
  dayText : {color:"#ffffff", padding:".4em"},
  cardDes : {
    boxShadow: "0px 4px 12px -3px #696a76",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    padding: "1.5em",
    background:"white"
  },
  cardInside : {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  empNameDes : {
    // marginTop: "2em",
    marginLeft:".8em",
    fontWeight:500
  }
}));
