import React, { useEffect, useState } from "react";
import { useBasicNav } from "../../../utils/useBasicNav";
import BasicLayout from "../../../components/BasicLayout";
import Filters from "../attendenceFilters/filters";
import { useStyles } from "./styles";
import { Grid, Box, Typography } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { Employee, useEmployees } from "../../../hooks/employees/useEmployees";
import {
  getAttendanceByName,
  getAttendanceByNameandDate,
} from "../../../apis/attendanceApis";
import { getLeaveById } from "../../../apis/leaves";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {
  formatIndividualAttendanceInfo,
  weekHeaders,
  paginateData,
} from "./attendanceTableFormat";

interface IndividualProps {
  loadPrimary: (event: boolean, basic?: boolean) => void;
  showToast: any;
}

export const Toast = React.createContext<any>({});

const IndividualInfo: React.FC<IndividualProps> = () => {
  const { employees } = useEmployees();
  useBasicNav("attendance");
  const classes = useStyles();
  const history: any = useHistory();

  let employeename: any = history.location.state;
  let tabHeads = weekHeaders();

  const [pageLength, setPageLength] = useState<any>(1);
  const [filterValue, setFilterValue] = useState<any>("Last week");
  const [pageNo, setPageNo] = useState<number>(1);
  const [individualData, setIndividualdata] = useState<any[]>([]);
  const [allData, setAlldata] = useState<any[]>([]);
  const [filterApply, setFilterApply] = useState<any>(false);
  const [employeeLeave, setEmployeeLeave] = useState<any>();

  const today = moment();
  const lastMonday = today.clone().subtract(1, "week").startOf("isoWeek");
  const lastMondayFormatted = lastMonday.format("DD/MM/YYYY");
  let WeekDate = [lastMondayFormatted];
  for (let i = 1; i < 5; i++) {
    const dayAdd = lastMonday.clone().add(i, "days").format("DD/MM/YYYY");
    WeekDate.push(dayAdd);
  }
  const oneMonthAgo = today.clone().subtract(1, "month").format("DD/MM/YYYY");
  const threeMonthAgo = today.clone().subtract(3, "month").format("DD/MM/YYYY");
  const sixMonthAgo = today.clone().subtract(6, "month").format("DD/MM/YYYY");
  const anYearAgo = today.clone().subtract(12, "month").format("DD/MM/YYYY");

  let filterOptions: any = {
    "Last 1 month": oneMonthAgo,
    "Last 3 months": threeMonthAgo,
    "Last 6 months": sixMonthAgo,
    "Last 1 year": anYearAgo,
  };

  const fetchIndividualData = async () => {
    let dataVal = await getAttendanceByNameandDate(1, 12, employeename.state);

    let formatting = formatIndividualAttendanceInfo(
      dataVal?.data,
      tabHeads[0],
      tabHeads[4]
    );

    let sortedData = formatting.sort((a: any, b: any) =>
      moment(a._id, "DD/MM/YYYY").diff(moment(b._id, "DD/MM/YYYY"))
    );
    setIndividualdata(sortedData);
    setAlldata(dataVal?.data);
    setPageLength(1);
  };

  const applyFilter = async (filter: string) => {
    setFilterValue(filter);
    if (filter == "Last week") {
      setPageLength(1);
      let WeekDate = [lastMondayFormatted];
      for (let i = 1; i < 5; i++) {
        const dayAdd = lastMonday.clone().add(i, "days").format("DD/MM/YYYY");
        WeekDate.push(dayAdd);
      }
      let formatting: any = formatIndividualAttendanceInfo(
        allData,
        WeekDate[0],
        WeekDate[4]
      );
      let sortedData = formatting.sort((a: any, b: any) =>
        moment(a.date, "DD/MM/YYYY").diff(moment(b.date, "DD/MM/YYYY"))
      );
      setIndividualdata(sortedData);
    } else if (filter == "This week") fetchIndividualData();
    else {
      let formatting: any = formatIndividualAttendanceInfo(
        allData,
        filterOptions[filter],
        tabHeads[0]
      );
      let sortedData = formatting.sort((a: any, b: any) =>
        moment(a.date, "DD/MM/YYYY").diff(moment(b.date, "DD/MM/YYYY"))
      );
      let pageWiseData = paginateData(pageNo, sortedData);
      setPageLength(Math.ceil(sortedData?.length / 12));
      setIndividualdata(pageWiseData);
    }
  };

  useEffect(() => {
    fetchIndividualData();
      employees &&
        employees.forEach(async (empObj) => {
          if (empObj.fullName == employeename.state) {
            const leaveDetails = await getLeaveById(empObj.id, 1, 100, "", "");
            // console.log("yodata", leaveDetails);
          }
        });
  }, [history.location.state, employees]);

  useEffect(() => {
    applyFilter(filterValue);
  }, [pageNo]);

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: any) => {
    setPageNo(value);
  };
  const dayDecide = (dateValue: string) => {
    const dateStr = dateValue;
    const date = moment(dateStr, "DD/MM/YYYY");
    const dayName = date.format("dddd").slice(0, 3);
    return `${dateValue} - ${dayName} `;
  };
  const nameInitials = (fullName: string) => {
    let nameInitSplit = fullName?.split(" ");
    return `${nameInitSplit[0]?.charAt(0) || ""} ${
      nameInitSplit?.[1]?.charAt(0) || ""
    }`;
  };

  const activeConvert = (timeString: string) => {
    let timeParts = timeString.split(":");
    return parseInt(timeParts[0]);
  };
  return (
    <BasicLayout>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Box className={classes.mainBox}>
            <div className={classes.nameFloater}>
              <div className={classes.titleDes}>
                <Typography className={classes.titleText} variant="h5">
                  {nameInitials(employeename.state)}
                </Typography>
              </div>
            </div>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h5"
                align="left"
                className={classes.empNameDes}
              >
                {employeename.state}
              </Typography>
              <Filters
                isDashboard={false}
                filterOptions={[
                  "This week",
                  "Last week",
                  "Last 1 month",
                  "Last 3 months",
                  "Last 6 months",
                  "Last 1 year",
                ]}
                getData={applyFilter}
              />
            </Box>
            <Grid container item xs={12} style={{ minHeight: "4em" }}>
              {individualData.map((item, indexVal) => (
                <Grid item xs={3} key={indexVal} style={{ padding: "1em" }}>
                  <div
                    className={
                      item?.employees[0].inTime == "--:--"
                        ? classes.parentCardDesAbs
                        : activeConvert(item?.employees[0].activeHour) < 5
                        ? classes.parentCardDesLessActive
                        : classes.parentCardDes
                    }
                  >
                    <Typography
                      variant="h6"
                      align="center"
                      className={classes.dayText}
                    >
                      <b>{dayDecide(item?.date)}</b>
                    </Typography>
                    <div className={classes.cardDes}>
                      <div className={classes.cardInside}>
                        <div style={{ width: "50%" }}>
                          <Typography variant="body1">In Time </Typography>
                        </div>
                        <div style={{ width: "50%" }}>
                          <Typography variant="body1">
                            :{item.employees[0].inTime}
                          </Typography>
                        </div>
                      </div>
                      <div className={classes.cardInside}>
                        <div style={{ width: "50%" }}>
                          <Typography variant="body1">Out Time </Typography>
                        </div>
                        <div style={{ width: "50%" }}>
                          <Typography variant="body1">
                            :{item.employees[0].outTime}
                          </Typography>
                        </div>
                      </div>
                      <div className={classes.cardInside}>
                        <div style={{ width: "50%" }}>
                          <Typography variant="body1">Break Time </Typography>
                        </div>
                        <div style={{ width: "50%" }}>
                          <Typography variant="body1">
                            :{item.employees[0].breakTime}
                          </Typography>
                        </div>
                      </div>
                      <div className={classes.cardInside}>
                        <div style={{ width: "50%" }}>
                          <Typography variant="body1">Active Hour </Typography>
                        </div>
                        <div style={{ width: "50%" }}>
                          <Typography variant="body1">
                            :{item.employees[0].activeHour}
                          </Typography>
                        </div>
                      </div>
                      <div className={classes.cardInside}>
                        <div style={{ width: "50%" }}>
                          <Typography variant="body1">
                            No. of Breaks{" "}
                          </Typography>
                        </div>
                        <div style={{ width: "50%" }}>
                          <Typography variant="body1">
                            :{item.employees[0]?.breaksTaken || "NA"}
                          </Typography>
                        </div>
                      </div>
                      <div className={classes.cardInside}>
                        <div style={{ width: "50%" }}>
                          <Typography variant="body1">
                            Leave Applied{" "}
                          </Typography>
                        </div>
                        <div style={{ width: "50%" }}>
                          <Typography variant="body1">
                            :
                            {activeConvert(item.employees[0]?.inTime) >= 12 ||
                            activeConvert(item.employees[0]?.outTime) <= 13 ||
                            item?.employees[0].inTime == "--:--"
                              ? "YES"
                              : "NO"}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "end",
            marginTop: "1em",
            marginBottom: "2em",
          }}
        >
          {pageLength >= 2 && (
            <Pagination
              count={pageLength}
              onChange={handleChangePage}
              defaultPage={pageNo}
              variant="outlined"
              shape="rounded"
            />
          )}
        </Grid>
      </Grid>
    </BasicLayout>
  );
};
export default IndividualInfo;
