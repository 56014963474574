import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Box,
  Typography,
  Button,
  IconButton,
} from "@material-ui/core";
import { useStyles } from "./styles";
import { Formik, FormikHelpers, FormikProps, Form, Field } from "formik";
import * as yup from "yup";
import { FormTextField } from "../FormtextField";
import FormValues from "../../models/assetDetailsType";
import { useLocation } from "react-router-dom";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { keyBy, values } from "lodash";
import TextFieldComponent from "../../components/TextFieldComponent";
import ValidationSchema from "./ValidationSchema";
import RoundedBoxWIthTitle from "./RoundedBoxWIthTitle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import SnackbarComponent from "../SnackbarComponent";
import CircularProgress from "@material-ui/core/CircularProgress";
import { updateAssetDetails } from "../../apis/employees";
import { employeeSensitiveDataEditPermission, employeeRemoveInf } from "../../utils/functions";
import { usePermissionObjectFor } from "../../hooks/permission/usePermissionObjectFor";

const validationSchema = yup
  .object()
  .shape(ValidationSchema.AssetDetailsValidationSchema);

type AssetType = {
  assetName: string;
  assetID: string;
};

type formType = AssetType[];

//eductaional dummy data
const userDataProps: FormValues = {
  assetDetails: [],
};

interface Props {
  employeeId: string;
  assetDetailsInitialData: any;
  loaderStateProps?: boolean;
}

const AssetDetailsEdit: React.FC<Props> = ({
  employeeId,
  assetDetailsInitialData,
  loaderStateProps = true,
}) => {
  const classes = useStyles();

  const [isEditableBasicDetails, setIsEditableBasicDetailsEdit] =
    useState(false);

  const [isFieldAddable, setIsFieldAddable] = useState(false);

  const [assetDetailsData, setAssetDetailsData] = useState(userDataProps);

  //dynamic asset field add logic for form only
  const [tempAssetData, setTempAssetData] = useState(userDataProps);

  //state for snackbar
  const [snackbarState, setSnackbarState] = useState({
    openBooleanProp: false,
    messageProp: "dummy message state",
    statusProp: "info",
  });

  const permission = usePermissionObjectFor("employees");
  const hasAllAccess =permission.required(["create", "upDate","read","del"]);
  const employeeSensitiveDataEditPermission =permission.required(["create", "upDate","read"]);

  const setInitialData = () => {
    //check if the prop contains any data then format it
    if (assetDetailsInitialData) {
      let obj = assetDetailsInitialData;
      const initdata = {
        assetDetails: obj,
      };
      setTempAssetData(initdata);
    } else {
      const initdata = {
        assetDetails: [],
      };
      setTempAssetData(initdata);
    }
  };
  //handle initial data from props
  useEffect(() => {
    setInitialData();
  }, [assetDetailsInitialData]);

  //add field dynamically
  const addFormFields = (currentFormData: any) => {
    const presentTempAssetdata = currentFormData?.assetDetails;
    presentTempAssetdata.push({ assetName: "", assetID: "" });
    const newAssetData = {
      assetDetails: presentTempAssetdata,
    };
    setTempAssetData(newAssetData);
  };

  //remove field dynamically
  const removeFormFields = (index: number, currentData: any) => {
    const presentTempAssetdata = currentData.assetDetails;

    const newAssetData = {
      assetDetails: presentTempAssetdata.filter(
        (el: string, ind: number) => ind !== index
      ),
    };
    setTempAssetData(newAssetData);
  };

  const updateFormdata = async (data: FormValues) => {
    let dataToUpdate: any = [];
    dataToUpdate = data.assetDetails;
    let obj = {
      assets: dataToUpdate,
    };

    try {
      await updateAssetDetails(employeeId, obj);
      setTempAssetData(data);
      setIsEditableBasicDetailsEdit((previous) => !previous);
      setIsFieldAddable(!isFieldAddable);

      //show toast
      setSnackbarState({
        openBooleanProp: true,
        messageProp: "successfully updated",
        statusProp: "success",
      });
    } catch (err: any) {
      //show toast
      setSnackbarState({
        openBooleanProp: true,
        messageProp: "Error : " + err,
        statusProp: "error",
      });
    }
  };

  const handleSubmit = (val: any) => {
    if (isEditableBasicDetails) {
      // 
      updateFormdata(val);
    }
  };

  //dynamic field addable button logic

  return (
    <Box className={classes.basicDetailsViewMainContainer}>
      <SnackbarComponent snackbarStateProp={snackbarState} />
      {!loaderStateProps ? (
        <Box
          sx={{
            width: "100%",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Formik
          initialValues={tempAssetData}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={(values, formikHelpers) => {
            // 

            formikHelpers.setSubmitting(false);
            handleSubmit(values);
          }}
        >
          {(formikProps: FormikProps<FormValues>) => (
            <Form noValidate autoComplete="off">
              <Grid container>
                <Grid item xs={12}>
                  <Grid container className={classes.widthHundred}>
                    <Grid
                      item
                      container
                      xs={12}
                      spacing={2}
                      className={classes.horizontalBoxContainerStyle}
                      style={{ paddingBlock: 16, background: "none" }}
                    >
                      {tempAssetData?.assetDetails?.length !== 0 ? (
                        tempAssetData?.assetDetails?.map((key, index) => {
                          return (
                            <Grid item xs={4}>
                              <RoundedBoxWIthTitle
                                title={`Asset ${index + 1}`}
                                ChildrenContent={
                                  <Grid
                                    container
                                    item
                                    xs={12}
                                    className={classes.flexStartContent}
                                    spacing={3}
                                  >
                                    <Grid
                                      item
                                      className={classes.keyValueMarginTop}
                                    >
                                      <Typography
                                        className={
                                          classes.keyLightTypographyStyle
                                        }
                                      >
                                        Asset Name
                                      </Typography>
                                      {isEditableBasicDetails ? (
                                        <TextFieldComponent
                                          defaultProps={{
                                            name: `assetDetails[${index}]assetName`,
                                            // label: "Name",
                                            fullWidth: false,

                                            // placeholder: "Enter name",
                                          }}
                                        // OnChangeHandler={onChangeTriggered}
                                        />
                                      ) : (
                                        <Typography
                                          className={classes.valueStrongStye}
                                        >
                                          {key.assetName}
                                        </Typography>
                                      )}
                                    </Grid>
                                    <Grid
                                      item
                                      className={classes.keyValueMarginTop}
                                    >
                                      <Typography
                                        className={
                                          classes.keyLightTypographyStyle
                                        }
                                      >
                                        Asset ID
                                      </Typography>
                                      {isEditableBasicDetails ? (
                                        <TextFieldComponent
                                          defaultProps={{
                                            name: `assetDetails[${index}]assetID`,
                                            // label: "Name",
                                            fullWidth: false,
                                            // placeholder: "Enter name",
                                          }}
                                        // OnChangeHandler={onChangeTriggered}
                                        />
                                      ) : (
                                        <Typography
                                          className={classes.valueStrongStye}
                                        >
                                          {key.assetID}
                                        </Typography>
                                      )}
                                    </Grid>
                                    {hasAllAccess && isEditableBasicDetails ? (
                                      <IconButton
                                        color="primary"
                                        aria-label="upload picture"
                                        component="span"
                                        onClick={() =>
                                          removeFormFields(
                                            index,
                                            formikProps.values
                                          )
                                        }
                                      >
                                        <RemoveCircleIcon />
                                        <Typography
                                          style={{ color: "#5E5E5E" }}
                                        >
                                          Remove
                                        </Typography>
                                      </IconButton>
                                    ) : (
                                      <></>
                                    )}
                                  </Grid>
                                }
                              />
                            </Grid>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      // className={classes.horizontalBoxContainerStyle}
                      style={{
                        paddingInline: 9,
                        background: "none",
                      }}
                    >
                      {employeeSensitiveDataEditPermission &&
                        <IconButton
                          color="primary"
                          aria-label="add fields"
                          component="span"
                          onClick={() => addFormFields(formikProps.values)}
                          disabled={!isFieldAddable}
                        >
                          <AddCircleIcon />
                          <Typography style={{ color: "#5E5E5E" }}>
                            Add New Asset
                          </Typography>
                        </IconButton>
                      }
                    </Grid>
                  </Grid>
                </Grid>
                {employeeSensitiveDataEditPermission && (
                  <Grid item xs={12} className={classes.flexEndContent}>
                    {isEditableBasicDetails ? (
                      <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        // onClick={() => setIsEditableBasicDetailsEdit(false)}
                        disabled={formikProps.isSubmitting}
                      >
                        Save
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          // 
                          setIsEditableBasicDetailsEdit(true);
                          setIsFieldAddable((previous) => !previous);
                        }}
                      >
                        Edit
                      </Button>
                    )}
                  </Grid>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};

export default AssetDetailsEdit;
