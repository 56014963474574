import { Grid, TextField, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import React, { useState, useEffect } from "react";
import {
  cancelRequest,
  getEmployeeLeaveById,
  leaveApprove,
  leaveDelete,
  leaveReject,
  userLeave,
} from "../../../../apis/leaves";
import Button from "../../../../components/CustomButton";
import Popup from "../../../../components/Popup";
import { CancelRequestType } from "../CancelRequest/CancelRequest";
import EmployeeLeaves from "./EmployeeLeaves";
import { IEmployeeLeavesInfo } from "./EmployeeLeaves/types";
import { useStyles } from "./styles";

interface IProps {
  parentHeight?: number | undefined;
  leaveRequest?: boolean;
  data?: any;
  showToast?: any;
  handleClose: () => void;
  cancelRequestData?: CancelRequestType[];
  cancelleaveRequest?: () => Promise<void>;
  getCancelRequests?: ()=> Promise<void>;
  getUserLeave?: () => Promise<any>;
}

const LeaveAppliedForm: React.FC<IProps> = ({
  leaveRequest,
  data,
  showToast,
  handleClose,
  cancelleaveRequest,
  getCancelRequests,
  getUserLeave
}) => {
  const classes = useStyles();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [openRequestModal, setOpenRequestModal] = useState<boolean>(false);
  const [cancelReason, setCancelReason] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [employeeLeaveBalance, setEmployeeLeaveBalance] =
    useState<IEmployeeLeavesInfo>({
      casualLeave: {
        taken: 0,
        allotted: 0,
      },
      privilegeLeave: {
        taken: 0,
        allotted: 0,
      },
      sickLeave: {
        taken: 0,
        allotted: 0,
      },
    });

  useEffect(() => {
    fetchEmpLeaveBalance();
  }, [data.emp_id]);

  async function fetchEmpLeaveBalance() {
    setIsLoading(true);
    try {
      const leavesData: IEmployeeLeavesInfo = await getEmployeeLeaveById(
        data.emp_id
      );
      setEmployeeLeaveBalance(leavesData);
      setIsLoading(false);
    } catch (error: any) {
      showToast.error(error.message);
      setIsLoading(false);
    }
  }

  /**
   * @description Function to approve leaves
   * @method handleLeaveApprove
   * @returns nothing
   */
  const handleLeaveApprove = () => {
    setSubmitting(true);
    leaveApprove(data?._id)
      .then((res) => {
        if (res?.status === 200) showToast.success("Approved");
        else showToast.error("Failed to approve leave");

        setSubmitting(false);
      })
      .catch((err) => {
        showToast.error("You are not authorized to approve this leave");
        setSubmitting(false);
      });
  };

  /**
   * @description Function to reject leaves
   * @method handleLeaveReject
   * @returns nothing
   */
  const rejectLeaveHandler = async () => {
    try {
      await leaveReject(data?._id, cancelReason);
      showToast.success("Leave Rejected");
      handleClose();
    } catch (err: any) {
      showToast.error(err.response.data.message);
      handleClose();
    }
  };
  const openModalHandler = () => {
    setOpenRequestModal(true);
  };
  const hideModal = () => {
    setOpenRequestModal(false);
  };

  /**
   * @description Function to delete pending leaves
   * @method handleDeleteLeave
   * @returns nothing
   */
  const handleDeleteLeave = async () => {
    try {
      await leaveDelete(data?._id);
      showToast.success("Leave Deleted");
      getUserLeave && getUserLeave();

      handleClose();
    } catch (err: any) {
      showToast.error(err.response.data.message);
      handleClose();
    }
  };

  const reasonChangeHandler = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setCancelReason(e.target.value);
    // setRejectReason(e.target.value);
  };

  const cancelRequestHandler = async () => {
    try {
      await cancelRequest(data?._id, cancelReason);
      showToast.success("Cancel Request is sent!");
      handleCloseRequestModal();
      handleClose();
      getCancelRequests && getCancelRequests();
      setCancelReason("");
    } catch (err: any) {
      showToast.error(err.response.data.message);
      handleClose();
      handleCloseRequestModal();
    }
  };

  const handleCloseRequestModal = () => {
    setOpenRequestModal(false);
  };

  const totalDuration = data?.duration + data?.adjustAmount;
  return (
    <Grid container className={classes.container}>
      <Grid
        justifyContent="space-between"
        container
        className={classes.formContainer}
      >
        <Grid item xs={12} className={classes.leavebalance}>
          {isLoading ? (
            <div className={classes.loading}>
              <CircularProgress color="primary" />
            </div>
          ) : (
            <EmployeeLeaves employeeLeaveBalance={employeeLeaveBalance} />
          )}
        </Grid>

        <Grid item xs={5} className={classes.fieldontainer}>
          <Typography className={classes.label}>Employee name : </Typography>
          <Typography className={classes.labelTexts}>
            {data?.emp_name}
          </Typography>
        </Grid>
        {data?.approved_by && (
          <Grid item xs={5} className={classes.fieldontainer}>
            <p className={classes.label}>Approved By : </p>
            <Typography className={classes.labelTexts}>
              {data?.approved_by}
            </Typography>
          </Grid>
        )}
        {data?.rejected_by && (
          <Grid item xs={5} className={classes.fieldontainer}>
            <p className={classes.label}>Rejected By : </p>
            <Typography className={classes.labelTexts}>
              {data?.rejected_by}
            </Typography>
          </Grid>
        )}
        <Grid item xs={5} className={classes.fieldontainer}>
          <p className={classes.label}>Leave type : </p>
          <Typography className={classes.labelTexts}>
            {data?.leave_type.replace(/_/g, " ")}
          </Typography>
        </Grid>
        <Grid item xs={5} className={classes.fieldontainer}>
          <p className={classes.label}>Day type : </p>
          <Typography className={classes.labelTexts}>
            {data?.day_type}
          </Typography>
        </Grid>
        <Grid item xs={5} className={classes.fieldontainer}>
          <p className={classes.label}>Duration : </p>
          <Typography className={classes.labelTexts}>
            {totalDuration}
          </Typography>
        </Grid>
        <Grid item xs={5} className={classes.fieldontainer}>
          <p className={classes.label}>From : </p>
          <Typography className={classes.labelTexts}>
            {moment(data?.from).format("DD-MM-YYYY")}
          </Typography>
        </Grid>
        <Grid item xs={5} className={classes.fieldontainer}>
          <p className={classes.label}>To :</p>
          <Typography className={classes.labelTexts}>
            {moment(data?.to).format("DD-MM-YYYY")}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.fieldcontainer}>
          <p className={classes.label}>Reason for applying :</p>
          <TextField
            variant="outlined"
            name="reason"
            multiline
            fullWidth
            inputProps={{
              className: classes.inputText,
            }}
            className={classes.textArea}
            maxRows={4}
            disabled
            value={data?.reason}
            type=""
          />
        </Grid>
        {data?.reasonCancelled && (
          <Grid item xs={12} className={classes.fieldcontainer}>
            <p className={classes.label}>Reason for cancellation :</p>
            <TextField
              variant="outlined"
              name="reason"
              multiline
              fullWidth
              inputProps={{
                className: classes.inputText,
              }}
              className={classes.textArea}
              maxRows={4}
              disabled
              value={data?.reasonCancelled}
              type=""
            />
          </Grid>
        )}
        {data?.reasonRejected && (
          <Grid item xs={12} className={classes.fieldcontainer}>
            <p className={classes.label}>Reason for rejection :</p>
            <TextField
              variant="outlined"
              name="reason"
              multiline
              fullWidth
              inputProps={{
                className: classes.inputText,
              }}
              className={classes.textArea}
              maxRows={4}
              disabled
              value={data?.reasonRejected}
              type=""
            />
          </Grid>
        )}
        {leaveRequest ? (
          <Grid
            justifyContent="flex-end"
            xs={12}
            container
            className={classes.buttonContainer}
          >
            <>
              {data.cancelRequested ? (
                <Grid>
                  <Button
                    label="Approve"
                    isDisabled={submitting}
                    isBordered={false}
                    startIcon=""
                    endIcon=""
                    isOutlined={false}
                    onButtonClick={()=> cancelleaveRequest && cancelleaveRequest()}
                    color=""
                  />
                </Grid>
              ) : (
                <>
                  <Grid className={classes.button}>
                    <Button
                      label={"Reject"}
                      isDisabled={submitting}
                      isBordered={false}
                      startIcon=""
                      endIcon=""
                      isOutlined={true}
                      onButtonClick={openModalHandler}
                      color=""
                    />
                  </Grid>
                  <Grid>
                    <Button
                      label="Approve"
                      isDisabled={submitting}
                      isBordered={false}
                      startIcon=""
                      endIcon=""
                      isOutlined={false}
                      onButtonClick={handleLeaveApprove}
                      color=""
                    />
                  </Grid>
                </>
              )}
            </>
          </Grid>
        ) : (
          <Grid
            xs={12}
            justifyContent="flex-end"
            container
            className={classes.buttonContainer}
          >
            <Grid className={classes.button}>
              <Button
                label="Cancel"
                isBordered={false}
                startIcon=""
                endIcon=""
                isOutlined={true}
                onButtonClick={handleClose}
                color=""
              />
            </Grid>
            {data?.leaveStatus === "pending" ||
            data?.leaveStatus === "approved" && !data?.cancelRequested ? (
              <Grid>
                <Button
                  label={
                    data?.leaveStatus === "approved" ? "Cancel leave" : "Delete"
                  }
                  isBordered={false}
                  startIcon=""
                  endIcon=""
                  isOutlined={false}
                  onButtonClick={
                    data?.leaveStatus === "approved"
                      ? openModalHandler
                      : handleDeleteLeave
                  }
                  color=""
                />
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        )}

        <Popup
          name="Confirm to Cancel the Leave"
          open={openRequestModal}
          handleClose={handleCloseRequestModal}
          close={true}
        >
          <Grid container direction="column" className={classes.popup}>
            <Grid item className={classes.reason}>
              <Typography className={classes.text}>Reason :</Typography>
            </Grid>
            <Grid item>
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="mention reason for cancellation.."
                multiline
                minRows={4}
                fullWidth
                onChange={(e) => reasonChangeHandler(e)}
              />
            </Grid>
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-end"
              className={classes.actions}
            >
              <Grid item className={classes.discard}>
                <Button
                  label="Cancel"
                  isBordered={false}
                  startIcon=""
                  endIcon=""
                  isOutlined={true}
                  onButtonClick={handleCloseRequestModal}
                  color=""
                />
              </Grid>
              <Grid item>
                <Button
                  label="Confirm"
                  isBordered={false}
                  startIcon=""
                  endIcon=""
                  isOutlined={true}
                  onButtonClick={
                    leaveRequest ? rejectLeaveHandler : cancelRequestHandler
                  }
                  color="primary"
                />
              </Grid>
            </Grid>
          </Grid>
        </Popup>
      </Grid>
    </Grid>
  );
};

export default LeaveAppliedForm;
