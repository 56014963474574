import { useBasicNav } from "../../utils/useBasicNav";
import BasicLayout from "../../components/BasicLayout";
import { useStyles } from "./styles";
import EmployeeSearch from "../../components/EmployeeSearch";
import { Button, Grid } from "@material-ui/core";
import NewRecruitsTable from "../../components/NewRecruitsTable";
import AddIcon from "@material-ui/icons/Add";
import { useEffect, useState } from "react";
import Pagination from "@material-ui/lab/Pagination";
import AddNewRecruiteeForm from "../../components/Forms/OnBoardingForm/AddNewRecruiteeForm";
import { useFormik } from "formik";
import { lettersRegex, phoneRegex } from "../../utils/constants";
import { createOnboardUser, getAllUser } from "../../apis/onboarding";
import { getDesignation } from "../../apis/designations_departments";
import { IAllDesignations } from "../../components/Forms/OnBoardingForm/RecruiteeDetails/EmployeeInformation/EmployeeInformation";

interface props {
  loadPrimary: (event: any, state: any) => void;
  showToast: any;
}

const HEAD_CELLS = [
  { key: "emp_name", label: "Name" },
  { key: "emp_email", label: "Email Address" },
  { key: "emp_phone", label: "Phone Number" },
  { key: "emp_designation", label: "Designation" },
  { key: "emp_status", label: "Status" },
  { key: "migrate", label: "Migrate" },
];

interface NewRecruitsProps {
  headCells: {
    label: string;
    key: string;
  }[];
  data: {
    designation: string;
    emailID: string;
    name: string;
    mobileNo: number;
    status: string;
    _id: string;
    migrated: boolean;
  }[];
}

export interface recruiteeDetailsProps {
  employeeName: string;
  employeeEmailId: string;
  employeePhoneNumber: number;
  employeeDesignation: string;
}

const NewRecruits: React.FC<props> = ({ loadPrimary, showToast }) => {
  useBasicNav("onboarding");

  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  const [onBoardUserId, setOnBoardUserId] = useState("");
  const [isValuesSet, setIsValuesSet] = useState(false);
  const [isId, setIsId] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [isLoadedByPageNo, setIsLoadedByPageNo] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isMigrated, setIsMigrated] = useState<boolean>(false);

  //new
  const [allDesignations, setAllDesignations] = useState<IAllDesignations[]>(
    []
  );

  const [data, setData] = useState<
    {
      designation: string;
      emailID: string;
      name: string;
      mobileNo: number;
      status: string;
      _id: string;
      migrated: boolean;
    }[]
  >([]);

  const [searchedData, setSearchedData] =
    useState<NewRecruitsProps["data"]>(data);

  //

  const [payload, setPayload] = useState({
    fullName: "",
    emailID: "",
    mobileNumber: "",
    designation: "",
  });

  let temp: {
    designation: string;
    emailID: string;
    name: string;
    mobileNo: number;
    status: string;
    _id: string;
    migrated: boolean;
  }[] = [];

  useEffect(() => {
    if (isSubmit === true) {
      createOnboardUser(payload, setOnBoardUserId, showToast, setIsId);
      setDataFetched(!dataFetched);
    }
  }, [isSubmit]);

  useEffect(() => {
    getAllUsersData();
  }, [isValuesSet, dataFetched, isDeleted, isLoadedByPageNo, isMigrated]);

  const getAllUsersData = async () => {
    loadPrimary(true, true);
    try {
      const res = await getAllUser(pageNo);

      loadPrimary(false, false);
      if (res.status === 200) {
        setPageCount(res.data.pages);

        res.data.docs.map((value: any) => {
          temp.push({
            designation: value?.employeeInformation?.designation,
            emailID: value?.emailID,
            name: value?.name,
            mobileNo: value?.mobileNo,
            status: value?.status,
            _id: value?._id,
            migrated: value?.migrated,
          });
        });

        setData(temp);
        loadPrimary(false, false);
        setDataFetched(true);
        setIsValuesSet(false);
      } else {
        loadPrimary(false, false);
        showToast.error("Unable to fetch Data!");
        setDataFetched(false);
        setIsValuesSet(false);
      }
    } catch (err: any) {
      showToast.error(err.response.data.message);
    }
  };

  const handleOpenFormModal = () => {
    setOpenModal(true);
  };

  const handleCloseFormModal = () => {
    setOpenModal(false);
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: any) => {
    setPageNo(value);
    setIsLoadedByPageNo(!isLoadedByPageNo);
  };

  const formik = useFormik({
    initialValues: {
      employeeName: "",
      employeeEmailId: "",
      employeePhoneNumber: "",
      employeeDesignation: "",
    },

    validate: (values) => {
      const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/g;

      const checkPhone = phoneRegex.test(values?.employeePhoneNumber);
      // const checkDesignation = values?.employeeDesignation;
      let errors: any = {};
      if (!checkPhone) {
        errors.employeePhoneNumber = true;
      } else if (!emailRegex.test(values?.employeeEmailId)) {
        errors.employeeEmailId = true;
      }
      // else if (!checkDesignation) {
      //    errors.employeeDesignation = true;
      // }
      else {
        errors = {};
      }
      return errors;
    },

    onSubmit: (values) => {
      setPayload({
        fullName: values.employeeName,
        emailID: values.employeeEmailId,
        mobileNumber: values.employeePhoneNumber.toString(),
        designation: values?.employeeDesignation,
      });
      setIsSubmit(true);

      values.employeeName = "";
      values.employeeEmailId = "";
      values.employeePhoneNumber = "";
      values.employeeDesignation = "";

      handleCloseFormModal();
    },
  });

  const getAllDesignations = async () => {
    try {
      const res = await getDesignation();
      setAllDesignations(res.data);
    } catch (error) {}
  };

  useEffect(() => {
    getAllDesignations();
  }, []);

  return (
    <BasicLayout>
      <div style={{ padding: "2%" }}>
        <p className={classes.heading}>New Recruitees</p>
        <Grid container className={classes.header}>
          <Grid item>
            <Grid item>
              <EmployeeSearch
                placeholder="Search by name"
                items={data}
                setSearchedData={setSearchedData}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              style={{ marginBottom: "5%" }}
              onClick={handleOpenFormModal}
            >
              <AddIcon fontSize="small" />
              &nbsp; Add New
            </Button>
          </Grid>
        </Grid>

        <>
          <NewRecruitsTable
            allDesignations={allDesignations}
            headCells={HEAD_CELLS}
            items={searchedData}
            setDataFetched={setDataFetched}
            setIsDeleted={setIsDeleted}
            setIsMigrated={setIsMigrated}
            showToast={showToast}
          />
          <Grid container justifyContent="flex-end" style={{ padding: "1em" }}>
            <Pagination
              defaultPage={pageNo}
              onChange={handleChangePage}
              count={pageCount}
              color="primary"
            />
          </Grid>
        </>

        <AddNewRecruiteeForm
          allDesignations={allDesignations}
          formik={formik}
          open={openModal}
          handleClose={handleCloseFormModal}
        />
      </div>
    </BasicLayout>
  );
};

export default NewRecruits;
