import React from "react";
import { IEmployeeLeavesInfo, LeaveInfo } from "./types";
import { useStyles } from "../styles";
import { Grid} from "@material-ui/core";
import RoundBoxWithTitle from "../../../../../components/RoundBoxWithTitle";

type EmployeeLeavesProps = {
  employeeLeaveBalance: IEmployeeLeavesInfo;
};

const LEAVES = [
  { label: "Priviledge Leave", key: "privilegeLeave" },
  { label: "Casual Leave", key: "casualLeave" },
  { label: "Sick Leave", key: "sickLeave" },
];

const EmployeeLeaves: React.FC<EmployeeLeavesProps> = ({
  employeeLeaveBalance,
}) => {
  const classes = useStyles();
  {console.log("leave",employeeLeaveBalance)}
  return(
    
    <Grid item md={12} xs={12}>
      <RoundBoxWithTitle
        title="Leave Balance"
        ChildrenContent={
          <Grid container xs={12}>
            {LEAVES.map((item) => {
              const leave: LeaveInfo =
                employeeLeaveBalance[item.key as keyof IEmployeeLeavesInfo];
              return (
                <Grid item xs={4} container direction="row">
                  <Grid item className={classes.labelText}>
                    {item.label} :
                  </Grid>
                  <Grid item className={classes.flex}>
                    <p className={classes.labelValue}>
                      {leave.taken}/{leave.allotted}
                    </p>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        }
      />
    </Grid>
  );
};

export default EmployeeLeaves;
