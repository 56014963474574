import React from 'react';
import classNames from 'classnames';
import { useStyles } from './styles';
import { Grid } from '@material-ui/core';
import topCornerArrow from '../../../assets/icons/topRightArrow.svg';
import loadingIcon from '../../../assets/icons/loading.svg';
import doubleTickIcon from '../../../assets/icons/doubleTick.svg';

interface IIconsObject {
  [key: string]: string;
}

const IconsObject: IIconsObject = {
  blue: topCornerArrow,
  yellow: loadingIcon,
  green: doubleTickIcon,
};

interface Props {
  label: string;
  color: string;
  noIcon?:boolean
}

const StatusCard: React.FC<Props> = ({ ...props }) => {

  const {  label, color, noIcon} = props
  const classes = useStyles();

  return (
    <Grid
      alignItems="center"
      justify="center"
      container
      className={classNames(classes.mainContainer, {
        [classes.mainContainerYellow]: color === 'yellow',
        [classes.mainContainerGreen]: color === 'green',
        [classes.mainContainerGreenWithBorder]: color === 'greenWithBorder',
        [classes.mainContainerYellowWithBorder]: color === 'yellowWithBorder',
        [classes.mainContainerRedWithBorder]: color === 'redWithBorder',
        [classes.mainContainerLightYellow]: color === 'lightYellow',
      })}
    >
      <h1 className={classNames(classes.label, {
         [classes.labelGreen]: color === 'greenWithBorder',
         [classes.labelYellow]: color === 'yellowWithBorder',
         [classes.labelRed]: color === 'redWithBorder',
         [classes.labelBlack]: color === 'lightYellow',
      })}>{label}</h1>
      {!noIcon && <img className={classes.icon} src={IconsObject[color]} alt="arrow-icon" />}
    </Grid>
  );
};

export default StatusCard;
