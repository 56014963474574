import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    roleCard: {
        width: "24em",
        height: "5em",
        background: "#140047",
        color: "#140047",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(4)}`,
    },
    roleLabel: {
        fontWeight: 600,
        textTransform: "uppercase",
        color: "#e8e9ec",
        fontFamily: "Poppins",
        marginLeft: "1rem"
    },

    editIcon: {
        marginRight: "1rem",
        color: "#e8e9ec",
        border: "2px solid #e8e9ec",
        transition: "all 0.3s ease-out",
        "&:hover": {
            color: "#26324c",
            background: "#e8e9ec",
            cursor: "pointer",
        },
    },

    dialogContent: {
        width: "34em",
        height: "16em",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "1.2em",
    },
    dialogButton: {
        height: "3rem",
        width: "8rem",
    },
    newRoleButton: {
        marginRight: "1em",
        marginTop: "1em",
        height: "3.5em",
        width: "16em",
    },
    roleNameWrapper: {
        display: "flex",
        justifyContent: "center",
        marginTop: "1em ",
        padding: "2em 4em",
        borderRadius: "8px",
    },
    roleNameContainer: {
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        gap: 20,
        margin: "auto",
    },
    backButton: {
        width: "10em",
        marginRight: "auto",
        alignSelf: "flex-end",
        backgroundColor: "#140047",
        color: "white",
    },
    roleNameInput: {
        display: "block",
        marginTop: "2em",
        marginBottom: "2em",
        fontWeight: "bold",
    },
    permissionsText: {
        backgroundColor: "#140047",
        height: "3rem",
        borderRadius: "4px",
        fontSize: "1.3em",
        paddingLeft: "0.6em",
        color: "white",
        display: "flex",
        alignItems: "center",
    },
    permissionContainer: {
        height: "70vh",
        width: "70vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    permissionCard: {
        display: "grid",
        gridTemplateColumns: "20% 1fr",
        paddingLeft: "1.2em",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "4px",
        padding: "1em 0",
        marginBottom: "1em",
        boxShadow:
            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
    }
}))