import { Box, Button, IconButton, Typography } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import * as React from "react";
import { Link, useHistory } from "react-router-dom";

import { isFirstNDaysOfTheQuarter } from "../../../../utils/isFirstNDaysOfTheQuarter";
import { getNextQuarter } from "../../../../utils/getNextQuarter";

import BackIconOutline from "@material-ui/icons/ArrowBackOutlined";
import moment from "moment";
import { usePermissionObjectFor } from "../../../../hooks/permission/usePermissionObjectFor";
import { IUserData } from "../../views/TeamLeadView";

export const Header: React.FC<IHeaderProps> = ({
  employeeID,
  employeeName,
  renderCreateKraButton,
  renderText,
}) => {
  const permissions = usePermissionObjectFor("kras");
  const isUnder15DaysOfTheQ = isFirstNDaysOfTheQuarter(15);
  // const isUnder15DaysOfTheQ = true;
  const hasCreatePermission = permissions.includes("create");
  const { start, end } = getNextQuarter();
  const history = useHistory();
  const leadId: IUserData = JSON.parse(sessionStorage.tvcpsess);

  return (
    <Box sx={{ display: "flex", alignItems: "center", py: 1 }}>
      <Box sx={{ my: 1, flex: 1, display: "flex", alignItems: "center" }}>
        <IconButton
          onClick={() => history.push("/app/kra")}
          style={{ marginRight: "4px" }}
        >
          <BackIconOutline />
        </IconButton>
        <Typography variant="h5">
          KRA History of{" "}
          <Typography
            component="span"
            variant="h5"
            style={{ fontWeight: "bold", color: grey[700] }}
          >
            {employeeName}
          </Typography>
        </Typography>
      </Box>
      {/* It shoud open 1-15 days starting of an quarter and has create permission */}
      {employeeID !== leadId.userId &&
        isUnder15DaysOfTheQ &&
        hasCreatePermission &&
        renderCreateKraButton({ employeeID, employeeName })}
      {isUnder15DaysOfTheQ || renderText({})}
    </Box>
  );
};

interface IButtonProps
  extends Pick<IHeaderProps, "employeeID" | "employeeName"> {}
interface ITextProps {}

interface IHeaderProps {
  employeeID: string;
  employeeName: string;
  renderCreateKraButton: (props: IButtonProps) => React.ReactNode;
  renderText: (props: ITextProps) => React.ReactNode;
}
