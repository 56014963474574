import React, { useEffect } from "react";
import c3 from "c3";
import "c3/c3.css";
import { useStyles } from "./styles";
import "./styles.css";

interface Props {
  period: string;
}

const BarChart: React.FC<Props> = ({ period }) => {
  const classes = useStyles();

  // 

  const weeklyData = {
    columns: [["duration", 3, 2, 1, 4, 8, 6]],
    categories: [
      "15-11-21",
      "16-11-21",
      "17-11-21",
      "18-11-21",
      "19-11-21",
      "20-11-21",
    ],
  };

  const monthlyData = {
    columns: [["duration", 6]],
    categories: ["January"],
  };

  const getDynamicColumns = () => {
    switch (period) {
      case "this month":
        return monthlyData.columns;

      default:
        return weeklyData.columns;
    }
  };

  const getDynamicCategories = () => {
    switch (period) {
      case "this month":
        return monthlyData.categories;

      default:
        return weeklyData.categories;
    }
  };

  useEffect(() => {
    c3.generate({
      bindto: "#chart",
      data: {
        columns: getDynamicColumns(),
        type: "bar",
      },
      legend: {
        show: false,
      },
      tooltip: {
        show: true,
      },
      color: {
        pattern: ["#c3f0ff"],
      },
      axis: {
        x: {
          type: "category",
          categories: getDynamicCategories(),
        },
        y: {
          max: 9,
          min: 0,
          padding: { top: 0, bottom: 0 },
          tick: {
            // format: d3.format("$,")
            format: function (d: number) {
              if (d > 0) {
                return d + "h";
              } else {
                return d;
              }
            },
          },
        },
      },
      grid: {
        // x: {
        //   show: true,
        // },
        y: {
          // show: true,
          lines: [
            { value: 4, class: "red" },
            { value: 8, class: "green" },
          ],
        },
      },
      bar: {
        width: {
          ratio: 0.8, // this makes bar width 50% of length between ticks
        },
        // or
        //width: 100 // this makes bar width 100px
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  return <div id="chart" className={classes.chart} />;
};

export default BarChart;
