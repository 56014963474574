import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  dot: {
    width: "6px",
    height: "6px",
    borderRadius: "100%",
    marginLeft: "50%",
    transform: "translate(-50%, 0)",
    position: "relative",
    bottom: "1px",
  },
}));
