import { atom } from "recoil";

export const navBasic=atom({
    key : "navBasic",
    default : 0
});

export const navDashboard=atom({
    key : "navDashboard",
    default : 0
})