import { Grid } from "@material-ui/core";
import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { enableClickAtom, permissions } from "../../recoil/atoms";
import { useStyles } from "./styles";

interface StepTabsProps {
  activeStep: number;
  stepNumber: number;
  setTabSteps: (tabStep: number) => void;
}

const StepTabs: React.FC<StepTabsProps> = ({
  activeStep,
  stepNumber,
  setTabSteps,
}) => {
  const classes = useStyles();

  const [permission, setPermission] = useRecoilState<any>(permissions);

  const isSuperAdmin = permission?.roleName === "superadmin";
  const isHR = permission?.roleName === "Human Resource";
  const isAdmin = permission?.roleName === "Admin";
  const allowOnboarding =
    permission?.roleName === "superadmin" ||
    permission?.roleName === "Admin" ||
    permission?.roleName === "Human Resource";

  const enableClick = useRecoilValue(enableClickAtom);

  if (allowOnboarding) {
    return (
      <Grid container style={{ flexDirection: "row" }}>
        <Grid
          item
          // onClick={() => setTabSteps(0)}
          onClick={(e) => {
            enableClick.recruiteeDetails ? setTabSteps(0) : e.preventDefault();
          }}
          className={
            activeStep === 0 && stepNumber == 0
              ? classes.tabActive
              : classes.tabInactive
          }
        >
          Recruitee Details
        </Grid>
        <Grid
          item
          // onClick={() => setTabSteps(1)}
          onClick={(e) => {
            enableClick.recruiteeDetails ? setTabSteps(1) : e.preventDefault();
          }}
          className={
            activeStep === 1 && stepNumber == 1
              ? classes.tabActive
              : classes.tabInactive
          }
        >
          Uploads
        </Grid>
        <Grid
          onClick={(e) => {
            enableClick.uploads ? setTabSteps(2) : e.preventDefault();
          }}
          // onClick={() => setTabSteps(2)}
          item
          className={
            activeStep === 2 && stepNumber == 2
              ? classes.tabActive
              : classes.tabInactive
          }
        >
          Company Email
        </Grid>
        <Grid
          item
          // onClick={() => setTabSteps(3)}
          onClick={(e) => {
            enableClick.companyEmail ? setTabSteps(3) : e.preventDefault();
          }}
          className={
            activeStep === 3 && stepNumber == 3
              ? classes.tabActive
              : classes.tabInactive
          }
        >
          Checklist
        </Grid>

        <Grid item className={classes.tabInactive}></Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container style={{ flexDirection: "row" }}>
        <Grid item className={classes.tabInactive}></Grid>
        <Grid
          item
          onClick={() => setTabSteps(0)}
          className={
            activeStep === 0 && stepNumber == 0
              ? classes.tabActive
              : classes.tabInactive
          }
        >
          Recruitee Details
        </Grid>
        <Grid item className={classes.tabInactive}></Grid>
        <Grid
          item
          onClick={() => setTabSteps(1)}
          className={
            activeStep === 1 && stepNumber == 1
              ? classes.tabActive
              : classes.tabInactive
          }
        >
          Uploads
        </Grid>
        <Grid item className={classes.tabInactive}></Grid>
      </Grid>
    );
  }
};
export default StepTabs;
