import { atom } from "recoil";
import BankDetailsForm from "../components/Forms/OnBoardingForm/RecruiteeDetails/BankDetailsForm";
import BasicDetails from "../components/Forms/OnBoardingForm/RecruiteeDetails/BasicDetails";
import EducationForm from "../components/Forms/OnBoardingForm/RecruiteeDetails/EductionalQualifications";
import EmployeeInformation from "../components/Forms/OnBoardingForm/RecruiteeDetails/EmployeeInformation";
import FamilyAndLanguage from "../components/Forms/OnBoardingForm/RecruiteeDetails/FamilyAndLanguage";
// import TechvariableDocuments from "../components/Forms/OnBoardingForm/Uploads/TechvariableDocuments";
import WorkExperience from "../components/Forms/OnBoardingForm/RecruiteeDetails/WorkExperience";
import ProofOfIdentity from "../components/Forms/OnBoardingForm/Uploads/ProofOfIdentity";
import AcademicCertificates from "../components/Forms/OnBoardingForm/Uploads/AcademicCertificates";
import WorkExperienceDocuments from "../components/Forms/OnBoardingForm/Uploads/WorkExperienceDocuments";
import CompanyEmail from "../components/Forms/OnBoardingForm/CompanyEmail";
import Tasks from "../components/Forms/OnBoardingForm/Checklist/Tasks";
import AssetAllocation from "../components/Forms/OnBoardingForm/Checklist/AssetAllocation";
import Photos from "../components/Forms/OnBoardingForm/Uploads/Photos";

export const accrExpansionState = atom({
  key: "recruiteeDetailsExpansionState",
  default: [
    {
      title: "Basic Details",
      component: BasicDetails,
      expanded: true,
      expansionEnabled: true,
    },
    {
      title: "Educational Qualification",
      component: EducationForm,
      expanded: true,
    },
    {
      title: "Work Experience",
      component: WorkExperience,
      expanded: false,
    },
    {
      title: "Family and Language",
      component: FamilyAndLanguage,
      expanded: false,
    },
    {
      title: "Bank Details",
      component: BankDetailsForm,
      expanded: false,
    },
    {
      title: "Employee Information",
      component: EmployeeInformation,
      expanded: false,
    },
  ],
});

export const accrUploadsExpansion = atom({
  key: "accrUploadsExpansion",
  default: [
    {
      title: "Photos",
      component: Photos,
      expanded: true,
      expansionEnabled: true,
    },
    // {
    //   title: "Techvariable Documents",
    //   component: TechvariableDocuments,
    //   expanded: false,
    // },
    {
      title: "Proof of Identity",
      component: ProofOfIdentity,
      expanded: false,
    },
    {
      title: "Academic Certificates",
      component: AcademicCertificates,
      expanded: false,
    },
    {
      title: "Work Experience Documents",
      component: WorkExperienceDocuments,
      expanded: false,
    },
  ],
});

export const accrCompanyEmailExpansion = atom({
  key: "accrCompanyEmail",
  default: {
    title: "Company Email",
    component: CompanyEmail,
    expanded: true,
    expansionEnabled: true,
  },
});

export const accrChecklistExpansion = atom({
  key: "accrChecklistExpansion",
  default: [
    {
      title: "Tasks",
      component: Tasks,
      expanded: true,
      expansionEnabled: true,
    },
    {
      title: "Asset Allocation",
      component: AssetAllocation,
      expanded: false,
    },
  ],
});
