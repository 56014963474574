import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding:0
  },
  mainContainerPadding: {
    padding:theme.typography.pxToRem(8)
  },


 
}));
