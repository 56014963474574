import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { Form, Formik, FormikProps } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { updateWorkExperience } from "../../apis/employees";
import { usePermissionObjectFor } from "../../hooks/permission/usePermissionObjectFor";
import FormValues from "../../models/workExperienceType";
import KeyValueWrapper from "../KeyValueWrapper";
import SnackbarComponent from "../SnackbarComponent";
import RoundedBoxWIthTitle from "./RoundedBoxWIthTitle";
import { useStyles } from "./styles";
import ValidationSchema from "./ValidationSchema";

const WorkExperienceValidationSchema = yup
  .object()
  .shape(ValidationSchema.WorkExperienceValidationSchema);

//eductaional dummy data
const userDataProps: FormValues = {
  workExperience: [],
};

interface Props {
  employeeId: string;
  workExperienceInitialData: any;
  // workExperienceEndPoint: string;
  calledFromEmployee: boolean;
  headerProps?: any;
  loaderStateProps?: boolean;
}

const WorkExperienceEdit: React.FC<Props> = ({
  employeeId,
  workExperienceInitialData,
  // workExperienceEndPoint,
  headerProps = {
    Authorization: "Bearer ",
  },
  loaderStateProps = true,
  calledFromEmployee,
}) => {
  const classes = useStyles();

  const [isEditableBasicDetails, setIsEditableBasicDetailsEdit] =
    useState(false);
  const employeeEndPoint = `/employees/${employeeId}/work-experience`;
  const onboardingEndPoint = `/on-boarding/work-experience/${employeeId}`;

  //state for snackbar
  const [snackbarState, setSnackbarState] = useState({
    openBooleanProp: false,
    messageProp: "dummy message state",
    statusProp: "info",
  });

  const [isFieldAddable, setIsFieldAddable] = useState(false);

  //data handle logic
  const [workExperienceData, setWorkExperienceData] = useState(userDataProps);

  //dynamic  field add logic for form only
  const [tempWorkExperienceData, setTempWorkExperienceData] =
    useState(workExperienceData);

  const setInitialData = () => {
    //check if the prop contains any data then format it
    if (workExperienceData && workExperienceInitialData?.length > 0) {
      let obj = workExperienceInitialData;
      const initdata = {
        workExperience: obj,
      };
      setTempWorkExperienceData(initdata);
    } else {
      //
      const initdata = {
        workExperience: [],
      };
      setTempWorkExperienceData(initdata);
    }
  };

  const permission = usePermissionObjectFor("employees");
  const employeeNormalDataUpdatePermission = permission.required([
    "upDate",
    "read",
  ]);

  useEffect(() => {
    setInitialData();
  }, [workExperienceInitialData]);

  //add field dynamically
  const addFormFields = (currentFormData: FormValues) => {
    setTempWorkExperienceData(currentFormData);
    const presentTempWorkExperiencedata = currentFormData.workExperience;
    presentTempWorkExperiencedata.push({
      organization: "",
      tctc: 0,
      designation: "",
      startDate: "",
      endDate: "",
      reasonForLeaving: "",
      reference: {
        name: "",
        designation: "",
        contact: "",
        relationship: "",
      },
    });
    const newWorkExperienceData: FormValues = {
      workExperience: presentTempWorkExperiencedata,
    };
    setTempWorkExperienceData(newWorkExperienceData);
  };

  //remove field dynamically
  const removeFormFields = (index: number, currentData: any) => {
    const presentWorkExperienceData = currentData.workExperience;

    const newWorkExperienceData = {
      workExperience: presentWorkExperienceData.filter(
        (el: string, ind: number) => ind !== index
      ),
    };
    setTempWorkExperienceData(newWorkExperienceData);
  };

  const updateFormdata = async (data: FormValues) => {
    let dataToUpdate = data;

    await updateWorkExperience(
      calledFromEmployee ? employeeEndPoint : onboardingEndPoint,
      dataToUpdate
    );
    try {
      //
      setTempWorkExperienceData(data);
      setIsEditableBasicDetailsEdit((previous) => !previous);
      setIsFieldAddable(!isFieldAddable);

      //show toast
      setSnackbarState({
        openBooleanProp: true,
        messageProp: "successfully updated",
        statusProp: "success",
      });
    } catch (err: any) {
      //show toast
      setSnackbarState({
        openBooleanProp: true,
        messageProp: "Error : " + err,
        statusProp: "error",
      });
    }
  };

  const handleSubmit = (val: any) => {
    if (isEditableBasicDetails) {
      //
      updateFormdata(val);
    }
  };

  // const DateHandler=(value)=>{
  //   console.log("formik get value",formikProps)

  // }

  return (
    <Box className={classes.basicDetailsViewMainContainer}>
      <SnackbarComponent snackbarStateProp={snackbarState} />

      {!loaderStateProps ? (
        <Box
          sx={{
            width: "100%",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Formik
          initialValues={tempWorkExperienceData}
          enableReinitialize
          validationSchema={WorkExperienceValidationSchema}
          onSubmit={(values, formikHelpers) => {
            

            values.workExperience.map((val) => {
              if (new Date(val.startDate) > new Date(val.endDate)) {
                
                //   alert("start date cannot be ahead of end date");
              }
              // return;
            });

            formikHelpers.setSubmitting(false);
            handleSubmit(values);
          }}
        >
          {(formikProps: FormikProps<FormValues>) => (
            <Form noValidate autoComplete="off">
              <Grid container>
                <Box sx={{ width: "100%", p: 1 }}>
                  <Typography className={classes.greyThinBigTypoStyle}>
                    In Chronological order
                  </Typography>
                  <Grid container spacing={2} style={{ marginTop: 1 }}>
                    {tempWorkExperienceData?.workExperience?.map(
                      (key, index) => {
                        return (
                          <RoundedBoxWIthTitle
                            title={`Organization ${index + 1}`}
                            ChildrenContent={
                              <Grid
                                container
                                xs={12}
                                className={classes.widthHundred}
                                style={
                                  {
                                    // border: "1px solid blue",
                                  }
                                }
                              >
                                {/* 1st row */}
                                <Grid
                                  container
                                  item
                                  xs={12}
                                  className={
                                    classes.horizontalBoxLessStyleContainerStyle
                                  }
                                  style={{
                                    paddingBlock: 16,
                                  }}
                                >
                                  <KeyValueWrapper
                                    label={`Organisation Name`}
                                    valueParam={
                                      formikProps?.values?.workExperience[index]
                                        ?.organization
                                    }
                                    name={`workExperience[${index}]organization`}
                                    inputType={"textField"}
                                    isEditable={isEditableBasicDetails}
                                    xsProps={4}
                                  />
                                  <KeyValueWrapper
                                    label={"TCTC(PA)"}
                                    valueParam={
                                      formikProps?.values?.workExperience[index]
                                        ?.tctc
                                    }
                                    name={`workExperience[${index}]tctc`}
                                    inputType={"textField"}
                                    valueTypeProps={"number"}
                                    startTextvalue={"Rs "}
                                    isEditable={isEditableBasicDetails}
                                    xsProps={4}
                                  />
                                  <KeyValueWrapper
                                    label={"Designation"}
                                    valueParam={
                                      formikProps?.values?.workExperience[index]
                                        ?.designation
                                    }
                                    name={`workExperience[${index}]designation`}
                                    inputType={"textField"}
                                    isEditable={isEditableBasicDetails}
                                    xsProps={4}
                                  />
                                </Grid>

                                {/* 2nd row */}
                                <Grid container item xs={12}>
                                  <Grid
                                    item
                                    xs={12}
                                    className={
                                      classes.horizontalBoxLessStyleContainerStyle
                                    }
                                  >
                                    <Typography
                                      className={classes.greyStrongNormalStyle}
                                    >
                                      Period Worked
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    container
                                    item
                                    xs={12}
                                    className={
                                      classes.horizontalBoxLessStyleContainerStyle
                                    }
                                    style={{
                                      paddingBlock: 10,
                                    }}
                                  >
                                    {/* new date field start date */}
                                    {/* <TextField
                                      type="date"
                                      label={"Start Date"}
                                      name={`workExperience[${index}]startDate`}
                                      value={moment(
                                        new Date(
                                          formikProps?.values?.workExperience[
                                            index
                                          ]?.startDate
                                        )
                                      ).format("YYYY-MM-DD")}
                                      style={{ width: "100%" }}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      onChange={(e) =>
                                        formikProps.setFieldValue(
                                          `workExperience[${index}]startDate`,
                                          new Date(e.target.value).toISOString()
                                        )
                                      }
                                    /> */}

                                    <KeyValueWrapper
                                      label={"Start Date"}
                                      valueParam={
                                        formikProps?.values?.workExperience[
                                          index
                                        ]?.startDate
                                      }
                                      formikProps={formikProps}
                                      name={`workExperience[${index}]startDate`}
                                      inputType={"onBoardingDatePicker"}
                                      isEditable={isEditableBasicDetails}
                                      xsProps={4}
                                      errorParam={
                                        formikProps.errors.workExperience?.[
                                          index
                                        ]
                                      }
                                      errorParamKey={"startDate"}
                                    />

                                    <KeyValueWrapper
                                      label={"End date"}
                                      valueParam={
                                        formikProps.values?.workExperience[
                                          index
                                        ]?.endDate
                                      }
                                      formikProps={formikProps}
                                      name={`workExperience[${index}]endDate`}
                                      inputType={"onBoardingDatePicker"}
                                      isEditable={isEditableBasicDetails}
                                      xsProps={4}
                                      errorParam={
                                        formikProps.errors.workExperience?.[
                                          index
                                        ]
                                      }
                                      errorParamKey={"endDate"}
                                    />

                                    <KeyValueWrapper
                                      label={"Reason for Leaving"}
                                      valueParam={
                                        formikProps.values?.workExperience[
                                          index
                                        ]?.reasonForLeaving
                                      }
                                      name={`workExperience[${index}]reasonForLeaving`}
                                      inputType={"textField"}
                                      isEditable={isEditableBasicDetails}
                                      xsProps={4}
                                    />
                                  </Grid>
                                </Grid>

                                {/*3rd row */}
                                <Grid container item xs={12}>
                                  <Grid
                                    item
                                    xs={12}
                                    className={
                                      classes.horizontalBoxLessStyleContainerStyle
                                    }
                                    // style={{ border: "1px solid red" }}
                                  >
                                    <Typography
                                      className={classes.greyStrongNormalStyle}
                                    >
                                      Reference
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    container
                                    item
                                    xs={12}
                                    className={
                                      classes.horizontalBoxLessStyleContainerStyle
                                    }
                                    style={{
                                      paddingBlock: 10,
                                    }}
                                  >
                                    <KeyValueWrapper
                                      label={"Name"}
                                      valueParam={
                                        formikProps.values?.workExperience[
                                          index
                                        ]?.reference?.name
                                      }
                                      name={`workExperience[${index}]reference.name`}
                                      inputType={"textField"}
                                      isEditable={isEditableBasicDetails}
                                      xsProps={4}
                                    />
                                    <KeyValueWrapper
                                      label={"Designation"}
                                      valueParam={
                                        formikProps.values?.workExperience[
                                          index
                                        ]?.reference?.designation
                                      }
                                      name={`workExperience[${index}]reference.designation`}
                                      inputType={"textField"}
                                      isEditable={isEditableBasicDetails}
                                      xsProps={4}
                                    />
                                    <KeyValueWrapper
                                      label={"Contact"}
                                      valueParam={
                                        formikProps.values?.workExperience[
                                          index
                                        ]?.reference?.contact
                                      }
                                      name={`workExperience[${index}]reference.contact`}
                                      inputType={"textField"}
                                      isEditable={isEditableBasicDetails}
                                      xsProps={4}
                                    />
                                    <KeyValueWrapper
                                      label={"Relationship"}
                                      valueParam={
                                        formikProps.values?.workExperience[
                                          index
                                        ]?.reference?.relationship
                                      }
                                      name={`workExperience[${index}]reference.relationship`}
                                      inputType={"textField"}
                                      isEditable={isEditableBasicDetails}
                                      xsProps={4}
                                    />
                                  </Grid>
                                </Grid>

                                {isEditableBasicDetails ? (
                                  <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                    onClick={() =>
                                      removeFormFields(
                                        index,
                                        formikProps.values
                                      )
                                    }
                                  >
                                    <RemoveCircleIcon />
                                    <Typography style={{ color: "#5E5E5E" }}>
                                      Remove
                                    </Typography>
                                  </IconButton>
                                ) : (
                                  <></>
                                )}
                              </Grid>
                            }
                          />
                        );
                      }
                    )}
                  </Grid>
                </Box>
                <IconButton
                  color="primary"
                  aria-label="add fields"
                  component="span"
                  onClick={() => addFormFields(formikProps.values)}
                  disabled={!isFieldAddable}
                >
                  <AddCircleIcon />
                  <Typography style={{ color: "#5E5E5E" }}>
                    Add New Organization
                  </Typography>
                </IconButton>
                {employeeNormalDataUpdatePermission && (
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    sx={{ width: "100%" }}
                  >
                    {isEditableBasicDetails ? (
                      <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        // onClick={() => setIsEditableBasicDetailsEdit(false)}
                        disabled={formikProps.isSubmitting}
                      >
                        Save
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          //
                          setIsEditableBasicDetailsEdit(true);
                          setIsFieldAddable(!isFieldAddable);
                        }}
                      >
                        Edit
                      </Button>
                    )}
                  </Box>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};

export default WorkExperienceEdit;
