//#endregion
import React from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { getPersistentAuthentication } from "../../utils/functions";
import { useStyles } from "./styles";

interface Props {
  shouldAuthenticate: boolean;
  shouldHavePadding: boolean;
}

const Authenticator: React.FC<Props> = ({
  shouldAuthenticate = true,
  shouldHavePadding,
  ...props
}) => {
  let authentication: any = getPersistentAuthentication();
  const classes = useStyles();
  let history = useHistory();

  if (shouldAuthenticate) {
    if (authentication?.token) {
      return (
        <div
          className={classNames(classes.mainContainer, {
            [classes.mainContainerPadding]: shouldHavePadding,
          })}
        >
          {props.children}
        </div>
      );
    }
    history.push("/app/signin");
  }
  return (
    <div
      className={classNames(classes.mainContainer, {
        [classes.mainContainerPadding]: shouldHavePadding,
      })}
    >
      {props.children}
    </div>
  );
};

export default Authenticator;
