import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./styles";
import useParentStyles from "../../styles";
import { useHistory, useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import { format } from "date-fns";
import { KHubPost } from "../../../../recoil/atoms";
import profile from "../../../../assets/images/profNew.png";
import { CodeBlock, themes } from "../../components/Editor/MdEditor";
import gfm from "remark-gfm";
import rhr from "rehype-raw";
import { getPost } from "../../../../apis/kHubPost";

export interface BasicDetails{
  fullName:string;
}
export interface authorDetails{
  basicDetails:BasicDetails;
  id:string;
}
export interface KhubPost {
  author: authorDetails;
  body: string;
  category: string;
  createdAt: Date;
  framework: string;
  images: string[];
  isDraft: boolean;
  language: string;
  platform: string;
  title: string;
  updatedAt: string;
  updatedBy: string;
  _id: string;
}

const ArticleView: React.FC = () => {
  const classes = useStyles();
  const parentClasses = useParentStyles();
  const history = useHistory();
  const {id} = useParams<any>();
  const [postData, setPostData] = useState<KhubPost>({
    author: {
      basicDetails:{
        fullName:""
      },
      id:""
    },
    body: "",
    category: "",
    createdAt: new Date(),
    framework: "",
    images: [],
    isDraft: false,
    language: "",
    platform: "",
    title: "",
    updatedAt: "",
    updatedBy: "",
    _id: "",
  });

  const [theme, setTheme] = useState<any>(themes.atomDark);

  function back() {
    history.goBack();
  }

  useEffect(() => {
    fetchgetPost();
  }, []);

  async function fetchgetPost() {
    try {
      const response = await getPost(id ?? "");
      setPostData(response?.data);
    } catch (err: any) {}
  }

  return (
    <Grid container>
      <Grid item xs={12} className={parentClasses.backButton}>
        <Button
          onClick={back}
          variant="text"
          startIcon={
            <ArrowBackIosRoundedIcon className={parentClasses.backIcon} />
          }
        >
          <Typography variant="h6">Back</Typography>
        </Button>
        <Tooltip title="share post" enterDelay={500}>
          <IconButton>
            <ShareOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item xs={12}>
        <section className={classes.header}>
          <div className={classes.header__heading}>
            <Typography variant="h5">{postData?.title}</Typography>
          </div>
          <div className={classes.header__subHeading}>
            <img src={profile} alt="" />
            <Typography variant="body2">
              {postData?.author?.basicDetails?.fullName}
            </Typography>
            &bull;
            <Typography variant="body2">
              {format(
                new Date(
                  postData?.createdAt === undefined ? Date.now() : postData?.createdAt
                ),
                "MMMM dd, yyyy"
              )}
            </Typography>
          </div>
        </section>
      </Grid>
      <Grid item xs={12}>
        <ReactMarkdown
          remarkPlugins={[gfm]}
          rehypePlugins={[rhr]}
          className={`${classes.md} ${classes.preview}`}
          components={CodeBlock(theme)}
        >
          {postData?.body}
        </ReactMarkdown>
      </Grid>
    </Grid>
  );
};

export default ArticleView;
