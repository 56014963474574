import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) =>

    createStyles({

        table: {
            borderRadius: "10px",
            marginTop:"1rem",
            overflow:"scroll",
            overflowX:"scroll",
            height:"80vh",       
        },
        tableHeader: {
            fontFamily: 'Poppins',
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "12px",
            lineHeight: " 18px",
            backgroundColor: "#14213D",
            color: "#FFFFFF",
            padding: ".5rem",

        },
        tableRow: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },


        },
        tableHeaderInside: {
            borderRight: "2px solid white",
            display:"flex",
            alignItems:"center",
            justifyContent:"space-between",
            
        
        },
        tableHeaderNoBorder: {
            display:"flex",
            justifyContent:"space-between",            
            alignItems:"center",
        },
        type:{
            display:"flex",
            justifyContent:"space-between",            
            alignItems:"center",
        },
        loading:{
            position:"absolute",
            top:"20rem",
            left:"55rem",
           },     

    }));
