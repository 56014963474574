import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  header: {
    backgroundColor: "#14213D",
    color: "#fff",
    borderRight: "1px solid #d6d6d6",
    textAlign: "left",
    width: 150,
  },
  headerStatus: {
    backgroundColor: "#14213D",
    color: "#fff",
    textAlign: "left",
    // width: 70,
  },
  emptyFieldsDiv: {
    textAlign: "center",
    backgroundColor: "#fff",
    paddingBottom: "2%",
  },
  emptyFieldsImage: {
    width: 300,
    height: 270,
  },
  emptyFieldsLink: {
    textDecoration: "none",
    color: "#305BF3",
  },
  emptyFieldsText: {
    color: "#14213D",
  },
});
