import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  calendarContainer: {
    height: "22em",
    display: "flex",
    flexDirection: "column",
    marginTop: "15px",
    marginRight: "10px",
    backgroundColor: "white",
    borderRadius: "10px",
    border: "1px solid rgba(0, 0, 0, 0.15)",
    overflow: "hidden",
  },
  calendarTop: {
    flex: 5,
    overflow: "hidden",
    padding: " 0 3%",
  },
  calendar: {
    border: "none",
    margin: "0 auto",
    width: "85%",
  },
  calendarBottom: {
    flex: 1,
    borderTop: "1px solid rgba(0, 0, 0, 0.15)",
    display: "flex",
    justifyContent: "space-evenly",
  },

  calendarHeader: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    justifyContent: "flex-start",
  },

  dot: {
    width: "8px",
    height: "8px",
    borderRadius: "100%",
    backgroundColor: "red",
    marginLeft: "1.5em",
    marginTop: "3px",
    position: "absolute",
  },
}));
