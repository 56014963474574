import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({

  container:{
    padding:theme.typography.pxToRem(10),
    height:"100%"
  },

  eventBox:{
    flex:1,
    paddingBottom:theme.typography.pxToRem(6),
  },

  eventCard :{
    background: "#FFF8F8",
    borderRadius: "5px",
    border: "1px solid #DD0909",
    flex:2
  },

  eventDescription:{
    fontFamily:"Poppins",
    fontWeight:300,
    fontSize:theme.typography.pxToRem(11),
    color:theme.palette.primary.main,
    margin:0
  },

  eventTitle:{
    fontFamily:"Poppins",
    fontWeight:600,
    fontSize:theme.typography.pxToRem(14),
    color:theme.palette.primary.main,
    margin:0
  },

  content:{
    padding:`0 ${theme.typography.pxToRem(8)}`,
  }





}));
