import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  IconButton,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Tooltip,
} from "@material-ui/core";
import { useStyles } from "./styles";
import closeButton from "../../../../assets/icons/closeButton.svg";
import {
  updateContent,
  contentformUpload,
} from "../../../../apis/contentBasket";
import { AxiosResponse } from "axios";

interface props {
  showToast: any;
  hideModalAndClear: () => void;
  createContentHandler(e:React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void>;
  uploadFileHandler: (e: any) => void;
  titleChangeHandler: (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  typeChangeHandler: (e:  React.ChangeEvent<{
    name?: string | undefined;
    value: string | unknown;
  }>) => void;
  areaChangeHandler: (e: React.ChangeEvent<{
    name?: string | undefined;
    value: string | unknown;
  }>) => void;
  fileName: string;
  setfileName: React.Dispatch<React.SetStateAction<string>>;
  contentData: any;
  reupload?: boolean;
  uploadedFile: {
    name: string;
    value: File | string;
}
  setUploadedFile:React.Dispatch<React.SetStateAction<{
    name: string;
    value: File | string;
}>>
  setIsUpdated: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading:React.Dispatch<React.SetStateAction<Boolean>>;
}

const FormModal: React.FC<props> = ({
  hideModalAndClear,
  showToast,
  createContentHandler,
  uploadFileHandler,
  titleChangeHandler,
  typeChangeHandler,
  areaChangeHandler,
  uploadedFile,
  fileName,
  contentData = {},
  reupload,
  setIsUpdated,
  setfileName,
  setUploadedFile,
  setIsLoading,
}) => {
  const classes = useStyles();
  const updateContentHandler = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string
  ) => {
    e.preventDefault();
    setIsLoading(true);
    if(contentData.title === ""){
      showToast.error("Title can't be empty!");
      setIsLoading(false);
      return;
    }
    hideModalAndClear();
    try {
     await updateContent(id, {
        title: contentData.title,
        contentType: contentData.contentType,
        contentArea: contentData.contentArea,
      });
      if(uploadedFile.value !== "" ){
        let formData = new FormData();
        formData.append("file", uploadedFile.value);
        await contentformUpload(formData, id);
        setUploadedFile({
          name:"",
          value:""
        })
        setIsUpdated(true);
        setfileName("");
      }
      setIsLoading(false);   
      showToast.success("Content Updated");  
    } 
    catch (error: any) {
      const err: AxiosResponse<any, any> = error;
      showToast.error(err.data.message);
    }
    setIsUpdated(false);   
  }

  return (
    <div className={classes.container}>
      <div className={classes.heading}>
        {reupload ? (
          <Typography variant="h6">Update Your Content</Typography>
        ) : (
          <Typography variant="h6">Add New Content</Typography>
        )}

        <IconButton onClick={hideModalAndClear}>
          <img src={closeButton} alt="" />
        </IconButton>
      </div>
      <form className={classes.form}>
        <Grid container>
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            className={classes.mb}
          >
            <Grid item xs={5}>
              <InputLabel className={classes.labels} id="title">
                Title*
              </InputLabel>
            </Grid>
            <Grid item xs={7} container justifyContent="flex-end">
              <TextField
                fullWidth
                required
                id="title"
                name="title"
                variant="outlined"
                placeholder="Enter Title"
                onChange={(e) => titleChangeHandler(e)}
                value={contentData.title}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            className={classes.mb}
          >
            <Grid item xs={5}>
              <InputLabel className={classes.labels} id="type">
                Type*
              </InputLabel>
            </Grid>
            <Grid item xs={7} container justifyContent="flex-end">
              <Select
                labelId="type"
                id="type"
                name="type"
                fullWidth
                variant="outlined"
                required
                onChange={(e) => typeChangeHandler(e)}
                value={contentData.contentType}
              >
                <MenuItem value="Article">Article</MenuItem>
                <MenuItem value="Blog">Blog</MenuItem>
                <MenuItem value="Short Writeup">Short Writeup</MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            className={classes.mb}
          >
            <Grid item xs={5}>
              <InputLabel className={classes.labels} id="area">
                Area*
              </InputLabel>
            </Grid>
            <Grid item xs={7} container justifyContent="flex-end">
              <Select
                id="area"
                name="area"
                fullWidth
                variant="outlined"
                onChange={(e) => areaChangeHandler(e)}
                value={contentData.contentArea}
                required
              >
                <MenuItem value="project_management">
                  Project Management
                </MenuItem>
                <MenuItem value="ux_ui">UI-UX</MenuItem>
                <MenuItem value="technology">Technology</MenuItem>
                <MenuItem value="general_operations">
                  General Operations
                </MenuItem>
                <MenuItem value="marketing">Marketing</MenuItem>
                <MenuItem value="general">General </MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            className={classes.mb}
          >
            <Grid item xs={5}>
              <Typography className={classes.labels} variant="body1">
                File upload*
              </Typography>
            </Grid>
            <Grid item xs={7} container justifyContent="flex-end">
              <div className={classes.upload}>
                <input
                  accept=".doc,.docx"
                  className={classes.input}
                  id="contained-button-file"
                  type="file"
                  onChange={(e) => uploadFileHandler(e)}
                />
                <label htmlFor="contained-button-file">
                  <Button variant="contained" component="span" color="primary">
                    Browse
                  </Button>
                </label>
                <div className={classes.cancel}>
                  <Tooltip title={fileName}>
                    <span className={classes.uploadedfile}>{fileName}</span>
                  </Tooltip>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        {reupload ? (
          <Button
            variant="contained"
            disableElevation
            type="submit"
            color="primary"
            onClick={(e) => updateContentHandler(e, contentData.id)}
          >
            Update
          </Button>
        ) : (
          <Button
            variant="contained"
            disableElevation
            type="submit"
            color="primary"
            onClick={(e) => createContentHandler(e)}
          >
            Create
          </Button>
        )}
      </form>
    </div>
  );
};

export default FormModal;
