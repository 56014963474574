import { Dispatch, SetStateAction } from "react";
import { SetterOrUpdater } from "recoil";
import { UPLOAD_DOCUMENT_SIZE } from "../../../../utils/constants";

export const handleAcademicFiles = (
  event: React.ChangeEvent<HTMLInputElement>,
  setImgName: Dispatch<
    SetStateAction<{
      class10th: string;
      class12th: string;
      graduation: string;
      postGraduation: string;
      diploma: string;
    }>
  >,
  setImgSize: Dispatch<
    SetStateAction<{
      class10th: number;
      class12th: number;
      graduation: number;
      postGraduation: number;
      diploma: number;
    }>
  >,
  setIsFileSelected: any,
  setIsSubmit: any,
  setRewriteFileDownload: Dispatch<
    SetStateAction<{
      personalPhoto: boolean;
      passportPhoto: boolean;
      // offerLetter: boolean;
      pan: boolean;
      aadhar: boolean;
      class10: boolean;
      class12: boolean;
      grad: boolean;
      postGrad: boolean;
      diploma: boolean;
      reliving: boolean;
      lastSalSlip: boolean;
      secondLastSalSlip: boolean;
      thirdLastSalSlip: boolean;
    }>
  >,
  showToast: {
    error: (message: string) => void;
    success: (message: string) => void;
    warning: (message: string) => void;
  },
  academicProofs?: any,
  setAcademicProof?: any
) => {
  const fileHandlers: any = {
    class10th: () => {
      const uploadFile = event.target.files as FileList;
      const file10 = uploadFile[0];
      if (
        file10.type == "application/pdf" &&
        file10.size <= UPLOAD_DOCUMENT_SIZE
      ) {
        if (file10) {
          setIsFileSelected((prev: any) => ({
            ...prev,
            class10: true,
          }));
          setRewriteFileDownload((prev) => ({
            ...prev,
            class10: true,
          }));

          setImgName((prev: any) => ({
            ...prev,
            class10th: file10.name,
          }));

          setImgSize((prev: any) => ({
            ...prev,
            class10th: file10.size,
          }));

          academicProofs.forEach((exp: any, index: number) => {
            if (exp.name === event.target.name) {
              academicProofs.splice(index, 1);
            }
          });

          setAcademicProof([
            ...academicProofs,
            {
              name: event.target.name,
              value: file10,
            },
          ]);
          setIsSubmit((prev: any) => ({
            ...prev,
            class10: true,
          }));
        }
        event.target.value = "";
      } else {
        showToast.error("SELECT ONLY PDF FILES WITH MAXIMUM SIZE 1MB!");
        setIsSubmit((prev: any) => ({
          ...prev,
          class10: false,
        }));
        setRewriteFileDownload((prev) => ({
          ...prev,
          class10: false,
        }));
      }
    },
    class12th: () => {
      const uploadFile = event.target.files as FileList;
      const file12 = uploadFile[0];
      if (
        file12.type == "application/pdf" &&
        file12.size <= UPLOAD_DOCUMENT_SIZE
      ) {
        if (file12) {
          setRewriteFileDownload((prev) => ({
            ...prev,
            class12: true,
          }));
          setIsFileSelected((prev: any) => ({
            ...prev,
            class12: true,
          }));
          setImgName((prev: any) => ({
            ...prev,
            class12th: file12.name,
          }));
          setImgSize((prev: any) => ({
            ...prev,
            class12th: file12.size,
          }));

          academicProofs.forEach((exp: any, index: number) => {
            if (exp.name === event.target.name) {
              academicProofs.splice(index, 1);
            }
          });
          setAcademicProof([
            ...academicProofs,
            {
              name: event.target.name,
              value: file12,
            },
          ]);

          setIsSubmit((prev: any) => ({
            ...prev,
            class12: true,
          }));
        }
      } else {
        setRewriteFileDownload((prev) => ({
          ...prev,
          class12: false,
        }));
        showToast.error("SELECT ONLY PDF FILES WITH MAXIMUM SIZE 1MB!");
        setIsSubmit((prev: any) => ({
          ...prev,
          class12: false,
        }));
      }
      event.target.value = "";
    },
    graduation: () => {
      const uploadFile = event.target.files as FileList;
      const fileGrad = uploadFile[0];
      if (
        fileGrad.type == "application/pdf" &&
        fileGrad.size <= UPLOAD_DOCUMENT_SIZE
      ) {
        if (fileGrad) {
          setIsFileSelected((prev: any) => ({
            ...prev,
            grad: true,
          }));
          setImgName((prev) => ({
            ...prev,
            graduation: fileGrad.name,
          }));
          setImgSize((prev) => ({
            ...prev,
            graduation: fileGrad.size,
          }));

          academicProofs.forEach((exp: any, index: number) => {
            if (exp.name === event.target.name) {
              academicProofs.splice(index, 1);
            }
          });

          setAcademicProof([
            ...academicProofs,
            {
              name: event.target.name,
              value: fileGrad,
            },
          ]);
          setIsSubmit((prev: any) => ({
            ...prev,
            grad: true,
          }));
          setRewriteFileDownload((prev) => ({
            ...prev,
            grad: true,
          }));
        }
      } else {
        showToast.error("SELECT ONLY PDF FILES WITH MAXIMUM SIZE 1MB!");
        setIsSubmit((prev: any) => ({
          ...prev,
          grad: false,
        }));
        setRewriteFileDownload((prev) => ({
          ...prev,
          grad: false,
        }));
      }
      event.target.value = "";
    },

    postGraduation: () => {
      const uploadFile = event.target.files as FileList;
      const filePostGrad = uploadFile[0];
      if (
        filePostGrad.type == "application/pdf" &&
        filePostGrad.size <= UPLOAD_DOCUMENT_SIZE
      ) {
        if (filePostGrad) {
          setIsFileSelected((prev: any) => ({
            ...prev,
            postGrad: true,
          }));
          setImgName((prev) => ({
            ...prev,
            postGraduation: filePostGrad.name,
          }));
          setImgSize((prev) => ({
            ...prev,
            postGraduation: filePostGrad.size,
          }));

          academicProofs.forEach((exp: any, index: number) => {
            if (exp.name === event.target.name) {
              academicProofs.splice(index, 1);
            }
          });

          setAcademicProof([
            ...academicProofs,
            {
              name: event.target.name,
              value: filePostGrad,
            },
          ]);
          setIsSubmit((prev: any) => ({
            ...prev,
            postGrad: true,
          }));
          setRewriteFileDownload((prev) => ({
            ...prev,
            postGrad: true,
          }));
        }
      } else {
        showToast.error("SELECT ONLY PDF FILES WITH MAXIMUM SIZE 1MB!");
        setIsSubmit((prev: any) => ({
          ...prev,
          postGrad: false,
        }));
        setRewriteFileDownload((prev) => ({
          ...prev,
          postGrad: false,
        }));
      }
      event.target.value = "";
    },
    diploma: () => {
      const uploadFile = event.target.files as FileList;
      const fileDiploma = uploadFile[0];
      if (
        fileDiploma.type == "application/pdf" &&
        fileDiploma.size <= UPLOAD_DOCUMENT_SIZE
      ) {
        if (fileDiploma) {
          setIsFileSelected((prev: any) => ({
            ...prev,
            diploma: true,
          }));
          setImgName((prev: any) => ({
            ...prev,
            diploma: fileDiploma.name,
          }));
          setImgSize((prev: any) => ({
            ...prev,
            diploma: fileDiploma.size,
          }));

          academicProofs.forEach((exp: any, index: number) => {
            if (exp.name === event.target.name) {
              academicProofs.splice(index, 1);
            }
          });

          setAcademicProof([
            ...academicProofs,
            {
              name: event.target.name,
              value: fileDiploma,
            },
          ]);
          setIsSubmit((prev: any) => ({
            ...prev,
            diploma: true,
          }));
          setRewriteFileDownload((prev) => ({
            ...prev,
            diploma: true,
          }));
        }
      } else {
        showToast.error("SELECT ONLY PDF FILES WITH MAXIMUM SIZE 1MB!");
        setIsSubmit((prev: any) => ({
          ...prev,
          diploma: false,
        }));
        setRewriteFileDownload((prev) => ({
          ...prev,
          diploma: false,
        }));
      }
      event.target.value = "";
    },
  };
  return fileHandlers;
};

export const handleWorkFiles = (
  event: React.ChangeEvent<HTMLInputElement>,
  setIsSubmit: any,
  setImgName: Dispatch<
    SetStateAction<{
      relivingLetter: string;
      salarySlip1st: string;
      salarySlip2nd: string;
      salarySlip3rd: string;
    }>
  >,
  setImgSize: Dispatch<
    SetStateAction<{
      relivingLetter: number;
      salarySlip1st: number;
      salarySlip2nd: number;
      salarySlip3rd: number;
    }>
  >,
  setIsFileSelected: SetterOrUpdater<{
    relieving: boolean;
    lastSal: boolean;
    secondLast: boolean;
    thirdLast: boolean;
  }>,
  experienceProof: { name: string; value: Blob | string }[],
  setExperienceProof: React.Dispatch<React.SetStateAction<File[]>> | any,
  showToast: {
    error: (message: string) => void;
    success: (message: string) => void;
    warning: (message: string) => void;
  },
  setRewriteFileDownload: SetterOrUpdater<{
    personalPhoto: boolean;
    passportPhoto: boolean;
    // offerLetter: boolean;
    pan: boolean;
    aadhar: boolean;
    class10: boolean;
    class12: boolean;
    grad: boolean;
    postGrad: boolean;
    diploma: boolean;
    reliving: boolean;
    lastSalSlip: boolean;
    secondLastSalSlip: boolean;
    thirdLastSalSlip: boolean;
  }>
) => {
  const fileHandlers: any = {
    relivingLetter: () => {
      const uploadFile = event.target.files as FileList;
      const fileReliving = uploadFile[0];
      event.target.value = "";
      if (
        fileReliving.type == "application/pdf" &&
        fileReliving.size <= UPLOAD_DOCUMENT_SIZE
      ) {
        if (fileReliving) {
          setRewriteFileDownload((prev) => ({
            ...prev,
            reliving: true,
          }));
          setIsFileSelected((prev: any) => ({
            ...prev,
            relieving: true,
          }));
          setImgName((prev) => ({
            ...prev,
            relivingLetter: fileReliving.name,
          }));
          setImgSize((prev) => ({
            ...prev,
            relivingLetter: fileReliving.size,
          }));

          experienceProof.forEach((exp: any, index: number) => {
            if (exp.name === event.target.name) {
              experienceProof.splice(index, 1);
            }
          });

          setExperienceProof([
            ...experienceProof,
            {
              name: event.target.name,
              value: fileReliving,
            },
          ]);
          setIsSubmit((prev: any) => ({
            ...prev,
            reliving: true,
          }));
        }
      } else {
        setRewriteFileDownload((prev) => ({
          ...prev,
          reliving: false,
        }));
        showToast.error("SELECT ONLY PDF FILES WITH MAXIMUM SIZE 1MB!");
        setIsSubmit((prev: any) => ({
          ...prev,
          reliving: false,
        }));
      }
    },
    salarySlip1st: () => {
      const uploadFile = event.target.files as FileList;
      const fileSalary1 = uploadFile[0];
      event.target.value = "";
      if (
        fileSalary1.type == "application/pdf" &&
        fileSalary1.size <= UPLOAD_DOCUMENT_SIZE
      ) {
        if (fileSalary1) {
          setRewriteFileDownload((prev) => ({
            ...prev,
            lastSalSlip: true,
          }));
          setIsFileSelected((prev: any) => ({
            ...prev,
            lastSal: true,
          }));
          setImgName((prev) => ({
            ...prev,
            salarySlip1st: fileSalary1.name,
          }));
          setImgSize((prev) => ({
            ...prev,
            salarySlip1st: fileSalary1.size,
          }));

          experienceProof.forEach((exp: any, index: number) => {
            if (exp.name === event.target.name) {
              experienceProof.splice(index, 1);
            }
          });

          setExperienceProof([
            ...experienceProof,
            {
              name: event.target.name,
              value: fileSalary1,
            },
          ]);
          setIsSubmit((prev: any) => ({
            ...prev,
            fileSalary1: true,
          }));
        }
      } else {
        setRewriteFileDownload((prev) => ({
          ...prev,
          lastSalSlip: false,
        }));
        showToast.error("SELECT ONLY PDF FILES WITH MAXIMUM SIZE 1MB!");
        setIsSubmit((prev: any) => ({
          ...prev,
          fileSalary1: false,
        }));
      }
    },
    salarySlip2nd: () => {
      const uploadFile = event.target.files as FileList;
      const fileSalary2 = uploadFile[0];
      event.target.value = "";
      if (
        fileSalary2.type == "application/pdf" &&
        fileSalary2.size <= UPLOAD_DOCUMENT_SIZE
      ) {
        if (fileSalary2) {
          setRewriteFileDownload((prev) => ({
            ...prev,
            secondLastSalSlip: true,
          }));
          setIsFileSelected((prev: any) => ({
            ...prev,
            secondLast: true,
          }));
          setImgName((prev) => ({
            ...prev,
            salarySlip2nd: fileSalary2.name,
          }));
          setImgSize((prev) => ({
            ...prev,
            salarySlip2nd: fileSalary2.size,
          }));

          experienceProof.forEach((exp: any, index: number) => {
            if (exp.name === event.target.name) {
              experienceProof.splice(index, 1);
            }
          });

          setExperienceProof([
            ...experienceProof,
            {
              name: event.target.name,
              value: fileSalary2,
            },
          ]);
          setIsSubmit((prev: any) => ({
            ...prev,
            fileSalary2: true,
          }));
        }
      } else {
        setRewriteFileDownload((prev) => ({
          ...prev,
          secondLastSalSlip: false,
        }));
        showToast.error("SELECT ONLY PDF FILES WITH MAXIMUM SIZE 1MB!");
        setIsSubmit((prev: any) => ({
          ...prev,
          fileSalary2: false,
        }));
      }
    },
    salarySlip3rd: () => {
      const uploadFile = event.target.files as FileList;
      const fileSalary3rd = uploadFile[0];
      event.target.value = "";
      if (
        fileSalary3rd.type == "application/pdf" &&
        fileSalary3rd.size <= UPLOAD_DOCUMENT_SIZE
      ) {
        if (fileSalary3rd) {
          setRewriteFileDownload((prev) => ({
            ...prev,
            thirdLastSalSlip: true,
          }));
          setIsFileSelected((prev: any) => ({
            ...prev,
            thirdLast: true,
          }));
          setImgName((prev) => ({
            ...prev,
            salarySlip3rd: fileSalary3rd.name,
          }));
          setImgSize((prev) => ({
            ...prev,
            salarySlip3rd: fileSalary3rd.size,
          }));

          experienceProof.forEach((exp: any, index: number) => {
            if (exp.name === event.target.name) {
              experienceProof.splice(index, 1);
            }
          });

          setExperienceProof([
            ...experienceProof,
            {
              name: event.target.name,
              value: fileSalary3rd,
            },
          ]);
          setIsSubmit((prev: any) => ({
            ...prev,
            fileSalary3rd: true,
          }));
        }
      } else {
        setRewriteFileDownload((prev) => ({
          ...prev,
          thirdLastSalSlip: false,
        }));
        showToast.error("SELECT ONLY PDF FILES WITH MAXIMUM SIZE 1MB!");
        setIsSubmit((prev: any) => ({
          ...prev,
          fileSalary3rd: false,
        }));
      }
    },
  };
  return fileHandlers;
};

interface IhandleIdentityFiles {
  event: React.ChangeEvent<HTMLInputElement>;
  setIsSubmit: any;
  setImageName: Dispatch<
    SetStateAction<{
      adhaarCard: string;
      panCard: string;
    }>
  >;
  setImageSize: Dispatch<
    SetStateAction<{
      adhaarCard: number;
      panCard: number;
    }>
  >;
  setIsFileSelected: SetterOrUpdater<{
    pan: boolean;
    aadhar: boolean;
  }>;
  idProofs: File[] | any;
  setIdentityProof: React.Dispatch<React.SetStateAction<File[]>> | any;
  setRewriteFileDownload: Dispatch<
    SetStateAction<{
      personalPhoto: boolean;
      passportPhoto: boolean;
      // offerLetter: boolean;
      pan: boolean;
      aadhar: boolean;
      class10: boolean;
      class12: boolean;
      grad: boolean;
      postGrad: boolean;
      diploma: boolean;
      reliving: boolean;
      lastSalSlip: boolean;
      secondLastSalSlip: boolean;
      thirdLastSalSlip: boolean;
    }>
  >;
  showToast: any;
}

export const handleIdentityFiles = ({
  event,
  idProofs,
  setIdentityProof,
  setImageName,
  setImageSize,
  setIsFileSelected,
  setIsSubmit,
  setRewriteFileDownload,
  showToast,
}: IhandleIdentityFiles) => {
  const fileHandlers: any = {
    panCard: () => {
      const uploadFile = event.target.files as FileList;
      const filePan = uploadFile[0];
      if (
        filePan.type == "application/pdf" &&
        filePan.size <= UPLOAD_DOCUMENT_SIZE
      ) {
        setRewriteFileDownload((prev) => ({
          ...prev,
          pan: true,
        }));
        if (filePan) {
          setIsFileSelected((prev: any) => ({
            ...prev,
            pan: true,
          }));
          setImageName((prev) => ({
            ...prev,
            panCard: filePan.name,
          }));
          setImageSize((prev) => ({
            ...prev,
            panCard: filePan.size,
          }));

          idProofs.forEach((exp: any, index: number) => {
            if (exp.name === event.target.name) {
              idProofs.splice(index, 1);
            }
          });

          setIdentityProof([
            ...idProofs,
            {
              name: event.target.name,
              value: filePan,
            },
          ]);
        }

        setIsSubmit((prev: any) => ({
          ...prev,
          pan: true,
        }));
      } else {
        showToast.error("SELECT ONLY PDF FILES WITH MAXIMUM SIZE 1MB!");
        setRewriteFileDownload((prev) => ({
          ...prev,
          pan: false,
        }));
        setIsSubmit((prev: any) => ({
          ...prev,
          pan: false,
        }));
      }
      event.target.value = "";
    },

    adhaarCard: () => {
      const uploadFile = event.target.files as FileList;
      const fileAadhar = uploadFile[0];
      if (
        fileAadhar.type == "application/pdf" &&
        fileAadhar.size <= UPLOAD_DOCUMENT_SIZE
      ) {
        setRewriteFileDownload((prev) => ({
          ...prev,
          aadhar: true,
        }));
        if (fileAadhar) {
          setIsFileSelected((prev: any) => ({
            ...prev,
            aadhar: true,
          }));
          setImageName((prev) => ({
            ...prev,
            adhaarCard: fileAadhar.name,
          }));
          setImageSize((prev) => ({
            ...prev,
            adhaarCard: fileAadhar.size,
          }));
          idProofs.forEach((exp: any, index: number) => {
            if (exp.name === event.target.name) {
              idProofs.splice(index, 1);
            }
          });
          setIdentityProof([
            ...idProofs,
            {
              name: event.target.name,
              value: fileAadhar,
            },
          ]);
        }
        setIsSubmit((prev: any) => ({
          ...prev,
          aadhar: true,
        }));
      } else {
        showToast.error("SELECT ONLY PDF FILES WITH MAXIMUM SIZE 1MB!");
        setRewriteFileDownload((prev) => ({
          ...prev,
          aadhar: false,
        }));
        setIsSubmit((prev: any) => ({
          ...prev,
          aadhar: false,
        }));
      }
      event.target.value = "";
    },
  };
  return fileHandlers;
};
