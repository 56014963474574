import React from "react";
import { Grid } from "@material-ui/core";
import FormSection from "./FormSection";
import WithLoader from "../../../hoc/WithLoader";
import { useStyles } from "./styles";

interface designationObject {
  _id: string;
  designationName: string;
}
interface departmentObject {
  _id: string;
  departmentName: string;
}

interface IProps {
  showToast: any;
  departments: departmentObject[];
  toggleRefresh: (type?: string) => void;
  designations: designationObject[];
  loadDesignation?: boolean;
}

const RolesForm: React.FC<IProps> = ({
  showToast,
  departments,
  toggleRefresh,
  designations,
  loadDesignation,
}) => {
  const classes = useStyles();

  const FormSectionDesignationWithLoader = WithLoader(FormSection, {
    width: "28.125rem",
    // height: theme.typography.pxToRem(95),
    // borderRadius: '10px',
    // background: '#fff',
  });

  return (
    <Grid direction="column" container className={classes.mainContainer}>
      <FormSectionDesignationWithLoader
        items={designations}
        toggleRefresh={toggleRefresh}
        isLoading={loadDesignation}
        type="Designation"
        name="designationName"
        showToast={showToast}
      />
      <FormSection
        items={departments}
        toggleRefresh={toggleRefresh}
        type="Department"
        name="departmentName"
        showToast={showToast}
      />
    </Grid>
  );
};

export default RolesForm;
