import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    card: {
        borderRadius: "0.8rem",
        border: "1px solid #E4E4E4",
        width: "100%",
        marginBottom: "1rem",
    },
    heading: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        columnGap: "1rem",
    },
    avatar: {
        width: "1.8rem",
        height: "1.8rem",
        border: "1px solid black",
    },
    name: {
        fontWeight: 600,
        color: "#140047CC",
    },
    cardContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        rowGap: "0.7rem",
    },
    content: {
        fontWeight: 500,
        color: "rgba(20, 0, 71, 1)"
    },
    cardActions: {
        padding: "0px 0px 0.8rem 1rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        columnGap: "rem",
        "& > div": {
            width: "1px",
            height: "12px",
            backgroundColor: "rgba(20, 0, 71, 0.6)",
        },
        "& > a": {
            textDecoration: "none",
            color: "rgba(20, 0, 71, 0.6)",
            fontSize: "12px",
        },
        "& > a:hover": {
            color: "rgba(20, 0, 71, 1)",
        },
        "& >span":{
            textDecoration: "none",
            color: "rgba(20, 0, 71, 0.6)",
            fontSize: "12px",
        },
        "& > span:hover": {
            color: "rgba(20, 0, 71, 1)",
        },
    },
    delete:{
        cursor:"pointer"
    }
}));
