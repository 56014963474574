import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Button,
} from "@material-ui/core";
import { useStyles } from "./style";
import { useRecoilState } from "recoil";
import {
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { permissions } from "../../../../recoil/atoms";
import { itemValues } from "../ProjectTabs/IncidentManagement/IncidentManagement";
import Moment from "moment";
import type { Moment as IMoment } from "moment";
import { DatePicker, } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { usePermissionObjectFor } from "../../../../hooks/permission/usePermissionObjectFor";

interface props {
  hideModalAndClear: () => void;
  createIncidentHandler: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  setIncidentFormData: React.Dispatch<
    React.SetStateAction<{
      ID: string;
      date: string;
      severity: string;
      description: string;
    }>
  >;
  incidentFormData: {
    ID: string;
    date: string;
    severity: string;
    description: string;
  };
  incidentData: itemValues[];
  isIncidentView: Boolean;
  incidentUpdateHandler: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  isEditing: Boolean;
  incidentEditHandler: () => void;
}

const FormModal: React.FC<props> = ({
  hideModalAndClear,
  createIncidentHandler,
  setIncidentFormData,
  incidentFormData,
  isIncidentView,
  incidentData,
  incidentUpdateHandler,
  isEditing,
  incidentEditHandler,
}) => {
  const classes = useStyles();
  const [selectedDate, setDate] = useState(Moment());
  const permission = usePermissionObjectFor("incidents");
  const hasAccess = permission.required(["create","upDate"]);

  

  const _onChange = (date: MaterialUiPickersDate) => {
    setDate(date as unknown as IMoment);
    setIncidentFormData({
      ...incidentFormData,
      date: date as unknown as string,
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.heading}>
        {isIncidentView || isEditing ? (
          <Typography variant="h6">Incident Details</Typography>
        ) : (
          <Typography variant="h6">Add a New Incident</Typography>
        )}
      </div>
      <form className={classes.form}>
        <Grid container>
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            className={classes.mb}
          >
            <Grid item xs={5}>
              <InputLabel className={classes.labels} id="date">
                Date*
              </InputLabel>
            </Grid>

            <Grid container item xs={7} justifyContent="flex-end">
              {isIncidentView ? (
                <TextField
                  fullWidth
                  required
                  type="text"
                  variant="outlined"
                  value={Moment(incidentFormData.date).format("DD/MM/YYYY")}
                  disabled
                />
              ) : isEditing ? (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    inputVariant="outlined"
                    fullWidth
                    disableFuture={true}
                    value={incidentFormData.date}
                    onChange={_onChange}
                    format="dd/MM/yyyy"
                  />
                </MuiPickersUtilsProvider>
              ) : (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    inputVariant="outlined"
                    fullWidth
                    disableFuture={true}
                    value={selectedDate}
                    onChange={_onChange}
                    format="dd/MM/yyyy"
                  />
                </MuiPickersUtilsProvider>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            className={classes.mb}
          >
            <Grid item xs={5}>
              <InputLabel className={classes.labels} id="type">
                severity*
              </InputLabel>
            </Grid>
            <Grid container item xs={7} justifyContent="flex-end">
              {isIncidentView ? (
                <Select
                  labelId="severity"
                  id="severity"
                  name="severity"
                  fullWidth
                  variant="outlined"
                  value={incidentFormData.severity}
                  disabled
                >
                  <MenuItem value="High">High</MenuItem>
                  <MenuItem value="Medium">Medium</MenuItem>
                  <MenuItem value="Low">Low</MenuItem>
                </Select>
              ) : (
                <Select
                  labelId="severity"
                  id="severity"
                  name="severity"
                  fullWidth
                  variant="outlined"
                  value={incidentFormData.severity}
                  onChange={(
                    e: React.ChangeEvent<{
                      name?: string | undefined;
                      value: unknown;
                    }>
                  ) =>
                    setIncidentFormData({
                      ...incidentFormData,
                      severity: e.target.value as string,
                    })
                  }
                  required
                >
                  <MenuItem value="High">High</MenuItem>
                  <MenuItem value="Medium">Medium</MenuItem>
                  <MenuItem value="Low">Low</MenuItem>
                </Select>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            className={classes.mb}
          >
            <Grid item xs={5}>
              <InputLabel className={classes.labels} id="area">
                Description
              </InputLabel>
            </Grid>
            <Grid container item xs={7} justifyContent="flex-end">
              {isIncidentView ? (
                <TextField
                  fullWidth
                  multiline
                  required
                  id="description"
                  name="description"
                  variant="outlined"
                  placeholder="write briefly"
                  value={incidentFormData.description}
                  disabled
                />
              ) : (
                <TextField
                  fullWidth
                  multiline
                  required
                  id="description"
                  name="description"
                  variant="outlined"
                  placeholder="write briefly"
                  value={incidentFormData.description}
                  onChange={(e) =>
                    setIncidentFormData({
                      ...incidentFormData,
                      description: e.target.value,
                    })
                  }
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.discard}>
          {isIncidentView ? (
            <>
              <Button
                variant="contained"
                color="default"
                className={classes.button}
                onClick={hideModalAndClear}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disableElevation
                type="submit"
                color="primary"
                onClick={incidentEditHandler}
                disabled={!hasAccess}
              >
                Edit
              </Button>
            </>
          ) : (
            <>
              {isEditing ? (
                <>
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.button}
                    onClick={hideModalAndClear}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    disableElevation
                    type="submit"
                    color="primary"
                    onClick={(e) => {
                      incidentUpdateHandler(e);
                    }}
                  >
                    Update
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.button}
                    onClick={hideModalAndClear}
                  >
                    Discard
                  </Button>
                  <Button
                    variant="contained"
                    disableElevation
                    type="submit"
                    color="primary"
                    onClick={(e) => {
                      createIncidentHandler(e);
                    }}
                  >
                    Create
                  </Button>
                </>
              )}
            </>
          )}
        </Grid>
      </form>
    </div>
  );
};

export default FormModal;
