import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Typography,
} from "@material-ui/core";
import { useRecoilValue } from "recoil";
import {
  profileAvatarAtom,
  recruiteeBasicDetails,
} from "../../../../../recoil/atoms";
import { useStyles } from "./styles";
import profile from "../../../../../assets/images/profileBG.svg";
import moment from "moment";
import { useEffect } from "react";

interface ViewBasicDetailsProps {
  formik: any;
}

const ViewDetails: React.FC<ViewBasicDetailsProps> = ({ formik }) => {
  const classes = useStyles();
  const basicDetails = useRecoilValue(recruiteeBasicDetails);
  const avatarImage = useRecoilValue(profileAvatarAtom);

  useEffect(() => {
    
  }, [avatarImage]);

  return (
    <>
      <Grid container direction="row" spacing={2} justifyContent="center">
        <Grid item md={8}>
          <Grid container direction="row" spacing={5} justifyContent="center">
            <Grid item xs={12} sm={12} md={6}>
              <InputLabel
                variant="outlined"
                shrink
                className={classes.viewTextLabel}
              >
                <b className={classes.formControlsWidth}>Full Name</b>
              </InputLabel>
              <Typography className={classes.viewText}>
                {formik.values.name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <InputLabel
                variant="outlined"
                shrink
                className={classes.viewTextLabel}
              >
                <b>Email ID</b>
              </InputLabel>
              <Typography className={classes.viewText}>
                {formik.values.emailID}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <InputLabel
                variant="outlined"
                shrink
                className={classes.viewTextLabel}
              >
                <b>Phone Number</b>
              </InputLabel>
              <Typography className={classes.viewText}>
                {formik.values.mobileNo}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <InputLabel
                variant="outlined"
                shrink
                className={classes.viewTextLabel}
              >
                <b>Gender</b>
              </InputLabel>
              <Typography className={classes.viewText}>
                {formik.values.gender}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <InputLabel
                variant="outlined"
                shrink
                className={classes.viewTextLabel}
              >
                <b>Date of Birth</b>
              </InputLabel>
              <Typography className={classes.viewText}>
                {/* {formik.values.birthDate.split("T")[0]} */}
                {moment(new Date(formik.values.birthDate)).format("DD-MM-YYYY")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <InputLabel
                variant="outlined"
                shrink
                className={classes.viewTextLabel}
              >
                <b>Age</b>
              </InputLabel>
              <Typography className={classes.viewText}>
                {formik.values?.age ? basicDetails.age : 0}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          className={classes.uploadImgContainerView}
        >
          <img
            src={
              avatarImage.profileImage !== ""
                ? avatarImage.profileImage
                : profile
            }
            className={classes.profileImage}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        spacing={6}
        className={classes.viewPadding}
      >
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <InputLabel
            variant="outlined"
            shrink
            className={classes.viewTextLabel}
          >
            <b>Married</b>
          </InputLabel>
          <Typography className={classes.viewText}>
            {formik.values.married === "Yes" ? "Married" : "Unmarried"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <InputLabel
            variant="outlined"
            shrink
            className={classes.viewTextLabel}
          >
            <b>Blood Group</b>
          </InputLabel>
          <Typography className={classes.viewText}>
            {formik.values.bloodGroup}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <InputLabel
            variant="outlined"
            shrink
            className={classes.viewTextLabel}
          >
            <b>Voter ID</b>
          </InputLabel>
          <Typography className={classes.viewText}>
            {formik.values?.idProofs?.voterIdNo}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <InputLabel
            variant="outlined"
            shrink
            className={classes.viewTextLabel}
          >
            <b>Aadhar Number</b>
          </InputLabel>
          <Typography className={classes.viewText}>
            {formik.values?.idProofs?.aadharNo}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <InputLabel
            variant="outlined"
            shrink
            className={classes.viewTextLabel}
          >
            <b>PAN No.</b>
          </InputLabel>
          <Typography className={classes.viewText}>
            {formik.values?.idProofs?.panNo}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <InputLabel
            variant="outlined"
            shrink
            className={classes.viewTextLabel}
          >
            <b>Passport No.</b>
          </InputLabel>
          <Typography className={classes.viewText}>
            {formik.values?.idProofs?.passportNo}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={4} className={classes.viewPadding2em}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FormControl className={classes.formControlsWidth}>
            <InputLabel
              variant="outlined"
              shrink
              className={classes.viewAddressTextLabel}
            >
              <b>Present Address</b>
            </InputLabel>
            <Typography className={classes.viewAddressText}>
              {formik.values.address.present.addressField}
            </Typography>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={6} className={classes.viewPadding}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <InputLabel
            variant="outlined"
            shrink
            className={classes.viewTextLabel}
          >
            <b>City</b>
          </InputLabel>
          <Typography className={classes.viewText}>
            {formik.values.address.present.city}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <InputLabel
            variant="outlined"
            shrink
            className={classes.viewTextLabel}
          >
            <b>State</b>
          </InputLabel>
          <Typography className={classes.viewText}>
            {formik.values.address.present.state}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <InputLabel
            variant="outlined"
            shrink
            className={classes.viewTextLabel}
          >
            <b>PIN Code</b>
          </InputLabel>
          <Typography className={classes.viewText}>
            {formik.values.address.present.pin}
          </Typography>
        </Grid>
        {/* new */}
        <Grid item xs={12} sm={12} style={{ marginLeft: "1em" }}>
          <FormControlLabel
            disabled
            control={
              <Checkbox
                name="isPermanentAddressDifferent"
                checked={formik.values.isPermanentAddressDifferent}
                value={formik.values.isPermanentAddressDifferent}
              />
            }
            label="Permanent Address different from above"
          />
        </Grid>
      </Grid>

      {formik.values.isPermanentAddressDifferent ? (
        <Grid
          container
          spacing={4}
          className={classes.viewPadding2em}
          // style={{ border: "1px solid red" }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl className={classes.formControlsWidth}>
              <InputLabel
                variant="outlined"
                shrink
                className={classes.viewAddressTextLabel}
              >
                <b>Permanent Address</b>
              </InputLabel>
              <Typography className={classes.viewAddressText}>
                {formik.values?.address?.permanent?.addressField}
              </Typography>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <InputLabel
              variant="outlined"
              shrink
              className={classes.viewTextLabel}
            >
              <b>City</b>
            </InputLabel>
            <Typography className={classes.viewText}>
              {formik.values?.address?.permanent?.city}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <InputLabel
              variant="outlined"
              shrink
              className={classes.viewTextLabel}
            >
              <b>State</b>
            </InputLabel>
            <Typography className={classes.viewText}>
              {formik.values?.address?.permanent?.state}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <InputLabel
              variant="outlined"
              shrink
              className={classes.viewTextLabel}
            >
              <b>PIN Code</b>
            </InputLabel>
            <Typography className={classes.viewText}>
              {formik.values?.address?.permanent?.pin}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}

      {/* permanent address */}

      <Grid
        container
        justifyContent="flex-start"
        spacing={6}
        className={classes.viewPadding2em}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <span className={classes.emergencyHead}>Emergency Contact No.</span>
          <br />
          <span className={classes.emergencyContent}>
            Whom we can contact in case of emergency
          </span>
        </Grid>
      </Grid>
      <Grid container spacing={6} className={classes.viewPadding2em}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <InputLabel
            variant="outlined"
            shrink
            className={classes.viewTextLabel}
          >
            <b>Name</b>
          </InputLabel>
          <Typography className={classes.viewText}>
            {formik.values.emergencyContact.name}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <InputLabel
            variant="outlined"
            shrink
            className={classes.viewTextLabel}
          >
            <b>Relationship</b>
          </InputLabel>
          <Typography className={classes.viewText}>
            {formik.values.emergencyContact.relationship}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <InputLabel
            variant="outlined"
            shrink
            className={classes.viewTextLabel}
          >
            <b>Phone No.</b>
          </InputLabel>
          <Typography className={classes.viewText}>
            {formik.values.emergencyContact.contactNo}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default ViewDetails;
