import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: "100%",
    height: "calc(100vh - 16px)",
    display: "flex !important",
    gap: "1rem",
    
  },

  leftWrapper: {
    flexBasis: "calc(75% - 8px)",
    flexGrow: 1,
    height: "100%",

    display: "flex",
    gap: "1rem",
    flexDirection: "column",
  },

  rightWrapper: {
    flexBasis: "calc(25%)",
    height: "100%",
    
  },

  myLeavesContainer: {
    boxSizing: "border-box",
    width: "100%",
    flexBasis: "9rem",
    backgroundColor: "#FFFFFF",
    border: "1px solid rgba(0, 0, 0, 0.15)",
    borderRadius: "0.625rem",
    display: "flex",
    padding: ".5rem",
  },

  pl: {
    flex: 1,
    backgroundColor: "rgba(8, 133, 223, 0.05)",
    borderRadius: "0.625rem",
    border: "1px solid #0885DF",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  plTitle: {
    margin: 0,
    padding: 0,
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "15px",
    color: "#0885DF",
  },
  plCount: {
    margin: 0,
    padding: 0,
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "38px",
    lineHeight: "46px",
    color: "#0885DF",
  },

  cl: {
    flex: 1,
    backgroundColor: "rgba(217, 2, 2, 0.05)",
    borderRadius: "0.625rem",
    border: "1px solid #DD0909",
    margin: "0px 0.625rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  clTitle: {
    margin: 0,
    padding: 0,
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "15px",
    color: "#D90202",
  },
  clCount: {
    margin: 0,
    padding: 0,
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "38px",
    lineHeight: "46px",
    color: "#D90202",
  },

  sl: {
    flex: 1,
    backgroundColor: "rgba(243, 217, 12, 0.05)",
    borderRadius: "0.625rem",
    border: "1px solid #F3D90C",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  slTitle: {
    margin: 0,
    padding: 0,
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "15px",
    color: "#F3D90C",
  },
  slCount: {
    margin: 0,
    padding: 0,
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "38px",
    lineHeight: "46px",
    color: "#F3D90C",
  },

  contentWrapper: {
    width: "100%",
    height: "100%",
    borderRadius: "0.625rem",
  },
  loading: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
}));
