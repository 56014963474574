import { Grid, InputLabel, Paper, Typography } from "@material-ui/core";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useRecoilState, useRecoilValue } from "recoil";
import { getDocuments } from "../../../../../apis/onboarding";
import uploadDocument from "../../../../../assets/icons/uploadDocument.svg";
import {
  isUploadsViewEnabled,
  recruiteeBasicDetails
} from "../../../../../recoil/atoms";
import { UploadsContext } from "../../../../../store/uploadsStore";
import { useStyles } from "../styles";
import UploadedDocuments from "../UploadedDocuments";
import { PhotosInterface } from "./Photos";

const PassportPhotos = ({
  imageName,
  setImageName,
  imageSize,
  fileSetter,
}: PhotosInterface) => {
  const classes = useStyles();
  const { photos, setPhotos } = useContext(UploadsContext);
  const [, setIsViewEnabled] = useRecoilState(isUploadsViewEnabled);

  const getOnboardingId = useRecoilValue(recruiteeBasicDetails);
  const mainUrl = process.env.REACT_APP_BACKEND_URL;
  const getEndpoint = `/api/on-boarding/get-uploaded-documents/${getOnboardingId.id}`;
  const [isDisable, setIsDisable] = useState<boolean>(false);

  const url = mainUrl + getEndpoint;
  const parentKey = "photos";
  const childKey = "passportPhoto";

  const getDocs = async () => {
    const response = await getDocuments(url, parentKey, childKey);
    if (response.status === 200 && response.data !== "") {
      setImageName((prev) => ({
        ...prev,
        passportPhoto: "Passport Photo",
      }));
      setPhotos((prev) => [
        ...prev,
        {
          name: "passportPhoto",
          value: response.data,
        },
      ]);
      setIsViewEnabled((prev) => ({
        ...prev,
        photos: true,
      }));
    } else {
      setIsViewEnabled((prev) => ({
        ...prev,
        photos: false,
      }));
    }
  };

  useEffect(() => {
    getDocs();
  }, []);

  useEffect(() => {
    const length = photos.length;
    if (length > 0) {
      for (let i = 0; i < length; i++) {
        if (
          photos[i].name === "passportPhoto" &&
          imageName.passportPhoto !== ""
        ) {
          setIsDisable(true);
          break;
        }
        if (imageName.passportPhoto === "") {
          setIsDisable(false);
        }
      }
    }
  }, [photos, imageName]);

  const {
    getRootProps: getRootPropsPassport,
    getInputProps: getInputPropsPassport,
  } = useDropzone({
    onDrop: (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      if (!isDisable) {
        fileSetter(file, "passportPhoto");
      }
    },
    noClick: true,
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files as FileList;
    const file = uploadedFile[0];
    fileSetter(file, "passportPhoto");
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      className={classes.docContainer}
    >
      <Paper
        variant="outlined"
        elevation={4}
        className={classes.paperContainer}
      >
        <InputLabel variant="outlined" className={classes.label} shrink>
          <b className={classes.labelColor}>Passport Photos</b>
        </InputLabel>

        <Grid container direction="column" className={classes.dropContainer}>
          <Grid item>
            <Grid
              container
              direction="column"
              spacing={2}
              className={classes.dashedContainer}
              {...getRootPropsPassport()}
            >
              <input {...getInputPropsPassport()} />
              <Grid item className={classes.itemStyle}>
                <Typography className={classes.boldText}>
                  Drag and Drop files to upload
                </Typography>
              </Grid>
              <Grid item className={classes.itemStyle}>
                <Typography className={classes.instruction}>
                  Maximum upload size 1MB
                </Typography>
              </Grid>

              <Grid item className={classes.itemStyle}>
                <input
                  type="file"
                  name="passport"
                  id="passport"
                  className={classes.inputfile}
                  onChange={(event) => {
                    handleChange(event);
                  }}
                  onClick={(event) => {
                    //@ts-ignore
                    event.target.value = null;
                  }}
                  disabled={isDisable}
                />

                <label htmlFor="passport" className={classes.inputlabel}>
                  <i className={classes.inputButtonIcon}>
                    <img src={uploadDocument} />
                  </i>
                  &nbsp; Upload File
                </label>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={classes.itemStyle}
          >
            {imageName.passportPhoto !== "" ? (
              <UploadedDocuments
                imageName={imageName.passportPhoto}
                imageSize={imageSize.passportPhoto}
                setImgName={setImageName}
                label="passportDoc"
                progress={0}
              />
            ) : (
              <Typography className={classes.instruction}>
                No Files Selected
              </Typography>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default PassportPhotos;
