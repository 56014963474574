import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({

   container: {
    flex: 3,
    paddingTop: theme.typography.pxToRem(12),
    position: 'relative',
  },

  AdminContainer: {
    flex: 3,
    paddingTop: theme.typography.pxToRem(25),
    position: 'relative',
  },

  form: {
    width: '100%',
    height:"100%",
  },

  formControl: {
    width: 'calc(100% - 1.25rem)',
  },

  radioBtn: {
    '& svg': {
      width: theme.typography.pxToRem(13),
      height: theme.typography.pxToRem(13)
    }
  },

  label: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    color: '#686868',
    opacity: '0.9',
    textTransform: 'uppercase',
  },

  inputLabel: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '10px !important',
    lineHeight: theme.typography.pxToRem(12),
    opacity: '0.5'
  },

  inputLabelChecked: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '10px !important',
    lineHeight: theme.typography.pxToRem(12),
  },

  textField: {
    marginRight: theme.spacing(1),
    width: '100%',
    marginTop: theme.typography.pxToRem(20),
  },

  addLeaveBtn: {
    width: '97%',
    height: '3rem',
    marginLeft: '0.625rem',
    marginBottom: '3rem',
    borderRadius: '0.625rem',
    border: '1px dashed rgba(0, 0, 0, 0.38)',
    padding: '0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },

  adminAddLeaveBtn: {
    width: '97%',
    height: '3rem',
    marginLeft: '0.625rem',
    marginBottom: '3rem',
    borderRadius: '0.625rem',
    border: '1px dashed rgba(0, 0, 0, 0.38)',
    padding: '0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },

  buttonGroup:{
    paddingRight: '.5rem',
    paddingBottom: '1.25rem',
    marginTop:"3em",
  },

  button:{
    marginRight:theme.typography.pxToRem(20),
  },
  addIcon: {
       marginRight:theme.typography.pxToRem(20),
    
  },
  formContainer:{
    marginBottom: "1.5rem"
  },
  innerFormContainer:{
    marginTop: "30px"
  }
}));
