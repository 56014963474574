import axios from "./axios.global";

export const createRole = async (payload: object) => {
  try {
    return await axios.post(`/roles`, payload);
  } catch (err: any) {
    return err;
  }
};

export const getRoles = async () => {
  try {
    return await axios.get(`/roles`);
  } catch (err: any) {
    return err;
  }
};

export const editRole = async (payload: object, id: string | undefined) => {
  try {
    return await axios.put(`/roles/${id}`, payload);
  } catch (err: any) {
    return err;
  }
};

export const deleteRole = async (id: string | undefined) => {
  try {
    return await axios.delete(`/roles/del/${id}`);
  } catch (err: any) {
    return err;
  }
};


export const getRoleNamesAPI = async () => {
  return await axios.get('/roles/rolenames');
}


export const createRoleAPI = async (roleName: string) => {
  return await axios.post('/roles/create', { roleName });
}

export const getRolePermissions = async (id: string) => {
  return await axios.get(`/roles/permissions/${id}`);
}