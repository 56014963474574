import { makeStyles } from "@material-ui/core";
// import { SportsRugbySharp } from "@material-ui/icons";
// import { theme } from "../../theme/theme";

export const useStyles = makeStyles({
  nameWithButton: {
    display: "flex",
    justifyContent: "space-between",
  },
  addButton: {
    fontSize: "14px",
    color: "rgba(0,0,0,0.6)",
    border: "none",
    background: "rgba(0,0,0,0.1)",
    "&:hover": {
      border: "none",
    },
  },
  tableCell: {
    fontSize: "14px",
    borderTop: "transparent",
    borderBottom: "1px dashed rgba(0,0,0,0.1)",
    width: "20rem",
  },
  tableCellText: {
    fontSize: "14px",
    borderTop: "transparent",
    borderBottom: "1px dashed rgba(0,0,0,0.1)",
    textAlign: "left",
  },
  historyTableCell: {
    fontSize: "14px",
    borderTop: "transparent",
    background: "#f5f6f7",
  },
  mainTableCell: {
    fontSize: "14px",
  },
  alternateTableRow: {
    background: "rgba(231,233,236,0.4)",
  },
});
