import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  // mainContainer: {
  //   width: '100%',
  //   height: '100%',
  //   background: "#fff",
  //   borderLeft: '1px solid rgba(0, 0, 0, 0.15)',
  //   borderRight: '1px solid rgba(0, 0, 0, 0.15)',
  //   borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
  //   borderTop: '8px solid #F3D90C',
  //   borderRadius: "8px",
  //   border: '4px solid blue'
  // },

  mainContainerRed: {
    borderTop: '8px solid #DD0909',
  },

  mainContainerBlue: {
    borderTop: '8px solid #0885DF',
  },

  mainContainerPurple: {
    borderTop: '8px solid #DF08BD',
  },

  title: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(18),
    color: "#F3D90C",
    textAlign: 'center',
    paddingTop: '1em',
  },

  titleRed: {
    color: "#DD0909"
  },

  titleBlue: {
    color: "#0885DF"
  },

  titlePurple: {
    color: "#DF08BD"
  },

  container: {
    height: '18em',
    borderRadius: "4px",
    paddingBottom: '.3em'
  },
  headerBox: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottom: '1px solid #E8E8E8',
    height: "4em",
    display: 'flex',
    justifyContent: 'center',
    position: 'relative'
  },
  bodyBox: {
    height: '13.5em',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  uploadButton: {
    position: 'absolute',
    top: '0em',
    right: '0em',
    margin: '.6em',
    marginLeft: 'auto',
    cursor: 'pointer'
  }
}));
