import {
  Avatar,
  Box,
  Button,
  Grid,
  Paper,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import PublishIcon from "@material-ui/icons/Publish";
import { Autocomplete, Pagination } from "@material-ui/lab";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  getAttendanceHistory,
  getEmpDetailsAPI,
  getWorkdaysAPI,
  uploadAttendanceAPI,
} from "../../../../apis/attendance";
import { getEmployeeListKRA } from "../../../../apis/employees";
import {
  allMonths,
  detailsBox,
  lastThreeYears,
  userInfo,
} from "../../../../constants/attendance.constants";
import { Adapter } from "../../../../utils/Adapter";
import { getPersistentAuthentication } from "../../../../utils/functions";
import { AttendanceContext } from "../../AttendancePageV2";
import {
  IAllEmployees,
  IAttendanceDetailsNew,
  IEmpDetails,
  IHistory,
  IHistoryShredded,
  TabPanelProps,
} from "../../attendance.interface";
import GraphFilters from "../../attendanceFilters/graphFilters";
import AttendanceCard from "../../components/AttendanceCard";
import { useStyles } from "./styles";
import { usePermissionObjectFor } from "../../../../hooks/permission/usePermissionObjectFor";

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const EmployeeView = () => {
  const permissions = usePermissionObjectFor("attendance");
  const hasCreatePermission = permissions.includes("create");

  const [tabsValue, setTabsValue] = useState<number>(0);
  const classes = useStyles();

  const [attendanceHistory, setAttendanceHistory] = useState<
    IHistoryShredded[]
  >([]);
  const [file, setFile] = useState<File | string>();
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const user = getPersistentAuthentication();
  const [allEmployees, setAllEmployees] = useState<IAllEmployees[]>([]);
  const [userId, setUserId] = useState<string>(user?.userId);
  const ownUserIdRef = useRef<string>(user?.userId || "");
  const workDaysRef = useRef(0);
  const [fetchingAllEmp, setFetchingAllEmp] = useState(true);
  const [workDays, setWorkDays] = useState(0);

  // current month and year
  const [currentMonth, setCurrentMonth] = useState<number>(
    new Date().getMonth() + 1
  );
  const [currentYear, setCurrentYear] = useState<number>(
    new Date().getFullYear()
  );

  const date = new Date();
  const startDate = moment(
    new Date(currentYear, currentMonth - 1, 1).toISOString(),
    ""
  ).format("YYYY-MM-DD");
  const endDate = moment(
    new Date(currentYear, currentMonth, 0).toISOString()
  ).format("YYYY-MM-DD");

  const [empDetails, setEmpDetails] = useState<IEmpDetails>({
    department: "",
    designation: "",
    email: "",
    name: "",
    phone: "",
    role: "",
    _id: "",
  });
  const [attendanceDetails, setAttendanceDetails] =
    useState<IAttendanceDetailsNew>({
      avgInTime: "",
      avgActiveHours: "",
      avgOutTime: "",
      totalAttendance: 0,
    });

  const { showToast } = useContext(AttendanceContext);

  const getHistory = async (
    userId: string,
    startDate: string,
    endDate: string,
    page: number
  ) => {
    const res = await getAttendanceHistory({
      empId: userId,
      startDate,
      endDate,
      page,
    });
    console.log("res :>> ", res);
    const apiData = new Adapter<IHistory[]>(res?.data.data || []);
    const data = apiData.adapt<IHistoryShredded[]>((data) =>
      data.map((data) => ({
        id: data._id,
        date: data.date,
        inTime: data.inTime,
        outTime: data.outTime,
        activeHour: data.activeHour,
        breakTime: data.breakTime,
      }))
    );
    setAttendanceHistory(data);
    setPageCount(res?.data.totalPage);
  };

  const getEmpDetails = async (userId: string) => {
    const res = await getEmpDetailsAPI(userId, startDate, endDate);
    setEmpDetails({
      ...res?.data.empDetails,
      name: res?.data.empDetails.name.trim(),
    });
    setAttendanceDetails(res?.data.attendanceDetails);
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("handle file upload", e.target.files);
    if (!e.target.files || e.target.files?.length == 0) return;
    setFile(e.target.files[0]);
    uploadAttendanceFile(e.target.files[0]);
  };

  const uploadAttendanceFile = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    const res = await uploadAttendanceAPI(formData);
    console.log("upload ---> ", res);
    //show toast message: res.data.success & res.data.message
    if (res?.data.success) {
      showToast.success(res?.data.message);
    } else {
      showToast.error(res?.data.message);
    }
  };

  useEffect(() => {
    getHistory(userId, startDate, endDate, pageNo);
    getEmpDetails(userId);
  }, [pageNo, userId, startDate, endDate]);

  const handleTabsChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabsValue(newValue);
  };

  const handleEmpChange = (e: any) => {
    setUserId(e.target.value);
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setPageNo(page);
  };

  const getAllEmployees = async () => {
    setFetchingAllEmp(true);
    const res = await getEmployeeListKRA();
    setAllEmployees(res.data);
    setFetchingAllEmp(false);
  };

  const getWorkDays = async () => {
    const result = await getWorkdaysAPI(currentMonth, currentYear);
    setWorkDays(result.workdays);
  };

  useEffect(() => {
    getAllEmployees();
    // (async function () {
    //   const result = await getWorkdaysAPI(date.getMonth(), date.getFullYear());

    //   workDaysRef.current = result.workdays;
    // })();
  }, []);

  useEffect(() => {
    getWorkDays();
  }, [currentMonth, currentYear]);

  const idNameMapper: Record<string, string> = {};
  allEmployees.map((item) => {
    idNameMapper[item._id] = item.name;
  });

  const handleMonthChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setCurrentMonth(e.target.value as number);
  };

  const handleYearChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setCurrentYear(e.target.value as number);
  };

  function findEmployee(userId: string) {
    const employee = allEmployees.find((item) => item._id === userId);
    return employee;
  }

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        height: "98vh",
      }}
    >
      <Paper elevation={3} style={{ padding: "1em 2em", marginBottom: "1em" }}>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1em",
          }}
        >
          <Box style={{ display: "flex" }}>
            <Box
              style={{ borderLeft: "4px solid #140047", marginRight: ".5em" }}
            ></Box>
            <Typography
              variant="h6"
              style={{
                color: "gray",
                fontWeight: "500",
              }}
            >
              Employee Details
            </Typography>
          </Box>

          {hasCreatePermission && (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {!fetchingAllEmp && (
                <Autocomplete
                  fullWidth
                  size="small"
                  id="combo-box-demo"
                  autoSelect
                  value={findEmployee(userId)}
                  options={allEmployees}
                  getOptionLabel={(option) => option.name}
                  style={{ width: 260, marginRight: "1em" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Employee"
                      variant="outlined"
                    />
                  )}
                  onChange={(e, value) => {
                    console.log("autocomplete", value);
                    setUserId((value?._id || ownUserIdRef.current) as string);
                  }}
                />
              )}
              <Box
                style={{
                  display: "flex",
                  width: "200px",
                }}
              >
                <input
                  accept="*/"
                  // className={classes.input}
                  id="contained-button-file"
                  type="file"
                  hidden
                  onChange={handleFileUpload}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    style={{ width: "100%" }}
                    variant="contained"
                    size="large"
                    // className={classes.uploadButn}
                    startIcon={<PublishIcon />}
                    component="div"
                    color="primary"
                  >
                    Upload File
                  </Button>
                </label>
              </Box>
            </Box>
          )}
        </Box>
        <Box
          style={{
            marginBottom: "1.2em",
            display: "flex",
            alignItems: "center",
          }}
        >
          {empDetails.name !== "" && (
            <Avatar style={{ padding: "1.8em", marginRight: "1.6em" }}>
              <Typography style={{ fontSize: "2em" }}>
                {empDetails?.name[0]}
              </Typography>
            </Avatar>
          )}

          <Box>
            <Typography
              variant="body1"
              color="initial"
              style={{ fontSize: "1.6em", fontWeight: "600" }}
            >
              {empDetails.name}
            </Typography>
            <Box style={{ display: "flex" }}>
              {userInfo.map((item, index) => (
                <Box
                  style={{ marginRight: "2em", marginTop: ".6em" }}
                  key={index}
                >
                  <Typography style={{ fontWeight: "600" }}>
                    {item.title}
                  </Typography>
                  <Typography>
                    {empDetails[item.label as keyof typeof empDetails]}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1em",
          }}
        >
          {detailsBox.map((item) => (
            <Box
              key={item.id}
              style={{
                background: "#e8e8e8",
                padding: ".4em 1em",
                display: "flex",
                borderRadius: "6px",
                alignItems: "center",
                width: "16em",
                position: "relative",
              }}
            >
              <Tooltip
                title={item.desc}
                style={{
                  position: "absolute",
                  top: "4px",
                  right: "4px",
                }}
              >
                <InfoOutlinedIcon />
              </Tooltip>
              <Avatar
                style={{
                  marginRight: "1em",
                  background: "#140047",
                }}
              >
                {<item.icon />}
              </Avatar>
              <Box>
                <Typography style={{ fontSize: "1.2em", fontWeight: "500" }}>
                  {attendanceDetails[item.label]}
                  {item.label === "totalAttendance" && `/${workDays}`}
                </Typography>
                <Typography style={{ fontSize: "1em" }}>
                  {item.title}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Paper>

      {/* tabs: attendance-history and attendance graph */}

      {/* attendance history */}
      <Paper elevation={3} style={{ padding: "2em", flex: 1 }}>
        <Tabs
          value={tabsValue}
          onChange={handleTabsChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="Attendance History"
            style={{
              fontSize: "1.1em",
              fontWeight: "500",
            }}
          />
          {/* <Tab
            label="Attendance Graph"
            style={{
              fontSize: "1.1em",
              fontWeight: "500",
            }}
          /> */}
        </Tabs>

        {/* attendance history */}
        <Box
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <TabPanel value={tabsValue} index={0}>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box style={{ alignSelf: "flex-end", marginBottom: "1em" }}>
                <GraphFilters
                  currentMonth={currentMonth}
                  currentYear={currentYear}
                  allMonths={allMonths}
                  lastThreeYears={lastThreeYears}
                  handleMonthChange={handleMonthChange}
                  handleYearChange={handleYearChange}
                />
              </Box>

              <Grid container spacing={2}>
                {attendanceHistory.length > 0 &&
                  attendanceHistory.map((item: IHistoryShredded) => (
                    <Grid key={item.id} item xs={12} sm={6} md={4} lg={3}>
                      <AttendanceCard key={item.id} item={item} />
                    </Grid>
                  ))}
                <Grid item xs={12}>
                  {attendanceHistory.length === 0 && (
                    <Typography
                      variant="h6"
                      color="textSecondary"
                      style={{
                        fontWeight: "600",
                        padding: "24px",
                        fontFamily: "monospace",
                        textAlign: "center",
                      }}
                    >
                      No data found !!
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
          <Grid
            container
            justifyContent="flex-end"
            style={{ padding: "1em", marginBottom: "1em" }}
          >
            <Pagination
              defaultPage={pageNo}
              onChange={handleChangePage}
              count={pageCount}
              color="primary"
              shape="rounded"
            />
          </Grid>
        </Box>

        {/* attendance graph */}
        {/* <TabPanel value={tabsValue} index={1}>
          <Grid container spacing={3} style={{ marginTop: "1em" }}>
            <Grid item xs={12}>
              <Paper
                elevation={3}
                style={{ padding: "2em", marginBottom: "1em" }}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "1em",
                  }}
                >
                  <Typography variant="h6" color="initial">
                    Overall Attendance
                  </Typography>
                  <GraphFilters
                    allMonths={allMonths}
                    lastThreeYears={lastThreeYears}
                  />
                </Box>
                <BarChart dataNew={data} graphHeight={"100%"} />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper
                elevation={3}
                style={{ padding: "2em", marginBottom: "1em" }}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "1em",
                  }}
                >
                  <Typography variant="h6" color="initial">
                    Overall Active Hours
                  </Typography>
                  <GraphFilters
                    allMonths={allMonths}
                    lastThreeYears={lastThreeYears}
                  />
                </Box>
                <BarChart dataNew={data} graphHeight={"100%"} />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper
                elevation={3}
                style={{ padding: "2em", marginBottom: "1em" }}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "1em",
                  }}
                >
                  <Typography variant="h6" color="initial">
                    Average Entry and Exit
                  </Typography>
                  <GraphFilters
                    allMonths={allMonths}
                    lastThreeYears={lastThreeYears}
                  />
                </Box>
                <BarChart dataNew={data} graphHeight={"100%"} />
              </Paper>
            </Grid>
          </Grid>
        </TabPanel> */}
      </Paper>
    </Box>
  );
};

export default EmployeeView;

/* 


 <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "1.6em",
          }}
        >
          <Box style={{ display: "flex" }}>
            <Box
              style={{ borderLeft: "4px solid #140047", marginRight: ".5em" }}
            ></Box>
            <Typography
              variant="h6"
              style={{
                color: "gray",
                fontWeight: "500",
              }}
            >
              Attendance History
            </Typography>
          </Box>
        </Box>

*/

/*
            {allEmployees && (
              <Select
                variant="outlined"
                className={classes.monthBox}
                name="allEmployees"
                renderValue={() => idNameMapper[userId]}
                value={userId}
                onChange={handleEmpChange}
              >
                {allEmployees?.map((curElem, index) => (
                  <MenuItem key={index} value={curElem._id}>
                    {idNameMapper[curElem._id]}
                  </MenuItem>
                ))}
              </Select>
            )} 
**/
