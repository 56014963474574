import { Box, Button, Grid, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Field, Form, Formik, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { updateFamilyAndLanguage } from "../../apis/employees";
import { usePermissionObjectFor } from "../../hooks/permission/usePermissionObjectFor";
import FormValues from "../../models/familyAndLanguageType";
import { stringToBoolean } from "../../utils/functions";
import CheckboxField from "../checkbox-field";
import { FormTextField } from "../FormtextField";
import KeyValueWrapper from "../KeyValueWrapper";
import SnackbarComponent from "../SnackbarComponent";
import RoundedBoxWIthTitle from "./RoundedBoxWIthTitle";
import { useStyles } from "./styles";
import ValidationSchema from "./ValidationSchema";

const userDataProps: FormValues = {
  //boolean values
  hasSpouse: false,
  married: "",
  spouseName: "",
  spouseDependent: "false",
  spouseOccupation: "",
  spouseDOB: "",
  //father
  fatherName: "",
  fatherDependent: "true",
  fatherOccupation: "",
  fatherDOB: "12/09/1976",
  //mother
  motherName: "Jane Thor",
  motherDependent: "false",
  motherOccupation: "SDE 2",
  motherDOB: "12/09/1980",

  //first language
  firstLanguageName: "English",
  firstLanguageSpeak: true,
  firstLanguageRead: true,
  firstLanguageWrite: true,

  //second language
  secondLanguageName: "Hindi",
  secondLanguageSpeak: true,
  secondLanguageRead: true,
  secondLanguageWrite: false,

  //third language
  thirdLanguageName: "Assamese",
  thirdLanguageSpeak: true,
  thirdLanguageRead: false,
  thirdLanguageWrite: false,

  //fourth language
  // fourthLanguageName: "Bengali",
  // fourthLanguageSpeak: true,
  // fourthLanguageRead: false,
  // fourthLanguageWrite: false,
};

const validationSchema = yup
  .object()
  .shape(ValidationSchema.FamilyAndlanguageValidationSchema, []);

//dependent selection options
const dependentSelectOptions = [
  {
    value: "true",
    label: "Yes",
  },
  {
    value: "false",
    label: "No",
  },
];

interface Props {
  employeeId: string;
  familyAndLanguageInitialData: any;
  headerProps?: {};
  loaderStateProps?: boolean;
  calledFromEmployee: boolean;
}

const FamilyAndLanguageEdit: React.FC<Props> = ({
  employeeId,
  familyAndLanguageInitialData,
  headerProps = {
    Authorization: "Bearer ",
  },
  loaderStateProps = true,
  calledFromEmployee,
}) => {
  const classes = useStyles();
  const employeeEndPoint = `/employees/${employeeId}/family-and-language`;
  const onboardingEndPoint = `/on-boarding/family-and-language-details/${employeeId}`;

  //container for label and textbox field
  const KeyValueViewBox = (
    keyParam: string,
    valueParam: string,
    keyName: string,
    editable: boolean
  ): JSX.Element => {
    const classes = useStyles();

    return (
      <Box sx={{ marginBottom: 25 }} style={{ width: "100%" }}>
        {keyParam === "hiddenDiv" ? (
          <div style={{ visibility: "hidden" }}>hidden</div>
        ) : (
          <Typography className={classes.keyLightTypographyStyle}>
            {keyParam}
          </Typography>
        )}
        {isEditableBasicDetails && editable === true ? (
          <Field
            name={keyName}
            testKey="testValue"
            size="small"
            component={FormTextField}
          />
        ) : (
          <Typography className={classes.valueStrongStye}>
            {valueParam}
          </Typography>
        )}
      </Box>
    );
  };

  const [isEditableBasicDetails, setIsEditableBasicDetailsEdit] =
    useState(false);

  //eductaional dummy data

  const [familyandlanguageData, setFamilyandlanguageData] =
    useState(userDataProps);

  //state for snackbar
  const [snackbarState, setSnackbarState] = useState({
    openBooleanProp: false,
    messageProp: "dummy message state",
    statusProp: "info",
  });

  const permission = usePermissionObjectFor("employees");
  const employeeNormalDataUpdatePermission = permission.required([
    "upDate",
    "read",
  ]);

  const setInitialdata = () => {
    const initData = {
      //hasSpouse condition check with value of spouseName or length of spouse field of family and language
      hasSpouse: familyAndLanguageInitialData?.family?.spouse ? true : false,

      married: familyAndLanguageInitialData?.married,
      //spouse
      spouseName: familyAndLanguageInitialData?.family?.spouse?.name
        ? familyAndLanguageInitialData?.family?.spouse?.name
        : "",
      spouseDependent: familyAndLanguageInitialData?.family?.spouse?.dependent
        ? familyAndLanguageInitialData?.family.spouse.dependent
        : "false",
      spouseOccupation: familyAndLanguageInitialData?.family?.spouse?.occupation
        ? familyAndLanguageInitialData?.family.spouse.occupation
        : "",
      spouseDOB: familyAndLanguageInitialData?.family?.spouse?.dateOfBirth
        ? familyAndLanguageInitialData?.family.spouse.dateOfBirth
        : "",
      //father
      fatherName: familyAndLanguageInitialData?.family?.father?.name
        ? familyAndLanguageInitialData?.family.father.name
        : "",
      fatherDependent: familyAndLanguageInitialData?.family?.father?.dependent
        ? familyAndLanguageInitialData?.family.father.dependent
        : "false",
      fatherOccupation: familyAndLanguageInitialData?.family?.father?.occupation
        ? familyAndLanguageInitialData?.family.father.occupation
        : "",
      fatherDOB: familyAndLanguageInitialData?.family?.father?.dateOfBirth
        ? familyAndLanguageInitialData?.family.father.dateOfBirth
        : "",
      //mother
      motherName: familyAndLanguageInitialData?.family?.mother?.name
        ? familyAndLanguageInitialData?.family.mother.name
        : "",
      motherDependent: familyAndLanguageInitialData?.family?.mother?.dependent
        ? familyAndLanguageInitialData?.family.mother.dependent
        : "false",
      motherOccupation: familyAndLanguageInitialData?.family?.mother?.occupation
        ? familyAndLanguageInitialData?.family.mother.occupation
        : "",
      motherDOB: familyAndLanguageInitialData?.family?.mother?.dateOfBirth
        ? familyAndLanguageInitialData?.family.mother.dateOfBirth
        : "",

      //first language
      firstLanguageName: "English",
      firstLanguageSpeak: familyAndLanguageInitialData?.languages[0]?.speak
        ? familyAndLanguageInitialData?.languages[0]?.speak
        : false,
      firstLanguageRead: familyAndLanguageInitialData?.languages[0]?.read
        ? familyAndLanguageInitialData?.languages[0]?.read
        : false,
      firstLanguageWrite: familyAndLanguageInitialData?.languages[0]?.write
        ? familyAndLanguageInitialData?.languages[0]?.write
        : false,

      //second language
      secondLanguageName: "Hindi",
      secondLanguageSpeak: familyAndLanguageInitialData?.languages[1]?.speak
        ? familyAndLanguageInitialData?.languages[1]?.speak
        : false,
      secondLanguageRead: familyAndLanguageInitialData?.languages[1]?.read
        ? familyAndLanguageInitialData?.languages[1]?.read
        : false,
      secondLanguageWrite: familyAndLanguageInitialData?.languages[1]?.write
        ? familyAndLanguageInitialData?.languages[1]?.write
        : false,

      //third language
      thirdLanguageName: "Assamese",
      thirdLanguageSpeak: familyAndLanguageInitialData?.languages[2]?.speak
        ? familyAndLanguageInitialData?.languages[2]?.speak
        : false,
      thirdLanguageRead: familyAndLanguageInitialData?.languages[2]?.read
        ? familyAndLanguageInitialData?.languages[2]?.read
        : false,
      thirdLanguageWrite: familyAndLanguageInitialData?.languages[2]?.write
        ? familyAndLanguageInitialData?.languages[2]?.write
        : false,

      //fourth language
      fourthLanguageName: "Bengali",
      fourthLanguageSpeak: familyAndLanguageInitialData?.languages[3]?.speak
        ? familyAndLanguageInitialData.languages[3]?.speak
        : false,
      fourthLanguageRead: familyAndLanguageInitialData?.languages[3]?.read
        ? familyAndLanguageInitialData.languages[3]?.read
        : false,
      fourthLanguageWrite: familyAndLanguageInitialData?.languages[3]?.write
        ? familyAndLanguageInitialData.languages[3]?.write
        : false,
    };
    setFamilyandlanguageData(initData);
  };

  useEffect(() => {
    setInitialdata();
  }, [familyAndLanguageInitialData]);

  const updateFormdata = async (data: FormValues) => {
    //

    let dataToUpdate: any = {
      family: {
        mother: {
          name: data.motherName,
          dependent: stringToBoolean(data.motherDependent),
          occupation: data.motherOccupation,
          dateOfBirth: data.motherDOB.toString(),
        },
        father: {
          name: data.fatherName,
          dependent: stringToBoolean(data?.fatherDependent),
          occupation: data.fatherOccupation,
          dateOfBirth: data.fatherDOB.toString(),
        },
      },
      languages: [
        {
          languageName: data.firstLanguageName,
          speak: data.firstLanguageSpeak,
          read: data.firstLanguageRead,
          write: data.firstLanguageWrite,
        },
        {
          languageName: data.secondLanguageName,
          speak: data.secondLanguageSpeak,
          read: data.secondLanguageRead,
          write: data.secondLanguageWrite,
        },
        {
          languageName: data.thirdLanguageName,
          speak: data.secondLanguageSpeak,
          read: data.secondLanguageRead,
          write: data.secondLanguageWrite,
        },
        // {
        //   languageName: data.fourthLanguageName,
        //   speak: data.fourthLanguageSpeak,
        //   read: data.fourthLanguageRead,
        //   write: data.fourthLanguageWrite,
        // },
      ],
    };
    //
    if (data.hasSpouse) {
      let addData = {
        name: data.spouseName,
        dependent: stringToBoolean(data.spouseDependent),
        occupation: data.spouseOccupation,
        dateOfBirth: data.spouseDOB.toString(),
      };
      dataToUpdate.family.spouse = addData;
    }

    //

    try {
      await updateFamilyAndLanguage(
        calledFromEmployee ? employeeEndPoint : onboardingEndPoint,
        dataToUpdate
      );
      setFamilyandlanguageData(data);
      setIsEditableBasicDetailsEdit((previous) => !previous);

      //show toast
      setSnackbarState({
        openBooleanProp: true,
        messageProp: "successfully updated",
        statusProp: "success",
      });
    } catch (err: any) {
      //show toast
      setSnackbarState({
        openBooleanProp: true,
        messageProp: "Error : " + err,
        statusProp: "error",
      });
    }
  };

  const handleSubmit = (val: FormValues) => {
    if (isEditableBasicDetails) {
      //
      updateFormdata(val);
    }
  };

  return (
    <Box className={classes.basicDetailsViewMainContainer}>
      <SnackbarComponent snackbarStateProp={snackbarState} />
      {!loaderStateProps ? (
        <Box
          sx={{
            width: "100%",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Formik
          initialValues={familyandlanguageData}
          enableReinitialize
          validationSchema={validationSchema}
          validateOnChange
          onSubmit={(values, formikHelpers) => {
            
            formikHelpers.setSubmitting(false);
            handleSubmit(values);
          }}
        >
          {(formikProps: FormikProps<FormValues>) => (
            <Form noValidate autoComplete="off">
              {/* {console.log("errors", formikProps.errors, formikProps.values)} */}
              <Grid container>
                <Box sx={{ width: "100%" }}>
                  <Grid
                    container
                    xs={12}
                    className={classes.widthHunderWIthInlinePadding}
                  >
                    <RoundedBoxWIthTitle
                      title={"Family"}
                      ChildrenContent={
                        <Grid container>
                          <Grid item xs={12}>
                            {/* <Grid
                              container
                              style={{
                                alignItems: "center",
                                border: "1px solid red",
                              }}
                              spacing={1}
                            >
                              <Grid item>
                                <Typography
                                  className={classes.keyLightTypographyStyle}
                                >
                                  Is Married
                                </Typography>
                              </Grid>
                              <Grid item>
                                <SwitchField
                                  name={"hasSpouse"}
                                  isEditableProps={isEditableBasicDetails}
                                  colorNotCheckedBall={"red"}
                                  colorCheckedBall={"#14213D"}
                                  colorCheckedBg={"blue"}
                                />
                              </Grid>
                            </Grid> */}
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={2}>
                                <Grid container item xs={12} direction="column">
                                  {formikProps.values.married === "Yes" ? (
                                    <KeyValueWrapper
                                      label={"hiddenDiv"}
                                      valueParam={"Spouse"}
                                      name={"spouseName"}
                                      inputType={"textField"}
                                      isEditable={false}
                                    />
                                  ) : (
                                    <></>
                                  )}

                                  <KeyValueWrapper
                                    label={"hiddenDiv"}
                                    valueParam={"Father"}
                                    name={"spouseName"}
                                    inputType={"textField"}
                                    isEditable={false}
                                  />
                                  <KeyValueWrapper
                                    label={"hiddenDiv"}
                                    valueParam={"Mother"}
                                    name={"spouseName"}
                                    inputType={"textField"}
                                    isEditable={false}
                                  />
                                </Grid>
                              </Grid>
                              <Grid item xs={3}>
                                <Grid container item xs={12} direction="column">
                                  {formikProps.values.married === "Yes" ? (
                                    <KeyValueWrapper
                                      label={"Name"}
                                      valueParam={formikProps.values.spouseName}
                                      name={"spouseName"}
                                      inputType={"textField"}
                                      isEditable={isEditableBasicDetails}
                                    />
                                  ) : (
                                    <></>
                                  )}

                                  <KeyValueWrapper
                                    label={"Name"}
                                    valueParam={formikProps.values.fatherName}
                                    name={"fatherName"}
                                    inputType={"textField"}
                                    isEditable={isEditableBasicDetails}
                                  />

                                  <KeyValueWrapper
                                    label={"Name"}
                                    valueParam={formikProps.values.motherName}
                                    name={"motherName"}
                                    inputType={"textField"}
                                    isEditable={isEditableBasicDetails}
                                  />
                                </Grid>
                              </Grid>
                              <Grid item xs={2}>
                                <Grid container item xs={12} direction="column">
                                  {formikProps.values.married === "Yes" ? (
                                    <KeyValueWrapper
                                      label={"Dependent"}
                                      valueParam={
                                        formikProps.values.spouseDependent
                                      }
                                      name={"spouseDependent"}
                                      inputType={"selectField"}
                                      isEditable={isEditableBasicDetails}
                                      data={dependentSelectOptions}
                                      booleanDisplayvalue={["Yes", "No"]}
                                    />
                                  ) : (
                                    <></>
                                  )}

                                  <KeyValueWrapper
                                    label={"Dependent"}
                                    valueParam={
                                      formikProps.values.fatherDependent
                                    }
                                    name={"fatherDependent"}
                                    inputType={"selectField"}
                                    isEditable={isEditableBasicDetails}
                                    data={dependentSelectOptions}
                                    booleanDisplayvalue={["Yes", "No"]}
                                  />

                                  <KeyValueWrapper
                                    label={"Dependent"}
                                    valueParam={
                                      formikProps.values.motherDependent
                                    }
                                    name={"motherDependent"}
                                    inputType={"selectField"}
                                    isEditable={isEditableBasicDetails}
                                    data={dependentSelectOptions}
                                    booleanDisplayvalue={["Yes", "No"]}
                                  />
                                </Grid>
                              </Grid>
                              <Grid item xs={2}>
                                <Grid container item xs={12} direction="column">
                                  {formikProps.values.married === "Yes" ? (
                                    <KeyValueWrapper
                                      label={"Occupation"}
                                      valueParam={
                                        formikProps.values.spouseOccupation
                                      }
                                      name={"spouseOccupation"}
                                      inputType={"textField"}
                                      isEditable={isEditableBasicDetails}
                                    />
                                  ) : (
                                    <></>
                                  )}

                                  <KeyValueWrapper
                                    label={"Occupation"}
                                    valueParam={
                                      formikProps.values.fatherOccupation
                                    }
                                    name={"fatherOccupation"}
                                    inputType={"textField"}
                                    isEditable={isEditableBasicDetails}
                                  />

                                  <KeyValueWrapper
                                    label={"Occupation"}
                                    valueParam={
                                      formikProps.values.motherOccupation
                                    }
                                    name={"motherOccupation"}
                                    inputType={"textField"}
                                    isEditable={isEditableBasicDetails}
                                  />
                                </Grid>
                              </Grid>
                              <Grid item xs={3}>
                                <Grid container item xs={12} direction="column">
                                  {formikProps.values.married === "Yes" ? (
                                    // <KeyValueWrapper
                                    //   label={"Date of Birth"}
                                    //   valueParam={formikProps.values.spouseDOB}
                                    //   name={"spouseDOB"}
                                    //   inputType={"datePicker"}
                                    //   isEditable={isEditableBasicDetails}
                                    // />

                                    <KeyValueWrapper
                                      label={"Date of Birth"}
                                      valueParam={
                                        formikProps?.values?.spouseDOB
                                      }
                                      name={"spouseDOB"}
                                      inputType={"onBoardingDatePicker"}
                                      isEditable={isEditableBasicDetails}
                                      formikProps={formikProps}
                                      // errorParam={formikProps.errors?.spouseDOB}
                                      // errorParamKey={"spouseDOB"}
                                    />
                                  ) : (
                                    <></>
                                  )}

                                  {/* <KeyValueWrapper
                                    label={"Date of Birth"}
                                    valueParam={formikProps.values.fatherDOB}
                                    name={"fatherDOB"}
                                    inputType={"datePicker"}
                                    isEditable={isEditableBasicDetails}
                                  /> */}

                                  <KeyValueWrapper
                                    label={"Date of Birth"}
                                    valueParam={formikProps?.values?.fatherDOB}
                                    name={"fatherDOB"}
                                    inputType={"onBoardingDatePicker"}
                                    isEditable={isEditableBasicDetails}
                                    formikProps={formikProps}
                                    // errorParam={formikProps.errors?.fatherDOB}
                                    // errorParamKey={"fatherDOB"}
                                  />

                                  {/* <KeyValueWrapper
                                    label={"Date of Birth"}
                                    valueParam={formikProps.values.motherDOB}
                                    name={"motherDOB"}
                                    inputType={"datePicker"}
                                    isEditable={isEditableBasicDetails}
                                  /> */}

                                  <KeyValueWrapper
                                    label={"Date of Birth"}
                                    valueParam={formikProps?.values?.motherDOB}
                                    name={"motherDOB"}
                                    inputType={"onBoardingDatePicker"}
                                    isEditable={isEditableBasicDetails}
                                    formikProps={formikProps}
                                    // errorParam={formikProps.errors?.motherDOB}
                                    // errorParamKey={"motherDOB"}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      }
                    />
                    <RoundedBoxWIthTitle
                      title={"Language"}
                      ChildrenContent={
                        <Grid container>
                          <Grid container item xs={6} direction="row">
                            <Grid item xs={4}>
                              {KeyValueViewBox(
                                "Language",
                                formikProps.values.firstLanguageName,
                                "firstLanguageName",
                                false
                              )}
                            </Grid>
                            <Grid container item xs={8}>
                              <Grid item>
                                <CheckboxField
                                  name={"firstLanguageSpeak"}
                                  isEditableProps={isEditableBasicDetails}
                                  label={"Speak"}
                                />
                                {/* <KeyValueCheckBox name="firstLanguageSpeak" />
                              speak */}
                              </Grid>
                              <Grid item>
                                <CheckboxField
                                  name={"firstLanguageRead"}
                                  isEditableProps={isEditableBasicDetails}
                                  label={"Read"}
                                />
                                {/* <KeyValueCheckBox name="firstLanguageRead" />
                              Read */}
                              </Grid>
                              <Grid item>
                                <CheckboxField
                                  name={"firstLanguageWrite"}
                                  isEditableProps={isEditableBasicDetails}
                                  label={"Write"}
                                />
                                {/* <KeyValueCheckBox name="firstLanguageWrite" />
                              Write */}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid container item xs={6} direction="row">
                            <Grid item xs={4}>
                              {KeyValueViewBox(
                                "Language",
                                formikProps.values.secondLanguageName,
                                "secondLanguageName",
                                false
                              )}
                            </Grid>
                            <Grid container item xs={8}>
                              <Grid item>
                                <CheckboxField
                                  name={"secondLanguageSpeak"}
                                  isEditableProps={isEditableBasicDetails}
                                  label={"Speak"}
                                />
                              </Grid>
                              <Grid item>
                                <CheckboxField
                                  name={"secondLanguageRead"}
                                  isEditableProps={isEditableBasicDetails}
                                  label={"Read"}
                                />
                                {/* <KeyValueCheckBox name="secondLanguageRead" />
                              Read */}
                              </Grid>
                              <Grid item>
                                <CheckboxField
                                  name={"secondLanguageWrite"}
                                  isEditableProps={isEditableBasicDetails}
                                  label={"Write"}
                                />

                                {/* <KeyValueCheckBox name="secondLanguageWrite" /> */}
                                {/* Write */}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid container item xs={6} direction="row">
                            <Grid item xs={4}>
                              {KeyValueViewBox(
                                "Language",
                                formikProps.values.thirdLanguageName,
                                "thirdLanguageName",
                                false
                              )}
                            </Grid>
                            <Grid container item xs={8}>
                              <Grid item>
                                <CheckboxField
                                  name={"thirdLanguageSpeak"}
                                  isEditableProps={isEditableBasicDetails}
                                  label={"Speak"}
                                />
                                {/* <KeyValueCheckBox name="thirdLanguageSpeak" />
                              speak */}
                              </Grid>
                              <Grid item>
                                <CheckboxField
                                  name={"thirdLanguageRead"}
                                  isEditableProps={isEditableBasicDetails}
                                  label={"Read"}
                                />
                                {/* <KeyValueCheckBox name="thirdLanguageRead" />
                              Read */}
                              </Grid>
                              <Grid item>
                                <CheckboxField
                                  name={"thirdLanguageWrite"}
                                  isEditableProps={isEditableBasicDetails}
                                  label={"Write"}
                                />
                                {/* <KeyValueCheckBox name="thirdLanguageWrite" />
                              Write */}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      }
                    />
                  </Grid>
                </Box>
                {employeeNormalDataUpdatePermission && (
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    sx={{ width: "100%" }}
                  >
                    {isEditableBasicDetails ? (
                      <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        // onClick={() => setIsEditableBasicDetailsEdit(false)}
                        disabled={formikProps.isSubmitting}
                      >
                        Save
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          //
                          setIsEditableBasicDetailsEdit(true);
                        }}
                      >
                        Edit
                      </Button>
                    )}
                  </Box>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};

export default FamilyAndLanguageEdit;
