import { AxiosResponse } from "axios";
import { SetterOrUpdater } from "recoil";
import { handleChecklistExpansion } from "../components/Forms/OnBoardingForm/utils";
import { MODULES } from "../utils/constants";
import axios from "./axios.global";

// type
type TEmployeeInfoSubmit = {
  payload: {
    employeeNo: number;
    department: string;
    designation: string;
    status: boolean;
    role: string;
    assignedTo: string;
  };
  onboardingId: string;
  showToast: {
    error: (message: string) => void;
    success: (message: string) => void;
    warning: (message: string) => void;
  };
  setIsViewEnabled: SetterOrUpdater<{
    basicDetailsViewEnabled: boolean;
    educationViewEnabled: boolean;
    workExperienceViewEnabled: boolean;
    familyAndLanguageViewEnabled: boolean;
    bankDetailsViewEnabled: boolean;
    employeeInformationViewEnabled: boolean;
  }>;
  setOnboardingIds: SetterOrUpdater<{
    eduId: string;
    workExp: string;
    FamLang: string;
    BankDetails: string;
    EmployeeInfo: string;
  }>;
};

// GET METHODS

export const getAllUser = async (pageNo?: number) => {
  let response = await axios.get(`/on-boarding?page=${pageNo}`);
  return response;
};

export const getAllUserByName = async (name: string) => {
  try {
    let response = await axios.get(`/on-boarding?search=${name}`);
    return response;
  } catch (err: any) {
    return err.response.data;
  }
};
export const getOnbUser = async (onbid: string) => {
  try {
    let resp = await axios.get(`/api/on-boarding/${onbid}`);
    return resp;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getRecruiteeInfo = async (onboardingId: string) => {
  try {
    let response = await axios.get(
      `/on-boarding/get-basic-details/${onboardingId}`
    );

    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getEducationInfo = async (onboardingId: string) => {
  try {
    let response = await axios.get(
      `/on-boarding/get-education-details/${onboardingId}`
    );
    if (response.status === 404) {
      return [];
    } else {
      return response.data;
    }
  } catch (err: any) {
    return err.response.data;
  }
};

export const getWorkExpInfo = async (onboardingId: string) => {
  try {
    let response = await axios.get(
      `/on-boarding/get-work-experience/${onboardingId}`
    );
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getFamNLangInfo = async (onboardingId: string) => {
  try {
    let response = await axios.get(
      `/on-boarding/get-family-and-language/${onboardingId}`
    );
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getBankInfo = async (onboardingId: string) => {
  try {
    let response = await axios.get(
      `/on-boarding/get-bank-details/${onboardingId}`
    );
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getEmployeeInfo = async (onboardingId: string) => {
  try {
    let response = await axios.get(
      `/on-boarding/get-employee-information/${onboardingId}`
    );
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};

export const getUploadedDocsApi = async (onboardingId?: string) => {
  const role = window.sessionStorage.getItem("userRole");
  const token = window.sessionStorage.getItem("access_token");

  if (role === "temp-user") {
    try {
      const response = await axios.get(
        `/on-boarding/temp-get-uploaded-documents`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      return response;
    } catch (err: any) {
      return err.response.data;
    }
  } else {
    try {
      const response = await axios.get(
        `/on-boarding/get-uploaded-documents/${onboardingId}`
      );

      return response;
    } catch (err: any) {
      return err.response.data;
    }
  }
};

export const getCheckedTasks = async (onboardingId: string) => {
  try {
    let response = await axios.get(
      `/on-boarding/checklist/${onboardingId}/tasks`
    );
    return response;
  } catch (err: any) {
    return err.response.data;
  }
};

// POST METHOD(S)

export const updateUserStatus = async (onboardId: string, status: string) => {
  try {
    let response = await axios.patch(
      `on-boarding/update-status/${onboardId}?status=${status}`
    );
    return response;
  } catch (err: any) {
    return err.response.data;
  }
};

export const handleBasicDetailsFormSubmit = async (payload: any) => {
  try {
    let response = await axios.patch(`/on-boarding/onBoardUser`, payload);

    return response;
  } catch (err: any) {
    return err.response.data;
  }
};

// PATCH METHODS
export const submitAvatar = async (
  onboardingId: string,
  formData: FormData
) => {
  try {
    let response = await axios.patch(
      `/on-boarding/upload-avatar/${onboardingId}`,
      formData,
      {
        headers: {
          "Content-type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (err: any) {
    return err.response.data;
  }
};

export const handleBankDetailsSubmit = async (
  payload: {
    bankName: string;
    accountNo: string;
    IFSC: string;
  },
  onboardingId: string
) => {
  try {
    let response = await axios.patch(
      `/on-boarding/bank-details/${onboardingId}`,
      payload
    );
    return response;
  } catch (err: any) {
    return err.response.data;
  }
};

export const handleEmployeeInfoSubmit = async ({
  onboardingId,
  payload,
  setIsViewEnabled,
  setOnboardingIds,
  showToast,
}: TEmployeeInfoSubmit) => {
  const newPayload = {
    employeeNo: +payload.employeeNo,
    department: payload.department,
    designation: payload.designation,
    status: payload.status,
    role: payload.role,
    assignedTo: payload.assignedTo,
  };

  try {
    let response = await axios.patch(
      `/on-boarding/employeeInformation/${onboardingId}`,
      newPayload
    );

    if (response.status === 200) {
      showToast.success("Information Saved!");

      setOnboardingIds((prev) => ({
        ...prev,
        EmployeeInfo: response.data._id,
      }));
      setIsViewEnabled((prevState) => ({
        ...prevState,
        employeeInformationViewEnabled: true,
      }));
    }
    return response;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getDocuments = async (
  url: string,
  parentKey: string,
  imageKey?: string
) => {
  try {
    const response = await axios.get(url, {
      params: {
        parentKey,
        imageKey,
      },
    });
    return response;
  } catch (err: any) {
    return err;
  }
};

export const saveDocument = async (url: string, formData: FormData) => {
  try {
    const response = await axios.patch(url, formData, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const handleCompanyEmailSubmit = async (payload: {
  emailID: string;
  name: string;
}) => {
  try {
    let response = await axios.post(
      `/on-boarding/send-companyEmail-mail`,
      payload
    );
    return response;
  } catch (err: any) {
    return err.response.data;
  }
};

export const handleCheckListSubmit = async (
  payload: {
    item: string;
    checked: boolean;
  }[],
  onboardingId: string
) => {
  try {
    const response: any = await axios.patch(
      `/on-boarding/checklist/tasks/${onboardingId}`,
      payload
    );

    return response;
  } catch (err: any) {
    return err.response.data;
  }
};

export const deleteRecruiteeAPI = async (onboardingId: string) => {
  try {
    let response = await axios.delete(`/on-boarding/del/${onboardingId}`);
    return response;
  } catch (err: any) {
    return err.response.data;
  }
};

export const deleteAllRecruiteeAPI = async () => {
  try {
    let response = await axios.delete(`/on-boarding/delete-ALL-user/demoAPI`);
    return response;
  } catch (err: any) {
    return err.response.data;
  }
};

export const assetAllocationInfoSubmit = async (
  payload: {
    assetID: string;
    assetName: string;
  }[],
  onboardingId: string
) => {
  try {
    const response = await axios.patch(
      `/on-boarding/assets-allocation/${onboardingId}`,
      payload
    );
    return response;
  } catch (err: any) {
    return err.response.data;
  }
};

export const assetAllocationGetInfo = async (onboardingId: string) => {
  try {
    let response = await axios.get(
      `/on-boarding/checklist/${onboardingId}/assetAllocation`
    );
    return response;
  } catch (err: any) {
    return err.response.data;
  }
};

// OTP BASED LOGIN:
export const createOnboardUser = async (
  payload: {
    fullName: string;
    emailID: string;
    mobileNumber: string;
    designation: string;
  },
  setOnBoardUserId: React.Dispatch<React.SetStateAction<string>>,
  showToast: any,
  setIsId: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    let response = await axios.post(
      `on-boarding/create-basic-details`,
      payload
    );
    if (response.status === 201) {
      showToast.success("USER CREATED!");
      setOnBoardUserId(response.data._id);
      setIsId(true);
    } else {
      showToast.error("Something went wrong!");
    }
    return response;
  } catch (err: any) {
    showToast.error(err.response.data.message);
    return err;
  }
};

export const sendInviteOTP = async (onboardingId: string) => {
  try {
    let response = await axios.get(`/on-boarding/send-invite/${onboardingId}`);
    return response;
  } catch (err: any) {
    return err.response.data;
  }
};

export const verifyOTP = async (payloadInfo: {
  emailID: string;
  otp: string;
}) => {
  try {
    let response = await axios.post("/on-boarding/verify-otp", payloadInfo);
    return response;
  } catch (err: any) {
    return err.response.data;
  }
};

export const regenerateOtp = async (email: string) => {
  try {
    let response = await axios.post(`/on-boarding/generate-otp`, email);
    return response;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getOnboardUser = async (access_token: string) => {
  try {
    let response = await axios.get(`/on-boarding/get-user-info`, {
      headers: {
        Authorization: "Bearer " + access_token,
      },
    });
    return response;
  } catch (err: any) {
    return err.response.data;
  }
};

export const setUserDone = async (status: boolean, access_token: string) => {
  try {
    let response = await axios.put(
      `/on-boarding/set-user-done?isDone=${status}`,
      "",
      {
        headers: { Authorization: "Bearer " + access_token },
      }
    );

    return response;
  } catch (err: any) {
    return err.response.data;
  }
};

export const getPassportPhotoAPI = async (
  userId: string,
  parentKey: string,
  imageKey: string
) => {
  return await axios.get(
    `${MODULES.ONBOARDING}/get-uploaded-documents/${userId}`,
    {
      params: {
        parentKey,
        imageKey,
      },
    }
  );
  // return await axios.get(`${MODULES.ONBOARDING}/get-uploaded-documents/${userId}?parentKey=${parentKey}&imageKey=${imageKey}`)
};

export const saveEmpInfoAPI = async (
  onbID: string,
  payload: {
    employeeNo: number;
    department: string;
    designation: string;
    status: boolean;
    role: string;
    assignedTo: string;
  }
) => {
  return await axios.patch(
    `/on-boarding/employeeInformation/${onbID}`,
    payload
  );
};

export const migrateToEmployeeAPI = async (onbID: string) => {
  return await axios.get(`/on-boarding/migrate-to-employees?id=${onbID}`);
};

//getCompanyEmail
export const getCompanyEmailAPI = async (onbId: string) => {
  return await axios.get(`/on-boarding/get-company-email/${onbId}`);
};

export const setCompanyEmailAPI = async (
  onbID: string,
  companyEmail: string
) => {
  // let payload = companyEmail;
  return await axios.post(`/on-boarding/set-company-email/${onbID}`, {
    companyEmail,
  });
};
