import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";

import profile from "../../../../../assets/images/profileBG.svg";
import bx_upload from "../../../../../assets/icons/bx_upload.svg";

import { useStyles } from "./styles";
import { submitAvatar } from "../../../../../apis/onboarding";
import { profileAvatarAtom } from "../../../../../recoil/atoms";
import { useRecoilState } from "recoil";
import { ChangeEvent } from "react";
import moment from "moment";

interface DetailsEditProps {
  formik: any;
  setFieldValue: any;
  showToast: any;
}

const DetailsEdit: React.FC<DetailsEditProps> = ({
  formik,
  showToast,
  setFieldValue,
}) => {
  const classes = useStyles();
  const [avatarImage, setAvatarImage] = useRecoilState(profileAvatarAtom);

  // const handleProfileChange = async (event: any) => {
  //   if (
  //     (event.target.files[0].type == "image/jpeg" ||
  //       event.target.files[0].type == "image/png") &&
  //     event.target.files[0].size <= 1048576
  //   ) {
  //     const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
  //     if (!allowedExtensions.exec(event.target.value)) {
  //       showToast.error("SELECT ONLY JPG OR PNG IMAGES!");
  //       return;
  //     } else {
  //       const uploadFile = event.target.files as FileList;
  //       const file = uploadFile[0];
  //       const profileAvatar = new FormData();
  //       profileAvatar.append("profile-image", file);
  //       submitAvatar(formik.values.id, profileAvatar).then((res) => {
  //         if (res.status === 200) {
  //           showToast.success("Profile Image Saved!");
  //           setAvatarImage({ profileImage: URL.createObjectURL(file) });
  //           return;
  //         } else {
  //           showToast.error("COULDN'T UPLOAD IMAGE !");
  //           setAvatarImage({ profileImage: "" });
  //           return;
  //         }
  //       });
  //     }
  //   } else {
  //     showToast.error("SELECT ONLY JPG OR PNG IMAGES WITH MAXIXMUM SIZE 1MB");
  //   }
  // };

  function handleChangeDate(dateData: string) {
    // console.log("dateee", new Date(dateData).toISOString());
    if (dateData) {
      setFieldValue("birthDate", new Date(dateData).toISOString());
    }
  }

  return (
    <>
      <Grid container direction="row" spacing={2}>
        <Grid item md={8}>
          <Grid container direction="row" spacing={5} justifyContent="center">
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                id="name"
                name="name"
                label="Full Name"
                value={formik?.values?.name}
                onChange={formik?.handleChange}
                className={classes.formControlsWidth}
              />
              <span className={classes.errorMessage}>
                {formik?.errors?.name}
              </span>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                id="emailID"
                name="emailID"
                label="Email Address"
                value={formik?.values?.emailID}
                onChange={formik?.handleChange}
                className={classes.formControlsWidth}
              />
              <span className={classes.errorMessage}>
                {formik?.errors?.emailID}
              </span>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                id="mobileNo"
                name="mobileNo"
                label="Phone No."
                value={formik?.values?.mobileNo}
                onChange={formik?.handleChange}
                className={classes.formControlsWidth}
              />
              <span className={classes.errorMessage}>
                {formik?.errors?.mobileNo}
              </span>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl className={classes.formControlsWidth}>
                <InputLabel id="gender">Gender</InputLabel>
                <Select
                  labelId="gender"
                  id="gender"
                  name="gender"
                  value={formik?.values?.gender}
                  onChange={formik?.handleChange}
                >
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
                <span className={classes.errorMessage}>
                  {formik?.errors?.gender}
                </span>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                id="birthDate"
                name="birthDate"
                label="Date of Birth"
                type="date"
                value={moment(new Date(formik.values.birthDate)).format(
                  "YYYY-MM-DD"
                )}
                style={{ width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChangeDate(e.target.value)}
                InputProps={{
                  inputProps: {
                    max: moment(new Date().toISOString()).format("YYYY-MM-DD"),
                  },
                }}
              />
              <span className={classes.errorMessage}>
                {formik?.errors?.birthDate}
              </span>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                id="age"
                name="age"
                label="Age (Years)"
                InputProps={{
                  readOnly: true,
                }}
                value={formik?.values?.age ? formik?.values?.age : "0"}
                onChange={formik?.handleChange}
                className={classes.formControlsWidth}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={4}
          direction="column"
          className={classes.uploadImgContainer}
        >
          <Grid item>
            <img
              src={
                avatarImage.profileImage === ""
                  ? profile
                  : avatarImage.profileImage
              }
              className={classes.profileImage}
            />
            {/* <Button
              className={classes.uploadImgButton}
              variant="contained"
              component="label"
            >
              <img src={bx_upload} width="20" height="20" />
              <input
                type="file"
                hidden
                name="profileImage"
                onChange={(event) => handleProfileChange(event)}
              />
            </Button> */}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        spacing={6}
        className={classes.viewPadding}
      >
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <FormControl className={classes.formControlsWidth6}>
            <InputLabel id="married">Married</InputLabel>
            <Select
              labelId="married"
              id="married"
              name="married"
              value={formik?.values?.married}
              onChange={formik?.handleChange}
            >
              <MenuItem value="Yes">Married</MenuItem>
              <MenuItem value="No">Unmarried</MenuItem>
            </Select>
          </FormControl>
          <span className={classes.errorMessage}>
            {formik?.errors?.married}
          </span>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <FormControl className={classes.formControlsWidth6}>
            <InputLabel id="married">Blood Group</InputLabel>
            <Select
              labelId="bloodGroup"
              id="bloodGroup"
              name="bloodGroup"
              value={formik?.values?.bloodGroup}
              onChange={formik?.handleChange}
            >
              <MenuItem value="A+">A+</MenuItem>
              <MenuItem value="B+">B+</MenuItem>
              <MenuItem value="B-">B-</MenuItem>
              <MenuItem value="O+">O+</MenuItem>
              <MenuItem value="O-">O-</MenuItem>
              <MenuItem value="AB+">AB+</MenuItem>
              <MenuItem value="AB-">AB-</MenuItem>
            </Select>
          </FormControl>

          <span className={classes.errorMessage}>
            {formik?.errors?.bloodGroup}
          </span>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <TextField
            id="idProofs.voterIdNo"
            name="idProofs.voterIdNo"
            label="Voter ID"
            value={formik?.values?.idProofs?.voterIdNo}
            onChange={formik?.handleChange}
            className={classes.formControlsWidthFull}
          />
          <span className={classes.errorMessage}>
            {formik?.errors?.idProofs?.voterIdNo}
          </span>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <TextField
            id="idProofs.aadharNo"
            name="idProofs.aadharNo"
            label="Aadhar Number"
            value={formik?.values?.idProofs?.aadharNo}
            onChange={formik?.handleChange}
            className={classes.formControlsWidthFull}
          />
          <span className={classes.errorMessage}>
            {formik?.errors?.idProofs?.aadharNo}
          </span>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <TextField
            id="idProofs.panNo"
            name="idProofs.panNo"
            label="PAN No."
            value={formik?.values?.idProofs?.panNo}
            onChange={formik?.handleChange}
            className={classes.formControlsWidthFull}
          />
          <span className={classes.errorMessage}>
            {formik?.errors?.idProofs?.panNo}
          </span>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <TextField
            id="idProofs.passportNo"
            name="idProofs.passportNo"
            label="Passport No."
            value={formik?.values?.idProofs?.passportNo}
            onChange={formik?.handleChange}
            className={classes.formControlsWidthFull}
          />
          <span className={classes.errorMessage}>
            {formik?.errors?.idProofs?.passportNo}
          </span>
        </Grid>
      </Grid>
      <Grid container spacing={4} className={classes.viewPadding}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FormControl className={classes.formControlsWidth}>
            <TextField
              id="address.present.addressField"
              name="address.present.addressField"
              label="Present Address"
              value={formik?.values?.address?.present?.addressField}
              onChange={formik?.handleChange}
            />
            <span className={classes.errorMessage}>
              {formik?.errors?.address?.present?.addressField}
            </span>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <TextField
            id="address.present.city"
            name="address.present.city"
            label="City"
            value={formik?.values?.address?.present?.city}
            onChange={formik?.handleChange}
            className={classes.formControlsWidthFull}
          />
          <span className={classes.errorMessage}>
            {formik?.errors?.address?.present?.city}
          </span>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <TextField
            id="address.present.state"
            name="address.present.state"
            label="State"
            value={formik?.values?.address?.present?.state}
            onChange={formik?.handleChange}
            className={classes.formControlsWidthFull}
          />
          <span className={classes.errorMessage}>
            {formik?.errors?.address?.present?.state}
          </span>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <TextField
            id="address.present.pin"
            name="address.present.pin"
            label="PIN Code"
            value={formik?.values?.address?.present?.pin}
            onChange={formik?.handleChange}
            className={classes.formControlsWidthFull}
          />
          <span className={classes.errorMessage}>
            {formik?.errors?.address?.present?.pin}
          </span>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={0}>
        <Grid item className={classes.checkAddressContainer}>
          <Checkbox
            color="primary"
            inputProps={{ "aria-label": "secondary checkbox" }}
            name="isPermanentAddressDifferent"
            value={formik.values.isPermanentAddressDifferent}
            checked={formik.values.isPermanentAddressDifferent}
            onChange={(e) => formik?.handleChange(e)}
          />
        </Grid>
        <Grid item>
          <p className={classes.checkText}>
            Permanent Address different from above
          </p>
        </Grid>
      </Grid>
      {formik?.values?.isPermanentAddressDifferent ? (
        <>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <FormControl className={classes.formControlsWidth}>
                <TextField
                  fullWidth
                  id="address.permanent.addressField"
                  name="address.permanent.addressField"
                  label="Permanent Address"
                  value={formik?.values?.address?.permanent?.addressField}
                  onChange={formik?.handleChange}
                />
                <span className={classes.errorMessage}>
                  {formik?.errors?.address?.permanent?.addressField}
                </span>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={6} style={{ marginBottom: "2em" }}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <TextField
                fullWidth
                id="address.permanent.city"
                name="address.permanent.city"
                label="City"
                value={formik?.values?.address?.permanent?.city}
                onChange={formik?.handleChange}
              />
              <span className={classes.errorMessage}>
                {formik?.errors?.address?.permanent?.city}
              </span>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <TextField
                fullWidth
                id="address.permanent.state"
                name="address.permanent.state"
                label="State"
                value={formik?.values?.address?.permanent?.state}
                onChange={formik?.handleChange}
              />
              <span className={classes.errorMessage}>
                {formik?.errors?.address?.permanent?.state}
              </span>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <TextField
                fullWidth
                id="address.permanent.pin"
                name="address.permanent.pin"
                label="PIN Code"
                value={formik?.values?.address?.permanent?.pin}
                onChange={formik?.handleChange}
              />
              <span className={classes.errorMessage}>
                {formik?.errors?.address?.permanent?.pin}
              </span>
            </Grid>
          </Grid>
        </>
      ) : (
        <></>
      )}
      <Grid container justifyContent="flex-start" spacing={6}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <span className={classes.emergencyHead}>
            Emergency Contact Details
          </span>
          <br />
          <span className={classes.emergencyContent}>
            Whom we can contact in case of emergency
          </span>
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <>
            <TextField
              id="emergencyContact.name"
              name="emergencyContact.name"
              label="Name"
              value={formik?.values?.emergencyContact?.name}
              onChange={formik?.handleChange}
              className={classes.formControlsWidthFull}
            />
            <span className={classes.errorMessage}>
              {formik?.errors?.emergencyContact?.name}
            </span>
          </>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <TextField
            id="emergencyContact.relationship"
            name="emergencyContact.relationship"
            label="Relationship"
            value={formik?.values?.emergencyContact?.relationship}
            onChange={formik?.handleChange}
            className={classes.formControlsWidthFull}
          />
          <span className={classes.errorMessage}>
            {formik?.errors?.emergencyContact?.relationship}
          </span>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <TextField
            id="emergencyContact.contactNo"
            name="emergencyContact.contactNo"
            label="Phone No."
            value={formik?.values?.emergencyContact?.contactNo}
            onChange={formik?.handleChange}
            className={classes.formControlsWidthFull}
          />
          <span className={classes.errorMessage}>
            {formik?.errors?.emergencyContact?.contactNo}
          </span>
        </Grid>
      </Grid>
    </>
  );
};

export default DetailsEdit;
