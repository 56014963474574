import { atom } from "recoil";
import { filterEmployeePageTableDataValue } from "../utils/constants";

import { assetSchema } from "../schema/assetAllocationSchema";
import {
  checkDocumentSelection,
  checkIdDocumentSelection,
  checkWorkDocumentSelection,
  employeeInfo,
  assetAllocationfreshers,
  assetAllocationexperience,
} from "../schema/OnBoardingFormSchema";
import { OnboardingIdSchema } from "../schema/onboardingIDs";
import {
  checklistSteps,
  companyEmailSteps,
  recruiteeDetailsSteps,
  recruiteeDetailsStepsTemp,
  uploadDocumentsSteps,
  uploadDocumentsStepsTemp,
} from "../schema/onboardingSteps";

const rolesData = {
  attendance: {},
  department: {},
  designation: {},
  leave: {},
  policies: {},
  role: {},
  user: {},
  _id: "",
  roleName: "",
  projects: {},
  incidents: {},
  contentBasket: {},
};

const IdProofsDto = {
  aadharNo: "",
  panNo: "",
  voterIdNo: "",
  passportNo: "",
};

const AddressFieldsDto = {
  city: "",
  state: "",
  pin: "",
  addressField: "",
};

const AddressTypesDto = {
  present: AddressFieldsDto,
  permanent: AddressFieldsDto,
};

const EmergencyContactDetails = {
  name: "",
  relationship: "",
  contactNo: "",
};

export const basicDetailsData = {
  id: "",
  name: "",
  age: "",
  gender: "",
  married: "",
  bloodGroup: "",
  birthDate: "",
  emailID: "",
  idProofs: IdProofsDto,
  mobileNo: "",
  address: AddressTypesDto,
  isPermanentAddressDifferent: false,
  emergencyContact: EmergencyContactDetails,
};

const EducationDetails = {
  class10: {
    institution: "",
    yearOfPassing: "",
    subjects: "",
    percentage: "",
  },
  class12: {
    institution: "",
    yearOfPassing: "",
    subjects: "",
    percentage: "",
  },
  graduation: {
    institution: "",
    yearOfPassing: "",
    subjects: "",
    percentage: "",
  },
  postGraduation: {
    institution: "",
    yearOfPassing: "",
    subjects: "",
    percentage: "",
  },
};

const bankDetials = {
  bankName: "",
  accountNo: "",
  IFSC: "",
};

export const recruiteeBasicDetails = atom({
  key: "recruiteeBasicDetails",
  default: basicDetailsData,
});

export const profileAvatarAtom = atom({
  key: "profileAvatarAtom",
  default: { profileImage: "" },
});

export const educationFormAtom = atom({
  key: "educationForm",
  default: EducationDetails,
});

export const bankDetailsAtom = atom({
  key: "bankDetailsForm",
  default: bankDetials,
});

export const employeeInformation = atom({
  key: "employeeInformation",
  default: employeeInfo,
});
const isRecruiteeDetailsViewEnabled = {
  basicDetailsViewEnabled: true,
  educationViewEnabled: true,
  workExperienceViewEnabled: true,
  familyAndLanguageViewEnabled: true,
  bankDetailsViewEnabled: true,
  employeeInformationViewEnabled: true,
};
export const recruiteeDetailsViewEnabled = atom({
  key: "recruiteeDetailsViewEnabled",
  default: isRecruiteeDetailsViewEnabled,
});
const isRecruiteeEditEnabled = {
  basicDetailsEdit: false,
  educationEdit: false,
  workExperienceEdit: false,
  familyAndLanguageEdit: false,
  bankDetailsEdit: false,
  employeeInformationEdit: false,
};

export interface Projects {
  _id: string;
  projectName: string;
  projectType: string;
  clientName: string;
  clientEmail: string;
  projectStatus: string;
  projectManager: string;
  startdate: string;
  orientation: string;
}

const Projects: Projects[] = [];

export interface ProjectFormData {
  projectName: string;
  clientCompanyName: string;
  clientContact: string;
  clientEmail: string;
  projectManager: string;
  startDate: string;
  projectType: string;
}
const ProjectFormData: ProjectFormData[] = [];
export interface Role {
  value: string;
  label: string;
}
export interface empInfo {
  empNo: number;
  department: string;
  assignedTo: string;
  empStatus: boolean;
  designation: string;
  role: Role[];
}
const EmpInf: empInfo = {
  empNo: 0,
  department: "Not Selected",
  assignedTo: "Not Selected",
  empStatus: false,
  designation: "",
  role: [],
};

export const EmployeeInformation = atom({
  key: "EmployeeInformation",
  default: EmpInf,
});

export const recruiteeEditEnabled = atom({
  key: "recruiteeEditEnabled",
  default: isRecruiteeEditEnabled,
});

const uploadsViewEnabled = {
  photos: false,
  // offerLetter: false,
  identity: false,
  academic: false,
  workExp: false,
};
export const isUploadsViewEnabled = atom({
  key: "isUploadsViewEnabled",
  default: uploadsViewEnabled,
});
export const assetAllocationAtom = atom({
  key: "assetAllocationAtom",
  default: [assetSchema],
});
export const assetAllocationFresher = atom({
  key: "assetAllocationFresher ",
  default: assetAllocationfreshers,
});
export const assetAllocationExperience = atom({
  key: "assetAllocationExperience",
  default: assetAllocationexperience,
});
const isassetAllocationDetailsViewEnabled = {
  assetAllocationViewEnabled: true,
};
export const assetAllocationDetailsViewEnabled = atom({
  key: "assetAllocationDetailsViewEnabled",
  default: isassetAllocationDetailsViewEnabled,
});
const isAssetAllocationEditEnabled = {
  assetAllocationDetailsEdit: false,
};
export const AssetAllocationEditEnabled = atom({
  key: "AssetAllocationEditEnabled ",
  default: isAssetAllocationEditEnabled,
});

export const checkAcademicDocumentSelectionAtom = atom({
  key: "checkAcademicDocumentSelectionAtom",
  default: checkDocumentSelection,
});
// work
export const checkWorkDocumentSelectionAtom = atom({
  key: "checkWorkDocumentSelectionAtom",
  default: checkWorkDocumentSelection,
});
// id
export const checkIdDocumentSelectionAtom = atom({
  key: "checkIdDocumentSelectionAtom",
  default: checkIdDocumentSelection,
});
export const onboardingAccordionStepsAtom = atom({
  key: "onboardingAccordionStepsAtom",
  default: {
    step: 0,
  },
});
export const circularProgressStrokeAtom = atom({
  key: "circularProgressStrokeAtom",
  default: {
    strokeValue: 0,
  },
});
export const recruiteeDetailsStepsAtom = atom({
  key: "recruiteeDetailsStepsAtom",
  default: recruiteeDetailsSteps,
});
export const uploadDocumentsStepsAtom = atom({
  key: "uploadDocumentsStepsAtom",
  default: uploadDocumentsSteps,
});
export const companyEmailStepsAtom = atom({
  key: "companyEmailStepsAtom",
  default: companyEmailSteps,
});
export const checklistStepsAtom = atom({
  key: "checklistStepsAtom",
  default: checklistSteps,
});
// for temp user
export const recruiteeDetailsStepsTempAtom = atom({
  key: "recruiteeDetailsStepsTempAtom",
  default: recruiteeDetailsStepsTemp,
});
export const uploadDocumentsStepsTempAtom = atom({
  key: "uploadDocumentsStepsTempAtom",
  default: uploadDocumentsStepsTemp,
});
const enableClick = {
  recruiteeDetails: true,
  uploads: true,
  companyEmail: true,
  checklist: true,
};
export const enableClickAtom = atom({
  key: "enableClickAtom",
  default: enableClick,
});
export const onboardingIdAtom = atom({
  key: "onboardingIdAtom",
  default: OnboardingIdSchema,
});

export interface KHubPost {
  // data:{
  _id: string;
  author: any;
  category: string;
  title: string;
  platform: string;
  language: string;
  body: string;
  isDraft: boolean;
  framework: string;
  updatedBy: any;
  createdAt: string;
  updatedAt: string;
  images: string[];
  // },
  fetch: () => Promise<void>;
}

const kHubPost: KHubPost[] = [];

const KHubPostTechs: string[] = [
  "react js",
  "python",
  "javascript",
  "flutter",
  "devops",
  "react native",
  "data engineering",
  "others",
];

const kHubCategory: string = "all";
const kHubQuery: string = "";
export interface Contents {
  Sl_no: number;
  title: string;
  Type: string;
  Contributor: string;
  Date: string;
  File: string[];
  Status: string;
}
const pQuery: string = "";
export const pQueryAtom = atom({
  key: "pQuery",
  default: pQuery,
});
const iQuery: string = "";
export const iQueryAtom = atom({
  key: "iQuery",
  default: iQuery,
});

export interface Resource {
  email: string;
  id: string;
  name: string;
}
const Resource: Resource[] = [];

export const resourceAtom = atom({
  key: "resource",
  default: Resource,
});

export const permissions = atom({
  key: "stateData",
  default: rolesData,
});

export const kHubPostAtom = atom({
  key: "KHubPost",
  default: kHubPost,
});

export const kHubPostTechsAtom = atom({
  key: "KHubPostTechs",
  default: KHubPostTechs,
});

export const kHubCategoryAtom = atom({
  key: "KHubCategory",
  default: kHubCategory,
});

export const kHubQueryAtom = atom({
  key: "KHubQuery",
  default: kHubQuery,
});
export const ProjectsAtom = atom({
  key: "Projects",
  default: Projects,
});

// atom for profile pic,designation and name
export const profileItemsAtom = atom({
  key: "profileItemsAtom",
  default: {
    name: "",
    src: "",
    designation: "",
    assignedTo: "",
    projectAssigned: "",
  },
});

export const employeePageTableAtoms = atom({
  key: "employeePageTableAtoms",
  default: {
    tableData: [],
    search: "",
    filterBy: filterEmployeePageTableDataValue.active,
    currentPage: 1,
    pages: 1,
  },
});
