import { Box, Paper, Typography } from '@material-ui/core';
import React from 'react';
import TVLogo from '../../assets/images/TVLogo.png';
import useStyles from './styles';

interface props {
  children: any;
  text: string;
}

const LoginLayout: React.FC<props> = ({children, text}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box display='flex' alignItems='center' height={'100vh'} >
        <Paper className={classes.paper} variant='elevation' elevation={3}>
          <Typography
            variant='h4'
            component='div'
            align='center'
            color='primary'
          >
            <img src={TVLogo} height='55px' alt='logo' />
          </Typography>
          <Typography
            variant='subtitle2'
            color='primary'
            align='center'
            className={classes.loginText}
          >
            {text}
          </Typography>
            {children}
        </Paper>
      </Box>
    </div>
  );
};

export default LoginLayout;
