import React from "react";
import TextField from "@material-ui/core/TextField";
import { Grid } from "@material-ui/core";
import useStyles from "./styles";
interface IProps {
  type: any;
  variant: any;
  label?: any;
  onChange?: any;
  name?: string;
  value?: string;
  [key: string]: any;
}

const FieldText: React.FC<IProps> = ({ type, ...otherProps }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.textfield}>
      <TextField fullWidth size="small" type={type} {...otherProps} />
    </Grid>
  );
};

export default FieldText;
