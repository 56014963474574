import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dropdown: {
    backgroundColor: "#F7F7F7",
    borderBottom: "none",
    borderRadius: "5px",
    padding: "0 20px",
  },
  form: {
    width: "100%",
  },
  input: {
    "&:focus": {
      backgroundColor: "#F7F7F7",
    },
  },
  label: {
    fontFamily: "Poppins",
    color: "#686868",
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 500,
  },
}));

export default useStyles;
