import {
  Avatar,
  Box,
  Button,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useStyles } from "./Style";
import { Link } from "react-router-dom";
import { getEmployeeListKRA } from "../../../../apis/employees";
import SearchIcon from "@material-ui/icons/Search";
interface IProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
}

interface IRole {
  _id: string;
  roleName: string;
}

interface IUser {
  _id: string;
  name: string;
  email: string;
  empID: number;
  role: IRole[];
}

const KRAModal = ({ setOpen, open }: IProps) => {
  const classes = useStyles();
  const handleClose = () => setOpen(false);
  const [employee, setEmployee] = useState<IUser[] | []>([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    getAllEmployees();
  }, []);

  const getAllEmployees = async () => {
    const res = await getEmployeeListKRA();
    setEmployee(res.data);
  };

  return (
    <>
      <Modal open={open} className={classes.backdrop}>
        <Box className={classes.containerBox}>
          <Box className={classes.contentBox}>
            <Paper className={classes.searchBarContainer}>
              <SearchIcon
                style={{
                  fontSize: "35px",
                  marginLeft: "0.5em",
                }}
              />
              <TextField
                variant="outlined"
                onChange={(e) => setSearch(e.target.value)}
                placeholder={"search employee"}
                InputProps={{
                  classes: {
                    root: classes.searchBarTextField,
                  },
                }}
              />
            </Paper>
            <Box className={classes.userDataContainer}>
              {employee
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .filter((item) => {
                  if (item.name.toLowerCase().includes(search.toLowerCase())) {
                    return item;
                  }
                })
                .map((item) => {
                  return (
                    <>
                      <Link
                        to={`/app/kra/history/${item?._id}`}
                        style={{
                          textDecoration: "none",
                          marginBottom: "1em",
                        }}
                      >
                        <Box className={classes.userDataCard} key={item._id}>
                          <Avatar
                            style={{
                              background: "#140047",
                            }}
                          />
                          <Typography
                            component={"div"}
                            className={classes.optionTextField}
                          >
                            {item.name}
                            <Typography
                              style={{
                                fontSize: "0.8em",
                                color: "grey",
                              }}
                            >
                              empId : {item.empID}
                            </Typography>
                          </Typography>
                        </Box>
                      </Link>
                    </>
                  );
                })}
            </Box>
            <Button
              size="medium"
              style={{
                marginLeft: "auto",
                background: "#140047",
                color: "white",
              }}
              variant="contained"
              onClick={handleClose}
            >
              close
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default KRAModal;
