import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  getDepartment,
  getDesignation,
} from "../../../../../apis/designations_departments";
import { getLeaders, getRolesAPI } from "../../../../../apis/employees";
import {
  getEmployeeInfo,
  handleEmployeeInfoSubmit,
  saveEmpInfoAPI,
} from "../../../../../apis/onboarding";
import {
  employeeInformation,
  enableClickAtom,
  onboardingIdAtom,
  recruiteeBasicDetails,
  recruiteeDetailsStepsTempAtom,
  recruiteeDetailsViewEnabled,
  recruiteeEditEnabled,
} from "../../../../../recoil/atoms";
import { useStyles } from "./styles";
import { setEmployeeInformation } from "./utils";

interface EmployeeInfoProps {
  showToast: any;
  index: number;
}

interface IAllDepartments {
  _id: string;
  departmentName: string;
}

export interface IAllDesignations {
  _id: string;
  designationName: string;
}

interface IAllRoles {
  _id: string;
  roleName: string;
}

interface IAllEmployees {
  id: string;
  leadName: string;
}

interface IEmpIdLabel {
  id: string;
  label: string;
}

interface IEmpInfo {
  employeeNo: number;
  status: boolean;
  department: IEmpIdLabel;
  designation: IEmpIdLabel;
  role: IEmpIdLabel;
  assignedTo: IEmpIdLabel;
}

const EmployeeInformation: React.FC<EmployeeInfoProps> = ({
  showToast,
  index,
}) => {
  const classes = useStyles();
  // const [radioValue, setRadioValue] = useState(false);
  // const [isValid, setIsValid] = useState(false);
  // const [employeeInfo, setEmployeeInfo] =
  //   useRecoilState<IEmployeeInfo>(employeeInformation);
  //   const [isEdit, setIsEdit] = useRecoilState(recruiteeEditEnabled);
  //   const designationRef = useRef<HTMLInputElement>(null);
  //   const [recruiteeDetailsStepsatom, setRecruiteeDetailsStepsatom] =
  //   useRecoilState(recruiteeDetailsStepsTempAtom);
  //   const [isEmpNo, setIsEmpNo] = useState(false);
  //   const [isDept, setIsDept] = useState(false);
  //   const [onboardingIds, setOnboardingIds] = useRecoilState(onboardingIdAtom);
  //   const [toggleUser, setToggleUser] = useState(false);
  //   const [accessToken, setAccessToken] = useState("");
  //   const [userRole, setUserRole] = useState("");

  const getOnboardingId = useRecoilValue(recruiteeBasicDetails); //imp
  const [isViewEnabled, setIsViewEnabled] = useRecoilState(
    recruiteeDetailsViewEnabled
  ); //imp
  const [clickable, setClickable] = useRecoilState(enableClickAtom);

  //new
  const [allDesignations, setAllDesignations] = useState<IAllDesignations[]>(
    []
  );
  const [allDepartments, setAllDepartments] = useState<IAllDepartments[]>([]);
  const [allRoles, setAllRoles] = useState<IAllRoles[]>([]);
  const [allEmployees, setAllEmployees] = useState<IAllEmployees[]>([]);

  const [currentDepartment, setCurrentDepartment] = useState("");
  const [currentDesignation, setCurrentDesignation] = useState("");
  const [currentRole, setCurrentRole] = useState("");
  const [currentAssignedTo, setCurrentAssignedTo] = useState("");
  const [currentEmpNo, setCurrentEmpNo] = useState<number>(0);

  // const [empInfo, setEmpInfo] = useState<IEmpInfo>({
  //   employeeNo: 0,
  //   status: true,
  //   department: {
  //     id: "",
  //     label: "",
  //   },
  //   designation: {
  //     id: "",
  //     label: "",
  //   },
  //   role: {
  //     id: "",
  //     label: "",
  //   },
  //   assignedTo: {
  //     id: "",
  //     label: "",
  //   },
  // });

  // useEffect(() => {
  //   const role = window.sessionStorage.getItem("userRole") as string;
  //   const token = window.sessionStorage.getItem("access_token") as string;

  //   setAccessToken(token);
  //   setUserRole(role);
  // }, [toggleUser]);

  // useEffect(() => {
  //   // if (isNaN(employeeInfo.employeeNo)) {
  //   //   setIsEmpNo(false);
  //   // }
  //   if (employeeInfo.employeeNo <= 0) {
  //     setIsEmpNo(false);
  //   } else {
  //     setIsEmpNo(true);
  //   }
  //   if (employeeInfo.employeeNo !== 0) {
  //     setIsEmpNo(true);
  //   }
  //   if (employeeInfo.department !== "") {
  //     setIsDept(true);
  //   }
  // }, [employeeInfo]);

  // useEffect(() => {
  //   if (employeeInfo.employeeNo <= 0) {
  //     setIsEmpNo(false);
  //   }
  // }, [employeeInfo.employeeNo]);

  // useEffect(() => {
  //   getEmpInfoFunc();
  // }, [onboardingIds.EmployeeInfo]);

  // const getEmpInfoFunc = async () => {
  //   if (
  //     getOnboardingId.id === "" ||
  //     getOnboardingId.id === undefined ||
  //     getOnboardingId.id === null
  //   ) {
  //     return;
  //   } else {
  //     const getEmpInfoDetails = await getEmployeeInfo(getOnboardingId.id);
  //     setEmployeeInformation({ getEmpInfoDetails, setEmployeeInfo });
  //   }
  // };

  // const generateEmpInfo = () => {
  //   allEmployees.forEach((emp) => {
  //     if (emp.id === employeeInfo.assignedTo) {
  //       setEmployeeInfo((prev) => ({
  //         ...prev,
  //         assignedTo: emp.leadName,
  //       }));
  //     }
  //   });
  // };
  const handleChange = (event: any) => {
    // if (event.target.name == "department") {
    //   setEmpInfo((prevState: IEmpInfo) => ({
    //     ...prevState,
    //     department: event.target.value,
    //   }));
    // }
    // if (event.target.name == "designation") {
    //   setEmpInfo((prevState: IEmpInfo) => ({
    //     ...prevState,
    //     designation: event.target.value,
    //   }));
    // allDesignations.forEach((des) => {
    //   if (des._id === event.target.value) {
    //   }
    // });
  };

  // if (event.target.name == "role") {
  //   setEmpInfo((prevState: IEmpInfo) => ({
  //     ...prevState,
  //     role: event.target.value,
  //   }));

  // allRoles.forEach((role) => {
  //   if (role._id === event.target.value) {

  //   }
  // });
  // }

  // if (event.target.name == "assignedTo") {
  //   setEmpInfo((prevState: IEmpInfo) => ({
  //     ...prevState,
  //     assignedTo: event.target.value,
  //   }));

  // allEmployees.forEach((emp) => {
  //   if (emp.id === event.target.value) {

  //   }
  // });
  // }

  // setEmployeeInfo((prev) => ({
  //   ...prev,
  //   status: true,
  // }));

  // if (event.target.value == "a") {
  //   setSelectedValue(event.target.value);
  //   setRadioValue(true);
  // } else {
  //   setRadioValue(false);
  //   setSelectedValue(event.target.value);
  // }
  // const allHandlers = handleChangeEmployeeInfo(
  //   event,
  //   setEmployeeInfo,
  //   radioValue,
  //   setIsValid,
  //   setIsEmpNo,
  //   setIsDept
  // );
  // const myHandler = allHandlers[event.target.name];
  // myHandler();
  // };

  // const handleSubmit = () => {
  //

  //   if (employeeInfo.employeeNo < 1) {
  //     setIsEmpNo(false);
  //
  //   }
  //   if (!isEmpNo) {
  //     showToast.error("Invalid employee number!");
  //     return;
  //   }

  //   if (!isDept) {
  //     showToast.error("No Department selected");
  //     return;
  //   }

  //   setIsViewEnabled((prevState) => ({
  //     ...prevState,
  //     employeeInformationViewEnabled: true,
  //   }));

  //   handleEmployeeInfoSubmit(
  //     employeeInfo,
  //     getOnboardingId.id,
  //     showToast,
  //     setRecruiteeDetailsStepsatom,
  //     setIsViewEnabled,
  //     setOnboardingIds,
  //     accessToken,
  //     userRole
  //   )
  //     .then((res) => {
  //       if (res.status === 400) {
  //         showToast.error("Something is missing...");
  //         return;
  //       }
  //       if (res.status === 200) {
  //         setToggleUser(!toggleUser);
  //         setIsViewEnabled((prevState) => ({
  //           ...prevState,
  //           employeeInformationViewEnabled: true,
  //         }));
  //         if (userRole === "temp-user") {
  //           setRecruiteeDetailsStepsatom((prev) => ({
  //             ...prev,
  //             employeeInformation: true,
  //           }));
  //         }
  //         setClickable((prev) => ({
  //           ...prev,
  //           recruiteeDetails: true,
  //         }));
  //       }
  //     })
  //     .catch((err: any) => {
  //       setToggleUser(!toggleUser);
  //     });
  // };

  // const hadnleEdit = () => {
  //   setIsEdit((prevState) => ({
  //     ...prevState,
  //     employeeInformationEdit: true,
  //   }));
  //   setIsViewEnabled((prev) => ({
  //     ...prev,
  //     employeeInformationViewEnabled: false,
  //   }));
  // };

  const getEmpInfoDetails = async () => {
    const res = await getEmployeeInfo(getOnboardingId.id);

    setCurrentEmpNo(res?.employeeNo ? res.employeeNo : 0);
    setCurrentDepartment(res?.department ? res.department : "");
    setCurrentDesignation(res?.designation ? res.designation : "");
    setCurrentRole(res?.role ? res.role : "");
    setCurrentAssignedTo(res?.assignedTo ? res.assignedTo : "");
  };

  useEffect(() => {
    if (getOnboardingId.id) {
      getEmpInfoDetails();
    }
  }, [getOnboardingId.id]);

  const handleSubmit = async () => {
    //handle error toast --> if even one is not selected and submitted
    if (!currentEmpNo || currentEmpNo < 999 || currentEmpNo > 9999) {
      alert("Employee Number must be a 4 digit number");
      return;
    }

    if (!currentDepartment) {
      alert("Department cannot be empty");
      return;
    }
    if (!currentDesignation) {
      alert("Designation cannot be empty");
      return;
    }
    if (!currentRole) {
      alert("Role cannot be empty");
      return;
    }
    if (!currentAssignedTo) {
      alert("Assigned To cannot be empty");
      return;
    }

    const payload = {
      status: true,
      employeeNo: currentEmpNo,
      department: currentDepartment,
      designation: currentDesignation,
      role: currentRole,
      assignedTo: currentAssignedTo,
    };

    try {
      await saveEmpInfoAPI(getOnboardingId?.id, payload);
      showToast.success("Employee Information Details Saved");
      setIsViewEnabled((prev) => ({
        ...prev,
        employeeInformationViewEnabled: true,
      }));
    } catch (error) {
      showToast.error("EmpInfo", error);
    }
  };

  const handleEdit = () => {
    setIsViewEnabled((prev) => ({
      ...prev,
      employeeInformationViewEnabled: false,
    }));
  };

  const getAllDepartments = async () => {
    try {
      const res = await getDepartment();

      setAllDepartments(res.data);
    } catch (error) {}
  };

  const getAllDesignations = async () => {
    try {
      const res = await getDesignation();

      setAllDesignations(res.data);
    } catch (error) {}
  };

  const getAllEmployees = async () => {
    try {
      const res = await getLeaders();

      setAllEmployees(res.data);
    } catch (error) {}
  };

  const getAllRoles = async () => {
    try {
      const res = await getRolesAPI();

      setAllRoles(res.data);
    } catch (error) {}
  };

  useEffect(() => {
    getAllDepartments();
    getAllDesignations();
    getAllEmployees();
    getAllRoles();
  }, []);

  const getDepartmentName =
    allDepartments.find((dep) => dep._id === currentDepartment)
      ?.departmentName || "";

  const getDesignatonName =
    allDesignations.find((des) => des._id === currentDesignation)
      ?.designationName || "";

  const getRoleName =
    allRoles.find((role) => role._id === currentRole)?.roleName || "";
  const getAssignedToName =
    allEmployees.find((emp) => emp.id === currentAssignedTo)?.leadName || "";

  return (
    <form className={classes.formContainer}>
      <Grid container justifyContent="flex-start" className={classes.container}>
        <Grid item xs={12} sm={12} md={4}>
          {isViewEnabled.employeeInformationViewEnabled ? (
            <>
              <InputLabel
                variant="outlined"
                shrink
                className={classes.viewTextLabel}
              >
                <b>Employee Number</b>
              </InputLabel>
              <Typography className={classes.viewText}>
                {/* {employeeInfo.employeeNo} */}
                {/* {empInfo.employeeNo} */}
                {currentEmpNo}
              </Typography>
            </>
          ) : (
            <TextField
              type="number"
              id="employeeNumber"
              name="employeeNumber"
              label="Employee Number"
              // value={employeeInfo.employeeNo ? employeeInfo.employeeNo : ""}
              // onChange={(event) => handleChange(event)}
              value={currentEmpNo}
              onChange={(event) =>
                setCurrentEmpNo(parseInt(event.target.value, 10))
              }
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          {isViewEnabled.employeeInformationViewEnabled ? (
            <>
              <InputLabel
                variant="outlined"
                shrink
                className={classes.viewTextLabel}
              >
                <b>Department</b>
              </InputLabel>
              <Typography className={classes.viewText}>
                {/* {employeeInfo.department} */}
                {getDepartmentName}
              </Typography>
            </>
          ) : (
            <FormControl className={classes.formControlsWidth}>
              <InputLabel id="department">Department</InputLabel>
              <Select
                labelId="department"
                id="department"
                name="department"
                // value={employeeInfo?.department}
                // value={empInfo.department.id}
                value={currentDepartment}
                onChange={(event) =>
                  setCurrentDepartment(event.target.value as string)
                }
              >
                {allDepartments.map((department, index) => {
                  return (
                    <MenuItem key={department._id} value={department._id}>
                      {department.departmentName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
        </Grid>

        {/* designation*/}
        <Grid item xs={12} sm={12} md={4}>
          {isViewEnabled.employeeInformationViewEnabled ? (
            <>
              <InputLabel
                variant="outlined"
                shrink
                className={classes.viewTextLabel}
              >
                <b>Designation</b>
              </InputLabel>
              <Typography className={classes.viewText}>
                {/* {employeeInfo.designation} */}
                {/* {empInfo.designation.label} */}
                {getDesignatonName}
              </Typography>
            </>
          ) : (
            <FormControl className={classes.formControlsWidth}>
              <InputLabel id="designation">Designation</InputLabel>
              <Select
                labelId="designation"
                id="designation"
                name="designation"
                // value={employeeInfo?.designation}
                // value={empInfo.designation.id}
                value={currentDesignation}
                onChange={(event) =>
                  setCurrentDesignation(event.target.value as string)
                }
              >
                {allDesignations.map((designation, index) => {
                  return (
                    <MenuItem key={designation._id} value={designation._id}>
                      {designation.designationName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
        </Grid>

        {/* gap */}
        <Grid item xs={12} sm={12} style={{ marginTop: "2em" }}></Grid>

        {/* roles */}
        <Grid item xs={12} sm={12} md={4}>
          {isViewEnabled.employeeInformationViewEnabled ? (
            <>
              <InputLabel
                variant="outlined"
                shrink
                className={classes.viewTextLabel}
              >
                <b>Role</b>
              </InputLabel>
              <Typography className={classes.viewText}>
                {/* {employeeInfo.role} */}
                {/* {empInfo.role.label} */}
                {getRoleName}
              </Typography>
            </>
          ) : (
            <FormControl className={classes.formControlsWidth}>
              <InputLabel id="designation">Role</InputLabel>
              <Select
                labelId="role"
                id="role"
                name="role"
                // value={empInfo?.role.id}
                value={currentRole}
                onChange={(event) =>
                  setCurrentRole(event.target.value as string)
                }
              >
                {allRoles.map((role, index) => {
                  return (
                    <MenuItem key={role._id} value={role._id}>
                      {role.roleName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
        </Grid>

        {/* assigned to */}
        <Grid item xs={12} sm={12} md={4}>
          {isViewEnabled.employeeInformationViewEnabled ? (
            <>
              <InputLabel
                variant="outlined"
                shrink
                className={classes.viewTextLabel}
              >
                <b>Assigned To</b>
              </InputLabel>
              <Typography className={classes.viewText}>
                {/* {employeeInfo.assignedTo} */}
                {/* {empInfo.assignedTo.label} */}
                {getAssignedToName}
              </Typography>
            </>
          ) : (
            <FormControl className={classes.formControlsWidth}>
              <InputLabel id="designation">Assigned To</InputLabel>
              <Select
                labelId="assignedTo"
                id="assignedTo"
                name="assignedTo"
                // value={empInfo.assignedTo.id}
                value={currentAssignedTo}
                onChange={(event) =>
                  setCurrentAssignedTo(event.target.value as string)
                }
              >
                {allEmployees.map((emp, index) => {
                  return (
                    <MenuItem key={emp.id} value={emp.id}>
                      {emp.leadName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
        </Grid>

        {/* <Grid item xs={12} sm={12} md={4}>
          {isViewEnabled.employeeInformationViewEnabled ? (
            <>
              <InputLabel
                variant="outlined"
                shrink
                className={classes.viewTextLabel}
              >
                <b>Employee Status</b>
              </InputLabel>
              <Typography className={classes.viewText}>
                {employeeInfo.status ? "Active" : "Inactive"}
              </Typography>
            </>
          ) : (
            <>
              <small>Employee Status</small>
              <Grid container>
                <Grid item container direction="row" md={12} lg={12} xl={12}>
                  <Grid item md={3} lg={6} xl={6}>
                    <Radio
                      color="primary"
                      checked={selectedValue === "a"}
                      onChange={(event) => handleChange(event)}
                      value="a"
                      name="status"
                      inputProps={{ "aria-label": "A" }}
                    />
                    Active
                  </Grid>
                  <Grid item md={3} lg={6} xl={6}>
                    <Radio
                      color="primary"
                      checked={selectedValue === "b"}
                      onChange={(event) => handleChange(event)}
                      value="b"
                      name="status"
                      inputProps={{ "aria-label": "B" }}
                    />
                    Inactive
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid> */}
      </Grid>

      <Grid container justifyContent="flex-end">
        {isViewEnabled.employeeInformationViewEnabled ? (
          <Button
            variant="contained"
            className={classes.submitButton}
            onClick={handleEdit}
          >
            Edit
          </Button>
        ) : (
          <Button
            variant="contained"
            className={classes.submitButton}
            onClick={handleSubmit}
          >
            Update
          </Button>
        )}
      </Grid>
    </form>
  );
};

export default EmployeeInformation;

{
  /* <TextField
value="Project Manager"
InputProps={{
  readOnly: isViewEnabled.employeeInformationViewEnabled,
  disableUnderline: isViewEnabled.employeeInformationViewEnabled,
}}
onChange={(e) => console.log("test", e.target.value)}
/> */
}
