import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  root: {
    padding: "2em",
  },
  paperContainer: {
    position: "relative",
    justifyContent: "space-evenly",
    marginTop: "2em",
    padding: 20,
    width: "100%",
  },
  label: {
    position: "absolute",
    left: 0,
    top: 0,
    transform: "translate(0, 24px) scale(1)",
  },
  labelColor: {
    backgroundColor: "#fff",
    color: "#000",
    fontSize: "20px",
    padding: 10,
  },
  taskContainer: {
    margin: "1em",
    padding: "1em",
    border: "1px solid #d6d6d6",
    borderRadius: 5,
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.08)",
    "&:hover": {
      background: "#EDEDED",
    },
  },
  taskContainerClicked: {
    margin: "1em",
    padding: "1em",
    border: "1px solid #d6d6d6",
    borderRadius: 5,
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.08)",
    backgroundColor: "#EDEDED",
  },
  doneButton: {
    marginTop: "2em",
    backgroundColor: "#14213D",
    color: "#fff",
  },

  toggleLabels: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    color: "#14213D99",
    cursor: "pointer",
    transitionDuration: "0.5s",
    "&:hover": {
      color: "#14213D",
      transitionDuration: "0.5s",
    },
  },
});
