import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  tableContainer: {
    flex: 3,
    // paddingTop: theme.typography.pxToRem(12),
  },
  table: {
    borderRadius: "10px",
    marginTop: "1rem",
    overflow: "scroll",
    overflowX: "hidden",
    height: "100vh",
  },
  tableHeaderRoot: {},
  tableHeader: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: " 18px",
    backgroundColor: "#14213D",
    color: "#FFFFFF",
    padding: ".5rem",
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  tableHeaderInside: {
    borderRight: "2px solid white",
    display: "flex",
    alignItems: "center",
  },
  tableHeaderNoBorder: {
    display: "flex",

    alignItems: "center",
  },
  loading: {
    position: "relative",
    top: "10rem",
    left: "30rem",
  },
}));
