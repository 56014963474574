import {
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { searchPost } from "../../apis/kHubPost";
import useDebounce from "../../hooks/useDebounce";
import { Toast } from "../../pages/knowledgeHubPage/KnowledgeHubPage";
import {
  kHubCategoryAtom,
  kHubPostAtom,
  kHubPostTechsAtom,
  kHubQueryAtom,
} from "../../recoil/atoms";
import { capitalizeString } from "../../utils/functions";
import SearchIcon from "../customIcons/searchIcon/SearchIcon";
import { useStyles } from "./styles";

interface Props {
  pageNo: number;
  setIsBySearch: React.Dispatch<React.SetStateAction<boolean>>;
}

const SelectSearchBar: React.FC<Props> = ({ pageNo, setIsBySearch }) => {
  const classes = useStyles();
  const techs = useRecoilValue(kHubPostTechsAtom);
  const [category, setCategory] = useRecoilState(kHubCategoryAtom);
  const [query, setQuery] = useRecoilState(kHubQueryAtom);
  const setPosts = useSetRecoilState(kHubPostAtom);
  const dbState = useDebounce(query, 500);
  const showToast = useContext(Toast);

  async function handleChange(e: any) {
    const cat: string = e.target.value;
    setCategory(cat);
  }

  function texthandler(e: any) {
    const query = e.target.value;
    setQuery(query);
  }

  function showSeeMoreButton(data: any[]) {
    if (data.length <= 0) setIsBySearch(true);
    else if (query.length <= 0) setIsBySearch(false);
    else setIsBySearch(true);
  }

  async function fetch() {
    let data = [];
    if (query.length <= 0 && category) {
      try {
        data = await searchPost({
          title: "",
          page: pageNo,
          category: category,
        });
      } catch (error: any) {
        showToast.error(error.message);
      }
      setIsBySearch(false);
    } else {
      try {
        data = await searchPost({
          title: dbState,
          page: 200,
          category: "",
        });
      } catch (error: any) {
        showToast.error(error.message);
      }
    }
    setPosts(data);
    showSeeMoreButton(data);
  }

  useEffect(() => {
    fetch();
  }, [dbState]);

  return (
    <div className={classes.container}>
      <FormControl className={classes.formControl}>
        <Select
          MenuProps={{
            className: classes.selectMenu,
          }}
          id="select"
          value={category}
          onChange={handleChange}
          className={classes.select}
        >
          <MenuItem value={"all"}>All</MenuItem>
          {techs.map((tech) => (
            <MenuItem key={tech} value={tech}>
              {capitalizeString(tech)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className={classes.break}></div>
      <TextField
        fullWidth
        onChange={texthandler}
        id="standard-basic"
        placeholder="Search"
        className={classes.textField}
        value={query}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton className={classes.iconButton}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default SelectSearchBar;
