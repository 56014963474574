import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import Button from "../../../components/CustomButton";
import eventIcon from "../../../assets/icons/event.svg";
import Popup from "../../../components/Popup";
import EventForm from "./EventForm";
import { useStyles } from "./styles";
// import { permissions } from "../../../recoil/atoms";
// import { useRecoilState } from "recoil";

const Events: React.FC = () => {
  const classes = useStyles();
  const [eventPopup, setEventPopup] = useState<boolean>(false);
  // const [permission, setPermission] = useRecoilState<any>(permissions);
  const adminPermission =
    "superadmin" || "Admin" || "admin" || "Human Resource";

  /**
   * @method handleOpenEventPopup
   * @description opens the event creation popup on click of button
   */

  // const handleOpenEventPopup = () => {
  //   setEventPopup(true);
  // };

  /**
   * @method handleCloseEventPopup
   * @description closes the event creation popup
   */

  const handleCloseEventPopup = () => {
    setEventPopup(false);
  };

  return (
    <Grid direction="column" container className={classes.container}>
      {adminPermission && (
        <Grid className={classes.eventBox}>
          <Button
            label="Create a new event"
            isDisabled={false}
            isBordered={false}
            startIcon=""
            endIcon=""
            isOutlined={false}
            // onButtonClick functionality is disabled temporary
            // onButtonClick={handleOpenEventPopup}
            onButtonClick={() => {}}
            color="primary"
          />
        </Grid>
      )}

      <Grid container className={classes.eventCard}>
        <Grid
          className={classes.content}
          justify="space-between"
          alignItems="center"
          container
        >
          <Grid justify="center" container xs={3}>
            <img src={eventIcon} alt="event-icon"></img>
          </Grid>

          <Grid xs={9} direction="column" container>
            <h3 className={classes.eventTitle}>Symposium Townhall Event</h3>
            <p className={classes.eventDescription}> October 5, 3:30 PM</p>
          </Grid>
        </Grid>
      </Grid>

      <Grid>
        <Popup
          name="Create a new event"
          open={eventPopup}
          handleClose={handleCloseEventPopup}
        >
          <EventForm />
        </Popup>
      </Grid>
    </Grid>
  );
};

export default Events;
