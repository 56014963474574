import React from "react";
import Calendar from "react-calendar";
import { useStyles } from "./styles";
import "./styles.css";
import Legend from "./Legend";
import { calendarLegends } from "../../utils/constants";
import CalendarIcon from "../../assets/icons/calendar.svg";
import { formatDate } from "../../utils/functions";
import CalendarDot from "./CalendarDot";

interface IProps {
  data?: any;
}

const CalendarComponent: React.FC<IProps> = ({ data }) => {
  const classes = useStyles();

  const today = new Date();

  /**
   * @description Function to change the style of the column depicting Sunday
   * @method addDynamicClass
   */
  const addDynamicClass = ({ date, view }: { date: Date; view: string }) => {
    let formattedDate = formatDate(date);
    if (
      view === "month" &&
      date.getDay() === 6 &&
      formattedDate === formatDate(today)
    ) {
      return "saturday";
    } else {
      return null;
    }
  };

  /**
   * @description Function to generate a calendar header with the month or year tag
   * @method getCalendarHeader
   * @param {any} obj
   * @returns a node with header label and a calendar icon
   */
  const getCalendarHeader = ({ label }: { label: string }) => {
    return (
      <div className={classes.calendarHeader}>
        <img alt="" src={CalendarIcon} />
        <p>{label}</p>
      </div>
    );
  };

  /**
   * @description Function to mark leaves and holidays
   * @method getDotOnCalendar
   * @param {any} obj
   * @returns a <CalendarDot /> component
   */
  const getDotOnCalendar = ({ date }: { date: Date }) => {
    let formattedDate = formatDate(date);
    if (data?.cl.includes(formattedDate)) {
      return <CalendarDot type="casualLeave" />;
    } else if (data?.pl?.includes(formattedDate)) {
      return <CalendarDot type="priviledgeLeave" />;
    } else if (data?.sl?.includes(formattedDate)) {
      return <CalendarDot type="sickLeave" />;
    } else if (data?.halfDay?.includes(formattedDate)) {
      return <CalendarDot type="halfDayLeave" />;
    } else if (data?.holidayDates?.includes(formattedDate)) {
      return <CalendarDot type="holiday" />;
    }
  };

  return (
    <div className={classes.calendarContainer}>
      <div className={classes.calendarTop}>
        <Calendar
          className={classes.calendar}
          value={today}
          tileClassName={addDynamicClass}
          showNeighboringMonth={true}
          navigationLabel={getCalendarHeader}
          tileContent={getDotOnCalendar}
        />
      </div>
      <div className={classes.calendarBottom}>
        {calendarLegends.map((legend) => {
          return <Legend name={legend.name} color={legend.color} />;
        })}
      </div>
    </div>
  );
};

export default CalendarComponent;
