export const recruiteeDetailsSteps = {
  basicDetails: true,
  educationQualification: true,
  workExperience: true,
  familyAndLanguage: true,
  bankDetails: true,
  employeeInformation: true,
};

export const uploadDocumentsSteps = {
  acceptedOfferLetter: true,
  identityProof: true,
  academicProof: true,
  worExperienceProof: true,
};

export const companyEmailSteps = {
  companyEmail: true,
};

export const checklistSteps = {
  tasks: true,
  assetAllocation: true,
};


export const recruiteeDetailsStepsTemp = {
  basicDetails: false,
  educationQualification: false,
  familyAndLanguage: false,
  bankDetails: false,
  employeeInformation: false,
};

export const uploadDocumentsStepsTemp = {
  acceptedOfferLetter: false,
  identityProof: false,
  academicProof: false,
  worExperienceProof: false,
};