import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: "100%",
    height: "100%",
    padding: theme.typography.pxToRem(20),
    textAlign: "center",
    position: "relative",
  },

  leaveStat: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main,
    marginBottom: theme.typography.pxToRem(5),
  },

  seeMore: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(10),
    color: "#D90202",
    textDecoration: "underline",
    margin: 0,
  },
  detailCard: {
    padding: theme.typography.pxToRem(20),
    position: "absolute",
    display: "none",
    borderRadius: "8px",
    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.05)",
    left: "50%",
    top: "60%",
    zIndex: 5,
    width: "85%",
    transform: "translateX(-50%)",
    background: "#fff",
  },

  detailCardActive: {
    display: "flex",
  },

  title: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main,
    margin: 0,
    marginTop: theme.typography.pxToRem(8),
  },

  subTitle: {
    fontFamily: "Poppins",
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(9),
    color: "#0885DF",
    margin: 0,
  },
  detail: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.primary.main,
    margin: 0,
    //marginTop:theme.typography.pxToRem(8),
  },

  detailsContainerLeft: {
    textAlign: "left",
  },

  detailsContainerRight: {
    textAlign: "left",
  },

  detailsMainContainer: {
    paddingTop: theme.typography.pxToRem(20),
  },
  avatar: {
    cursor: "pointer",
  },
  remainingList: {
    position: "absolute",
    top: "5px",
    left: "10%",
    background: "white",
    zIndex: 5,
    borderRadius: "4px",
    boxShadow:
      "rgba(0, 0, 0, 0.15) 0px 20px 40px, rgba(255, 255, 255, 0.3) 0px 0px 0px 0.5px inset",
  },
  leaveCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "16em",
    padding: ".7em",
    background: "white",
    "&:first-child": {
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px"
    },
    "&:last-child": {
      borderBottomLeftRadius: "4px",
      borderBottomRightRadius: "4px",
    },
    "&:nth-child(even)": {
      background: "rgba(189, 189, 189, .4)",
    },
  },
  leaveTooltip: {
    width: "25%",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #140047",
  },
  leaveBox: {
    position: "absolute",
    top: "70%",
    left: "10%",
    zIndex: 5,
    border: "1px solid #140047",
    borderRadius: "4px",
  }
}));
