import {
  Avatar,
  Button,
  Card,
  createStyles,
  Divider,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { grey, green } from "@material-ui/core/colors";
import clsx from "clsx";
import * as React from "react";
import DoneAllOutlinedIcon from "@material-ui/icons/DoneAllOutlined";
import CalenderTodayOutline from "@material-ui/icons/CalendarTodayOutlined";
import AssignmentOutlined from "@material-ui/icons/AssignmentIndOutlined";

import { Link } from "react-router-dom";
import moment from "moment";

const useStyles = makeStyles<Theme, Pick<IFlatCardProps, "minHeight">>(
  (theme: Theme) =>
    createStyles({
      flex: {
        display: "flex",
      },
      flex1: {
        flex: 1,
      },
      px2: {
        paddingInline: "16px",
      },
      my1: {
        marginBlock: "8px",
      },
      my2: {
        marginBlock: "16px",
      },
      py2: {
        paddingBlock: "16px",
      },
      minHeight: {
        minHeight: (prop) => `${prop.minHeight}px`,
      },
      bgTransparent: {
        background: "transparent",
        color: "#000",
      },
      circle: {
        border: `1px solid black`,
      },
      centerAlign: {
        alignItems: "center",
      },
      gap2: {
        gap: "16px",
      },
      gap1: {
        gap: "8px",
      },
      boldText: {
        fontWeight: "bold",
      },
      flexCol: {
        display: "flex",
        flexDirection: "column",
      },
      grey8: {
        color: grey[800],
      },
    })
);

export interface ISection {
  title: string;
  value: string;
  renderIcon?: () => React.ReactNode;
}
const Section: React.FC<ISection> = ({ title, value, renderIcon }) => {
  const classes = useStyles({ minHeight: 0 });
  return (
    <div
      className={clsx([classes.flex1, classes.flexCol, classes.centerAlign])}
    >
      <div className={clsx([classes.flex, classes.centerAlign, classes.gap1])}>
        {renderIcon && renderIcon()}
        <Typography className={clsx([classes.my1])}> {title}</Typography>
      </div>
      <Typography className={clsx([classes.boldText, classes.grey8])}>
        {value}
      </Typography>
    </div>
  );
};

interface IRenderActionButton {
  status: "new" | "old";
  EditButton: React.ReactNode;
  ViewReportButton: React.ReactNode;
  dateCreated: string;
}

const RenderActionButton: React.FC<IRenderActionButton> = ({
  status,
  EditButton,
  ViewReportButton,
  dateCreated,
}) => {
  const hasEditPermission = true;
  const currQ = moment().quarter();
  const reportQ = moment(dateCreated, "DD-MM-YYYY").quarter();
  const isReportFromCurrentQ = currQ === reportQ;

  if (status === "new") {
    return (
      <>
        <>{ViewReportButton}</>
        <>{hasEditPermission && isReportFromCurrentQ && EditButton}</>
      </>
    );
  }
  if (status === "old") {
    return <>{ViewReportButton}</>;
  }
  return <></>;
};

const FlatCard: React.FC<IFlatCardProps> = ({
  minHeight,
  employeeID,
  kraID,
  score,
  status,
  approvedBy,
  createdBy,
  dateCreated,
  quarter,
  renderEditButton,
  renderViewReportButton,
}) => {
  const classes = useStyles({ minHeight });
  return (
    <Paper
      variant="outlined"
      className={clsx([
        classes.my2,
        classes.py2,
        classes.px2,
        // classes.minHeight,
        // classes.flex,
      ])}
    >
      <div className={clsx([classes.flex, classes.centerAlign, classes.gap2])}>
        <div
          className={clsx([
            classes.flex,
            classes.centerAlign,
            classes.gap2,
            classes.flex1,
          ])}
        >
          <Avatar className={clsx([classes.bgTransparent, classes.circle])}>
            {score}
          </Avatar>
          <Typography
            className={clsx([classes.boldText, classes.grey8])}
            variant="h6"
          >
            {quarter}
          </Typography>
        </div>
        <div className={clsx([classes.flex, classes.gap1])}>
          <RenderActionButton
            status={status}
            EditButton={renderEditButton()}
            ViewReportButton={renderViewReportButton()}
            dateCreated={dateCreated}
          />
        </div>
      </div>
      <Divider className={clsx([classes.my2])} />
      <div className={clsx([classes.flex, classes.py2])}>
        <Section
          title="Create By"
          value={createdBy}
          renderIcon={() => <AssignmentOutlined />}
        />
        <Section
          title="Date created"
          value={dateCreated}
          renderIcon={() => <CalenderTodayOutline />}
        />
        <Section
          title="Approved By"
          value={approvedBy}
          renderIcon={() => (
            <DoneAllOutlinedIcon style={{ color: green[700] }} />
          )}
        />
      </div>
    </Paper>
  );
};

export default FlatCard;
FlatCard.defaultProps = {
  minHeight: 200,
};
export interface IFlatCardProps {
  minHeight?: number;
  employeeID: string;
  kraID: string;
  status: "new" | "old";
  dateCreated: string;
  createdBy: string;
  approvedBy: string;
  quarter: string;
  score: number;
  // renderSparkLine: () => React.ReactNode;
  renderEditButton: () => React.ReactNode;
  renderViewReportButton: () => React.ReactNode;
}

{
  /* <Button
            component={Link}
            to={{
              pathname: `/app/kra/details/${userId}/${kraData._id}/edit`,
              state: {
                kraInfo: {
                  kraId: kraData?._id,
                  empName: "Aditi Gogoi", //change it to dynamic
                },
              },
            }}
            startIcon={<EditOutlinedIcon />}
            variant="outlined"
          >
            Edit
          </Button> */
}
