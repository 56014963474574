import { Button, Grid } from "@material-ui/core";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  checkIdDocumentSelectionAtom,
  isUploadsViewEnabled,
} from "../../../../../recoil/atoms";
import { UploadsContext } from "../../../../../store/uploadsStore";
import { useStyles } from "../styles";
import { handleIdentityFiles } from "../utils";
import PanCard from "./PanCard";
import AadharCard from "./AadharCard";
import { documentDownloadAtom } from "../../../../../recoil/downloadAtoms";

interface ProofOfIdentityProps {
  showToast: any;
  index: number;
  getEndpoint: string;
  uploadDocsEndpointPatch: string;
  headerPropsGet: any;
  headerPropsPatch: any;
}

export interface IIdentityChildrenProps {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setIsSubmit: React.Dispatch<any>;
  showToast: {
    error: (message: string) => void;
    success: (message: string) => void;
    warning: (message: string) => void;
  };
  getEndpoint: string;
  imgName: {
    adhaarCard: string;
    panCard: string;
  };
  imgSize: {
    adhaarCard: number;
    panCard: number;
  };
  setImgName: Dispatch<
    SetStateAction<{
      adhaarCard: string;
      panCard: string;
    }>
  >;
  setImgSize: Dispatch<
    SetStateAction<{
      adhaarCard: number;
      panCard: number;
    }>
  >;
}

const ProofOfIdentity = ({
  showToast,
  getEndpoint,
  uploadDocsEndpointPatch,
  headerPropsGet,
}: ProofOfIdentityProps) => {
  const classes = useStyles();
  const { idProofs, handlePostIdentityProof, setIdentityProof } =
    useContext(UploadsContext);

  const [, setIsFileSelected] = useRecoilState(checkIdDocumentSelectionAtom);
  const isViewEnabled = useRecoilValue(isUploadsViewEnabled);
  const [isSubmit, setIsSubmit] = useState<any>({});
  const [, setRewriteFileDownload] = useRecoilState(documentDownloadAtom);

  const [imageName, setImageName] = useState<{
    adhaarCard: string;
    panCard: string;
  }>({
    adhaarCard: "",
    panCard: "",
  });

  const [imageSize, setImageSize] = useState<{
    adhaarCard: number;
    panCard: number;
  }>({
    adhaarCard: 0,
    panCard: 0,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileHandler = handleIdentityFiles({
      event,
      setIsSubmit,
      setImageName,
      setImageSize,
      setIsFileSelected,
      idProofs,
      setIdentityProof,
      setRewriteFileDownload,
      showToast,
    });
    const handler = fileHandler[event.target.name];
    handler();
  };

  const handleSubmit = () => {
    idProofs.forEach((value, index) => {
      if (value.name === "panCard" && value.value !== "") {
        setIsSubmit((prev: any) => ({
          ...prev,
          pan: true,
        }));
      }

      if (value.name === "adhaarCard" && value.value !== "") {
        setIsSubmit((prev: any) => ({
          ...prev,
          aadhar: true,
        }));
      }
    });

    if (
      idProofs.length === 2 &&
      imageName.adhaarCard !== "" &&
      imageName.panCard !== ""
    ) {
      handlePostIdentityProof(showToast, uploadDocsEndpointPatch);
    } else {
      showToast.error("No file selected!");
    }
  };

  return (
    <Grid container item xs={12} spacing={3} style={{ paddingTop: "2em" }}>
      <Grid item xs={12} md={6} lg={6} xl={12}>
        <PanCard
          handleChange={handleChange}
          setIsSubmit={setIsSubmit}
          showToast={showToast}
          getEndpoint={getEndpoint}
          imgName={imageName}
          imgSize={imageSize}
          setImgName={setImageName}
          setImgSize={setImageSize}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6} xl={12}>
        <AadharCard
          handleChange={handleChange}
          setIsSubmit={setIsSubmit}
          showToast={showToast}
          getEndpoint={getEndpoint}
          imgName={imageName}
          imgSize={imageSize}
          setImgName={setImageName}
          setImgSize={setImageSize}
        />
      </Grid>

      <Grid xs={12} className={classes.submitDiv}>
        {isViewEnabled.identity ? (
          <Button disabled className={classes.submitButtonDisabled}>
            Submit
          </Button>
        ) : (
          <Button className={classes.submitButton} onClick={handleSubmit}>
            Submit
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default ProofOfIdentity;
