import React, { useContext, useState } from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import { useStyles } from "../style";
import { addRequirementOverview } from "../../../../../../apis/projects";
import { useParams } from "react-router-dom";
import { Toast } from "../../../../ProjectsPage";
import EditIcon from "@material-ui/icons/Edit";
import { usePermissionObjectFor } from "../../../../../../hooks/permission/usePermissionObjectFor";

interface Iprops {
  projectRequirement: string | undefined;
  fetchRequirements: () => Promise<void>;
}
const ProjectRequirementOverview: React.FC<Iprops> = ({
  projectRequirement,
  fetchRequirements,
}) => {
  const classes = useStyles();
  const showToast = useContext(Toast);
  const { id } = useParams<any>();
  const [overview, setOverview] = useState<string>("");
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const permission = usePermissionObjectFor("projects");
  const hasCreateAccess = permission.required("upDate");

  const changeHandler = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setOverview(e.target.value);
  };

  const editHandler = () => {
    setIsEditable(true);
  };

  const saveHandler = async () => {
    try {
      await addRequirementOverview(id, overview);
      setIsEditable(false);
      showToast.success("Successfully saved!");
      fetchRequirements();
    } catch (err: any) {
      showToast.error(err.response.data.message);
    }
  };

  return (
    <Grid container xs={12} spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          required
          id="requirement"
          name="requirement"
          variant="outlined"
          placeholder="Requirement overview"
          defaultValue={projectRequirement}
          onChange={(e) => changeHandler(e)}
          disabled={!isEditable}
        />
      </Grid>
      {hasCreateAccess && (
        <Grid item xs={12} className={classes.buttonContainer}>
          {isEditable ? (
            <Button
              variant="contained"
              color="default"
              className={classes.button}
              onClick={saveHandler}
            >
              save
            </Button>
          ) : (
            <Button
              variant="contained"
              color="default"
              className={classes.button}
              onClick={editHandler}
            >
              <EditIcon />
            </Button>
          )}
        </Grid>
      )}
    </Grid>
  );
};
export default ProjectRequirementOverview;
