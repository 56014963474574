import { Grid, InputLabel, Paper, Typography } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useRecoilState, useRecoilValue } from "recoil";
import { getDocuments } from "../../../../../apis/onboarding";
import uploadDocument from "../../../../../assets/icons/uploadDocument.svg";
import {
  checkWorkDocumentSelectionAtom,
  isUploadsViewEnabled,
  recruiteeBasicDetails
} from "../../../../../recoil/atoms";
import { documentDownloadAtom } from "../../../../../recoil/downloadAtoms";
import { UploadsContext } from "../../../../../store/uploadsStore";
import { HandleDroppedWorkExperience } from "../HandleDroppedFiles/HandleDroppedWorkExperience";
import { useStyles } from "../styles";
import UploadedDocuments from "../UploadedDocuments";
import { IExperienceChildrenProps } from "./WorkExperienceDocuments";

const ThirdLastSalarySlip = ({
  handleChange,
  setIsSubmit,
  showToast,
  getEndpoint,
  imgName,
  imgSize,
  setImgName,
  setImgSize,
}: IExperienceChildrenProps) => {
  const classes = useStyles();
  const { workExperienceProgress, experienceProof, setExperienceProof } =
    useContext(UploadsContext);
  const [, setIsFileSelected] = useRecoilState(checkWorkDocumentSelectionAtom);
  const mainUrl = process.env.REACT_APP_BACKEND_URL;
  const basicDetails = useRecoilValue(recruiteeBasicDetails);
  const [, setIsViewEnabled] = useRecoilState(isUploadsViewEnabled);

  const [rewriteFileDownload, setRewriteFileDownload] =
    useRecoilState(documentDownloadAtom);
  const [isDisable, setIsDisable] = useState<boolean>(false);

  const url = mainUrl + getEndpoint;
  const parentKey = "workExperience";
  const childKey = "salarySlip3rd";

  const getDocs = async () => {
    const response = await getDocuments(url, parentKey, childKey);
    if (response.status === 200 && response.data !== "") {
      setImgName((prev) => ({
        ...prev,
        salarySlip3rd: `Third Last Salary Slip of ${basicDetails.name}`,
      }));
      setImgSize((prev) => ({
        ...prev,
        salarySlip3rd: 0,
      }));
      setIsViewEnabled((prev) => ({
        ...prev,
        workExp: true,
      }));
      setIsSubmit((prev: any) => ({
        ...prev,
        fileSalary3: true,
      }));
      setExperienceProof((prev) => [
        ...prev,
        {
          name: "salarySlip3rd",
          value: response.data,
        },
      ]);
    } else {
      setIsViewEnabled((prev) => ({
        ...prev,
        workExp: false,
      }));
    }
  };

  useEffect(() => {
    getDocs();
  }, []);

  useEffect(() => {
    const length = experienceProof.length;
    if (length > 0) {
      for (let i = 0; i < length; i++) {
        if (
          experienceProof[i].name === "salarySlip3rd" &&
          imgName.salarySlip3rd !== ""
        ) {
          setIsDisable(true);
          break;
        }
        if (imgName.salarySlip3rd === "") {
          setIsDisable(false);
        }
      }
    }
  }, [experienceProof, imgName]);

  const {
    getRootProps: getRootPropsThirdLastSal,
    getInputProps: getInputPropsThirdLastSal,
  } = useDropzone({
    onDrop: (acceptedFilesThirdLastSal) => {
      const file = acceptedFilesThirdLastSal[0];
      const docType = "ThirdLastSalarySlip";
      if (!isDisable) {
        HandleDroppedWorkExperience({
          docType,
          file,
          experienceProof,
          setExperienceProof,
          setIsFileSelected,
          setImgName,
          setImgSize,
          setIsSubmit,
          showToast,
          setRewriteFileDownload,
        });
      }
    },
    noClick: true,
  });

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      className={classes.docContainer}
    >
      <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
        <Paper
          variant="outlined"
          elevation={4}
          className={classes.paperContainer}
        >
          <InputLabel variant="outlined" className={classes.label} shrink>
            <b className={classes.labelColor}>Third Last Salary Slip</b>
          </InputLabel>

          <Grid container direction="column" className={classes.dropContainer}>
            <Grid item>
              <Grid
                container
                direction="column"
                spacing={2}
                className={classes.dashedContainer}
                {...getRootPropsThirdLastSal()}
              >
                <input {...getInputPropsThirdLastSal()} />
                <Grid item className={classes.itemStyle}>
                  <Typography className={classes.boldText}>
                    Drag and Drop files to upload
                  </Typography>
                </Grid>
                <Grid item className={classes.itemStyle}>
                  <Typography className={classes.instruction}>
                    Maximum upload size 1MB
                  </Typography>
                </Grid>

                <Grid item className={classes.itemStyle}>
                  <input
                    type="file"
                    name="salarySlip3rd"
                    id="salarySlip3rd"
                    className={classes.inputfile}
                    onChange={(event) => handleChange(event)}
                    onClick={(event) => {
                      //@ts-ignore
                      event.target.value = null;
                    }}
                    disabled={isDisable}
                  />
                  <label htmlFor="salarySlip3rd" className={classes.inputlabel}>
                    <i className={classes.inputButtonIcon}>
                      <img src={uploadDocument} />
                    </i>
                    &nbsp; Upload File
                  </label>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className={classes.itemStyle}
            >
              {imgName.salarySlip3rd !== "" ? (
                <UploadedDocuments
                  progress={workExperienceProgress}
                  imageName={imgName.salarySlip3rd}
                  imageSize={imgSize.salarySlip3rd}
                  label="thirdLastSalSlip"
                  getEndpoint={getEndpoint}
                  setImgName={setImgName}
                />
              ) : (
                <Typography className={classes.instruction}>
                  No Files Selected
                </Typography>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ThirdLastSalarySlip;
