import React, { useState,useEffect } from 'react';
import { useStyles } from "./styles";
import { setStatus } from "../../../../apis/contentBasket";
import Select from '@material-ui/core/Select';

interface Iprops {
  status:string;
  _id: string;
  showToast:any;
}
const inputColor = {
  published: "#BBBE1A",
  sent_back: "#BE1A90",
  approved: "#1ABE2A",
  rejected: "#BE1A1A",
  under_review: "#1A65BE",
}

export interface Color {
  color: string
}

type ColorTypes = "approved" | "under_review" | "published" | "sent_back" | "rejected";

const AdminMenu: React.FC<Iprops> = ({ status, _id,showToast}) => {
  const [Avalue, setAvalue] = useState(status);
  const [curInputTextColor, setCurInputTextColor] = useState<Color>({
    color: inputColor["under_review"]
  })
   
  const classes = useStyles({ color: curInputTextColor.color });
  
  const handleChange = (event: React.ChangeEvent<{
    name?: string | undefined;
    value: unknown;
  }>) => {
    const color = (event.target.value as ColorTypes)
    let statusValue: string = event.target.value as string;
    setAvalue(statusValue);
    setCurInputTextColor({ color: inputColor[color] })  
    setStatus(_id,statusValue)
      .then((res) => {
        showToast.success("Status saved successfully!");
      })
      .catch((res) => {
        console.error(res.message);
      })
       };

  useEffect(()=>{
    setAvalue(status);
  },[status])
  
  return (
    <div>
     
      <Select
        native
        value={Avalue}
        onChange={handleChange}
        variant="outlined"
        className={classes.select}
      >
        <option value="under_review" className={classes.underreview} >Under Review</option>
        <option className={classes.published} value="published">Published</option>
        <option className={classes.sentback} value="sent_back">Sent Back</option>
        <option className={classes.approved} value="approved">Approved</option>
        <option className={classes.rejected} value="rejected">Rejected</option>
      </Select>
    </div>
  );
}

export default AdminMenu;
