import { makeStyles } from "@material-ui/core/styles";
import LoginBackground from "../../assets/images/LoginBackground.png";
import TVLogo from "../../assets/images/TVLogo.png";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.backgroundColor.panels,
    padding: "32px",
    margin: "auto",
    width: theme.typography.pxToRem(550),
    borderRadius: 10,
  },

  root: {
    // backgroundImage: `url(${TVLogo})`,
    background: `url(${LoginBackground}) `,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },

  loginText: {
    marginTop: theme.typography.pxToRem(40),
    marginBottom: theme.typography.pxToRem(20),
  },
}));

export default useStyles;
