import React, { useEffect, useState } from "react";
import BasicLayout from "../../components/BasicLayout";
import { useBasicNav } from "../../utils/useBasicNav";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CollapsableTableRow from "../../components/CollapsableTableRow";
import { useStyles } from "./styles";
import { Search, ImportExport, CloudDownload } from "@material-ui/icons";
import { Popover, TextField } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

function createData(name: string, points: number) {
  return {
    name,
    points,
    history: [
      {
        criteria: "Criteria One",
        remarks:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate omnis reprehenderit asperiores quia ipsam consectetur voluptatibus, corporis nesciunt distinctio eum quod debitis consequatur odio minima esse nostrum, a quam sed?",
        points: 3,
      },
      {
        criteria: "Criteria Two",
        remarks: "Anonymous",
        points: 1,
      },
      {
        criteria: "Criteria Three",
        remarks: "11091700",
        points: 3,
      },
      {
        criteria: "Criteria Four",
        remarks: "Anonymous",
        points: 1,
      },
      {
        criteria: "Criteria Five",
        remarks: "11091700",
        points: 3,
      },
      {
        criteria: "Criteria One",
        remarks: "Anonymous",
        points: 1,
      },
    ],
  };
}

const rows = [
  createData("One", 159),
  createData("Two", 237),
  createData("Three", 262),
  createData("Four", 305),
  createData("Five", 356),
];
interface IProps {
  loadPrimary: (event: boolean, basic?: boolean) => void;
  showToast: any;
}

/**
 * @description Functional component which renders the performance page
 * @param {Function} loadPrimary
 * @param {any} showToast
 * @returns a node
 */
export default function PerformacePage({ loadPrimary, showToast }: IProps) {
  useBasicNav("performance");
  const classes = useStyles();
  // const [tableItems, setTableItems] = useState<any>([]);
  const [tableItems] = useState<any>([]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [value, setValue] = useState<any>({ searchField: "" });
  const searchOpen = Boolean(anchorEl);

  /**
   * @description Function to fetch performance data and put it into table items
   * @method getPerformanceData
   * @returns nothing
   */
  // const getPerformanceData = () => {
  // let tempArr: any = [];
  // axios
  //   .get("https://jsonplaceholder.typicode.com/users")
  //   .then((response: any) => {
  //
  //     response.data.map((item: any) => {
  //       let tempObj = {
  //         name: item?.name,
  //         points: item?.id,
  //       };
  //       tempArr.push(tempObj);
  //     });
  //
  //     setTableItems([...tempArr]);
  //   })
  //   .catch((err: any) => {
  //
  //   });
  // };

  /**
   * @description Function to handle input value changes on the search field
   * @method handleSearchFieldChange
   * @param {any} e
   * @returns nothing
   */
  const handleSearchFieldChange = (e: any) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  /**
   * @description Function to perform an employee search
   * @method invokeSearchApi
   * @param {any} e
   * @returns nothing
   */
  const invokeSearchApi = (e: any) => {
    if (e.key === "Enter" && value[e.target.name]) {
    }
  };

  /**
   * @description Closes search popover
   * @method handleSearchClose
   * @returns nothing
   */
  const handleSearchClose = () => {
    setAnchorEl(null);
  };

  /**
   * @description Opens search popover
   * @method handleSearchOpen
   * @param {React.MouseEvent<HTMLButtonElement>} e
   * @returns {any}
   */
  const handleSearchOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };
  /**
   * @description Sort points field
   * @method handleSort
   * @returns nothing
   */
  const handleSort = () => {};

  useEffect(() => {
    loadPrimary(false, false);
    // getPerformanceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BasicLayout>
      <Box mx={2}>
        <Grid container justifyContent="flex-start">
          {/* {console.log("performance response table items", tableItems)} */}
          <Grid item xs={12}>
            <Box
              mt={2}
              p={2}
              bgcolor="white"
              borderRadius="10px"
              height="100vh"
            >
              {/* <Typography variant="h5" color="primary" align="center">
                <strong>Performance Page</strong>
              </Typography> */}
              {/* {console.log("performance", tableItems)} */}
              <Box mt={2}></Box>
              <TableContainer
                className={classes.tableContainer}
                component={Paper}
              >
                <Table aria-label="collapsible table">
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell />
                      <TableCell className={classes.tableHeadCell} align="left">
                        Name
                        <Tooltip title="Search">
                          <IconButton
                            className={classes.searchButton}
                            aria-label="search"
                            onClick={handleSearchOpen}
                          >
                            <Search />
                          </IconButton>
                        </Tooltip>
                        <Popover
                          id="search-popover"
                          open={searchOpen}
                          anchorEl={anchorEl}
                          onClose={handleSearchClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          <TextField
                            id="search-field"
                            autoFocus
                            placeholder="Search"
                            name="searchField"
                            type="text"
                            onKeyDown={invokeSearchApi}
                            onChange={handleSearchFieldChange}
                            className={classes.searchField}
                            InputProps={{
                              disableUnderline: true,
                            }}
                          />
                        </Popover>
                      </TableCell>
                      <TableCell className={classes.tableHeadCell} align="left">
                        Points
                        <Tooltip title="Sort">
                          <IconButton
                            className={classes.searchButton}
                            aria-label="sort"
                            onClick={handleSort}
                          >
                            <ImportExport />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title="Download as CSV">
                          <IconButton
                            className={classes.downloadButton}
                            aria-label="download"
                            onClick={() => {}}
                          >
                            <CloudDownload />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => (
                      <CollapsableTableRow
                        row={row}
                        alternateTableRow={index % 2 === 0 ? true : false}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </BasicLayout>
  );
}
