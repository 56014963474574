import { Avatar, Box, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import LeaveCard from "./LeaveCard";
import RemainingList from "./RemainingList";
import { useStyles } from "./styles";

export interface ILeave {
  adjustAmount: number;
  adjustLeaveType: string;
  applied_on: string;
  approved_by: string;
  assignedTo: string[];
  cancelRequested: boolean;
  day_type: string;
  duration: number;
  emp_id: string;
  emp_name: string;
  from: string;
  isApproved: boolean;
  isPending: boolean;
  leaveStatus: string;
  leave_type: string;
  notes: string[];
  reason: string;
  to: string;
  warnings: string[];
  __v: number;
  _id: string;
}

interface IProps {
  data: ILeave[];
}

const LeaveTracker: React.FC<IProps> = ({ data }) => {
  const classes = useStyles();
  const [hoveredUser, setHoveredUser] = useState<ILeave>();
  const [isHoverActive, setIsHoverActive] = useState<boolean>(false);

  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);

  const MAX_AVATARS = 4;
  const displayData = data.slice(0, MAX_AVATARS);
  const remainingData = data.slice(MAX_AVATARS);

  const onMouseHover = (item: ILeave) => {
    setIsHoverActive(true);
    setHoveredUser(item);
  };

  const onMouseRemoveHover = () => setIsHoverActive(false);

  const handlePopoverOpen = () => setPopoverOpen(true);

  const handlePopoverClose = () => setPopoverOpen(false);

  return (
    <Grid direction="column" container className={classes.mainContainer}>
      <Grid
        style={{
          justifyContent: "center",
        }}
        container
      >
        <Box style={{ display: "flex" }}>
          {displayData.length !== 0 &&
            displayData.map((item: ILeave) => {
              return (
                <Avatar
                  style={{
                    zIndex: displayData.length - 1,
                    marginLeft: "-8px",
                    border: "2px solid white",
                  }}
                  className={classes.avatar}
                  onMouseEnter={() => onMouseHover(item)}
                  onMouseLeave={onMouseRemoveHover}
                  alt={item.emp_name}
                  children={item.emp_name.split(" ")[0][0]}
                />
              );
            })}
          {remainingData.length !== 0 && (
            <Avatar
              style={{
                zIndex: 1,
                marginLeft: "-8px",
                border: "2px solid white",
              }}
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              +{remainingData.length}
            </Avatar>
          )}
        </Box>
      </Grid>
      <h3 className={classes.leaveStat}>
        Number of people on leave : {data?.length}
      </h3>

      {isHoverActive && hoveredUser && (
        <Box className={classes.leaveBox}>
          <LeaveCard
            empName={hoveredUser.emp_name}
            duration={hoveredUser.duration}
            adjustAmount={hoveredUser.adjustAmount}
            leaveType={hoveredUser.leave_type}
          />
        </Box>
      )}

      {popoverOpen && (
        <Box style={{ position: "relative" }}>
          <RemainingList data={remainingData} />
        </Box>
      )}
    </Grid>
  );
};

export default LeaveTracker;
