import { alpha, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: "#140047",
  },
  tableHeaderCell: {
    color: "#fff",
    borderLeft: "1px solid white",
  },
  tableRow: {
    height: 10,
  },
  statusPending: {
    color: "#FF9F45",
  },
  statusApproved: {
    color: "green",
  },
  statusRegected: {
    color: "red",
  },

  search: {
    position: "relative",
    marginLeft: 0,
		// paddingRight: "3em",
		paddingBottom: "1em",
    borderRadius: theme.shape.borderRadius,
    width: "100%",
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
	searchIconStyle:{
		paddingBottom: "0.5em",
	},
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
		borderBottom: "1px solid grey",

    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },

	sortDiv: {
		paddingLeft: "1em"
	},
	sortButton:{
		border: "1px solid #d6d6d6"
	}

}));
