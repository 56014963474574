import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  Dialog,
  CircularProgress,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { useStyles } from "../style";
import { useRecoilState } from "recoil";
import Search from "../../Search";
import Button from "../../../../../components/CustomButton";
import addNewContent from "../../../../../assets/images/addNewContent.svg";
import FormModal from "../../FormModal";
import Pagination from "@material-ui/lab/Pagination";
import {
  getAllIncident,
  createIncident,
  deleteIncident,
  updateIncident,
} from "../../../../../apis/projects";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { Toast } from "../../../ProjectsPage";
import { useLocation } from "react-router-dom";
import moment from "moment";
import IncidentTable from "./IncidentTable";
import CallMadeIcon from "@material-ui/icons/CallMade";
import { permissions, iQueryAtom } from "../../../../../recoil/atoms";
import useDebounce from "../../../../../hooks/useDebounce";
import { Incident } from "../../../Page/ProjectDetailPage/types";
import { usePermissionObjectFor } from "../../../../../hooks/permission/usePermissionObjectFor";

interface Iprops {}
interface Data {
  serial: number;
  Date: Date;
  Reportedby: string;
  Severerity: string;
  Description: string;
}

export interface HeadCells {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}
export interface itemValues {
  _id: string;
  projectId: string;
  date: string;
  reportedBy: string;
  severity: string;
  description: JSX.Element | string;
  incidentId: string;
}
const headerCells: HeadCells[] = [
  { id: "serial", numeric: true, disablePadding: true, label: "SL No." },
  { id: "Date", numeric: true, disablePadding: false, label: "Date" },
  {
    id: "Reportedby",
    numeric: false,
    disablePadding: false,
    label: "Reported by",
  },
  {
    id: "Severerity",
    numeric: false,
    disablePadding: false,
    label: "Severerity",
  },
  {
    id: "Description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
];
const limit = 5;

const IncidentManagement: React.FC<Iprops> = () => {
  const classes = useStyles();
  const showToast = useContext(Toast);
  const location = useLocation() as any;
  const _id = location.state._id as string;
  const [modalState, setModalState] = useState<boolean>(false);
  const [incidentData, setIncidentData] = useState<itemValues[]>([]);
  const [isIncidentView, setIsIncidentView] = useState<boolean>(false);
  const [pageNo, setPageNo] = useState<number>(1);
  const [searchIncident, setSearchIncident] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [incidentFormData, setIncidentFormData] = useState<{
    ID: string;
    date: string;
    severity: string;
    description: string;
  }>({
    ID: "",
    date: "",
    severity: "",
    description: "",
  });
  const [incidentQuery, setIncidentQuery] = useState<string>("");
  const debouncedValue = useDebounce(incidentQuery, 1000);
  const [totalData, setTotalData] = useState<number>(0);
 const permission = usePermissionObjectFor("incidents");
 const hasCreateAccess = permission.required("create");

  useEffect(() => {
    fetchingIncident();
  }, [debouncedValue, pageNo]);

  function PageCount(totalLength: number) {
    let totalPage = Math.ceil(totalLength / limit);
    return totalPage === 0 ? 1 : totalPage;
  }

  async function fetchingIncident() {
    //fetching all incident
    try {
      let data = await getAllIncident(_id, debouncedValue, limit, pageNo);
      setIsLoading(false);
      let tempItem: itemValues[] = [];
      setTotalData(data.data.total);
      data?.data?.incidents.forEach(
        ({
          projectId,
          date,
          reportedBy,
          severity,
          description,
          _id,
          incidentId,
        }: Incident) => {
          let tempObjUser = {
            _id: _id,
            projectId: projectId,
            date: date,
            reportedBy: reportedBy.basicDetails.fullName,
            severity: severity,
            description: description,
            incidentId: incidentId,
          };
          tempItem.push(tempObjUser);
        }
      );
      setIncidentData(tempItem);
      if (data?.data?.incidents.length === 0) {
        setPageNo((page_no) => {
          if (page_no > 1) {
            return page_no - 1;
          }
          return 1;
        });
      }
    } catch (error:any) {
      setIsLoading(false);
      showToast.error(error.response.data.message);
    }
  }

  const showDesc = (item: itemValues) => {
    return (
      <div className={classes.incidentDescription}>
        <Tooltip title={item.description}>
          <span className={classes.desc}>{item.description}</span>
        </Tooltip>
        <div>
          <IconButton
            onClick={() => viewIncidentModal(item)}
            className={classes.view}
          >
            <CallMadeIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => incidentDeleteHandler(item)}
            disabled={!hasCreateAccess}
            className={classes.view}
          >
            <DeleteOutlineIcon htmlColor="#B0BABF" />
          </IconButton>
        </div>
      </div>
    );
  };

  const generateIncident = () => {
    return incidentData.map((item) => ({
      date: moment(item.date).format("DD-MM-YYYY"),
      reportedBy: item.reportedBy,
      severity: item.severity,
      description: showDesc(item),
      projectId: item.projectId,
      _id: item._id,
      incidentId: item.incidentId,
    }));
  };

  function showModal() {
    //show incident modal
    setIncidentFormData({
      ...incidentFormData,
      ID: "",
      date: "",
      severity: "",
      description: "",
    });
    setIsIncidentView(false);
    setModalState(true);
  }
  const viewIncidentModal = (item: itemValues) => {
    //view incident handler
    setModalState(true);
    setIsIncidentView(true);
    setIncidentFormData({
      ...incidentFormData,
      ID: item._id,
      date: item.date,
      severity: item.severity,
      description: item.description as string,
    });
  };
  function hideModalAndClear() {
    //hide incident modal handler
    setModalState(false);
    setIsLoading(false);
    setIncidentFormData({
      ...incidentFormData,
      ID: "",
      date: "",
      severity: "",
      description: "",
    });
  }

  const createIncidentHandler = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    //create incident handler
    setIsLoading(true);
    e.preventDefault();
    if (incidentFormData.date === "") {
      showToast.error("Please select the date!");
      return;
    }
    if (incidentFormData.severity === "") {
      showToast.error("Please provide the severity!");
      return;
    }
    if (incidentFormData.description === "") {
      showToast.error("Please write the description!");
      return;
    }
    try {
      setModalState(false);
      await createIncident(_id, incidentFormData);
      setIsLoading(false);
      showToast.success("Incident created successfully");
      fetchingIncident();
    } catch (error: any) {
      setIsLoading(false);
      showToast.error(error.response.data.message);
    }
  };

  const incidentEditHandler = () => {
    //Edit incident handler
    setIsEditing(true);
    setIsIncidentView(!isIncidentView);
  };
  const incidentUpdateHandler = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setIsLoading(true);
    //update incident handler
    e.preventDefault();
    if (incidentFormData.description === "") {
      showToast.error("Description Required!");
      return;
    }
    setModalState(false);
    try {
      await updateIncident(_id, incidentFormData.ID, incidentFormData);
      showToast.success("Updated Successfully");
      fetchingIncident();
      setIsLoading(false);
      setIsEditing(false);
    } catch (error: any) {
      setIsLoading(false);
      showToast.error(error.response.data.message);
    }
  };

  const paginateHandler = (e: React.ChangeEvent<unknown>, value: number) => {
    //pagination for incident
    setPageNo(value);
  };

  const incidentDeleteHandler = async (item: itemValues) => {
    //delete incident
    const confirm = window.confirm(
      "Are you sure you want to delete the Incident?"
    );
    if (confirm) {
      setIsLoading(true);
      try {
        await deleteIncident(item.projectId, item._id);
        setIsLoading(false);
        showToast.success("Delete Successful");
        fetchingIncident();
      } catch (error: any) {
        setIsLoading(false);
        showToast.error(error.response.data.message);
      }
    }
  };

  return (
    <Grid container direction="row">
      <Grid item xs={12}>
        <Grid container direction="row">
          <Grid item xs={4} alignItems="flex-start">
            <Search
              setQuery={setIncidentQuery}
              setSearchActive={setSearchIncident}
            />
          </Grid>
          <Grid item xs={8} alignItems="flex-end">
            <div className={classes.addnewcontent}>
              <Button
                label="Add a New Incident"
                isBordered={false}
                startIcon={addNewContent}
                endIcon=""
                isOutlined={false}
                onButtonClick={showModal}
                color="primary"
                isDisabled={!hasCreateAccess}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {isLoading ? (
          <div className={classes.loading}>
            <CircularProgress color="primary" />
          </div>
        ) : (
          <IncidentTable items={generateIncident()} headCells={headerCells} />
        )}
      </Grid>
      <Grid item xs={12} className={classes.paginate}>
        <Pagination
          className={classes.pagination}
          page={pageNo}
          defaultPage={pageNo}
          count={PageCount(totalData)}
          showFirstButton
          showLastButton
          onChange={paginateHandler}
        />
      </Grid>
      <Dialog
        open={modalState}
        onClose={hideModalAndClear}
        className={classes.dialog}
      >
        <FormModal
          hideModalAndClear={hideModalAndClear}
          createIncidentHandler={createIncidentHandler}
          setIncidentFormData={setIncidentFormData}
          incidentFormData={incidentFormData}
          incidentData={incidentData}
          isIncidentView={isIncidentView}
          incidentUpdateHandler={incidentUpdateHandler}
          isEditing={isEditing}
          incidentEditHandler={incidentEditHandler}
        />
      </Dialog>
    </Grid>
  );
};

export default IncidentManagement;
