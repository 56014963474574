import React from "react";
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Button,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useStyles } from "./styles";
import { contentTable } from "../../types";
import CircularProgress from "@material-ui/core/CircularProgress";

interface Iprops {
  items: contentTable[];
  headCells: {
    label: string;
    key: string;
    width: string;
  }[];
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  contentId: string;
  isLoading: Boolean;
}

const ContentTable: React.FC<Iprops> = ({ items, headCells, isLoading }) => {
  const classes = useStyles();
  const downloadFile = (value: any) => {
    return (
      <>
        <Button
          component="a"
          variant="contained"
          color="default"
          className={classes.file}
          download
          onClick={() => openNewTab(value)}
        >
          <GetAppIcon />
        </Button>
      </>
    );
  };
  const openNewTab = (link: string) => {
    window.open(link, "_blank", "noopener,noreferrer");
  };
  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table>
        <TableHead className={classes.tableHeaderRoot}>
          <TableRow>
            <TableCell className={classes.tableHeader} width="1%"></TableCell>
            {headCells.map((headCells) => (
              <TableCell
                height="40"
                key={headCells.key}
                width={headCells.width}
                align="center"
                className={classes.tableHeader}
              >
                <div
                  className={
                    headCells.key === "status"
                      ? classes.tableHeaderNoBorder
                      : classes.tableHeaderInside
                  }
                >
                  <span>{headCells.label}</span>
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {isLoading ? (
          <div className={classes.loading}>
            <CircularProgress color="primary" />
          </div>
        ) : (
          <TableBody>
            {items.map((item: contentTable, i: number) => (
              <TableRow className={classes.tableRow} key={item.id}>
                <TableCell></TableCell>
                <TableCell component="th" scope="row" align="left">
                  {item.sl_no}
                </TableCell>
                <TableCell align="left">{item.title}</TableCell>
                <TableCell align="left">{item.contentType}</TableCell>
                <TableCell align="left">
                  {item.contributor.basicDetails.fullName}
                </TableCell>
                <TableCell align="left">{item.date}</TableCell>
                <TableCell align="left">{downloadFile(item.file)}</TableCell>
                <TableCell align="left">{item.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default ContentTable;
