import { Theme, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        flexDirection: "column",
        rowGap: "1rem",
        minWidth: "26rem"
    },
    heading: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid rgba(0, 0, 0, 0.25)",
        "& > h6": {
            fontWeight: 600,
        },
        backgroundColor: "#F7F9FE",
        borderTopLeftRadius: "0.8rem",
        borderTopRightRadius: "0.8rem",
        padding: "0.8rem",
    },
    labels: {
        color: "#140047",
        fontWeight: 500,
        width: "50%",
    },
    section: {
        borderRadius: "0.8rem",
        backgroundColor: "white",
        border: "1px solid rgba(0, 0, 0, 0.25)",
        width: "100%",
        flex: 1,
    },
    mb: {
        marginBottom: "1.5rem",
    },
    gridContainer: {
        padding: "1rem",
    },
    imageDivWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "flex-start",
        padding: "1rem",
        height: "100%",
        rowGap: "1rem",
    },
    imageDiv: {
        height: "14rem",
        width: "100%",
        overflowY: "auto",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        gap: "0.7rem",
    },
    imageDivWrapper__actionButtons: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        columnGap: "1rem",
    },
    section__infos: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flex: 1,
    },
    section__info__wrapper: {
        display: "flex",
        flexDirection: "column",
        rowGap: "1rem",
        padding: "0.8rem",
        height: "17rem",
        overflowY: "auto",
    },
}));
