import {
  Avatar,
  Button,
  CircularProgress,
  createStyles,
  Divider,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import clsx from "clsx";
import grey from "@material-ui/core/colors/grey";
import { Link } from "react-router-dom";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import useFetchMemorized from "../../../hooks/useFetchMemorized";
import { getEmployeeHistoryGraph, IGraphData } from "../../../apis/kra";
import { Adapter } from "../../../utils/Adapter";

const useStyles = makeStyles<Theme, { maxHeight: number }>((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    stack: {
      display: "flex",
      alignItems: "center",
    },
    flex1: {
      flex: 1,
    },
    px2: {
      paddingInline: "16px",
    },
    my2: {
      marginBlock: "16px",
    },
    fwBold: {
      fontWeight: "bold",
    },
    grey8: {
      color: grey[800],
    },
    center: {
      height: "100%",
      display: "grid",
      placeItems: "center",
    },
    radius2: {
      borderRadius: "16px",
    },
    py2: {
      paddingBlock: "16px",
    },
    bgTransparent: {
      backgroundColor: "transparent",
    },
    border1: {
      border: "1px solid blue",
    },
    blackText: {
      color: "#000",
    },
    maxHeight: {
      maxHeight: (props) => `${props.maxHeight}px`,
    },
    tvButtonColor: {
      backgroundColor: "#140047",
      color: "#fff",
    },
  })
);

const KRAEmployeeCard: React.FC<IKRAEmployeeCardProps> = ({
  renderSparkLine,
  maxHeight,
  empId,
  overallScore,
  empName,
}) => {
  const classes = useStyles({ maxHeight: maxHeight! });

  return (
    <Paper
      variant="outlined"
      className={clsx([
        classes.radius2,
        classes.px2,
        classes.py2,
        // classes.maxHeight,
      ])}
    >
      <div className={classes.stack}>
        <Avatar className={clsx([classes.bgTransparent, classes.border1])}>
          <Typography className={classes.blackText}>
            {overallScore.toFixed()}
          </Typography>
        </Avatar>
        <Typography
          className={clsx([
            classes.flex1,
            classes.fwBold,
            classes.grey8,
            classes.px2,
          ])}
        >
          {empName}
        </Typography>
        <Button
          component={Link}
          startIcon={<VisibilityOutlinedIcon />}
          to={{
            pathname: `kra/history/${empId}`,
            state: {
              empInfo: {
                empId,
                empName,
              },
            },
          }}
          style={{ textTransform: "capitalize" }}
          variant="outlined"
        >
          View
        </Button>
      </div>
      <Divider className={classes.my2} />
      <div className={clsx([classes.my2, classes.center])}>
        {renderSparkLine()}
      </div>
    </Paper>
  );
};

export default KRAEmployeeCard;
KRAEmployeeCard.defaultProps = {
  maxHeight: 280,
};

export interface IKRAEmployeeCardProps {
  renderSparkLine: () => React.ReactNode;
  /**
   * maxHeight
   * - in pixel
   * - default value 200px
   */
  maxHeight?: number;
  /**
   * Employee ID
   * - To navigate KRA page against the given ID
   */
  empId: number;
  /**
   * Name of the Employee
   */
  empName: string;

  /**
   * Overall Score
   */
  overallScore: number;
}
