import {
  getBankInfo,
  getCheckedTasks,
  getEducationInfo,
  getEmployeeInfo,
  getFamNLangInfo,
  getRecruiteeInfo,
  getUploadedDocsApi,
} from "../../../apis/onboarding";

export const checkAdminSubmission = async (userId: string) => {
  const basicResponse = await getRecruiteeInfo(userId);
  const eduResponse = await getEducationInfo(userId);
  const famResponse = await getFamNLangInfo(userId);
  const bankResponse = await getBankInfo(userId);
  const docResponse = await getUploadedDocsApi(userId);
  const checklistResponse = await getCheckedTasks(userId);
  const empInfoResponse = await getEmployeeInfo(userId);

  // if(infoResponse.)

  if (
    (basicResponse?.birthDate === "" ||
      basicResponse?.birthDate === undefined) &&
    (basicResponse?.emergencyContact?.name === "" ||
      basicResponse?.emergencyContact?.name === undefined)
  ) {
    return false;
  }

  if (
    (eduResponse?.class10?.institute === "" ||
      eduResponse?.class10?.institute === undefined) &&
    (eduResponse?.class12?.institute === "" ||
      eduResponse?.class12?.institute === undefined)
  ) {
    return false;
  }

  if (
    (famResponse?.family?.father?.name === "" ||
      famResponse?.family?.father?.name === undefined) &&
    (famResponse?.family?.mother?.name === "" ||
      famResponse?.family?.mother?.name === undefined)
  ) {
    return false;
  }

  if (
    (bankResponse?.bankName === "" || bankResponse?.bankName === undefined) &&
    (bankResponse?.IFSC === "" || bankResponse?.IFSC === undefined)
  ) {
    return false;
  }

  if (
    (docResponse?.data?.acceptedOfferLetter === "" ||
      docResponse?.data?.acceptedOfferLetter === undefined) &&
    (docResponse?.data?.proofOfIdentity?.adhaarCard === "" ||
      docResponse?.data?.proofOfIdentity?.adhaarCard === undefined) &&
    (docResponse?.data?.proofOfIdentity?.panCard === "" ||
      docResponse?.data?.proofOfIdentity?.panCard === undefined) &&
    (docResponse?.data?.academicCertificates?.class10th === "" ||
      docResponse?.data?.academicCertificates?.class10th === undefined) &&
    (docResponse?.data?.academicCertificates?.class12th === "" ||
      docResponse?.data?.academicCertificates?.class12th === undefined)
  ) {
    return false;
  }

  if (checklistResponse.data.length !== 17) {
    return false;
  }

  if (!empInfoResponse.role) {
    return false;
  }
  return true;
};
