import { SetterOrUpdater } from "recoil";

interface setBankDetailsProps {
  getBankDetails: any;
  setBankDetailsForm: SetterOrUpdater<{
    bankName: string;
    accountNo: string;
    IFSC: string;
  }>;
}

export const handleChangeBankDetailsForm = (
  event: any,
  setBankDetailsForm: any,
  setIsBankName: React.Dispatch<React.SetStateAction<boolean>>,
  setIsAccNo: React.Dispatch<React.SetStateAction<boolean>>,
  setIsIfsc: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const allHandlers: any = {
    bankName: () => {
      setIsBankName(true);
      setBankDetailsForm((prevState: any) => ({
        ...prevState,
        bankName: event.target.value,
      }));
    },
    accountNo: () => {
      if (event.target.value?.length > 18) {
        // setIsAccNo(false);
      } else if (event.target.value?.length <= 18) {
        setIsAccNo(true);
        setBankDetailsForm((prevState: any) => ({
          ...prevState,
          accountNo: event.target.value,
        }));
      } else {
        setIsAccNo(false);
      }
    },
    ifsc: () => {
      setIsIfsc(true);
      setBankDetailsForm((prevState: any) => ({
        ...prevState,
        IFSC: event.target.value,
      }));
    },
  };

  return allHandlers;
};

export const setBankDetails = ({
  getBankDetails,
  setBankDetailsForm,
}: setBankDetailsProps) => {
  setBankDetailsForm({
    bankName: getBankDetails.bankName,
    accountNo: getBankDetails.accountNo,
    IFSC: getBankDetails.IFSC,
  });
};
