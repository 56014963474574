import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    backgroundColor: "#fff",
    padding: `${theme.typography.pxToRem(13)} ${theme.typography.pxToRem(33)}`,
  },

  formContainer: {},

  fieldontainer: {
    marginTop: theme.typography.pxToRem(5),
    display: "flex",
  },
  fieldcontainer: {
    marginTop: theme.typography.pxToRem(16),
  },

  label: {
    margin: 0,
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 600,
    color: "black",
    fontFamily: "poppins",
    marginBottom: theme.typography.pxToRem(5),
  },

  button: {
    marginRight: theme.typography.pxToRem(10),
  },

  buttonContainer: {
    marginTop: theme.typography.pxToRem(10),
  },

  fieldHide: {
    display: "none",
  },
  popup: {
    margin: "1rem",
  },
  reason: {
    marginBottom: ".5rem",
  },
  text: {
    fontWeight: 500,
  },
  actions: {
    marginTop: ".5rem",
  },
  discard: {
    marginRight: "1rem",
  },
  labelText: {
    margin: 0,
    fontSize: theme.typography.pxToRem(12),
    color: "black",
    fontFamily: "poppins",
    fontWeight: 600,
  },
  labelValue: {
    margin: 0,
    fontSize: theme.typography.pxToRem(11),
    color: "#686868",
    fontFamily: "poppins",
    fontWeight: 600,
    marginBottom: "-2px",
    marginLeft: ".5rem",
  },
  labelTexts: {
    padding: 0,
    marginLeft: 4,
    fontSize: theme.typography.pxToRem(13),
    color: "#686868",
    fontFamily: "poppins",
    fontWeight: 500,
  },
  textArea: {
    padding: 0,
    fontSize: theme.typography.pxToRem(12),
    color: "black",
    fontFamily: "poppins",
    fontWeight: 600,
  },
  inputText: {
    padding: 0,
    margin: 0,
    fontSize: theme.typography.pxToRem(12),
    color: "#686868",
    fontFamily: "poppins",
    fontWeight: 500,
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  loading: {
    position: "relative",
    left: "15rem",
  },
  leavebalance:{
    marginBottom: ".5rem"
  }
}));
