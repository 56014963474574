import { useCallback, useState } from "react";
import { useRecoilState } from "recoil";
import { saveDocument } from "../../apis/onboarding";
import { isUploadsViewEnabled } from "../../recoil/atoms";
import { documentDownloadAtom } from "../../recoil/downloadAtoms";

export type File = {
  name: string;
  value: Blob | string;
};
export const useAcademicProofs = () => {
  const formData = new FormData();
  const [academicProofs, setAcademicProof] = useState<File[]>([]);
  const [academicProgress, setAcademicProgress] = useState<number>(0);
  const [, setIsViewEnabled] = useRecoilState(isUploadsViewEnabled);
  const [, setRewriteFileDownload] = useRecoilState(documentDownloadAtom);

  const mainUrl = process.env.REACT_APP_BACKEND_URL;

  const handlePostAcademicProof = useCallback(
    async (
      showToast: {
        error: (message: string) => void;
        success: (message: string) => void;
        warning: (message: string) => void;
      },
      uploadDocsEndpointPatch: string
    ) => {
      academicProofs.forEach((file) => {
        formData.set(file.name, file.value);
      });

      const url = mainUrl + uploadDocsEndpointPatch;
      const response: any = await saveDocument(url, formData);
      if (response.status === 200) {
        showToast.success("Documents Saved!");
        setIsViewEnabled((prev) => ({
          ...prev,
          academic: true,
        }));
        setRewriteFileDownload((prev) => ({
          ...prev,
          class10: false,
          class12: false,
          diploma: false,
          grad: false,
          postGrad: false,
        }));
      } else {
        showToast.error("Something Went Wrong!");
      }
    },
    [academicProofs]
  );

  return {
    academicProofs,
    setAcademicProof,
    handlePostAcademicProof,
    academicProgress,
    setAcademicProgress,
  };
};
