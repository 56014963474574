import { Grid, Typography, Button } from "@material-ui/core";
import React from // , { useRef }
"react";
import useStyles from "./styles";
import GoogleIcon from "../../assets/images/GoogleIcon.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import rightArrow from "../../assets/images/rightArrow.png";
import { useGoogleLogin } from "@react-oauth/google";

interface props {
  handleChangeLogin: () => void;
  responseGoogle: (response: any) => void;
  submitting: boolean;
}
const SignInGoogle: React.FC<props> = ({
  handleChangeLogin,
  responseGoogle,
  submitting,
}) => {
  const classes = useStyles();
  // const googleLoginRef = useRef<any>(null);
  // const [autoLoading, setAutoLoading] = React.useState<any>(false);

  const login = useGoogleLogin({
    onSuccess: responseGoogle,
    scope:
      "email profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid",
  });

  return (
    <>
      <Grid
        container
        justifyContent="center"
        direction="column"
        className={classes.root}
      >
        <Grid item className={classes.buttonRoot}>
          <Button
            onClick={() => login()}
            className={classes.googleButtonContainer}
          >
            <Grid container spacing={1}>
              <Grid item xs={1} className={classes.googleIcon}>
                <img src={GoogleIcon} alt="google" height="40px" />
              </Grid>
              <Grid
                alignItems="center"
                item
                xs={11}
                className={classes.textContainer}
              >
                {submitting ? (
                  <CircularProgress size={35} />
                ) : (
                  <Typography
                    variant="subtitle1"
                    align="center"
                    className={classes.textColor}
                  >
                    Continue with Google
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Button>
        </Grid>
        <Grid item className={classes.adminLinkContainer}>
          <span
            className={classes.link}
            onClick={() => {
              handleChangeLogin();
            }}
          >
            Login as Admin <img src={rightArrow} alt="arrow" />
          </span>
        </Grid>
      </Grid>
    </>
  );
};

export default SignInGoogle;
