import { Button, Typography } from "@material-ui/core";
import React, { useRef, useContext } from "react";
import { postData, Toast } from "../../KnowledgeHubPage";
import { useStyles } from "./styles";
import useParentStyles from "../../styles";
import ImageDiv from "./ImageDiv";
import { uploadImage } from "../../../../apis/kHubPost";
import { useParams } from "react-router-dom";

interface props {
  postData: postData;
  setPostData: React.Dispatch<React.SetStateAction<postData>>;
}

const EditorForm: React.FC<props> = ({ postData, setPostData }) => {
  const classes = useStyles();
  const parentClasses = useParentStyles();
  const param: any = useParams();
  const showToast = useContext(Toast);

  const inputRef = useRef<HTMLInputElement>(null);

  // function that runs after selection of the files
  async function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files) {
      const selectedFiles = e.target.files;
      const files = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        if (selectedFiles[i].size > 1048576)
          return showToast.error("File size too big, must be less than 1MB");
        files.append("files", selectedFiles[i]);
      }
      try {
        const data = await uploadImage(files, param.id);
        setPostData({ ...postData, images: [...postData.images, ...data] });
        showToast.success("Images uploaded.");
      } catch (error: any) {
        showToast.error(error.message);
      }
    }
  }

  // function to trigger the file input programatically
  function addImage() {
    inputRef?.current?.click();
  }

  return (
    <div className={classes.container}>
      <section className={classes.section}>
        <Typography variant="h6" className={classes.heading}>
          New Documentation
        </Typography>
        <div className={classes.section__info__wrapper}>
          <div className={classes.section__infos}>
            <Typography variant="body1" className={classes.labels}>
              Category :
            </Typography>
            <Typography variant="body1" className={classes.labels}>
              {postData.category}
            </Typography>
          </div>
          <div className={classes.section__infos}>
            <Typography variant="body1" className={classes.labels}>
              Language :
            </Typography>
            <Typography variant="body1" className={classes.labels}>
              {postData.language}
            </Typography>
          </div>
          <div className={classes.section__infos}>
            <Typography variant="body1" className={classes.labels}>
              Framework :
            </Typography>
            <Typography variant="body1" className={classes.labels}>
              {postData.framework}
            </Typography>
          </div>
          <div className={classes.section__infos}>
            <Typography variant="body1" className={classes.labels}>
              Platform :
            </Typography>
            <Typography variant="body1" className={classes.labels}>
              {postData.platform}
            </Typography>
          </div>
          <div className={classes.section__infos}>
            <Typography variant="body1" className={classes.labels}>
              Title :
            </Typography>
            <Typography variant="body1" className={classes.labels}>
              {postData.title}
            </Typography>
          </div>
        </div>
      </section>
      <section className={classes.section}>
        <Typography variant="h6" className={classes.heading}>
          Media Library
        </Typography>
        <div className={classes.imageDivWrapper}>
          <div
            className={classes.imageDiv}
            onClick={(e: any) => {
              navigator.clipboard.writeText(e.target.id);
            }}
          >
            {postData.images
              ?.map((img) => <ImageDiv key={img._id} img={img} />)
              .reverse()}
          </div>
          <input
            ref={inputRef}
            type="file"
            accept="image/jpeg, image/jpg, image/png"
            hidden
            multiple
            onChange={handleFileChange}
          />
          <div className={classes.imageDivWrapper__actionButtons}>
            <Button
              onClick={addImage}
              variant="contained"
              disableElevation
              className={parentClasses.btnBase}
            >
              Upload
            </Button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EditorForm;
