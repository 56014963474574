import { makeStyles } from "@material-ui/core";
import { theme } from "../../theme/theme";

const useStyles = makeStyles({
  root: {
    width: "100%",
    minHeight: "calc(100vh - 19.7rem)",
  },
  paper: {
    width: "100%",
    boxShadow: "none",
    marginBottom: theme.spacing(2),
    borderRadius: "10px",
    border: "1px solid rgba(0, 0, 0, 0.15)",
  },
  table: {
    boxShadow: "none",
  },

  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tableContent: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: "#000",
    margin: 0,
  },
  title: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(11),
    color: "#FFFFFF",
    margin: 0,
    padding: 0,
  },
  head: {
    background: theme.palette.primary.main,
    padding: "8px 10px",
  },
  tableRow: {
    "&:hover": {
      boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.15)",
      zIndex: 1,

      position: "relative",
    },

    "&:nth-of-type(even)": {
      backgroundColor: "#f8f8f8",
    },

    "&:nth-of-type(odd)": {
      "&:hover": {
        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.15)",
      },
    },
  },
  bar: {
    background: "#ffffff",
    width: theme.typography.pxToRem(3),
    height: theme.typography.pxToRem(40),
    "&:nth-last-child(even)": {
      width: theme.typography.pxToRem(0),
    },
  },
  tableCell: {
    width: "150px",
    "&div:nth-last-child(n)": {
      width: theme.typography.pxToRem(0),
    },
    paddingTop: theme.typography.pxToRem(17),
    paddingBottom: theme.typography.pxToRem(17),
    "&td": {
      border: "none",
    },
  },
  search: {
    width: "80%",
    background: "#6633F2",
  },
  thRoot: {
    borderRight: "2px solid white",
    paddingRight: "5px",
  },
  thRootWithoutBorder: {
    width: "auto",
    display: "flex",
    justifyContent: "flex-start",
  },
  icons: {
    height: theme.typography.pxToRem(30),
    width: theme.typography.pxToRem(30),
  },
  circle: {
    borderRadius: "50%",
    width: theme.typography.pxToRem(6),
    height: theme.typography.pxToRem(6),
    background: "#13D902",
    marginRight: theme.typography.pxToRem(26),
    marginLeft: theme.typography.pxToRem(15),
  },
  circleRed: {
    background: "#D90202",
  },

  editControls: {
    //paddingRight: theme.typography.pxToRem(30)
  },

  edit: {
    marginRight: theme.typography.pxToRem(20),
    cursor: "pointer",
  },

  delete: {
    cursor: "pointer",
  },
  statusTag: {
    justifyContent: "flex-start",
    height: "100%",
    width: "100%",
  },
  toggler: {
    zIndex: 9,
  },
  tooltip: {
    margin: theme.spacing(2),
    fontSize:theme.typography.pxToRem(20),
  }
});

export default useStyles;
