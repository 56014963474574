import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: 600,
    fontSize: "20px",
  },
  searchDiv: {
    border: "1px solid black",
    borderRadius: 10,
    width: "425px",
    height: "47px",
    justifyContent: "space-evenly",
    paddingLeft: "2%",
    marginBottom: "2%",
  },
  searchInput: {
    paddingTop: "3%",
  },
  searchIcon: {
    paddingTop: "20%",
    paddingBottom: "25%",
    paddingLeft: 10,
  },
  closeSearchIcon: {
    paddingTop: "20%",
    color: "#BABABA",
    paddingBottom: "25%",
    paddingRight: 10,
    "&:hover": {
      color: "#14213D",
    },
  },
}));
