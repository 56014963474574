import { useStyles } from "./styles";
import { Grid, Paper, InputLabel, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { useState } from "react";
import { TASK } from "./CheckLists";

const CompletedTasks = ({ completedTasks }: { completedTasks: TASK[] }) => {
  const classes = useStyles();
  const [showCompleted, setShowCompleted] = useState<boolean>(false);

  return (
    <Paper variant="outlined" elevation={4} className={classes.paperContainer}>
      <InputLabel variant="outlined" className={classes.label} shrink>
        <b className={classes.labelColor}>Completed</b>
      </InputLabel>
      {completedTasks.length > 0 && showCompleted
        ? completedTasks.map((task, index) => {
            return (
              <Grid
                container
                direction="column"
                className={classes.taskContainer}
                key={index}
              >
                {task.item}
              </Grid>
            );
          })
        : completedTasks
            .filter((task, index) => {
              return index <= 2;
            })
            .map((data, index) => {
              return (
                <Grid
                  container
                  direction="column"
                  className={classes.taskContainer}
                  key={index}
                >
                  {data.item}
                </Grid>
              );
            })}
      <Grid
        item
        container
        justifyContent="center"
        className={classes.toggleLabels}
        onClick={() =>
          setShowCompleted((prev) => {
            return !prev;
          })
        }
      >
        {completedTasks.length > 0 ? (
          showCompleted ? (
            <Typography>
              Hide items
              <ExpandLessIcon fontSize="medium" />
            </Typography>
          ) : (
            <Typography>
              Show More Items
              <ExpandMoreIcon fontSize="medium" />
            </Typography>
          )
        ) : (
          <Typography>No Task Completed!</Typography>
        )}
      </Grid>
    </Paper>
  );
};

export default CompletedTasks;
