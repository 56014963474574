import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import empFilter from "../../assets/icons/empFilter.svg";
import empty from "../../assets/icons/empty.svg";
import { useStyles } from "./styles";

interface EmptyTableProps {
  headCells: {
    label: string | JSX.Element;
    key: string;
  }[];
}
const EmptyTable: React.FC<EmptyTableProps> = ({ headCells }) => {
  const classes = useStyles();

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              {headCells.map((header) => {
                return (
                  <TableCell
                    colSpan={6}
                    className={
                      header.label == "Status"
                        ? classes.headerStatus
                        : classes.header
                    }
                  >
                    <div>
                      {header.label != "Status" ? (
                        header.label
                      ) : (
                        <span>
                          {header.label}
                          <img src={empFilter} style={{ paddingLeft: "30%" }} />
                        </span>
                      )}
                    </div>
                  </TableCell>
                );
              })}
              <TableCell className={classes.headerStatus}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody></TableBody>
        </Table>
      </TableContainer>
      <div className={classes.emptyFieldsDiv}>
        <img src={empty} className={classes.emptyFieldsImage} />
        <p className={classes.emptyFieldsText}>
          Click {/* <a href="" className={classes.emptyFieldsLink}> */}
          Add new
          {/* </a> */} to begin with
        </p>
      </div>
    </>
  );
};

export default EmptyTable;
