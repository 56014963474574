import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import classNames from "classnames";
import { Grid, Tooltip } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import StatusCard from "../Cards/StatusCard";
import Paper from "@material-ui/core/Paper";
// import editIcon from "../../assets/icons/editBlack.svg";
import deleteIcon from "../../assets/icons/deleteBlack.svg";
import WithLoader from "../../hoc/WithLoader";
import useStyles from "./styles";
import Switch from "@material-ui/core/Switch";
// import { RowingOutlined } from "@material-ui/icons";
import { setEmployeeStatus } from "../../apis/users";
import { withStyles } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";
import { permissions } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";

/**
 * @method SimpleTable - table component of material ui
 * @param {Object} props - props passed to the component
 * @param {Array} props.items - rows of table
 * @param {Array} props.headCells - headers of table
 * @param {Array} props.isStatusIndicator - status indicator of to shown or not on first column of each row
 * @param {Array} props.isAction - action button to be shown or not on the last column of each row
 */

interface IProps {
  items: any;
  headCells: {
    label: string;
    key: string;
  }[];
  tableClickHandler?: (event: object) => void;
  inviteUserHandler?: (event: any, id: string, index: number) => void;
  deleteHolidayHandler?: (event: any, id: string, index: number) => void;
  loading?: boolean;
  clickedTableIndex?: number;
  isStatusIndicator?: boolean;
  isAction?: boolean;
  isEditDeleteEnabled?: boolean;
  changeEmpStatus?: () => void;
  showToast?: any;
}

const SimpleTable: React.FC<IProps> = ({
  items = [],
  headCells,
  tableClickHandler,
  inviteUserHandler,
  loading,
  clickedTableIndex,
  isStatusIndicator = true,
  isAction = true,
  isEditDeleteEnabled,
  deleteHolidayHandler,
  changeEmpStatus,
  showToast,
}) => {
  const classes = useStyles();
  const [switchStatus, setSwitchStatus] = useState<any>([]);
  const permission = useRecoilValue<any>(permissions);
  const isSuperRole =
    permission?.roleName ===
      ("superadmin" ||
        "Admin" ||
        "Human Resource" ||
        "Team Lead" ||
        "Engineer Head") && true;

  const leaveTypeList: any = {
    priviledge_leave: "Priviledge Leave",
    casual_leave: "Casual Leave",
    sick_leave: "Sick Leave",
  };

  const StatusCardWithLoader = WithLoader(StatusCard, {
    // width: theme.typography.pxToRem(192),
    // height: theme.typography.pxToRem(95),
    // borderRadius: '10px',
    // background: '#fff',
  });

  const ColoredSwitch = withStyles({
    switchBase: {
      color: red[500],
      "&$checked": {
        color: green[400],
      },
      "&$checked + $track": {
        backgroundColor: green[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);

  useEffect(() => {
    let data = items.map((item: any) => ({ [item.fullname]: item.empStatus }));
    setSwitchStatus(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const handleSwitchChange = (e: any, index: any) => {
    showToast.success(`changing employment status of ${e.target.name}`);
    let data = switchStatus;
    data[index] = { [e.target.name]: e.target.checked };
    setSwitchStatus(data);

    //call api to change emp status
    setEmployeeStatus(e.target.id)
      .then((response) => {
        showToast.success(
          `${response.data.fullname}'s employment status has changed`
        );
        changeEmpStatus?.();
      })
      .catch((error: any) => {
        showToast.error("Something went wrong!");
      });
  };

  return (
    <div className={classes.root}>
      <TableContainer className={classes.paper} component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          {headCells?.map((title, i) => (
            <TableCell className={classes.head}>
              <Grid
                classes={{
                  root:
                    i === headCells.length - 1
                      ? classes.thRootWithoutBorder
                      : classes.thRoot,
                }}
                container
                direction="column"
              >
                <Grid
                  style={{ padding: "8px 0" }}
                  alignItems="center"
                  justify="space-between"
                  container
                >
                  <Grid
                    xs={9}
                    container
                    justify={
                      i === 0 && isStatusIndicator ? "center" : "flex-start"
                    }
                  >
                    <span className={classes.title}>{title?.label}</span>
                  </Grid>
                </Grid>
              </Grid>
            </TableCell>
          ))}
          <TableBody>
            {items?.map((row: any, index: number) => {
              return (
                <TableRow
                  className={classes.tableRow}
                  onClick={(e) => {
                    tableClickHandler && tableClickHandler(row);

                    e.preventDefault();
                  }}
                >
                  {headCells?.map((colIdx: any, i: number) => {
                    return (
                      <TableCell classes={{ root: classes.tableCell }}>
                        <Grid
                          alignItems="center"
                          justify={i === 0 ? "flex-start" : "space-between"}
                          container
                          classes={{
                            root:
                              i === headCells.length - 1
                                ? classes.thRootWithoutBorder
                                : classes.thRoot,
                          }}
                        >
                          {i === 0 && isStatusIndicator && (
                            <Grid
                              className={classNames(classes.circle, {
                                [classes.circleRed]: !row?.empStatus,
                              })}
                            ></Grid>
                          )}

                          <Grid>
                            <span className={classes.tableContent}>
                              {colIdx?.key === "designation" ? (
                                row?.[colIdx?.key]?.designationName
                              ) : colIdx?.key === "assignedTo" ? (
                                row?.[colIdx?.key]?.map(
                                  (item: { fullname: string }) => {
                                    return item?.fullname + ", ";
                                  }
                                )
                              ) : colIdx?.key === "leave_type" ? (
                                leaveTypeList[row?.[colIdx?.key]]
                              ) : colIdx?.key === "reason" ? (
                                <Tooltip title={row?.[colIdx?.key]}>
                                  <span>
                                    {row?.[colIdx?.key].slice(0, 11) + "..."}
                                  </span>
                                </Tooltip>
                              ) : (
                                row?.[colIdx?.key]
                              )}
                            </span>
                          </Grid>

                          {i === headCells.length - 1 && (
                            <Grid
                              className={classes.statusTag}
                              onClick={(e) => {
                                inviteUserHandler &&
                                  !row?.isVerified &&
                                  inviteUserHandler(e, row?._id, index);
                              }}
                              xs={6}
                            >
                              {isStatusIndicator && !row?.isVerified && (
                                <StatusCardWithLoader
                                  isLoading={
                                    loading && clickedTableIndex === index
                                      ? true
                                      : false
                                  }
                                  label={
                                    row?.isInviteSent
                                      ? row?.isVerified
                                        ? "Successful"
                                        : "Pending "
                                      : "Send invite"
                                  }
                                  color={
                                    row?.isInviteSent
                                      ? row?.isVerified
                                        ? "green"
                                        : "yellow"
                                      : "blue"
                                  }
                                />
                              )}
                              {row?.isVerified && (
                                <Grid
                                  container
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  {/* {console.log(
                                    "user response i",
                                    switchStatus[index],
                                    switchStatus[index]?.[row?.fullname]
                                  )} */}
                                  <ColoredSwitch
                                    id={row?._id}
                                    checked={
                                      switchStatus[index]?.[row?.fullname]
                                        ? true
                                        : false
                                    }
                                    onChange={(e: any) =>
                                      handleSwitchChange(e, index)
                                    }
                                    name={row?.fullname}
                                    onClick={(e: any) => {
                                      e.stopPropagation();
                                    }}
                                    inputProps={{
                                      "aria-label": "secondary checkbox",
                                    }}
                                  />
                                </Grid>
                              )}
                              {isEditDeleteEnabled && (
                                <Grid
                                  xs={8}
                                  container
                                  justify="flex-end"
                                  onClick={(e: any) => {
                                    deleteHolidayHandler &&
                                      deleteHolidayHandler(e, row?._id, index);
                                  }}
                                  className={classes.editControls}
                                >
                                  <img
                                    className={classes.delete}
                                    src={deleteIcon}
                                    alt="delete"
                                    id="delete"
                                  />
                                </Grid>
                              )}
                            </Grid>
                          )}
                        </Grid>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SimpleTable;
