import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getEmployeeKraListAPI } from "../../../apis/kra";
import { Loader } from "../../../components/Preloader/Preloader";
import { PERSISTENT_AUTHENTICATION } from "../../../utils/constants";
import { getPersistentAuthentication } from "../../../utils/functions";
import KRALayout from "../components/KRALayout";
import KRATable, { ITableData } from "../components/KRATable";

const EmployeeView = () => {
  const [tableData, setTableData] = useState<ITableData[]>([]);
  const user = getPersistentAuthentication();
  const history = useHistory();

  const getEmployeeKra = async () => {
    try {
      const res = await getEmployeeKraListAPI();
      setTableData(res.data);
    } catch (error) {
      console.error("err", error);
    }
  };

  useEffect(() => {
    if (user.username) {
      history.push({
        pathname: `/app/kra/history/${user.userId}`,

        state: {
          empInfo: {
            empName: "Some",
            empId: "some",
          },
        },
      });
    }
    getEmployeeKra();
  }, [user]);

  if (!user) {
    return <Loader defaultLoading={false} children={undefined} />;
  }

  return (
    <KRALayout>
      <KRATable
      // tableData={tableData}
      />
    </KRALayout>
  );
};

export default EmployeeView;
