import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: 600,
    fontSize: "20px",
  },
  header: {
    paddingLeft: "1%",
    justifyContent: "space-between",
  },
}));
