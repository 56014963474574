import React, { useState } from "react";
import { useStyles } from "./styles";
import TrackerGraphIcon from "../../assets/icons/tracker-graph.svg";
import SelectField from "../Fields/SelectField";
import BarChart from "./BarChart";
import ColoredBar from "./ColoredBar";

const TrackerGraph: React.FC = () => {
  const classes = useStyles();
  const [period, setPeriod] = useState("");

  const handleSelect = (value: any) => {
    
    setPeriod(value.target.value);
  };

  return (
    <div className={classes.graphContainer}>
      <div className={classes.graphTop}>
        <div className={classes.graphHeader}>
          <div className={classes.trackerIconDiv}>
            <img
              className={classes.trackerIcon}
              src={TrackerGraphIcon}
              alt="tracker-icon"
            />
            <div>Tracker</div>
          </div>
          <div>
            <SelectField
              type="tracker"
              options={["this week", "this month"]}
              onSelect={handleSelect}
            />
          </div>
        </div>
        <div className={classes.barContainer}>
          <BarChart period={period} />
        </div>
      </div>
      <div className={classes.graphBottom}>
        <div className={classes.bars}>
          <div className={classes.bottomContainer}>
            <div className={classes.barDot}>
              {[1, 2, 3, 4, 5].map((item) => {
                return <ColoredBar type="half" />;
              })}
            </div>
            <div className={classes.trackerLegend}>Half Day</div>
          </div>
          <div className={classes.bottomContainer}>
            <div className={classes.barDot}>
              {[1, 2, 3, 4, 5].map((item) => {
                return <ColoredBar type="full" />;
              })}
            </div>
            <div className={classes.trackerLegend}>Full Day</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackerGraph;
