import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    graphContainer: {
        height: '95%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '15px',
        marginLeft: '10px',
        backgroundColor: 'white',
        borderRadius: '10px',
        border: '1px solid rgba(0, 0, 0, 0.15)',
        overflow: 'hidden',
       
    },
    graphHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '3% 5%'
    },
    trackerIconDiv: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(15)
    },
    trackerIcon: {
        marginRight: theme.typography.pxToRem(5)
    },
    graphBottom: {
      
        flex: 1,
        borderTop: '1px solid rgba(0, 0, 0, 0.15)',
        display: 'flex',
        justifyContent: 'space-evenly'
      },
      graphTop: {
        flex: 5,
           overflow: 'hidden'
      },
      bars: {
          display: 'flex',
          alignItems: 'center',
          width: '50%',
          justifyContent: 'space-around'
      },
      bottomContainer: {
          display: 'flex',
          alignItems: 'center',
          
      },
      barDot: {
          display: 'flex'
      },
      trackerLegend: {
          fontSize: theme.typography.pxToRem(12),
          marginLeft: '5px'
      },
      barContainer: {
          display: 'flex',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
          
      }
      
     
      
        
        
        
      

    

}))