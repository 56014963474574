import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import classNames from "classnames";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useStyles from "./styles";
import Badge from "@material-ui/core/Badge";
import { Grid, Button, Input } from "@material-ui/core";
import "./styles.css";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Search } from "@material-ui/icons";
import _ from "lodash";

var newSearchName: any;

interface IProps {
  data?: {
    title: string;
    component: any;
    shouldDisplay?: boolean;
    shouldDisplayBadge?: number;
    shouldSort?: boolean;
    changeSortTag?: any;
    handleNameSearch?: any;
  }[];
}

const leaveTypeTag: any = {
  casual_leave: "Casual Leave",
  sick_leave: "Sick Leave",
  priviledge_leave: "Priviledge Leave",
  all: "All",
};

const SimpleAccordion: React.FC<IProps> = ({ data }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>("panel0");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [sortNameTag, setSortNameTag] = React.useState<string>("");

  /**
   * Sets the anchor element to display sort selection popover menu
   * @param {React.MouseEvent<HTMLButtonElement>} event
   * @returns nothing
   */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    
  };

  /**
   * Sets the selected sort tag to generate a new array list
   * @param {string} nameString
   * @returns nothing
   */
  const setNameTag = (nameString: string) => {
    setSortNameTag(nameString);
  };

  /**
   * Removes the anchor element to close the sort selection popover menu
   * @returns nothing
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * @description Function to expand or contract the accordion
   * @method handleChange
   * @param {string} panel
   * @returns nothing
   */
  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      if (panel !== expanded) {
        setExpanded(isExpanded ? panel : false);
      }
    };

  return (
    <div className={classes.root}>
      {data?.map((accor, index) => {
        const { shouldDisplay = true, shouldDisplayBadge = false } = accor;
        return (
          <Accordion
            className={classNames(classes.accordionComp, {
              [classes.accordionHide]: !shouldDisplay,
            })}
            style={{ marginBottom: data.length - 1 !== index ? "1rem" : 0 }}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummary
              classes={{
                root: classes.accordionHeader,
                expanded: classes.expanded,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}bh-content`}
              id={`panel${index}bh-header`}
            >
              <Badge
                color="primary"
                invisible={shouldDisplayBadge ? false : true}
                badgeContent={shouldDisplayBadge}
                className={classes.badge}
              >
                <Typography className={classes.heading}>
                  {accor.title}
                </Typography>
              </Badge>
              {accor.shouldSort && (
                <Grid container justifyContent="flex-end" alignItems="center">
                  <div className={classes.sortTag}>
                    <Grid>
                      <Input
                        id="employee_name"
                        name="employee_name"
                        type=""
                        placeholder="Search employee"
                        className={classes.searchField}
                        endAdornment={<Search />}
                        onChange={(e: any) => {
                          if (newSearchName) {
                            newSearchName.cancel();
                          }
                          newSearchName = _.debounce(() => {
                            accor.handleNameSearch?.(e.target.value);
                          }, 1000);
                          newSearchName();
                        }}
                        onClick={(e: any) => {
                          e.stopPropagation();
                        }}
                      />
                      <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        variant="outlined"
                        onClick={(e: any) => {
                          e.stopPropagation();
                          handleClick(e);
                          
                        }}
                      >
                        Sort by : {sortNameTag}
                      </Button>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <MenuItem
                          id="all"
                          onClick={(e: any) => {
                            e.stopPropagation();
                            
                            setNameTag(leaveTypeTag[e.target.id]);
                            accor.changeSortTag?.(leaveTypeTag[e.target.id]);
                            handleClose();
                          }}
                        >
                          All
                        </MenuItem>
                        <MenuItem
                          id="casual_leave"
                          onClick={(e: any) => {
                            e.stopPropagation();
                            
                            setNameTag(leaveTypeTag[e.target.id]);
                            accor.changeSortTag?.(leaveTypeTag[e.target.id]);
                            handleClose();
                          }}
                        >
                          Casual Leave
                        </MenuItem>
                        <MenuItem
                          id="sick_leave"
                          onClick={(e: any) => {
                            e.stopPropagation();
                            
                            setNameTag(leaveTypeTag[e.target.id]);
                            accor.changeSortTag?.(leaveTypeTag[e.target.id]);
                            handleClose();
                          }}
                        >
                          Sick Leave
                        </MenuItem>
                        <MenuItem
                          id="priviledge_leave"
                          onClick={(e: any) => {
                            e.stopPropagation();
                            
                            setNameTag(leaveTypeTag[e.target.id]);
                            accor.changeSortTag?.(leaveTypeTag[e.target.id]);
                            handleClose();
                          }}
                        >
                          Priviledge Leave
                        </MenuItem>
                      </Menu>
                    </Grid>
                  </div>
                </Grid>
              )}
            </AccordionSummary>
            <AccordionDetails className={classes.accordionBody}>
              {accor.component}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default SimpleAccordion;
