import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Table from "../../../components/Table";
import { useStyles } from "./styles";
import { WEB_SOCKET_URL } from "../../../config";
import { io } from "socket.io-client";
import _ from "lodash";

const HEAD_CELLS = [
  { key: "name", label: "Name" },
  { key: "startDate", label: "Start Date" },
  { key: "endDate", label: "End Date" },
  { key: "by", label: "Approved/Rejected By" },
  { key: "type", label: "Type" },
  { key: "status", label: "Status" },
  { key: "reason", label: "Reason" },
];
interface IProps {
  parentHeight?: number | undefined;
  sortTag?: string;
  searchName?: string;
  tableItems:any;
}

const DetailedLeaveReport: React.FC<IProps> = ({
  tableItems
}) => {
  const classes = useStyles();
  const [socketStatus, setSocketStatus] = useState<boolean>(false);

  useEffect(() => {
    let socket = io(`${WEB_SOCKET_URL}`, { transports: ["websocket"] });
    socket.on("message", (message: string) => {
      setSocketStatus(!socketStatus);
    });

    return () => {
      socket.disconnect();
    };
  }, [socketStatus]);
  

  return (
    <Grid
      container
      className={classes.tableContainer}
      style={{ height: "76vh" }}
    >
        <Table
        headCells={HEAD_CELLS}
        items={tableItems}
        isStatusIndicator={false}
        isAction={false}
      />
    </Grid>
  );
};

export default DetailedLeaveReport;
