import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    background: "#fff",
    borderRadius: "10px",
    padding: `${theme.typography.pxToRem(0)} ${theme.typography.pxToRem(
      0
    )} ${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(20)}`,
  },
  top: {
    flex: 1,
  },
  mid: {
    flex: 4,
    paddingTop: theme.typography.pxToRem(15),
  },
  bottom: {
    flex: 5,
  },
  back: {
    margin: 0,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
    fontFamily: "poppins",
    marginRight: theme.typography.pxToRem(12),
  },
  buttonSave: {
    marginLeft: theme.typography.pxToRem(10),
  },
  formContainer: {
    paddingRight: theme.typography.pxToRem(50),
    borderRight: "1px solid rgba(0, 0, 0, 0.25)",
  },
  fieldontainer: {
    marginTop: theme.typography.pxToRem(13),
  },
  radioGroup: {
    flexDirection: "row",
  },
  icon: {
    borderRadius: "50%",
    width: 20,
    height: 20,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {},
    "input:disabled ~ &": {
      boxShadow: "none",
    },
  },
  checkedIcon: {
    backgroundColor: "#000",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 20,
      height: 20,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#000",
    },
  },
  assignedDropdown: {
    position: "absolute",
    border: "1px solid rgba(20, 33, 61, 0.1)",
    boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
    background: "#fff",
    padding: theme.typography.pxToRem(10),
    zIndex: 3,
  },
  assignedDropdownHide: {
    display: "none",
  },
  checkboxLabel: {
    margin: 0,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 300,
    fontFamily: "poppins",
  },
  sameAsPermanentAddress: {
    width: "40px",
    margin: "8px 4px",
  },
}));
