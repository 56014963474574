// PLUGINS IMPORTS //
import React, { FC } from "react";
import { at } from "lodash";
import { FormikProps, useField } from "formik";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core";

import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Switch, { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";
import { useStyles } from "./AccordionComponent/styles";

// COMPONENTS IMPORTS //

// EXTRA IMPORTS //

/////////////////////////////////////////////////////////////////////////////
interface delependentPropType {
  dependentFieldName: string;
  conditions: {
    currentFieldConditionValue: string | boolean | number;
    dependentFieldValue: string | boolean | number;
  }[];
}
interface PropsType {
  name: string;
  label?: string;
  isEditableProps: boolean;
  colorNotCheckedBall?: string;
  colorCheckedBall?: string;
  colorCheckedBg?: string;
  dependentFields?: delependentPropType[];
  formikProps?: FormikProps<any>;
}

const SwitchField: FC<PropsType> = (props) => {
  const { label, ...restProps } = props;
  const [field, meta, helper] = useField(props);
  const { setValue } = helper;
  const { isEditableProps } = props;
  const { dependentFields } = props;

  //style
  let colorNotCheckedBall = "red";
  let colorCheckedBall = "#14213D";
  let colorCheckedBg = "blue";
  if (props.colorNotCheckedBall) {
    colorNotCheckedBall = props.colorNotCheckedBall;
  }
  if (props.colorCheckedBall) {
    colorCheckedBall = props.colorCheckedBall;
  }
  if (props.colorCheckedBg) {
    colorCheckedBg = props.colorCheckedBg;
  }

  const CustomSwitch = withStyles({
    switchBase: {
      color: colorNotCheckedBall,
      "&$checked": {
        color: colorCheckedBall,
      },
      "&$checked + $track": {
        backgroundColor: colorCheckedBg,
      },
    },
    checked: {},
    track: {},
  })(Switch);

  function renderHelperText() {
    const [touched, error] = at(meta, "touched", "error");
    if (touched && error) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  const setDependable = (selectedValue: boolean) => {
    if (dependentFields) {
      dependentFields.find((dependentObj: any) => {
        dependentObj.conditions.find((conditionObj: any) => {
          if (conditionObj.currentFieldConditionValue === selectedValue) {
            //set the dependent field value
            props.formikProps?.setFieldValue(
              dependentObj.dependentFieldName,
              conditionObj.dependentFieldValue
            );
          }
        });
      });
    }
  };

  function onChangeFunc(e: any) {
    setValue(e.target.checked);
    setDependable(e.target.checked);
  }

  return (
    <FormControl {...restProps}>
      <FormControlLabel
        value={field.checked}
        checked={field.value}
        disabled={!isEditableProps}
        control={<CustomSwitch {...field} onChange={onChangeFunc} />}
        // control={
        //   <Switch
        //     {...field}
        //     onChange={onChangeFunc}
        //     className={classes.switchStyle}
        //   />
        // }
        label={label}
      />
      {renderHelperText()}
    </FormControl>
  );
};

export default SwitchField;
