import { SvgIcon } from "@material-ui/core";
import React from "react";

const CirclePlusIcon: React.FC = () => {
    return (
        <SvgIcon viewBox="-2 -2 24 24">
            <path d="M10 19.1654C4.93729 19.1654 0.833374 15.0614 0.833374 9.9987C0.833374 4.93595 4.93729 0.832031 10 0.832031C15.0628 0.832031 19.1667 4.93595 19.1667 9.9987C19.1667 15.0614 15.0628 19.1654 10 19.1654ZM9.08337 9.08203H5.41671V10.9154H9.08337V14.582H10.9167V10.9154H14.5834V9.08203H10.9167V5.41536H9.08337V9.08203Z" />
        </SvgIcon>
    );
};

export default CirclePlusIcon;
