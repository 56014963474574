import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { getEducationInfo } from "../../../../../apis/onboarding";
import { onboardingIdAtom } from "../../../../../recoil/atoms";
import EducationalQualificationEdit from "../../../../AccordionComponent/EducationalQualificationEdit";

const EducationQualifications = () => {
  const onboardingIds = useRecoilValue(onboardingIdAtom);
  const [userRole, setUserRole] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [toggleUser, setToggleUser] = useState(false);
  const [educationData, setEducationData] = useState({});


  const getEduInfoFunc = async () => {
    if (
      onboardingIds.eduId === null ||
      onboardingIds.eduId === undefined ||
      onboardingIds.eduId === ""
    ) {
      return;
    }
    const response = await getEducationInfo(onboardingIds.eduId);
    setEducationData(response);
    setToggleUser(!toggleUser);
  };

  useEffect(() => {
    getEduInfoFunc();
  }, [onboardingIds.eduId]);

  useEffect(() => {
    const role = window.sessionStorage.getItem("userRole") as string;
    const access_token = window.sessionStorage.getItem(
      "access_token"
    ) as string;

    setUserRole(role);
    setAccessToken(access_token);
  }, [toggleUser]);

  return (
    <div>
      <EducationalQualificationEdit
        educationalQualificationInitialData={educationData}
        employeeId={onboardingIds.eduId}
        calledFromEmployee={false}
      />
    </div>
  );
};

export default EducationQualifications;
