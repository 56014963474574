import { Grid } from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import { leaveApply, leaveRequest } from "../../../apis/leaves";
import Button from "../../../components/CustomButton";
import Popup from "../../../components/Popup";
import { LeaveTypes } from "../LeavesPage";
import RequestLeaveForm from "../RequestLeaveResponse";
import { RequestedLeave } from "../types";
import ApplyLeaveForm from "./ApplyLeaveForm";
import { useStyles } from "./styles";
import { LeaveForm } from "./types";

const DEFAULT_FORM_VALUES: LeaveForm = {
  reason: "",
  duration: 1,
  from: new Date(),
  to: new Date(),
  leave_type: "", 
  day_type: "fullDay",
};

interface IProps {
  parentHeight?: number | undefined;
  leaveTypesData: LeaveTypes[];
  showToast: any;
  toggleRefresh: () => void;
  refreshLeaves?: () => void;
  getUserLeave: () => void;
  setIsLoading: React.Dispatch<React.SetStateAction<Boolean>>;
}

const ApplyLeave: React.FC<IProps> = ({
  parentHeight,
  leaveTypesData,
  showToast,
  getUserLeave,
  setIsLoading,
  
}) => {
  const classes = useStyles();
  const [formData, setFormData] = useState<LeaveForm>(DEFAULT_FORM_VALUES);
  const [isToDisabled, setIsToDisabled] = useState<boolean>(false);
  const [openRequestModal, setOpenRequestModal] = useState<boolean>(false);
  const [requestedLeave, setRequestedLeave] = useState<RequestedLeave>();
  const [loadingRequestedLeave, setLoadingRequestedLeave] = useState<boolean>(true);  


  const DayTypeMap ={
    firstHalf : "firstHalf",
    secondHalf: "secondHalf",
    fullDay: "fullDay", 
  } as const ;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let draftFormData = {
      ...formData,
      [event.target.name]: event.target.value,
    };   

    const fromWithoutTimeZone = moment(draftFormData.from, "YYYY-MM-DD").format(
      "YYYY-MM-DD"
    );
    const duration =
      moment(draftFormData.to).diff(fromWithoutTimeZone, "days") + 1;
    
    
      if (event.target.name === "from" || event.target.name === "to"){
        draftFormData = {
          ...draftFormData,
          [event.target.name]: new Date(event.target.value),
          duration:duration,
        };
        if(formData.day_type === DayTypeMap.firstHalf || formData.day_type === DayTypeMap.secondHalf ){
          draftFormData={
            ...draftFormData,
            to:draftFormData.from,
            duration:0.5,
          }
        }
        else{
          draftFormData = {
            ...draftFormData,
            duration: duration,
          };

        }

      }
   
    if (event.target.name === "day_type") {
      setIsToDisabled(false);
      const day_type = event.target.value;
      if (day_type === DayTypeMap.firstHalf || day_type === DayTypeMap.secondHalf) {
        setIsToDisabled(true);
        draftFormData = {
          ...draftFormData,
          to: draftFormData.from,
          duration: 0.5,
          
        };
      }
       else {
        draftFormData = {
          ...draftFormData,
          duration: duration,
        };
      }
    }
  
    if(event.target.name === "leave_type"){
      if(formData.day_type === DayTypeMap.firstHalf || formData.day_type === DayTypeMap.secondHalf){
        setIsToDisabled(true);
        draftFormData = {
          ...draftFormData,
          to:draftFormData.from,
          duration: 0.5,
        };
      }
      else{
        draftFormData = {
          ...draftFormData,
          duration: 1,
          from:new Date(),
          to:new Date(),
        };
      }
    } 
      
    setFormData(draftFormData);
   
  };

  /**
   * @description Function to submit Apply leave form data
   * @method handleSubmit
   * @returns nothing
   */

  const handleSubmit = async () => {
    let isValid = true;

    const reasonEmpty = !formData.reason  || /^\s+$/.test(formData?.reason);
    isValid = reasonEmpty || formData.leave_type === "" ? false :true;
    if (!isValid) {
      showToast.error("All fields are required");
      return;
    }
    try {
      setOpenRequestModal(true);
      setLoadingRequestedLeave(true);
      let data = await leaveRequest({
        leaveType: formData.leave_type,
        dayType: formData.day_type,
        from:formData.from,
        to: formData.to,
        reason: formData.reason,
      });
      let requestLeaveData = data?.data;
      setRequestedLeave(requestLeaveData);
      setLoadingRequestedLeave(false);
    } catch (err: any) {
      setOpenRequestModal(false);
      showToast.error(err.response.data.message);
    }
  };

  const applyHandler = async () => {
    setIsLoading(true);
    try {
      await leaveApply({
        adjustAmount: requestedLeave?.adjustAmount ?? 0 ,
        updatedBalance: requestedLeave?.updatedBalance ,
        adjustLeaveType: requestedLeave?.adjustLeaveType ,
        day_type: requestedLeave?.day_type,
        duration: requestedLeave?.duration,
        from: requestedLeave?.from ,
        leave_type: requestedLeave?.leave_type,
        notes: requestedLeave?.notes,
        reason: requestedLeave?.reason,
        to: requestedLeave?.to,
        warnings: requestedLeave?.warnings,
      } as RequestedLeave);
      handleCloseRequestModal();
      getUserLeave();
      setFormData(DEFAULT_FORM_VALUES);
      setIsLoading(false);
      showToast.success("Leave Applied successfully");

    } catch (error: any) {
      handleCloseRequestModal();
      setIsLoading(false);
      showToast.error(error.response.data.message);
    }
  };

  /**
   * @description Function to delete a form node
   * @method handleDelete
   */

  const handleDelete = () => {
    let draftFormData = formData;
    setFormData(draftFormData);
  };

  /**
   * @description Function to discard all form data from a particular node
   * @method handleDiscard
   * @returns nothing
   */

  const handleDiscard = () => {
    setFormData({ ...DEFAULT_FORM_VALUES });
    if (isToDisabled) {
      setIsToDisabled(false);
    }
  };

  let Adminheight = parentHeight ? `${parentHeight - 115}px` : "auto";

  const handleCloseRequestModal = () => {
    setOpenRequestModal(false);
  };
 
  return (
    <>
      <Grid
        container
        className={classes.AdminContainer}
        style={{ height: Adminheight }}
      >
        <form className={classes.form}>
          <ApplyLeaveForm
            leaveTypesData={leaveTypesData}
            fieldsState={formData}
            handleChange={(e) => handleChange(e)}
            handleDelete={handleDelete}
          />

          <Grid justifyContent="flex-end" container className={classes.buttonGroup} >
            <Grid item xs={2} className={classes.button}>
              <Button
                label="Clear"
                isDisabled={false}
                isBordered={false}
                startIcon=""
                endIcon=""
                isOutlined={true}
                onButtonClick={handleDiscard}
                color="primary"
              />
            </Grid>
            <Grid xs={2} item>
              <Button
                label="Request"
                isDisabled={false}
                isBordered={false}
                startIcon=""
                endIcon=""
                isOutlined={false}
                color="primary"
                onButtonClick={handleSubmit}
              />
              {openRequestModal && (
                <Popup
                  name="Confirm Your Leave"
                  open={openRequestModal}
                  handleClose={handleCloseRequestModal}
                  close={true}
                >
                  <RequestLeaveForm
                    handleSubmit={handleSubmit}
                    handleDiscard={handleCloseRequestModal}
                    requestedLeave={requestedLeave}
                    loadingRequestedLeave={loadingRequestedLeave}
                    applyHandler={applyHandler}
                  />
                </Popup>
              )}
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
};

export default ApplyLeave;
