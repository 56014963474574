import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { editRole, getRolePermissions } from "../../apis/roles";
import { useStyles } from "./styles";

interface Props {
  handleFunc: (event: boolean, basic?: boolean) => void;
  roleID: string;
}

type BaseType = {
  _id: string;
};

type IOperation = {
  create: boolean;
  read: boolean;
  upDate: boolean;
  del: boolean;
  adjust?: boolean;
  approve?: boolean;
  grant?: boolean;
  cancel?: boolean;
  _id: string;
};

type IOperationKeys = Exclude<keyof IOperation, "_id">;

interface IPermissions {
  _id: string;
  permissions: BaseType & {
    [key: string]: IOperation;
  };
  roleName: string;

  _v: number;
}

interface IProps {
  showToast: {
    error: (message: string) => void;
    success: (message: string) => void;
    warning: (message: string) => void;
  };
}

const RolePermission = ({ showToast }: IProps) => {
  const [permission, setPermission] = useState<IPermissions | null>(null);
  const { rid } = useParams<{ rid: string }>();
  const roleID: string = rid;
  const classes = useStyles();

  useEffect(() => {
    getPermissions();
  }, []);

  async function getPermissions() {
    const res = await getRolePermissions(roleID);

    setPermission(res.data);
    setValues(res.data);
  }

  async function editUserRole(values: {}, roleID: string) {
    const res = await editRole(values, roleID);
    if (res.status === 200) {
      showToast.success("Information Saved!");
    } else {
      showToast.error("something went wrong");
    }
  }

  let modules: string[] = [];
  if (permission?.permissions) {
    modules = Object.keys(permission?.permissions);
  }

  const { values, handleChange, handleSubmit, setValues } = useFormik({
    initialValues: permission!,
    onSubmit: (vals) => {
      editUserRole(vals, roleID);
    },
  });

  return (
    <>
      <Box
        style={{
          display: "flex",
        }}
      >
        <NavLink to={"/app/roles"}>
          <Button
            variant="contained"
            size="large"
            className={classes.backButton}
          >
            Back
          </Button>
        </NavLink>
      </Box>
      <Paper
        style={{
          padding: "1em 1em",
          marginTop: "1em",
          borderRadius: "10px",
        }}
      >
        <TextField
          className={classes.roleNameInput}
          variant="outlined"
          size="small"
          fullWidth
          name={"roleName"}
          value={values?.roleName}
          onChange={handleChange}
        />

        <Typography component={"div"} className={classes.permissionsText}>
          Permissions
        </Typography>

        <Grid container xl={12}>
          <Grid
            item
            xl={12}
            style={{
              marginTop: "1em",
              borderRadius: "4px",
            }}
          >
            <form onSubmit={handleSubmit}>
              <Box>
                {modules.length < 1 ? (
                  <Paper className={classes.permissionContainer}>
                    <CircularProgress disableShrink />
                  </Paper>
                ) : (
                  modules.map((moduleName: string) => {
                    const operationsObj = permission?.permissions[moduleName];
                    const { _id, ...rest } = operationsObj!;
                    if (moduleName === "_id") {
                      return;
                    }

                    return (
                      <Box className={classes.permissionCard}>
                        <Typography
                          style={{
                            fontSize: "1em",
                            color: "#140047",
                            fontWeight: "600",
                            paddingLeft: "0.6em",
                          }}
                        >
                          {moduleName.toLocaleUpperCase()}
                        </Typography>
                        <Box
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          {Object.keys(rest).map((key: string) => (
                            <>
                              <Box
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: "20rem",
                                }}
                              >
                                <label
                                  style={{
                                    color: "gray",
                                  }}
                                >
                                  {values?.permissions && (
                                    <Checkbox
                                      color="primary"
                                      name={`permissions.${moduleName}.${key}`}
                                      checked={
                                        values.permissions[moduleName][
                                          key as unknown as IOperationKeys
                                        ]
                                      }
                                      onChange={handleChange}
                                    />
                                  )}

                                  {key === "del"
                                    ? "Delete"
                                    : key
                                        .toLowerCase()
                                        .charAt(0)
                                        .toUpperCase() +
                                      key.toLowerCase().slice(1)}
                                </label>
                              </Box>
                            </>
                          ))}
                        </Box>
                      </Box>
                    );
                  })
                )}
              </Box>
              <Box
                style={{
                  display: "flex",
                  gap: "2rem",
                  marginTop: "1rem",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  style={{
                    marginLeft: "auto",
                    width: "10em",
                  }}
                >
                  save
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default RolePermission;

//comment
