import { makeStyles, Theme } from "@material-ui/core/styles";
import { Height } from "@material-ui/icons";

export const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    width: "100%",
    height: "100vh",
    position: "fixed",
    backgroundColor: "rgba(0,0,0,0.4)",
  },
  containerBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "inherit",
  },
  contentBox: {
    display: "flex",
    padding: "1em",
    flexDirection: "column",
    borderRadius: "4px",
    justifyContent: "center",
    alignItems: "center",
    background: "white",
  },
  searchBarTextField: {
    color: "black",
    fontSize: "1em",
    flex: 1,
    borderRadius: "10px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  optionTextField: {
    fontSize: "1em",
    fontWeight: "bold",
    color: "black",
    alignItems: "center",
    marginTop: "1em",
    width: "20em",
  },
  userDataContainer: {
    padding: "0.5em",
    overflowY: "scroll",
    height: "26em",
    marginBottom: "1em",
    width: "24em",
    backgroundColor: "#e9e8ed",
    borderRadius: "4px",

    scrollbarColor: "unset",

    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  searchBarContainer: {
    display: "flex",
    // justifyContent: "space-around",
    alignItems: "center",
    border: "2px solid #140047",
    width: "26em",
    borderRadius: "4px",
    boxShadow: "none",
  },
  userDataCard: {
    display: "flex",
    alignItems: "center",
    width: "24em",
    gap: "1em",
    paddingLeft: "0.8em",
    marginBottom: "0.4em",
    borderRadius: "4px",
    borderBottom: "2px solid #fff",
  },
}));
