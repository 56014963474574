import { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useStyles } from "./style";
import { HeadCells } from "../IncidentManagement";
import {itemValues} from "../IncidentManagement";

interface Iprops {
  items: itemValues[];
  headCells: HeadCells[];
}

const IncidentTable: React.FC<Iprops> = ({
  items = [],
  headCells,
}) => {
  const classes = useStyles();
  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table>
        <TableHead className={classes.tableHeaderRoot}>
          <TableRow>
            {headCells.map((headCells) => (
              <TableCell
                height="30"
                key={headCells.id}
                align="center"
                className={classes.tableHeader}
              >
                <div
                  className={
                    headCells.id === "Description"
                      ? classes.tableHeaderNoBorder
                      : classes.tableHeaderInside
                  }
                >
                  <span>{headCells.label}</span>
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
          <TableBody>
            {items.map((item: any, i: number) => (
              <TableRow className={classes.tableRow} key={i}>
                <TableCell component="th" scope="row" align="center">
                  {item.incidentId}
                </TableCell>
                <TableCell align="left">{item.date}</TableCell>
                <TableCell align="left">{item.reportedBy}</TableCell>
                <TableCell align="left">{item.severity}</TableCell>
                <TableCell align="left">{item.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
      </Table>
    </TableContainer>
  );
};

export default IncidentTable;
