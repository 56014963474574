import React, { useState, useEffect,useContext } from "react";
import { Typography, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import RoundedBoxWIthTitle from "../../../RoundBoxWithTitle";
import LeaveBalanceForm from "./LeaveBalanceForm";
import { getEmployeeLeave,updatedLeaveBalance } from "../../../../apis/employees";
import EditIcon from "@material-ui/icons/Edit";
import { Toast } from "../../../../pages/EmployeesPage/EmployeesPage";
import {employeeLeaveAdjust} from "../../../../utils/functions";

import { useStyles } from "./style";
import { usePermissionObjectFor } from "../../../../hooks/permission/usePermissionObjectFor";


export interface leaveBalancetype {
  privilegeLeave: {
    taken: number;
    allotted: number;
  };
  casualLeave: {
    taken: number;
    allotted: number;
  };
  sickLeave: {
    taken: number;
    allotted: number;
  };
}
interface LeaveBalanceUpdate {
  employeeId: string;
}

const leaveType = [
  { key: "privilegeLeave", label: "Priviledge Leave" },
  { key: "casualLeave", label: "Casual Leave" },
  { key: "sickLeave", label: "Sick Leave" },
];

const LeaveBalanceUpdate: React.FC<LeaveBalanceUpdate> = ({ employeeId }) => {
  const classes = useStyles();
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [leaveBalance, setLeaveBalance] = useState<leaveBalancetype>({
    privilegeLeave: {
      taken: 0,
      allotted: 0,
    },
    casualLeave: {
      taken: 0,
      allotted: 0,
    },
    sickLeave: {
      taken: 0,
      allotted: 0,
    }
  });
  const showToast = useContext(Toast);

  const permission = usePermissionObjectFor("leave");
  const hasAdjustLeaveAccess = permission.includes("adjust");
  useEffect(() => {
    getUserLeave();
    
  }, []);

  async function getUserLeave() {
    try {
      let data = await getEmployeeLeave(employeeId);
      setLeaveBalance({
        ...leaveBalance,
        privilegeLeave: {
          taken: data.data.privilegeLeave.taken,
          allotted: data.data.privilegeLeave.allotted,
        },
        casualLeave: {
          taken: data.data.casualLeave.taken,
          allotted: data.data.casualLeave.allotted,
        },
        sickLeave: {
          taken: data.data.sickLeave.taken,
          allotted: data.data.sickLeave.allotted,
        },
      });
    } catch (error:any) {
      showToast.error(error.response.data.message);
    }
  }


  const handleEdit = () => {
    setIsEditable(true);  
  };

  const handleSave= async()=>{
    try{
      await updatedLeaveBalance(employeeId,leaveBalance);
      setIsEditable(false);
      showToast.success("Leave Balance Updated Successfully!");
      
      
    }
    catch(error:any){
      showToast.error(error.response.data.message);
    }
 
  }
  const compare=(item:{key:string,label:string})=>{
    for (const [key, value] of Object.entries(leaveBalance)) {
        if(item.key === key){
          return value;
        }   
    }
  }
  

  return (
    <Grid container direction="row">
      <Grid container spacing={3} item xs={12}>
        {leaveType.map((item) => (
          <Grid item xs={4} key={item.key}>
            <RoundedBoxWIthTitle
              title={item.label}
              ChildrenContent={
                <LeaveBalanceForm
                  balanceKey={item.key}
                  onChange={(key, value)=> {
                    setLeaveBalance((prev)=> {
                      return {...prev, [`${key}`]: value}
                    })
                  }}
                  leaveBalance={compare(item)}
                  isEditable={isEditable}
                  item={item}
                />
              }
            />
          </Grid>
        ))}
      </Grid>

      { hasAdjustLeaveAccess && <Grid item className={classes.button}>
        {isEditable ? (
          <Button
            className={classes.editButton}
            onClick={handleSave}
            variant="contained"
          >
            Save
          </Button>
        ) : (
          <Button
            className={classes.editButton}
            onClick={handleEdit}
            variant="contained"
          >
            <EditIcon />
          </Button>
        )}
      </Grid>}
    </Grid>
  );
};

export default LeaveBalanceUpdate;
