import React from "react";
import Dialog from "@material-ui/core/Dialog";
import closeIcon from "../../assets/icons/close.svg";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// import PropTypes from "prop-types";
import useStyles from "./style";
// import { Fullscreen } from "@material-ui/icons";

/**
 * @method Popup - popup component of material ui
 * @param {Object} props - props passed to the component
 * @param {function} props.handleClose - to close the popup
 * @param {Object} props.children - components passed as children
 * @param {string} props.name - title of popup
 * @param {boolean} props.open - when true, opens the popup
 */

interface IProps {
  open: boolean;
  name: string;
  handleClose: (event: any) => void;
  children: any;
  maxWidth?: any;
  close?: boolean;
}

const Popup: React.FC<IProps> = ({
  open,
  name,
  handleClose,
  children,
  maxWidth,
  close,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.dialogWrapper }}
        aria-labelledby="form-dialog-title"
        maxWidth={maxWidth}
      >
        <DialogTitle className={classes.titleWrapper} id="form-dialog-title">
          <div className={classes.title}>
            {/* <img src={FrameIcon} alt="icon"></img> */}
            <h3 className={classes.titleText}>{name}</h3>

            {close && (
              <img
                onClick={handleClose}
                style={{ cursor: "pointer" }}
                src={closeIcon}
                className={classes.closeIconClass}
                alt="close"
              ></img>
            )}
          </div>
        </DialogTitle>

        <DialogContent classes={{ root: classes.content }}>
          {children}
        </DialogContent>
      </Dialog>
    </div>
  );
};

// Popup.propTypes = {
//   handleClose: PropTypes.func,
//   name: PropTypes.string,
//   open: PropTypes.bool,
//   children: PropTypes.object,
// };

export default Popup;
