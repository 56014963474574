import {Theme, makeStyles} from "@material-ui/core/styles";


export const useStyles = makeStyles((theme: Theme)=>({
    container:{
        minWidth: "34rem",
    },
    heading:{
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-between',
        borderBottom: "1px solid rgba(0, 0, 0, 0.25)",
        padding: "0.5rem 1.5rem"
    },
    labels:{
        color: "#140047",
    },
    form:{
        padding: "2rem 3rem",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-Start",
        flexDirection: "column",
    },
    mb:{
        marginBottom: "1.7rem",
    },
    link:{
        textDecoration: "none",
        color: "white",
        padding: "0.6rem 1rem",
        backgroundColor: "#140047",
        borderRadius: "0.5rem"
    },
    btn:{       
        backgroundColor: "transparent",
        "&:hover":{
            backgroundColor: "transparent",
        }
    },
    menuItem:{
        textTransform: "capitalize",
    },
    input: {
        display: 'none',
        maxWidth:"fit-content",
        overflow:"hidden",
      },
      cancel:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        // border:"1px solid black",
        position:"relative",
        // left:".2rem",
      },
      button: {
       
        backgroundColor: "#FFFFFF",
        marginRight:".5rem",
      },
      discard:{
        display:"flex",
        alignItems:"flex-end",
        justifyContent:"flex-end"
      }
}))