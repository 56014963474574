import * as React from "react";
// import { Chart } from "chart.js";
import { Theme, createStyles, makeStyles } from "@material-ui/core";
import Chart from "chart.js/auto";
import clsx from "clsx";

const useStyles = makeStyles<
  Theme,
  Pick<ISparkLineProps, "minHeight" | "minWidth">
>((theme: Theme) =>
  createStyles({
    border: {
      border: `1px solid black`,
    },
    height: {
      minHeight: (props) => props.minHeight,
    },
    width: {
      minWidth: (props) => props.minWidth,
    },
    defaultWidth: {
      minWidth: "100%",
    },
  })
);

const SparkLine: React.FC<ISparkLineProps> = ({
  data,
  color,
  labels,
  displayX = true,
  displayY = true,
  minHeight,
  minWidth,
}) => {
  const classes = useStyles({ minHeight, minWidth });
  const chartRef = React.useRef(null);
  if (data.length !== labels.length) {
    console.error("Provide same length of data and labels");
  }

  React.useEffect(() => {
    if (chartRef && chartRef.current) {
      const chart = new Chart(chartRef.current, {
        type: "line",
        data: {
          labels,
          datasets: [
            {
              data,
              borderColor: color,
              borderWidth: 2,
              fill: false,
              tension: 0.25,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          aspectRatio: 1 | 2,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              display: displayX,
              grid: {
                display: false,
              },
            },
            y: {
              display: displayY,
              grid: {
                display: false,
              },
              suggestedMin: 0,
              suggestedMax: 100,
            },
          },
        },
      });

      return () => {
        chart.destroy();
      };
    }
  }, [data, color]);

  return (
    <div
      className={clsx({
        [classes.height]: minHeight,
        [classes.defaultWidth]: !minWidth,
        [classes.width]: minWidth,
      })}
      style={{ width: "100%" }}
    >
      <canvas ref={chartRef} />
    </div>
  );
};

export default SparkLine;
SparkLine.defaultProps = {
  minHeight: "80px",
};

export interface ISparkLineProps {
  /**
   * **Data**
   * - An array of numbers
   * - Max length 4
   */
  data: number[];
  /**
   * **Labels**
   * - An array of strings
   * - Same length as `data`
   */
  labels: string[];
  /**
   * **Color**
   *  - Hex value
   *  - string
   */
  color: string;
  minHeight: string;
  minWidth?: string;
  displayX?: boolean;
  displayY?: boolean;
}
