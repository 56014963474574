import React, { createContext, useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import BasicLayout from "../../components/BasicLayout";
import { usePermissionObjectFor } from "../../hooks/permission/usePermissionObjectFor";
import { useBasicNav } from "../../utils/useBasicNav";
import { KRAHistoryPage } from "./sub-pages/History";
import KRADetailPage from "./sub-pages/KRADetailPage.tsx";
import { KRAReportPage } from "./sub-pages/Report";
import AdminView from "./views/AdminView";
import EmployeeView from "./views/EmployeeView";
import TeamLeadView from "./views/TeamLeadView";

interface IShowToast {
  error: (message: string) => void;
  success: (message: string) => void;
  warning: (message: string) => void;
}

interface IKRAContext {
  showToast: IShowToast;
}

interface IProps {
  loadPrimary: (event: boolean, basic?: boolean) => void;
  showToast: IShowToast;
}

export const KRAContext = createContext<IKRAContext>({
  showToast: {
    error: () => {},
    success: () => {},
    warning: () => {},
  },
});

const KRAPage: React.FC<IProps> = ({ loadPrimary, showToast }: IProps) => {
  useBasicNav("kra");
  useEffect(() => {
    loadPrimary(false);
  }, []);

  // const permissions = usePermissionObjectFor("kras");
  // const isAdmin = permissions.includes("approve");
  // const isTeamLead = permissions.required(["create", "upDate", "read"]);
  // const isEmployee = permissions.required(["read", "upDate"]);
  // const isHR = permissions.includes("read");

  const { path } = useRouteMatch();
  const permissions = usePermissionObjectFor("kras");
  const hasCreatePermission = permissions.includes("create");
  const hasReadPermission = permissions.includes("read");
  const hasApprovePermission = permissions.includes("approve");
  const hasUpdatePermission = permissions.includes("upDate");

  let user = "employee";
  if (hasCreatePermission) {
    user = "teamLead";
  }
  if (hasApprovePermission) {
    user = "admin";
  }

  return (
    <KRAContext.Provider value={{ showToast }}>
      <BasicLayout>
        <Switch>
          {user === "admin" && (
            <Route path={`${path}`} exact>
              <AdminView />
            </Route>
          )}
          {user === "employee" && (
            <Route path={`${path}`} exact>
              <EmployeeView />
            </Route>
          )}
          {user === "teamLead" && (
            <Route path={`${path}`} exact>
              <TeamLeadView />
            </Route>
          )}
          <Route path={`${path}/details/:userId/:kraId/edit`} exact>
            <KRADetailPage />
          </Route>
          <Route path={`${path}/details/:userId/create`} exact>
            <KRADetailPage />
          </Route>
          <Route path={`${path}/history/:id`} exact>
            <KRAHistoryPage />
          </Route>
          <Route path={`${path}/report`} exact>
            <KRAReportPage />
          </Route>
        </Switch>
      </BasicLayout>
    </KRAContext.Provider>
  );
};

export default KRAPage;
